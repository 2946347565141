import { CompetitionService } from "@core/services/admin/competition/CompetitionService";
import { GET_COMPETITION_DETAIL_DATA } from "@core/store/action-constants";
import {
  UPDATE_COMPETITION_DETAIL_DATA,
  UPDATE_PAGINATION,
  UPDATE_MODAL,
  SET_EMPTY
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { CompetitionDetailModalState } from "@core/store/types/admin/competition";
import { ActionTree, Module, MutationTree } from "vuex";

const initialState = (): CompetitionDetailModalState => {
  return {
    data: null,
    pagination: {
      perPage: 25,
      page: 1
    },
    modal: {
      isActive: false,
      offerId: null
    }
  };
};

export const state: () => CompetitionDetailModalState = initialState;

const mutations: MutationTree<CompetitionDetailModalState> = {
  [UPDATE_COMPETITION_DETAIL_DATA] (state, data) {
    state.data = { ...state.data, ...data };
  },
  
  [UPDATE_MODAL] (state, modal) {
    state.modal = { ...state.modal , ...modal };
  },
  
  [UPDATE_PAGINATION] (state, pagination) {
    state.pagination = { ...state.pagination, ...pagination };
  },
  
  [SET_EMPTY] (state) {
    Object.assign(state, initialState());
  }
};

const actions: ActionTree<CompetitionDetailModalState, RootState> = {
  async [GET_COMPETITION_DETAIL_DATA] ({ state, commit }) {
    const { pagination: { perPage: limit, page } } = state;
    const offset = (page - 1) * limit;
    const offerId = state.modal.offerId;
    
    if (offerId) {
      const { data: { competitionParticipants: data } } = await CompetitionService.detailCompetitions(limit, offset, offerId);
      
      commit(UPDATE_COMPETITION_DETAIL_DATA, data);
    }
  },
  
  [UPDATE_COMPETITION_DETAIL_DATA] ({ commit }, data) {
    commit(UPDATE_COMPETITION_DETAIL_DATA, data);
  },
  
  [UPDATE_MODAL] ({ commit }, modal) {
    commit(UPDATE_MODAL, modal);
  },
  
  [UPDATE_PAGINATION] ({ commit }, pagination) {
    commit(UPDATE_PAGINATION, pagination);
  },
  
  [SET_EMPTY] ({ commit }) {
    commit(SET_EMPTY);
  }
};

export const competitionDetailModal: Module<CompetitionDetailModalState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations
};
