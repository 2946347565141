import LTooltip from "../../../components/Common/LTooltip.vue";
import VTooltip from "v-tooltip";
import Vue from "vue";

Vue.use(VTooltip, {
  popover: {
    defaultBaseClass: "b-tooltip custom-tooltip-style"
  }
});

Vue.component("LTooltip", LTooltip);
