import { UserContextStorage } from "@core/services/common/UserContextStorage";
import {
  SET_USER_CONTEXT_STORAGE,
  GET_USER_CONTEXT_STORAGE,
  SET_EMPTY
} from "@core/store/action-constants";
import { UPDATE_USER_CONTEXT_STORAGE } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { UserContextStorageState } from "@core/store/types/common/UserContextStorage";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

const initialState = (): UserContextStorageState => {
  return {
    data: []
  };
};

const state: () => UserContextStorageState = initialState;

const getters: GetterTree<UserContextStorageState, RootState> = {};

const mutations: MutationTree<UserContextStorageState> = {
  [SET_EMPTY]: state => Object.assign(state, initialState()),
  
  [UPDATE_USER_CONTEXT_STORAGE] (state, data) {
    state.data = data;
  }
};

const actions: ActionTree<UserContextStorageState, RootState> = {
  async [GET_USER_CONTEXT_STORAGE] ({ commit }, path) {
    const data = await UserContextStorage.getItem(path);
    // @ts-ignore
    const parseData = JSON.parse(data);
    commit(UPDATE_USER_CONTEXT_STORAGE, parseData);
    return parseData;
  },
  
  [SET_USER_CONTEXT_STORAGE] (_, { path, data }) {
    UserContextStorage.setItem(path, JSON.stringify(data));
  }
};

export const userContextStorage: Module<UserContextStorageState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
