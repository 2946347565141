// @ts-ignore
import AuthenticationProvider from "@core/logic/auth/AuthenticationProvider";
import { AuthService, Login, Registration, TelegramLogin } from "@core/logic/auth/service";
import { ProfileService } from "@core/logic/user/service";
import Vue from "../../../../../main";
import {
  ADMIN_LOGIN_AS_ADVERTISER,
  ADMIN_LOGIN_AS_WEBMASTER,
  ADMIN_LOGOUT_ROLE,
  GET_TOKEN,
  GET_USER_ROLE,
  RESET_PASSWORD,
  SEND_NEW_PASSWORD,
  UPDATE_TOKEN,
  USER_LOGIN,
  USER_LOGIN_BY_PASSWORD,
  USER_LOGIN_BY_TELEGRAM,
  USER_LOGOUT,
  USER_REGISTRATION
} from "@core/store/action-constants";
import { LOGOUT, SAVE_USER, SET_TOKEN } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import Cookies from "js-cookie";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

export interface User {
  role: string | null;
  self: boolean | null;
  isConfirmed: boolean | null;
  isApproved: boolean | null;
  isBlocked: boolean | null;
  subType: string | null;
  preferredCurrency: string | null;
  isPartnerNetwork: boolean | null;
}

export interface Token {
  refresh: string | null;
  access: string | null;
  expiresIn: number;
}

export interface AuthState extends User {
  token: Token;
}

const state: AuthState = {
  role: null,
  self: null,
  isConfirmed: null,
  isApproved: null,
  isBlocked: null,
  subType: null,
  preferredCurrency: null,
  isPartnerNetwork: null,
  token: {
    refresh: Cookies.get("refresh_token") || null,
    access: null,
    expiresIn: 0
  }
};

const getters: GetterTree<AuthState, RootState> = {
  isUserBlocked: state => state.isBlocked,
  isUserLogin: state => state.token.refresh !== null,
  isTokenSet: state => state.token.access !== null,
  isActive: state => state.isConfirmed && (state.role !== "admin" && state.isApproved),
  asAdmin: state => state.role !== "admin" && state.self === false,
  isAgent: state => state.subType === "AGENT",
  permissions: state => {
    if (state.token.access) {
      const payloadEncode = state.token.access.split(".")[1];
      const replacedEncode = payloadEncode
        .replace(/\s+/g, "")
        .replace(/-/g, "+")
        .replace(/_/g, "/");
      const payload = JSON.parse(window.atob(replacedEncode));
      return payload.permissions;
    }
  }
};

const mutations: MutationTree<AuthState> = {
  [SAVE_USER] (state, payload: AuthState) {
    Cookies.set("refresh_token", payload.token.refresh as string);
    
    state.token = {
      ...state.token,
      refresh: payload.token.refresh
    };
    state.role = payload.role;
    state.self = payload.self;
    state.isConfirmed = payload.isConfirmed;
    state.isApproved = state.role === "admin" ? true : payload.isApproved;
    state.isBlocked = payload.isBlocked;
    state.subType = payload.subType;
    state.preferredCurrency = payload.preferredCurrency;
    state.isPartnerNetwork = payload.isPartnerNetwork;
    state.preferredCurrency = payload.preferredCurrency;
  },
  
  [SET_TOKEN] (state, payload: Token) {
    state.token = {
      ...state.token,
      access: payload.access,
      expiresIn: payload.expiresIn
    };
  },
  
  [LOGOUT] (state) {
    Cookies.remove("refresh_token");
    
    state.token = {
      refresh: null,
      access: null,
      expiresIn: 0
    };
    state.role = null;
    state.self = null;
    state.isConfirmed = null;
    state.isApproved = null;
    state.isBlocked = null;
    state.subType = null;
    state.preferredCurrency = null;
    state.isPartnerNetwork = null;
  }
};

const actions: ActionTree<AuthState, RootState> = {
  // Просто шорткат для USER_LOGIN_BY_PASSWORD
  [USER_LOGIN] ({ dispatch }, payload: Login): void {
    dispatch(USER_LOGIN_BY_PASSWORD, payload);
  },
  
  async [USER_LOGIN_BY_PASSWORD] (_, payload: Login): Promise<any> {
    await AuthenticationProvider.loginByPassword(payload);
  },
  
  async [USER_LOGIN_BY_TELEGRAM] (_, payload: TelegramLogin): Promise<any> {
    await AuthenticationProvider.loginByTelegram(payload);
  },
  
  async [USER_LOGOUT] ({ commit }): Promise<void> {
    commit(LOGOUT);
    if (!Vue.$route.name?.startsWith("auth:")) {
      await Vue.$router.push({ name: "auth:signIn" });
    }
  },
  
  async [RESET_PASSWORD] (_, params): Promise<any> {
    try {
      const { data } = await AuthService.resetPassword(params);
      return data;
    } catch (error) {
      throw error;
    }
  },
  
  async [SEND_NEW_PASSWORD] (_, params): Promise<any> {
    const { data } = await AuthService.sendNewPassword(params);
    return data;
  },
  
  async [ADMIN_LOGIN_AS_WEBMASTER] ({ state }, webmasterId: string): Promise<void> {
    if (state.role === "admin") {
      await AuthenticationProvider.loginAsWebmaster(webmasterId);
      await Vue.$router.push({ name: "main" });
    }
  },
  
  async [ADMIN_LOGIN_AS_ADVERTISER] ({ state }, advertiserId: string): Promise<void> {
    if (state.role === "admin") {
      await AuthenticationProvider.loginAsAdvertiser(advertiserId);
      await Vue.$router.push({ name: "main" });
    }
  },
  
  async [ADMIN_LOGOUT_ROLE] (): Promise<void> {
    await AuthenticationProvider.logoutToAdmin();
    await Vue.$router.push({ name: "main" });
  },
  
  async [USER_REGISTRATION] (_, data: Registration): Promise<any> {
    await AuthenticationProvider.loginByRegistration(data);
  },
  
  [GET_TOKEN] ({ state }): Promise<AuthenticationProvider> | undefined {
    if (state.token.refresh) {
      return AuthenticationProvider.loginByToken(state.token.refresh);
    }
  },
  
  // TODO: Нужно разобраться, зачем нужен этот метод, скорее его можно заменить с помощю AuthenticationProvider
  // TODO: Из этого места WhoAmi вызывается дважды
  [GET_USER_ROLE] ({ state }): Promise<AuthenticationProvider> | undefined {
    if (state.token.refresh) {
      return AuthenticationProvider.loginByToken(state.token.refresh);
    }

    // if (state.token.refresh) {
    //   const { data } = await ProfileService.getRole();
    //   commit(SAVE_USER, {
    //     ...data,
    //     role: data.userType,
    //     token: { refresh: state.token.refresh }
    //   });
    //   return data;
    // }
  },
  
  [UPDATE_TOKEN] ({ commit }, token: Token): void {
    commit(SET_TOKEN, token);
  }
};

export const auth: Module<AuthState, RootState> = {
  state,
  getters,
  actions,
  mutations
};
