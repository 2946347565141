import { ActionTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
// TODO: gql service
import { StatisticService } from "@core/logic/webmaster/statistic/statistic";
import { TodayStatistic, WebmasterStatisticState } from "@core/store/types/webmaster/statistic";
import {
  SET_STATISTIC,
  SET_TODAY_STATISTIC
} from "@core/store/mutation-constants";
import {
  GET_STATISTIC,
  GET_DAY_STATISTIC, GET_CURRENCY
} from "@core/store/action-constants";
import moment from "moment";
import { detailing } from "./modules/detailing";
import { postbacks } from "./modules/postbacks";
import { userContextStorage } from "@core/store/modules/common/userContextStorage";
import { trafficAnalysis } from "@core/store/modules/webmaster/statistic/modules/trafficAnalysis";
import { statistics as statisticsModule } from "@core/store/modules/webmaster/statistic/modules/statistics";
import { sortFieldGroupsStatistic } from "@core/helpers/sortFieldGroupsStatistic";
import { StatisticItems } from "@core/store/types/common/statistic/common/statistic";
import { sortByPreferredCurrency } from "@core/helpers/sortByPreferredCurrency";

const initialState = (): WebmasterStatisticState => {
  return {
    statistics: null,
    todayStatistic: {
      confirmed: [],
      income: [],
      leads: []
    }
  };
};

const state: () => WebmasterStatisticState = initialState;

const mutations: MutationTree<WebmasterStatisticState> = {
  [SET_STATISTIC] (state, statistics) {
    state.statistics = statistics;
  },
  
  [SET_TODAY_STATISTIC] (state, todayStatistic) {
    state.todayStatistic = todayStatistic;
  }
};

const actions: ActionTree<WebmasterStatisticState, RootState> = {
  async [GET_STATISTIC] ({ commit }, params): Promise<void> {
    const { filters, excludedFilters, groups } = params;

    const { data: { statistics } } = await StatisticService.getWebmasterStatistic(filters, excludedFilters, groups);
  
    sortFieldGroupsStatistic(statistics.items, "country", "region", "category");
  
    statistics.items = statistics?.items.map(item => {
      return {
        ...item,
        moneyCommissions: item?.moneyAdvertiserWrittenOff- item?.moneyWebmasterAccrued
      };
    });
    
    statistics.items = statistics?.items.map(item => {
      return {
        ...item,
        moneyCommissions: item.moneyAdvertiserWrittenOff- item.moneyWebmasterAccrued
      };
    });
    
    commit(SET_STATISTIC, statistics);
  },
  
  async [GET_DAY_STATISTIC] ({ commit, dispatch }): Promise<void> {
    const { data: { statistics } } = await StatisticService.getWebmasterStatistic(
      {
        dateStart: moment().startOf("day").toDate(),
        dateEnd: moment().toDate()
      },
      {},
      ["DAY", "CURRENCY"]
    );
    const currencies = await dispatch(`currencyList/${ GET_CURRENCY }`, undefined, { root: true });
    
    const zeroStatisticItems: Array<StatisticItems> = currencies
      .map((currency: string) => {
        return {
          countLeadsWhite: 0,
          countLeadsAccepted: 0,
          moneyWebmasterAccrued: 0,
          currency
        };
      });
    
    const items = statistics.items.length
      ? zeroStatisticItems.map((item) => {
        const findElement = statistics.items.find((el) => el.currency === item.currency);
        if (findElement) {
          return findElement;
        }
        return item;
      })
      : zeroStatisticItems;
  
    const payload = sortByPreferredCurrency(items)?.reduce((acc, obj) => {
      acc.leads.push({ value: obj.countLeadsWhite, currency: obj.currency });
      acc.confirmed.push({ value: obj.countLeadsAccepted, currency: obj.currency });
      acc.income.push({ value: obj.moneyWebmasterAccrued, currency: obj.currency });
      return acc;
    }, {
      leads: <TodayStatistic["leads"]>[],
      confirmed: <TodayStatistic["confirmed"]>[],
      income: <TodayStatistic["income"]>[]
    });
    
    commit(SET_TODAY_STATISTIC, payload);
  }
};

export const statistic: Module<WebmasterStatisticState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    detailing,
    statisticsFilters: statisticsModule,
    postbacks,
    trafficAnalysis,
    userContextStorage
  }
};
