import { ActionTree } from "vuex";
import { languagesList } from "@/store/modules/admin/lists/languagesList";
import { countriesList } from "@/store/modules/admin/lists/countriesList";
import {
  GET_LANGUAGES_LIST,
  GET_COUNTRIES_LIST,
  INITIALIZATION
} from "@/store/action-constants";
import { RootState } from "@/store/root-state";

const actions: ActionTree<any, RootState> = {
  [INITIALIZATION] ({ dispatch }) {
    dispatch(`languagesList/${ GET_LANGUAGES_LIST }`);
    dispatch(`countriesList/${ GET_COUNTRIES_LIST }`);
  }
};

export const initialization = {
  actions,
  modules: {
    languagesList,
    countriesList
  }
};
