import { RootState } from "@core/store/root-state";
import { ActionTree, Module, MutationTree } from "vuex";
import {
  CLEAR_FILTERS,
  SET_EMPTY,
  SET_OFFERS,
  UPDATE_FILTERS,
  UPDATE_PAGINATION, UPDATE_SORTING
} from "@core/store/mutation-constants";
import { GET_OFFERS } from "@core/store/action-constants";
import { OffersService } from "@core/services/webmaster/offers/OffersService";
import { OffersState } from "@core/store/types/webmaster/offers";
import { WebmasterOffer } from "@core/store/logic/webmaster/offer/WebmasterOffer";
import { WebmasterOfferInterface } from "@core/store/types/webmaster/offer";
import { currencyList } from "@core/store/modules/common/lists/currencyList";
import offersFieldsList from "@core/store/modules/webmaster/offers/modules/datasets/offersFieldsList.json";

const initialState = (): OffersState => {
  return {
    selectedFields: offersFieldsList,
    data: null,
    pagination: {
      perPage: 25,
      page: 1
    },
    filters: {},
    sort: null,
    order: null
  };
};

export const state: () => OffersState = initialState;

const mutations: MutationTree<OffersState> = {
  [SET_EMPTY] (state) {
    Object.assign(state, initialState());
  },
  
  [SET_OFFERS] (state, offers) {
    state.data = offers;
  },
  
  [UPDATE_PAGINATION] (state, pagination) {
    state.pagination = { ...state.pagination, ...pagination };
  },
  
  [UPDATE_FILTERS] (state, filters) {
    ["countries", "categories", "nameOrIntId"].forEach((key) => {
      if (
        filters.hasOwnProperty(key) &&
        Array.isArray(filters[key]) &&
        !filters[key].length ||
        filters[key] === ""
      ) {
        filters[key] = null;
      }
    });
    state.filters = { ...state.filters, ...filters };
  },
  
  [UPDATE_SORTING] (state, sort) {
    if (sort.value && sort.order) {
      state.sort = sort.value;
      state.order = sort.order.toUpperCase();
    }
  },
  
  [CLEAR_FILTERS]: (state): void => {
    state.filters = initialState().filters;
  }
};

const actions: ActionTree<OffersState, RootState> = {
  async [GET_OFFERS] ({ commit, state }) {
    try {
      const { filters, pagination: { perPage: limit, page }, sort, order } = state;
      const offset = (page - 1) * limit;
      const { data } = await OffersService.getOffers(filters, limit, offset, sort, order);
      
      const offers = { ...data.offers, items: data.offers.items.map((item: WebmasterOfferInterface) => new WebmasterOffer(item)) };
      commit(SET_OFFERS, offers);
      return offers;
    } catch (error) {
      throw error;
    }
  },
  
  [UPDATE_PAGINATION] ({ commit }, pagination) {
    commit(UPDATE_PAGINATION, pagination);
  },
  
  [UPDATE_FILTERS] ({ commit }, filters) {
    commit(UPDATE_FILTERS, filters);
    commit(UPDATE_PAGINATION, { page: 1 });
  },
  
  [CLEAR_FILTERS] ({ commit }) {
    commit(CLEAR_FILTERS);
    commit(UPDATE_PAGINATION, { page: 1 });
  }
};

export const offers: Module<OffersState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    currencyList
  }
};
