import { ActionTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import {
  UPDATE_MODAL_ACTIVE,
  UPDATE_MODAL_OPTIONS
} from "@core/store/action-constants";
import { SubAccountsModalOptions, SubAccountsModalState } from "@core/store/types/admin/users/webmasters/SubAccount";

const initialState = (): SubAccountsModalState => {
  return {
    options: {
      subAccounts: null
    },
    isSubAccountsModalActive: false
  };
};

const state: () => SubAccountsModalState = initialState;

const mutations: MutationTree<SubAccountsModalState> = {
  SET_EMPTY: state => Object.assign(state, initialState()),

  [UPDATE_MODAL_OPTIONS] (state, options: SubAccountsModalOptions) {
    state.options = { ...state.options, ...options };
  },

  [UPDATE_MODAL_ACTIVE] (state, payload: boolean) {
    state.isSubAccountsModalActive = payload;
  }
};

const actions: ActionTree<SubAccountsModalState, RootState> = {
  [UPDATE_MODAL_OPTIONS] ({ commit }, payload: SubAccountsModalOptions) {
    commit(UPDATE_MODAL_OPTIONS, payload);
  },

  [UPDATE_MODAL_ACTIVE] ({ commit }, payload: boolean) {
    commit(UPDATE_MODAL_ACTIVE, payload);
  }
};


export const subAccountsModal: Module<RootState, SubAccountsModalState> = {
  namespaced: true,
  state,
  actions,
  mutations
};