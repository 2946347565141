<template>
    <div>
        <ModalBox
            :is-active.sync="isActive"
            :is-loading="isLoading">
            <template #header>
                <h5 class="title is-5">
                    {{ $t(`admin.profile.modal.header.title`) }}
                </h5>
            </template>
            <AdminProfile
                is-edit
                is-currency
                :user.sync="localForm">
            </AdminProfile>
            <template #footer>
                <div class="container">
                    <div class="columns">
                        <div class="column">
                            <b-button
                                class="is-fullwidth is-info is-light"
                                :loading="isLoading"
                                expanded
                                @click="openPasswordModal">
                                {{ $t(`admin.profile.modal.footer.buttons.changePassword`) }}
                            </b-button>
                        </div>
                        <div class="column">
                            <b-button
                                class="is-fullwidth is-success is-light"
                                native-type="submit"
                                :loading="isLoading"
                                @click="saveUser">
                                {{ $t(`common.buttons.save`) }}
                            </b-button>
                        </div>
                    </div>
                </div>
            </template>
        </ModalBox>

        <AdminProfilePasswordModal></AdminProfilePasswordModal>
    </div>
</template>

<script>
  import AdminProfile from "@/components/Common/Admin/AdminProfile";
  import AdminProfilePasswordModal from "@/components/Common/Admin/AdminProfilePasswordModal";
  import ModalBox from "@/components/Common/ModalBox";
  import { EDIT_USER, FETCH_USER, GET_TOKEN } from "@core/store/action-constants";
  import { SET_EMPTY, UPDATE_ADMIN_PROFILE_FORM, UPDATE_MODAL } from "@core/store/mutation-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import _cloneDeep from "lodash/cloneDeep";
  import { mapActions, mapMutations, mapState } from "vuex";
  
  export default {
    name: "AdminProfileModal",
    
    components: {
      ModalBox,
      AdminProfile,
      AdminProfilePasswordModal
    },
    
    data () {
      return {
        localForm: null
      };
    },

    computed: {
      ...mapFields("admin/profile", {
        fields: [
          "isActive"
        ],
        base: "modal",
        action: UPDATE_MODAL
      }),
      
      ...mapFields("admin/profile/password", {
        fields: ["isPasswordModalActive"],
        base: "modal",
        action: UPDATE_MODAL
      }),

      ...mapState("admin/profile", [
        "user", "form"
      ]),

      isLoading () {
        return this.$wait(`admin/profile/${ FETCH_USER }`)
          || this.$wait(`admin/profile/${ EDIT_USER }`)
          || this.$wait(`${ GET_TOKEN }`);
      }
    },

    methods: {
      ...mapActions("admin/profile", [
        FETCH_USER,
        EDIT_USER
      ]),

      ...mapActions([
        GET_TOKEN
      ]),

      ...mapMutations("admin/profile", [
        UPDATE_ADMIN_PROFILE_FORM,
        SET_EMPTY
      ]),

      openPasswordModal () {
        this.isPasswordModalActive = true;
      },

      convertUserToForm () {
        const { contact, name, preferredCurrency, timeZone: { alias: timeZone } } = this.user;

        const form = {
          ...contact,
          preferredCurrency,
          name,
          timeZone
        };

        this.UPDATE_ADMIN_PROFILE_FORM(form);
        this.localForm = _cloneDeep(this.form);
      },

      async saveUser () {
        try {
          await this.EDIT_USER();
          await this.GET_TOKEN();

          this.$buefy.toast.open({
            message: this.$t("advertiser.profile.saveProfileSuccessConfirmation"),
            type: "is-success"
          });

          this.SET_EMPTY();
        } catch ({ errors }) {
          const { message } = errors[0];
          this.$buefy.toast.open({
            message: this.$t(`admin.profile.modal.messages.${ message }`, { email: this.form.email }),
            type: "is-danger"
          });
        }
      }
    },

    watch: {
      async isActive (value) {
        if (value && !this.user) {
          await this.FETCH_USER();
        }
        this.convertUserToForm();
      },

      localForm: {
        deep: true,
        handler (value) {
          this.UPDATE_ADMIN_PROFILE_FORM(value);
        }
      },

      isPasswordModalActive (value) {
        this.isActive = !value;
      }
    }
  };
</script>

<style lang="scss" scoped>
    input {
        &[type="checkbox"] {
            display: none;

            & + span {
                color: $success !important;
                border: 1px solid $success !important;
                background-color: transparent !important;
            }

            &:checked {
                & + span {
                    color: white !important;
                    background-color: $success !important;
                }
            }
        }
    }
</style>
