import { GraphQLServer } from "@core/plugins/axios";
import Service from "@core/services/common/Service";
import {
  PartialPlansFilters,
  PlansEditCommentInput,
  PlansEditCommentResponse,
  PlansResponse
} from "@core/store/types/admin/efficiency/efficiencyPlans";
import { AxiosPromise } from "axios";
import editAdminComment from "./graphql/editAdminComment.graphql";
import plans from "./graphql/plans.graphql";

export class EfficiencyPlansService extends Service {
  public static getPlans (limit: number, offset: number, filters: PartialPlansFilters = {}): AxiosPromise<PlansResponse> {
    return GraphQLServer.get("", {
      params: {
        query: plans,
        variables: {
          limit,
          offset,
          filters
        }
      }
    });
  }
  
  public static editAdminComment (adminId: string, input: PlansEditCommentInput): AxiosPromise<PlansEditCommentResponse> {
    return GraphQLServer.get("", {
      params: {
        query: editAdminComment,
        variables: {
          adminId,
          input
        }
      }
    });
  }
}
