import { DetailingService } from "@core/services/admin/statistic/detailing/DetailingService";
import {
  GET_DETAIL,
  GET_USER_CONTEXT_STORAGE,
  SET_EMPTY,
  SYNC_STATISTIC_FILTERS,
  UPDATE_SELECTED_LEADS
} from "@core/store/action-constants";

import { detailingRequestModal } from "@core/store/modules/admin/statistic/modules/detailing/detailingRequestModal";
import { detailingStatusesList } from "@core/store/modules/admin/statistic/modules/detailing/lists/detailingStatusesList";
import { leadStatusesList } from "@core/store/modules/admin/statistic/modules/detailing/lists/LeadStatusesList";
// @ts-ignore
import detailingFieldsList from "@core/store/modules/admin/statistic/modules/detailing/datasets/detailingFieldsList.json";
import {
  SET_DETAIL,
  UPDATE_DETAILING_FILTERS,
  UPDATE_DETAILING_PAGINATION,
  UPDATE_FILTERS,
  UPDATE_DETAILING_FIELDS,
  CLEAR_FILTERS
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { DetailingListState, PartialDetailingState } from "@core/store/types/admin/statistic/detailing/detailingState";
import moment from "moment";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { advertisersFilters } from "@core/store/modules/admin/statistic/modules/common/advertisersFilters";
import { webmastersFilters } from "@core/store/modules/admin/statistic/modules/common/webmastersFilters";
import { externalWebmasterFilters } from "@core/store/modules/admin/statistic/modules/common/externalWebmasterFilters";
import { offersFilters } from "@core/store/modules/admin/statistic/modules/common/offersFilters";
import { filterFilters, prepareBaseFilters, prepareUserFilters } from "@core/store/modules/common/helpers/prepareFilters";
import { filterPreparation } from "@core/helpers/filterPreparation";
import { withdrawTypesList } from "@core/store/modules/admin/lists/withdrawTypesList";
import { userContextStorage } from "@core/store/modules/common/userContextStorage";

const initialState = (): PartialDetailingState => {
  return {
    detail: null,
    filters: {
      datepicker: {
        dateStart: moment().startOf("day").subtract(7, "day").toDate(),
        dateEnd: moment().endOf("day").toDate()
      }
    },
    pagination: {
      page: 1,
      perPage: 100
    },
    contextStorageDetailingNamespace: "admin/statistics/detailing/userContextStorageDetailing",
    selectedLeads: [],
    selectedFields: detailingFieldsList,
    fields: []
  };
};

const state: () => PartialDetailingState = initialState;

const getters: GetterTree<PartialDetailingState, RootState> = {};

const mutations: MutationTree<PartialDetailingState> = {
  [SET_EMPTY]: state => Object.assign(state, initialState()),
  
  [SET_DETAIL] (state, items: DetailingListState["detail"]) {
    state.detail = items;
  },
  
  [UPDATE_DETAILING_FILTERS] (state, filter: any) {
    if (filter.hasOwnProperty("countries") && Array.isArray(filter.countries) && !filter.countries.length) {
      filter.countries = null;
    }
    state.filters = { ...state.filters, ...filterPreparation(filter) };
  },
  
  [UPDATE_DETAILING_PAGINATION] (state, pagination) {
    state.pagination = { ...state.pagination, ...pagination };
  },
  
  [UPDATE_SELECTED_LEADS] (state, leads) {
    state.selectedLeads = leads;
  },
  
  [UPDATE_DETAILING_FIELDS] (state, fields) {
    state.fields = fields;
  },

  [CLEAR_FILTERS]: (state): void => {
    const { datepicker, currency } = state.filters;
    state.filters = {
      ...initialState().filters,
      datepicker,
      currency
    };
  }
};

const actions: ActionTree<PartialDetailingState, RootState> = {
  async [GET_DETAIL] ({ state, commit, dispatch }) {
    const limit = state.pagination.perPage;
    const offset = (state.pagination.page - 1) * limit;
    const { datepicker: { dateStart, dateEnd }, id, clickId, remoteId, phone, countries, regions, ...filters } = state.filters;
    const fields = await dispatch(`userContextStorageDetailing/${ GET_USER_CONTEXT_STORAGE }`, state.contextStorageDetailingNamespace);
    if (fields) {
      commit(UPDATE_DETAILING_FIELDS, fields);
    }
    
    try {
      const { leads } = await DetailingService.getDetail(
        limit,
        offset,
        {
          id: id?.length ? id : null,
          clickId: clickId?.length ? clickId : null,
          remoteId: remoteId?.length ? remoteId : null,
          countries: countries?.length ? countries : null,
          phone: phone?.length > 2 ? phone : null,
          regions: regions?.length ? regions : null,
          ...filters,
          dateEnd,
          dateStart
        },
        state.fields
      );
      
      commit(SET_DETAIL, leads);
    } catch (e: any) {
      throw new Error(e);
    }
  },
  
  [UPDATE_DETAILING_FILTERS] ({ commit }, filter) {
    commit(UPDATE_DETAILING_FILTERS, filter);
    commit(UPDATE_DETAILING_PAGINATION, { page: 1 });
    commit(UPDATE_SELECTED_LEADS, []);
  },
  
  [UPDATE_DETAILING_FIELDS] ({ state, commit, dispatch }, fields) {
    if (fields.length !== state.fields.length) {
      dispatch(GET_DETAIL);
    }
    commit(UPDATE_DETAILING_FIELDS, fields);
  },
  
  [UPDATE_DETAILING_PAGINATION] ({ commit }, pagination) {
    commit(UPDATE_DETAILING_PAGINATION, pagination);
    if (pagination.perPage !== undefined) {
      commit(UPDATE_SELECTED_LEADS, []);
    }
  },
  
  [UPDATE_SELECTED_LEADS] ({ commit, state }, { isChecked, dataItem }) {
    let filteredLeads = state.selectedLeads?.length ? [ ...state.selectedLeads ] : [];
    
    if (dataItem) {
      // Toggle one
      if (isChecked) {
        filteredLeads.push(dataItem);
      } else {
        filteredLeads = filteredLeads.filter(({ id }) => id !== dataItem.id);
      }
    } else {
      // Toggle all
      if (isChecked) {
        filteredLeads.push(...state.detail?.items || []);
      } else {
        const itemsIds = (state.detail?.items || []).map(({ id }) => id);
        filteredLeads = filteredLeads.filter(({ id }) => !itemsIds.includes(id));
      }
    }
    
    commit(UPDATE_SELECTED_LEADS, filteredLeads);
  },
  
  [SYNC_STATISTIC_FILTERS] ({ dispatch }, filters) {
    const baseFilters = prepareBaseFilters(filters);
    const userFilters = prepareUserFilters(filters, baseFilters);
    
    dispatch(`offersFilters/${ UPDATE_FILTERS }`, baseFilters);
    dispatch(`webmastersFilters/${ UPDATE_FILTERS }`, filterFilters(userFilters, ["webmasterId"]));
    dispatch(`externalWebmastersList/${ UPDATE_FILTERS }`, filterFilters(userFilters, ["externalWebmasterId"]));
    dispatch(`advertisersFilters/${ UPDATE_FILTERS }`, filterFilters(userFilters, ["advertiserId"]));
  },
  
  [SET_EMPTY] ({ commit }) {
    commit("SET_EMPTY");
    commit(`offersFilters/${ SET_EMPTY }`);
    commit(`webmastersFilters/${ SET_EMPTY }`);
    commit(`externalWebmastersList/${ SET_EMPTY }`);
    commit(`advertisersFilters/${ SET_EMPTY }`);
    commit("withdrawTypesList/SET_EMPTY");
    commit("leadStatusesList/SET_EMPTY");
    commit("detailingStatusesList/SET_EMPTY");
    commit("detailingRequestModal/SET_EMPTY");
  },

  [CLEAR_FILTERS] ({ commit }) {
    commit(CLEAR_FILTERS);
    commit(UPDATE_DETAILING_PAGINATION, { page: 1 });
  }
};

export const detailing: Module<PartialDetailingState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    offersFilters,
    webmastersFilters,
    externalWebmastersList: externalWebmasterFilters,
    advertisersFilters,
    leadStatusesList,
    withdrawTypesList,
    detailingStatusesList,
    detailingRequestModal,
    userContextStorageDetailing: userContextStorage
  }
};
