import { SHOW_SUCCESS, SUCCESS } from "@/store/mutation-constants";
import { RootState } from "@/store/root-state";
import { GetterTree, Module, MutationTree } from "vuex";

export interface SuccessState {
  showSuccessMessage: boolean;
  successMessage: string | null;
  successMessageTimeout: number;
}

const state: SuccessState = {
  showSuccessMessage: false,
  successMessage: null,
  successMessageTimeout: 0
};

const getters: GetterTree<SuccessState, RootState> = {
  showSuccessMessage: state => state.successMessage,
  successMessage: state => state.successMessage,
  successMessageTimeout: state => state.successMessageTimeout
};

const mutations: MutationTree<SuccessState> = {
  [SUCCESS] (state, payload) {
    if (payload === null) {
      state.showSuccessMessage = false;
      state.successMessage = null;
    } else {
      state.showSuccessMessage = true;
      state.successMessageTimeout =
        payload.timeout === 0 ? 0 : payload.timeout || 6000;
      state.successMessage = payload.msg;
      // Toast.open({
      //   message: payload.msg,
      //   type: "is-success"
      // });
    }
  },
  [SHOW_SUCCESS] (state, payload) {
    state.showSuccessMessage = !!payload;
  }
};

export const success: Module<SuccessState, RootState> = {
  state,
  getters,
  mutations
};
