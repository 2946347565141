type Status = "in processing" | "closed" | "confirmed" | "hold up %s" | "paid" | "canceled" | "expired" | "spam" |
"fraud" | "replay" | "the confirmation";

interface Data {
  cancelReason: string;
  clickId: string;
  offerId: string;
  id: string;
  intId: number | null;
  createdAt: string;
  offer: string;
  status: Status[];
  phone: string;
  amount: number;
  unholdDate: string | null;
}

interface Labels {
  utmCampaign: string;
  utmContent: string;
  utmMedium: string;
  utmSource: string;
  utmTerm: string;
}

export class StatisticDetail {
  public labels: Labels = {
    utmCampaign: "",
    utmContent: "",
    utmMedium: "",
    utmSource: "",
    utmTerm: ""
  };
  public data: Data = {
    cancelReason: "",
    clickId: "",
    offerId: "",
    id: "",
    intId: null,
    createdAt: "",
    offer: "",
    status: [],
    phone: "",
    amount: 0,
    unholdDate: ""
  };
  
  
  constructor (statisticDetail: any) {
    this.labels.utmCampaign = statisticDetail.fields.utm_campaign;
    this.labels.utmContent = statisticDetail.fields.utm_content;
    this.labels.utmMedium = statisticDetail.fields.utm_medium;
    this.labels.utmSource = statisticDetail.fields.utm_source;
    this.labels.utmTerm = statisticDetail.fields.utm_term;
  
    this.data.cancelReason = statisticDetail.cancelReason;
    this.data.clickId = statisticDetail.fields.clickId;
    this.data.offerId = statisticDetail.fields.offerId;
    this.data.id = statisticDetail.id;
    this.data.intId = statisticDetail.intId;
    this.data.createdAt = statisticDetail.createdAt;
    this.data.offer = statisticDetail.name;
    this.data.status = statisticDetail.status;
    this.data.amount = statisticDetail.amount;
    this.data.phone = statisticDetail.fields.phone;
    this.data.unholdDate = statisticDetail.unholdDate;
  }
}
