import { SearchParams } from "@core/logic/statistics/params";
import { AxiosPromise } from "axios";
import { WebmasterStatisticByGroups } from "@core/logic/webmaster/statistic/WebmasterStatisticByGroups";
import Service from "@core/services/common/Service";
import { BaseFiltersInput } from "@core/store/types/common/statistic/generic/statisticFilters";
import { StatisticResponseData } from "@core/store/types/common/statistic/common/statistic";
import { auth } from "@core/store/modules/common/auth";
import statisticGenerator from "@core/services/webmaster/statistic/statistic/graphql/statistics.generator";

export interface UTMTags {
  "utm_campaign": Array<string>;
  "utm_term": Array<string>;
  "utm_source": Array<string>;
  "utm_content": Array<string>;
  "utm_medium": Array<string>;
}

export class StatisticService extends Service{
  public static getUTMTags (): AxiosPromise<UTMTags> {
    return this.rest.get("/webmaster/statistics/utm-tags");
  }
  
  public static getWebmasterStatisticByLeads (params?: any): AxiosPromise {
    return this.rest.get("/webmaster/statistics/leads", { params });
  }
  
  public static getWebmasterStatisticByGroups (params?: SearchParams): AxiosPromise<WebmasterStatisticByGroups> {
    return this.rest.get("/webmaster/statistics", { params });
  }
  
  public static async getWebmasterStatistic (
    filters: BaseFiltersInput,
    excludedFilters: BaseFiltersInput,
    groups: string[]
  ): Promise<StatisticResponseData> {
    try {
      // @ts-ignore
      const subType = auth?.state?.subType;
      const isAgent = subType === "AGENT";
      
      return await this.api.get("", {
        params: statisticGenerator(
          filters,
          excludedFilters,
          groups,
          isAgent
        )
      });
    } catch (e) {
      throw e;
    }
  }
}
