import { formatPercent, toFixed } from "@core/filters";
import { StatisticItems, StatisticResponse } from "@core/store/types/common/statistic/common/statistic";
import { Dates, GroupType } from "@core/store/types/admin/lists/DashboardListState";
import _groupBy from "lodash/groupBy";

const getPercentDifference = (newValue: Nullable<number>, oldValue: Nullable<number>): number | string | undefined => {
  if (oldValue === newValue) {
    return 0;
  } else if (oldValue === 0 || newValue === 0) {
    return 100;
  } else if (oldValue && newValue) {
    return toFixed(formatPercent(Math.abs(oldValue - newValue) / oldValue), 2);
  }
};

const groupedBySecondParam = (items: StatisticItems[], label: string): Dates => {
  // Исходя из label, возвращает объект у которого ключ дата и значение массив
  // пользователей за эту дату
  return _groupBy(items, label);
};

export const serializeItems = (cardStatistic: StatisticResponse, groupType: GroupType, label: string) => {
  const currentItems = cardStatistic?.currentPeriod.items || [];
  const graphItems = cardStatistic?.graphPeriod.items || [];
  const prevItems = cardStatistic?.prevPeriod.items || [];
  
  return currentItems.map((item: StatisticItems) => {
    const responseDates = {
      ...groupedBySecondParam(graphItems, label)
    };
    
    const prevPeriodStatisticItem = prevItems
      .find((itemPrevPeriod: StatisticItems) => {
        // @ts-ignore
        return item[groupType].id === itemPrevPeriod[groupType].id;
      });
      
    const leadsCurrent = item.countLeadsCreated;
      
    const response = {
      groups: [label, groupType],
      item: item[groupType],
      dates: responseDates,
      amount: {
        leads: leadsCurrent,
        commission: item.moneyAdvertiserWrittenOff - item.moneyWebmasterAccrued
      },
      change: {}
    };
      
    const commissionCurrent = item.moneyAdvertiserWrittenOff - item.moneyWebmasterAccrued;
      
    if (prevPeriodStatisticItem) {
      const leadsPrev = prevPeriodStatisticItem.countLeadsCreated;
      const commissionPrev = prevPeriodStatisticItem.moneyAdvertiserWrittenOff - prevPeriodStatisticItem.moneyWebmasterAccrued;
        
      // Что-бы узнать что такое absolute и relative, загляни в DashboardTopCard.vue
      response.change = {
        leads: {
          absolute: leadsCurrent - leadsPrev,
          relative: getPercentDifference(leadsCurrent, leadsPrev)
        },
        commission: {
          absolute: commissionCurrent - commissionPrev,
          relative: getPercentDifference(commissionCurrent, commissionPrev)
        }
      };
    } else {
      response.change = {
        leads: {
          absolute: leadsCurrent,
          relative: 100
        },
        commission: {
          absolute: commissionCurrent,
          relative: 100
        }
      };
    }
      
    return response;
  });
};