import Service from "@core/services/common/Service";
import { OfferRequest } from "@core/store/types/admin/offer/OfferCommon";
import { AxiosPromise } from "axios";
import createOffer from "./graphql/createOffer.graphql";
import updateOffer from "./graphql/updateOffer.graphql";
import { OfferState } from "@core/store/modules/admin/offer/AdminOfferModule";

export class OfferEditCommonService extends Service {
  public static createOffer (offer: OfferState["offer"]): AxiosPromise {
    const preparedData = this.prepareData(offer);
    try {
      return this.api.post("", {
        query: createOffer,
        variables: { input: { ...preparedData } }
      });
    } catch (e) {
      throw e;
    }
  }

  public static updateOffer (offerId: string, offer: OfferState["offer"]): AxiosPromise {
    const preparedData = this.prepareData(offer);
    try {
      return this.api.post("", {
        query: updateOffer,
        variables: { offerId, input: { ...preparedData } }
      });
    } catch (e) {
      throw e;
    }
  }

  public static prepareData (data: OfferState["offer"]): Nullable<OfferRequest> {
    if (data) {
      return {
        ...data,
        categories: data.categories ?? [],
        trafficTypes: data.trafficTypes ?? [],
        countries: data.countries ?? [],
        logo: data.logo?.id,
        withdrawType: data.withdrawType?.toLowerCase(),
        offerType: data.offerType?.toLowerCase()
      };
    } return data;
  }
}
