import { StatisticPostback } from "@core/logic/webmaster/statistic/StatisticPostback";
import { GET_STATISTIC_POSTBACKS } from "@core/store/action-constants";
import { SET_STATISTIC_POSTBACKS } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { Postbacks, PostbackState } from "@core/store/types/webmaster/statistic/modules/postbacks";
import Axios from "axios";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

const state: PostbackState = {
  postbacks: null
};

const getters: GetterTree<PostbackState, RootState> = {};

const mutations: MutationTree<PostbackState> = {
  [SET_STATISTIC_POSTBACKS]: (state, postbacks: Postbacks): void => {
    state.postbacks = postbacks;
  }
};

const actions: ActionTree<PostbackState, RootState> = {
  async [GET_STATISTIC_POSTBACKS] ({ commit }, payload) {
    try {
      const { data } = await Axios.get("/webmaster/postbacks/queue", { params: payload });
      data.items = data.items.map((detail: any) => new StatisticPostback(detail));
      commit(SET_STATISTIC_POSTBACKS, data);
    } catch (error) {
      throw error;
    }
  }
};

export const postbacks: Module<PostbackState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
