<template>
    <div>
        <slot name="title">
            <div
                v-if="title"
                class="mb-1 ml-2 flex">
                <span class="text-sm text-blue-text">{{ title }}</span>
            </div>
        </slot>
        <div
            class="w-full"
            :class="customClass">
            <slot
                :value="value"
                :update="update">
                <el-input
                    v-bind="$attrs"
                    :value="value"
                    :rows="rows"
                    :clearable="clearable"
                    :placeholder="placeholder"
                    :type="type"
                    :class="{ 'border': bordered, 'is-password': showPassword }"
                    :show-password="showPassword"
                    :readonly="readonly"
                    :autocomplete="autocomplete"
                    v-on="$listeners"
                    @input="update">
                    <CopyButton
                        v-if="canCopy"
                        slot="suffix"
                        :title="canCopyTitle"
                        :copy-button-class="canCopyClass"
                        :value="value">
                    </CopyButton>
                    <template #append>
                        <slot name="append"></slot>
                    </template>
                    <template #prepend>
                        <slot name="prepend"></slot>
                    </template>
                    <!--                <el-button-->
                    <!--                    v-if="$slots['appendButton']"-->
                    <!--                    slot="append">-->
                    <!--                    <slot name="appendButton"></slot>-->
                    <!--                </el-button>-->
                </el-input>
            </slot>
        </div>
    </div>
</template>

<script>
  import CopyButton from "@/components/Common/CopyButton";
    
  export default {
    name: "FInput",

    components: {
      CopyButton
    },

    props: {
      value: {
        type: null,
        required: true
      },
      placeholder: {
        type: String,
        default: null
      },
      type: {
        type: String,
        default: null
      },
      showPassword: {
        type: Boolean,
        default: false
      },
      clearable: {
        type: Boolean,
        default: true
      },
      readonly: {
        type: Boolean,
        default: false
      },
      title: {
        type: String,
        default: null
      },
      bordered: {
        type: Boolean,
        default: true
      },
      rows: {
        type: Number,
        default: 2
      },

      canCopy: {
        type: Boolean,
        default: false
      },

      canCopyTitle: {
        type: String,
        default: null
      },

      canCopyClass: {
        type: String,
        default: "text-red-500 hover:text-red-500 focus:text-red-500"
      },

      customClass : {
        type: String,
        default: null
      },
      autocomplete: {
        type: String,
        default: "off",
        validator: (value) => ["off", "on"].indexOf(value) !== -1
      }
    },
        
    methods: {
      update (value) {
        this.$emit("input", value);
      }
    }
  };
</script>

<style lang="scss" scoped>
    ::v-deep {
        .border {
            border: 1px solid #dee3ec;
            border-radius: 4px;
        }
        .el-input__inner {
            border: none;
        }

        .el-icon-view {
            &:before {
                font-family: 'Font Awesome\ 5 Free', serif;
                content: "\f06e";
            }
        }

        .is-password {
            .el-input__inner[type="text"] + .el-input__suffix {
                .el-icon-view {
                    &:before {
                        font-family: 'Font Awesome\ 5 Free', serif;
                        content: "\f070";
                    }
                }
            }
        }
    
        .el-input-group__append {
            border: none;
            background-color: white;
        }

        .el-input-group__prepend {
            border: none;
        }

        .el-input__suffix {
            display: flex;
            align-items: center;
        }
    }
</style>