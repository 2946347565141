import { AxiosResponse } from "axios";
import { GetIncomesParams } from "@core/store/types/admin/finance/finance";
import { GraphQLServer } from "@core/plugins/axios";
import inTransactions from "@core/services/admin/finance/graphql/inTransactions.graphql";

export class FinanceService {
  public static getIncomes (params: GetIncomesParams): Promise<AxiosResponse<any>> {
    return GraphQLServer.get("", {
      params: {
        query: inTransactions,
        variables: {
          ...params
        }
      }
    });
  }
}
