import { EfficiencyPlansEditService } from "@core/services/admin/efficiency/EfficiencyPlansEditService";
import { ADD_PLAN } from "@core/store/action-constants";
import {
  SET_EMPTY,
  SET_MODAL_ACTIVE,
  SET_PLANS_EDIT_MANAGER_ID,
  UPDATE_PLANS_EDIT_FORM
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { PlansEditInput, PlansEditState } from "@core/store/types/admin/efficiency/efficiencyPlansEdit";
import { ActionTree, Module, MutationTree } from "vuex";

const initialState = (): PlansEditState => {
  return {
    managerId: null,
    isModalActive: false,
    form: {
      plan: null,
      applyDate: null
    }
  };
};

const state: () => PlansEditState = initialState;

const mutations: MutationTree<PlansEditState> = {
  [SET_EMPTY]: state => Object.assign(state, initialState()),

  [SET_MODAL_ACTIVE] (state, value) {
    state.isModalActive = value;
  },

  [SET_PLANS_EDIT_MANAGER_ID] (state, managerId) {
    state.managerId = managerId;
  },

  [UPDATE_PLANS_EDIT_FORM] (state, form) {
    state.form = { ...state.form, ...form };
  }
};

const actions: ActionTree<PlansEditState, RootState> = {
  async [ADD_PLAN] ({ state: { form, managerId } }) {
    await EfficiencyPlansEditService.addPlan(managerId as string, form as PlansEditInput);
  },

  [UPDATE_PLANS_EDIT_FORM] ({ commit }, form) {
    commit(UPDATE_PLANS_EDIT_FORM, form);
  }
};

export const efficiencyPlansEdit: Module<PlansEditState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions
};
