import { Module } from "vuex";
import { RootState } from "@core/store/root-state";
import { advertisers } from "./advertisers";
import { webmasters } from "./webmasters";
import { administrators } from "./administrators";
import { adminsList } from "@core/store/modules/admin/lists/adminsList";

export const users: Module<any, RootState> = {
  namespaced: true,
  modules: {
    advertisers,
    webmasters,
    administrators,
    adminsList
  }
};
