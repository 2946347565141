import { ActionTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import { FETCH_USER, EDIT_USER, SET_EMPTY } from "@core/store/action-constants";
import { ProfileService } from "@core/services/admin/profile/ProfileService";
import {
  SAVE_USER,
  UPDATE_ADMIN_PROFILE_FORM,
  UPDATE_MODAL
} from "@core/store/mutation-constants";
import { ProfileState } from "@core/store/types/admin/profile";
import { password } from "./modules/password";
import { currencyList } from "@core/store/modules/common/lists/currencyList";

const initialState = (): ProfileState => {
  return {
    user: null,
    form: {},
    modal: {
      isActive: false
    }
  };
};

const state: () => ProfileState = initialState;

const mutations: MutationTree<ProfileState> = {
  [SAVE_USER] (state, user) {
    state.user = user;
  },

  [UPDATE_ADMIN_PROFILE_FORM] (state, form) {
    state.form = { ...state.form, ...form };
  },

  [UPDATE_MODAL] (state, modal) {
    state.modal = { ...state.modal , ...modal };
  },

  [SET_EMPTY] (state) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { user, ...emptyFields } = initialState();    
    Object.assign(state, emptyFields);
  }
};

const actions: ActionTree<ProfileState, RootState> = {
  async [FETCH_USER] ({ commit }) {
    const { data: { profile } } = await ProfileService.getProfile();
    commit(SAVE_USER, profile);
  
    return profile;
  },
  
  async [EDIT_USER] ({ commit, state: { form } }) {
    const { data: { editAdminProfile } } = await ProfileService.editProfile(form);
    commit(SAVE_USER, editAdminProfile);
  },

  [UPDATE_MODAL] ({ commit }, modal) {
    commit(UPDATE_MODAL, modal);
  }
};

export const profile: Module<ProfileState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    currencyList,
    password
  }
};
