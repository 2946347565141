import Service from "@core/services/common/Service";

import { CorrectionCreateState } from "@core/store/types/admin/finances/financesCorrection/FinancesCorrectionCreateState";
import createFinanceTransaction from "./graphql/createFinanceTransaction.graphql";

export class FinancesCorrectionCreateService extends Service {

  public static async createFinanceTransaction (options: CorrectionCreateState["options"]): Promise<string>{
    try {
      return await this.api.post("", {
        query: createFinanceTransaction,
        variables: { input: { ...options } }
      });
    } catch (e) {
      throw e;
    }
  }
}
