import Vue from "vue";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

export default Sentry.init.bind(window, {
  Vue,
  environment: process.env.NODE_ENV,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  attachProps: true,
  logErrors: process.env.NODE_ENV !== "development",
  integrations: [
    new Integrations.BrowserTracing()
  ],
  tracingOptions: {
    trackComponents: true
  }
});
