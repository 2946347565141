<template>
    <ColoredStatus
        :tooltip="tooltip"
        :tooltip-position="tooltipPosition"
        :tooltip-type="type"
        position="after">
        <slot></slot>
        <template #after="{ active, label, position, type }">
            <LTooltip
                :max-width="maxWidth"
                :flip-behavior="flipBehavior"
                :active="active"
                :label="label"
                :position="position"
                :type="type">
                <FIcon
                    :type="type"
                    class="is-clickable is-marginless"
                    icon="question-circle"
                    pack="far">
                </FIcon>
            </LTooltip>
        </template>
    </ColoredStatus>
</template>

<script>
  import ColoredStatus from "@/components/Common/ColoredStatus";
  import FIcon from "@/components/Common/FIcon";
  
  export default {
    name: "LabelWithAnnotation",
    components: { FIcon, ColoredStatus },
    props: {
      tooltip: {
        type: String,
        required: true
      },
      
      type: {
        type: String,
        default: "info"
      },
  
      tooltipPosition: {
        type: String,
        default: "top"
      },

      maxWidth: {
        type: [Number, String],
        default: 350
      },

      flipBehavior: {
        type: [String, Array],
        default: "flip"
      }
    }
  };
</script>

<style scoped lang="scss">
    ::v-deep {
        .content {
            vertical-align: baseline;
        }
    }
</style>
