<template>
    <router-link
        :to="{ name: 'main' }"
        class="logo flex items-center">
        <img
            alt="Fairpay"
            src="@/assets/logo.svg">
        <span
            class="tablet:text-base text-sm laptop:text-xl text-blue-text font-bold break-words pr-2">FAIRPAY PARTNERS</span>
    </router-link>
</template>

<script>
  export default {
    name: "Logo",
    props: {
      isCollapsed: {
        type: Boolean,
        default: false
      }
    }
  };
</script>

<style scoped lang="scss">
</style>
