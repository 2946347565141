import i18n from "@core/plugins/i18n";
import { GET_EMAIL_CONFIRMED } from "@core/store/action-constants";
import { UPDATE_EMAIL_CONFIRMED_LIST } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { EmailConfirmedListState } from "@core/store/types/admin/lists/EmailConfirmedListState";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

const initialState = (): EmailConfirmedListState => {
  return {
    data: {}
  };
};

const state: () => EmailConfirmedListState = initialState;

const getters: GetterTree<EmailConfirmedListState, RootState> = {};

const mutations: MutationTree<EmailConfirmedListState> = {
  [UPDATE_EMAIL_CONFIRMED_LIST] (state, emailConfirmed: EmailConfirmedListState["data"]) {
    state.data = emailConfirmed;
  }
};

const actions: ActionTree<EmailConfirmedListState, RootState> = {
  [GET_EMAIL_CONFIRMED] ({ commit }) {
    const items = [
      {
        label: i18n.t("admin.users.webmasters.filters.values.emailConfirmed"),
        value: true
      },
      {
        label: i18n.t("admin.users.webmasters.filters.values.emailUnConfirmed"),
        value: false
      }
    ];
    commit(UPDATE_EMAIL_CONFIRMED_LIST, {
      items,
      count: items.length
    });
  }
};

export const emailConfirmedList: Module<EmailConfirmedListState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
