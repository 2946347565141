import { AxiosPromise } from "axios";
import Service from "@core/services/common/Service";
import profile from "./graphql/profile.graphql";
import editAdminProfile from "./graphql/editAdminProfile.graphql";
import {
  ProfileResponse,
  ProfileEditInput,
  ProfileEditResponse
} from "@core/store/types/admin/profile";

export class ProfileService extends Service {
  public static getProfile (): AxiosPromise<ProfileResponse> {
    return this.api.get("", {
      params: {
        query: profile
      }
    });
  }

  public static editProfile (input: ProfileEditInput): AxiosPromise<ProfileEditResponse> {
    return this.api.post("", {
      query: editAdminProfile,
      variables: {
        input
      }
    });
  }
}
