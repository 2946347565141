import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import { StatisticService } from "@core/logic/advertiser/statistic/statistic";
import { SearchParams } from "@core/logic/statistics/params";
import { AdvertiserStatisticByGroups } from "@core/logic/advertiser/statistic/AdvertiserStatisticByGroups";
import { GET_STATISTIC } from "@core/store/action-constants";
import { SET_STATISTIC } from "@core/store/mutation-constants";

export interface StatisticState {
  statistics?: AdvertiserStatisticByGroups[] | null;
}

const state: StatisticState = {
  statistics: null
};

const getters: GetterTree<StatisticState, RootState> = {};

const mutations: MutationTree<StatisticState> = {
  [SET_STATISTIC] (state, statistics: AdvertiserStatisticByGroups[]) {
    state.statistics = statistics;
  }
};

const actions: ActionTree<StatisticState, RootState> = {
  async [GET_STATISTIC] ({ commit }, params: SearchParams): Promise<void> {
    const { filters, groups } = params;
    const { data: { statistics } } = await StatisticService.getAdvertiserStatistic(filters, groups);
    commit(SET_STATISTIC, statistics);
  }
};

export const statistic: Module<StatisticState, RootState> = {
  state,
  getters,
  actions,
  mutations
};
