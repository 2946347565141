import { GET_STRATEGY } from "@core/store/action-constants";
import { UPDATE_STRATEGY_LIST } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { StrategyListState } from "@core/store/types/admin/crm/lists/StrategyListState";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

const initialState = (): StrategyListState => {
  return {
    data: {}
  };
};

const state: () => StrategyListState = initialState;

const getters: GetterTree<StrategyListState, RootState> = {};

const mutations: MutationTree<StrategyListState> = {
  [UPDATE_STRATEGY_LIST] (state, strategy: StrategyListState["data"]) {
    state.data = strategy;
  }
};

const actions: ActionTree<StrategyListState, RootState> = {
  [GET_STRATEGY] ({ commit }) {
    const items = [
      {
        label: "tokenAndRemoteId",
        value: "TOKEN_AND_REMOTE_ID"
      },
      {
        label: "leadIdAndCrm",
        value: "LEAD_ID_AND_CRM"
      },
      {
        label: "sale",
        value: "SALE"
      }
    ];
    commit(UPDATE_STRATEGY_LIST, {
      items,
      count: items.length
    });
  }
};

export const strategyList: Module<StrategyListState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
