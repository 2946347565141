import i18n from "@core/plugins/i18n";
import { GET_PARTNER_NETWORK } from "@core/store/action-constants";
import { UPDATE_PARTNER_NETWORK_LIST } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { PartnerNetworkListState } from "@core/store/types/admin/lists/PartnerNetworkListState";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

const initialState = (): PartnerNetworkListState => {
  return {
    data: {}
  };
};

const state: () => PartnerNetworkListState = initialState;

const getters: GetterTree<PartnerNetworkListState, RootState> = {};

const mutations: MutationTree<PartnerNetworkListState> = {
  [UPDATE_PARTNER_NETWORK_LIST] (state, partnerNetwork: PartnerNetworkListState["data"]) {
    state.data = partnerNetwork;
  }
};

const actions: ActionTree<PartnerNetworkListState, RootState> = {
  [GET_PARTNER_NETWORK] ({ commit }) {
    const items = [
      {
        label: i18n.t("admin.users.webmasters.filters.values.isPartnerNetwork"),
        value: true
      },
      {
        label: i18n.t("admin.users.webmasters.filters.values.isNotPartnerNetwork"),
        value: false
      }
    ];
    commit(UPDATE_PARTNER_NETWORK_LIST, {
      items,
      count: items.length
    });
  }
};

export const partnerNetworkList: Module<PartnerNetworkListState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
