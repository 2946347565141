export default [
  {
    path: "finances",
    name: "finances",
    redirect: { name: "admin:finances:costs" },
    component: (): Promise<any> => import(/* webpackChunkName: "admin:finances" */ "@/views/Admin/FinancesView.vue"),
    children: [
      {
        path: "costs",
        name: "costs",
        component: (): Promise<any> =>
          import(/* webpackChunkName: "admin:finances:costs" */ "@/components/Admin/Finances/FinancesCosts.vue")
      },
      {
        path: "incomes",
        name: "incomes",
        component: (): Promise<any> =>
          import(/* webpackChunkName: "admin:finances:incomes" */ "@/components/Admin/Finances/FinancesIncomes.vue")
      },
      {
        path: "commissions",
        name: "commissions",
        component: (): Promise<any> =>
          import(/* webpackChunkName: "admin:finances:commissions" */ "@/components/Admin/Finances/FinancesCommissions.vue")
      },
      {
        path: "correction",
        name: "correction",
        component: (): Promise<any> =>
          import(/* webpackChunkName: "admin:finances:correction" */ "@/components/Admin/Finances/FinancesCorrection.vue")
      }
    ]
  }
];
