import { Login, LoginAsAdvertiserResponse, LoginAsWebmasterResponse } from "@core/logic/auth/service";
import Service from "@core/services/common/Service";
import { AxiosPromise } from "axios";
import authentication from "./graphql/authentication.graphql";
import loginAsAdvertiser from "./graphql/loginAsAdvertiser.graphql";
import loginAsWebmaster from "./graphql/loginAsWebmaster.graphql";
import refreshToken from "./graphql/refreshToken.graphql";
import whoAmI from "./graphql/whoAmI.graphql";

export class AuthenticationService extends Service {
  public static login (data: Login): AxiosPromise {
    const {
      login,
      password
    } = data;
  
    return this.api.post("", {
      query: authentication,
      variables: {
        login,
        password
      }
    });
  }
  
  public static whoAmI (): AxiosPromise {
    return this.api.get("", {
      params: {
        query: whoAmI
      }
    });
  }
  
  public static refreshToken (token: string): AxiosPromise {
    return this.api.post("", {
      query: refreshToken,
      variables: { refreshToken: token }
    });
  }

  public static loginAsWebmaster (webmasterId: string): AxiosPromise<LoginAsWebmasterResponse> {
    try {
      return this.api.post("", {
        query: loginAsWebmaster,
        variables: { webmasterId }
      });
    } catch (e) {
      throw e;
    }
  }

  public static loginAsAdvertiser (advertiserId: string): AxiosPromise<LoginAsAdvertiserResponse> {
    try {
      return this.api.post("", {
        query: loginAsAdvertiser,
        variables: { advertiserId }
      });
    } catch (e) {
      throw e;
    }
  }
}
