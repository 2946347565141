import Service from "@core/services/common/Service";
import analytic from "./graphql/analytic.graphql";
import { AnalyticFilters, AnalyticResponse } from "@core/store/types/admin/analytic/analytic";

export class AnalyticService extends Service {
  /**
   * TODO: Добавить описание
   * @param {number} limit
   * @param {number} offset
   * @param filters
   * @param mainGroup
   * @param additionalGroup
   * @return {Promise<AnalyticResponse>}
   */
  public static async getAnalytic (
    limit: number,
    offset: number,
    filters: AnalyticFilters,
    mainGroup: string,
    additionalGroup: string
  ): Promise<AnalyticResponse> {
    try {
      return await this.api.get("", {
        params: {
          query: analytic,
          variables: {
            limit,
            offset,
            filters,
            mainGroup,
            additionalGroup
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
}
