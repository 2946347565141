import Vue from "vue";
import { hasPermissions } from "./permissions";

Vue.mixin({
  beforeCreate: function () {
    const role = (this.$store || { getters: {} }).getters.role;
    const componentPermissions = (this.$options.propsData || {}).permissions || this.$options.permissions;
    const userPermissions = (this.$store || { getters: {} }).getters.permissions;
    
    if (role === "admin" && !hasPermissions(componentPermissions, userPermissions)) {
      this.$options = {
        render: () => {
        }
      };
      
      this.$on("hook:created", () => {
        this.$destroy();
      });
    }
  },
  
  methods: {
    toastFail (t, err) {
      this.$buefy.toast.open({
        duration: 5000,
        message: `${ t } <br><br> <b>${ err?.message ?? err }</b>`,
        type: "is-danger"
      });
    },
    
    toastSuccess (t) {
      this.$buefy.toast.open({
        message: t,
        type: "is-success"
      });
    }
  }
});
