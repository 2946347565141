import { CRMListService } from "@core/services/admin/lists/CRMListService";
import { GET_CRM_LIST, UPDATE_CRM_LIST } from "@core/store/action-constants";
import { EMPTY_CRM_LIST, UPDATE_CRM_FILTERS, UPDATE_CRM_LIST_PAGINATION } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { CRMListResponse, PartialCRMListState } from "@core/store/types/admin/lists/CRMListState";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

const initialState = (): PartialCRMListState => {
  return {
    data: {},
    filters: {
      name: null,
      alias: null
    },
    pagination: {
      page: 1,
      perPage: 10
    }
  };
};

const state: () => PartialCRMListState = initialState;

const getters: GetterTree<PartialCRMListState, RootState> = {};

const mutations: MutationTree<PartialCRMListState> = {
  SET_EMPTY: state => Object.assign(state, initialState()),

  [UPDATE_CRM_LIST] (state, CRM: CRMListResponse["crms"]) {
    if (state.pagination.page !== 1 && state.data?.items && CRM) {
      state.data.items = Array.from(new Set([...state.data.items, ...CRM.items as []]));
    } else {
      state.data = CRM;
    }
  },

  [UPDATE_CRM_LIST_PAGINATION] (state) {
    if (state.data?.items && state.data.items.length >= state.pagination.perPage) {
      state.pagination.page = Math.ceil(state.data.items.length / state.pagination.perPage) + 1;
    }
  },

  [UPDATE_CRM_FILTERS] (state, filters: PartialCRMListState["filters"]) {
    if (filters?.name !== state.filters?.name) {
      state.pagination.page = 1;
    }
    state.filters = { ...state.filters, ...filters };
  },

  [EMPTY_CRM_LIST] (state) {
    Object.assign(state, initialState());
  }
};

const actions: ActionTree<PartialCRMListState, RootState> = {
  async [GET_CRM_LIST] ({ commit, state }) {
    const limit = state.pagination.perPage;
    const offset = (state.pagination.page - 1) * limit;

    const { data: { crms } } =
            await CRMListService.getCRMList({ name: state.filters?.name }, limit, offset);

    commit(UPDATE_CRM_LIST, crms);
    return crms;
  }

  // async [GET_INITIAL_CRM] ({ commit, state }, alias) {
  //     if (alias) {
  //         alias = Array.isArray(alias) ? alias : [alias];
  //     }
  //     const { data: { crms, initialCRM } } = await CRMListService.getCRMList(
  //         {},
  //         state.pagination.perPage,
  //         0,
  //         { alias: alias });
  //     crms.items = [...initialCRM.items, ...crms.items as []];
  //
  //     commit(UPDATE_CRM_LIST, crms);
  //     return initialCRM?.items?.length ? initialCRM?.items.map((CRM: CRMListItem) => CRM.alias) : null;
  // }
};

export const CRMList: Module<PartialCRMListState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
