<template>
    <el-menu
        :default-active="asideActive"
        class="bg-gray-background border-0"
        :collapse="false">
        <template v-for="(item, index) in menu">
            <AsideMenuItem
                :key="index"
                :is-mobile-menu-open.sync="_isMobileMenuOpen"
                :item="item"
                :index="index"></AsideMenuItem>
        </template>
    </el-menu>
</template>

<script>
  import { mapGetters } from "vuex";
  import AsideMenuItem from "@/components/Common/AsideMenuItem";
  import main from "@/assets/AsideMenu/main.svg";
  import offers from "@/assets/AsideMenu/offers.svg";
  import statistics from "@/assets/AsideMenu/statistics.svg";
  import flows from "@/assets/AsideMenu/flows.svg";
  import domains from "@/assets/AsideMenu/domains.svg";
  import payments from "@/assets/AsideMenu/payments.svg";
  
  export default {
    name: "AsideMenu",
    components: {
      AsideMenuItem
    },
    props: {
      isMobileMenuOpen: {
        type: Boolean,
        default: false
      }
    },
        
    // mounted () {
    //   window.onresize = () => {
    //     this.windowWidth = window.innerWidth;
    //   };
    // },
    data () {
      return {
        // windowWidth: window.innerWidth,
        isCollapsed: true
      };
    },
    computed: {
      ...mapGetters([
        "user",
        "role"
      ]),
            
      _isMobileMenuOpen: {
        get () {
          return this.isMobileMenuOpen;
        },
        set (value) {
          this.$emit("update:isMobileMenuOpen", value);
        }
      },

      menu () {
        return [
          {
            label: `${ this.$t("webmaster.menu.dashboard") }`,
            icon: main,
            to: { name: "dashboard" }
          },
          {
            label: `${ this.$t("webmaster.menu.offers") }`,
            icon: offers,
            to: { name: "webmaster:offers" }
          },
          {
            label: `${ this.$t("webmaster.menu.statistics") }`,
            icon: statistics,
            to: { name: "webmaster:statistics" }
          },
          {
            label: `${ this.$t("webmaster.menu.flows") }`,
            icon: flows,
            to: { name: "webmaster:flows" }
          },
          {
            label: `${ this.$t("webmaster.menu.finances") }`,
            icon: payments,
            to: { name: "webmaster:finances" }
          },
          {
            label: `${ this.$t("webmaster.menu.domains") }`,
            icon: domains,
            to: { name: "webmaster:domains" }
          }
          // {
          //   label: "Помощь",
          //   icon: help
          // }
        ];
      },
      // isTab () {
      //   return this.windowWidth < 1280;
      // },
      asideActive () {
        const path = this.$route.path?.split("/");
        return path[2] ?? path[1];
      }
    },
    watch: {
      isCollapsed (value) {
        this.$emit("isCollapsed", value);
      }
    }
  };
</script>

<style lang="scss" scoped>
    aside {
        top: 0;
        left: 0;
        bottom: 0;
        height: 100vh;
        display: flex;
        padding: 0 1rem;
        z-index: 30;
        position: fixed;
        flex-direction: column;
        background: inherit;
        @media screen and (max-width: 768px) {
            display: none;
        }
        @media screen and (max-width: 1280px) {
            width: 4rem;
            padding: 0 7px;
            ::v-deep {
                ul {
                    li {
                        .label {
                            display: none;
                        }
                    }
                }
                .logo {
                    span {
                        display: none;
                    }
                }
                .el-card__body {
                    .managerLogo {
                        display: flex;
                    }
                    .manager {
                        display: none;
                    }
                }
                
            }
        }
    }
    .menuOpen {
        @media screen and (max-width: 768px) {
            display: flex;
        }
        @media screen and (max-width: 1280px) {
            width: 21rem;
            padding: 0 1rem;
            ::v-deep {
                ul {
                    li {
                        .label {
                            display: flex;
                        }
                    }
                }
                .logo {
                    span {
                        display: flex;
                    }
                }
                .el-card__body {
                    .managerLogo {
                        display: none;
                    }
                    .manager {
                        display: flex;
                    }
                }
            }
        }
    }
    .el-menu--collapse {
        width: 50px;
    }
</style>
