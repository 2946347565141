import { webmastersList } from "@core/store/modules/admin/lists/webmastersList";
import { SET_EMPTY, UPDATE_ADVERTISER_DETAIL_DATA } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { AdvertisersDetailState } from "@core/store/types/admin/users/advertisers/Detail";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { rates } from "./modules/rates";

const initialState = (): AdvertisersDetailState => {
  return {
    data: {
      advertiserId: null
    }
  };
};

const state: () => AdvertisersDetailState = initialState;

const getters: GetterTree<AdvertisersDetailState, RootState> = {
  advertiserId: (state) => state.data.advertiserId
};

const mutations: MutationTree<AdvertisersDetailState> = {
  [UPDATE_ADVERTISER_DETAIL_DATA] (state, data) {
    state.data = { ...state.data, ...data };
  },
  
  [SET_EMPTY] (state) {
    Object.assign(state, initialState());
  }
};

const actions: ActionTree<AdvertisersDetailState, RootState> = {
  [UPDATE_ADVERTISER_DETAIL_DATA] ({ commit }, data) {
    commit(UPDATE_ADVERTISER_DETAIL_DATA, data);
  }
};

export const detail: Module<AdvertisersDetailState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules: {
    rates,
    rateWebmastersList: webmastersList
  }
};
