<template>
    <el-container>
        <el-button
            type="text"
            class="mr-2"
            :class="copyButtonClass"
            @click.prevent="click">
            <div class="flex justify-center w-max">
                <FSvgIcon icon="copy">
                </FSvgIcon>
                <span
                    v-if="title"
                    class="mx-2">
                    {{ title }}
                </span>
            </div>
        </el-button>
        <slot></slot>
    </el-container>
</template>

<script>
  import FSvgIcon from "@/components/Common/FSvgIcon";

  export default {
    name: "CopyButton",
    components: { FSvgIcon },

    props: {
      title: {
        type: String,
        default: null
      },

      copyButtonClass: {
        type: String,
        default: "text-red-500 hover:text-red-500 focus:text-red-500"
      }
    },

    methods: {
      click () {
        if (!this.preventCopy) {
          navigator.clipboard.writeText(`${ this.$attrs.value }`);
          this.$message({
            message: this.$t("common.components.copyButton.messages.success"),
            type: "success"
          });
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
    .el-button {
        padding: 0;
    }
</style>
