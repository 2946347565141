import { Module } from "@core/store/logic/Module";
import { auth } from "@core/store/modules/common/auth";
import { adblock } from "../../../store/modules/common/adblock";
import { balance } from "../../../store/modules/common/balance-widget";
import { dictionaries } from "../../../store/modules/common/dictionaries";
import { error } from "../../../store/modules/common/error";
import { initialization } from "../../../store/modules/common/initialization";
import { loading } from "../../../store/modules/common/loading";
import { locale } from "../../../store/modules/common/locale";
import { password } from "../../../store/modules/common/password";
import { success } from "../../../store/modules/common/success";
import { trafficTypes } from "../../../store/modules/common/trafficTypes";
import { uploads } from "../../../store/modules/common/uploads/uploads";
import { categories } from "@core/store/modules/common/categories/categories";
import { badges } from "@core/store/modules/common/badges";
import { currencyList } from "@core/store/modules/common/lists/currencyList";

export class CoreStore<S, R> extends Module<S, R> {
  constructor () {
    super();
  
    this.modules({
      auth,
      adblock,
      locale,
      success,
      initialization,
      error,
      loading,
      balance,
      dictionaries,
      password,
      uploads,
      trafficTypes,
      categories,
      badges,
      currencyList
    });
  }
}