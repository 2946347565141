import { FlowsService } from "@core/services/webmaster/flows/FlowsService";
import {
  CREATE_BASE_LINK,
  GET_FLOWS,
  GET_FLOWS_WITH_DOMAINS,
  GET_DOMAINS_BY_NAME
} from "@core/store/action-constants";
import {
  SET_FLOWS,
  UPDATE_PAGINATION,
  UPDATE_FLOWS_DOMAINS,
  UPDATE_FLOWS_FILTERS,
  SET_DOMAINS_BY_NAME
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { Flow, FlowsState } from "@core/store/types/webmaster/flows";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { offersList } from "@core/store/modules/admin/lists/offersList";
import { flowsEditLinkModal } from "@core/store/modules/webmaster/flows/modules/flowsEditLinkModal";
import { DomainsService } from "@core/store/logic/webmaster/domains/domainsService";
import Vue from "vue";

const initialState = (): FlowsState => {
  return {
    flows: null,
    pagination: {
      perPage: 25,
      page: 1
    },
    filters: {
      offerId: null,
      name: null
    }
  };
};

const state: () => FlowsState = initialState;

const getters: GetterTree<FlowsState, RootState> = {
  [CREATE_BASE_LINK] () {
    return ({ id, options, isHttps }: Flow): URL => {
      const protocol = isHttps ? "https://" : "http://";
      const { webmasterDomain: domain } = options;
      let url;
      
      if (domain) {
        url = new URL("/", `${ protocol + domain }`);
      } else {
        url = new URL("/", process.env.VUE_APP_FLOWS_URL);
      }
      
      url.searchParams.append("flow", window.btoa(id));
      
      return url;
    };
  }
};

const mutations: MutationTree<FlowsState> = {
  [SET_FLOWS] (state, flows): void {
    state.flows = flows;
  },
  
  [UPDATE_PAGINATION] (state, pagination): void {
    state.pagination = { ...state.pagination, ...pagination };
  },

  [UPDATE_FLOWS_FILTERS] (state, filters): void {
    state.filters = { ...state.filters, ...filters };
  },

  [SET_DOMAINS_BY_NAME] (state, { isHttps, id }): void {
    if (state.flows && state.flows.items) {
      const flowsIndex = state.flows.items.findIndex((flows: Flow) => flows.id === id);
      Vue.set(state.flows.items, flowsIndex, { ...state.flows.items[flowsIndex], isHttps });
    }
  }
};

const actions: ActionTree<FlowsState, RootState> = {
  async [GET_FLOWS] ({ commit, state }) {
    const {
      pagination: {
        perPage: limit,
        page
      }
    } = state;
    const offset = (page - 1) * limit;
    const {
      offerId,
      name
    } = state.filters;


    const {
      data: {
        landingFlows
      }
    } = await FlowsService.getFlows(
      limit,
      offset,
      {
        offerId,
        name
      });
  
    commit(SET_FLOWS, landingFlows);
  
    return landingFlows;
  },
  
  async [GET_FLOWS_WITH_DOMAINS] ({ dispatch }) {
    await dispatch(GET_FLOWS);
    dispatch(UPDATE_FLOWS_DOMAINS);
  },
  
  [UPDATE_FLOWS_DOMAINS] ({ state, dispatch }) {
    state.flows?.items?.forEach((item: Flow) => {
      const webmasterDomain = item.options?.webmasterDomain;
      if (webmasterDomain) {
        dispatch(GET_DOMAINS_BY_NAME, { webmasterDomain, id: item.id });
      }
    });
  },

  async [GET_DOMAINS_BY_NAME] ({ commit }, { name, id }) {
    const filters = { name };
    const { data: { landingDomains } } = await DomainsService.getDomains(filters, 25, 0);
    const isHttps = landingDomains.items?.[0]?.isHttps;
    
    commit(SET_DOMAINS_BY_NAME, { isHttps, id });
  },

  [UPDATE_PAGINATION] ({ commit }, pagination) {
    commit(UPDATE_PAGINATION, pagination);
  },

  [UPDATE_FLOWS_FILTERS] ({ commit }, filters): void {
    commit(UPDATE_FLOWS_FILTERS, filters);
    commit(UPDATE_PAGINATION, { page: 1 });
  }
};
export const flows: Module<FlowsState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    offersList,
    flowsEditLinkModal
  }
};
