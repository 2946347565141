import Service from "@core/services/common/Service";
import competitions from "@core/services/admin/competition/grapgql/competitions.graphql";
import editCompetition from "@core/services/admin/competition/grapgql/editCompetition.graphql";
import planCompetition from "@core/services/admin/competition/grapgql/planCompetition.graphql";
import draftCompetition from "@core/services/admin/competition/grapgql/draftCompetition.graphql";
import createCompetition from "@core/services/admin/competition/grapgql/createCompetition.graphql";
import deleteCompetition from "@core/services/admin/competition/grapgql/deleteCompetition.graphql";
import competitionParticipants from "@core/services/admin/competition/grapgql/competitionParticipants.graphql";
import { CompetitionFilters, CompetitionModalState } from "@core/store/types/admin/competition";
import { AxiosPromise } from "axios";

export class CompetitionService extends Service {
  public static async getCompetitions (
    limit: number,
    offset: number,
    filters: CompetitionFilters
  ): Promise<AxiosPromise> {
    try {
      return await this.api.get("", {
        params: {
          query: competitions,
          variables: {
            limit,
            offset,
            filters
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static async createCompetition (input: CompetitionModalState["form"]): Promise<AxiosPromise> {
    try {
      return await this.api.get("", {
        params: {
          query: createCompetition,
          variables: { input }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static async editCompetition (competitionId: string, input: CompetitionModalState["form"]): Promise<AxiosPromise> {
    try {
      return await this.api.get("", {
        params: {
          query: editCompetition,
          variables: {
            competitionId,
            input
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static async draftCompetitions (competitionId: string): Promise<AxiosPromise> {
    try {
      return await this.api.get("", {
        params: {
          query: draftCompetition,
          variables: { competitionId }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static async planCompetitions (competitionId: string): Promise<AxiosPromise> {
    try {
      return await this.api.get("", {
        params: {
          query: planCompetition,
          variables: { competitionId }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static async deleteCompetitions (competitionId: string): Promise<AxiosPromise> {
    try {
      return await this.api.get("", {
        params: {
          query: deleteCompetition,
          variables: { competitionId }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static async detailCompetitions (
    limit: number,
    offset: number,
    competitionId: string
  ): Promise<AxiosPromise> {
    try {
      return await this.api.get("", {
        params: {
          query: competitionParticipants,
          variables: {
            limit,
            offset,
            competitionId
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
}