import moment from "moment";
import { ActionTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import { StatisticService } from "@core/logic/webmaster/statistic/statistic";
import { WebmasterStatistic } from "@core/store/types/webmaster/dashboard";
import {
  SET_OLD_STATISTIC,
  SET_NEW_STATISTIC,
  UPDATE_FILTERS,
  SET_EMPTY,
  UPDATE_CURRENCY_LIST
} from "@core/store/mutation-constants";
import {
  GET_STATISTIC,
  GET_OLD_STATISTIC,
  GET_CURRENCY
} from "@core/store/action-constants";
import { sortByPreferredCurrency } from "@core/helpers/sortByPreferredCurrency";
import { TodayStatistic } from "@core/store/types/webmaster/statistic";
import { StatisticItems } from "@core/store/types/common/statistic/common/statistic";

const initialState = (): WebmasterStatistic => {
  return {
    filters: {
      datepicker: {
        dateStart: moment().startOf("day").toDate(),
        dateEnd: moment().endOf("day").toDate()
      }
    },
    oldStatistic: {
      clicks: [],
      leads: [],
      confirmed: [],
      income: []
    },
    newStatistic: {
      clicks: [],
      leads: [],
      confirmed: [],
      income: []
    },
    currencies: []
  };
};

const state: () => WebmasterStatistic = initialState;

const mutations: MutationTree<WebmasterStatistic> = {
  [SET_EMPTY]: state => Object.assign(state, initialState()),
    
  [UPDATE_FILTERS] (state, filters) {
    state.filters = { ...state.filters, ...filters };
  },
    
  [SET_OLD_STATISTIC] (state, oldStatistic) {
    state.oldStatistic = oldStatistic;
  },
    
  [SET_NEW_STATISTIC] (state, newStatistic) {
    state.newStatistic = newStatistic;
  },
  
  [UPDATE_CURRENCY_LIST] (state, payload) {
    state.currencies = payload;
  }
};

const actions: ActionTree<WebmasterStatistic, RootState> = {
  async [GET_STATISTIC] ({ state, commit, dispatch }) {
    const { data: { statistics } } = await StatisticService.getWebmasterStatistic(
      {
        ...state.filters.datepicker
      },
      {},
      ["CURRENCY"]
    );
    
    const payload = await dispatch("UPDATE_STATISTIC", statistics);
    commit(SET_NEW_STATISTIC, payload);
  },
    
  async [GET_OLD_STATISTIC] ({ state, commit, dispatch }, period) {
    const datepicker = state.filters.datepicker;
    let dateStart = moment().startOf(period).subtract(1, period).toDate();
    let dateEnd = moment().endOf(period).subtract(1, period).toDate();
        
    if (!period) {
      // @ts-ignore;
      const range = datepicker.dateEnd - datepicker.dateStart;
      dateStart = new Date(datepicker.dateStart.getTime() - range);
      dateEnd = new Date(datepicker.dateEnd.getTime() - range);
    }
    
    const filters = {
      dateStart,
      dateEnd
    };
    const { data: { statistics } } = await StatisticService.getWebmasterStatistic(
      filters,
      {},
      ["CURRENCY"]
    );
  
    const payload = await dispatch("UPDATE_STATISTIC", statistics);
    commit(SET_OLD_STATISTIC, payload);
  },
  
  async UPDATE_STATISTIC ({ commit, dispatch }, statistics) {
    const currencies = await dispatch(`currencyList/${ GET_CURRENCY }`, undefined, { root: true });
  
    const zeroStatisticItems: Array<StatisticItems> = currencies
      .map((currency: string) => {
        return {
          countLandingClicks: 0,
          countLeadsWhite: 0,
          countLeadsAccepted: 0,
          moneyWebmasterAccrued: 0,
          currency
        };
      });
  
    const items = statistics.items.length
      ? zeroStatisticItems.map((item) => {
        const findElement = statistics.items.find((el: StatisticItems) => el.currency === item.currency);
        if (findElement) {
          return findElement;
        }
        return item;
      })
      : zeroStatisticItems;
  
    const payload = sortByPreferredCurrency(items)?.reduce((acc, obj) => {
      acc.clicks.push({ value: obj.countLandingClicks, currency: obj.currency });
      acc.leads.push({ value: obj.countLeadsWhite, currency: obj.currency });
      acc.confirmed.push({ value: obj.countLeadsAccepted, currency: obj.currency });
      acc.income.push({ value: obj.moneyWebmasterAccrued, currency: obj.currency });
      return acc;
    }, {
      clicks: <TodayStatistic["clicks"]>[],
      leads: <TodayStatistic["leads"]>[],
      confirmed: <TodayStatistic["confirmed"]>[],
      income: <TodayStatistic["income"]>[]
    });
  
    commit(UPDATE_CURRENCY_LIST, currencies);
    return payload;
  },
    
  [UPDATE_FILTERS] ({ commit }, filters) {
    commit(UPDATE_FILTERS, filters);
  }
};

export const statistic: Module<WebmasterStatistic, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations
};
