import {
  Integration,
  IntegrationResponse,
  Integrations,
  IntegrationServices
} from "@core/logic/admin/integrations/integrations";
import { IntegrationService } from "@core/services/admin/integrations/IntegrationsService";
import {
  CREATE_INTEGRATION,
  DELETE_INTEGRATION,
  GET_INTEGRATIONS,
  LAUNCH_INTEGRATION,
  STOP_INTEGRATION,
  VALIDATE_CRM_LEAD_GROUP
} from "@core/store/action-constants";
import { advertisersList } from "@core/store/modules/admin/lists/advertisersList";
import { CRMList } from "@core/store/modules/admin/lists/CRMList";
import { offersList } from "@core/store/modules/admin/lists/offersList";
import {
  SET_INTEGRATIONS,
  UPDATE_INTEGRATIONS_FILTERS,
  UPDATE_INTEGRATIONS_PAGINATION
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { PaginationState } from "@core/store/types/PaginationState";
import { ActionTree, Module, MutationTree } from "vuex";

export interface IntegrationState {
  integrations: Integrations | null;
  filters: {
    offer: string | null;
    advertiser: string | null;
    crm: string | null;
    country: string[] | null;
  };
}

export type PartialIntegrationState = PaginationState<IntegrationState>

const initialState = (): PartialIntegrationState => {
  return {
    integrations: null,
    filters: {
      advertiser: null,
      offer: null,
      crm: null,
      country: null
    },
    pagination: {
      page: 1,
      perPage: 25
    }
  };
};

const state: () => PartialIntegrationState = initialState;

const mutations: MutationTree<PartialIntegrationState> = {
  SET_EMPTY: state => Object.assign(state, initialState()),

  [SET_INTEGRATIONS] (state, items: Integrations) {
    state.integrations = items;
  },

  [UPDATE_INTEGRATIONS_FILTERS] (state, filter: any) {
    state.filters = { ...state.filters, ...filter };
  },

  [UPDATE_INTEGRATIONS_PAGINATION] (state, pagination) {
    state.pagination = { ...state.pagination, ...pagination };
  }
};

const actions: ActionTree<PartialIntegrationState, RootState> = {
  async [GET_INTEGRATIONS] ({ state, commit }) {
    const limit = state.pagination.perPage;
    const offset = (state.pagination.page - 1) * limit;
    const { country, ...filters } = state.filters;

    try {
      const { data: { integrations } } = await IntegrationService.getIntegrations(
        limit,
        offset,
        {
          country: country?.length ? country : null,
          ...filters
        }
      );

      integrations.items.map((item: IntegrationResponse) => new Integration(item));
      commit(SET_INTEGRATIONS, integrations);
    } catch (e: any) {
      throw new Error(e);
    }
  },

  async [VALIDATE_CRM_LEAD_GROUP] (_, integrationId) {
    const { data } = await IntegrationService.validateIntegration(integrationId);
    return data;
  },

  async [CREATE_INTEGRATION] (_, payload) {
    try {
      await IntegrationServices.createIntegration(payload);
    } catch (e: any) {
      throw new Error(e);
    }
  },

  async [DELETE_INTEGRATION] (_, integrationId) {
    try {
      await IntegrationService.removeIntegration(integrationId);
    } catch (e: any) {
      throw new Error(e);
    }
  },

  async [LAUNCH_INTEGRATION] (_, integrationId: string) {
    try {
      await IntegrationService.launchIntegration(integrationId);
    } catch (e) {
      throw e;
    }
  },
  
  async [STOP_INTEGRATION] (_, integrationId: string) {
    await IntegrationService.stopIntegration(integrationId);
  },
  
  async [CREATE_INTEGRATION] (_, payload) {
    try {
      await IntegrationServices.createIntegration(payload);
    } catch (e: any) {
      throw new Error(e);
    }
  },
  
  [UPDATE_INTEGRATIONS_FILTERS] ({ commit }, filter) {
    commit(UPDATE_INTEGRATIONS_FILTERS, filter);
    commit(UPDATE_INTEGRATIONS_PAGINATION, { page: 1 });
  },

  [UPDATE_INTEGRATIONS_PAGINATION] ({ commit }, pagination) {
    commit(UPDATE_INTEGRATIONS_PAGINATION, pagination);
  },

  SET_EMPTY ({ commit }) {
    commit("SET_EMPTY");
    commit("advertisersList/SET_EMPTY");
    commit("offersList/SET_EMPTY");
    commit("CRMList/SET_EMPTY");
  }
};

export const integrations: Module<PartialIntegrationState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    advertisersList,
    offersList,
    CRMList
  }
};
