import { ASTGeneratedOutput, Generator } from "@core/services/common/Generator";
import { BaseFiltersInput } from "@core/store/types/common/statistic/generic/statisticFilters";

export default function statisticsGenerator (
  filters: BaseFiltersInput,
  excludeFilters: BaseFiltersInput,
  groups: string[],
  isAgent: boolean
): ASTGeneratedOutput {
  const params = {
    exclude: !isAgent ? [
      "items.webmaster",
      "items.moneyAgentCommission"
    ] : []
  };
  
  const gqlObject = {
    operation: "statistics",
    
    fields: [
      {
        items: [
          "countLeadsWhite",
          "countLeadsAccepted",
          "countLeadsProcessing",
          "countLeadsCancelled",
          "countLeadSpamByAdvertiser",
          "countLeadsHold",
          "conversionCleanApprove",
          "moneyWebmasterAwaiting",
          "moneyWebmasterAccrued",
          "moneyAgentCommission",
          "countLandingClicks",
          "countTransitClicks",
          "countFlowClicks",
          "countLandingUniques",
          "conversionCR",
          "conversionLandingEpc",
          "conversionTransitEpc",
          "conversionFlowEpc",
          "offer",
          "bunch",
          "flow",
          "webmaster",
          "country",
          "externalWebmaster",
          "hours",
          "day",
          "week",
          "month",
          "year",
          "globalSource",
          "withdraw",
          "region",
          "category",
          "utmCampaign",
          "utmContent",
          "utmMedium",
          "utmSource",
          "utmTerm",
          "currency"
        ]
      },
      "count",
      "aggregates",
      "extras"
    ],
    
    variables: {
      filters: {
        type: "StatisticsFiltersInput",
        value: filters
      },
      excludeFilters: {
        type: "StatisticsFiltersInput",
        value: excludeFilters
      },
      groups: {
        type: "[StatisticsGroupsEnum!]",
        value: groups
      }
    }
  };
  
  return Generator.query(gqlObject, params);
}
