import { AdminOfferModule, OfferState } from "@core/store/modules/admin/offer/AdminOfferModule";
import { Module } from "vuex";
import { RootState } from "@core/store/root-state";
import { edit } from "@core/store/modules/admin/offer/modules/edit";
import { webmasters } from "@core/store/modules/admin/offer/modules/webmasters";
import { OfferLandingsList } from "@core/store/modules/admin/offer/modules/offerLandings/offerLandingsList";

const offer = new AdminOfferModule().toModule();
offer.namespaced = false;

export const AdminOffer: Module<OfferState, RootState> = {
  namespaced: true,
  modules: {
    offer,
    edit,
    webmasters,
    landings: new OfferLandingsList("landing"),
    transits: new OfferLandingsList("transit")
  }
};
