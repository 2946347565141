<template>
    <el-container class="bg-gray-light-bg h-screen flex items-center justify-center">
        <div class="flex items-center flex-col">
            <!--suppress HtmlUnknownTarget -->
            <img
                alt="logo"
                src="@/assets/logo.svg">
            <!--suppress HtmlUnknownTarget -->
            <img
                alt="mail-image"
                src="@/assets/MailConfirmation/send.png">
            <h1 class="has-text-black is-title has-text-centered">
                {{ $t(`common.mailConfirmation.send.title`) }}
            </h1>
            <a
                :href="link"
                class="mt-2">
                <FButton
                    type="success"
                    plain
                    round
                    no-more>
                    {{ $t(`common.mailConfirmation.send.link`) }}
                </FButton>
            </a>
        </div>
    </el-container>
</template>

<script>
  import FButton from "@/components/Common/FButton";

  export default {
    name: "MailConfirmationSend",
    components: { FButton },
    computed: {
      link () {
        return process.env.VUE_APP_LANDING_PAGE_URL;
      }
    }
  };
</script>

<style scoped lang="scss">

</style>
