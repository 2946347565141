<template>
    <el-form
        class="form"
        :model="{ newPassword, newPasswordConfirmation }"
        @submit.native.prevent="sendNewPassword">
        <!--suppress HtmlUnknownTarget-->
        <img
            alt="FairPay"
            class="form-logo"
            src="@/assets/logo.svg">

        <EditUserPasswordField
            v-model="newPassword"
            prop="newPassword"
            class="column is-12"
            :placeholder="$t(`common.auth.signIn.component.passwordResetWithToken.body.labels.newPassword`)">
        </EditUserPasswordField>

        <EditUserPasswordField
            v-model="newPasswordConfirmation"
            prop="newPasswordConfirmation"
            class="column is-12"
            :message-key="newPasswordError"
            :type="typeIsDanger"
            :placeholder="$t(`common.auth.signIn.component.passwordResetWithToken.body.labels.newPasswordConfirmation`)">
        </EditUserPasswordField>

        <b-button
            :disabled="!newPasswordIsValid"
            class="is-success"
            expanded
            native-type="submit"
            rounded>
            {{ $t(`common.auth.signIn.component.passwordResetWithToken.body.buttons.send`) }}
        </b-button>
        <router-link
            :to="{ name: 'auth:signIn' }"
            class="form-subbutton">
            {{ $t(`common.auth.signIn.signIn`) }}
        </router-link>
    </el-form>
</template>

<script>
  import { SEND_NEW_PASSWORD } from "@core/store/action-constants";
  import { mapActions } from "vuex";
  import EditUserPasswordField from "@/components/Common/Fields/EditUserPasswordField";

  export default {
    name: "NewPassword",
    components: {
      EditUserPasswordField
    },
    data () {
      return {
        token: null,
        newPassword: null,
        newPasswordConfirmation: null
      };
    },
    computed: {
      newPasswordIsValid () {
        return this.newPassword == null || !!(this.newPassword && this.newPassword === this.newPasswordConfirmation);
      },

      newPasswordError () {
        return this.newPasswordIsValid ?
          undefined : "common.auth.signIn.component.passwordResetWithToken.body.errors.newPasswordError";
      },

      typeIsDanger () {
        return this.newPasswordIsValid ? undefined : "is-danger";
      }
    },
    methods: {
      ...mapActions([
        SEND_NEW_PASSWORD
      ]),

      async sendNewPassword () {
        const data = await this.SEND_NEW_PASSWORD({
          password: this.newPassword === this.newPasswordConfirmation ? this.newPassword : null,
          token: this.$route.query.token
        });

        if (data) await this.$router.push({
          name: "auth:signIn"
        }).catch(_ => {});
      }
    }
  };
</script>

<style lang="scss" scoped>
    @media screen and (min-width: 1025px) {
        .form {
            top: 50%;
            right: 22vw;
            position: absolute;
            transform: translateY(-50%);
        }
    }
</style>
