import { AxiosPromise } from "axios";
import { SearchParams } from "@core/logic/statistics/params";
import { AdvertiserStatisticByGroups } from "@core/logic/advertiser/statistic/AdvertiserStatisticByGroups";
import Service from "@core/services/common/Service";
import { BaseFiltersInput } from "@core/store/types/common/statistic/generic/statisticFilters";
import statistic from "@core/services/advertiser/statistic/graphql/statistic.graphql";
import { StatisticResponseData } from "@core/store/types/common/statistic/common/statistic";

export class StatisticService extends Service {
  public static getOffers (params: any): any {
    return this.rest.get("/advertiser/offers", { params });
  }

  public static async getAdvertiserStatistic (
    filters: BaseFiltersInput,
    groups: string[]
  ): Promise<StatisticResponseData> {
    try {
      return await this.api.get("", {
        params: {
          query: statistic,
          variables: {
            filters,
            groups
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
}
