import { AdvertisersEditService } from "@core/services/admin/users/advertisers/AdvertisersEditService";
import { EDIT_PASSWORD } from "@core/store/action-constants";
import { SET_EMPTY, UPDATE_MODAL, UPDATE_PASSWORD_FORM } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { AdvertisersPasswordEditInput, AdvertisersPasswordState } from "@core/store/types/admin/users/advertisers/Edit";
import { ActionTree, Module, MutationTree } from "vuex";

const initialState = (): AdvertisersPasswordState => {
  return {
    modal: {
      isPasswordModalActive: false
    },
    form: {
      password: null
    }
  };
};

const mutations: MutationTree<AdvertisersPasswordState> = {
  [SET_EMPTY] (state) {
    Object.assign(state, initialState());
  },

  [UPDATE_MODAL] (state, modal) {
    state.modal = { ...state.modal, ...modal };
  },

  [UPDATE_PASSWORD_FORM] (state, form) {
    state.form = { ...state.form, ...form };
  }
};

const actions: ActionTree<AdvertisersPasswordState, RootState> = {
  async [EDIT_PASSWORD] ({ state: { form }, rootState }) {
    const { advertiserId } = rootState.admin.users.advertisers.edit;
    await AdvertisersEditService.editPassword(advertiserId, form as AdvertisersPasswordEditInput);
  },

  [UPDATE_MODAL] ({ commit }, modal) {
    commit(UPDATE_MODAL, modal);
  },

  [UPDATE_PASSWORD_FORM] ({ commit }, password) {
    commit(UPDATE_PASSWORD_FORM, password);
  }
};

export const password: Module<AdvertisersPasswordState, RootState> = {
  namespaced: true,
  state: initialState,
  mutations,
  actions
};
