import Service from "@core/services/common/Service";
import { BindAdminInput } from "@core/store/types/admin/users/advertisers/AdvertisersState";
import { AccessAgentInput } from "@core/store/types/admin/users/webmasters/Agent";
import { PaginationWebmastersState } from "@core/store/types/admin/users/webmasters/WebmastersState";
import { AxiosPromise } from "axios";
import approveWebmaster from "./graphql/approveWebmaster.graphql";
import bindWebmaster from "./graphql/bindWebmaster.graphql";
import blockWebmaster from "./graphql/blockWebmaster.graphql";
import disableAccessToApiForWebmaster from "./graphql/disableAccessToApiForWebmaster.graphql";
import disablePartnerNetworkForWebmaster from "./graphql/disablePartnerNetworkForWebmaster.graphql";
import enableAccessToApiForWebmaster from "./graphql/enableAccessToApiForWebmaster.graphql";
import enablePartnerNetworkForWebmaster from "./graphql/enablePartnerNetworkForWebmaster.graphql";
import changeAgentAccessForWebmaster from "./graphql/changeAgentAccessForWebmaster.graphql";
import unblockWebmaster from "./graphql/unblockWebmaster.graphql";
import webmasters from "./graphql/webmasters.graphql";
import checkWebmaster from "./graphql/checkWebmaster.graphql";

export class WebmastersService extends Service {
  public static getWebmasters (
    limit: number,
    offset: number,
    filters: PaginationWebmastersState["filters"],
    sort?: PaginationWebmastersState["sort"],
    order?: PaginationWebmastersState["order"]
  ): AxiosPromise {
    try {
      return this.api.get("", {
        params: {
          query: webmasters,
          variables: {
            limit,
            offset,
            filters,
            sort,
            order
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static approveWebmaster (webmasterId: string): AxiosPromise {
    try {
      return this.api.post("", {
        query: approveWebmaster,
        variables: {
          webmasterId
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static enableAccessToApiForWebmaster (webmasterId: string): AxiosPromise {
    try {
      return this.api.post("", {
        query: enableAccessToApiForWebmaster,
        variables: {
          webmasterId
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static disableAccessToApiForWebmaster (webmasterId: string): AxiosPromise {
    try {
      return this.api.post("", {
        query: disableAccessToApiForWebmaster,
        variables: {
          webmasterId
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static changeAgentAccessForWebmaster (webmasterId: Nullable<string>, input: AccessAgentInput): AxiosPromise {
    try {
      return this.api.post("", {
        query: changeAgentAccessForWebmaster,
        variables: {
          webmasterId,
          input
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static blockWebmaster (webmasterId: string): AxiosPromise {
    try {
      return this.api.post("", {
        query: blockWebmaster,
        variables: {
          webmasterId
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static unblockWebmaster (webmasterId: string): AxiosPromise {
    try {
      return this.api.post("", {
        query: unblockWebmaster,
        variables: {
          webmasterId
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static enablePartnerNetworkForWebmaster (webmasterId: string): AxiosPromise {
    try {
      return this.api.post("", {
        query: enablePartnerNetworkForWebmaster,
        variables: {
          webmasterId
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static disablePartnerNetworkForWebmaster (webmasterId: string): AxiosPromise {
    try {
      return this.api.post("", {
        query: disablePartnerNetworkForWebmaster,
        variables: {
          webmasterId
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static async bindWebmaster (webmasterId: string, input: BindAdminInput): Promise<AxiosPromise> {
    try {
      return await this.api.post("", {
        query: bindWebmaster,
        variables: {
          webmasterId,
          input
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static async checkWebmaster (webmasterTelegram: string): Promise<AxiosPromise> {
    try {
      return await this.api.get("", {
        params: {
          query: checkWebmaster,
          variables: {
            identifier: webmasterTelegram
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
}
