import Service from "@core/services/common/Service";
import { AxiosPromise } from "axios";
import offer from "./graphql/offer.graphql";
import landingFlows from "./graphql/landingFlows.graphql";
import removeFlow from "./graphql/removeFlow.graphql";
import webmasterJoinToOffer from "./graphql/webmasterJoinToOffer.graphql";
import excludeWebmasterFromOffer from "./graphql/excludeWebmasterFromOffer.graphql";
import { FlowsState } from "@core/store/types/webmaster/flows";
import { WebmasterOffer } from "@core/store/logic/webmaster/offer/WebmasterOffer";

export class OfferService extends Service {
  public static async getOffer (offerId: string): Promise<WebmasterOffer> {
    try {
      const { data } = await this.api.post("", {
        query: offer,
        variables: {
          offerId
        }
      });
      return new WebmasterOffer(data.offer);
    } catch (e) {
      throw e;
    }
  }

  public static async getFlows (
    limit: number,
    offset: number,
    filters: FlowsState["filters"]
  ): Promise<AxiosPromise> {
    try {
      return await this.api.get("", {
        params: {
          query: landingFlows,
          variables: {
            limit,
            offset,
            filters
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static async deleteFlow (flowId: string): Promise<AxiosPromise> {
    try {
      return await this.api.get("", {
        params: {
          query: removeFlow,
          variables: { flowId }
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static async webmasterJoinToOffer (offerId: string): Promise<AxiosPromise> {
    try {
      return await this.api.post("", {
        query: webmasterJoinToOffer,
        variables: {
          offerId
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static async excludeWebmasterFromOffer (offerId: string): Promise<AxiosPromise> {
    try {
      return await this.api.post("", {
        query: excludeWebmasterFromOffer,
        variables: {
          offerId
        }
      });
    } catch (e) {
      throw e;
    }
  }
}
