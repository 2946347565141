import { GraphQLServer } from "@core/plugins/axios";
import Service from "@core/services/common/Service";
import { RequestAdvertiser } from "@core/store/types/admin/offer/OfferAdvertiser.d";
import { AxiosPromise } from "axios";
import addOutTransaction from "./graphql/addOutTransaction.graphql";

export class CreatePaymentCostsService extends Service {

  public static createCosts (options: RequestAdvertiser[]): AxiosPromise {
    try {
      return GraphQLServer.post("", {
        query: addOutTransaction,
        variables: { input: { ...options } }
      });
    } catch (e) {
      throw e;
    }
  }
}
