//TODO: отрефакторить капчу
<template>
    <el-form
        :class="[{ 'form': true, 'in-modal': isModal }, 'relative']"
        :model="{ email }"
        @submit.prevent.native="captchaExecute">
        <!--suppress HtmlUnknownTarget-->
        <img
            alt="FairPay"
            class="form-logo"
            src="@/assets/logo.svg">
        <div class="w-full">
            <RequiredField
                v-model.trim="email"
                :placeholder="$t(`common.auth.signIn.component.passwordReset.body.labels.email`)"
                :pattern="emailValidator"
                pattern-message="common.validation.email"
                prop="email">
            </RequiredField>
        </div>

        <Captcha
            ref="captcha"
            @onVerify="resetPassword($event)">
        </Captcha>
      
        <div class="w-full">
            <FButton
                expanded
                native-type="submit"
                type="success"
                no-more
                rounded>
                {{ $t(`common.auth.signIn.component.passwordReset.body.buttons.reset`) }}
            </FButton>
            <router-link
                v-if="!isModal"
                :to="{ name: 'auth:signIn' }"
                class="form-subbutton">
                {{ $t(`common.auth.signIn.signIn`) }}
            </router-link>
        </div>
    </el-form>
</template>

<script>
  import { RESET_PASSWORD } from "@core/store/action-constants";
  import { EMAIL_VALIDATOR } from "@core/validators";
  import { mapActions } from "vuex";
  import Captcha from "@/components/Common/Auth/Captcha";
  import RequiredField from "@/components/Common/RequiredField";
  import FButton from "@/components/Common/FButton";

  export default {
    name: "ResetPassword",
    components: {
      FButton,
      RequiredField,
      Captcha 
    },
    data () {
      return {
        email: null
      };
    },
    
    computed: {
      isModal () {
        return this.$parent.$vnode.componentOptions.tag === "b-modal";
      },
      
      emailValidator () {
        return EMAIL_VALIDATOR;
      }
    },
    
    methods: {
      ...mapActions([
        RESET_PASSWORD
      ]),

      async resetPassword (captcha) {
        try {
          await this.RESET_PASSWORD({
            email: this.email,
            captcha: captcha
          });
          if (this.$route.name !== "auth:signIn") {
            await this.$router.push({
              name: "auth:signIn",
              params: {
                email: this.email
              }
            }).catch(_ => {});
          }
          this.$emit("closeResetModal");
        } catch (error) {
          this.$buefy.toast.open({
            message: this.$t("common.auth.signIn.notification.passwordResetError"),
            type: "is-danger"
          });
        }
      },

      captchaExecute () {
        this.$refs.captcha.$refs.hcaptcha.execute();
      }
    }
  };
</script>

<style lang="scss" scoped>
    .form {
        &.in-modal {
            margin: auto;
            transform: none;
            position: static;
        }
    }

    @media screen and (min-width: 1025px) {
        .form {
            top: 50%;
            right: 22vw;
            position: absolute;
            transform: translateY(-50%);
        }
    }

    ::v-deep {
        .el-form-item.is-error {
            @apply mb-8 desktop:mb-6;
        }
    }
</style>
