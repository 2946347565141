import { OfferEditAdvertisersIndividualRateService } from "@core/services/admin/offer/edit/OfferEditAdvertisersIndividualRateService";
import {
  ADD_OFFER_ADVERTISER_INDIVIDUAL_RATE, GET_OFFER_ID,
  GET_SELECTED_WEBMASTERS,
  GET_WEBMASTERS_BY_ID,
  UPDATE_MAXIMAL_ALLOWED_WEBMASTER_REWARD,
  UPDATE_MINIMAL_ALLOWED_ADVERTISER_WRITE_OFF,
  UPDATE_SELECTED_WEBMASTERS,
  UPDATE_MINIMAL_ALLOWED_ADVERTISER_RATE
} from "@core/store/action-constants";
import { offerEditWebmastersList } from "@core/store/modules/admin/offer/lists/offerEditWebmastersList";
import { UPDATE_MODAL_ACTIVE, UPDATE_SELECTED_WEBMASTERS_IDS } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { RequestIndividualRate, SelectedWebmaster } from "@core/store/types/admin/offer/OfferAdvertiserIndividualRate.d";
import { OfferIndividualRatesModalState } from "@core/store/types/admin/offer/OfferIndividualRatesModal.d";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import Vue from "vue";

const initialState = (): OfferIndividualRatesModalState => {
  return {
    selectedWebmastersIds: [],
    selectedWebmasters: {},
    isIndividualRatesModalActive: false
  };
};

const state: () => OfferIndividualRatesModalState = initialState;

const getters: GetterTree<OfferIndividualRatesModalState, RootState> = {
  [GET_SELECTED_WEBMASTERS]: state => Object.values(state.selectedWebmasters)
};

const mutations: MutationTree<OfferIndividualRatesModalState> = {
  SET_EMPTY: state => Object.assign(state, initialState()),

  [UPDATE_MODAL_ACTIVE] (state, payload: boolean) {
    state.isIndividualRatesModalActive = payload;
  },

  [UPDATE_SELECTED_WEBMASTERS] (state, Webmasters: OfferIndividualRatesModalState["selectedWebmasters"]) {
    state.selectedWebmasters = Webmasters;
  },

  [UPDATE_SELECTED_WEBMASTERS_IDS] (state, ids: string[]) {
    state.selectedWebmastersIds = ids;
  },

  [UPDATE_MINIMAL_ALLOWED_ADVERTISER_RATE] (state, payload) {
    Vue.set(state.selectedWebmasters[payload.id], "minimalAllowedAdvertiserRate", payload.minimalAllowedAdvertiserRate);
  }
};

const actions: ActionTree<OfferIndividualRatesModalState, RootState> = {
  [UPDATE_SELECTED_WEBMASTERS] ({ getters, state, commit, dispatch }, ids) {
    commit(UPDATE_SELECTED_WEBMASTERS_IDS, ids);
    const newWebmasters: { [key: string]: unknown } = {};
    for (const id of state.selectedWebmastersIds) {
      if (state.selectedWebmasters[id]) {
        newWebmasters[id] = state.selectedWebmasters[id];
      } else {
        newWebmasters[id] =
                    JSON.parse(JSON.stringify(getters[`offerEditWebmastersList/${ GET_WEBMASTERS_BY_ID }`]([id])[0]));

        dispatch(UPDATE_MINIMAL_ALLOWED_ADVERTISER_RATE, id);
      }
    }
    commit(UPDATE_SELECTED_WEBMASTERS, newWebmasters);
  },

  async [UPDATE_MINIMAL_ALLOWED_ADVERTISER_RATE] ({ commit, rootGetters, rootState }, id) {
    const offerId = rootGetters[`admin/offer/edit/common/${ GET_OFFER_ID }`];
    const advertiserId = rootState.admin.offer.edit.advertisersInternalIndividualRates.filters.advertiserId;
    const { data: { minimalAllowedAdvertiserRate } } = await OfferEditAdvertisersIndividualRateService.minimalAllowedAdvertiserRate(
      offerId,
      advertiserId,
      id
    );
    commit(UPDATE_MINIMAL_ALLOWED_ADVERTISER_RATE, { id, minimalAllowedAdvertiserRate });
  },

  async [ADD_OFFER_ADVERTISER_INDIVIDUAL_RATE] ({ state, rootState, dispatch, rootGetters }) {
    const offerId = rootGetters[`admin/offer/edit/common/${ GET_OFFER_ID }`];
    const advertiserId = rootState.admin.offer.edit.advertisersInternalIndividualRates.filters.advertiserId;
    try {
      if (offerId && advertiserId) {
        const rates: RequestIndividualRate[] = Object.values(state.selectedWebmasters).map((user: SelectedWebmaster) => {
          return {
            rate: Number(user.rate) || 1,
            webmasterId: user.id,
            advertiserId: advertiserId
          };
        });
        const { data: { createAdvertiserIndividualRate } } =
                    await OfferEditAdvertisersIndividualRateService.createAdvertiserIndividualRate(offerId, rates);

        dispatch(`admin/offer/edit/webmastersInternalRates/${ UPDATE_MAXIMAL_ALLOWED_WEBMASTER_REWARD }`,
          { offerId }, { root: true });
        dispatch(`admin/offer/edit/offerEditAdvertisers/${ UPDATE_MINIMAL_ALLOWED_ADVERTISER_WRITE_OFF }`,
          { offerId }, { root: true });
        dispatch(`admin/offer/edit/webmastersExternalRates/${ UPDATE_MAXIMAL_ALLOWED_WEBMASTER_REWARD }`,
          { offerId }, { root: true });
        return createAdvertiserIndividualRate;
      }
    } catch (e) {
      throw e;
    }
  },

  [UPDATE_MODAL_ACTIVE] ({ commit }, payload: boolean) {
    if (payload) {
      commit("SET_EMPTY");
      commit("offerEditWebmastersList/SET_EMPTY");
    }
    commit(UPDATE_MODAL_ACTIVE, payload);
  }
};

export const offerEditIndividualRatesModal: Module<OfferIndividualRatesModalState, RootState> = {
  namespaced: true,
  modules: {
    offerEditWebmastersList
  },
  getters,
  state,
  mutations,
  actions
};
