import { WithdrawType } from "@core/logic/common/common";
import { PaginationParams } from "@core/logic/common/pagination-params";
import inTransactionCreatorsQuery from "@core/services/admin/finance/graphql/inTransactionCreators.graphql";
import { AxiosPromise, AxiosResponse } from "axios";
import Service from "@core/services/common/Service";
import { ExecutorsData, ExecutorsFilters } from "@core/store/types/admin/users/advertisers/Executors";
import { auth } from "@core/store/modules/common/auth";
import statisticGenerator from "@core/services/webmaster/statistic/statistic/graphql/statistics.generator";

export interface OffersFilters {
  nameOrIntId: string | number;
  trafficType: string;
  type: WithdrawType;
  countries: Array<never>;
  advertisers: Array<never>;
  categories: Array<never>;
  isLaunched: boolean;
}

export type PartialOffersFilters = Partial<OffersFilters>;

export interface OffersParams extends PaginationParams {
  filters: PartialOffersFilters;
}

export class DictionariesService extends Service{
  public static getRequisites (params: any): AxiosPromise {
    return this.rest.get("/admin/requisites", { params });
  }

  public static getFlows (params: any): AxiosPromise {
    return this.rest.get("/admin/statistics/filters/flows", { params });
  }

  public static getBunches (params: any): AxiosPromise {
    return this.rest.get("/admin/statistics/filters/bunches", { params });
  }

  public static async getWebmasters (params: any): Promise<AxiosResponse<any>> {
    const response = await this.rest.get("/admin/webmasters", { params });
    response.data.items.forEach((item: any) => {
      return {
        id: item.id,
        login: item.login,
        type: item.type,
        contact: {
          name: item.contact.name,
          email: item.contact.email,
          telegram: item.contact.telegram
        }
      };
    });

    return response;
  }

  public static async getExecutors (
    limit: number,
    offset: number,
    filters: ExecutorsFilters
  ): Promise<ExecutorsData> {
    try {
      return await this.api.get("", {
        params: {
          query: inTransactionCreatorsQuery,
          variables: {
            limit,
            offset,
            filters
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static async getAdmins (params: any): Promise<AxiosResponse<any>> {
    const response = await this.rest.get("/admin/admins", { params });
    response.data.items.forEach((item: any) => {
      return {
        id: item.id,
        login: item.login,
        type: item.type,
        contact: {
          name: item.contact.name,
          email: item.contact.email,
          telegram: item.contact.telegram
        }
      };
    });

    return response;
  }

  public static async getAdvertisers (params: any): Promise<AxiosResponse<any>> {
    const response = await this.rest.get("/admin/advertisers", { params });
    response.data.items.forEach((item: any) => {
      return {
        id: item.id,
        login: item.login,
        type: item.type,
        contact: {
          name: item.contact.name,
          email: item.contact.email,
          telegram: item.contact.telegram
        }
      };
    });
    return response;
  }

  public static async getOffers (params: PartialOffersFilters): Promise<AxiosResponse<any>> {
    const response = await this.rest.get("/admin/offers", { params });
    response.data.items.forEach((item: any) => {
      return {
        id: item.id,
        name: item.name
      };
    });

    return response;
  }
}
