import {
  GET_WRITE_OFFS_BY_LEADS,
  GET_WRITE_OFFS_BY_LEADS_GROUPED,
  GET_WRITE_OFFS_PROXY,
  UPDATE_GROUP
} from "@core/store/action-constants";
import financesCostsFieldsList
  from "@core/store/modules/advertiser/finances/modules/costs/lists/financesCostsFieldsList.json";
import { writeOffsByLeads } from "@core/store/modules/advertiser/finances/modules/costs/writeOffsByLeads";
import { writeOffsByLeadsGrouped } from "@core/store/modules/advertiser/finances/modules/costs/writeOffsByLeadsGrouped";
import { CLEAR_FILTERS, UPDATE_FILTERS, UPDATE_PAGINATION } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { CostsState } from "@core/store/types/advertiser/finances/modules/costs";
import moment from "moment";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { offersList } from "@core/store/modules/admin/lists/offersList";

const initialState = (): CostsState => ({
  filters: {
    offer: null,
    remoteId: null,
    currency: null,
    datepicker: {
      dateStart: moment().startOf("day").subtract(1, "month").toDate(),
      dateEnd: moment().endOf("day").toDate()
    }
  },
  selectedFields: financesCostsFieldsList,
  group: null,
  pagination: {
    page: 1,
    perPage: 100
  }
});

const state = initialState;

const getters: GetterTree<CostsState, RootState> = {
  isGrouped: ({ group }) => !!group,
  getWriteOffs: (state, getters, rootState) => getters.isGrouped ?
    rootState.advertiser.finances.costs.writeOffsByLeadsGrouped.data :
    rootState.advertiser.finances.costs.writeOffsByLeads.data,
  preferredCurrency: (state, _, rootState) => rootState.auth.preferredCurrency
};

const mutations: MutationTree<CostsState> = {
  [UPDATE_FILTERS]: (state, filters): void => {
    state.filters = { ...state.filters, ...filters };
  },

  [UPDATE_GROUP]: (state, group): void => {
    state.group = group;
  },

  [CLEAR_FILTERS]: (state): void => {
    state.filters = {
      ...initialState().filters,
      currency: state.filters.currency
    };
    state.group = null;
  },

  [UPDATE_PAGINATION]: (state, pagination): void => {
    state.pagination = { ...state.pagination, ...pagination };
  }
};

const actions: ActionTree<CostsState, RootState> = {
  async [GET_WRITE_OFFS_PROXY] ({ state, getters, dispatch }) {
    const { group, pagination } = state;
    const { datepicker, remoteId, ...filters } = state.filters;

    if (getters.isGrouped) {
      await dispatch(`writeOffsByLeadsGrouped/${ GET_WRITE_OFFS_BY_LEADS_GROUPED }`,
        { filters: { ...datepicker, ...filters }, group, pagination });
    } else {
      await dispatch(`writeOffsByLeads/${ GET_WRITE_OFFS_BY_LEADS }`,
        { filters: {
          ...datepicker,
          remoteId: remoteId?.length ? remoteId : null,
          ...filters
        },
        pagination
        });
    }
  },

  [UPDATE_FILTERS] ({ commit }, filters) {
    commit(UPDATE_FILTERS, filters);
    commit(UPDATE_PAGINATION, { page: 1 });
  },

  [UPDATE_GROUP] ({ commit }, group) {
    commit(UPDATE_GROUP, group);
    commit(UPDATE_PAGINATION, { page: 1 });
  },

  [CLEAR_FILTERS] ({ commit }) {
    commit(CLEAR_FILTERS);
    commit(UPDATE_PAGINATION, { page: 1 });
  },

  [UPDATE_PAGINATION] ({ commit }, pagination) {
    commit(UPDATE_PAGINATION, pagination);
  }
};

export const costs: Module<CostsState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    writeOffsByLeads,
    writeOffsByLeadsGrouped,
    offersList
  }
};
