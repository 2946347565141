import Service from "@core/services/common/Service";
import { AxiosResponse } from "axios";
import {
  ManagerEfficiencyDashboard,
  PartialManagerEfficiencyDashboardFilterInput
} from "@core/store/types/admin/efficiency/efficiency";
import efficiency from "@core/services/admin/efficiency/graphql/efficiency.graph.generator";

export class EfficiencyService extends Service {
  public static async getEfficiency (
    payload: PartialManagerEfficiencyDashboardFilterInput
  ): Promise<AxiosResponse<ManagerEfficiencyDashboard>> {
    try {
      return await this.api.get("", {
        params: efficiency(payload.currency, payload.filters, payload.group, payload.sort, payload.order, payload.chartFilters)
      });
    } catch (e) {
      throw e;
    }
  }
}