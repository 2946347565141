import { AxiosPromise, AxiosResponse } from "axios";
import { PaginationParams } from "@core/logic/common/pagination-params";
import { PaginationOutput } from "@core/logic/common/pagination-output";
import { Offer } from "@core/logic/admin/offer/offer";
import { Country } from "@core/logic/admin/finances/finances";
import Service from "@core/services/common/Service";
import includeWebmastersToOffers from "@core/services/admin/offers/graphql/includeWebmastersToOffers.graphql";
import excludeWebmastersFromOffers from "@core/services/admin/offers/graphql/excludeWebmastersFromOffers.graphql";

interface OffersFilters {
  name: string;
  country: string;
  withdrawType: string;
  trafficTypes: string;
}

export interface OffersRequest extends PaginationParams {
  filters: OffersFilters;
}

export interface OffersResponse extends PaginationOutput {
  items: Offer[];
}

interface IndividualRate {
  countries: Country[];
  id: string;
  offer: IndividualRateOffer;
  offerHoldTime: IndividualRateHoldTime;
  offerMoneyReward: number;
  webmasterHoldTime: IndividualRateHoldTime;
  webmasterMoneyReward: number;
}

interface IndividualRateOffer {
  id: string;
  indId: number;
  name: string;
}

interface IndividualRateHoldTime {
  id: string;
  indId: number;
  name: string;
}

export interface WebmasterIndividualRates extends PaginationOutput {
  items: IndividualRate[];
}

// tslint:disable-next-line:max-classes-per-file
export class OffersService extends Service {
  public static getOffers (params: OffersRequest): Promise<AxiosResponse<OffersResponse>> {
    return this.rest.get("/admin/offers", { params });
  }

  public static deleteOffer (offerId: string): Promise<AxiosResponse<any>> {
    return this.rest.delete(`/admin/offers/${ offerId }`);
  }

  public static launchOffer (offerId: string): Promise<AxiosResponse<OffersResponse>> {
    return this.rest.put(`/admin/offers/${ offerId }/launch`);
  }

  public static stopOffer (offerId: string): Promise<AxiosResponse<OffersResponse>> {
    return this.rest.put(`/admin/offers/${ offerId }/stop`);
  }

  public static getWebmasterIndividualRates (webmasterId: string, params: any): AxiosPromise<WebmasterIndividualRates> {
    return this.rest.get(`/admin/webmasters/${ webmasterId }/individual-rates`, { params });
  }

  public static includeWebmastersToOffers (offerIds: string[], webmasterIds: string[]): Promise<AxiosResponse<any>> {
    try {
      return this.api.post("", {
        query: includeWebmastersToOffers,
        variables: {
          offerIds,
          webmasterIds
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static excludeWebmastersFromOffers (offerIds: string[], webmasterIds: string[]): Promise<AxiosResponse<any>> {
    try {
      return this.api.post("", {
        query: excludeWebmastersFromOffers,
        variables: {
          offerIds,
          webmasterIds
        }
      });
    } catch (e) {
      throw e;
    }
  }
}
