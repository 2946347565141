import { GraphQLServer } from "@core/plugins/axiosGraphQL";
import Service from "@core/services/common/Service";
import { OffersListResponse, PartialOffersListState } from "@core/store/types/admin/lists/OffersListState";
import { AxiosResponse } from "axios";
import offers from "../../admin/lists/graphql/offers.graphql";

export class OffersListService extends Service {
  public static async getOffersList (
    filters: PartialOffersListState["filters"], limit = 25, offset = 0,
    initialOffers: PartialOffersListState["filters"] = null): Promise<AxiosResponse<OffersListResponse>> {
    
    const initialOffersLimit = initialOffers?.id?.length || 0;
    const isInitialOffers = !!initialOffers?.id;
    limit = Math.floor(initialOffersLimit / limit + 1) * limit - initialOffersLimit;
    const response = await GraphQLServer.get("", {
      params: {
        query: offers,
        variables: {
          limit,
          offset,
          filters,
          initialOffers,
          initialOffersLimit,
          isInitialOffers
        }
      }
    });
    response.data.initialOffers.items = response.data.initialOffers.items.map((item: any) => {
      return {
        id: item.id,
        intId: item.intId,
        name: item.detail.name
      };
    });
    
    response.data.offers.items = response.data.offers.items.map((item: any) => {
      return {
        id: item.id,
        intId: item.intId,
        name: item.detail.name
      };
    });
    
    return response;
  }
}
