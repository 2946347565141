<template>
    <div>
        <v-select
            class="control "
            :components="{Deselect, OpenIndicator}"
            :append-to-body="appendToBody && !multiple"
            :autocomplete="autocomplete"
            :calculate-position="calculatePosition"
            :class="{ 'not-empty': value && value.length !== 0, 'bordered': bordered }"
            :clearable="isClearable"
            :close-on-select="!multiple"
            :create-option="createOption"
            :disabled="disabled || isInitialDataLoading"
            :filter-by="_filterBy"
            :filterable="filterable"
            :label="field"
            :loading="loading || isInitialDataLoading"
            :multiple="multiple"
            :no-drop="noDrop"
            :options="filteredOptions"
            :placeholder="placeholder"
            :searchable="searchable"
            :taggable="taggable"
            :value="computedValue"
            :selectable="selectable"
            @close="close"
            @input="select($event)"
            @open="open"
            @search="debouncedSearch"
            @option:selecting="selectOption">
            <template
                v-if="hasNextPage || localLoading"
                #list-footer>
                <div class="text-center">
                    <InlineLoader
                        ref="load"
                        active
                        size="is-large">
                    </InlineLoader>
                </div>
            </template>

            <template #search="{ attributes, events }">
                <slot
                    :attributes="attributes"
                    :computedValue="computedValue"
                    :class="[{ 'mt-1': !computedValue || !computedValue.length }, 'vs__search']"
                    :events="events"
                    :required="required"
                    name="search">
                    <input
                        v-bind="attributes"
                        :class="[{ 'mt-1': !computedValue || !computedValue.length }, 'vs__search']"
                        :required="required && !computedValue"
                        v-on="events">
                </slot>
            </template>

            <template #option="option">
                <slot :option="option">
                    <div class="media">
                        <div class="media-content">
                            <slot
                                :field="field"
                                :option="option"
                                name="text">
                                {{ (field ? option[field] : option) || option.id }}
                            </slot>
                        </div>
                    </div>
                </slot>
            </template>

            <template #selected-option="option">
                <slot
                    :option="option"
                    name="selected-option">
                    {{ (field ? option[field] : option) || option.id }}
                </slot>
            </template>


            <template #spinner="{ loading }">
                <div
                    v-if="loading"
                    class="vs__spinner">
                </div>
            </template>

            <template #no-options>
                <div></div>
            </template>
        </v-select>
    </div>
</template>

<script>
  import BaseSelect from "@/components/Common/Base/BaseSelect";
  import InlineLoader from "@/components/Common/InlineLoader";
  import FSvgIcon from "@/components/Common/FSvgIcon";
  import _debounce from "lodash/debounce";

  export default {
    name: "FSelect",

    extends: BaseSelect,

    components: {
      InlineLoader
    },

    created () {
      this.update();
    },

    data () {
      return {
        offset: 0,
        label: "",
        items: [],
        values: {},
        rawData: null,
        isActive: false,
        localLoading: false,
        Deselect: {
          render: createElement => createElement(FSvgIcon, {
            props: {
              icon: "times"
            }
          })
        },
        OpenIndicator: {
          render: createElement => createElement(FSvgIcon, {
            props: {
              icon: "arrow",
              color: "#C2C2C2"
            }
          })
        }
      };
    },

    beforeUpdate () {
      this.fillValues();
    },

    computed: {
      isInitialDataLoading () {
        return this.localLoading && !this.isActive;
      },

      hasNextPage () {
        return this.items.length < (this.rawData ? this.rawData.count : 0);
      },

      computedValue () {
        if (this.value != null) {
          if (typeof this.value === "object" && Object.keys(this.values).length !== 0) {
            return this.value.map(value => this.values[String(value)]);
          } else {
            return this.values[String(this.value)];
          }
        }

        return undefined;
      },

      debouncedSearch () {
        return _debounce(this.search, 400, { leading: false, trailing: true });
      },

      filteredOptions () {
        if (this.value != null && this.filterable) {
          if (typeof this.prop === "string") {
            if (this.multiple) {
              if (typeof this.value === "object") {
                return this.items.filter(option => this.value.every(value => (option[this.prop] ?? option) !== String(value)));
              } else {
                return this.items.filter(option => this.value !== (option[this.prop] ?? option));
              }
            } else return this.items.filter(option => this.value !== option[this.prop] ?? option);
          } else {
            return this.items.filter(option => this.value.every(value => this.prop.every(prop => (option[prop] ?? option) !== value)));
          }
        } else {
          return this.items;
        }
      },

      observer () {
        return new IntersectionObserver(this.infiniteScroll, { threshold: 0.1 });
      },

      isClearable () {
        return this.loading || this.localLoading ? false : this.clearable;
      },

      isAbleToUpdate () {
        return this.label.length === 0 || this.label.length >= this.minRequiredLength;
      },

      locale () {
        return this.$i18n.locale;
      }
    },

    methods: {
      fillValues () {
        if (this.value != null) {
          if (typeof this.value === "object") {
            this.value.forEach(value => {
              value = String(value);

              if (!this.values[value]) {
                this.values = { ...this.values, [value]: this.items.find(item => item[this.prop] === value) };
              }
            });
          } else {
            const value = String(this.value);

            if (!this.values[value]) {
              this.values = { ...this.values, [value]: this.items.find(item => (item[this.prop] ?? item) === this.value) };
            }
          }
        }
      },

      select (event) {
        if (event) {
          // Select может возвращать массив если multiple=true иначе значение ключа из пропса prop
          if (/*typeof event === "object" &&*/ !Array.isArray(event)) {
            this.$emit("input", event[this.prop] ?? event);
          } else {
            this.$emit("input", event.map(_event => _event[this.prop] ?? _event));
          }

          this.$emit("select", event);
        } else {
          this.$emit("input", null);
          this.$emit("select", null);
        }
      },

      search (label) {
        this.label = label;

        if (this.isAbleToUpdate) {
          this.offset = 0;
          this.items = [];
          this.update();
        }
      },

      async infiniteScroll ([{
        isIntersecting,
        target
      }]) {
        if (isIntersecting && this.offset) {
          const ul = target.offsetParent;
          const scrollTop = target.offsetParent.scrollTop;
          await this.update(true);
          await this.$nextTick();
          ul.scrollTop = scrollTop;
        }
      },

      async open () {
        this.isActive = true;
        if (this.hasNextPage) {
          await this.$nextTick();

          const load = this.$refs.load?.$el || this.$refs.load;
          this.observer.observe(load);
        }
      },

      close () {
        this.isActive = false;

        this.observer.disconnect();
      },

      async update (isLoading) {
        const count = (this.rawData || {}).count || Infinity;

        if (this.getData) {
          if (count > this.offset && this.isAbleToUpdate) {
            this.localLoading = true;
            this.rawData = await this.getData(this.offset, this.label);
            this.localLoading = false;

            if (this.rawData && this.rawData.items?.length > 0) {
              this.items = [...this.items, ...this.rawData.items];
              this.offset = this.items.length;
            }
          }
        } else if (this.getDataVuex) {
          if (count > this.offset && this.isAbleToUpdate) {
            this.localLoading = true;
            this.rawData = await this.getDataVuex(this.label, isLoading);
            this.localLoading = false;

            if (this.rawData && this.rawData.items?.length > 0) {
              this.items = this.rawData.items;
              this.offset = this.items.length;
            }
          }
        }
      },

      selectOption (selectedOption) {
        this.$emit("option:selecting", selectedOption);
      }
    },

    watch: {
      seed: {
        deep: true,
        handler () {
          this.offset = 0;
          this.items = [];

          this.update();
        }
      },
      locale: {
        handler () {
          this.offset = 0;
          this.items = [];
          this.values = {};

          this.update();
        }
      }
    }
  };
</script>

<style lang="scss">
// Не перемещать в ::v-deep, т.к. с appendToBody стили отвалятся
.vs__dropdown-menu {
    z-index: 9000;
    display: block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    width: 100%;
    max-width: 100%;
    max-height: 200px;
    overflow: auto;
    box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);
    border-radius: 5px;
    border: none;

    .vs__dropdown-option {
        position: relative;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 0.875rem;
        line-height: 1.5;
        display: block;
        padding: 0.375rem 1rem;
        color: #4a4a4a;
        white-space: nowrap;

        &--highlight {
            background: whitesmoke;
            color: #333;
        }
    }
}
</style>

<style lang="scss" scoped>
    .control {
        &::v-deep {
            .vs {
                $self: &;
                &__selected {
                    border: 1px solid rgba(140, 140, 140, .26);
                }

                &__spinner {
                    font-size: 3px;
                    border: .9em solid hsl(0deg 0% 100% / 10%);
                    border-left-color: rgba(60,60,60,.45);
                    animation: vSelectSpinner .7s linear infinite;
                    margin-right: 5px;
                }

                &__search::placeholder,
                &__dropdown-toggle,
                &__dropdown-menu {
                    font-family: inherit;
                    font-size: 0.875rem;
                    border-radius: 5px;
                    padding: 6px 0;
                }

                &__search {
                    padding: 0 4px;
                }

                &__clear,
                &__open-indicator {
                    fill: #ffffff;
                }

                &__dropdown-menu {
                    margin: 5px 0 5px;
                }
            }

            .vs__dropdown-toggle {
                @apply border-0;
                min-height: 40px;
            }

            &.not-empty {
                .vs__selected-options {
                    padding: 0 4px;
                    min-height: 25px; // предотвращает уменьшение высоты селекта при открытии
                }
            }

            &.vs {
                &--open {
                    .vs__dropdown-toggle {
                        border:none;
                    }

                    .vs__search {
                        margin-top: 0 !important;
                    }
                }

                &--disabled {
                    .vs__dropdown-toggle {
                        cursor: not-allowed;
                    }

                    .vs__actions {
                        * {
                            display: none;
                        }
                        .vs__spinner {
                            display: flex;
                        }
                    }
                }

                &--single {
                    .vs__selected-options {
                        white-space: nowrap; /* Запрещаем перенос строк */
                        overflow: hidden;
                        text-overflow: ellipsis;
                        margin-top: -4px;
                    }

                    .vs__selected {
                        background-color: transparent;
                        border-color: transparent;
                        .vs__open {
                            margin-top: 5px;
                        }

                        + .vs__search {
                            position: absolute;
                            top: 0;
                            left: 0;
                        }
                    }
                }
            }

            &.is-opened-top {
                top: auto;
                bottom: 100%;
            }

            &.is-fixed {
                .vs__selected-options {
                    white-space: pre;
                    overflow: hidden;
                    flex-wrap: unset;
                }
            }

            background-color: white;
            border-radius: 4px;
        }
    }

    .bordered {
        border: 1px solid #dee3ec;
    }

    .el-form-item.is-error {
        .bordered {
            border-color: #F56C6C;
        }
    }
</style>
