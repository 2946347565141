<template>
    <el-form
        class="m-auto"
        @submit.prevent.stop.native="updateUser">
        <el-alert
            :closable="false"
            show-icon
            type="info">
            <i18n
                path="common.auth.signUp.message"
                tag="span">
                <template #telegram>
                    <a
                        href="https://t.me/Natalya_Di"
                        rel="noopener">@Natalya_Di</a>
                </template>
            </i18n>
        </el-alert>
        <WebmasterProfile
            :user.sync="localForm">
            <template #buttons>
                <FButton
                    :loading="isSaveLoading"
                    type="success"
                    native-type="submit"
                    rounded
                    no-more
                    plain>
                    {{ $t(`webmaster.profile.values.save`) }}
                </FButton>
            </template>
        </WebmasterProfile>
        <b-loading :active="isSaveLoading"></b-loading>
    </el-form>
</template>

<script>
  import WebmasterProfile from "@/components/Common/Webmaster/WebmasterProfile";
  import { EDIT_USER } from "@core/store/action-constants";
  import { UPDATE_WEBMASTER_PROFILE_FORM } from "@core/store/mutation-constants";
  import _cloneDeep from "lodash/cloneDeep";
  import { mapActions, mapMutations, mapState } from "vuex";
  import FButton from "@/components/Common/FButton";

  export default {
    name: "SignUpDetailWebmaster",
    components: { WebmasterProfile, FButton },

    data () {
      return {
        localForm: null
      };
    },

    computed: {
      ...mapState("webmaster/profile", [
        "user",
        "form"
      ]),

      isSaveLoading () {
        return this.$wait(`webmaster/profile/${ EDIT_USER }`);
      }
    },

    methods: {
      ...mapActions("webmaster/profile", [
        EDIT_USER
      ]),

      ...mapMutations("webmaster/profile", [
        UPDATE_WEBMASTER_PROFILE_FORM
      ]),

      convertUserToForm () {
        const { user } = this;

        const form = {
          timeZone: user.timeZone.alias,
          telegram: user.contact.telegram,
          email: user.contact.email,
          categoriesPreferences: user.categoriesPreferences.map(({ id }) => id),
          trafficTypes: user.trafficTypes.map(({ id }) => id),
          trust: user.trust.alias,
          login: user.login,
          additional: {
            skype: user.skype,
            birthday: user.birthday
          }
        };

        this.UPDATE_WEBMASTER_PROFILE_FORM(form);
        this.localForm = _cloneDeep(this.form);
      },

      async updateUser () {
        try {
          await this.EDIT_USER();

          this.$message({
            message: this.$t("webmaster.profile.saveProfileSuccessConfirmation"),
            type: "success"
          });
        } catch (e) {
          this.$message({
            message: this.$t("webmaster.profile.saveProfileErrorConfirmation"),
            type: "error"
          });
        }
      }
    },

    watch: {
      localForm: {
        deep: true,
        handler (value) {
          this.UPDATE_WEBMASTER_PROFILE_FORM(value);
        }
      },

      user () {
        this.convertUserToForm();
      }
    }
  };
</script>

<style lang="scss" scoped>
    form {
        max-width: 800px;
    }
</style>
