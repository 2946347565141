import { AuditService } from "@core/services/admin/audit/AuditService";
import {
  GET_AUDIT,
  SYNC_STATISTIC_FILTERS
} from "@core/store/action-constants";
import {
  SET_AUDIT,
  SET_EMPTY,
  UPDATE_FILTERS,
  UPDATE_MODAL,
  UPDATE_PAGINATION
} from "@core/store/mutation-constants";
import { adminsList } from "@core/store/modules/admin/lists/adminsList";
import { RootState } from "@core/store/root-state";
import { AuditState } from "@core/store/types/admin/audit";
import { ActionTree, Module, MutationTree } from "vuex";
import { prepareBaseFilters } from "@core/store/modules/common/helpers/prepareFilters";
import { offersFilters } from "@core/store/modules/admin/statistic/modules/common/offersFilters";

const initialState = (): AuditState => {
  return {
    data: null,
    pagination: {
      perPage: 25,
      page: 1
    },
    filters: {},
    modal: {
      isActive: false,
      offer: null
    }
  };
};

export const state: () => AuditState = initialState;

const mutations: MutationTree<AuditState> = {
  [SET_EMPTY] (state) {
    Object.assign(state, initialState());
  },
  
  [SET_AUDIT] (state, audit) {
    state.data = audit;
  },
  
  [UPDATE_PAGINATION] (state, pagination) {
    state.pagination = { ...state.pagination, ...pagination };
  },
  
  [UPDATE_FILTERS] (state, filters) {
    state.filters = { ...state.filters, ...filters };
  },
  
  [UPDATE_MODAL] (state, modal) {
    state.modal = { ...state.modal, ...modal };
  }
};

const actions: ActionTree<AuditState, RootState> = {
  async [GET_AUDIT] ({ state, commit }) {
    const { filters, pagination: { perPage: limit, page } } = state;
    const offset = (page - 1) * limit;
    
    const data = await AuditService.getOfferAudits(limit, offset, filters);
    
    commit(SET_AUDIT, data);
  },
  
  [UPDATE_PAGINATION] ({ commit }, pagination) {
    commit(UPDATE_PAGINATION, pagination);
  },
  
  [UPDATE_FILTERS] ({ commit }, filter) {
    commit(UPDATE_FILTERS, filter);
    commit(UPDATE_PAGINATION, { page: 1 });
  },
  
  [UPDATE_MODAL] ({ commit }, modal) {
    commit(UPDATE_MODAL, modal);
  },
  
  [SYNC_STATISTIC_FILTERS] ({ dispatch }, filters) {
    const baseFilters = prepareBaseFilters(filters);
    // const userFilters = prepareUserFilters(filters, baseFilters);
  
    dispatch(`offersFilters/${ UPDATE_FILTERS }`, baseFilters);
  },
  
  [SET_EMPTY] ({ commit }) {
    commit("SET_EMPTY");
    commit("adminsList/SET_EMPTY");
    commit("offersFilters/SET_EMPTY");
  }
};

export const audit: Module<AuditState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    adminsList,
    offersFilters
  }
};
