export default [
  {
    path: "statistics",
    name: "statistics",
    redirect: { name: "webmaster:statistics:statistics" },
    component: (): Promise<any> => import(/* webpackChunkName: "webmaster:statistics" */ "@/views/Webmaster/StatisticView.vue"),
    children: [
      {
        path: "",
        name: "statistics",
        component: (): Promise<any> =>
          import(/* webpackChunkName: "webmaster:statistics:statistics" */ "@/components/Webmaster/Statistic/Statistic.vue")
      },
      {
        path: "detailing",
        name: "detailing",
        component: (): Promise<any> =>
          import(/* webpackChunkName: "webmaster:statistics:detailing" */ "@/components/Webmaster/Statistic/Detailing.vue")
      },
      {
        path: "postbacks",
        name: "postbacks",
        component: (): Promise<any> =>
          import(/* webpackChunkName: "webmaster:statistics:postbacks" */ "@/components/Webmaster/Statistic/Postback.vue")
      }
    ]
  }
];
