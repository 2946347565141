import { ASTGeneratedOutput, Generator } from "@core/services/common/Generator";
import { OffersFilters, OffersState } from "@core/store/types/admin/offers";

export default function offers (
  filters: OffersFilters,
  limit: number,
  offset: number,
  sort: OffersState["sort"],
  order: OffersState["order"],
  hasPermissionAdvertisers: boolean
): ASTGeneratedOutput {
  const params = {
    exclude: !hasPermissionAdvertisers ? [
      "items.permissions",
      "items.roles.id",
      "items.roles.name",
      "items.roles.permissions"
    ] : []
  };

  const gqlObject = {
    operation: "admins",
    
    fields: [
      {
        items: [
          "id",
          "intId",
          "login",
          "name",
          "skype",
          "permissions",
          {
            avatar: [
              "id",
              "url"
            ]
          },
          {
            contact: [
              "email",
              "telegram"
            ]
          },
          {
            countries: [
              "id",
              "alpha2",
              "name"
            ]
          },
          {
            activity: [
              "isBlocked",
              "isConfirmed",
              "lastLoginAt",
              "registeredAt",
              "updatedAt"
            ]
          },
          {
            roles: [
              "id",
              "name",
              "permissions"
            ]
          },
          {
            timeZone: [
              "alias",
              "continent",
              "offset"
            ]
          }
        ]
      },
      "count",
      "aggregates",
      "extras"
    ],
    
    variables: {
      filters: {
        type: "AdminListQueryFilterRegistry",
        value: filters
      },
      limit: {
        type: "Int",
        value: limit
      },
      offset: {
        type: "Int",
        value: offset
      },
      sort: {
        type: "AdminSort",
        value: sort
      },
      order: {
        type: "SortOrder",
        value: order
      }
    }

  };

  return Generator.query(gqlObject, params);
}
