<template>
    <div class="splash-screen">
        <!--suppress HtmlUnknownTarget -->
        <img
            alt="FairPay"
            src="@/assets/fairpay-splash.png">
        <el-container
            v-loading="true"></el-container>
    </div>
</template>

<script>
  export default {
    name: "SplashScreen"
  };
</script>

<style lang="scss" scoped>
    .splash-screen {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
        display: flex;
        position: fixed;
        align-items: center;
        justify-content: center;
        background-color: white;
        flex-direction: column;

        img {
            width: 60%;
            display: block;
            max-width: 280px;
        }

        .el-container {
            max-height: 100px;
            width: 100px;
        }
    }
</style>
