import { AxiosPromise } from "axios";
import Service from "@core/services/common/Service";
import editAdmin from "./graphql/editAdmin.graphql";
import createAdmin from "./graphql/createAdmin.graphql";
import getAdmin from "./graphql/getAdmin.graphql";
import changeAdminPassword from "./graphql/changeAdminPassword.graphql";
import {
  AdministratorResponse,
  EditInput,
  EditResponse,
  CreateResponse,
  AdministratorsPasswordEditInput,
  AdministratorsPasswordEditResponse
} from "@core/store/types/admin/users/administrators/Edit";

export class AdministratorsEditService extends Service {
  public static getAdmin (adminId: string): AxiosPromise<AdministratorResponse> {
    return this.api.get("", {
      params: {
        query: getAdmin,
        variables: {
          adminId
        }
      }
    });
  }

  public static editAdmin (adminId: string, input: EditInput): AxiosPromise<EditResponse> {
    return this.api.post("", {
      query: editAdmin,
      variables: {
        adminId,
        input
      }
    });
  }

  public static createAdmin (input: EditInput): AxiosPromise<CreateResponse> {
    return this.api.post("", {
      query: createAdmin,
      variables: {
        input
      }
    });
  }

  public static editPassword (adminId: string, input: AdministratorsPasswordEditInput): AxiosPromise<AdministratorsPasswordEditResponse> {
    return this.api.post("", {
      query: changeAdminPassword,
      variables: {
        adminId,
        input
      }
    });
  }
}
