import { CRMService } from "@core/services/admin/crm/CRMService";
import { DELETE_CRM, GET_CRM, GET_CRM_BY_ALIAS, UPDATE_IS_WITHOUT_REQUEST } from "@core/store/action-constants";
import { crmCreateModal } from "@core/store/modules/admin/crm/crmCreateModal";
import { SET_CRM, UPDATE_CRM_PAGINATION, UPDATE_CUSTOM_MACROS, UPDATE_MODAL_OPTIONS } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { CRMState } from "@core/store/types/admin/crm/CRMState";
import Vue from "vue";
import { ActionTree, Module, MutationTree } from "vuex";

const initialState = (): CRMState => {
  return {
    CRM: null,
    pagination: {
      page: 1,
      perPage: 25
    }
  };
};

const state: () => CRMState = initialState;

const mutations: MutationTree<CRMState> = {
  SET_EMPTY: state => Object.assign(state, initialState()),

  [SET_CRM] (state, items: CRMState["CRM"]) {
    state.CRM = items;
  },

  [UPDATE_CRM_PAGINATION] (state, pagination) {
    state.pagination = { ...state.pagination, ...pagination };
  }
};

const actions: ActionTree<CRMState, RootState> = {
  async [GET_CRM] ({ state, commit }) {
    const limit = state.pagination.perPage;
    const offset = (state.pagination.page - 1) * limit;

    try {
      const { data: { crms } } = await CRMService.getCRMs(
        limit,
        offset
      );

      commit(SET_CRM, crms);
    } catch (e) {
      throw e;
    }
  },

  async [GET_CRM_BY_ALIAS] ({ commit, dispatch }, alias) {

    try {
      const { data: { crm } } = await CRMService.getCRMByAlias(
        alias
      );
      if (!crm.recoveryDetail) {
        crm.recoveryDetail = {};
      }
      if (crm.integrationDetail) {
        if (crm.integrationDetail.requestBody) {
          Vue.set(crm.integrationDetail, "requestBody", JSON.stringify(crm.integrationDetail.requestBody));
        }
        if (crm.integrationDetail.requestHeaders) {
          Vue.set(crm.integrationDetail, "requestHeaders", JSON.stringify(crm.integrationDetail.requestHeaders));
        }
      } else {
        dispatch(`crmCreateModal/${ UPDATE_IS_WITHOUT_REQUEST }`, true);
      }
      if (crm.flowRedirect) {
        Vue.set(crm, "flowRedirect", JSON.stringify(crm.flowRedirect));
      }
  
      commit(`crmCreateModal/${ UPDATE_CUSTOM_MACROS }`, {
        customFields: crm.defaultOptions,
        customMacros: Object.keys(crm.defaultOptions)
      });
      commit(`crmCreateModal/${ UPDATE_MODAL_OPTIONS }`, crm);
    } catch (e) {
      throw e;
    }
  },

  async [DELETE_CRM] (_, alias) {

    try {
      await CRMService.deleteCRM(
        alias
      );

    } catch (e) {
      throw e;
    }
  },

  SET_EMPTY ({ commit }) {
    commit("SET_EMPTY");
  }
};

export const crm: Module<CRMState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    crmCreateModal
  }
};
