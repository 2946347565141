<template>
    <div :class="{ 'cursor-not-allowed': disabled }">
        <el-tag
            v-bind="$attrs"
            size="small"
            class="font-semibold"
            :class="[
                { 'pointer-events-none opacity-50': disabled },
                { 'cursor-pointer hover:opacity-90': isPointer },
                coloredTypes, customClass
            ]"
            v-on="$listeners">
            <slot></slot>
        </el-tag>
    </div>
</template>

<script>
  export default {
    name: "FTag",

    props: {
      disabled: {
        type: Boolean,
        default: false
      },

      type: {
        type: String,
        default: "primary"
      },

      customClass: {
        type: String,
        default: null
      },

      isPointer: {
        type: Boolean,
        default: true
      }
    },

    computed: {
      coloredTypes () {
        const auxiliaryClasses = "rounded bg-transparent";

        switch (this.type) {
          case "warning plain" : return `${ auxiliaryClasses } border-yellow-500 text-yellow-500`;
          case "success plain" : return `${ auxiliaryClasses } border-green-500 text-green-500`;
          case "danger plain" : return `${ auxiliaryClasses } border-red-600 text-red-600`;
          case "primary plain" : return `${ auxiliaryClasses } border-purple-900 text-purple-700`;
          case "info plain" : return `${ auxiliaryClasses } border-gray-500 text-gray-500`;
          case "warning" : return "bg-yellow-500 text-white";
          case "success" : return "bg-green-500 text-white";
          case "danger" : return "bg-red-600 text-white";
          case "primary" : return "bg-purple-900 text-white";
          case "dark" : return "bg-gray-900 text-white";
          default : return "bg-gray-500 text-white";
        }
      }
    }
  };
</script>

<style scoped>
</style>