import Service from "@core/services/common/Service";
import { PaginationAdministratorsState } from "@core/store/types/admin/users/administrators/AdministratorsState";
import { AxiosPromise } from "axios";
import admins from "./graphql/admins.generator";
import blockAdmin from "./graphql/blockAdmin.graphql";
import unblockAdmin from "./graphql/unBlockAdmin.graphql";

export class AdministratorsService extends Service {
  public static async getAdministrators (
    filters: PaginationAdministratorsState["filters"],
    limit: number,
    offset: number,
    sort: PaginationAdministratorsState["sort"],
    order: PaginationAdministratorsState["order"],
    hasPermissionAdvertisers: boolean
  ): Promise<AxiosPromise> {
    try {
      return await this.api.get("", {
        params: admins(
          filters,
          limit,
          offset,
          sort,
          order,
          hasPermissionAdvertisers
        )
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static async blockAdministrator (
    adminId: string
  ): Promise<AxiosPromise> {
    try {
      return await this.api.post("", {
        query: blockAdmin,
        variables: {
          adminId
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static async unBlockAdministrator (
    adminId: string
  ): Promise<AxiosPromise> {
    try {
      return await this.api.post("", {
        query: unblockAdmin,
        variables: {
          adminId
        }
      });
    } catch (e) {
      throw e;
    }
  }
}
