import Service from "@/services/common/Service";
import { LanguagesListResponse } from "@/store/types/admin/lists/LanguagesListState";
import { AxiosPromise } from "axios";
import languages from "../../admin/lists/graphql/languages.graphql";

export class LanguagesListService extends Service {
  public static async getLanguagesList (
    limit: number,
    offset: number

  ): Promise<AxiosPromise<LanguagesListResponse>> {
    try {
      return await this.api.get("", {
        params: {
          query: languages,
          variables: {
            limit,
            offset
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
}
