<template>
    <el-form
        ref="form"
        :model="model"
        @submit.prevent.stop.native="submit">
        <el-dialog
            :visible.sync="isModalActive"
            :destroy-on-close="destroyOnClose"
            :width="width"
            :z-index="zIndex"
            @close="$emit('close', $event)"
            @closed="$emit('after-leave', $event)">
            <template #title>
                <slot name="header"></slot>
            </template>

            <slot></slot>

            <template #footer>
                <slot name="footer"></slot>
            </template>
        </el-dialog>
    </el-form>
</template>

<script>
  import { Modal } from "@core/mixins";

  export default {
    name: "ModalBox",
    mixins: [Modal],
    props: {
      autocomplete: {
        type: Boolean,
        default: false
      },
      model: {
        type: Object,
        default: null
      },
      destroyOnClose: {
        type: Boolean,
        default: true
      },
        
      width: {
        type: String,
        default: "50%"
      },

      zIndex: {
        type: String,
        default: "40"
      }
    },

    methods: {
      cancel () {
        this.$emit("cancel");
      },
  
      confirm () {
        this.$emit("confirm");
      },
        
      submit (value) {
        if (this.model) {
          this.$refs.form.validate((valid) => {
            if (valid) {
              this.$emit("submit", value);
            } else {
              return false;
            }
          }); 
        } else {
          this.$emit("submit", value);
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
    ::v-deep {
        .el-dialog__footer {
            @apply flex;
        }
    }

    @keyframes error {
        $error: #fc5c5c;
        0% {
            border: 2px solid white;
        }

        25% {
            border: 2px solid $error;
        }

        50% {
            border: 2px solid white;
        }

        75% {
            border: 2px solid $error;
        }

        100% {
            border: 2px solid white;
        }
    }

    .modal-card {
        border: 2px solid white;
    
        &.error {
            animation: 1500ms error;
        }
    
        ::v-deep {
            .common-table {
                thead {
                    tr {
                        th {
                            top: 0 !important;
                        }
                    }
                }
            }
        }
    }
</style>
