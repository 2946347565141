<script>
  export default {
    name: "BaseTooltip",

    props: {
      label: {
        type: String,
        default: null
      },

      position: {
        type: String,
        default: "is-top"
      },

      arrow: {
        type: Boolean,
        default: true
      },

      withoutArrow: {
        type: Boolean,
        default: false
      },

      offset: {
        type: Number,
        default: 3
      },

      delay: {
        type: [Number, Array],
        default: () => [0, 20]
      },

      ignoreAttributes: {
        type: Boolean,
        default: true
      },

      type: {
        type: String,
        default: "is-primary"
      },

      trigger: {
        type: String,
        default: "mouseenter click"
      },

      always: {
        type: Boolean,
        default: false
      },

      disabled: {
        type: Boolean,
        default: false
      },

      interactive: {
        type: Boolean,
        default: false
      },

      flipOnUpdate: {
        type: Boolean,
        default: true
      },

      size: {
        type: String,
        default: "regular",
        validation: (value) => [
          "small",
          "regular",
          "large",
          "is-small",
          "is-regular",
          "is-large"
        ].includes(value)
      },

      active: {
        type: Boolean,
        default: true
      },

      maxWidth: {
        type: [Number, String],
        default: 350
      },

      flipBehavior: {
        type: [String, Array],
        default: "flip"
      },

      ignoreFlip: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      getPosition () {
        return this.position?.replace("is-", "");
      },

      getTheme () {
        return this.type?.replace("is-", "");
      },

      getSize () {
        return this.size?.replace("is-", "");
      },

      getBoundary () {
        return this.ignoreFlip ? "window" : "scrollParent";
      }
    }
  };
</script>