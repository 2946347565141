import { GET_CORRECTION_BY_VALUE, GET_CORRECTION_TYPES } from "@core/store/action-constants";
import { UPDATE_CORRECTION_TYPES_LIST } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import {
  CorrectionType,
  CorrectionTypesListItem,
  CorrectionTypesListState
} from "@core/store/types/admin/finances/financesCorrection/lists/correctionsList";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

const initialState = (): CorrectionTypesListState => {
  return {
    data: {}
  };
};

const state: () => CorrectionTypesListState = initialState;

const getters: GetterTree<CorrectionTypesListState, RootState> = {
  [GET_CORRECTION_BY_VALUE]: (state) => function (item: CorrectionType): CorrectionTypesListItem | undefined {
    return state.data.items?.find(({ value }: CorrectionTypesListItem) => value.from === item.from && value.to === item.to);
  }
};

const mutations: MutationTree<CorrectionTypesListState> = {
  [UPDATE_CORRECTION_TYPES_LIST] (state, correctionTypes: CorrectionTypesListState["data"]) {
    state.data = correctionTypes;
  }
};

const actions: ActionTree<CorrectionTypesListState, RootState> = {
  [GET_CORRECTION_TYPES] ({ commit }) {
    const items = [
      {
        label: "admin.finances.correction.create.correction.partnerProgramToWebmaster",
        value: { from: null, to: "webmaster" }
      },
      {
        label: "admin.finances.correction.create.correction.partnerProgramToAdvertiser",
        value: { from: null, to: "advertiser" }
      },
      {
        label: "admin.finances.correction.create.correction.advertiserToPartnerProgram",
        value: { from: "advertiser", to: null }
      },
      {
        label: "admin.finances.correction.create.correction.advertiserToWebmaster",
        value: { from: "advertiser", to: "webmaster" }
      },
      {
        label: "admin.finances.correction.create.correction.webmasterToPartnerProgram",
        value: { from: "webmaster", to: null }
      },
      {
        label: "admin.finances.correction.create.correction.webmasterToAdvertiser",
        value: { from: "webmaster", to: "advertiser" }
      }
    ];
    commit(UPDATE_CORRECTION_TYPES_LIST, {
      items,
      count: items.length
    });
  }
};

export const correctionTypesList: Module<CorrectionTypesListState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
