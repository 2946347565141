import { UploadsService } from "@/services/admin/finances/uploads/UploadsService";
import {
  FILES_UPLOADED_IDS,
  GET_LINK_FOR_DOWNLOAD,
  GET_UPLOADS,
  GET_UPLOADS_FILE_READY_PERCENT
} from "@/store/action-constants";
import {
  SET_UPLOADS,
  UPDATE_FILE_LINK,
  UPDATE_FILES_READY_PERCENT,
  UPDATE_FILES_UPLOADED,
  UPDATE_UPLOADS_FILTERS,
  UPDATE_UPLOADS_PAGINATION,
  UPDATE_UPLOADS
} from "@/store/mutation-constants";
import { RootState } from "@/store/root-state";
import { UploadsListItem, UploadsState } from "@/store/types/admin/uploads/UploadsState";
import Vue from "vue";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { uploadFile } from "@/store/modules/common/uploads/uploadFile";

function debounceUpdateFiles (): any {
  // @ts-ignore
  // eslint-disable-next-line no-invalid-this
  const func = (): Promise => this.dispatch(`uploads/${ GET_UPLOADS_FILE_READY_PERCENT }`);
  setTimeout(func, 3000);
}

const initialState = (): UploadsState => {
  return {
    uploads: null,
    filters: {},
    pagination: {
      page: 1,
      perPage: 25
    },
    isFiledUploaded: false
  };
};

const state: () => UploadsState = initialState;

const getters: GetterTree<UploadsState, RootState> = {
  [FILES_UPLOADED_IDS]: state => state.uploads?.items?.map(item => {
    if (["PROCESSING", "UNPROCESSED"].includes(item.status)) {
      return item.id;
    }
  }).filter(Boolean)
};

const mutations: MutationTree<UploadsState> = {
  SET_EMPTY: state => Object.assign(state, initialState()),
  
  [SET_UPLOADS] (state, items: UploadsState["uploads"]) {
    state.uploads = items;
  },
  
  [UPDATE_UPLOADS_FILTERS] (state, filter: any) {
    state.filters = { ...state.filters, ...filter };
  },
  
  [UPDATE_UPLOADS_PAGINATION] (state, pagination) {
    state.pagination = { ...state.pagination, ...pagination };
  },
  
  [UPDATE_UPLOADS] (state, payload) {
    state.uploads = {
      count: payload.count,
      items: state.uploads?.items?.concat(payload.items)
    };
  },
  
  [UPDATE_FILES_READY_PERCENT] (state, payload) {
    if (state.uploads) {
      const updatedFiles = state.uploads.items?.map(item => {
        if (Object.keys(payload).includes(item.id)) {
          return {
            ...item,
            status: payload[item.id]?.status,
            readyPercent: payload[item.id]?.readyPercent
          };
        } else {
          return item;
        }
      });

      Vue.set(state.uploads, "items", updatedFiles);
    }
  },
  
  [UPDATE_FILES_UPLOADED] (state, payload: boolean) {
    state.isFiledUploaded = payload;
  },

  [UPDATE_FILE_LINK] (state, payload) {
    if (state.uploads && state.uploads.items) {
      const fileIndex = state.uploads.items.findIndex(item => item.id === payload.id);
      Vue.set(state.uploads.items[fileIndex], "link", payload.link);
    }
  }
};

const actions: ActionTree<UploadsState, RootState> = {
  async [GET_UPLOADS] ({ state, commit, dispatch, getters }) {
    const limit = state.pagination.perPage;
    const offset = (state.pagination.page - 1) * limit;
    const { ...filters } = state.filters;

    try {
      const { data: { queryExports } } = await UploadsService.queryExports(
        limit,
        offset,
        {
          ...filters,
          isDownloaded: false
        }
      );
      queryExports.items = queryExports.items.filter((item: UploadsListItem) => {
        if (item.status === "DONE") {
          dispatch(GET_LINK_FOR_DOWNLOAD, item.id);
        }
        return item;
      });
      
      if (offset === 0) {
        commit(SET_UPLOADS, queryExports);
      } else {
        commit(UPDATE_UPLOADS, queryExports);
      }
      
    } catch (e: any) {
      throw new Error(e);
    }
    
    if (getters[FILES_UPLOADED_IDS].length !== 0 && !state.isFiledUploaded) {
      dispatch(GET_UPLOADS_FILE_READY_PERCENT);
    }
  },
  
  async [GET_UPLOADS_FILE_READY_PERCENT] ({ commit, getters, dispatch }) {
    commit(UPDATE_FILES_UPLOADED, true);
    const uploadedFilesIds = getters[FILES_UPLOADED_IDS];
    const limit = uploadedFilesIds.length;
    try {
      const { data: { queryExports } } = await UploadsService.getFilesReadyPercent(
        limit,
        0,
        {
          id: uploadedFilesIds
        }
      );

      const files: { [key: string]: unknown } = {};
      queryExports.items.forEach((item: UploadsListItem) => {
        const { id, ...options } = item;
        files[id] = options;

        if (options.status === "DONE") {
          dispatch(GET_LINK_FOR_DOWNLOAD, id);
        }
      });
  
      commit(UPDATE_FILES_READY_PERCENT, files);
    } catch (e: any) {
      throw new Error(e);
    }
    if (getters[FILES_UPLOADED_IDS].length !== 0) {
      debounceUpdateFiles.call(this);
    } else {
      commit(UPDATE_FILES_UPLOADED, false);
    }
  },
  
  async [GET_LINK_FOR_DOWNLOAD] ({ commit }, queryExportId) {
    try {
      const { data: { fileExport } } = await UploadsService.fileExport(queryExportId);
      
      commit(UPDATE_FILE_LINK, {
        link: fileExport,
        id: queryExportId
      });
      
    } catch (e: any) {
      throw new Error(e);
    }
  },

  [UPDATE_UPLOADS_PAGINATION] ({ dispatch, commit }, payload) {
    commit(UPDATE_UPLOADS_PAGINATION, payload);
    dispatch(GET_UPLOADS);
  },
  
  // [UPDATE_UPLOADS_FILTERS] ({ dispatch, commit }, payload) {},
  
  SET_EMPTY ({ commit }) {
    commit("SET_EMPTY");
  }
};

export const uploads = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
  modules: {
    uploadFile
  }
};
