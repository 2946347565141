<template>
    <el-form-item
        ref="field"
        :prop="prop"
        :label="label"
        class="flex flex-col"
        :error="error"
        :rules="rules ? rules : customRules">
        <slot
            :value="value"
            :update="update"
            :resetField="resetField">
            <FInput
                v-bind="$attrs"
                :min="min"
                :value="value"
                :bordered="bordered"
                v-on="$listeners"
                @input="update">
                <template #append>
                    <slot name="append"></slot>
                </template>
                <template #prepend>
                    <slot name="prepend"></slot>
                </template>
            </FInput>
        </slot>
    </el-form-item>
</template>

<script>
   /*
   * В минимальном виде компонент представляет:
   *
   * props: [ value, label, required, rules, prop, pattern, patternMessage, bordered, min ]
   * value - значение передаваемое в инпут
   * label - описание с пометкой обязательно
   * rules - объект правил для валидации, содержит:
   *    required - обязательна ли валидация, default - true
   *    message - сообщение при не удачной проверке
   *    min - минимальное значение передаваемое в инпут
   *    pattern - регулярное выражение для валидации
   * patternMessage - кастомное сообщение об ошибке для паттерна
   * bordered - логическое значение для стилей инпута
   * */

  import FInput from "@/components/Common/FInput";
  export default {
    name: "RequiredField",
    components: { FInput },
    props: {
      value: {
        type: null,
        default: null
      },
      
      label: {
        type: String,
        default: ""
      },

      required: {
        type: Boolean,
        default: true
      },

      rules: {
        type: [Object, Array],
        default: null
      },

      prop: {
        type: String,
        required: true
      },

      pattern: {
        type: [String, RegExp],
        default: null
      },

      patternMessage: {
        type: String,
        default: "common.validation.pattern"
      },

      bordered: {
        type: Boolean,
        default: true
      },

      min: {
        type: [String, Number],
        default: Infinity
      },

      error: {
        type: String,
        default: null
      }
    },

    computed: {
      customRules () {
        // const x =
        const rules = [
          { required: this.required, message: this.$t("common.validation.required") }
        ];
        if (this.pattern) {
          rules.push({ message: this.$t(this.patternMessage), pattern: this.pattern });
        }
        if (this.min != null && this.$attrs.type === "number") {
          rules.push({ type: "number", min: this.min, message: `${ this.$t("common.validation.minimum") } ${ this.min }.` });
        }
        return rules;
      }
    },

    methods: {
      update (value) {
        this.$emit("input", value);
      },

      resetField () {
        this.$refs.field.resetField();
      }
    }
  };
</script>

<style lang="scss">
        .el-form-item__label {
            text-align: left;
        }

        .el-form-item.is-error {
            .el-input {
                @apply border rounded border-red-400;

                    input {
                        @apply border-none;
                    }
            }
        }

        .el-input-number {
            @apply w-full;
        }
</style>