import { Type, WithdrawType } from "@core/logic/common/common";
import { PaginationOutput } from "@core/logic/common/pagination-output";
import { PaginationParams } from "@core/logic/common/pagination-params";
import { HoldTime } from "@core/store/types/admin/offer/OfferCommon";
import { AxiosPromise } from "axios";
import Service from "@core/services/common/Service";

interface OffersRequestFilters {
  nameOrIntId: string;
  dateStart: Date;
  dateEnd: Date;
  categories: string;
  countries: string;
  isMine: boolean;
  type: string;
  trafficTypes: string;
  withdrawType: string;
  isNewCreated: boolean;
  regions: string;
}

interface TrafficType {
  name: string;
  id: string;
}

interface OffersQuery extends PaginationParams {
  filters: OffersRequestFilters;
  sort: string;
  order: string;
}
export interface Category {
  name: string;
  id: string;
}

export interface Country {
  id: string;
  name: string;
  alpha2: string;
  alpha3: string;
}

export interface Detail {
  name: string;
  description: string;
  rules: string;
  isInHouse: boolean;
}

export interface Finance {
  webmasterReward: number;
  withdrawType: number;
  holdTime: HoldTime;
  minimalCommission: Nullable<number>;
}

export interface OfferResponse {
  id: string;
  intId: number;
  trafficTypes: TrafficType[];
  detail: Detail;
  finance: Finance;
  type: Type;
  name: string;
  isPostbackEnable: boolean;
  isWebmasterJoinedToOffer: boolean;
  landing: null | {
    cost: number;
    preCost: number;
  };
  photoUrl: string;
  landingUrl: string;
  landingParameters: string[];
  logo: Logo;
  webmasterReward: number;
  withdrawType: WithdrawType;
  categories: [];
  countries: Country[];
  regions: string[];
  createdAt: string;
  advertisers: Advertisers;
  isNewCreated: boolean;
  minimalCommission: Nullable<number>;
  advertiserMinimalCommission: Nullable<number>;
}

export interface Advertisers extends PaginationOutput {
  items: Advertiser[];
}

interface Logo {
  id: string;
  url: string;
}

export interface Advertiser {
  writeOff: number;
}

/* tslint:disable:max-classes-per-file */
export class Offer {
  public id: string | undefined;
  public intId: number | undefined;
  public isPostbackEnable: boolean | undefined;
  public isWebmasterJoinedToOffer: boolean | undefined;
  public cost: number | undefined = 0;
  public preCost: number | undefined = 0;
  public logo?: Logo;
  public photoUrl: string | undefined;
  public landingUrl: string | undefined;
  public name: string | undefined;
  public type: Type | undefined;
  public webmasterReward: number | undefined;
  public withdrawType: WithdrawType | undefined;
  public categories: [] | undefined;
  public countries: Country[] | null | undefined;
  public regions: string[] | undefined;
  public createdAt: string | undefined;

  constructor (payload: OfferResponse) {
    if (payload) {
      this.id = payload.id;
      this.intId = payload.intId;
      this.name = payload.name;
      this.isPostbackEnable = payload.isPostbackEnable;
      this.isWebmasterJoinedToOffer = payload.isWebmasterJoinedToOffer;
      this.preCost = payload.landing?.preCost;
      this.cost = payload.landing?.cost;
      this.logo = payload.logo;
      this.photoUrl = payload.photoUrl;
      this.landingUrl = payload.landingUrl;
      this.type = payload.type;
      this.webmasterReward = payload.webmasterReward;
      this.withdrawType = payload.withdrawType;
      this.categories = payload.categories;
      this.regions = payload.regions;
      this.countries = payload.countries;
      this.createdAt = payload.createdAt;
    }
  }
}

export interface LandingResponse {
  id: string;
  name: string;
  offerId: string;
  discriminator: string;
  domains: Record<string, unknown>[];
  options: Record<string, unknown>;
  createdAt: Date;
}

export class Landing {
  public id: string;
  public name: string;
  public offerId: string;
  public discriminator: string;
  public domains: Record<string, unknown>[];
  public options: Record<string, unknown>;
  public createdAt: Date;

  constructor ({
    id,
    name,
    offerId,
    discriminator,
    domains,
    options,
    createdAt
  }: LandingResponse) {
    this.id = id;
    this.name = name;
    this.offerId = offerId;
    this.discriminator = discriminator;
    this.domains = domains;
    this.options = options;
    this.createdAt = createdAt;
  }
}

export interface Offers extends PaginationOutput {
  items: Offer[];
}

export interface ResponseOffers extends PaginationOutput {
  items: OfferResponse[];
}

export class OffersService extends Service{
  public static getOffers (params: OffersQuery): AxiosPromise<ResponseOffers> {
    return this.rest.get("/webmaster/offers", { params });
  }

  public static joinOffer (id: string): AxiosPromise<OfferResponse> {
    return this.rest.post(`/webmaster/offers/${ id }/join`);
  }

  public static excludeOffer (id: string): AxiosPromise<OfferResponse> {
    return this.rest.post(`/webmaster/offers/${ id }/exclude`);
  }

  public static getOffer (id: string): AxiosPromise<OfferResponse> {
    return this.rest.get(`/webmaster/offers/${ id }`);
  }

  public static getLandings (offerId: string, params: any): AxiosPromise<LandingResponse> {
    return this.rest.get(`/webmaster/offers/${ offerId }/pages`, { params });
  }
}
