import { IntegrationCRMListService } from "@core/services/admin/offer/lists/IntegrationCRMListService";
import {
  GET_INTEGRATION_CRM,
  UPDATE_INTEGRATION_CRM_FILTERS,
  UPDATE_INTEGRATION_CRM_LIST_PAGINATION
} from "@core/store/action-constants";
import { SET_INTEGRATION_CRM_LIST, UPDATE_INTEGRATION_CRM_LIST } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { PartialAdminsListState } from "@core/store/types/admin/lists/AdminsListState";
import { IntegrationCRM, IntegrationCRMListState } from "@core/store/types/admin/offer/lists/IntegrationCRMListState";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

const initialState = (): IntegrationCRMListState => {
  return {
    data: {},
    filters: {
      offer: null
    },
    pagination: {
      page: 1,
      perPage: 25
    }
  };
};

const state: () => IntegrationCRMListState = initialState;

const getters: GetterTree<IntegrationCRMListState, RootState> = {};

const mutations: MutationTree<IntegrationCRMListState> = {
  SET_EMPTY: state => Object.assign(state, initialState()),

  [SET_INTEGRATION_CRM_LIST] (state, integrationCRM: IntegrationCRMListState["data"]) {
    state.data = integrationCRM;
  },

  [UPDATE_INTEGRATION_CRM_LIST] (state, integrationCRM: IntegrationCRMListState["data"]) {
    state.data = {
      count: integrationCRM.count,
      items: state.data?.items?.concat(integrationCRM.items as IntegrationCRM[])
    };
  },

  [UPDATE_INTEGRATION_CRM_LIST_PAGINATION] (state) {
    if (state.data?.items && state.data.items.length >= state.pagination.perPage) {
      state.pagination.page = Math.ceil(state.data.items.length / state.pagination.perPage) + 1;
    }
  },

  [UPDATE_INTEGRATION_CRM_FILTERS] (state, filters: PartialAdminsListState["filters"]) {
    state.filters = { ...state.filters, ...filters };
  }
};

const actions: ActionTree<IntegrationCRMListState, RootState> = {
  async [GET_INTEGRATION_CRM] ({ state, commit, rootState }) {
    const limit = state.pagination.perPage;
    const offset = (state.pagination.page - 1) * limit;
    const offer = state.filters.offer ? state.filters.offer : rootState.admin.offer.edit.offerId;

    const { data: { integrations } } =
            await IntegrationCRMListService.getIntegrationsCRM(
              limit,
              offset,
              { offer }
            );
    if (offset === 0) {
      commit(SET_INTEGRATION_CRM_LIST, integrations);
    } else {
      commit(UPDATE_INTEGRATION_CRM_LIST, integrations);
    }
  },

  [UPDATE_INTEGRATION_CRM_LIST_PAGINATION] ({ commit }, payload: any) {
    commit(UPDATE_INTEGRATION_CRM_LIST_PAGINATION, payload);
  },

  [UPDATE_INTEGRATION_CRM_FILTERS] ({ commit }, payload: any) {
    commit(UPDATE_INTEGRATION_CRM_FILTERS, payload);
  }
};

export const integrationCRMList: Module<IntegrationCRMListState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
