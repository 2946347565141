<template>
    <RequiredField
        :value="value"
        prop="_birthday"
        :required="false">
        <div
            v-click-outside="updateValidation"
            class="block border rounded mb-0 laptop:flex"
            :class="{'invalid': isEveryValueValidDate && hasFilledValue }">
            <div class="w-full">
                <FInput
                    v-model.number="day"
                    :placeholder="$t(`common.components.datepicker.birthday.day`)"
                    type="number"
                    :bordered="false"
                    :min="1"
                    :max="daysInSelectedMonth">
                </FInput>
            </div>
            <div class="w-full">
                <FSelect
                    v-model="month"
                    :get-data="getMonths"
                    prop="value"
                    :class="{ 'is-error': isEveryValueValidDate && hasFilledValue }"
                    :bordered="false"
                    :placeholder="$t(`common.components.datepicker.birthday.month`)">
                </FSelect>
            </div>

            <div class="w-full">
                <FInput
                    v-model.number="year"
                    :placeholder="$t(`common.components.datepicker.birthday.year`)"
                    type="number"
                    :bordered="false"
                    :min="minAcceptableYear"
                    :max="maxAcceptableYear">
                </FInput>
            </div>
        </div>
        <div
            v-show="isEveryValueValidDate && hasFilledValue"
            class="el-form-item__error">
            {{ isEveryValueValidDate && hasFilledValue ? invalidMessage : null }}
        </div>
    </RequiredField>
</template>

<script>
  import momentjs from "moment";
  import ClickOutside from "vue-click-outside";
  import FSelect from "@/components/Common/FSelect";
  import RequiredField from "@/components/Common/RequiredField";
  import FInput from "@/components/Common/FInput";
  
  export default {
    name: "BirthDatepicker",
    components: {
      FInput,
      RequiredField,
      FSelect
    },

    props: {
      value: {
        type: String,
        default: null
      },
      
      required: {
        type: Boolean,
        default: false
      }
    },

    directives: {
      ClickOutside
    },

    mounted () {
      if (this.value) {
        this.setDate();
      }
    },

    data () {
      return {
        day: null,
        month: null,
        year: null,
        invalidMessage: null,
        isValidate: false
      };
    },
      
    updated () {
      if (this.isValidDate) {
        this.$emit("input", this.date);
      } else {
        this.$emit("input", null);
      }
    },
      
    computed: {
      daysInSelectedMonth () {
        return new Date(this.year, this.month, 0).getDate();
      },
      
      maxAcceptableYear () {
        const UNTIL_ADULTHOOD = 18;
        return new Date().getFullYear() - UNTIL_ADULTHOOD;
      },

      minAcceptableYear () {
        const LIFESPAN = 100;
        return this.maxAcceptableYear - LIFESPAN;
      },
      
      date () {
        const { year, month, day } = this;

        if (+year && month !== null && +day) {
          const date = `${ year }-${ ("0" + month).slice(-2) }-${ day }`;
          return momentjs(date, this.local).utc().format();
        } return null;
      },

      hasInvalidDay () {
        return +this.day < 1 || +this.day > this.daysInSelectedMonth;
      },

      hasInvalidMonth () {
        return !this.month;
      },

      hasInvalidYear () {
        return +this.year < this.minAcceptableYear || +this.year > this.maxAcceptableYear;
      },
      
      isValidDate () {
        return this.date ? momentjs(this.date).isValid() : false;
      },

      isEveryValueValidDate () {
        return this.isValidate && this.hasInvalidDay || this.isValidate && this.hasInvalidMonth || this.isValidate && this.hasInvalidYear;
      },
        
      hasFilledValue () {
        return !!this.day || !!this.month || !!this.year;
      }
    },
    
    methods: {
      getMonths () {
        const months = this.$t("common.months").map((month, index) => {
          return {
            name: month,
            value: index + 1
          };
        });

        return {
          items: months,
          count: months.length
        };
      },
      
      setDate () {
        const [ day, month, year ] = momentjs(this.value)
          .format("D.M.YYYY")
          .split(".");
        this.year = year;
        this.month = Number(month);
        this.day = day;
      },

      updateValidation () {
        if (this.hasFilledValue || this.required) {
          this.isValidate = true;
        }
        if (this.hasInvalidDay) {
          this.invalidMessage = this.$t("common.components.datepicker.birthday.messages.day", {
            maxAcceptableDay: this.daysInSelectedMonth
          });
        } else if (this.hasInvalidMonth) {
          this.invalidMessage = this.$t("common.components.datepicker.birthday.messages.month");
        } else if (this.hasInvalidYear) {
          this.invalidMessage = this.$t("common.components.datepicker.birthday.messages.year", {
            minAcceptableYear: this.minAcceptableYear,
            maxAcceptableYear: this.maxAcceptableYear
          });
        } else if (this.hasFilledValue) {
          this.invalidMessage = this.$t("common.components.datepicker.birthday.messages.date");
        } else {
          this.invalidMessage = null;
        }
      }
    },
    watch: {
      hasFilledValue (value) {
        if (!value) {
          this.isValidate = false;
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
    $border-color: #dbdbdb;
    $border-color-invert: #b5b5b5;

    // Убирает стрелки в input
    ::v-deep {
        .el-input {
            @apply border-none;

            input[type="number"] {
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                & {
                    -moz-appearance: textfield;
                }
                &:hover, &:focus {
                    -moz-appearance: number-input;
                }
            }
        }
    }

    .invalid {
      border-color: $danger;
    }

    ::v-deep {
        .el-input-number {
            input {
                @apply border-none text-left;
            }
        }

        .v-select {
            @apply rounded-none laptop:border-l laptop:border-r max-h-10;

            @media screen and (max-width: 769px) {
                @apply border-t border-b;
            }

            .vs__dropdown-toggle {
                @apply max-h-10;
            }
        }

        .is-error {
            .v-select {
                @apply border-red-400 rounded-none;
            }
        }
    }
</style>