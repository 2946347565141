import Axios from "axios";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@/store/root-state";

export type BalanceItem = {
  value: number;
  currency: string;
}

export interface BalanceState {
  balance?: BalanceItem;
  updatedAt?: Date;
}

// Делает из массива объектов объект массивов
function arrayToArrayObject (array: Array<BalanceState>) {
  return array?.reduce((acc, obj) => {
    const keys = Object.keys(obj);
    const values = Object.values(obj);
    keys.forEach((key, index) => {
      // @ts-ignore
      acc[key] ??= [];
      // @ts-ignore
      acc[key].push(values[index]);
    });
    return acc;
  }, {});
}

const state: BalanceState = {
  balance: undefined
};

const getters: GetterTree<BalanceState, RootState> = {};

const mutations: MutationTree<BalanceState> = {
  SET_BALANCE: (state, { balance }) => {
    state.balance = balance;
  }
};

const actions: ActionTree<BalanceState, RootState> = {
  GET_WEBMASTER_BALANCE ({ commit }): Promise<void> {
    return Axios.get("/webmaster/finances/balance").then(response => {
      const balance = arrayToArrayObject(response.data) as Promise<void>;
  
      commit("SET_BALANCE", balance);
      return balance;
    });
  
  },
  GET_ADVERTISER_BALANCE ({ commit }): Promise<void> {
    return Axios.get("/advertiser/finances/balance").then(response => {
      const balance = arrayToArrayObject(response.data) as Promise<void>;
  
      commit("SET_BALANCE", balance);
      return balance;
    });
  
  }
};


export const balance: Module<BalanceState, RootState> = {
  state,
  getters,
  actions,
  mutations
};
