import { StatisticsService } from "@core/logic/admin/statistic/statistic";
import {
  GET_STATISTIC,
  GET_ACTIVE_COUNTS,
  GET_STATISTIC_TOP_CARD_TABLE_DATA
} from "@core/store/action-constants";
import {
  SET_STATISTIC,
  SET_ACTIVE_COUNTS,
  UPDATE_TOP_CARD_AMOUNT_TYPE,
  UPDATE_TOP_CARD_FILTERS,
  UPDATE_TOP_CARD_PERIODS,
  UPDATE_PAGINATION,
  SET_CURRENCY
} from "@core/store/mutation-constants";
import {
  DashboardTopCardRowData,
  DashboardTopCardState,
  DashboardTopCardItem
} from "@core/store/types/admin/lists/DashboardListState";
import {
  ActionTree,
  GetterTree,
  Module, ModuleTree,
  MutationTree
} from "vuex";
import store from "../../../../../../../store";
import { RootState } from "@core/store/root-state";
import { momentInst } from "@core/filters";
import moment from "moment";
import { serializeItems } from "@core/store/modules/admin/dashboard/modules/serializeItems";
import { isArray } from "lodash";

export class DashboardTopCard implements Module<DashboardTopCardState, RootState> {
  public namespaced: boolean;
  public state: DashboardTopCardState | undefined;
  public modules: ModuleTree<RootState>;
  
  public getters: GetterTree<DashboardTopCardState, RootState> = {
    [GET_STATISTIC_TOP_CARD_TABLE_DATA]: (state) => {
      const label = state.periods?.label;
      const groupType = state.groupType;
      // @ts-ignore
      return serializeItems(state.cardStatistic, groupType, label)
        // @ts-ignore
        .map((item: DashboardTopCardItem) => {
          const dates = [];
          const amountType = state.amountType;
          const amount = amountType ? item.amount[amountType] : item.amount.leads;
          
          for (let i = 6; i >= 0; i--) {
            let date;
            if (label === "week") {
              date = moment().clone().startOf(label).add(1, "day").subtract(i, label).format("YYYY-MM-DD");
            } else if (label === "month") {
              date = moment().clone().startOf(label).subtract(i, label).format("YYYY-MM-DD");
            } else {
              date = moment().clone().subtract(i, label).format("YYYY-MM-DD");
            }
            const format = label === "day" ? item.dates[date + " 00:00:00"] : item.dates[date];
            
            dates.push(format || 0);
          }
          
          const datesFilter = dates.map(element => {
            if (isArray(element)) {
              // @ts-ignore
              return element.find(el => el[groupType].id === item.item.id) || 0;
            }
            return element;
          });
          
          return {
            ...item,
            amountType,
            dates: datesFilter,
            amount
          };
        })
        // @ts-ignore
        .sort((a: DashboardTopCardRowData, b: DashboardTopCardRowData) => {
          return b.amount - a.amount;
        });
    }
  };
  
  public mutations: MutationTree<DashboardTopCardState> = {
    [SET_STATISTIC] (state, payload): void {
      state.cardStatistic = {
        currentPeriod: payload.currentPeriod,
        prevPeriod: payload.prevPeriod,
        graphPeriod: payload.graphPeriod
      };
    },
    
    [SET_ACTIVE_COUNTS] (state, payload): void {
      state.activeToday = payload.activeToday;
      state.activeYesterday = payload.activeYesterday;
    },
    
    [SET_CURRENCY] (state): void {
      state.filters.currency = store.state.auth.preferredCurrency;
    },
    
    [UPDATE_TOP_CARD_AMOUNT_TYPE] (state, payload): void {
      state.amountType = payload;
    },
    
    [UPDATE_TOP_CARD_FILTERS] (state, payload): void {
      state.filters = { ...state.filters, ...payload };
    },
    
    [UPDATE_TOP_CARD_PERIODS] (state, payload): void {
      state.periods = { ...state.periods, ...payload };
    },
    
    [UPDATE_PAGINATION] (state, pagination): void {
      state.pagination = { ...state.pagination, ...pagination };
    }
  };
  
  public actions: ActionTree<DashboardTopCardState, RootState> = {
    async [GET_STATISTIC] ({ commit, state }) {
      const { excludeFilters, groupType } = state;
      const label = state.periods?.label;
      const groups = [groupType, label].map(item => item?.toUpperCase());
      
      const filtersCurrent = {
        ...state.filters,
        dateStart: state.periods.currentPeriod?.dateStart,
        dateEnd: state.periods.currentPeriod?.dateEnd
      };
      const filtersPrev = {
        ...state.filters,
        dateStart: state.periods.prevPeriod?.dateStart,
        dateEnd: state.periods.prevPeriod?.dateEnd
      };
      
      const { data: { statistics: currentPeriod } } = await StatisticsService.getAdminStatistic(filtersCurrent, excludeFilters, groups);
      
      const filtersGraph = {
        ...state.filters,
        dateStart: state.periods.graphPeriod?.dateStart,
        dateEnd: state.periods.graphPeriod?.dateEnd
      };
      
      const { data: { statistics: prevPeriod } } = await StatisticsService.getAdminStatistic(filtersPrev, excludeFilters, groups);
      const { data: { statistics: graphPeriod } } = await StatisticsService.getAdminStatistic(filtersGraph, excludeFilters, groups);
      
      commit(SET_STATISTIC, {
        currentPeriod,
        prevPeriod,
        graphPeriod
      });
    },
    
    async [GET_ACTIVE_COUNTS] ({ commit, state }) {
      const now = momentInst();
      /*const payload = {
        // isNew: true,
        groups: [state.groupType]
      };*/
      const { excludeFilters, groupType } = state;
      const groups = groupType.toUpperCase();
      const filters = {
        today: {
          dateStart: now.clone().startOf("day").toDate(),
          dateEnd: now.clone().toDate()
        },
        yesterday: {
          dateStart: now.clone().startOf("day").subtract(1, "day").toDate(),
          dateEnd: now.clone().subtract(1, "day").toDate()
        }
      };
      const [today, yesterday] = await Promise.all([
        StatisticsService.getAdminStatistic(filters.today, excludeFilters, [groups]),
        StatisticsService.getAdminStatistic(filters.yesterday, excludeFilters, [groups])
      ]);
      
      commit(SET_ACTIVE_COUNTS, {
        activeToday: today.data.statistics?.items?.length ?? null,
        activeYesterday: yesterday.data.statistics?.items?.length ?? null
      });
    },
    
    [UPDATE_TOP_CARD_AMOUNT_TYPE] ({ commit }, payload) {
      commit(UPDATE_TOP_CARD_AMOUNT_TYPE, payload);
    },
    
    [UPDATE_TOP_CARD_FILTERS] ({ commit }, payload) {
      commit(UPDATE_TOP_CARD_FILTERS, payload);
    },
    
    [UPDATE_TOP_CARD_PERIODS] ({ commit }, payload) {
      commit(UPDATE_TOP_CARD_PERIODS, payload);
    },
    
    [UPDATE_PAGINATION] ({ commit }, pagination) {
      commit(UPDATE_PAGINATION, pagination);
    }
  };
  
  private readonly initState: () => DashboardTopCardState;
  
  constructor (type: DashboardTopCardState["groupType"], modules: ModuleTree<RootState>) {
    this.namespaced = true;
    this.initState = (): DashboardTopCardState => {
      return {
        groupType: type,
        cardStatistic: null,
        activeToday: null,
        activeYesterday: null,
        amountType: "leads",
        periods: null,
        pagination: {
          page: 1,
          perPage: 12
        },
        filters: {
          managerId: null,
          currency: null
        }
      };
    };
    this.state = this.initState();
    this.modules = modules;
    
    this.mutations.SET_EMPTY = (state): DashboardTopCardState => Object.assign(state, this.initState());
    
    return this;
  }
}
