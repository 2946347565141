import { ERROR, SHOW_ERROR } from "@/store/mutation-constants";
import { GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@/store/root-state";

export interface ErrorState {
  showErrorMessage: boolean;
  errorMessage: string | null;
}

const state: ErrorState = {
  showErrorMessage: false,
  errorMessage: null
};

const getters: GetterTree<ErrorState, RootState> = {
  showErrorMessage: state => state.showErrorMessage,
  errorMessage: state => state.errorMessage
};

const mutations: MutationTree<ErrorState> = {
  [ERROR] (state, payload) {
    if (payload === null) {
      state.showErrorMessage = false;
      state.errorMessage = null;
    } else {
      state.showErrorMessage = true;
      state.errorMessage = payload;
    }
  },
  [SHOW_ERROR] (state, payload) {
    state.showErrorMessage = !!payload;
  }
};


export const error: Module<ErrorState, RootState> = {
  state,
  getters,
  mutations
};
