import { GraphQLServer } from "@core/plugins/axios";
import Service from "@core/services/common/Service";
import { PlansEditInput, PlansEditResponse } from "@core/store/types/admin/efficiency/efficiencyPlansEdit";
import { AxiosPromise } from "axios";
import addPlan from "./graphql/addPlan.graphql";

export class EfficiencyPlansEditService extends Service {
  public static addPlan (managerId: string, input: PlansEditInput): AxiosPromise<PlansEditResponse> {
    return GraphQLServer.get("", {
      params: {
        query: addPlan,
        variables: {
          managerId,
          input
        }
      }
    });
  }
}
