import { correction } from "@core/store/modules/admin/finances/modules/financesCorrection/financesCorrection";
import { costs } from "@core/store/modules/admin/finances/modules/financesCosts/financesCosts";
import { paymentSystemsList } from "@core/store/modules/common/lists/paymentSystemsList";
import { requisitesList } from "@core/store/modules/admin/lists/requisitesList";
import { webAccrual } from "./modules/webAccrual";

export const finances = {
  namespaced: true,
  modules: {
    costs,
    requisitesList,
    paymentSystemsList,
    webAccrual,
    correction
  }
};
