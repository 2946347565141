import Service from "@core/services/common/Service";
import { OfferLandingsResponse } from "@core/store/types/admin/offer/OfferLandings";
import { AxiosPromise } from "axios";
import landingPages from "./graphql/landingPages.graphql";
import deletePage from "./graphql/deletePage.graphql";

export class OfferLandingsService extends Service {
  public static getLandings (payload: OfferLandingsResponse): AxiosPromise {
    return this.api.get("", {
      params: {
        query: landingPages,
        variables: payload
      }
    });
  }
  public static removePage (pageId: string): AxiosPromise {
    return this.api.get("", {
      params: {
        query: deletePage,
        variables: { pageId }
      }
    });
  }
}
