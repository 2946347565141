<template>
    <div class="input">
        <slot
            :value="value"
            :update="update">
            <b-input
                v-bind="$attrs"
                :value="value"
                :password-reveal="isPassword"
                @input="update">
            </b-input>
        </slot>
    </div>
</template>

<script>
  export default {
    name: "LInput",
    inheritAttrs: false,
    
    props: {
      value: {
        type: null,
        required: true
      }
    },

    computed: {
      isPassword () {
        return this.$attrs.type === "password";
      }
    },
    
    methods: {
      update (value) {
        this.$emit("input", value);
      }
    }
  };
</script>

<style lang="scss" scoped>
    @import "src/scss/theme-default";
    
    .input {
        display: contents!important;
    }
</style>
