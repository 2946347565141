import {
  PartialTrafficAnalysisState, Reason,
  TrafficAnalysisReason
} from "@core/store/types/admin/statistic/trafficAnalysis/trafficAnalysis";
import { TrafficAnalysisService } from "@core/services/admin/statistic/trafficAnalysis/TrafficAnalysis";
import {
  SET_EMPTY,
  GET_TRAFFIC_ANALYSIS,
  SYNC_STATISTIC_FILTERS,
  SET_EMPTY_CONTEXT
} from "@core/store/action-constants";
import { webmastersFilters } from "@core/store/modules/admin/statistic/modules/common/webmastersFilters";
import { offersFilters } from "@core/store/modules/admin/statistic/modules/common/offersFilters";
import { prepareBaseFilters } from "@core/store/modules/common/helpers/prepareFilters";
import { filterPreparation } from "@core/helpers/filterPreparation";
import { SET_TRAFFIC_ANALYSIS, UPDATE_FILTERS } from "@core/store/mutation-constants";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import i18n from "@core/plugins/i18n";
import { RootState } from "@core/store/root-state";
import moment from "moment";
import { filterFilters, prepareUserFilters } from "@core/store/modules/admin/statistic/helpers/prepareFilters";
import { toFixed } from "@core/filters";
import { flowsFilters } from "@core/store/modules/admin/statistic/modules/common/flowsFilters";
import { externalWebmasterFilters } from "@core/store/modules/admin/statistic/modules/common/externalWebmasterFilters";
import { utmCampaignFilters } from "@core/store/modules/common/statistic/common/utmCampaignFilters";
import { utmContentFilters } from "@core/store/modules/common/statistic/common/utmContentFilters";
import { utmMediumFilters } from "@core/store/modules/common/statistic/common/utmMediumFilters";
import { utmSourceFilters } from "@core/store/modules/common/statistic/common/utmSourceFilters";

function formatReason (arr: TrafficAnalysisReason[]): Reason[] {
  if (arr?.length > 0) {
    const total = arr.reduce((a: number,b: TrafficAnalysisReason) => a + b.count, 0);
 
    return arr.map((item: TrafficAnalysisReason) => {
      const reason = `common.entity.cancelReason.${ item.reason }`;
  
      return {
        name: `${ toFixed(item.count / total * 100, 1) }% - ${ i18n.te(reason) ? i18n.t(reason) : `${ i18n.t("common.entity.cancelReason.unknownReason") } (${ item.reason })` }`,
        value: item.count
      };
    }).sort((a, b) => {
      return b.value - a.value;
    });
  } else {
    return [];
  }
}

const initialState = (): PartialTrafficAnalysisState => {
  return {
    trafficAnalysis: null,
    filters: {
      datepicker: {
        dateStart: moment().startOf("day").subtract(1, "months").toDate(),
        dateEnd: moment().endOf("day").toDate()
      }
    }
  };
};

const state: () => PartialTrafficAnalysisState = initialState;

const getters: GetterTree<PartialTrafficAnalysisState, RootState> = {};

const mutations: MutationTree<PartialTrafficAnalysisState> = {
  [SET_EMPTY]: state => Object.assign(state, initialState()),
  
  [SET_TRAFFIC_ANALYSIS] (state, items: PartialTrafficAnalysisState["trafficAnalysis"]) {
    state.trafficAnalysis = items;
  },
  
  [UPDATE_FILTERS] (state, filter: PartialTrafficAnalysisState["filters"]) {
    state.filters = { ...state.filters, ...filterPreparation(filter) };
  }
};

const actions: ActionTree<PartialTrafficAnalysisState, RootState> = {
  async [GET_TRAFFIC_ANALYSIS] ({ commit, state }) {
    const { datepicker, ...fields } = state.filters;
    const filters = {
      ...fields,
      dateStart: datepicker?.dateStart,
      dateEnd: datepicker?.dateEnd
    };
    
    const { data: { traffic } } = await TrafficAnalysisService.getTraffic(filters);
    
    const spam = formatReason(traffic.spam);
    const cancel = formatReason(traffic.cancel);
    
    commit(SET_TRAFFIC_ANALYSIS, { ...traffic, spam, cancel });
  },
  
  [UPDATE_FILTERS] ({ commit }, filter: PartialTrafficAnalysisState["filters"]) {
    commit(UPDATE_FILTERS, filter);
  },
  
  [SYNC_STATISTIC_FILTERS] ({ dispatch }, filters: PartialTrafficAnalysisState["filters"]) {
    const baseFilters = prepareBaseFilters(filters);
    const userFilters = prepareUserFilters(filters, baseFilters);
    
    dispatch(`offersFilters/${ UPDATE_FILTERS }`, baseFilters);
    dispatch(`flowsFilters/${ UPDATE_FILTERS }`, baseFilters);
    dispatch(`externalWebmasterFilters/${ UPDATE_FILTERS }`, filterFilters(userFilters, ["externalWebmasterId"]));
    dispatch(`webmastersFilters/${ UPDATE_FILTERS }`, filterFilters(userFilters, ["webmasterId"]));
    dispatch(`utmCampaignFilters/${ UPDATE_FILTERS }`, baseFilters);
    dispatch(`utmContentFilters/${ UPDATE_FILTERS }`, baseFilters);
    dispatch(`utmSourceFilters/${ UPDATE_FILTERS }`, baseFilters);
  },
  
  [SET_EMPTY] ({ commit }) {
    commit("SET_EMPTY");
    commit(`offersFilters/${ SET_EMPTY }`);
    commit(`flowsFilters/${ SET_EMPTY }`);
    commit(`externalWebmasterFilters/${ SET_EMPTY }`);
    commit(`webmastersFilters/${ SET_EMPTY }`);
    commit(`utmCampaignFilters/${ SET_EMPTY }`);
    commit(`utmContentFilters/${ SET_EMPTY }`);
    commit(`utmSourceFilters/${ SET_EMPTY }`);
  },
  
  [SET_EMPTY_CONTEXT] ({ commit }) {
    commit(`offersFilters/${ SET_EMPTY }`);
    commit(`flowsFilters/${ SET_EMPTY }`);
    commit(`externalWebmasterFilters/${ SET_EMPTY }`);
    commit(`webmastersFilters/${ SET_EMPTY }`);
    commit(`utmCampaignFilters/${ SET_EMPTY }`);
    commit(`utmContentFilters/${ SET_EMPTY }`);
    commit(`utmSourceFilters/${ SET_EMPTY }`);
  }
};

export const trafficAnalysis: Module<PartialTrafficAnalysisState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    offersFilters,
    flowsFilters,
    externalWebmasterFilters,
    webmastersFilters,
    utmCampaignFilters,
    utmContentFilters,
    utmSourceFilters
  }
};
