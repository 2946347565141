import Service from "@core/services/common/Service";
import badges from "@core/services/common/badges/qraphql/badges.generator";
import { AxiosPromise } from "axios";
import { BadgesCount } from "@core/store/types/common/badges";

export class BadgesService extends Service {
  public static async getBadges (
    badgesOperations: Array<keyof BadgesCount>
  ): Promise<AxiosPromise> {
    return await this.api.get("", {
      params: badges(
        badgesOperations
      )
    });
  }
}