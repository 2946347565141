import { EfficiencyPlansService } from "@core/services/admin/efficiency/EfficiencyPlansService";
import { EDIT_PLANS_ADMIN_COMMENT, GET_PLANS } from "@core/store/action-constants";
import {
  EMPTY_PLANS_EDITABLE_COMMENT,
  SET_EMPTY,
  SET_PLANS,
  UPDATE_PLANS_EDITABLE_COMMENT,
  UPDATE_PLANS_PAGINATION
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { PlansEditCommentInput, PlansTableState } from "@core/store/types/admin/efficiency/efficiencyPlans";
import { ActionTree, Module, MutationTree } from "vuex";

const initialEditableComment = (): PlansTableState["editableComment"] => ({
  adminId: null,
  comment: null
});

const initialState = (): PlansTableState => {
  return {
    plans: null,
    pagination: {
      page: 1,
      perPage: 20
    },
    filters: {
      hasActiveWebmasters: "true"
    },
    editableComment: initialEditableComment()
  };
};

const state: () => PlansTableState = initialState;

const mutations: MutationTree<PlansTableState> = {
  [SET_EMPTY]: state => Object.assign(state, initialState()),

  [SET_PLANS] (state, items: PlansTableState["plans"]) {
    state.plans = items;
  },

  [UPDATE_PLANS_PAGINATION] (state, pagination) {
    state.pagination = { ...state.pagination, ...pagination };
  },

  [UPDATE_PLANS_EDITABLE_COMMENT] (state, editableComment: PlansTableState["editableComment"]) {
    state.editableComment = { ...state.editableComment, ...editableComment };
  },

  [EMPTY_PLANS_EDITABLE_COMMENT] (state) {
    state.editableComment = initialEditableComment();
  }
};

const actions: ActionTree<PlansTableState, RootState> = {
  async [GET_PLANS] ({ state: { pagination: { perPage, page }, filters }, commit }) {
    const limit = perPage,
      offset = (page - 1) * limit;
    
    try {
      const { data: { admins } } = await EfficiencyPlansService.getPlans(limit, offset, filters);
    
      commit(SET_PLANS, admins);
    } catch (err) {
      throw err;
    }
  },

  async [EDIT_PLANS_ADMIN_COMMENT] ({ state: { editableComment: { adminId, comment } } }) {
    try {
      const input = { comment } as PlansEditCommentInput;
      await EfficiencyPlansService.editAdminComment(adminId as string, input);
    } catch (err) {
      throw err;
    }
  },

  [UPDATE_PLANS_PAGINATION] ({ commit }, pagination) {
    commit(UPDATE_PLANS_PAGINATION, pagination);
  },

  [UPDATE_PLANS_EDITABLE_COMMENT] ({ commit }, editableComment) {
    commit(UPDATE_PLANS_EDITABLE_COMMENT, editableComment);
  }
};

export const efficiencyPlans: Module<PlansTableState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions
};
