import { IntegrationServices } from "@core/logic/admin/integrations/integrations";
import { CRMService } from "@core/services/admin/crm/CRMService";
import { IntegrationService } from "@core/services/admin/integrations/IntegrationsService";
import { CREATE_INTEGRATION, GET_CRM_OPTIONS, GET_UNINTEGRATION_OFFERS } from "@core/store/action-constants";
import { CRMList } from "@core/store/modules/admin/lists/CRMList";
import { offerAdvertisersList } from "@core/store/modules/admin/lists/offerAdvertisersList";
import { offersList } from "@core/store/modules/admin/lists/offersList";
import {
  SET_INTEGRATION_OPTIONS,
  SET_IS_LAUNCHED,
  SET_UNINTEGRATION_OFFERS,
  UPDATE_INTEGRATION_FILTERS,
  UPDATE_INTEGRATION_OPTIONS
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { CrmOption } from "@core/store/types/admin/crm/CRMState";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

interface IntegrationsEditStateFilters {
  [key: string]: string | null;
}

interface IntegrationsEditState {
  filters: IntegrationsEditStateFilters;
  isLaunched: boolean | null;
  options: CrmOption[];
}

const initialState = (): IntegrationsEditState => {
  return {
    filters: {
      advertiser: null,
      offer: null,
      crm: null
    },
    isLaunched: null,
    options: []
  };
};

const state: () => IntegrationsEditState = initialState;

const mutations: MutationTree<IntegrationsEditState> = {
  SET_EMPTY: state => Object.assign(state, initialState()),
  
  [SET_IS_LAUNCHED] (state, payload) {
    state.isLaunched = payload;
  },
  
  [UPDATE_INTEGRATION_FILTERS] (state, filter: any) {
    state.filters = { ...state.filters, ...filter };
  },
  
  [UPDATE_INTEGRATION_OPTIONS] (state, payload) {
    state.options = [ ...state.options, ...payload ];
  },

  [SET_INTEGRATION_OPTIONS] (state, payload) {
    state.options = payload;
  }
};

const actions: ActionTree<IntegrationsEditState, RootState> = {
  async [CREATE_INTEGRATION] ({ state }) {

    try {
      await IntegrationService.createIntegration({
        offer: state.filters.offer,
        advertiser: state.filters.advertiser,
        isLaunched: Boolean(state.isLaunched),
        crm: state.filters.crm,
        options: state.options
      });
    } catch (e: any) {
      throw new Error(e);
    }
  },
  
  async [GET_UNINTEGRATION_OFFERS] ({ commit }) {
    try {
      const { data } = await IntegrationServices.getUnallocatedOffers();
      commit(SET_UNINTEGRATION_OFFERS, data);
      return data;
    } catch (e: any) {
      throw new Error(e);
    }
  },

  async [GET_CRM_OPTIONS] ({ commit }, alias) {
    try {
      const { data: { crm: { options } } } = await CRMService.getCRMOptions(
        alias
      );
      commit(SET_INTEGRATION_OPTIONS, options);
    } catch (e) {
      throw e;
    }
  },

  [UPDATE_INTEGRATION_FILTERS] ({ commit }, filter) {
    commit(UPDATE_INTEGRATION_FILTERS, filter);
  },

  [UPDATE_INTEGRATION_OPTIONS] ({ commit }, option) {
    commit(UPDATE_INTEGRATION_OPTIONS, option);
  },
  
  SET_EMPTY ({ commit }) {
    commit("SET_EMPTY");
    commit("offerAdvertisersList/SET_EMPTY");
    commit("offersList/SET_EMPTY");
    commit("CRMList/SET_EMPTY");
  }
};

const getters: GetterTree<IntegrationsEditState, RootState> = {
  getOptions ({ filters: { crm } }) {
    if (crm === "lg" || crm === "lv") {
      return ["webmasterId", "domain", "goodId"];
    } else return ["webmasterId", "domain", "projectId", "apiKey", "orderId", "productName"];
  },
  
  isCrmSelected ({ filters: { crm } }) {
    return !(crm === null || crm === "");
  }
};

export const integrationEdit: Module<IntegrationsEditState, RootState> = {
  namespaced: true,
  modules: {
    offersList,
    offerAdvertisersList,
    CRMList
  },
  state,
  mutations,
  actions,
  getters
};
