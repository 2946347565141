import { GET_TRANSFER_RULES_URL } from "@core/store/action-constants";
import { UPDATE_TRANSFER_RULES_URL_LIST } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { TransferRulesUrlState } from "@core/store/types/admin/crm/lists/TransferRulesUrl";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

const initialState = (): TransferRulesUrlState => {
  return {
    data: {}
  };
};

const state: () => TransferRulesUrlState = initialState;

const getters: GetterTree<TransferRulesUrlState, RootState> = {};

const mutations: MutationTree<TransferRulesUrlState> = {
  [UPDATE_TRANSFER_RULES_URL_LIST] (state, transferRulesUrl: TransferRulesUrlState["data"]) {
    state.data = transferRulesUrl;
  }
};

const actions: ActionTree<TransferRulesUrlState, RootState> = {
  [GET_TRANSFER_RULES_URL] ({ commit }) {
    const items = [
      {
        label: "COMMA_IDS",
        value: "COMMA_IDS"
      },
      {
        label: "ARRAY_IDS",
        value: "ARRAY_IDS"
      },
      {
        label: "admin.crm.modal.labels.recoveryDetail.withoutRule",
        value: null
      }
    ];
    commit(UPDATE_TRANSFER_RULES_URL_LIST, {
      items,
      count: items.length
    });
  }
};

export const transferRulesUrlList: Module<TransferRulesUrlState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
