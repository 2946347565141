import MAIN from "@/locales/MAIN.en.json";

import FAQ from "@/locales/FAQ.en.json";
import PHP_CONFIG from "@/locales/PHP_CONFIG.en.json";

export default {
  ...MAIN,
  ...FAQ,
  ...PHP_CONFIG
};
