<template>
    <Background>
        <div class="form-wrapper">
            <NewPassword v-if="isNewPassword"></NewPassword>
            <ResetPassword v-else></ResetPassword>
        </div>
    </Background>
</template>

<script>
  import Background from "@/components/Common/Auth/Background";
  import ResetPassword from "@/components/Common/Auth/ResetPassword/ResetPassword";
  import NewPassword from "@/components/Common/Auth/ResetPassword/NewPassword";
  
  export default {
    name: "ResetPasswordView",
    components: {
      Background,
      ResetPassword,
      NewPassword
    },
    computed: {
      isNewPassword () {
        // В письме, которое приходит на почту лежит ссылка, которая содержит в себе токен, который мы тут используем
        // для определения этапа восстановления пароля. Костыль? Ну... Да.
        return this.$route.query.token;
      }
    }
  };
</script>

<style scoped>

</style>
