<template>
    <el-form-item
        :rules="rules ? rules : customRules"
        :label="showAsterisk ? label : null"
        :prop="prop">
        <FInput
            :title="!showAsterisk ? label : null"
            :value="value"
            type="password"
            :placeholder="placeholder"
            :bordered="bordered"
            show-password
            @input="updateValue">
        </FInput>
    </el-form-item>
</template>

<script>
  import { PASSWORD_VALIDATOR } from "@core/validators";
  import FInput from "@/components/Common/FInput";
  
  export default {
    name: "EditUserPasswordField",
    components: {
      FInput
    },
    props: {
      value: {
        type: null,
        default: null
      },
        
      placeholder: {
        type: String,
        default: " "
      },

      pattern: {
        type: String,
        default: PASSWORD_VALIDATOR
      },

      required: {
        type: Boolean,
        default: true
      },
      
      messageKey: {
        type: String,
        default: "common.validation.password"
      },

      type: {
        type: String,
        default: ""
      },

      label: {
        type: String,
        default: null
      },

      prop: {
        type: String,
        required: true
      },

      bordered: {
        type: Boolean,
        default: true
      },

      showAsterisk: {
        type: Boolean,
        default: false
      },

      rules: {
        type: [Object, Array],
        default: null
      }
    },
      
    computed: {
      customRules () {
        const rules = [
          { required: this.required, message: this.$t("common.validation.required") }
        ];
        if (this.pattern) {
          rules.push({ message: this.$t(this.messageKey), pattern: this.pattern });
        }
        return rules;
      }
    },
      
    methods: {
      updateValue (value) {
        this.$emit("input", value);
      }
    }
  };
</script>

<style scoped></style>