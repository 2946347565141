import { Offer } from "@core/logic/admin/offer/offer";
import { OfferModule } from "@core/store/modules/common/offer/OfferModule";
import { RootState } from "@core/store/root-state";

export interface OfferState {
  offer: Nullable<Partial<Offer>>;
}

export class AdminOfferModule extends OfferModule<OfferState, RootState> {
  private readonly initState: () => OfferState;

  constructor () {
    super();

    this.namespaced = true;

    this.initState = (): OfferState => {
      return {
        offer: {
          landingParameters: ["name", "phone"]
        }
      };
    };
    this.state(this.initState());
    this.mutations({
      SET_EMPTY: (state): void => {
        Object.assign(state, this.initState());
      }
    });

    return this;
  }
}

