export { Offer } from "./offer";
export { Modal } from "./modal";
export { CallCenters } from "./callCenters";
export { Filters } from "./filters";
export { Table } from "./table";
export { TableVuex } from "./tableVuex";
export { FilteredComponent } from "./filteredComponent";
export { ListView } from "./listView";
export { StatisticTableView } from "./statisticTableView";
export { UsersFilters } from "./usersFilter";
export { OffersFilters } from "./offersFilter";
export { DebounceUpdateByWatchedParams } from "./debounceUpdateByWatchedParams";
export { CRM } from "./CRM";
export { FiltersCount } from "./filtersCount";
export { UtmFilters } from "./statistic/utmFilters";

// @vue/components
export const Flows = {
  data () {
    return {
      activeRowId: null,
      deleteFlowId: null
    };
  },
  
  computed: {
    isDeleteFlowActive: {
      get () {
        return this.deleteFlowId != null;
      },
      
      set (value) {
        if (!value) {
          this.deleteFlowId = null;
        }
      }
    }
  },
  
  methods: {
    isWaitingButton (id) {
      return id === this.activeRowId;
    },

    deleteFlow (deleteFlowId) {
      this.$buefy.dialog.confirm({
        title: this.$t("webmaster.flows.modals.deleteConfirmationText"),
        confirmText: this.$t("webmaster.flows.modals.deleteConfirmationAction"),
        cancelText: this.$t("common.buttons.cancel"),
        type: "is-danger is-light",
        onConfirm: async () => {
          try {
            this.deleteFlowId = deleteFlowId;
            this.activeRowId = this.deleteFlowId;
            await this.$store.dispatch("webmaster/offer/REMOVE_FLOW", this.deleteFlowId);
            this.$emit("update");
            this.activeRowId = null;
            this.deleteFlowId = null;
          } catch (err) {
            this.$buefy.toast.open({
              message: `${ this.$t("common.messages.requestFail") }`,
              type: "is-danger"
            });
            this.deleteFlowId = null;
          }
        }
      });
    }
  }
};
