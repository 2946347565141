import { offerLandingsAddModal } from "@core/store/modules/admin/offer/modules/offerLandings/offerLandingsAddModal";
import { OfferLandingsList } from "@core/store/modules/admin/offer/modules/offerLandings/offerLandingsList";
import { ActionTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import { OfferState } from "@core/store/modules/admin/offer/AdminOfferModule";
import {
  GET_OFFER,
  GET_OFFER_ID, GET_OFFER_WEBMASTERS_REWARD,
  SET_EMPTY, UPDATE_MINIMAL_ALLOWED_ADVERTISER_WRITE_OFF, UPDATE_MINIMAL_ALLOWED_REWARD,
  UPDATE_OFFER_RATES_REQUIREMENTS
} from "@core/store/action-constants";
import { SET_OFFER_RATES_REQUIREMENTS } from "@core/store/mutation-constants";
import { OfferEditService } from "@core/services/admin/offer/edit/OfferEditService";
import { offerEditAdvertisers } from "./modules/offerEditAdvertisers";
import { EditAdminOfferModule } from "./modules/EditAdminOfferModule";
import { AdvertisersInternalIndividualRates } from "@core/store/modules/admin/offer/modules/edit/modules/AdvertisersInternalIndividualRates";
import { AdvertisersExternalIndividualRates } from "@core/store/modules/admin/offer/modules/edit/modules/AdvertisersExternalIndividualRates";
import { offerEditInternalWebmasters } from "@core/store/modules/admin/offer/modules/edit/modules/offerEditInternalWebmasters";
import { offerEditExternalWebmasters } from "@core/store/modules/admin/offer/modules/edit/modules/offerEditExternalWebmasters";

const initialState = (): OfferState => {
  return {
    offer: null
  };
};

const state: () => OfferState = initialState;

const mutations: MutationTree<OfferState> = {
  SET_EMPTY: state => Object.assign(state, initialState()),

  [SET_OFFER_RATES_REQUIREMENTS] (state, offer) {
    if (offer) {
      state.offer = { ...offer };
    }
  }
};

const actions: ActionTree<OfferState, RootState> = {
  SET_EMPTY ({ commit }) {
    commit(SET_EMPTY);
    commit("common/SET_EMPTY");
    commit("webmastersInternalRates/SET_EMPTY");
    commit("webmastersExternalRates/SET_EMPTY");
    commit("offerEditAdvertisers/SET_EMPTY");
    commit("advertisersInternalIndividualRates/SET_EMPTY");
    commit("advertisersExternalIndividualRates/SET_EMPTY");
    commit("landings/SET_EMPTY");
    commit("transits/SET_EMPTY");
  },

  async [UPDATE_OFFER_RATES_REQUIREMENTS] ({ getters, commit }) {
    try {
      const offerId = getters[`common/${ GET_OFFER_ID }`];
      if (offerId) {
        const { data: { offer: { finance, ...offer } } } =
          await OfferEditService.getRequirements(offerId);
        const newOffer = {
          ...offer,
          webmasterReward: finance.webmasterReward.value
        };
        commit(SET_OFFER_RATES_REQUIREMENTS, newOffer);
        return newOffer;
      }
    } catch (e) {
      throw e;
    }
  },

  [UPDATE_MINIMAL_ALLOWED_REWARD] ({ dispatch }) {
    dispatch(`offerEditAdvertisers/${ UPDATE_MINIMAL_ALLOWED_ADVERTISER_WRITE_OFF }`);
    dispatch(`advertisersExternalIndividualRates/${ GET_OFFER_WEBMASTERS_REWARD }`);
    dispatch(`advertisersInternalIndividualRates/${ GET_OFFER_WEBMASTERS_REWARD }`);
  },

  async [GET_OFFER] ({ commit, dispatch }, payload) {
    const data = await dispatch(`common/${ GET_OFFER }`, payload);
    commit(SET_OFFER_RATES_REQUIREMENTS, data);
    return data;
  }
};

export const edit: Module<OfferState, RootState> = {
  namespaced: true,
  modules: {
    common: new EditAdminOfferModule().toModule(),
    webmastersInternalRates: new offerEditInternalWebmasters().toModule(),
    webmastersExternalRates: new offerEditExternalWebmasters().toModule(),
    offerEditAdvertisers,
    advertisersInternalIndividualRates: new AdvertisersInternalIndividualRates().toModule(),
    advertisersExternalIndividualRates: new AdvertisersExternalIndividualRates().toModule(),
    offerLandingsAddModal,
    landings: new OfferLandingsList("landing"),
    transits: new OfferLandingsList("transit")
  },
  state,
  mutations,
  actions
};
