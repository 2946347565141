import { serializeDate } from "@/helpers/serializeDate";
import { BackendError } from "@/services/errors/BackendError";
import { USER_LOGOUT } from "@/store/action-constants";
import Axios, { CancelToken } from "axios";
import qs from "qs";
import Vue from "../main";

export { GraphQLServer } from "./axiosGraphQL";

const JWT_TOKEN_ERRORS = ["JWT_TOKEN_IS_EXPIRED", "JWT_TOKEN_IS_INVALID", "JWT_TOKEN_NOT_PROVIDED"];
const REFRESH_TOKEN_ERRORS = ["REFRESH_TOKEN_IS_EXPIRED", "REFRESH_TOKEN_IS_INVALID"];

export const FileServer = Axios.create({
  baseURL: process.env.VUE_APP_FILES_URL
});

Axios.defaults.baseURL = process.env.VUE_APP_API_URL;
Axios.defaults.headers.common["Accept-Language"] = localStorage.getItem("locale") || "en";

const pending = new Map();

// noinspection JSUnusedLocalSymbols
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const addPending = (config: any): void => {
  const url = [
    config.method,
    config.url,
    JSON.stringify(config.params)
  ].join("&");
  
  // @ts-ignore
  config.cancelToken = config.cancelToken || new CancelToken(cancel => {
    if (!pending.has(url)) {
      pending.set(url, cancel);
    }
  });
};

// noinspection JSUnusedLocalSymbols
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const removePending = (config: any): void => {
  const url = [
    config.method,
    config.url,
    JSON.stringify(config.params)
  ].join("&");
  
  if (pending.has(url)) {
    const cancel = pending.get(url);
    cancel(url);
    pending.delete(url);
  }
};

Axios.interceptors.request.use(
  config => {
    config.paramsSerializer = (params: any): string => qs.stringify(params, {
      skipNulls: true,
      arrayFormat: "brackets",
      serializeDate
    });
    
    if (Vue.$store.state.auth?.token?.access) {
      config.headers.Authorization = `Bearer ${ Vue.$store.state.auth.token.access }`;
    }
    
    // removePending(config);
    // addPending(config);
    
    return config;
  },
  
  error => Promise.reject(error)
);

Axios.interceptors.response.use(
  response => {
    // removePending(response.config);
    
    return {
      ...response,
      data: response.data.data || response.data.data === 0 ? response.data.data : response.data
    };
  },
  
  error => {
    const { response } = error;
    
    // Проверяем произошла ли ошибка из-за adblock
    if (response == null) {
      const request = error.request || error.message;
      
      if (request && request.readyState === 4 && request.status === 0) {
        Vue.$store.commit("SET_IS_REQUEST_BLOCKED", true);
      }
    } else {
      // Logout user if token refresh didn't work or user is disabled
      if (
        error.config.url === "/auth/token/refresh" ||
        REFRESH_TOKEN_ERRORS.includes(response.data.message)
      ) {
        Vue.$store.dispatch(USER_LOGOUT);
      }
      
      if (
        (response.status === 400 || response.status === 401) &&
        (response.data.type && JWT_TOKEN_ERRORS.includes(response.data.type))
      ) {
        // Try request again with new token
        return Vue.$store.dispatch("GET_TOKEN")
          .then(() => {
            // New request with new token
            const config = error.config;
            
            // removePending(config);
            
            if (Vue.$store.state.auth.token?.access) {
              config.headers.Authorization = `Bearer ${ Vue.$store.state.auth.token.access }`;
            }
            
            return new Promise((resolve, reject) => {
              Axios.request(config).then(response => {
                resolve(response);
              }).catch(error => {
                reject(error);
              });
            });
          })
          .catch(error => Promise.reject(new BackendError(error)));
      }
    }
    
    return Promise.reject(new BackendError(error));
  }
);
