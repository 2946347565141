import { FlowService } from "@core/services/webmaster/FlowService";

import {
  CREATE_BASE_LINK,
  CREATE_LINK,
  GENERATE_FLOW_URL,
  GET_FLOW,
  GET_FLOW_PARAMETERS,
  UPDATE_FLOW_FIELDS
} from "@core/store/action-constants";
import {
  SET_EMPTY,
  SET_FLOW_DYNAMIC_FIELDS,
  SET_FLOW_FIELDS,
  SET_FLOW_OPTIONS,
  SET_MODAL_ACTIVE,
  UPDATE_FLOW_URL,
  UPDATE_MODAL_ACTIVE
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { FlowsEditLinkModalState } from "@core/store/types/webmaster/flows/modules/FlowsEditLinkModal";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { globalSourceFilters } from "@core/store/modules/common/globalSource";

const fieldsMap: Record<string, string> = {
  "utmSource": "utm_source",
  "utmTerm": "utm_term",
  "utmMedium": "utm_medium",
  "utmContent": "utm_content",
  "utmCampaign": "utm_campaign",
  "clickId": "clickid",
  "externalWebmasterId": "externalWebmasterId",
  "globalSource": "globalSource"
};

const initialState = (): FlowsEditLinkModalState => {
  return {
    isModalActive: false,
    flowOptions: null,
    url: null,
    fields: {
      utmSource: null,
      utmTerm: null,
      utmMedium: null,
      utmContent: null,
      utmCampaign: null,
      clickId: null,
      externalWebmasterId: null,
      globalSource: null
    }
  };
};

const state: () => FlowsEditLinkModalState = initialState;

const getters: GetterTree<FlowsEditLinkModalState, RootState> = {
  [CREATE_LINK] (state, __, ___, rootGetters) {
    return (flowOptions = state.flowOptions as FlowsEditLinkModalState["flowOptions"]): URL => {
      const createBaseLink = rootGetters[`webmaster/flows/${ CREATE_BASE_LINK }`];
      const url = createBaseLink(flowOptions);
      
      for (const [key, value] of Object.entries(state.fields)) {
        const name = fieldsMap[key] ?? key;
        
        if (value && typeof value === "string") {
          url.searchParams.append(name, value);
        }
      }
      
      return url;
    };
  }
};

const mutations: MutationTree<FlowsEditLinkModalState> = {
  [SET_MODAL_ACTIVE] (state, value) {
    state.isModalActive = value;
  },
  
  [SET_FLOW_OPTIONS] (state, flowOptions) {
    state.flowOptions = flowOptions;
  },
  
  [SET_FLOW_DYNAMIC_FIELDS] (state, fields) {
    state.fields = { ...state.fields, ...fields };
  },
  
  [SET_FLOW_FIELDS] (state, fields) {
    state.fields = { ...state.fields, ...fields };
  },
  
  [UPDATE_FLOW_URL] (state, url) {
    state.url = url;
  },
  
  [SET_EMPTY]: state => Object.assign(state, initialState())
};

const actions: ActionTree<FlowsEditLinkModalState, RootState> = {
  [UPDATE_MODAL_ACTIVE] ({ commit, dispatch }, payload: boolean) {
    commit(SET_MODAL_ACTIVE, payload);
    if (payload) {
      dispatch(GET_FLOW_PARAMETERS);
    } else {
      commit(SET_EMPTY);
    }
  },
  
  async [GET_FLOW] ({ state }) {
    if (state.flowOptions?.id) {
      const {
        data: {
          landingFlow
        }
      } = await FlowService.getFlow(state.flowOptions.id);
    
      return landingFlow;
    }
  },

  async [GET_FLOW_PARAMETERS] ({ dispatch, commit }) {
    const { options } = await dispatch(GET_FLOW);
    const payload: Record<string, string | null> = {};
  
    for (const [key, value] of Object.entries(options) as [string, string][]) {
      if (key.endsWith("FieldName") && value) {
        payload[value] = null;
      }
    }
  
    commit(SET_FLOW_DYNAMIC_FIELDS, payload);
    dispatch(GENERATE_FLOW_URL);
  },
  
  [GENERATE_FLOW_URL] ({
    commit,
    getters
  }) {
    const url = getters.CREATE_LINK();
    
    commit(UPDATE_FLOW_URL, url);
  },
  
  [UPDATE_FLOW_FIELDS] ({
    commit,
    dispatch
  }, payload) {
    commit(SET_FLOW_FIELDS, payload);
    dispatch(GENERATE_FLOW_URL);
  }
};

export const flowsEditLinkModal: Module<FlowsEditLinkModalState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    globalSourceFilters
  }
};
