import Service from "../../../services/common/Service";
import axios, { AxiosPromise } from "axios";

export class CategoriesListService extends Service {
  public static async getCategoriesList (): Promise<AxiosPromise> {
    try {
      return await axios.get("/dictionaries/offers/categories", { params: { limit: 100, offset: 0 } });
    } catch (e) {
      throw e;
    }
  }
}
