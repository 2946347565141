import { OfferService } from "@core/services/webmaster/OfferService";
import { RootState } from "@core/store/root-state";
import { LandingPage, LandingPages } from "@core/store/types/common";
import { OfferLandingsFilters, OfferLandingsState } from "@core/store/types/webmaster/offerLandings";
import { ActionTree, Module, MutationTree } from "vuex";

export class OfferLandings implements Module<OfferLandingsState, RootState> {
  public namespaced: boolean;
  public state: OfferLandingsState | undefined;
  public mutations: MutationTree<OfferLandingsState> = {
    SET_LANDINGS (state: OfferLandingsState, payload: LandingPages) {
      state.landings = { ...payload };
    },
    
    UPDATE_LANDINGS (state: OfferLandingsState, payload: LandingPages) {
      state.landings = {
        count: payload.count,
        items: state.landings?.items?.concat(payload.items as LandingPage[])
      };
    },
    
    UPDATE_LANDINGS_FILTERS (state: OfferLandingsState, payload: any) {
      state.filters = { ...state.filters, ...payload };
    },
    
    UPDATE_PAGINATION (state: OfferLandingsState, payload: any) {
      state.pagination = { ...state.pagination, ...payload };
    }
  };
  public actions: ActionTree<OfferLandingsState, RootState> = {
    async GET_LANDINGS ({
      state,
      commit
    }, offerId) {
      const limit = state.pagination.perPage;
      const offset = (state.pagination.page - 1) * state.pagination.perPage;
      const filters = {
        ...state.filters,
        pageType: state.filters.pageType?.toUpperCase()
      };
    
      const { data: { landingPages } } = await OfferService.getLandingPages({
        limit,
        offset,
        offerId,
        filters
      });
    
      if (offset === 0) {
        commit("SET_LANDINGS", landingPages);
      } else {
        commit("UPDATE_LANDINGS", landingPages);
      }
    }
  };
  private readonly initState: () => OfferLandingsState;

  constructor (pageType: OfferLandingsFilters["pageType"]) {
    this.namespaced = true;
    this.initState = (): OfferLandingsState => {
      return {
        landings: {
          items: [],
          count: 0
        },
        pagination: {
          perPage: 25,
          page: 1
        },
        filters: {
          pageType
        }
      };
    };
    this.state = this.initState();

    this.mutations.SET_EMPTY = (state): OfferLandingsState => Object.assign(state, this.initState());

    return this;
  }
}
