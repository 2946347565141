import {
  DELETE_DOMAIN,
  GET_DOMAINS,
  UPDATE_DOMAINS_PAGINATION
} from "@core/store/action-constants";
import { SET_DOMAINS, SET_EMPTY, UPDATE_FILTERS } from "@core/store/mutation-constants";
import { NullableDomainsState } from "@core/store/types/common/domains/domains";
import { RootState } from "@core/store/root-state";
import { ActionTree, Module, MutationTree } from "vuex";
import { DomainsService } from "@core/store/logic/admin/domains/domainsService";
import { filterPreparation } from "@core/helpers/filterPreparation";
import { webmastersList } from "@core/store/modules/admin/lists/webmastersList";
import { domainModal } from "@core/store/modules/admin/domains/domainModal";

const initialState = (): NullableDomainsState => {
  return {
    domains: null,
    filters: {
      name: null,
      sslAuthMethod: null,
      webmasterId: null,
      certificateStatus: null
    },
    pagination: {
      page: 1,
      perPage: 25
    }
  };
};

const state: () => NullableDomainsState = initialState;

const mutations: MutationTree<NullableDomainsState> = {
  [SET_EMPTY] (state) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { domains, pagination, ...emptyFields } = initialState();
    Object.assign(state, emptyFields);
  },
  
  [SET_DOMAINS] (state, domains) {
    state.domains = domains;
  },

  [UPDATE_FILTERS] (state, filters) {
    state.filters = { ...state.filters, ...filters };
  },

  [UPDATE_DOMAINS_PAGINATION] (state, pagination) {
    state.pagination = { ...state.pagination, ...pagination };
  }
};

const actions: ActionTree<NullableDomainsState, RootState> = {
  async [GET_DOMAINS] ({ state, commit }) {
    const limit = state.pagination.perPage;
    const offset = (state.pagination.page - 1) * limit;
    const filters = filterPreparation(state.filters);
    
    const { data: { landingDomains } } = await DomainsService.getDomains(filters, limit, offset);
    
    commit(SET_DOMAINS, landingDomains);
  },
  
  async [DELETE_DOMAIN] (_, domainId) {
    await DomainsService.deleteDomain(domainId);
  },

  [UPDATE_DOMAINS_PAGINATION] ({ commit }, pagination) {
    commit(UPDATE_DOMAINS_PAGINATION, pagination);
  },

  [UPDATE_FILTERS] ({ commit }, filters) {
    commit(UPDATE_FILTERS, filters);
    commit(UPDATE_DOMAINS_PAGINATION, { page: 1 });
  },
  
  [SET_EMPTY] ({ commit }) {
    commit(SET_EMPTY);
    commit("webmastersList/SET_EMPTY");
  }
};

export const domains: Module<NullableDomainsState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    domainModal,
    webmastersList
  }
};
