<template>
    <el-dialog
        append-to-body
        width="20%"
        :visible.sync="_isModalActive">
        <el-card
            v-loading="isLoading"
            :body-style="{ padding: '0px' }"
            class="bg-gray-light-bg shadow-inner mb-8 mobile:bg-gray-background">
            <div class="">
                <div class="flex items-center p-4">
                    <div class="mr-4">
                        <el-avatar
                            :src="String(sizedAvatar)"
                            :size="64"></el-avatar>
                    </div>
                    <div>
                        <span class="text-xs text-gray-text">{{ $t("webmaster.aside.personalManager.title") }}</span>
                        <div class="flex is-align-items-center">
                            <span
                                v-if="personalManager"
                                class="text-base text-blue-text font-semibold cursor-pointer"
                                slot="reference"
                                @click="visible = !visible">
                                {{ personalManager.name }}
                            </span>
                            <div class="status">
                                {{ $t("webmaster.aside.personalManager.status") }}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="h-auto">
                    <a :href="'skype:' + contacts.skype + '?call'">
                        <div class="flex text-item text-xs p-3 border-t">
                            <img
                                class="pr-2"
                                src="@/assets/AsideMenu/skype.svg"
                                alt="skype">
                            <span>{{ $t("webmaster.aside.personalManager.skype") }}</span>
                        </div>
                    </a>
                    <a
                        :href="'mailto:' + contacts.email"
                        target="_blank">
                        <div class="flex text-item text-xs p-3 border-t">
                            <img
                                class="pr-2"
                                src="@/assets/AsideMenu/email.svg"
                                alt="email">
                            <span>{{ $t("webmaster.aside.personalManager.email") }}</span>
                        </div>
                    </a>
                    <a
                        :href="'https://telegram.im/' + contacts.telegram"
                        target="_blank">
                        <div class="flex text-item text-xs p-3 border-t">
                            <img
                                class="pr-2"
                                src="@/assets/AsideMenu/telegram.svg"
                                alt="telegram">
                            <span>{{ $t("webmaster.aside.personalManager.telegram") }}</span>
                        </div>
                    </a>
                </div>
            </div>
        </el-card>
    </el-dialog>
</template>

<script>
  // @ts-ignore
  import managerDefaultIcon from "@/assets/manager.svg";
  import { GET_ADVERTISER_PERSONAL_MANAGER, GET_WEBMASTER_PERSONAL_MANAGER } from "@core/store/action-constants";
  import { mapActions, mapGetters, mapState } from "vuex";
  
  export default {
    name: "ManagerModal",
    
    props: {
      isModalActive: {
        type: Boolean,
        default: false
      }
    },
    
    async created () {
      await this.getPersonalManager();
    },
    
    data () {
      return {
        isLoading: false
      };
    },
    
    computed: {
      ...mapState("webmaster", {
        webmasterPersonalManagers: state => state.profile.personalManagers,
        user: state => state.profile.user
      }),
      
      ...mapState("advertiser", {
        advertiserPersonalManager: state => state.profile.personalManager
      }),
      
      ...mapGetters([
        "role"
      ]),
      
      _isModalActive: {
        get () {
          return this.isModalActive;
        },
        set (value) {
          this.$emit("update:isModalActive", value);
        }
      },
      
      contacts () {
        return {
          email: this.user.contact.email,
          skype: this.user.skype,
          telegram: this.user.contact.telegram
        };
      },
      
      sizedAvatar () {
        try {
          const url = new URL(this.personalManager?.avatar?.url);
          url.searchParams.set("s", "48");
          return url;
        } catch {
          return this.personalManager?.avatar?.url || managerDefaultIcon;
        }
      },
      
      personalManager () {
        if (this.role === "webmaster") {
          return this.webmasterPersonalManagers?.[0];
        } else if (this.role === "advertiser") {
          return this.advertiserPersonalManager;
        }
        return undefined;
      }
    },
    methods: {
      ...mapActions("webmaster/profile", [
        GET_WEBMASTER_PERSONAL_MANAGER
      ]),
      
      ...mapActions("advertiser/profile", [
        GET_ADVERTISER_PERSONAL_MANAGER
      ]),
      
      async getPersonalManager () {
        this.isLoading = true;
        if (this.role === "webmaster") {
          await this.GET_WEBMASTER_PERSONAL_MANAGER();
        } else if (this.role === "advertiser") {
          await this.GET_ADVERTISER_PERSONAL_MANAGER();
        }
        this.isLoading = false;
      }
    }
  };
</script>

<style lang="scss" scoped>
    .status {
        height: 16px;

        padding: 2px 4px;
        font-size: 9px;
        font-weight: bold;
        color: white;

        border-radius: 5px;
        background: #1BD12D;
        letter-spacing: 0.05rem;
        cursor: default;
    }
    
    ::v-deep {
        .el-dialog {
            @media screen and (max-width: 991px) {
                width: 90%;
            }
            
            .el-dialog__body {
                padding: 20px 32px 20px;
                
                @media screen and (max-width: 991px) {
                    padding: 20px 15px 20px;
                }
            }
            
            .el-dialog__footer {
                padding: 10px 32px 32px;
            }
            
            .el-card__body {
                display: flex;
                justify-content: space-between;
                width: 100%;
                padding: 0.75rem;
            }
            
            //.el-checkbox-group {
            //    &:before {
            //        position: relative;
            //        content: url("../../assets/Table/line.svg");
            //        margin-right: 20px;
            //        bottom: 4px;
            //    }
            //}
            
            .el-switch__label * {
                color: #162239;
                font-weight: 400;
                
                @media screen and (max-width: 991px) {
                    //display: none;
                }
            }
        }
        
    }
</style>
