import { AxiosPromise } from "axios";
import Service from "@core/services/common/Service";
import editAdvertiser from "./graphql/editAdvertiser.graphql";
import getAdvertiser from "./graphql/getAdvertiser.graphql";
import changePasswordAdvertiser from "./graphql/changePasswordAdvertiser.graphql";
import {
  AdvertiserResponse,
  EditInput,
  EditResponse,
  AdvertisersPasswordEditInput,
  AdvertisersPasswordEditResponse
} from "@core/store/types/admin/users/advertisers/Edit";

export class AdvertisersEditService extends Service {
  public static getAdvertiser (advertiserId: string): AxiosPromise<AdvertiserResponse> {
    return this.api.get("", {
      params: {
        query: getAdvertiser,
        variables: {
          advertiserId
        }
      }
    });
  }

  public static editAdvertiser (advertiserId: string, input: EditInput): AxiosPromise<EditResponse> {
    return this.api.post("", {
      query: editAdvertiser,
      variables: {
        advertiserId,
        input
      }
    });
  }

  public static editPassword (advertiserId: string, input: AdvertisersPasswordEditInput): AxiosPromise<AdvertisersPasswordEditResponse> {
    return this.api.post("", {
      query: changePasswordAdvertiser,
      variables: {
        advertiserId,
        input
      }
    });
  }
}
