<template>
    <div class="flex flex-col w-40">
        <div class="flex text-blue-text items-center h-full balance gap-x-2">
            <div class="flex min-w-max">
                <img
                    src="@/assets/NavigationBar/balance.svg"
                    alt="icon"
                    class="mr-1">
                <span class="font-normal text-sm mobile:hidden laptop:block">
                    {{ $t("common.navBar.balance") }}:
                </span>
            </div>

            <div
                :class="{ 'is-loading': isLoadingBalance }"
                class="balance-number text-xs whitespace-pre tablet:text-sm"
                @click="refreshBalance">
                <div>
                    <div
                        :key="balance.currency"
                        v-for="balance of balanceList">
                        {{ balanceCurrency(balance) }}
                    </div>
                </div>
            </div>
        </div>

        <span class="balance-update text-xs text-gray-text font-light whitespace-nowrap">
            {{ $t("common.navBar.updated", { message: updatedMessage }) }}
        </span>
    </div>
</template>
<script>
  import { formatCurrency, momentFrom, toFixed } from "@core/filters";
  import { mapActions, mapState } from "vuex";
  import { sortByPreferredCurrency } from "@core/helpers/sortByPreferredCurrency";
  
  export default {
    name: "Balance",
    props: {
      role: {
        type: String,
        required: true
      }
    },

    created () {
      this.refreshBalance();

      this.balanceInterval = setInterval(() => {
        this.refreshBalance();
      }, 5 * 60 * 1000);
    },

    data () {
      return {
        updatedMessage: null,
        reload: new Date(),
        messageInterval: null,
        balanceInterval: null,
        isLoading: true,
        isLoadingBalance: false
      };
    },

    computed: {
      ...mapState({
        balanceList: ({ balance }) => sortByPreferredCurrency(balance.balance)
      })
    },

    methods: {
      balanceCurrency ({ value, currency }) {
        return formatCurrency(toFixed(value, 2), currency);
      },

      toFixed,
      
      formatCurrency,
      
      ...mapActions([
        "GET_WEBMASTER_BALANCE",
        "GET_ADVERTISER_BALANCE"
      ]),

      click () {
        this.isLoadingBalance = true;
      },

      async refreshBalance () {
        let response;
        this.isLoadingBalance = true;

        if (this.role === "webmaster") {
          response = await this.GET_WEBMASTER_BALANCE();
        } else if (this.role === "advertiser") {
          response = await this.GET_ADVERTISER_BALANCE();
        }

        if (response) {
          this.updateMessage();
          this.isLoading = false;
        }

        this.isLoadingBalance = false;
      },

      updateMessage () {
        clearInterval(this.messageInterval);

        this.reload = new Date();
        this.updatedMessage = momentFrom(this.reload);

        this.messageInterval = setInterval(() => {
          this.updatedMessage = momentFrom(this.reload);
        }, 1000);
      }
    },

    beforeDestroy () {
      clearInterval(this.messageInterval);
      clearInterval(this.balanceInterval);
    }
  };
</script>

<style scoped lang="scss">
    @import "../../../scss/colors";

    .balance {
        $self: &;

        display: flex;
        position: relative;
        justify-content: flex-start;

            #{ $self }-number {
                cursor: help;
                display: inline-flex;
                align-items: center;

                &:after {
                    content: "\f021";
                    font-size: 70%;
                    opacity: 0;
                    visibility: hidden;
                    display: inline-block;
                    vertical-align: bottom;
                    transform: translateX(15px);
                    transition: transform 300ms, opacity 300ms, visibility 300ms;
                    font-family: "Font Awesome 5 Free";
                }

                &:hover {
                    &:after {
                        opacity: 1;
                        visibility: visible;
                        transform: translateX(5px);
                    }
                }

                &.is-loading {
                    @keyframes rotate {
                        0% {
                            transform: translateX(5px) rotateZ(360deg * 0);
                        }

                        25% {
                            transform: translateX(5px) rotateZ(360deg * 0.25);
                        }

                        50% {
                            transform: translateX(5px) rotateZ(360deg * 0.5);
                        }

                        75% {
                            transform: translateX(5px) rotateZ(360deg * 0.75);
                        }

                        100% {
                            transform: translateX(5px) rotateZ(360deg * 1);
                        }
                    }

                    @keyframes pulse {
                        0% {
                            color: darken($purple, 15);
                        }

                        50% {
                            color: $purple;
                        }

                        100% {
                            color: darken($purple, 15);
                        }
                    }

                    color: $purple;
                    animation: pulse infinite 2s linear both;

                    &:after {
                        opacity: 1;
                        visibility: visible;
                        transform: translateX(5px);
                        animation: rotate infinite 400ms ease-in-out both;
                    }
                }
            }

            #{ $self }-update {
                display: block;
                font-size: 12px;
                color: #b9b9b9;
            }
    }
</style>
