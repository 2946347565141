import { GraphQLServer } from "@core/plugins/axiosGraphQL";
import Service from "@core/services/common/Service";
import { PartialWebmastersListState, WebmastersListResponse } from "@core/store/types/admin/lists/WebmastersListState";
import { AxiosPromise } from "axios";
import webmasterBalance from "../../admin/lists/graphql/webmasterBalance.graphql";
import webmasters from "../../admin/lists/graphql/webmasters.graphql";

export class WebmastersListService extends Service {
  public static getWebmastersList (
    filters: PartialWebmastersListState["filters"], limit: number, offset: number,
    initialWebmasters: PartialWebmastersListState["filters"] = {}): AxiosPromise<WebmastersListResponse> {

    const initialWebmastersLimit = initialWebmasters?.id?.length || 0;
    const isInitialWebmasters = !!initialWebmasters?.id;
    limit = Math.floor(initialWebmastersLimit / limit + 1) * limit - initialWebmastersLimit;
    return GraphQLServer.get("", {
      params: {
        query: webmasters,
        variables: {
          limit,
          offset,
          filters,
          initialWebmasters,
          initialWebmastersLimit,
          isInitialWebmasters
        }
      }
    });
  }

  public static getWebmasterBalance (filters: PartialWebmastersListState["filters"]):
  AxiosPromise<WebmastersListResponse> {
    return GraphQLServer.get("", {
      params: {
        query: webmasterBalance,
        variables: {
          filters
        }
      }
    });
  }
}
