import { ASTGeneratedOutput, Generator } from "@core/services/common/Generator";
import { Datepicker } from "@core/store/types/common";
import { ChartFilters } from "@core/store/types/admin/efficiency/efficiency";

export default function efficiency (
  currency: string | null | undefined,
  filters: Partial<NullableRecord<Datepicker>> | null | undefined,
  group: Nullable<string> | undefined,
  sort?: string | null | undefined,
  order?: string | null | undefined,
  chartFilters?: Nullable<ChartFilters> | undefined
): ASTGeneratedOutput {

  let params = {};
  if (chartFilters) {
    params = {
      include: Object.values(chartFilters ? chartFilters : {})
        ?.map(filter => filter ? `graph.managers.${ filter }` : null)
    };
  }

  const gqlObject = {
    operation: "efficiencyDashboard",
    fields: [
      {
        total: [
          {
            admin: [
              "intId",
              "login",
              "name",
              "activeWebmastersAmount",
              "partnerNetworksAmount",
              {
                activity: [
                  "isBlocked",
                  "isConfirmed"
                ]
              }
            ]
          },
          "countLeadsValid",
          "averageLeadsValid",
          "countLeadsAccepted",
          "cashFlow",
          "averageLeadsAccepted",
          "countLeadsAcceptedPlan",
          "averageLeadsAcceptedPlan",
          "percentLeadsAcceptedPlan",
          "sumCommission",
          "averageCommission",
          "countWebmasters",
          "countActiveWebmasters"
        ]
      },
      {
        graph: [
          "date",
          {
            managers: [
              {
                manager: [
                  "intId",
                  "name",
                  "login"
                ]
              }
            ]
          }
        ]
      }
    ],

    variables: {
      currency: {
        type: "CurrencyEnum!",
        value: currency
      },
      filters: {
        type: "ManagerEfficiencyDashboardFilterInput",
        value: filters
      },
      group: {
        type: "ManagerEfficiencyDashboardEnum",
        value: group
      },
      sort: {
        type: "ManagerEfficiencyDashboardTotalSort",
        value: sort
      },
      order: {
        type: "SortOrder",
        value: order
      }
    }
  };

  return Generator.query(gqlObject, params);
}



