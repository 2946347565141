import { PaymentsService } from "@core/services/admin/payments/PaymentsService";
import {
  DELETE_PAYMENT_SYSTEM,
  GET_PAYMENT_BY_ID,
  GET_PAYMENT_SYSTEMS,
  ENABLE_PAYMENT_SYSTEM,
  DISABLE_PAYMENT_SYSTEM
} from "@core/store/action-constants";
import { paymentsModal } from "@core/store/modules/admin/payments/paymentsModal";
import { SET_EMPTY, UPDATE_PAYMENT_SYSTEMS, UPDATE_PAYMENTS_PAGINATION } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { PartialPaymentsState, Payment } from "@core/store/types/admin/payments/Payments";
import { PaginationOutput } from "@core/store/types/PaginationOutput";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

const initialState = (): PartialPaymentsState => {
  return {
    payments: null,
    pagination: {
      page: 1,
      perPage: 25
    }
  };
};

const state: () => PartialPaymentsState = initialState;

const getters: GetterTree<PartialPaymentsState, RootState> = {
  [GET_PAYMENT_BY_ID]: state => function (paymentId: string): Payment | undefined {
    return state.payments?.items?.find((payment: Payment) => payment.id === paymentId);
  }
};

const mutations: MutationTree<PartialPaymentsState> = {
  [SET_EMPTY]: state => Object.assign(state, initialState()),

  [UPDATE_PAYMENT_SYSTEMS] (state, items: PaginationOutput<Payment[]>) {
    state.payments = items;
  },

  [UPDATE_PAYMENTS_PAGINATION] (state, pagination) {
    state.pagination = { ...state.pagination, ...pagination };
  }
};

const actions: ActionTree<PartialPaymentsState, RootState> = {
  async [GET_PAYMENT_SYSTEMS] ({ state, commit }) {
    const limit = state.pagination.perPage;
    const offset = (state.pagination.page - 1) * limit;

    try {
      const { data: { paymentSystems } } = await PaymentsService.getPaymentSystems(
        limit,
        offset
      );
      commit(UPDATE_PAYMENT_SYSTEMS, paymentSystems);
    } catch (e: any) {
      throw new Error(e);
    }
  },

  async [DELETE_PAYMENT_SYSTEM] (_, id: string) {
    try {
      await PaymentsService.deletePaymentSystem(id);
    } catch (e: any) {
      throw new Error(e);
    }
  },
  
  async [ENABLE_PAYMENT_SYSTEM] (_, paymentSystemId: string) {
    try {
      await PaymentsService.enablePaymentSystem(paymentSystemId);
    } catch (e) {
      throw e;
    }
  },
  
  async [DISABLE_PAYMENT_SYSTEM] (_, paymentSystemId: string) {
    await PaymentsService.disablePaymentSystem(paymentSystemId);
  },

  [UPDATE_PAYMENTS_PAGINATION] ({ commit }, pagination) {
    commit(UPDATE_PAYMENTS_PAGINATION, pagination);
  }
};

export const payments: Module<PartialPaymentsState, RootState> = {
  namespaced: true,
  modules: {
    paymentsModal
  },
  getters,
  state,
  mutations,
  actions
};
