import {
  OfferLandingParameterType,
  OfferResponseCategoryInterface, OfferResponseDetailInterface,
  OfferResponseInterface
} from "@core/store/types/common/offer/offer";
import { CountryInterface, File, TrafficTypeInterface, Withdraw } from "@core/store/types/common";

export abstract class AbstractOffer implements OfferResponseInterface {
  public id?: string;
  public intId?: number;
  
  public name?: string;
  public description?: string;
  public rules?: OfferResponseDetailInterface["rules"];
  public isInHouse?: OfferResponseDetailInterface["isInHouse"];
  
  public withdrawType?: Withdraw;
  public totalTimeHold?: number;
  
  public preCost?: number;
  public cost?: number;
  
  public categories?: Nullable<OfferResponseCategoryInterface[]>;
  public countries?: Nullable<CountryInterface[]>;
  public offerType?: string;
  public trafficTypes?: Nullable<TrafficTypeInterface[]>;
  public logo?: Nullable<File>;
  public isNewCreated?: boolean;
  public createdAt?: Date;
  public photoUrl?: string;
  public landingUrl?: string;
  public landingParameters?: Nullable<OfferLandingParameterType[]>;
  
  public trackingPageId?: string;
  
  protected constructor (payload?: OfferResponseInterface) {
    if (payload) {
      this.id = payload.id;
      this.intId = payload.intId;
      
      this.name = payload.detail?.name;
      this.description = payload.detail?.description;
      this.rules = payload.detail?.rules;
      this.isInHouse = payload.detail?.isInHouse;
      
      this.withdrawType = payload.finance?.withdrawType;
      this.totalTimeHold = payload.finance?.holdTime?.holdMinutes;
      
      this.preCost = payload.landing?.preCost;
      this.cost = payload.landing?.cost;
      this.trackingPageId = payload.landing?.trackingPageId;
      
      this.categories = payload.categories;
      this.countries = payload.countries;
      this.offerType = payload.type;
      this.trafficTypes = payload.trafficTypes;
      this.logo = payload.logo;
      this.isNewCreated = payload.isNewCreated;
      this.createdAt = payload.createdAt;
      this.photoUrl = payload.photoUrl;
      this.landingUrl = payload.landingUrl;
      this.landingParameters = payload.landingParameters;
    }
  }
}
