import { Commissions, Costs, FinancesService, Incomes } from "@core/logic/admin/finances/finances";
import { FinanceService } from "@core/services/admin/finance/FinanceService";
import { CREATE_COST, CREATE_INCOME, GET_USER_BALANCE } from "@core/store/action-constants";
import { SET_ADVERTISER_BALANCE } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { Advertiser } from "@core/store/types/admin/users/advertisers/Edit";
import { ActionTree, Module, MutationTree } from "vuex";
import { Balance } from "@core/store/types/admin/users/advertisers/AdvertisersState";

export interface FinanceState {
  incomes: Incomes | null;
  commissions: Commissions | null;
  costs: Costs | null;
}

const state: FinanceState = {
  incomes: null,
  commissions: null,
  costs: null
};

const mutations: MutationTree<FinanceState> = {
  SET_INCOMES (state, payload: Incomes) {
    state.incomes = payload;
  },
  SET_COMMISSIONS (state, payload: Commissions) {
    state.commissions = payload;
  },
  SET_COSTS (state, payload: Costs) {
    state.costs = payload;
  }
};

const actions: ActionTree<FinanceState, RootState> = {
  async [GET_USER_BALANCE] (_, userId) {
    const { data } = await FinancesService.getUserBalance(userId);
    
    return data;
  },
  
  async GET_INCOMES ({ commit }, payload) {
    const { data: { inTransactions } } = await FinanceService.getIncomes(payload);
    commit("SET_INCOMES", inTransactions);
  
    return inTransactions;
  },
  
  async GET_COMMISSIONS ({ commit }, payload) {
    const { data } = await FinancesService.getCommissions(payload);
    commit("SET_COMMISSIONS", data);
    
    return data;
  },
  
  async GET_COSTS ({ commit }, payload) {
    const { data } = await FinancesService.getCosts(payload);
    commit("SET_COSTS", data);
    
    return data;
  },
  
  async PROCESS_COST (_, id) {
    const { data } = await FinancesService.processCost(id);
    return data;
  },
  
  async ACCEPT_COST (_, id) {
    const { data } = await FinancesService.acceptCost(id);
    return data;
  },
  
  async REJECT_COST (_, id) {
    const { data } = await FinancesService.rejectCost(id);
    return data;
  },
  
  async [CREATE_INCOME] ({ rootState, commit }, payload) {
    const advertisers = rootState.admin.users.advertisers.advertisers;
    
    try {
      const { data } = await FinancesService.createIncome(payload);

      if (advertisers) {
        const balances = advertisers?.items.find((advertiser: Advertiser) => advertiser.id === payload.userId)?.balance;
        if (balances != null) {
          const balance = balances.map((item: Balance) => {
            if (item.balance.currency === payload.currency) {
              item.balance.value += data.amount.value;
            }
            return item;
          });
          // Изменяем локальные данные вручную, потому что бек не может изменять данные сразу после запроса
          commit(`users/advertisers/${ SET_ADVERTISER_BALANCE }`, {
            payload: {
              balance,
              lastTransactionDate: data.createdAt
            },
            advertiserId: payload.userId
          });
        }
      }
      return data;
    } catch (e) {
      throw e;
    }
  },
  
  async [CREATE_COST] (_, payload) {
    const { data } = await FinancesService.createCost(payload);
    
    return data;
  }
};

export const finance: Module<FinanceState, RootState> = {
  state,
  actions,
  mutations
};
