import { GraphQLServer } from "@core/plugins/axios";
import Service from "@core/services/common/Service";
import {
  PartialPlansHistoryFilters,
  PlansHistoryResponse,
  RemovePlanResponse
} from "@core/store/types/admin/efficiency/efficiencyPlansHistory";
import { AxiosPromise } from "axios";
import adminEfficiencies from "./graphql/adminEfficiencies.graphql";
import removePlan from "./graphql/removePlan.graphql";

export class EfficiencyPlansHistoryService extends Service {
  public static getPlans (adminId: string, limit: number, offset: number, filters: PartialPlansHistoryFilters = {}): AxiosPromise<PlansHistoryResponse> {
    return GraphQLServer.get("", {
      params: {
        query: adminEfficiencies,
        variables: {
          adminId,
          limit,
          offset,
          filters
        }
      }
    });
  }
  
  public static removePlan (managerEfficiencyId: string): AxiosPromise<RemovePlanResponse> {
    return GraphQLServer.get("", {
      params: {
        query: removePlan,
        variables: {
          managerEfficiencyId
        }
      }
    });
  }
}
