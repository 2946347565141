import { ActionTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import { FinancesService } from "@core/services/advertiser/finances/FinancesService";
import { PaginationTransactionsListState, TransactionListState } from "@core/store/types/advertiser/finances/index";
import {
  SET_INCOMES,
  UPDATE_INCOMES_FILTERS,
  UPDATE_INCOMES_PAGINATION
} from "@core/store/mutation-constants";
import { GET_INCOMES } from "@core/store/action-constants";
import moment from "moment";
import { paymentSystemsList } from "@core/store/modules/common/lists/paymentSystemsList";
import { refillModal } from "@core/store/modules/advertiser/finances/modules/refillModal";

const initialState = (): PaginationTransactionsListState => {
  return {
    transactions: null,
    filters: {
      status: null,
      paymentSystemId: null,
      currency: null,
      datepicker: {
        dateStart: moment().startOf("day").toDate(),
        dateEnd: moment().toDate()
      }
    },
    pagination: {
      page: 1,
      perPage: 25
    }
  };
};

const state: () => PaginationTransactionsListState = initialState;

const mutations: MutationTree<PaginationTransactionsListState> = {
  [SET_INCOMES]: (state, transactions: TransactionListState["transactions"]) => {
    state.transactions = transactions;
  },

  [UPDATE_INCOMES_PAGINATION] (state, pagination) {
    state.pagination = { ...state.pagination, ...pagination };
  },

  [UPDATE_INCOMES_FILTERS] (state, filter) {
    state.filters = { ...state.filters, ...filter };
  }
};

const actions: ActionTree<PaginationTransactionsListState, RootState> = {
  async [GET_INCOMES] ({ state, commit }) {
    try {
      const limit = state.pagination.perPage;
      const offset = (state.pagination.page - 1) * limit;
      const { datepicker, ...filters } = state.filters;
    
      const { advertiserTransactions } = await FinancesService.getTransactions(
        limit,
        offset,
        {
          ...datepicker,
          ...filters
        }
      );
      
      commit(SET_INCOMES, advertiserTransactions);
    } catch (error) {
      throw error;
    }
  },

  [UPDATE_INCOMES_PAGINATION] ({ commit }, pagination) {
    commit(UPDATE_INCOMES_PAGINATION, pagination);
  },

  [UPDATE_INCOMES_FILTERS] ({ commit }, filter) {
    commit(UPDATE_INCOMES_FILTERS, filter);
    commit(UPDATE_INCOMES_PAGINATION, { page: 1 });
  }
};

export const refill: Module<PaginationTransactionsListState, RootState> = {
  namespaced: true,
  modules: {
    paymentSystemsList,
    refillModal
  },
  state,
  actions,
  mutations
};