import { ActionTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import { AgentAccessModalOptions, AgentAccessModalState } from "@core/store/types/admin/users/webmasters/Agent";
import { UPDATE_MODAL_ACTIVE } from "@core/store/mutation-constants";
import {
  DISABLE_WEBMASTER_AGENT_ACCESS,
  ENABLE_WEBMASTER_AGENT_ACCESS,
  UPDATE_MODAL_OPTIONS
} from "@core/store/action-constants";
import { WebmastersService } from "@core/services/admin/users/webmasters/WebmastersService";

const initialState = (): AgentAccessModalState => {
  return {
    options: {
      webmasterId: null,
      agentType: null,
      subType: null
    },
    isAgentAccessModalActive: false
  };
};

const state: () => AgentAccessModalState = initialState;

const mutations: MutationTree<AgentAccessModalState> = {
  SET_EMPTY: state => Object.assign(state, initialState()),

  [UPDATE_MODAL_OPTIONS] (state, options: AgentAccessModalOptions) {
    state.options = { ...state.options, ...options };
  },

  [UPDATE_MODAL_ACTIVE] (state, payload: boolean) {
    state.isAgentAccessModalActive = payload;
  }
};

const actions: ActionTree<AgentAccessModalState, RootState> = {
  [UPDATE_MODAL_OPTIONS] ({ commit }, payload: AgentAccessModalOptions) {
    commit(UPDATE_MODAL_OPTIONS, payload);
  },

  [UPDATE_MODAL_ACTIVE] ({ commit }, payload: boolean) {
    commit(UPDATE_MODAL_ACTIVE, payload);
  },

  async [ENABLE_WEBMASTER_AGENT_ACCESS] ({ state }) {
    try {
      const { webmasterId, agentType } = state.options;
      await WebmastersService.changeAgentAccessForWebmaster(webmasterId, { isEnable: true, type: agentType });
    } catch (error) {
      throw error;
    }
  },

  async [DISABLE_WEBMASTER_AGENT_ACCESS] ({ state }) {
    try {
      const { webmasterId } = state.options;
      await WebmastersService.changeAgentAccessForWebmaster(webmasterId, { isEnable: false });
    } catch (error) {
      throw error;
    }
  }
};


export const agentModal: Module<RootState, AgentAccessModalState> = {
  namespaced: true,
  state,
  actions,
  mutations
};