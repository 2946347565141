import { PaginationOutput } from "@core/logic/common/pagination-output";
import { PaginationParams } from "@core/logic/common/pagination-params";
import { AxiosResponse } from "axios";
import Service from "@core/services/common/Service";

export interface IntegrationResponse {
  id: string;
  isActual: boolean;
  offer: Offer;
  errors: string[];
  lastCheckedAt: string;
  advertiser: Advertiser;
  options: Option;
  crm: CRM;
  isLaunched: boolean;
  countries: Countries;
}

export class Integration {
  public id: string;
  public isActual: boolean;
  public offer: Offer;
  public errors: string[];
  public lastCheckedAt: string;
  public advertiser: Advertiser;
  public options: Option;
  public CRM: CRM;
  public isLaunched: boolean;
  public countries: Countries;

  constructor (integration: IntegrationResponse) {
    this.id = integration.id;
    this.isActual = integration.isActual;
    this.offer = integration.offer;
    this.errors = integration.errors;
    this.lastCheckedAt = integration.lastCheckedAt;
    this.advertiser = integration.advertiser || null;
    this.options = integration.options || null;
    this.CRM = integration.crm || null;
    this.isLaunched = integration.isLaunched;
    this.countries = integration.countries || null;
  }
}

export interface Integrations extends PaginationOutput {
  items: Integration[];
}

export interface IntegrationsResponse extends PaginationOutput {
  items: IntegrationResponse[];
}

interface Offer {
  name: string;
  id: string;
}

export interface Offers extends PaginationOutput {
  items: Offer[];
}


interface Advertiser {
  id: string;
  name: string;
  login: string;
}

export interface Advertisers extends PaginationOutput {
  items: Advertiser[];
}

interface CrmItem {
  alias: string;
  name: string;
  actual: boolean;
}

export interface CRM extends PaginationOutput {
  items: CrmItem[];
}

interface CountryItem {
  id: number;
  name: string;
}

export interface Countries extends PaginationOutput {
  items: CountryItem[];
}

interface Option {
  token: string;
  domain: string;
  webmasterId: string;
}

export interface Options extends PaginationOutput {
  items: Option[];
}

export interface IntegrationsFilters {
  advertiser: string;
  offer: string;
  crm: string;
  country: string[];
}

export type NullableIntegrationsFilters = NullableRecord<IntegrationsFilters>

export interface IntegrationsRequest extends PaginationParams {
  limit: number;
  offset: number;
  filters: IntegrationsFilters;
}

// tslint:disable-next-line:max-classes-per-file
export class IntegrationServices extends Service {
  public static getIntegrations (params: IntegrationsRequest): Promise<AxiosResponse<IntegrationsResponse>> {
    return this.rest.get("/admin/integrations", { params });
  }

  public static getUnallocatedOffers (): Promise<AxiosResponse<Offers>> {
    return this.rest.get("/admin/integrations/offers/unallocated");
  }

  public static validateCrmLeadGroup (integrationId: string): Promise<AxiosResponse<Offers>> {
    return this.rest.post(`/admin/integrations/${ integrationId }/validate`);
  }

  public static createIntegration (payload: Record<string, unknown>): Promise<AxiosResponse<IntegrationsResponse>> {
    return this.rest.post("/admin/integrations", payload);
  }

  public static deleteIntegration (integrationId: string): Promise<AxiosResponse<IntegrationResponse>> {
    return this.rest.delete(`/admin/integrations/${ integrationId }`);
  }

  public static getCrm (): Promise<AxiosResponse<CRM>> {
    return this.rest.get("/dictionaries/crm");
  }

  public static getCountries (): Promise<AxiosResponse<CRM>> {
    return this.rest.get("/dictionaries/countries");
  }

  public static launchIntegration (integrationId: string): Promise<AxiosResponse<IntegrationsResponse>> {
    return this.rest.put(`/admin/integrations/${ integrationId }/launch`);
  }

  public static stopIntegration (integrationId: string): Promise<AxiosResponse<IntegrationsResponse>> {
    return this.rest.put(`/admin/integrations/${ integrationId }/stop`);
  }
}
