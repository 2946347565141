import { AnalyticService } from "@core/services/admin/analytic/AnalyticService";
import { adminsList } from "@core/store/modules/admin/lists/adminsList";
import { advertisersFilters } from "@core/store/modules/admin/statistic/modules/common/advertisersFilters";
import { offersFilters } from "@core/store/modules/admin/statistic/modules/common/offersFilters";
import { webmastersFilters } from "@core/store/modules/admin/statistic/modules/common/webmastersFilters";
import { subAnalytic } from "@core/store/modules/admin/analytic/subanalytic";
import { RootState } from "@core/store/root-state";
import {
  AnalyticItem,
  AnalyticItems,
  NullablePaginationAdminAnalyticState
} from "@core/store/types/admin/analytic/analytic";
import { routeFilter } from "@core/store/modules/admin/analytic/routeFilter";
import _merge from "lodash/merge";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import {
  FILTERED_CHART_LIST,
  SET_ANALYTIC,
  SET_ANALYTIC_FILTERS,
  SET_ANALYTIC_PAGINATION,
  SET_CHART_FILTERS,
  UPDATE_FILTERS
} from "@core/store/mutation-constants";
import {
  GET_ANALYTIC,
  SET_EMPTY,
  SET_EMPTY_CONTEXT,
  UPDATE_CHART_FILTERS,
  UPDATE_ANALYTIC_FILTERS,
  UPDATE_ANALYTIC_PAGINATION
} from "@core/store/action-constants";
import { filterFilters, prepareBaseFilters, prepareUserFilters } from "@core/store/modules/admin/statistic/helpers/prepareFilters";
import { utmCampaignFilters } from "@core/store/modules/common/statistic/common/utmCampaignFilters";
import { utmContentFilters } from "@core/store/modules/common/statistic/common/utmContentFilters";
import { utmMediumFilters } from "@core/store/modules/common/statistic/common/utmMediumFilters";
import { utmSourceFilters } from "@core/store/modules/common/statistic/common/utmSourceFilters";
import { utmTermFilters } from "@core/store/modules/common/statistic/common/utmTermFilters";
import { analyticModal } from "@core/store/modules/admin/analytic/modules/analyticModal";
import { globalSourceFilters } from "@core/store/modules/common/globalSource";
import { formatPercent, toFixed } from "@core/filters";

const initialState = (): NullablePaginationAdminAnalyticState => {
  return {
    analytic: null,
    filters: {},
    chartFilters: {},
    pagination: {
      page: 1,
      perPage: 30
    }
  };
};

const state: () => NullablePaginationAdminAnalyticState = initialState;

const mutations: MutationTree<NullablePaginationAdminAnalyticState> = {
  [SET_ANALYTIC] (state, analytic) {
    state.analytic = analytic;
  },
  
  [SET_ANALYTIC_FILTERS] (state, filters) {
    state.filters = filters;
  },
  
  [SET_CHART_FILTERS] (state, chartFilters) {
    state.chartFilters = { ...state.chartFilters, ...chartFilters };
  },
  
  [SET_ANALYTIC_PAGINATION] (state, pagination) {
    state.pagination = pagination;
  }
};

const getters: GetterTree<NullablePaginationAdminAnalyticState, RootState> = {
  [FILTERED_CHART_LIST]: (state) => {
    return state.analytic?.items.map((item: AnalyticItem) => {
      const { name, login, ...fields } = routeFilter(item.group) ?? {};
      const user = {
        ...fields,
        name: name || login
      };
      
      const items = item.items.map((item: AnalyticItems) => ({
        leadsIntegrated: item.countLeadsIntegrated,
        leads: item.countLeadsCreated,
        validLeads: item.countLeadsCreated - item.countLeadsSpammed,
        approvePercent: toFixed(item.conversionCleanApprove * 100, 2),
        spamPercent: toFixed(formatPercent(
          item.countLeadsSpammed / item.countLeadsCreated), 2),
        redemptionPercent: toFixed(item.conversionRedemption * 100, 2),
        day: item.day,
        week: item.week,
        month: item.month,
        year: item.year,
        user
      }));
      
      return { user, items };
    });
  }
};

const actions: ActionTree<NullablePaginationAdminAnalyticState, RootState> = {
  async [GET_ANALYTIC] ({ commit, state }) {
    const limit = state.pagination?.perPage ?? 10;
    const offset = limit * ((state.pagination?.page ?? 1) - 1);
    const { groups, ...filters } = state.filters;
    
    const { data: { analytic } } = await AnalyticService.getAnalytic(
      limit,
      offset,
      filters,
      groups[0],
      groups[1]
    );
    
    commit(SET_ANALYTIC, analytic);
  },
  
  [UPDATE_ANALYTIC_FILTERS] ({ dispatch, commit }, filters) {
    const baseFilters = prepareBaseFilters(filters);
    const userFilters = prepareUserFilters(filters, baseFilters);
    
    dispatch(`offersFilters/${ UPDATE_FILTERS }`, baseFilters);
    dispatch(`webmastersFilters/${ UPDATE_FILTERS }`, filterFilters(userFilters, ["webmasterId"]));
    dispatch(`advertisersFilters/${ UPDATE_FILTERS }`, filterFilters(userFilters, ["advertiserId"]));
    dispatch(`utmCampaignFilters/${ UPDATE_FILTERS }`, baseFilters);
    dispatch(`utmContentFilters/${ UPDATE_FILTERS }`, baseFilters);
    dispatch(`utmMediumFilters/${ UPDATE_FILTERS }`, baseFilters);
    dispatch(`utmSourceFilters/${ UPDATE_FILTERS }`, baseFilters);
    dispatch(`utmTermFilters/${ UPDATE_FILTERS }`, baseFilters);
    
    const { datepicker, registeredDatepicker, ...others } = filters;
    filters = { ...datepicker, ...registeredDatepicker, ...others };
    
    commit(SET_ANALYTIC_FILTERS, filters);
  },
  
  [UPDATE_ANALYTIC_PAGINATION] ({ commit, state }, pagination) {
    commit(SET_ANALYTIC_PAGINATION, _merge(state.pagination, pagination));
  },
  
  [UPDATE_CHART_FILTERS] ({ commit }, filters) {
    commit(SET_CHART_FILTERS, filters);
  },
  
  [SET_EMPTY] ({ commit }) {
    commit("offersFilters/SET_EMPTY");
    commit("advertisersFilters/SET_EMPTY");
    commit("webmastersFilters/SET_EMPTY");
    commit("globalSourceFilters/SET_EMPTY");
    commit("utmCampaignFilters/SET_EMPTY");
    commit("utmContentFilters/SET_EMPTY");
    commit("utmMediumFilters/SET_EMPTY");
    commit("utmSourceFilters/SET_EMPTY");
    commit("utmTermFilters/SET_EMPTY");
  },

  [SET_EMPTY_CONTEXT] ({ commit }) {
    commit("managersFilters/SET_EMPTY");
    commit("utmCampaignFilters/SET_EMPTY");
    commit("utmContentFilters/SET_EMPTY");
    commit("utmMediumFilters/SET_EMPTY");
    commit("utmSourceFilters/SET_EMPTY");
    commit("utmTermFilters/SET_EMPTY");
  }
};

export const analytic: Module<NullablePaginationAdminAnalyticState, RootState> = {
  namespaced: true,
  modules: {
    globalSourceFilters,
    offersFilters,
    webmastersFilters,
    advertisersFilters,
    managersFilters: adminsList,
    subAnalytic,
    utmCampaignFilters,
    utmContentFilters,
    utmMediumFilters,
    utmSourceFilters,
    utmTermFilters,
    analyticModal
  },
  state,
  mutations,
  getters,
  actions
};
