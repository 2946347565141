import { formatCurrency, formatEmptyString, moment, toFixed } from "@core/filters";

// @vue/component
export const TableVuex = {
  computed: {
    isPaginated () {
      return this.data && !isNaN(this.data.count) ? this.data.count > this.perPage : false;
    }
  },
  
  methods: {
    formatEmptyString,
    
    formatCurrency,
    
    toFixed,
    
    moment,
    
    updateCurrentPage (value) {
      this.page = value;
    },
    
    updatePerPage (value) {
        this.perPage = value;
    }
  }
};
