import { OfferEditAdvertisersIndividualRateService } from "@core/services/admin/offer/edit/OfferEditAdvertisersIndividualRateService";
import {
  ADD_OFFER_ADVERTISER_INDIVIDUAL_RATE, GET_OFFER_ID,
  UPDATE_MAXIMAL_ALLOWED_WEBMASTER_REWARD,
  UPDATE_MINIMAL_ALLOWED_ADVERTISER_WRITE_OFF,
  UPDATE_RATES
} from "@core/store/action-constants";
import { UPDATE_MODAL_ACTIVE } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { ActionTree, Module, MutationTree } from "vuex";
import {
  Rate, RequestRates
} from "@core/store/types/admin/offer/webmasters/OfferExternalWebmastersModalState";
import { OfferExternalIndividualRatesModalState } from "@core/store/types/admin/offer/OfferExternalIndividualRatesModalState";

const initialState = (): OfferExternalIndividualRatesModalState => {
  return {
    rates: [{}],
    isIndividualRatesModalActive: false
  };
};

const state: () => OfferExternalIndividualRatesModalState = initialState;

const mutations: MutationTree<OfferExternalIndividualRatesModalState> = {
  SET_EMPTY: state => Object.assign(state, initialState()),

  [UPDATE_MODAL_ACTIVE] (state, payload: boolean) {
    state.isIndividualRatesModalActive = payload;
  },

  [UPDATE_RATES] (state, rates: OfferExternalIndividualRatesModalState["rates"]) {
    state.rates = rates;
  }
};

const actions: ActionTree<OfferExternalIndividualRatesModalState, RootState> = {
  async [ADD_OFFER_ADVERTISER_INDIVIDUAL_RATE] ({ state, rootState, dispatch, rootGetters }) {
    const offerId = rootGetters[`admin/offer/edit/common/${ GET_OFFER_ID }`];
    const advertiserId = rootState.admin.offer.edit.advertisersInternalIndividualRates.filters.advertiserId;
    const webmasterId = rootState.admin.offer.edit.advertisersExternalIndividualRates.filters.webmasterId;
    try {
      if (offerId && advertiserId) {
        const rates: RequestRates[] = state.rates.map((rate: Rate) => {
          return {
            rate: Number(rate.rate) || 1,
            externalWebmasterId: rate.externalWebmasterId,
            webmasterId,
            advertiserId
          };
        });
        const { data: { createAdvertiserIndividualRate } } =
          await OfferEditAdvertisersIndividualRateService.createAdvertiserIndividualRate(offerId, rates);

        dispatch(`admin/offer/edit/webmastersInternalRates/${ UPDATE_MAXIMAL_ALLOWED_WEBMASTER_REWARD }`,
          { offerId }, { root: true });
        dispatch(`admin/offer/edit/offerEditAdvertisers/${ UPDATE_MINIMAL_ALLOWED_ADVERTISER_WRITE_OFF }`,
          { offerId }, { root: true });
        dispatch(`admin/offer/edit/webmastersExternalRates/${ UPDATE_MAXIMAL_ALLOWED_WEBMASTER_REWARD }`,
          { offerId }, { root: true });
        return createAdvertiserIndividualRate;
      }
    } catch (e) {
      throw e;
    }
  },

  [UPDATE_RATES] ({ commit }, payload) {
    commit(UPDATE_RATES, payload);
  },

  [UPDATE_MODAL_ACTIVE] ({ commit }, payload: boolean) {
    if (payload) {
      commit("SET_EMPTY");
    }
    commit(UPDATE_MODAL_ACTIVE, payload);
  }
};

export const offerEditExternalIndividualRatesModal: Module<OfferExternalIndividualRatesModalState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions
};
