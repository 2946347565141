import { ExportService } from "@/services/common/exports/ExportService";
import { UPDATE_MODAL_ACTIVE, UPDATE_MODAL_OPTIONS, UPLOAD_FILE } from "@/store/action-constants";
import { formatFileList } from "@/store/modules/common/uploads/lists/formatFileLists";
import { uploadsFieldsList } from "@/store/modules/common/uploads/lists/uploadsFieldsList";
import { RootState } from "@/store/root-state";
import { UploadFileListItem, UploadFileState } from "@/store/types/admin/uploads/UploadFileState";
import { ActionTree, GetterTree, MutationTree } from "vuex";

const initialState = (): UploadFileState => {
  return {
    options: {
      query: null,
      fields: [],
      format: "XLSX",
      filters: [],
      arguments: null
    },
    isUploadFileModalActive: false
  };
};

const state: () => UploadFileState = initialState;

const getters: GetterTree<UploadFileState, RootState> = {
  filters: (state): UploadFileListItem["filters"] => {
    const filters = state.options?.filters;
    const newFilters: UploadFileListItem["filters"] = [];
    
    if (filters) {
      for (const [name, value] of Object.entries(filters)) {
        if (value) {
          if (name === "datepicker") {
            newFilters.push({
              name: "dateStart",
              // @ts-ignore
              value: [value.dateStart]
            });
            newFilters.push({
              name: "dateEnd",
              // @ts-ignore
              value: [value.dateEnd]
            });
          }else {
            newFilters.push({
              name,
              // @ts-ignore
              value: Array.isArray(value) ? value : [value]
            });
          }
        }
      }
    }
    return newFilters;
  },
  
  arguments: (state): UploadFileListItem["arguments"] => {
    const args = state.options?.arguments;
    const newArguments: UploadFileListItem["filters"] = [];
    
    if (args) {
      for (const [name, value] of Object.entries(args)) {
        if (value) {
          newArguments.push({
            name,
            // @ts-ignore
            value: value
          });
        }
      }
    }
    return newArguments;
  }
};

const mutations: MutationTree<UploadFileState> = {
  SET_EMPTY: state => Object.assign(state, initialState()),
  
  [UPDATE_MODAL_OPTIONS] (state, option: UploadFileListItem) {
    state.options = { ...state.options, ...option };
  },
  
  [UPDATE_MODAL_ACTIVE] (state, payload: boolean) {
    state.isUploadFileModalActive = payload;
  }
};

const actions: ActionTree<UploadFileState, RootState> = {
  async [UPLOAD_FILE] ({
    state,
    getters,
    rootState
  }) {
    const newFilters = getters.filters as unknown as UploadFileListItem["filters"];
    const newArguments = getters.arguments as unknown as UploadFileListItem["arguments"];
    const language = rootState.locale.locale.toUpperCase();
    
    try {
      await ExportService.queryExport(
        {
          ...state.options,
          filters: newFilters,
          language,
          arguments: newArguments
        }
      );
    } catch (e: any) {
      throw new Error(e);
    }
  },
  
  [UPDATE_MODAL_OPTIONS] ({ commit }, payload: any) {
    commit(UPDATE_MODAL_OPTIONS, payload);
  },
  
  [UPDATE_MODAL_ACTIVE] ({ commit }, payload: boolean) {
    if (payload) {
      commit(UPDATE_MODAL_ACTIVE, payload);
    } else {
      commit("SET_EMPTY");
    }
  },
  
  SET_EMPTY ({ commit }) {
    commit("SET_EMPTY");
    commit("uploadsFieldsList/SET_EMPTY");
    commit("formatFileList/SET_EMPTY");
  }
};

export const uploadFile = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
  modules: {
    uploadsFieldsList,
    formatFileList
  }
};
