<template>
    <el-aside
        width=""
        class="flex xl:w-80 tablet:justify-between flex-col tablet:px-4"
        :class="{ 'hidden': !isMobileMenuOpen, 'menuOpen': isMobileMenuOpen }">
        <div class="p-2 info flex flex-wrap tablet:hidden">
            <InfoBar
                class="py-6 -mr-4"
                :login="user.login"
                :role="role"
                :col-size="24"
                :is-mobile-menu-open="isMobileMenuOpen"></InfoBar>
            <div class="w-full flex justify-end my-4">
                <img
                    class="cursor-pointer transform -scale-x-1 laptop:hidden tablet:z-10"
                    src="@/assets/NavigationBar/mobile-menu.svg"
                    alt="menu"
                    @click="$emit('menuOpen')">
            </div>
        </div>
        <!--        <div class="flex mr-auto laptop:hidden mobile:hidden mt-6">-->
        <!--            <div-->
        <!--                class="flex mr-1">-->
        <!--                <span class="text-blue-text text-sm font-semibold">{{ user.login }}</span>-->
        <!--            </div>-->
        <!--            <Balance-->
        <!--                :role="role"-->
        <!--                class="hidden mobile:flex"></Balance>-->
        <!--        </div>-->
        <div class="px-10 tablet:px-0">
            <Logo
                :is-collapsed="isCollapsed"
                class="py-9 hidden tablet:flex w-full">
            </Logo>
            <Role>
                <template #webmaster>
                    <AsideMenuWebmaster :is-mobile-menu-open.sync="_isMobileMenuOpen"></AsideMenuWebmaster>
                </template>
                <template #admin>
                    <AsideMenuAdmin :is-mobile-menu-open.sync="_isMobileMenuOpen"></AsideMenuAdmin>
                </template>
            </Role>
        </div>
        <div class="mt-auto">
            <Manager
                v-if="(role === 'webmaster' && user.hasManager || role === 'advertiser' && user.hasManager)"
                :is-collapsed="isCollapsed"
                class="mobile:mb-4 w-full "></Manager>
            <footer class="pb-8 laptop:hidden">
                <div class="text-xs">
                    <span class="text-gray-text">© 2022. FairPay Partners</span>
                </div>
            </footer>
        </div>
    </el-aside>
</template>

<script>
  import { mapGetters } from "vuex";
  import Logo from "@/components/Common/Logo";
  import InfoBar from "@/components/Common/NavigationBar/InfoBar";
  import AsideMenuItem from "@/components/Common/AsideMenuItem";
  import Manager from "@/components/Common/NavigationBar/Manager";
  import main from "@/assets/AsideMenu/main.svg";
  import offers from "@/assets/AsideMenu/offers.svg";
  import statistics from "@/assets/AsideMenu/statistics.svg";
  import flows from "@/assets/AsideMenu/flows.svg";
  import payments from "@/assets/AsideMenu/payments.svg";
  import Balance from "@/components/Common/NavigationBar/Balance";
  import Role from "@/components/Common/Role";
  import AsideMenuWebmaster from "@/components/Webmaster/Menu/AsideMenu";
  import AsideMenuAdmin from "@/components/Admin/Menu/AsideMenu";

  export default {
    name: "AsideMenu",
    components: {
      Logo,
      InfoBar,
      Manager,
      // Balance
      // AsideMenuItem,
      Role,
      AsideMenuWebmaster,
      AsideMenuAdmin
    },
    props: {
      isMobileMenuOpen: {
        type: Boolean,
        default: false
      }
    },

    // mounted () {
    //   window.onresize = () => {
    //     this.windowWidth = window.innerWidth;
    //   };
    // },
    data () {
      return {
        // windowWidth: window.innerWidth,
        isCollapsed: true
      };
    },
    computed: {
      ...mapGetters([
        "user",
        "role"
      ]),

      _isMobileMenuOpen: {
        get () {
          return this.isMobileMenuOpen;
        },
        set (value) {
          this.$emit("update:isMobileMenuOpen", value);
        }
      },

      isTab () {
        return this.windowWidth < 1280;
      },
      asideActive () {
        return this.$route.path?.replaceAll("/", "");
      }
    },
    watch: {
      isCollapsed (value) {
        this.$emit("isCollapsed", value);
      }
    }
  };
</script>

<style lang="scss" scoped>
    aside {
        top: 0;
        left: 0;
        bottom: 0;
        height: 100vh;
        display: flex !important;
        width: 20rem;
        position: fixed;
        flex-direction: column;
        background: inherit;
        z-index: 40;
        @media screen and (max-width: 768px) {
            display: none !important;
        }
        @media screen and (max-width: 1366px) {
            width: 4rem;
            padding: 0 7px;
            ::v-deep {
                ul {
                    li {
                        .label {
                            display: none;
                        }
                    }
                }
                .logo {
                    span {
                        display: none;
                    }
                }
                .el-card__body {
                    .managerLogo {
                        display: flex;
                    }
                    .manager {
                        display: none;
                    }
                }

            }
        }
    }
    .menuOpen {
        @media screen and (max-width: 1024px) {
            display: flex !important;
            @apply px-4;
            width: 21rem;
            ::v-deep {
                ul {
                    li {
                        .label {
                            display: flex;
                        }
                    }
                }
                .logo {
                    span {
                        display: flex;
                    }
                }
                .el-card {
                    .el-card__body {
                        .managerLogo {
                            display: none;
                        }
                        .manager {
                            display: flex;
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 426px) {
            @apply w-full;
        }
    }
    footer {
        @media screen and (min-width: 1366px) {
            display: block !important;
        }
    }
    .el-menu--collapse {
        width: 50px;
    }

    .text-xs {
        text-align: center;
    }
</style>
