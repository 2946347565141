<template>
    <el-container>
        <template
            v-if="label"
            #label>
            {{ label }}
            <el-tooltip
                v-if="note"
                :content="note"
                placement="right">
                <!--suppress HtmlUnknownTarget -->
                <i class="el-icon-question"></i>
            </el-tooltip>
        </template>
        <div>
            <slot>
                <div>
                    <!--suppress HtmlFormInputWithoutLabel -->
                    <FInput
                        v-bind="$attrs"
                        :placeholder="placeholder"
                        :type="inputType"
                        v-on="listeners">
                    </FInput>
                </div>
            </slot>
            <template v-if="!('default' in this.$slots)">
                <!--                <div-->
                <!--                    v-if="isPassword"-->
                <!--                    class="control">-->
                <!--                    <el-button-->
                <!--                        :class="{ 'is-disabled': $attrs.disabled != null }"-->
                <!--                        @click="isHide = !isHide">-->
                <!--                        <b-icon-->
                <!--                            :alt="isHide ? $t(`common.components.customInput.icons.showIcon`) : $t(`common.components.customInput.icons.hideIcon`)"-->
                <!--                            :icon="isHide ? 'eye' : 'eye-slash'"-->
                <!--                            pack="far"></b-icon>-->
                <!--                    </el-button>-->
                <!--                </div>-->
                <div
                    v-if="canCopy && copy"
                    class="control">
                    <el-button
                        @click.prevent="copyToClipboard">
                        <i
                            :alt="$t(`common.components.customInput.icons.copy`)"
                            class="el-icon-document-copy">
                        </i>
                    </el-button>
                </div>
            </template>
        </div>
    </el-container>
</template>

<script>
  import { CopyToClipboard } from "@core/mixins/copyToClipboard";
  import FInput from "@/components/Common/FInput";
  
  export default {
    name: "CustomInput",
    inheritAttrs: false,
    mixins: [CopyToClipboard],
    components: { FInput },
    props: {
      copy: {
        type: Boolean,
        default: false
      },

      hide: {
        type: Boolean,
        default: false
      },

      expanded: {
        type: Boolean,
        default: false
      },

      label: {
        type: [String, Boolean],
        // eslint-disable-next-line no-irregular-whitespace
        default: " " //  	&#8195;	широкий пробел (em-шириной в букву m)
      },

      placeholder: {
        type: String,
        default: ""
      },

      type: {
        type: String,
        default: "text"
      },

      note: {
        type: String,
        default: ""
      },

      isValid: {
        type: Boolean,
        default: true
      }
    },
    data () {
      const isHide = this.hide;

      return {
        isHide
      };
    },
    computed: {
      listeners () {
        return {
          ...this.$listeners,
          input: event => this.$emit("input", event.target.value)
        };
      },

      isPassword () {
        return this.type === "password";
      },

      canCopy () {
        // If neither https nor localhost
        return navigator && navigator.clipboard;
      },

      inputType () {
        return this.isPassword && !this.isHide ? "text" : this.type;
      }
    }
  };
</script>

<style lang="scss" scoped>
    //@import "src/scss/theme-default";
    //
    //.input {
    //    &:focus {
    //        box-shadow: initial;
    //        border-color: #e9e9e9;
    //    }
    //}
    //
    //.button {
    //    &.is-disabled {
    //        border: none !important;
    //        //noinspection SassScssUnresolvedVariable
    //        background-color: $background;
    //    }
    //
    //    .icon {
    //        color: rgba(0, 0, 0, 0.4);
    //    }
    //}
</style>
