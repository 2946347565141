import UsersServiceREST from "@core/logic/admin/users/users";
import { AdvertisersService } from "@core/services/admin/users/advertisers/AdvertisersService";
import { UsersService } from "@core/services/admin/users/common/UsersService";
import {
  ADD_ADVERTISER_PERSONAL_MANAGER,
  ADD_APPROVED_ADVERTISER,
  ADVERTISER_BALANCE,
  BLOCK_ADVERTISER,
  DELETE_ADVERTISER_PERSONAL_MANAGER,
  EDIT_ADVERTISER_ADMIN_COMMENT,
  GET_ADVERTISERS,
  GET_ADVERTISERS_WITH_BALANCE,
  GET_HISTORY_ADVERTISER,
  RESEND_EMAIL_BY_ADMIN,
  UNBLOCK_ADVERTISER,
  UPDATE_ADVERTISERS_BALANCE
} from "@core/store/action-constants";
import { adminsList } from "@core/store/modules/admin/lists/adminsList";
import { approvedList } from "@core/store/modules/admin/lists/ApprovedList";
import { blockedList } from "@core/store/modules/admin/lists/BlockedList";
import { emailConfirmedList } from "@core/store/modules/admin/lists/EmailConfirmedList";
import {
  EMPTY_ADVERTISER_EDITABLE_COMMENT,
  SET_ADVERTISER_BALANCE,
  SET_ADVERTISER_HISTORY,
  SET_ADVERTISERS,
  SET_EMPTY,
  UPDATE_ADVERTISER_EDITABLE_COMMENT,
  UPDATE_ADVERTISERS_FILTERS,
  UPDATE_ADVERTISERS_PAGINATION,
  UPDATE_ADVERTISERS_SORTS
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import {
  AdvertisersItem,
  Balance,
  PaginationAdvertisersState
} from "@core/store/types/admin/users/advertisers/AdvertisersState";
import { UserCommentInput } from "@core/store/types/admin/users/common";
import Vue from "vue";
import { ActionTree, Module, MutationTree } from "vuex";
import { detail } from "./modules/detail";
import { edit } from "./modules/edit";
import { Money } from "@core/store/types/common";
import { sortByPreferredCurrency } from "@core/helpers/sortByPreferredCurrency";

const initialEditableComment = (): PaginationAdvertisersState["editableComment"] => ({
  userId: null,
  comment: null
});

const initialState = (): PaginationAdvertisersState => {
  return {
    advertisers: null,
    filters: {
      isBlocked: false
    },
    pagination: {
      page: 1,
      perPage: 25
    },
    sort: null,
    order: null,
    historyAdvertisers: null,
    editableComment: initialEditableComment()
  };
};

const state: () => PaginationAdvertisersState = initialState;

const mutations: MutationTree<PaginationAdvertisersState> = {
  [SET_ADVERTISERS]: (state, advertisers: PaginationAdvertisersState["advertisers"]): void => {
    state.advertisers = advertisers;
  },

  [SET_ADVERTISER_HISTORY]: (state, history: PaginationAdvertisersState["historyAdvertisers"]): void => {
    state.historyAdvertisers = history;
  },

  [SET_ADVERTISER_BALANCE]: (state, { payload, advertiserId }): void => {
    if (state.advertisers && state.advertisers.items) {
      const advertiserIndex = state.advertisers.items.findIndex(advertisers => advertisers.id === advertiserId);
      const balance = payload.balance.map((item: Balance) => ({ ...item.balance }));
      const sortingBalance: any = sortByPreferredCurrency(balance);
      const endingBalance = sortingBalance.map((item: Money, idx: number) => {
        return {
          balance: { ...item },
          updatedAt: payload.balance[idx].updatedAt
        };
      });
      Vue.set(state.advertisers.items, advertiserIndex, {
        ...state.advertisers?.items[advertiserIndex],
        balance: endingBalance
      });
    }
  },

  [UPDATE_ADVERTISERS_SORTS] (state, { sort, order }): void {
    state.sort = sort;
    state.order = order.toUpperCase();
  },

  [UPDATE_ADVERTISERS_FILTERS] (state, filter: any): void {
    state.filters = { ...state.filters, ...filter };
  },
  
  [UPDATE_ADVERTISERS_PAGINATION] (state, pagination): void {
    state.pagination = { ...state.pagination, ...pagination };
  },
  
  [UPDATE_ADVERTISER_EDITABLE_COMMENT] (state, editableComment: PaginationAdvertisersState["editableComment"]): void {
    state.editableComment = { ...state.editableComment, ...editableComment };
  },
  
  [EMPTY_ADVERTISER_EDITABLE_COMMENT] (state): void {
    state.editableComment = initialEditableComment();
  },
  
  [SET_EMPTY] (state) {
    Object.assign(state, initialState());
  }
};

const actions: ActionTree<PaginationAdvertisersState, RootState> = {
  async [GET_ADVERTISERS] ({ state, commit }) {
    const limit = state.pagination.perPage;
    const offset = (state.pagination.page - 1) * limit;

    let {
      loginOrIntId,
      email,
      telegram,
      ...filters
    } = state.filters;

    loginOrIntId = loginOrIntId ? loginOrIntId : null;
    email = email ? email : null;
    telegram = telegram ? telegram : null;

    try {
      const { data: { advertisers } } = await AdvertisersService.getAdvertisers(
        limit,
        offset,
        {
          loginOrIntId,
          email,
          telegram,
          ...filters
        },
        state.sort,
        state.order
      );
  
      commit(SET_ADVERTISERS, advertisers);
    } catch (error) {
      throw error;
    }
  },
  
  async [GET_ADVERTISERS_WITH_BALANCE] ({ dispatch }) {
    await dispatch(GET_ADVERTISERS);
    dispatch(UPDATE_ADVERTISERS_BALANCE);
  },
  
  [UPDATE_ADVERTISERS_BALANCE] ({
    state,
    dispatch
  }) {
    state.advertisers?.items?.forEach((item: AdvertisersItem) => {
      dispatch(ADVERTISER_BALANCE, item.id);
    });
  },
  
  async [ADD_APPROVED_ADVERTISER] (_, advertiserId: string) {
    try {
      await AdvertisersService.approveAdvertiser(advertiserId);
    } catch (error) {
      throw error;
    }
  },

  async [ADD_ADVERTISER_PERSONAL_MANAGER] (_, { userId, adminIds }) {
    try {
      await AdvertisersService.bindAdvertiser(userId, { adminId: adminIds[0] });
    } catch (error) {
      throw error;
    }
  },

  async [DELETE_ADVERTISER_PERSONAL_MANAGER] (_, userId: string) {
    try {
      await AdvertisersService.bindAdvertiser(userId, { adminId: null });
    } catch (error) {
      throw error;
    }
  },

  async [BLOCK_ADVERTISER] (_, advertiserId: string) {
    try {
      await AdvertisersService.blockAdvertiser(advertiserId);
    } catch (error) {
      throw error;
    }
  },

  async [UNBLOCK_ADVERTISER] (_, advertiserId: string) {
    try {
      await AdvertisersService.unblockAdvertiser(advertiserId);
    } catch (error) {
      throw error;
    }
  },

  async [GET_HISTORY_ADVERTISER] ({ commit }, advertiserId: string) {
    try {
      const { data } = await UsersServiceREST.getHistoryAdvertiser(advertiserId);

      commit(SET_ADVERTISER_HISTORY, data);
    } catch (error) {
      throw error;
    }
  },

  async [ADVERTISER_BALANCE] ({ commit }, advertiserId: string) {
    try {
      const { data: { balance } } = await UsersService.balance(advertiserId);
      commit("SET_ADVERTISER_BALANCE", { payload: { balance }, advertiserId });
    } catch (error) {
      throw error;
    }
  },

  async [RESEND_EMAIL_BY_ADMIN] (_, userId: string) {
    await UsersService.resendEmailByAdmin(userId);
  },

  [UPDATE_ADVERTISERS_FILTERS] ({ commit }, filter) {
    commit(UPDATE_ADVERTISERS_FILTERS, filter);
    commit(UPDATE_ADVERTISERS_PAGINATION, { page: 1 });
  },

  [UPDATE_ADVERTISERS_PAGINATION] ({ commit }, pagination) {
    commit(UPDATE_ADVERTISERS_PAGINATION, pagination);
  },

  async [EDIT_ADVERTISER_ADMIN_COMMENT] ({ state }) {
    if (state.editableComment) {
      const { editableComment: { userId, comment } } = state;
      try {
        const input = { comment } as UserCommentInput;
        await UsersService.updateComment(userId as string, input);
      } catch (err) {
        throw err;
      }
    }
  },
  
  [UPDATE_ADVERTISER_EDITABLE_COMMENT] ({ commit }, editableComment) {
    commit(UPDATE_ADVERTISER_EDITABLE_COMMENT, editableComment);
  },
  
  [SET_EMPTY] ({ commit }) {
    commit(SET_EMPTY);
  }
};

export const advertisers: Module<PaginationAdvertisersState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    edit,
    detail,
    blockedList,
    approvedList,
    emailConfirmedList,
    adminsList
  }
};
