import { AxiosPromise } from "axios";
import Service from "@core/services/common/Service";

export class RolesService extends Service{
  public static getPermissions (): AxiosPromise {
    return this.rest.get("/admin/permission");
  }
  
  public static getRoles (): AxiosPromise {
    return this.rest.get("/admin/role");
  }
  
  public static addOrEditMultipleAdminsRoles (params: any): AxiosPromise {
    return this.rest.put("admin/admins/add-or-edit-admins-roles", { admins: params });
  }
  
  public static addRole (params: any): AxiosPromise {
    return this.rest.post("/admin/role", { ...params });
  }
  
  public static editRole (roleId: string, params: any): AxiosPromise {
    return this.rest.put(`/admin/role/${ roleId }`, { ...params });
  }
  
  public static deleteRole (roleId: string): AxiosPromise {
    return this.rest.delete(`/admin/role/${ roleId }`);
  }
}