import { AdvertiserOffer } from "@core/logic/common/offer/offer";
import { WithdrawType } from "@core/logic/common/common";

export class OffersAdvertiserOffer extends AdvertiserOffer {
  name: string | undefined;
  withdrawType: WithdrawType | undefined;
  
  constructor (offer: any) {
    super(offer);
    
    this.name = offer.detail.name;
    this.withdrawType = offer.finance.withdrawType;
  }
}