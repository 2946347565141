import Service from "@/services/common/Service";
import { UploadState } from "@/store/types/admin/uploads/UploadsState";
import { AxiosPromise } from "axios";
import fileExport from "./graphql/fileExport.graphql";
import filesReadyPercent from "./graphql/filesReadyPercent.graphql";
import queryExports from "./graphql/queryExports.graphql";

export class UploadsService extends Service {
  public static queryExports (
    limit: number,
    offset: number,
    filters: UploadState["filters"]
  ): AxiosPromise {
    try {
      return this.api.get("", {
        params: {
          query: queryExports,
          variables: {
            limit,
            offset,
            filters: { ...filters }
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static getFilesReadyPercent (
    limit: number,
    offset: number,
    filters: UploadState["filters"]
  ): AxiosPromise {
    try {
      return this.api.get("", {
        params: {
          query: filesReadyPercent,
          variables: {
            limit,
            offset,
            filters: { ...filters }
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static fileExport (
    queryExportId: string
  ): AxiosPromise {
    try {
      return this.api.get("", {
        params: {
          query: fileExport,
          variables: { queryExportId }
        }
      });
    } catch (e) {
      throw e;
    }
  }
}
