import Service from "@core/services/common/Service";
import { OffersFilters, OffersResponse, OffersState } from "@core/store/types/admin/offers";
import { AxiosPromise } from "axios";
import offers from "./graphql/offers.generator";

export class OffersService extends Service {
  public static getOffers (
    filters: OffersFilters,
    limit: number,
    offset: number,
    sort: OffersState["sort"],
    order: OffersState["order"],
    hasPermissionAdvertisers: boolean
  ): AxiosPromise<OffersResponse> {
    return this.api.get("", {
      params: offers(
        filters,
        limit,
        offset,
        sort,
        order,
        hasPermissionAdvertisers
      )
    });
  }
}
