import { offerEditWebmastersList } from "@core/store/modules/admin/offer/lists/offerEditWebmastersList";
import { ActionTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import { OfferLandingsAddModalState, OfferLandingsAddModalInput } from "@core/store/types/admin/offer/OfferLandingsAddModal";
import { OfferLandingsAddModalService } from "@core/services/admin/offer/OfferLandingsAddModalService";
import {
  CREATE_OFFER_LANDING,
  UPLOAD_LANDING_FILE,
  EDIT_OFFER_LANDING,
  UPDATE_MODAL_TYPE,
  PROXY_OFFER_LANDING
} from "@core/store/action-constants";
import {
  SET_EMPTY,
  SET_MODAL_ACTIVE,
  UPDATE_OFFER_LANDINGS_ADD_MODAL_INPUT,
  UPDATE_OFFER_LANDINGS_ADD_MODAL_UPLOAD,
  UPDATE_OFFER_LANDINGS_ADD_MODAL_EXTERNAL_DOMAIN,
  SET_OFFER_LANDINGS_ADD_MODAL_PAGE_ID,
  UPDATE_MODAL_ACTIVE
} from "@core/store/mutation-constants";
import { integrationCRMList } from "@core/store/modules/admin/lists/integrationCRMList";
import { flowRedirectMacrosList } from "@core/store/modules/admin/lists/flowRedirectMacrosList";

const initialState = (): OfferLandingsAddModalState => {
  return {
    isModalActive: false,
    type: null,
    pageId: null,
    input: {
      fileId: null,
      name: null,
      offerId: null,
      integrationId: null,
      language: null,
      pageType: "landing",
      typeLanding: "interior",
      path: null,
      options: [],
      webmasters: [],
      externalDomain: {
        hostname: null,
        isSecure: true,
        path: null,
        query: null
      }
    },
    upload: {
      file: null
    }
  };
};

const state: () => OfferLandingsAddModalState = initialState;

const mutations: MutationTree<OfferLandingsAddModalState> = {
  [SET_MODAL_ACTIVE] (state, value) {
    state.isModalActive = value;
  },

  [SET_OFFER_LANDINGS_ADD_MODAL_PAGE_ID] (state, pageId) {
    state.pageId = pageId;
  },

  [UPDATE_OFFER_LANDINGS_ADD_MODAL_INPUT] (state, input) {
    state.input = { ...state.input, ...input };
  },

  [UPDATE_OFFER_LANDINGS_ADD_MODAL_EXTERNAL_DOMAIN] (state, input) {
    state.input.externalDomain = { ...state.input.externalDomain, ...input };
  },

  [UPDATE_MODAL_TYPE] (state, type) {
    state.type = type;
  },

  [UPDATE_OFFER_LANDINGS_ADD_MODAL_UPLOAD] (state, upload) {
    state.upload = { ...state.upload, ...upload };
  },
  
  [SET_EMPTY]: state => Object.assign(state, initialState())
};

const actions: ActionTree<OfferLandingsAddModalState, RootState> = {
  async [UPLOAD_LANDING_FILE] ({ commit }, file) {
    const fd = new FormData();
    fd.append("file", file);
    const { data: { fileId } } = await OfferLandingsAddModalService.uploadLandingFile(fd);
    
    commit(UPDATE_OFFER_LANDINGS_ADD_MODAL_INPUT, { fileId });
  },

  async [PROXY_OFFER_LANDING] ({ state: { type }, dispatch }) {
    if (type === "add") {
      await dispatch(CREATE_OFFER_LANDING);

    } else {
      await dispatch(EDIT_OFFER_LANDING);
    }
  },
  
  async [CREATE_OFFER_LANDING] ({ state: { input } }) {
    await OfferLandingsAddModalService.createPage(input as OfferLandingsAddModalInput);
  },
  
  async [EDIT_OFFER_LANDING] ({ state: { pageId, input } }) {
    await OfferLandingsAddModalService.updatePage(pageId as string, input as OfferLandingsAddModalInput);
  },

  [UPDATE_OFFER_LANDINGS_ADD_MODAL_INPUT] ({ commit }, payload) {
    commit(UPDATE_OFFER_LANDINGS_ADD_MODAL_INPUT, payload);
  },

  [UPDATE_OFFER_LANDINGS_ADD_MODAL_EXTERNAL_DOMAIN] ({ commit }, payload) {
    commit(UPDATE_OFFER_LANDINGS_ADD_MODAL_EXTERNAL_DOMAIN, payload);
  },

  [UPDATE_MODAL_ACTIVE] ({ commit }, payload: boolean) {
    if (payload) {
      commit("integrationCRMList/SET_EMPTY");
    }
    commit(SET_MODAL_ACTIVE, payload);

  },

  [UPDATE_MODAL_TYPE] ({ commit }, payload) {
    commit(UPDATE_MODAL_TYPE, payload);
  },

  [UPDATE_OFFER_LANDINGS_ADD_MODAL_UPLOAD] ({ commit }, payload) {
    commit(UPDATE_OFFER_LANDINGS_ADD_MODAL_UPLOAD, payload);
  }
};

export const offerLandingsAddModal: Module<OfferLandingsAddModalState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    integrationCRMList,
    landingsWebmastersList: offerEditWebmastersList,
    flowRedirectMacrosList
  }
};
