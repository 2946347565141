import { WebAccrualService } from "@core/services/admin/finances/webAccrual/WebAccrualService";
import { GET_WEB_ACCRUAL } from "@core/store/action-constants";
import { adminsList } from "@core/store/modules/admin/lists/adminsList";
import { withdrawTypesList } from "@core/store/modules/admin/lists/withdrawTypesList";
import { SET_EMPTY, SET_WEB_ACCRUAL, UPDATE_FILTERS, UPDATE_PAGINATION } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { State } from "@core/store/types/admin/finances/webAccrual";
import moment from "moment";
import { ActionTree, Module, MutationTree } from "vuex";

const initialState = (): State => {
  return {
    data: null,
    filters: {
      dateStart: moment().startOf("day").toDate(),
      dateEnd: moment().toDate(),
      regions: null
    },
    pagination: {
      page: 1,
      perPage: 25
    }
  };
};

const state: () => State = initialState;

const mutations: MutationTree<State> = {
  [SET_EMPTY] (state) {
    Object.assign(state, initialState());
  },

  [SET_WEB_ACCRUAL] (state, data) {
    state.data = data;
  },

  [UPDATE_PAGINATION] (state, pagination) {
    state.pagination = { ...state.pagination, ...pagination };
  },

  [UPDATE_FILTERS] (state, filters) {
    if (Array.isArray(filters.countries) && !filters.countries.length) {
      filters.countries = null;
    }
    state.filters = { ...state.filters, ...filters };
  }
};

const actions: ActionTree<State, RootState> = {
  async [GET_WEB_ACCRUAL] ({ commit, state }) {
    const { pagination: { page, perPage: limit }, filters: { regions, ...filters } } = state;
    const offset = (page - 1) * limit;
		
    const { data: { webAccrual } } = await WebAccrualService.getWebAccrual(limit, offset, {
      regions: regions?.length ? regions : null,
      ...filters
    });
    commit(SET_WEB_ACCRUAL, webAccrual);
  },

  [UPDATE_PAGINATION] ({ commit }, pagination) {
    commit(UPDATE_PAGINATION, pagination);
  },

  [UPDATE_FILTERS] ({ commit, dispatch }, filters) {
    commit(UPDATE_FILTERS, filters);
    commit(UPDATE_PAGINATION, { page: 1 });

    dispatch(GET_WEB_ACCRUAL);
  }
};

export const webAccrual: Module<State, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    adminsList,
    withdrawTypesList
  }
};
