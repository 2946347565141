import { OfferWebmastersService } from "@core/services/admin/offer/webmasters";
import { GET_OFFER_WEBMASTERS } from "@core/store/action-constants";
import {
  SET_EMPTY,
  UPDATE_FILTERS,
  UPDATE_OFFER_WEBMASTERS,
  UPDATE_PAGINATION,
  UPDATE_OFFER_WEBMASTERS_FILTERS
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { State } from "@core/store/types/admin/offer/webmasters";
import { ActionTree, Module, MutationTree } from "vuex";
import { webmastersFilters } from "@core/store/modules/admin/offer/lists/webmastersFilters";

const initialState = (): State => {
  return {
    data: null,
    filters: {
      webmasterId: []
    },
    pagination: {
      page: 1,
      perPage: 10
    }
  };
};

const state: () => State = initialState;

const mutations: MutationTree<State> = {
  [UPDATE_OFFER_WEBMASTERS] (state, data) {
    state.data = { ...state.data, ...data };
  },
  
  [UPDATE_FILTERS] (state, filters) {
    state.filters = { ...state.filters, ...filters };
  },

  [UPDATE_PAGINATION] (state, pagination) {
    state.pagination = { ...state.pagination, ...pagination };
  },

  [SET_EMPTY] (state) {
    Object.assign(state, initialState());
  }
};

const actions: ActionTree<State, RootState> = {
  async [GET_OFFER_WEBMASTERS] ({ commit, state }, offerId) {
    const { pagination: { page, perPage: limit }, filters } = state;
    const offset = (page - 1) * limit;

    const { data: { offer: { webmasters } } } = await OfferWebmastersService.getOfferWebmasters(offerId, filters, limit, offset);
    commit(UPDATE_OFFER_WEBMASTERS, webmasters);
  },
  
  [UPDATE_OFFER_WEBMASTERS_FILTERS] ({ dispatch }, filters) {
    dispatch(`webmastersFilters/${ UPDATE_FILTERS }`, filters);
  },
  
  [UPDATE_FILTERS] ({ commit }, filter) {
    commit(UPDATE_FILTERS, filter);
    commit(UPDATE_PAGINATION, { page: 1 });
  },

  [UPDATE_PAGINATION] ({ commit }, pagination) {
    commit(UPDATE_PAGINATION, pagination);
  }
};

export const webmasters: Module<State, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    webmastersFilters
  }
};
