export default [
  {
    path: "efficiency",
    name: "efficiency",
    redirect: { name: "admin:efficiency:efficiency" },
    component: (): Promise<any> => import(/* webpackChunkName: "efficiency" */ "@/views/Admin/EfficiencyView.vue"),
    children: [
      {
        path: "",
        name: "efficiency",
        component: (): Promise<any> =>
          import(/* webpackChunkName: "efficiency" */ "@/components/Admin/Efficiency/Efficiency.vue")
      },
      {
        path: "plans",
        name: "plans",
        component: (): Promise<any> =>
          import(/* webpackChunkName: "plans" */ "@/components/Admin/Efficiency/EfficiencyPlans.vue")
      }
    ]
  }
];
