import { AxiosPromise } from "axios";
import Service from "@core/services/common/Service";

import { CreateFlowInput, UpdateFlowInput } from "@core/store/types/webmaster/flow";
import createLandingFlow from "@core/services/webmaster/graphql/createLandingFlow.graphql";
import landingFlow from "@core/services/webmaster/graphql/landingFlow.graphql";
import updateLandingFlow from "@core/services/webmaster/graphql/updateLandingFlow.graphql";

export class FlowService extends Service {
  public static createFlow (payload: CreateFlowInput): AxiosPromise {
    return this.api.post("", {
      query: createLandingFlow,
      variables: {
        input: payload
      }
    });
  }
  
  public static updateFlow (flowId: string, payload: UpdateFlowInput): AxiosPromise {
    return this.api.post("", {
      query: updateLandingFlow,
      variables: {
        flowId: flowId,
        input: payload
      }
    });
  }
  
  public static getFlow (payload: string): AxiosPromise {
    return this.api.get("", {
      params: {
        query: landingFlow,
        variables: {
          flowId: payload
        }
      }
    });
  }
}
