import { GraphQLServer } from "@core/plugins/axiosGraphQL";
import offers from "@core/services/admin/common/offer/graphql/availableWebmasters.graphql";
import Service from "@core/services/common/Service";
import { PartialOfferWebmastersAvailableFilters } from "@core/store/types/admin/offer/WebmastersAvailable";
import { AxiosPromise } from "axios";

export class OfferEditWebmastersAvailableService extends Service {

  public static getAvailableWebmasters (limit: number, offset: number,
    filters: PartialOfferWebmastersAvailableFilters,
    id: string): AxiosPromise {
    try {
      return GraphQLServer.get("", {
        params: {
          query: offers,
          variables: {
            limit,
            offset,
            filters,
            id: { id }
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
}
