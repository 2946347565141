<template>
    <main
        id="app">
        <SplashScreen v-if="isSplashScreen"></SplashScreen>
        <!--TODO: сделать splashScreen-->
        <template v-if="!isSplashScreen">
            <router-view></router-view>
        </template>
        <ReleaseUpdate></ReleaseUpdate>
    </main>
</template>

<script>
  import axios from "axios";
  import { mapGetters } from "vuex";
  import SplashScreen from "@/components/Common/SplashScreen";
  import ReleaseUpdate from "@/components/Common/ReleaseUpdate";

  export default {
    name: "App",
    components: {
      SplashScreen,
      ReleaseUpdate
    },
  
    created () {
      this.changeLocale();
      this.addClassToBody(this.isAdmin);
    },

    computed: {
      ...mapGetters([
        "role"
      ]),

      version () {
        return process.env.VUE_APP_VERSION;
      },
    
      isSplashScreen () {
        return (
          (this.$store.state.auth.role == null ||
            !this.$store.getters.isTokenSet ||
            (this.$store.getters.profile || {}).user == null) &&
          !(this.$route.name || "").startsWith("auth")
        );
      },

      isAdmin () {
        return this.role === "admin" && this.$route.name !== "admin:offers:edit" && this.$route.name !== "admin:offers:create"
          || this.$route.name === "auth:signUpProfile" || this.$route.name === "auth:signUpComplete" || this.$route.name === "auth:offers:rates";
      }
    },
  
    methods: {
      changeLocale () {
        const i18n = this.$root.$i18n;

        axios.defaults.headers.common["Accept-Language"] = i18n.locale;
        document.querySelector("html").setAttribute("lang", i18n.locale);
      },

      addClassToBody (isAdmin) {
        if(isAdmin) {
          document.body.classList.add("bulma");
        } else {
          document.body.classList.remove("bulma");
        }
      }
    },
  
    watch: {
      "$root.$i18n.locale" () {
        this.changeLocale();
      },

      isAdmin (value) {
        this.addClassToBody(value);
      }
    }
  };
</script>

<!--suppress CssInvalidFunction, SassScssUnresolvedVariable -->
<style lang="scss">
    @import "scss/main";
    @import url("https://use.fontawesome.com/releases/v6.1.1/css/all.css");

    // Import Bulma's core
    @import url("https://use.fontawesome.com/releases/v6.1.1/css/all.css");
    @import "~bulma/sass/utilities/_all";

    // Set your colors
    @import "scss/variables";

    // Setup $colors to use as bulma classes (e.g. 'is-twitter')
    $colors: (
        "white": ($white, $black, $white, $light-light-invert),
        "black": ($black, $white, $light-light, $light-light-invert),
        "light": ($light, $light-invert, $light-light, $light-light-invert),
        "light-button": ($light-light, $light-light-invert),
        "dark": ($dark, $dark-invert),
        "primary": ($primary, $primary-invert, $primary-light, $primary-light-invert),
        "info": ($info, $info-invert, $info-light, $info-light-invert),
        "success": ($success, $success-invert, $success-light, $success-light-invert),
        "warning": ($warning, $warning-invert, $warning-light, $warning-light-invert),
        "danger": ($danger, $danger-invert, $danger-light, $danger-light-invert)
    );

    // Links
    $link: $primary;
    $link-invert: $primary-invert;
    $link-focus-border: $primary;

    // Import Bulma and Buefy styles
    .bulma {
            @import "scss/theme-default";
            @import "~bulma";
            @import "~buefy/src/scss/buefy";
            @import "scss/main";
    }

</style>
