<template>
    <FontAwesomeIcon
        v-bind="$attrs"
        :icon="styleIcon ? [pack, icon] : icon"
        :class="colorOfType">
    </FontAwesomeIcon>
</template>

<script>
  import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
  import { library } from "@fortawesome/fontawesome-svg-core";
  import { fas } from "@fortawesome/free-solid-svg-icons";
  import { fab } from "@fortawesome/free-brands-svg-icons";
  import { far } from "@fortawesome/free-regular-svg-icons";
  import camelCase from "lodash/camelCase";

  export default {
    name: "FIcon",
    components: {
      FontAwesomeIcon
    },
    props: {
      icon: {
        type: [String, Array],
        required: true
      },
      type: {
        type: [String, Object],
        default: "info"
      },
      pack: {
        type: String,
        default: "fas",
        validator: (v) => ["fas", "far", "fab"].indexOf(v) !== -1
      }
    },

    created () {
      library.add(this.prefixOfPack[camelCase(`fa-${ this.styleIcon ? this.icon : this.icon[1] }`)]);
    },

    beforeUpdate () {
      library.add(this.prefixOfPack[camelCase(`fa-${ this.styleIcon ? this.icon : this.icon[1] }`)]);
    },

    computed: {
      colorOfType () {
        switch (this.type) {
          case "primary":
            return "text-purple-700";

          case "danger":
            return "text-red-500";

          case "warning":
            return "text-yellow-500";

          case "success":
            return "text-green-500";

          case "info":
            return "text-blue-500";

          default:
            return "text-gray-500";
        }
      },

      prefixOfPack () {
        switch (this.styleIcon ? this.pack : this.icon[0]) {
          case "far":
            return far;

          case "fab":
            return fab;

          default:
            return fas;
        }
      },

      styleIcon () {
        return typeof this.icon === "string";
      }
    }
  };
</script>

<style scoped>

</style>