import resendEmail from "@core/services/common/graphql/resendEmail.graphql";
import Service from "@core/services/common/Service";
import {
  EditInput,
  EditResponse,
  WebmasterResponse
} from "@core/store/types/webmaster/profile";
import { WebmasterProfileRequisitesResponse } from "@core/store/types/webmaster/profile/modules/requisites";
import {
  WebmasterProfileRequisitesAddInput,
  WebmasterProfileRequisitesAddResponse,
  WebmasterProfileRequisitesRemoveResponse
} from "@core/store/types/webmaster/profile/modules/requisites/modules/add";
import { WebmasterProfileTokensResponse } from "@core/store/types/webmaster/profile/modules/tokens";
import { AxiosPromise, AxiosResponse } from "axios";
import addRequisiteByWebmaster from "./graphql/addRequisiteByWebmaster.graphql";
import deleteRequisiteByWebmaster from "./graphql/deleteRequisiteByWebmaster.graphql";
import editWebmasterProfile from "./graphql/editWebmasterProfile.graphql";
import profile from "./graphql/profile.graphql";
import requisites from "./graphql/requisites.graphql";
import updateWebmasterNotifications from "./graphql/updateWebmasterNotifications.graphql";

export class ProfileService extends Service {
  public static getProfile (): AxiosPromise<WebmasterResponse> {
    return this.api.get("", {
      params: {
        query: profile
      }
    });
  }

  public static editProfile (input: EditInput): AxiosPromise<EditResponse> {
    return this.api.post("", {
      query: editWebmasterProfile,
      variables: {
        input
      }
    });
  }

  public static getRequisites (): AxiosPromise<WebmasterProfileRequisitesResponse> {
    return this.api.get("", {
      params: {
        query: requisites
      }
    });
  }

  public static addRequisite (input: WebmasterProfileRequisitesAddInput): AxiosPromise<WebmasterProfileRequisitesAddResponse> {
    return this.api.post("", {
      query: addRequisiteByWebmaster,
      variables: {
        input
      }
    });
  }
  
  public static removeRequisite (requisiteId: string): AxiosPromise<WebmasterProfileRequisitesRemoveResponse> {
    return this.api.post("", {
      query: deleteRequisiteByWebmaster,
      variables: {
        requisiteId
      }
    });
  }
  
  public static async getTokens (): Promise<AxiosResponse<WebmasterProfileTokensResponse>> {
    // TODO: Поменять на gql api, когда у бека там появится токены
    const response = await this.rest.get("/webmaster/tokens");
    
    response.data = {
      tokens: {
        items: response.data,
        count: response.data.length
      }
    };
    
    return response;
  }

  public static resendEmail (): AxiosPromise {
    return this.api.post("", {
      query: resendEmail
    });
  }
  
  public static updateNotifications (input: any): AxiosPromise {
    return this.api.post("", {
      query: updateWebmasterNotifications,
      variables: {
        input
      }
    });
  }
}
