import { FileServer } from "../../../../plugins/axios";
import { GraphQLServer } from "../../../../plugins/axiosGraphQL";
import Axios, { AxiosInstance } from "axios";

export default class Service {
  /**
   * @protected
   * @deprecated Use "api" for GraphQL, or "rest" for REST.
   */
  protected static axios: AxiosInstance = GraphQLServer;
  protected static api: AxiosInstance = GraphQLServer;
  protected static files: AxiosInstance = FileServer;
  protected static rest: AxiosInstance = Axios;
}
