import Service from "@core/services/common/Service";
import { Response, StateFilters } from "@core/store/types/admin/finances/webAccrual";
import { AxiosPromise } from "axios";
import webAccrual from "./graphql/webAccrual.graphql";

export class WebAccrualService extends Service {
  public static getWebAccrual (limit: number, offset: number, filters: StateFilters): AxiosPromise<Response> {
    return this.api.get("", {
      params: {
        query: webAccrual,
        variables: {
          limit,
          offset,
          filters
        }
      }
    });
  }
}
