import { AxiosPromise } from "axios";
import Service from "@core/services/common/Service";
import landingPages from "@core/services/webmaster/graphql/landingPages.graphql";
import webmasterRequestToJoinToOffer from "@core/services/webmaster/graphql/joinPrivateOffer.graphql";

export class OfferService extends Service {
  public static async getLandingPages (
    payload: any
  ): Promise<AxiosPromise> {
    try {
      return await this.api.get("", {
        params: {
          query: landingPages,
          variables: payload
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static async joinPrivateOffer (
    offerId: string
  ): Promise<AxiosPromise> {
    try {
      return await this.api.post("", {
        query: webmasterRequestToJoinToOffer,
        variables: {
          offerId
        }
      });
    } catch (e) {
      throw e;
    }
  }
}
