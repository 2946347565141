import { CompetitionService } from "@core/services/admin/competition/CompetitionService";
import {
  UPDATE_COMPETITION_CONDITION_GOAL,
  UPDATE_COMPETITION_CONDITION,
  UPDATE_COMPETITION_FORM,
  CREATE_COMPETITION,
  EDIT_COMPETITION,
  UPDATE_MODAL,
  SET_EMPTY
} from "@core/store/mutation-constants";
import { offersList } from "@core/store/modules/admin/lists/offersList";
import { RootState } from "@core/store/root-state";
import { CompetitionModalState } from "@core/store/types/admin/competition";
import { ActionTree, Module, MutationTree } from "vuex";

const initialState = (): CompetitionModalState => {
  return {
    form: {
      competitionCondition: {
        startDate: null,
        finishDate: null,
        goal: [],
        isPartners: false,
        presentationDate: null
      },
      name: null,
      url: null,
      offers: [],
      countries: []
    },
    option: {
      isActive: false,
      offerId: null,
      status: null
    }
  };
};

export const state: () => CompetitionModalState = initialState;

const mutations: MutationTree<CompetitionModalState> = {
  [UPDATE_COMPETITION_FORM] (state, form) {
    state.form = { ...state.form, ...form };
  },
  
  [UPDATE_COMPETITION_CONDITION] (state, competitionCondition) {
    state.form.competitionCondition = { ...state.form.competitionCondition, ...competitionCondition };
  },
  
  [UPDATE_COMPETITION_CONDITION_GOAL] (state, commission) {
    // @ts-ignore
    state.form.competitionCondition.goal = state.form.competitionCondition.goal?.map(item => {
      if(item.currency === commission.currency) {
        return commission;
      } return item;
    });
  },
  
  [UPDATE_MODAL] (state, modal) {
    state.option = { ...state.option , ...modal };
  },
  
  [SET_EMPTY] (state) {
    Object.assign(state, initialState());
  }
};

const actions: ActionTree<CompetitionModalState, RootState> = {
  async [CREATE_COMPETITION] ({ state }) {
    await CompetitionService.createCompetition(state.form);
  },
  
  async [EDIT_COMPETITION] ({ state: { form, option: { status } } }, competitionId) {
    const { name, url } = form;
    await CompetitionService.editCompetition(competitionId, status === "planned" ? { name, url } : form);
  },
  
  [UPDATE_MODAL] ({ commit }, modal) {
    commit(UPDATE_MODAL, modal);
  },
  
  [UPDATE_COMPETITION_FORM] ({ commit }, form) {
    commit(UPDATE_COMPETITION_FORM, form);
  },
  
  [UPDATE_COMPETITION_CONDITION] ({ commit }, Condition) {
    commit(UPDATE_COMPETITION_CONDITION, Condition);
  },
  
  [UPDATE_COMPETITION_CONDITION_GOAL] ({ commit }, goal) {
    commit(UPDATE_COMPETITION_CONDITION_GOAL, goal);
  },
  
  [SET_EMPTY] ({ commit }) {
    commit(SET_EMPTY);
    commit(`offersList/${ SET_EMPTY }`);
  }
};

export const competitionModal: Module<CompetitionModalState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    offersList
  }
};
