import { OfferEditAdvertisersService } from "@core/services/admin/offer/edit/OfferEditAdvertisersService";
import {
  BEFORE_GET_OFFER_ADVERTISERS,
  DELETE_OFFER_ADVERTISERS,
  GET_ADVERTISER_BY_ID,
  GET_OFFER_ADVERTISERS, GET_OFFER_ID,
  SET_EMPTY,
  UPDATE_MAXIMAL_ALLOWED_WEBMASTER_REWARD,
  UPDATE_MINIMAL_ALLOWED_ADVERTISER_WRITE_OFF,
  UPDATE_OFFER_ADVERTISER
} from "@core/store/action-constants";
import { offerEditAdvertisersModal } from "@core/store/modules/admin/offer/modules/edit/modules/offerEditAdvertisers/modules/offerEditAdvertisersModal";
import {
  SET_OFFER_ADVERTISERZ,
  UPDATE_LOADING,
  UPDATE_OFFER_ADVERTISER_RATE,
  UPDATE_OFFER_ADVERTISERS_PAGINATION,
  UPDATE_OFFER_ADVERTISERZ
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import {
  Advertiser,
  OfferAdvertiser,
  PartialOfferAdvertiserState,
  RequestAdvertiser
} from "@core/store/types/admin/offer/OfferAdvertiser.d";
import { PaginationOutput } from "@core/store/types/PaginationOutput";
import Vue from "vue";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

const initialState = (): PartialOfferAdvertiserState => {
  return {
    advertisers: {},
    pagination: {
      page: 1,
      perPage: 25
    }
  };
};

const state: () => PartialOfferAdvertiserState = initialState;

const getters: GetterTree<PartialOfferAdvertiserState, RootState> = {
  [GET_ADVERTISER_BY_ID]: state => function (advertiserId: string): Advertiser | undefined {
    return state.advertisers?.items?.find((user: OfferAdvertiser) => user.advertiser.id === advertiserId)?.advertiser;
  }
};

const mutations: MutationTree<PartialOfferAdvertiserState> = {
  SET_EMPTY: state => Object.assign(state, initialState()),

  [SET_OFFER_ADVERTISERZ] (state, advertisers: PaginationOutput<OfferAdvertiser[]>) {
    state.advertisers = advertisers;
  },

  [UPDATE_OFFER_ADVERTISERZ] (state, advertisers: PaginationOutput<OfferAdvertiser[]>) {
    if (state.advertisers) {
      state.advertisers = {
        count: advertisers.count,
        items: state.advertisers?.items?.concat(advertisers.items as OfferAdvertiser[])
      };
    }
  },

  [UPDATE_LOADING] (state, { index, params, value }) {
    if (state.advertisers?.items) {
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          const prop = `is${ key.charAt(0).toUpperCase() + key.slice(1) }Loading`;
          Vue.set(state.advertisers.items[index], prop, value);
        }
      }

    }
  },

  [UPDATE_MINIMAL_ALLOWED_ADVERTISER_WRITE_OFF] (state, payload) {
    if (state.advertisers?.items) {
      const advertisersIds = state.advertisers.items.map(item => item.advertiser.id);

      state.advertisers.items = state.advertisers.items.map((item, index) => {
        const newReward = payload[advertisersIds[index]];

        item.minimalAllowedWriteOff = newReward ? newReward : item.minimalAllowedWriteOff;

        return item;
      });
    }
  },

  [UPDATE_OFFER_ADVERTISER_RATE] (state, { params, index }) {
    if (state.advertisers?.items) {
      const key = Object.keys(params)[0];
      Vue.set(state.advertisers.items[index], key, params[key]);
    }
  },

  [UPDATE_OFFER_ADVERTISERS_PAGINATION] (state, pagination) {
    state.pagination = { ...state.pagination, ...pagination };
  }
};

const actions: ActionTree<PartialOfferAdvertiserState, RootState> = {
  SET_EMPTY ({ commit }) {
    commit(SET_EMPTY);
  },

  async [GET_OFFER_ADVERTISERS] ({ state, rootGetters, commit }) {
    const offerId = rootGetters[`admin/offer/edit/common/${ GET_OFFER_ID }`];
    try {
      if (offerId) {
        const limit = state.pagination.perPage;
        const offset = (state.pagination.page - 1) * limit;

        const { data: { offer: { advertisers } } } = await OfferEditAdvertisersService.getOfferAdvertisers(
          limit,
          offset,
          offerId
        );
        advertisers.items = advertisers.items.map((item: OfferAdvertiser) => {
          return {
            ...item,
            writeOff: item.writeOff.value,
            isSoftCapacityLoading: false,
            isWriteOffLoading: false
          };
        });
        if (offset === 0) {
          commit(SET_OFFER_ADVERTISERZ, advertisers);
        } else {
          commit(UPDATE_OFFER_ADVERTISERZ, advertisers);
        }
        return advertisers;
      }
    } catch (e) {
      throw e;
    }
  },

  [BEFORE_GET_OFFER_ADVERTISERS] ({ commit, dispatch, state }) {
    const { page, perPage } = state.pagination;
    
    commit(UPDATE_OFFER_ADVERTISERS_PAGINATION, {
      page: 1,
      perPage: perPage * page
    });
    
    dispatch(GET_OFFER_ADVERTISERS);
    
    commit(UPDATE_OFFER_ADVERTISERS_PAGINATION, {
      page,
      perPage
    });
  },

  async [UPDATE_MINIMAL_ALLOWED_ADVERTISER_WRITE_OFF] ({ state, rootGetters, commit }) {
    const offerId = rootGetters[`admin/offer/edit/common/${ GET_OFFER_ID }`];
    try {
      if (state.advertisers?.items?.length && offerId) {
        const limit = state.pagination.perPage * state.pagination.page;
        const advertisersIds = state.advertisers.items.map(user => user.advertiser.id);

        const { data: { offer: { advertisers: { items } } } } =
                    await OfferEditAdvertisersService.getMinimalAllowedAdvertisersWriteOff(
                      limit,
                      0,
                      offerId,
                      advertisersIds
                    );

        const advertisersWriteOff: { [key: string]: unknown } = {};
        items.forEach((item: OfferAdvertiser) => {
          advertisersWriteOff[item.advertiser.id] = item.minimalAllowedWriteOff;
        });
        commit(UPDATE_MINIMAL_ALLOWED_ADVERTISER_WRITE_OFF, advertisersWriteOff);
      }
    } catch (e) {
      throw e;
    }
  },

  async [UPDATE_OFFER_ADVERTISER] ({ state, rootGetters, dispatch, commit }, { index, params }) {
    const offerId = rootGetters[`admin/offer/edit/common/${ GET_OFFER_ID }`];
    commit(UPDATE_LOADING, { index, params, value: true });
    try {
      if (state.advertisers?.items && offerId) {
        const prop = Object.keys(params)[0];
        const advertiser = state.advertisers.items[index];
        if (params.softCapacity && params.softCapacity === "0") {
          Vue.set(params, "softCapacity", null);
        }
        const newRate: RequestAdvertiser = Object.assign({
          id: advertiser.advertiser.id,
          writeOff: advertiser.writeOff,
          softCapacity: advertiser.softCapacity
        }, { [prop]: params[prop] ? +params[prop] : params[prop] });

        const { data: { editOfferAdvertiser } } =
                    await OfferEditAdvertisersService.editOfferAdvertiser(offerId, newRate);

        dispatch(`admin/offer/edit/webmastersInternalRates/${ UPDATE_MAXIMAL_ALLOWED_WEBMASTER_REWARD }`,
          { offerId: offerId }, { root: true });
        dispatch(`admin/offer/edit/webmastersExternalRates/${ UPDATE_MAXIMAL_ALLOWED_WEBMASTER_REWARD }`,
          { offerId }, { root: true });

        commit(UPDATE_OFFER_ADVERTISER_RATE, { params, index });
        commit(UPDATE_LOADING, { index, params, value: false });
        return editOfferAdvertiser;
      }
    } catch (e) {
      commit(UPDATE_LOADING, { index, params, value: false });
      throw e;
    }
  },

  async [DELETE_OFFER_ADVERTISERS] ({ rootGetters, dispatch }, { advertisers }) {
    const offerId = rootGetters[`admin/offer/edit/common/${ GET_OFFER_ID }`];
    try {
      if (offerId) {
        const { data: { offerExcludeMultipleAdvertisers } } =
                    await OfferEditAdvertisersService.offerExcludeMultipleAdvertisers(offerId, advertisers);

        dispatch(`admin/offer/edit/webmastersInternalRates/${ UPDATE_MAXIMAL_ALLOWED_WEBMASTER_REWARD }`,
          { offerId: offerId }, { root: true });
        dispatch(`admin/offer/edit/webmastersExternalRates/${ UPDATE_MAXIMAL_ALLOWED_WEBMASTER_REWARD }`,
          { offerId }, { root: true });
        return offerExcludeMultipleAdvertisers;
      }
    } catch (e) {
      throw e;
    }
  },

  [UPDATE_OFFER_ADVERTISERS_PAGINATION] ({ commit }, modal) {
    commit(UPDATE_OFFER_ADVERTISERS_PAGINATION, modal);
  }
};

export const offerEditAdvertisers: Module<PartialOfferAdvertiserState, RootState> = {
  namespaced: true,
  modules: {
    offerEditAdvertisersModal
  },
  getters,
  state,
  mutations,
  actions
};
