import { GET_WITHDRAW_TYPES } from "@core/store/action-constants";
import { SET_EMPTY, UPDATE_WITHDRAW_TYPES_LIST } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { WithdrawTypesListState } from "@core/store/types/admin/lists/WithdrawTypesListState";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import i18n from "@core/plugins/i18n";

const initialState = (): WithdrawTypesListState => {
  return {
    data: {}
  };
};

const state: () => WithdrawTypesListState = initialState;

const getters: GetterTree<WithdrawTypesListState, RootState> = {};

const mutations: MutationTree<WithdrawTypesListState> = {
  [UPDATE_WITHDRAW_TYPES_LIST] (state, statuses: WithdrawTypesListState["data"]) {
    state.data = statuses;
  },
  
  [SET_EMPTY]: state => {
    Object.assign(state, initialState());
  }
};

const actions: ActionTree<WithdrawTypesListState, RootState> = {
  [GET_WITHDRAW_TYPES] ({ commit }) {
    const items = [
      {
        value: "ACCEPT",
        label: i18n.t("common.entity.withdrawType.accept")
      },
      {
        value: "REQUEST",
        label: i18n.t("common.entity.withdrawType.request")
      },
      {
        value: "SALE",
        label: i18n.t("common.entity.withdrawType.sale")
      },
      {
        value: "INSTALL",
        label: i18n.t("common.entity.withdrawType.install")
      }
    ];
    commit(UPDATE_WITHDRAW_TYPES_LIST, {
      items,
      count: items.length
    });
  }
};

export const withdrawTypesList: Module<WithdrawTypesListState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
