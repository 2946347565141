import { AxiosPromise } from "axios";
import Service from "@core/services/common/Service";
import { Options } from "@core/store/types/common/domains/domains";
import { LandingDomainsFilters, ResponseLandingDomains } from "@core/store/types/common/lists/landingDomainsList";
import landingDomains from "@core/services/admin/domain/landingDomains.graphql";
import removeLandingDomain from "@core/services/admin/domain/removeLandingDomain.graphql";
import addLandingDomain from "@core/services/admin/domain/addLandingDomain.graphql";

export class DomainsService extends Service {
  public static async getDomains (
    filters: LandingDomainsFilters,
    limit = 25,
    offset = 0
  ): Promise<ResponseLandingDomains> {
    try {
      return await this.api.get("", {
        params: {
          query: landingDomains,
          variables: {
            limit,
            offset,
            filters
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static async addDomain (input: Options | null): Promise<AxiosPromise> {
    try {
      return await this.api.get("", {
        params: {
          query: addLandingDomain,
          variables: {
            input
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static async deleteDomain (domainId: string): Promise<AxiosPromise> {
    try {
      return await this.api.get("", {
        params: {
          query: removeLandingDomain,
          variables: {
            domainId
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
}
