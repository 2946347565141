import { AxiosPromise } from "axios";
import Service from "@core/services/common/Service";
import statistic from "@core/services/admin/statistic/statistic/graphql/statistic.graphql";
import approveDashboard from "@core/services/admin/dashboard/graphql/approveDashboard.graphql";
import commissionDashboard from "@core/services/admin/dashboard/graphql/commissionDashboard.graphql";
import debitDashboard from "@core/services/admin/dashboard/graphql/debitDashboard.graphql";
import prepaidExpenseDashboard from "@core/services/admin/dashboard/graphql/prepaidExpenseDashboard.graphql";
import { BaseFiltersInput } from "@core/store/types/common/statistic/generic/statisticFilters";
import { StatisticResponseData } from "@core/store/types/common/statistic/common/statistic";

export class StatisticsService extends Service {
  public static async getAdminStatistic (
    filters: BaseFiltersInput,
    excludedFilters: BaseFiltersInput,
    groups: string[]
  ): Promise<StatisticResponseData> {
    try {
      return await this.api.get("", {
        params: {
          query: statistic,
          variables: {
            filters,
            excludeFilters: excludedFilters,
            groups
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static getAdminStatisticByLeads (params: any): AxiosPromise {
    return this.rest.get("/admin/leads", { params });
  }
  
  public static async getAdminDebit (dueTo: Date): Promise<AxiosPromise> {
    try {
      return await this.api.get("", {
        params: {
          query: debitDashboard,
          variables: { dueTo }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static async getAdminPrepaid (dueTo: Date): Promise<AxiosPromise> {
    try {
      return await this.api.get("", {
        params: {
          query: prepaidExpenseDashboard,
          variables: { dueTo }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static async getAdminCommission (
    dateStart: Date,
    dateEnd: Date
  ): Promise<AxiosPromise> {
    try {
      return await this.api.get("", {
        params: {
          query: commissionDashboard,
          variables: {
            dateStart,
            dateEnd
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static async getAdminApproves (
    dateStart: Date,
    dateEnd: Date
  ): Promise<AxiosPromise> {
    try {
      return await this.api.get("", {
        params: {
          query: approveDashboard,
          variables: {
            dateStart,
            dateEnd
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static updateAdminStatisticLeadsStatuses (params: any): AxiosPromise<any> {
    return this.rest.post("/admin/integrations/postback/recovery", params);
  }
}
