import store from "../../../store";

export function sortByPreferredCurrency (array: any[]) {
  const preferredCurrency = store.state.auth.preferredCurrency.toUpperCase();
  if (array && Array.isArray(array)) {
    return [...array].sort((a) => {
      if (typeof a === "object") {
        return a.currency.toUpperCase() === preferredCurrency ? -1 : 1;
      } else {
        return a.toUpperCase() === preferredCurrency ? -1 : 1;
      }
    });
  }
}
