export default [
  {
    path: "statistics",
    name: "statistics",
    redirect: { name: "admin:statistics:statistics" },
    component: (): Promise<any> => import(/* webpackChunkName: "admin:statistics" */ "@/views/Admin/StatisticView.vue"),
    children: [
      {
        path: "",
        name: "statistics",
        component: (): Promise<any> =>
          import(/* webpackChunkName: "admin:statistics:statistics" */ "@/components/Admin/Statistic/Statistic.vue")
      },
      {
        path: "detailing",
        name: "detailing",
        component: (): Promise<any> =>
          import(/* webpackChunkName: "admin:statistics:detailing" */ "@/components/Admin/Statistic/Detailing.vue")
      },
      {
        path: "analytic",
        name: "analytic",
        component: (): Promise<any> =>
          import(/* webpackChunkName: "admin:statistics:analytic" */ "@/components/Admin/Statistic/Analytic.vue")
      }
    ]
  }
];
