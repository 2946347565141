import { StatisticsService } from "@core/logic/admin/statistic/statistic";
import { PaginationOutput } from "@core/logic/common/pagination-output";
import { Data as Lead, StatisticDetail } from "@core/logic/statistics/StatisticByLeads";
import { GET_DETAIL, REREQUEST_LEAD_STATUSES } from "@core/store/action-constants";
import { SET_DETAIL, SET_SELECTED_LEADS, SET_STATS_STATUS } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

export interface Details extends PaginationOutput {
  items: StatisticDetail[];
}

export interface DetailsState {
  details: Details | null;
  status: any[];
  selectedLeads: Lead[];
}

const state: DetailsState = {
  details: null,
  status: [],
  selectedLeads: []
};

const getters: GetterTree<DetailsState, RootState> = {};

const mutations: MutationTree<DetailsState> = {
  [SET_DETAIL]: (state, details: Details): void => {
    state.details = details;
  },
  
  [SET_STATS_STATUS]: (state, status): void => {
    state.status = status;
  },
  
  [SET_SELECTED_LEADS]: (state, selectedLeads: Lead[]): void => {
    state.selectedLeads = selectedLeads;
  }
};

const actions: ActionTree<DetailsState, RootState> = {
  async [GET_DETAIL] ({ commit }, payload) {
    try {
      const { data } = await StatisticsService.getAdminStatisticByLeads(payload);
      data.items = data.items.map((detail: any) => new StatisticDetail(detail));
      data.items.forEach((item: any) => {
        if (item.data.status) {
          item.data.status = item.data.status.map(
            (item: string) => item
              .replace(/[\s]+/gm, "_")
              .replace(/[\W\d]*/gm, "")
              .toUpperCase()
          );
        }
      });
      commit(SET_DETAIL, data);
    } catch (error) {
      throw error;
    }
  },
  async [REREQUEST_LEAD_STATUSES] (_, payload) {
    try {
      await StatisticsService.updateAdminStatisticLeadsStatuses(payload);
    } catch (error) {
      throw error;
    }
  }
};


export const detail: Module<DetailsState, RootState> = {
  state,
  getters,
  actions,
  mutations
};
