import integrations from "@core/services/admin/offer/lists/graphql/integrationCRMList.graphql";
import Service from "@core/services/common/Service";
import { IntegrationCRMList, ResponseIntegrationCRM } from "@core/store/types/admin/offer/lists/IntegrationCRMListState";

export class IntegrationCRMListService extends Service {
  public static async getIntegrationsCRM (
    limit: number,
    offset: number,
    filters: IntegrationCRMList["filters"]
  ): Promise<ResponseIntegrationCRM> {
    try {
      return await this.api.get("", {
        params: {
          query: integrations,
          variables: {
            limit,
            offset,
            filters
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
}
