import Service from "@core/services/common/Service";
import { CorrectionHistoryState } from "@core/store/types/admin/finances/financesCorrection/FinancesCorrectionHistoryState";
import { AxiosPromise } from "axios";
import financeTransactions from "@core/services/admin/finances/financesHistory/graphql/financeTransactions.graphql";

export class FinancesCorrectionService extends Service {
  public static getCorrectionHistory (
    limit: number,
    offset: number,
    filters: CorrectionHistoryState["filters"]
  ): AxiosPromise {
    const preparedFilters = this.prepareRequestGetCorrection(filters);
    try {
      return this.api.get("", {
        params: {
          query: financeTransactions,
          variables: {
            limit,
            offset,
            filters: { ...preparedFilters }
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  private static prepareRequestGetCorrection (data: any): any {
    const {
      fromType,
      toType
    } = data;
    data.fromType = fromType?.toUpperCase();
    data.toType = toType?.toUpperCase();
    return data;
  }
}
