import { Filters } from "../../../common/generators/filters";
import { WebmasterItem } from "@core/store/types/admin/statistic/common/webmastersFilters";
import { UserFiltersInput, FilterInput } from "@core/store/types/common/statistic/generic/statisticFilters";
import { OfferWebmastersService } from "@core/services/admin/offer/webmasters";

class WebmastersFilters extends Filters<WebmasterItem, UserFiltersInput> {
  getData = async (offset: number, limit: number, filters: UserFiltersInput) => {
    const { offerId, ...fields } = filters;
    const newFilters = { ...fields, webmasterId: [] };
    const { data: { offer: { webmasters: data } } } = await OfferWebmastersService.getOfferWebmasters(offerId, newFilters, limit, offset);
    
    return {
      data: {
        items: data.items?.map(item => item.webmaster),
        count: data.count
      }
    };
  }
  
  getInitialFilters = (ids?: Array<string> | string): FilterInput | undefined => {
    if (ids) {
      const webmasterId = Array.isArray(ids) ? ids : [ids];
      return { webmasterId };
    }
  };
}

export const webmastersFilters = new WebmastersFilters();
