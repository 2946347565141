// common
export const SET_MODAL_ACTIVE = "SET_MODAL_ACTIVE";
export const SET_TOTAL = "SET_TOTAL";
export const UPDATE_FILTERS = "UPDATE_FILTERS";
export const CLEAR_FILTERS = "CLEAR_FILTERS";
export const UPDATE_PAGINATION = "UPDATE_PAGINATION";
export const SET_EMPTY = "SET_EMPTY";
export const SET_EMPTY_DATA = "SET_EMPTY_DATA";
export const SET_EMPTY_FILTERS = "SET_EMPTY_FILTERS";
export const UPDATE_MODAL = "UPDATE_MODAL";
export const UPDATE_SORTINGS = "UPDATE_SORTINGS";
// locale
export const SET_LOCALE = "SET_LOCALE";
// error
export const SHOW_ERROR = "SHOW_ERROR";
export const ERROR = "ERROR";
// success
export const SHOW_SUCCESS = "SHOW_SUCCESS";
export const SUCCESS = "SUCCESS";
// loading
export const SHOW_LOADING = "SHOW_LOADING";
// auth
export const SAVE_USER = "SAVE_USER";
export const SET_TOKEN = "SET_TOKEN";
export const LOGOUT = "LOGOUT";
// user
export const SET_USER_ROLE = "SET_USER_ROLE";
// statistics
export const SET_STATISTIC_DATEPICKER = "SET_STATISTIC_DATEPICKER";
export const SET_DETAIL = "SET_DETAIL";
export const SET_STATS_STATUS = "SET_STATS_STATUS";
export const SET_SELECTED_LEADS = "SET_SELECTED_LEADS";
export const UPDATE_DETAILING_FILTERS = "UPDATE_DETAILING_FILTERS";
export const UPDATE_DETAILING_PAGINATION = "UPDATE_DETAILING_PAGINATION";

export const GET_FLOWS_DICTIONARY = "GET_FLOWS_DICTIONARY";
export const SET_FLOWS_DICTIONARY = "SET_FLOWS_DICTIONARY";
export const UPDATE_FLOWS_DICTIONARY = "UPDATE_FLOWS_DICTIONARY";

export const GET_OFFERS_DICTIONARY = "GET_OFFERS_DICTIONARY";
export const SET_OFFERS_DICTIONARY = "SET_OFFERS_DICTIONARY";
export const UPDATE_OFFERS_DICTIONARY = "UPDATE_OFFERS_DICTIONARY";

export const GET_WEBMASTERS_DICTIONARY = "GET_WEBMASTERS_DICTIONARY";
export const SET_WEBMASTERS_DICTIONARY = "SET_WEBMASTERS_DICTIONARY";
export const UPDATE_WEBMASTERS_DICTIONARY = "UPDATE_WEBMASTERS_DICTIONARY";

export const GET_EXECUTORS_DICTIONARY = "GET_EXECUTORS_DICTIONARY";
export const SET_EXECUTORS_DICTIONARY = "SET_EXECUTORS_DICTIONARY";
export const UPDATE_EXECUTORS_DICTIONARY = "UPDATE_EXECUTORS_DICTIONARY";

export const GET_ADVERTISERS_DICTIONARY = "GET_ADVERTISERS_DICTIONARY";
export const SET_ADVERTISERS_DICTIONARY = "SET_ADVERTISERS_DICTIONARY";
export const UPDATE_ADVERTISERS_DICTIONARY = "UPDATE_ADVERTISERS_DICTIONARY";

export const GET_ADMINS_DICTIONARY = "GET_ADMINS_DICTIONARY";
export const SET_ADMINS_DICTIONARY = "SET_ADMINS_DICTIONARY";
export const UPDATE_ADMINS_DICTIONARY = "UPDATE_ADMINS_DICTIONARY";

export const UPDATE_BUNCHES_DICTIONARY = "UPDATE_BUNCHES_DICTIONARY";

export const SET_CURRENCIES = "SET_CURRENCIES";

export const UPDATE_FILTER_DATA = "UPDATE_FILTER_DATA";

// detailing
export const UPDATE_LEAD_STATUSES_LIST = "UPDATE_LEAD_STATUSES_LIST";


// profile
export const SET_STATISTIC = "SET_STATISTIC";
export const SET_SUBSTATISTIC = "SET_SUBSTATISTIC";
export const SET_PERSONAL_MANAGER = "SET_PERSONAL_MANAGER";
export const SET_API_TOKENS = "SET_API_TOKENS";
export const SET_REQUISITES = "SET_REQUISITES";
export const SET_PAYMENT_SYSTEMS = "SET_PAYMENT_SYSTEMS";
export const SET_TRAFFIC_TYPES = "SET_TRAFFIC_TYPES";

export const SET_DASH_STATS = "SET_DASH_STATS";
export const SET_DAY_STATS = "SET_DAY_STATS";
export const SET_OFFER_CATEGORIES = "SET_OFFER_CATEGORIES";

export const SET_TIMEZONES = "SET_TIMEZONES";

export const SET_REGIONS = "SET_REGIONS";

// finance
export const SET_FINANCES = "SET_FINANCES";
export const SET_STATUS = "SET_STATUS";

// costs
export const SET_COSTS = "SET_COSTS";
export const UPDATE_COSTS_FILTERS = "UPDATE_COSTS_FILTERS";
export const UPDATE_COSTS_PAGINATION = "UPDATE_COSTS_PAGINATION";

// refill
export const SET_INCOMES = "SET_INCOMES";

// correction
export const UPDATE_CORRECTION_FORM_OPTIONS = "UPDATE_CORRECTION_FORM_OPTIONS";
export const SET_CORRECTIONS = "SET_CORRECTIONS";
export const UPDATE_CORRECTIONS_FILTERS = "UPDATE_CORRECTIONS_FILTERS";
export const UPDATE_CORRECTIONS_PAGINATION = "UPDATE_CORRECTIONS_PAGINATION";

// Tickets
export const SET_TICKETS = "SET_TICKETS";
export const SET_TICKET = "SET_TICKET";
export const PUSH_TICKET = "PUSH_TICKET";
export const PUSH_TICKET_MESSAGE = "PUSH_TICKET_MESSAGE";
export const SET_TICKET_MESSAGES = "SET_TICKET_MESSAGES";

// offers
export const SET_OFFERS = "SET_OFFERS";
export const SET_COUNTRIES = "SET_COUNTRIES";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const SET_OFFERS_TYPES = "SET_OFFERS_TYPES";

// offer
export const SET_OFFER = "SET_OFFER";
export const SET_OFFER_WEBMASTERS = "SET_OFFER_WEBMASTERS";
export const UPDATE_OFFER_WEBMASTERS_PAGINATION = "UPDATE_OFFER_WEBMASTERS_PAGINATION";
export const SET_OFFER_WEBMASTERS_AVAILABLE = "SET_OFFER_WEBMASTERS_AVAILABLE";
export const UPDATE_OFFER_AVAILABLE_FILTERS = "UPDATE_OFFER_AVAILABLE_FILTERS";
export const UPDATE_OFFER_WEBMASTERS_AVAILABLE_PAGINATION = "UPDATE_OFFER_WEBMASTERS_AVAILABLE_PAGINATION";
export const SET_OFFER_ADVERTISERS_AVAILABLE = "SET_OFFER_ADVERTISERS_AVAILABLE";
export const UPDATE_OFFER_ADVERTISERS_AVAILABLE_PAGINATION = "UPDATE_OFFER_ADVERTISERS_AVAILABLE_PAGINATION";
export const SET_OFFER_ADVERTISERZ = "SET_OFFER_ADVERTISERZ";
export const UPDATE_OFFER_FILTERS = "UPDATE_OFFER_FILTERS";
export const SET_OFFER_ADVERTISER_WEBMASTERS = "SET_OFFER_ADVERTISER_WEBMASTERS";
export const SET_OFFER_TRAFFIC_TYPES = "SET_OFFER_TRAFFIC_TYPES";
export const UPDATE_INTEGRATIONS_PAGINATION = "UPDATE_INTEGRATIONS_PAGINATION";
export const UPDATE_OFFER_WEBMASTERS = "UPDATE_OFFER_WEBMASTERS";
export const SET_OFFER_WEBMASTERS_ID = "SET_OFFER_WEBMASTERS_ID";
export const UPDATE_OFFER_ADVERTISERZ = "UPDATE_OFFER_ADVERTISERZ";
export const SET_OFFER_RATES_REQUIREMENTS = "SET_OFFER_RATES_REQUIREMENTS";
export const SET_OFFER_ADVERTISER_INDIVIDUAL_RATE = "SET_OFFER_ADVERTISER_INDIVIDUAL_RATE";
export const UPDATE_OFFER_ADVERTISER_INDIVIDUAL_RATES_PAGINATION = "UPDATE_OFFER_ADVERTISER_INDIVIDUAL_RATES_PAGINATION";
export const UPDATE_OFFER_ADVERTISER_INDIVIDUAL_RATES_FILTERS = "UPDATE_OFFER_ADVERTISER_INDIVIDUAL_RATES_FILTERS";
export const UPDATE_OFFER_ADVERTISER_WEBMASTERS = "UPDATE_OFFER_ADVERTISER_WEBMASTERS";
export const SET_LANDINGS = "SET_LANDINGS";
export const SET_TRANSITS = "SET_TRANSITS";
export const UPDATE_SELECTED_ADVERTISERS_IDS = "UPDATE_SELECTED_ADVERTISERS_IDS";
export const UPDATE_MODAL_ACTIVE = "UPDATE_MODAL_ACTIVE";
export const UPDATE_MODAL_OPTIONS = "UPDATE_MODAL_OPTIONS";
export const UPDATE_SELECTED_WEBMASTERS_BALANCE = "UPDATE_SELECTED_WEBMASTERS_BALANCE";
export const UPDATE_OFFER_WEBMASTERS_REWARD = "UPDATE_OFFER_WEBMASTERS_REWARD";
export const UPDATE_LOADING = "UPDATE_LOADING";
export const UPDATE_OFFER_ADVERTISER_RATE = "UPDATE_OFFER_ADVERTISER_RATE";
export const UPDATE_OFFER_ADVERTISER_INDIVIDUAL_RATES = "UPDATE_OFFER_ADVERTISER_INDIVIDUAL_RATES";
export const UPDATE_OFFER_ID = "UPDATE_OFFER_ID";
export const SET_OFFER_ADVERTISER_INDIVIDUAL_RATES = "SET_OFFER_ADVERTISER_INDIVIDUAL_RATES";
export const UPDATE_OFFER_OPTIONS = "UPDATE_OFFER_OPTIONS";

// Offer webmasters
export const UPDATE_SELECTED_WEBMASTERS_IDS = "UPDATE_SELECTED_WEBMASTERS_IDS";
export const EMPTY_WEBMASTERS_LIST = "EMPTY_WEBMASTERS_LIST";
export const UPDATE_WEBMASTERS_FILTERS = "UPDATE_WEBMASTERS_FILTERS";
export const UPDATE_WEBMASTERS_LIST_PAGINATION = "UPDATE_WEBMASTERS_LIST_PAGINATION";
export const UPDATE_OFFER_WEBMASTER_RATE = "UPDATE_OFFER_WEBMASTER_RATE";
export const INCLUDE_WEBMASTERS_OFFERS = "INCLUDE_WEBMASTERS_OFFERS";
export const EXCLUDE_WEBMASTERS_FORM_OFFERS = "EXCLUDE_WEBMASTERS_FORM_OFFERS";

// Admin. Offer. Landings
export const SET_OFFER_LANDINGS_ID = "SET_OFFER_LANDINGS_ID";
export const SET_OFFER_LANDINGS = "SET_OFFER_LANDINGS";
export const UPDATE_OFFER_LANDINGS = "UPDATE_OFFER_LANDINGS";
export const SET_OFFER_TRANSITS = "SET_OFFER_TRANSITS";
export const UPDATE_OFFER_LANDINGS_ADD_MODAL_INPUT = "UPDATE_OFFER_LANDINGS_ADD_MODAL_INPUT";
export const UPDATE_OFFER_LANDINGS_ADD_MODAL_UPLOAD = "UPDATE_OFFER_LANDINGS_ADD_MODAL_UPLOAD";
export const SET_OFFER_LANDINGS_ADD_MODAL_PAGE_ID = "SET_OFFER_LANDINGS_ADD_MODAL_PAGE_ID";

// flow
export const SET_NAME = "SET_NAME";
export const SET_IS_LAUNCHED = "SET_IS_LAUNCHED";
export const SET_OFFER_ID = "SET_OFFER_ID";
export const SET_OPTIONS = "SET_OPTIONS";
export const SET_CHECKED_LANDINGS = "SET_CHECKED_LANDINGS";
export const SET_CHECKED_PRELANDINGS = "SET_CHECKED_PRELANDINGS";
export const SET_CHECKED_BUNCHES = "SET_CHECKED_BUNCHES";
export const UPDATE_BUNCHES = "UPDATE_BUNCHES";
export const UPDATE_ANALYTICS = "UPDATE_ANALYTICS";

// postback detail
export const SET_STATISTIC_POSTBACKS = "SET_STATISTIC_POSTBACKS";
export const SET_POSTBACK = "SET_POSTBACK";
export const SET_POSTBACK_EMPTY = "SET_POSTBACK_EMPTY";
export const SET_GLOBAL_POSTBACK_EMPTY = "SET_GLOBAL_POSTBACK_EMPTY";
export const SET_GLOBAL_POSTBACKS = "SET_GLOBAL_POSTBACKS";
export const ADD_GLOBAL_POSTBACK = "ADD_GLOBAL_POSTBACK";
export const UPDATE_POSTBACK_STATUS = "UPDATE_POSTBACK_STATUS";
export const UPDATE_POSTBACK_STATE = "UPDATE_POSTBACK_STATE";
export const UPDATE_GLOBAL_POSTBACK_URL = "UPDATE_GLOBAL_POSTBACK_URL";
export const UPDATE_GLOBAL_POSTBACK_STATUSES = "UPDATE_GLOBAL_POSTBACK_STATUSES";

// admin
export const SET_UNINTEGRATION_OFFERS = "SET_UNINTEGRATION_OFFERS";
export const SET_CRM_ALIAS = "SET_CRM_ALIAS";
export const UPDATE_CRM_PAGINATION = "UPDATE_CRM_PAGINATION";
export const SET_ADVERTISER_ID = "SET_ADVERTISER_ID";
export const UPDATE_INTEGRATION_FILTERS = "UPDATE_INTEGRATION_FILTERS";
export const UPDATE_INTEGRATION_OPTIONS = "UPDATE_INTEGRATION_OPTIONS";
export const SET_INTEGRATION_OPTIONS = "SET_INTEGRATION_OPTIONS";
export const SET_INTEGRATIONS = "SET_INTEGRATIONS";
export const UPDATE_INTEGRATIONS_FILTERS = "UPDATE_INTEGRATIONS_FILTERS";
export const SET_OFFER_ADVERTISERS = "SET_OFFER_ADVERTISERS";
export const SET_CRM = "SET_CRM";
export const UPDATE_INTEGRATION_CRM_LIST = "UPDATE_INTEGRATION_CRM_LIST";
export const SET_INTEGRATION_CRM_LIST = "SET_INTEGRATION_CRM_LIST";
export const UPDATE_TOP_CARD_FILTERS = "UPDATE_TOP_CARD_FILTERS";
export const UPDATE_TOP_CARD_AMOUNT_TYPE = "UPDATE_TOP_CARD_AMOUNT_TYPE";
export const UPDATE_TOP_CARD_PERIODS = "UPDATE_TOP_CARD_PERIODS";
export const SET_ACTIVE_COUNTS = "SET_ACTIVE_COUNTS";

// users
export const SET_WEBMASTERS = "SET_WEBMASTERS";
export const UPDATE_WEBMASTERS_PAGINATION = "UPDATE_WEBMASTERS_PAGINATION";
export const SET_ADVERTISERS = "SET_ADVERTISERS";
export const SET_ADMINISTRATORS = "SET_ADMINISTRATORS";
export const UPDATE_ADMINISTRATORS_FILTERS = "UPDATE_ADMINISTRATORS_FILTERS";
export const UPDATE_ADMINISTRATORS_PAGINATION = "UPDATE_ADMINISTRATORS_PAGINATION";
export const UPDATE_ADMINISTRATORS_SORTS = "UPDATE_ADMINISTRATORS_SORTS";
export const SET_WEBMASTER_HISTORY = "SET_WEBMASTER_HISTORY";
export const UPDATE_WEBMASTERS_SORTINGS = "UPDATE_WEBMASTERS_SORTINGS";
export const SET_ADVERTISER_HISTORY = "SET_ADVERTISER_HISTORY";
export const SET_WEBMASTER_BALANCE = "SET_WEBMASTER_BALANCE";
export const UPDATE_WEBMASTERS = "UPDATE_WEBMASTERS";
export const SET_ADVERTISER_BALANCE = "SET_ADVERTISER_BALANCE";
export const SET_IS_ADVERTISERS_EDIT_MODAL_ACTIVE = "SET_IS_ADVERTISERS_EDIT_MODAL_ACTIVE";
export const SET_ADVERTISERS_EDIT_ID = "SET_ADVERTISERS_EDIT_ID";
export const UPDATE_ADVERTISERS_EDIT_FORM = "UPDATE_ADVERTISERS_EDIT_FORM";
export const SET_ADVERTISERS_EDIT_FORM = "SET_ADVERTISERS_EDIT_FORM";
export const UPDATE_WEBMASTERS_EDIT_FORM = "UPDATE_WEBMASTERS_EDIT_FORM";
export const SET_WEBMASTERS_EDIT_FORM = "SET_WEBMASTERS_EDIT_FORM";
export const SET_IS_WEBMASTERS_EDIT_MODAL_ACTIVE = "SET_IS_WEBMASTERS_EDIT_MODAL_ACTIVE";
export const SET_WEBMASTERS_EDIT_ID = "SET_WEBMASTERS_EDIT_ID";
export const SET_IS_ADMINISTRATORS_EDIT_MODAL_ACTIVE = "SET_IS_ADMINISTRATORS_EDIT_MODAL_ACTIVE";
export const SET_ADMINISTRATORS_EDIT_ID = "SET_ADMINISTRATORS_EDIT_ID";
export const SET_ADMINISTRATORS_EDIT_FORM = "SET_ADMINISTRATORS_EDIT_FORM";
export const UPDATE_ADMINISTRATORS_EDIT_FORM = "UPDATE_ADMINISTRATORS_EDIT_FORM";
export const UPDATE_WEBMASTER_EDITABLE_COMMENT = "UPDATE_WEBMASTER_EDITABLE_COMMENT";
export const EMPTY_WEBMASTER_EDITABLE_COMMENT = "EMPTY_WEBMASTER_EDITABLE_COMMENT";
export const UPDATE_ADVERTISERS_SORTS = "UPDATE_ADVERTISERS_SORTS";
export const UPDATE_ADVERTISERS_PAGINATION = "UPDATE_ADVERTISERS_PAGINATION";
export const UPDATE_ADVERTISER_EDITABLE_COMMENT = "UPDATE_ADVERTISER_EDITABLE_COMMENT";
export const EMPTY_ADVERTISER_EDITABLE_COMMENT = "EMPTY_ADVERTISER_EDITABLE_COMMENT";

// Admin. Users. Advertisers
export const UPDATE_ADVERTISER_DETAIL_DATA = "UPDATE_ADVERTISER_DETAIL_DATA";
export const SET_ADVERTISER_RATES = "SET_ADVERTISER_RATES";

//callCenters
export const SET_AVAILABLE_CALLCENTERS_FOR_OFFER = "SET_AVAILABLE_CALLCENTERS_FOR_OFFER";
export const SET_CALLCENTERS = "SET_CALLCENTERS";


// Roles
export const SET_PERMISSIONS = "SET_PERMISSIONS";
export const SET_ROLES = "SET_ROLES";

// Domains
export const SET_DOMAINS = "SET_DOMAINS";
export const UPDATE_DOMAINS = "UPDATE_DOMAINS";

// Admin. Efficiency
export const SET_EFFICIENCY = "SET_EFFICIENCY";
export const SET_GROUP = "SET_GROUP";
export const UPDATE_EFFICIENCY_FILTERS = "UPDATE_EFFICIENCY_FILTERS";

// Admin. Plans
export const SET_PLANS = "SET_PLANS";
export const UPDATE_PLANS_PAGINATION = "UPDATE_PLANS_PAGINATION";
export const UPDATE_PLANS_EDITABLE_COMMENT = "UPDATE_PLANS_EDITABLE_COMMENT";
export const EMPTY_PLANS_EDITABLE_COMMENT = "EMPTY_PLANS_EDITABLE_COMMENT";
export const SET_PLANS_HISTORY_MANAGER = "SET_PLANS_HISTORY_MANAGER";
export const UPDATE_PLANS_HISTORY = "UPDATE_PLANS_HISTORY";
export const SET_PLANS_EDIT_MANAGER_ID = "SET_PLANS_EDIT_MANAGER_ID";
export const UPDATE_PLANS_EDIT_FORM = "UPDATE_PLANS_EDIT_FORM";

// Lists. admins
export const EMPTY_ADMINS_LIST = "EMPTY_ADMINS_LIST";
export const UPDATE_ADMINS_FILTERS = "UPDATE_ADMINS_FILTERS";
export const UPDATE_ADMINS_LIST_PAGINATION = "UPDATE_ADMINS_LIST_PAGINATION";

// Lists. admins
export const EMPTY_REQUISITES_LIST = "EMPTY_REQUISITES_LIST";
export const UPDATE_REQUISITES_FILTERS = "UPDATE_REQUISITES_FILTERS";
export const UPDATE_REQUISITES_LIST_PAGINATION = "UPDATE_REQUISITES_LIST_PAGINATION";

// Lists. statuses
export const UPDATE_STATUSES_LIST = "UPDATE_STATUSES_LIST";

// Lists. PaymentSystems
export const EMPTY_PAYMENT_SYSTEMS_LIST = "EMPTY_PAYMENT_SYSTEMS_LIST";
export const UPDATE_PAYMENT_SYSTEMS_FILTERS = "UPDATE_PAYMENT_SYSTEMS_FILTERS";
export const UPDATE_PAYMENT_SYSTEMS_LIST_PAGINATION = "UPDATE_PAYMENT_SYSTEMS_LIST_PAGINATION";

// Lists. Advertisers
export const EMPTY_ADVERTISERS_LIST = "EMPTY_ADVERTISERS_LIST";
export const SET_ADVERTISERS_LIST = "SET_ADVERTISERS_LIST";
export const UPDATE_ADVERTISERS_FILTERS = "UPDATE_ADVERTISERS_FILTERS";
export const UPDATE_ADVERTISERS_INCLUDES_FILTERS = "UPDATE_ADVERTISERS_INCLUDES_FILTERS";
export const UPDATE_ADVERTISERS_LIST_PAGINATION = "UPDATE_ADVERTISERS_LIST_PAGINATION";

// Lists. Offers
export const EMPTY_OFFERS_LIST = "EMPTY_OFFERS_LIST";
export const SET_OFFERS_LIST = "SET_OFFERS_LIST";
export const UPDATE_OFFERS_FILTERS = "UPDATE_OFFERS_FILTERS";
export const UPDATE_OFFERS_INCLUDES_FILTERS = "UPDATE_OFFERS_INCLUDES_FILTERS";
export const UPDATE_OFFERS_LIST_PAGINATION = "UPDATE_OFFERS_LIST_PAGINATION";
export const UPDATE_PERMISSIONS = "UPDATE_PERMISSIONS";

// Lists. Offer Advertisers
export const UPDATE_OFFER_ADVERTISERS = "UPDATE_OFFER_ADVERTISERS";
export const UPDATE_OFFER_ADVERTISERS_PAGINATION = "UPDATE_OFFER_ADVERTISERS_PAGINATION";
export const UPDATE_OFFER_ADVERTISERS_FILTERS = "UPDATE_OFFER_ADVERTISERS_FILTERS";

//Lists. PaymentIntegrations
export const EMPTY_PAYMENT_INTEGRATIONS_LIST = "EMPTY_PAYMENT_INTEGRATIONS_LIST";
export const UPDATE_PAYMENT_INTEGRATIONS_FILTERS = "UPDATE_PAYMENT_INTEGRATIONS_FILTERS";
export const UPDATE_PAYMENT_INTEGRATIONS_LIST_PAGINATION = "UPDATE_PAYMENT_INTEGRATIONS_LIST_PAGINATION";

//Lists. Approved
export const UPDATE_APPROVED_LIST = "UPDATE_APPROVED_LIST";

//Lists. EmailConfirmed
export const UPDATE_EMAIL_CONFIRMED_LIST = "UPDATE_EMAIL_CONFIRMED_LIST";

//Lists. Blocked
export const UPDATE_BLOCKED_LIST = "UPDATE_BLOCKED_LIST";

//Lists. PartnerNetwork
export const UPDATE_PARTNER_NETWORK_LIST = "UPDATE_PARTNER_NETWORK_LIST";

//Lists. CRM
export const EMPTY_CRM_LIST = "EMPTY_CRM_LIST";
export const UPDATE_CRM_FILTERS = "UPDATE_CRM_FILTERS";
export const UPDATE_CRM_LIST_PAGINATION = "UPDATE_CRM_LIST_PAGINATION";

//Lists. WithdrawTypes
export const UPDATE_WITHDRAW_TYPES_LIST = "UPDATE_WITHDRAW_TYPES_LIST";

//Lists. OfferTypes
export const UPDATE_OFFER_TYPES_LIST = "UPDATE_OFFER_TYPES_LIST";

// Lists. Languages
export const EMPTY_LANGUAGES_LIST = "EMPTY_LANGUAGES_LIST";
export const SET_LANGUAGES_LIST = "SET_LANGUAGES_LIST";

// Lists. Countries
export const EMPTY_COUNTRIES_LIST = "EMPTY_COUNTRIES_LIST";
export const SET_COUNTRIES_LIST = "SET_COUNTRIES_LIST";

// Payments
export const UPDATE_PAYMENT_SYSTEMS = "UPDATE_PAYMENT_SYSTEMS";
export const UPDATE_PAYMENTS_PAGINATION = "UPDATE_PAYMENTS_PAGINATION";

// Webmaster. Profile
export const SET_WEBMASTER_PROFILE = "SET_WEBMASTER_PROFILE";
export const UPDATE_WEBMASTER_PROFILE_FORM = "UPDATE_WEBMASTER_PROFILE_FORM";

// Webmaster. Profile. Requisites
export const UPDATE_WEBMASTER_REQUISITES_ADD_FORM = "UPDATE_WEBMASTER_REQUISITES_ADD_FORM";
export const UPDATE_REQUISITES_DELETE = "UPDATE_REQUISITES_DELETE";

// Advertiser. Profile
export const UPDATE_ADVERTISER_FORM = "UPDATE_ADVERTISER_FORM";

// Admin. Profile
export const UPDATE_ADMIN_PROFILE_FORM = "UPDATE_ADMIN_PROFILE_FORM";

// Password
export const UPDATE_PASSWORD_FORM = "UPDATE_PASSWORD_FORM";
// Webmaster. Flows
export const SET_FLOWS = "SET_FLOWS";
export const SET_FLOW_FIELDS = "SET_FLOW_FIELDS";
export const SET_FLOW_ID = "SET_FLOW_ID";
export const SET_FLOW_DYNAMIC_FIELDS = "SET_FLOW_DYNAMIC_FIELDS";
export const UPDATE_FLOW_URL = "UPDATE_FLOW_URL";
export const UPDATE_FLOWS_FILTERS = "UPDATE_FLOWS_FILTERS";

// Statistic
export const SET_TODAY_STATISTIC = "SET_TODAY_STATISTIC";
export const SET_OLD_STATISTIC = "SET_OLD_STATISTIC";
export const SET_NEW_STATISTIC = "SET_NEW_STATISTIC";

// CRM
export const UPDATE_METHODS_LIST = "UPDATE_METHODS_LIST";
export const UPDATE_REQUEST_TYPES_LIST = "UPDATE_REQUEST_TYPES_LIST";
export const UPDATE_RESPONSE_TYPES_LIST = "UPDATE_RESPONSE_TYPES_LIST";
export const UPDATE_STRATEGY_LIST = "UPDATE_STRATEGY_LIST";
export const UPDATE_MACROS_LIST = "UPDATE_MACROS_LIST";
export const UPDATE_INTEGRATION_DETAILS = "UPDATE_INTEGRATION_DETAILS";
export const UPDATE_CUSTOM_MACROS = "UPDATE_CUSTOM_MACROS";
export const UPDATE_VALIDATION_OPTIONS = "UPDATE_VALIDATION_OPTIONS";
export const UPDATE_RECOVERY_STRATEGY_LIST = "UPDATE_RECOVERY_STRATEGY_LIST";
export const UPDATE_POSTBACK_STATUS_MAP = "UPDATE_POSTBACK_STATUS_MAP";
export const UPDATE_MODAL_IS_EDIT = "UPDATE_MODAL_IS_EDIT";
export const UPDATE_IS_VALID = "UPDATE_IS_VALID";
export const UPDATE_FLOW_REDIRECT_MACROS_LIST = "UPDATE_FLOW_REDIRECT_MACROS_LIST";

// Admin. Finances. WebAccrual
export const SET_WEB_ACCRUAL = "SET_WEB_ACCRUAL";

// Correction
export const UPDATE_CORRECTION_TYPES_LIST = "UPDATE_CORRECTION_TYPES_LIST";
export const UPDATE_CORRECTION_TARGET_LIST = "UPDATE_CORRECTION_TARGET_LIST";

// Uploads
export const SET_UPLOADS = "SET_UPLOADS";
export const UPDATE_UPLOADS = "UPDATE_UPLOADS";
export const UPDATE_UPLOADS_FILTERS = "UPDATE_UPLOADS_FILTERS";
export const UPDATE_UPLOADS_PAGINATION = "UPDATE_UPLOADS_PAGINATION";
export const SET_UPLOAD = "SET_UPLOAD";
export const UPDATE_UPLOAD_FILTERS = "UPDATE_UPLOAD_FILTERS";
export const UPDATE_UPLOAD_PAGINATION = "UPDATE_UPLOAD_PAGINATION";
export const UPDATE_FORMAT_FILE_LIST = "UPDATE_FORMAT_FILE_LIST";
export const UPDATE_UPLOADS_FIELDS_LIST = "UPDATE_UPLOADS_FIELDS_LIST";
export const UPDATE_FILES_UPLOADED = "UPDATE_FILES_UPLOADED";
export const UPDATE_FILES_READY_PERCENT = "UPDATE_FILES_READY_PERCENT";
export const UPDATE_FILE_LINK = "UPDATE_FILE_LINK";

// Webmaster. Offer. Postbacks
export const SET_OFFER_POSTBACKS = "SET_OFFER_POSTBACKS";
export const ADD_OFFER_POSTBACK = "ADD_OFFER_POSTBACK";
export const UPDATE_OFFER_POSTBACK = "UPDATE_OFFER_POSTBACK";
export const UPDATE_OFFER_POSTBACK_URL = "UPDATE_OFFER_POSTBACK_URL";
export const UPDATE_OFFER_POSTBACK_STATUSES = "UPDATE_OFFER_POSTBACK_STATUSES";

// Advertiser. Finances
export const UPDATE_INCOMES_FILTERS = "UPDATE_INCOMES_FILTERS";
export const UPDATE_INCOMES_PAGINATION = "UPDATE_INCOMES_PAGINATION";
