import Service from "@core/services/common/Service";
import { BindAdminInput, PaginationAdvertisersState } from "@core/store/types/admin/users/advertisers/AdvertisersState";
import { AxiosPromise } from "axios";
import advertisers from "./graphql/advertisers.graphql";
import approveAdvertiser from "./graphql/approveAdvertiser.graphql";
import bindAdvertiser from "./graphql/bindAdvertiser.graphql";
import blockAdvertiser from "./graphql/blockAdvertiser.graphql";
import unblockAdvertiser from "./graphql/unblockAdvertiser.graphql";

export class AdvertisersService extends Service {
  public static getAdvertisers (limit: number,
    offset: number,
    filters: PaginationAdvertisersState["filters"],
    sort: PaginationAdvertisersState["sort"],
    order: PaginationAdvertisersState["order"]
  ): AxiosPromise {
    try {
      return this.api.get("", {
        params: {
          query: advertisers,
          variables: {
            limit,
            offset,
            filters,
            sort,
            order
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static approveAdvertiser (advertiserId: string): AxiosPromise {
    try {
      return this.api.post("", {
        query: approveAdvertiser,
        variables: {
          advertiserId
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static bindAdvertiser (advertiserId: string, input: BindAdminInput): AxiosPromise {
    try {
      return this.api.post("", {
        query: bindAdvertiser,
        variables: {
          advertiserId,
          input
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static blockAdvertiser (advertiserId: string): AxiosPromise {
    try {
      return this.api.post("", {
        query: blockAdvertiser,
        variables: {
          advertiserId
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static unblockAdvertiser (advertiserId: string): AxiosPromise {
    try {
      return this.api.post("", {
        query: unblockAdvertiser,
        variables: {
          advertiserId
        }
      });
    } catch (e) {
      throw e;
    }
  }
}
