import { Manager } from "@core/logic/common/common";

type Status = "in processing" | "closed" | "confirmed" | "hold up %s" | "paid" | "canceled" | "expired" | "spam" |
"fraud" | "replay" | "the confirmation";

export interface Data {
  advertiserWriteOff: number;
  calls: any;
  callsCount: number;
  comment: string;
  cancelReason: string;
  clickId: string;
  offerId: string;
  ip: string;
  domain: string;
  id: string;
  createdAt: string;
  offer: string;
  status: Status[];
  phone: string;
  amount: number;
  webmaster: Webmaster | null;
  remoteId: string | null;
  target: string | null;
  unholdDate: string | null;
}

interface Labels {
  utmCampaign: string;
  utmContent: string;
  utmMedium: string;
  utmSource: string;
  utmTerm: string;
}

interface Webmaster {
  id: string;
  intId: number;
  login: string;
  manager: Manager;
}

export class StatisticDetail {
  public id: "";
  public labels: Labels = {
    utmCampaign: "",
    utmContent: "",
    utmMedium: "",
    utmSource: "",
    utmTerm: ""
  };
  public data: Data = {
    advertiserWriteOff: 0,
    calls: [],
    callsCount: 0,
    comment: "",
    cancelReason: "",
    clickId: "",
    offerId: "",
    ip: "",
    domain: "",
    id: "",
    createdAt: "",
    offer: "",
    status: [],
    phone: "",
    amount: 0,
    webmaster: null,
    remoteId: null,
    target: "",
    unholdDate: ""
  };


  constructor (statisticDetail: any) {
    this.id = statisticDetail.id;

    this.labels.utmCampaign = statisticDetail.fields.utm_campaign || "-";
    this.labels.utmContent = statisticDetail.fields.utm_content || "-";
    this.labels.utmMedium = statisticDetail.fields.utm_medium || "-";
    this.labels.utmSource = statisticDetail.fields.utm_source || "-";
    this.labels.utmTerm = statisticDetail.fields.utm_term || "-";

    this.data.advertiserWriteOff = statisticDetail.advertiserWriteOff || null;
    this.data.calls = statisticDetail.calls || null;
    this.data.callsCount = statisticDetail.callsCount || "-";
    this.data.comment = statisticDetail.comment || "-";
    this.data.cancelReason = statisticDetail.cancelReason || null;
    this.data.clickId = statisticDetail.fields.clickId || "-";
    this.data.offerId = statisticDetail.fields.offerId || "-";
    this.data.ip = statisticDetail.fields.ip || "-";
    this.data.domain = statisticDetail.fields.domain || "-";
    this.data.id = statisticDetail.id || "-";
    this.data.createdAt = statisticDetail.createdAt || "-";
    this.data.offer = statisticDetail.offer || "-";
    this.data.status = statisticDetail.status || "-";
    this.data.amount = statisticDetail.amount || 0;
    this.data.phone = statisticDetail.fields.phone || null;
    this.data.webmaster = statisticDetail.webmaster || null;
    this.data.remoteId = statisticDetail.remoteId || null;
    this.data.target = statisticDetail.target || null;
    this.data.unholdDate = statisticDetail.unholdDate || null;
  }
}
