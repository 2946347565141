import Vue from "vue";
import VueHighlightJS from "vue-highlight.js";
// Highlight.js languages (Only required languages)
// @ts-ignore
import php from "highlight.js/lib/languages/php";
// @ts-ignore
import javascript from "highlight.js/lib/languages/javascript";
// @ts-ignore
import xml from "highlight.js/lib/languages/xml";
/*
* Import Highlight.js theme
* Find more: https://highlightjs.org/static/demo/
*/
import "highlight.js/styles/atom-one-light.css";

Vue.use(VueHighlightJS, {
  // Register only languages that you want
  languages: {
    php,
    xml,
    javascript
  }
});
