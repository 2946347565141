import { Auth, AuthService, Login, Registration, TelegramLogin } from "@core/logic/auth/service";
import { AuthenticationService } from "@core/services/common/AuthenticationService";
import store from "../../../../store";
import { FETCH_USER, INITIALIZATION, UPDATE_TOKEN } from "@core/store/action-constants";
import { SAVE_USER } from "@core/store/mutation-constants";
import Vue from "../../../../main";

export default class AuthenticationProvider {
  public refreshToken = "";
  public login = "";
  public self: boolean;
  public userType: string;
  public isConfirmed: boolean;
  public isBlocked: boolean;
  public subType: string;
  public preferredCurrency: string;
  public isApproved: boolean;
  public isPartnerNetwork: boolean;
  public token: string;
  public expiresIn: number;
  public intId: string;
  
  constructor (auth: Auth) {
    this.refreshToken = auth.refreshToken;
    this.login = auth.login;
    this.self = auth.self;
    this.userType = auth.userType;
    this.isConfirmed = auth.isConfirmed;
    this.isBlocked = auth.isBlocked;
    // TODO убрать приведение регистра
    this.subType = auth.subType?.toUpperCase();
    this.preferredCurrency = auth.preferredCurrency;
    this.isApproved = auth.isApproved;
    this.token = auth.token;
    this.isPartnerNetwork = auth.isPartnerNetwork;
    this.expiresIn = auth.expiresIn;
    this.intId = auth.intId;
    
    store.dispatch(UPDATE_TOKEN, {
      access: this.token,
      expiresIn: this.expiresIn
    }).then();
    
    store.dispatch(INITIALIZATION).then();
    // TODO: Описать $ym в типе vue
    // @ts-ignore
    Vue.$ym?.setUserId(this.login, this.intId);
  
    store.commit(SAVE_USER, {
      role: this.userType,
      self: this.self,
      isConfirmed: this.isConfirmed,
      isApproved: this.isApproved,
      isBlocked: this.isBlocked,
      subType: this.subType,
      preferredCurrency: this.preferredCurrency,
      isPartnerNetwork: this.isPartnerNetwork,
      intId: this.intId,
      token: {
        refresh: this.refreshToken,
        access: this.token,
        expiresIn: this.expiresIn
      }
    });
    
    // noinspection PointlessBooleanExpressionJS
    if (this.userType && this.isBlocked === false) {
      store.dispatch(`${ FETCH_USER }`).then();
    }
  }
  
  /**
   * Логин через логин/пароль
   * @param {Login} login
   * @return {Promise<AuthenticationProvider>}
   */
  static async loginByPassword (login: Login): Promise<AuthenticationProvider> {
    const { data } = await AuthService.loginByPassword(login);
    data.self = true;
    return new AuthenticationProvider(data);
  }
  
  /**
   * Логин по телеграм аккаунту
   * @param {TelegramLogin} payload
   * @return {Promise<AuthenticationProvider>}
   */
  static async loginByTelegram (payload: TelegramLogin): Promise<AuthenticationProvider> {
    const { data } = await AuthService.loginByTelegram(payload);
    data.self = true;
    return new AuthenticationProvider(data);
  }
  
  /**
   * Логин по рефреш токену, при обновлении страницы
   * @param {string} refreshToken
   * @return {Promise<AuthenticationProvider>}
   */
  static async loginByToken (refreshToken: string): Promise<AuthenticationProvider> {
    let { data: { refreshToken: data } } = await AuthenticationService.refreshToken(refreshToken);
    
    const { token: access, expiresIn } = data;
    
    await store.dispatch(UPDATE_TOKEN, { access, expiresIn });
    
    const { data: { whoAmI } } = await AuthenticationService.whoAmI();
    
    data = { ...data, ...whoAmI, ...{ refreshToken } };
    data.userType = data.userType.toLowerCase();
    
    return new AuthenticationProvider(data);
  }
  
  /**
   * Логин за вебмастера
   * @param {string} webmasterId
   * @return {Promise<AuthenticationProvider>}
   */
  static async loginAsWebmaster (webmasterId: string): Promise<AuthenticationProvider> {
    const { data: { loginAsWebmaster } } = await AuthenticationService.loginAsWebmaster(webmasterId);
    loginAsWebmaster.self = false;
    return new AuthenticationProvider(loginAsWebmaster);
  }
  
  /**
   * Логин за рекламодателя
   * @param {string} advertiserId
   * @return {Promise<AuthenticationProvider>}
   */
  static async loginAsAdvertiser (advertiserId: string): Promise<AuthenticationProvider> {
    const { data: { loginAsAdvertiser } } = await AuthenticationService.loginAsAdvertiser(advertiserId);
    loginAsAdvertiser.self = false;
    return new AuthenticationProvider(loginAsAdvertiser);
  }
  
  /**
   * Логин за админа из вебмастера/рекламодателя
   * @return {Promise<AuthenticationProvider>}
   */
  static async logoutToAdmin (): Promise<AuthenticationProvider> {
    const { data } = await AuthService.returnToAdmin();
    data.self = true;
    return new AuthenticationProvider(data);
  }
  
  static async loginByRegistration (payload: Registration): Promise<AuthenticationProvider> {
    const { data } = await AuthService.registration(payload);
    data.self = true;
    return new AuthenticationProvider(data);
  }
}
