<template>
    <button
        v-show="active"
        v-loading="true"
        element-loading-spinner="el-icon-loading"
        :class="size"
        class="button is-white loading"
        disabled>
    </button>
</template>

<script>
  export default {
    name: "InlineLoader",
    props: {
      active: {
        type: Boolean,
        default: false
      },
      size: {
        type: String,
        default: "is-small"
      }
    }
  };
</script>

<style lang="scss" scoped>
        .loading {
            font-size: 24px;
        }
</style>
