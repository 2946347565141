import { AbstractOffer } from "@core/store/logic/common/offer/AbstractOffer";
import { WebmasterOfferInterface } from "@core/store/types/webmaster/offer";
import { Money } from "@core/store/types/common";

export class WebmasterOffer extends AbstractOffer {
  public isPostbackEnable?: boolean;
  public isWebmasterJoinedToOffer?: boolean;
  public type?: string;
  public webmasterReward?: Money;
  public currency?: string;
  public trackingUrl?: string;
  
  constructor (payload?: WebmasterOfferInterface) {
    super(payload);
    if (payload) {
      this.id = payload.id;
      this.intId = payload.intId;
      this.name = payload.detail.name;
      this.isPostbackEnable = payload.isPostbackEnable;
      this.isWebmasterJoinedToOffer = payload.isWebmasterJoinedToOffer;
      this.preCost = payload.landing?.preCost;
      this.cost = payload.landing?.cost;
      this.trackingUrl = payload.landing?.trackingUrl;
      this.logo = payload.logo;
      this.photoUrl = payload.photoUrl;
      this.landingUrl = payload.landingUrl;
      this.type = payload.type;
      this.webmasterReward = payload.finance.webmasterReward;
      this.withdrawType = payload.finance.withdrawType;
      this.currency = payload.finance.currency;
      this.categories = payload.categories;
      this.countries = payload.countries;
      this.createdAt = payload.createdAt;
      this.isNewCreated = payload.isNewCreated;
    }
  }
}

