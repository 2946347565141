<template>
    <div>
        <el-container class="sticky top-0 z-30 bg-gray-background pb-3 laptop:pb-0">
            <el-header
                class="header w-full items-center z-10 inset-x-0 mb-3.5 laptop:mb-0 laptop:pb-0 sm:mb-6"
                height="110px">
                <el-row
                    class="w-full flex flex-wrap items-center justify-between pt-8 tablet:pt-12 laptop:pt-0">
                    <el-col
                        class="w-1/2 mt-4 mobile:mt-0 laptop:hidden tablet:pl-4 tablet:w-1/3 sm:mt-0"
                        :span="12">
                        <Logo></Logo>
                    </el-col>
                    <el-col
                        :span="12"
                        class="flex text-xl font-bold uppercase items-center h-10 w-full laptop:bg-opacity-0 justify-between px-6 border-t-2 border-gray-100 mt-2
                    order-3 laptop:order-none tablet:py-3.5 tablet:px-6 bg-white laptop:w-auto laptop:mt-0 tablet:border-none
                    mobile:h-14 tablet:px-0">
                        <img
                            class="hidden xl:block"
                            src="@/assets/NavigationBar/main.svg"
                            alt="icon">
                        <span
                            class="text-blue-text">
                            {{ screenname }}
                        </span>
                        <img
                            class="laptop:hidden cursor-pointer tablet:z-10"
                            src="@/assets/NavigationBar/mobile-menu.svg"
                            alt="menu"
                            @click="$emit('menuOpen')">
                    </el-col>
                    <InfoBar
                        class="tablet:w-3/5"
                        :as-admin="asAdmin"
                        :login="user.login"
                        :role="role"></InfoBar>
                </el-row>
            </el-header>
        </el-container>
        <AdminProfileModal></AdminProfileModal>
    </div>
</template>

<script>
  import { ADMIN_LOGOUT_ROLE, UPDATE_LOCALE } from "@core/store/action-constants";
  import { UPDATE_MODAL } from "@core/store/mutation-constants";
  import { mapActions, mapGetters, mapState } from "vuex";
  import Logo from "@/components/Common/Logo";
  import InfoBar from "@/components/Common/NavigationBar/InfoBar";
  import AdminProfileModal from "@/components/Admin/NavigationBar/AdminProfileModal";

  export default {
    name: "NavigationBar",
    components: {
      AdminProfileModal,
      Logo,
      InfoBar
    },

    data () {
      return {
        isActiveModal: false,
        isPopupShown: false
      };
    },
    computed: {
      screenname () {
        const path = `${ this.role }.navBar.screenname.${ this.screenName }`;
        return this.$te(path) ? this.$t(path) : this.screenName;
      },
      
      isProfileModal: {
        get () {
          // У веба прфоиль не в модалке и вообще не в навбаре
          if (this.role !== "webmaster") {
            return this.$store[this.role].profile.modal.isActive;
          }
          return false;
        },

        set (isActive) {
          if (this.role !== "webmaster") {
            // Не mapMutations, т.к. у веба такой мутации нет
            this.$store.commit(`${ this.role }/profile/${ UPDATE_MODAL }`, { isActive });
          }
        }
      },

      ...mapState({
        role: state => state.auth.role
      }),

      ...mapGetters([
        "user",
        "role",
        "asAdmin"
      ]),
    
      screenName () {
        const path = this.$route.name.split(":");
        return path[1] ?? path[0];
      },

      locale: {
        get () {
          return this.$store.state.locale.locale;
        },

        set (value) {
          this.UPDATE_LOCALE(value);
        }
      }
    },
    methods: {
      ...mapActions([
        UPDATE_LOCALE,
        ADMIN_LOGOUT_ROLE
      ]),
  
      /**
       *
       * @param locale: string
       * @return { string }
       * @constructor
       */
      ISOLocale (locale) {
        if (locale === "dev") {
          return "eu";
        } else if (locale === "en") {
          return "us";
        } else {
          return locale;
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
    .el-row::before, .el-row::after {
        content: none;
    }

    .el-popper .popper__arrow, .el-popper .popper__arrow::after {
        display: none;
    }

    .el-dropdown-menu {
        top: 64px!important;
    
        @media (max-width: 991px) {
            top: 40px!important;
        }
    }

    .header {
        display: flex!important;

        @media (min-width: 426px) and (max-width: 640px) {
            @apply mb-6;
        }
    }
</style>
