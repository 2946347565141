import { FinancesCorrectionService } from "@core/services/admin/finances/financesHistory/FinancesCorrectionService";
import { GET_CORRECTIONS } from "@core/store/action-constants";
import { correctionTargetList } from "@core/store/modules/admin/finances/modules/financesCorrection/lists/correctionTargetList";
import { adminsList } from "@core/store/modules/admin/lists/adminsList";
import { SET_CORRECTIONS, UPDATE_CORRECTIONS_FILTERS, UPDATE_CORRECTIONS_PAGINATION } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import {
  CorrectionHistoryState,
  PartialFinancesCorrectionHistoryState
} from "@core/store/types/admin/finances/financesCorrection/FinancesCorrectionHistoryState";
import moment from "moment";
import { ActionTree, Module, MutationTree } from "vuex";
import { create } from "./financesCorrectionCreateModal";

const initialState = (): PartialFinancesCorrectionHistoryState => {
  return {
    corrections: null,
    filters: {
      datepicker: {
        dateStart: moment().startOf("day").subtract(7, "day").toDate(),
        dateEnd: moment().endOf("day").toDate()
      }
    },
    pagination: {
      page: 1,
      perPage: 25
    }
  };
};

const state: () => PartialFinancesCorrectionHistoryState = initialState;

const mutations: MutationTree<PartialFinancesCorrectionHistoryState> = {
  SET_EMPTY: state => Object.assign(state, initialState()),
  
  [SET_CORRECTIONS] (state, items: CorrectionHistoryState["corrections"]) {
    state.corrections = items;
  },
  
  [UPDATE_CORRECTIONS_FILTERS] (state, filter: any) {
    if (filter?.intId !== undefined && !filter.intId) {
      filter.intId = undefined;
    }
    
    state.filters = { ...state.filters, ...filter };
  },
  
  [UPDATE_CORRECTIONS_PAGINATION] (state, pagination) {
    state.pagination = { ...state.pagination, ...pagination };
  }
};

const actions: ActionTree<PartialFinancesCorrectionHistoryState, RootState> = {
  async [GET_CORRECTIONS] ({ state, commit }) {
    const limit = state.pagination.perPage;
    const offset = (state.pagination.page - 1) * limit;
    const { datepicker, ...filters } = state.filters;
    
    try {
      const { data: { financeTransactions } } = await FinancesCorrectionService.getCorrectionHistory(
        limit,
        offset,
        {
          ...datepicker,
          ...filters
        }
      );
      
      commit(SET_CORRECTIONS, financeTransactions);
    } catch (e: any) {
      throw new Error(e);
    }
  },
  
  [UPDATE_CORRECTIONS_PAGINATION] ({ commit }, payload) {
    commit(UPDATE_CORRECTIONS_PAGINATION, payload);
  },
  
  [UPDATE_CORRECTIONS_FILTERS] ({ dispatch, commit }, payload) {
    if (payload?.fromType !== undefined && !payload.fromType) {
      commit(UPDATE_CORRECTIONS_FILTERS, { fromType: payload.fromType, fromUser: null });
    } else if (payload?.toType !== undefined && !payload.toType) {
      commit(UPDATE_CORRECTIONS_FILTERS, { toType: payload.toType, toUser: null });
    } else {
      commit(UPDATE_CORRECTIONS_FILTERS, payload);
    }
    
    
    
    commit(UPDATE_CORRECTIONS_PAGINATION, { page: 1 });
    
    dispatch(GET_CORRECTIONS);
  },
  
  SET_EMPTY ({ commit }) {
    commit("SET_EMPTY");
    commit("create/advertisersList/SET_EMPTY");
    commit("create/webmastersList/SET_EMPTY");
    commit("adminsList/SET_EMPTY");
  }
};

export const correction: Module<PartialFinancesCorrectionHistoryState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    create,
    correctionTargetList,
    adminsList
  }
};
