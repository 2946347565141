import { FinancesCorrectionCreateService } from "@core/services/admin/finances/financesHistory/FinancesCorrectionCreateService";
import { CREATE_CORRECTION, UPDATE_CORRECTION_TYPE, UPDATE_MODAL_OPTIONS } from "@core/store/action-constants";
import { correctionTypesList } from "@core/store/modules/admin/finances/modules/financesCorrection/lists/correctionsList";
import { advertisersList } from "@core/store/modules/admin/lists/advertisersList";
import { webmastersList } from "@core/store/modules/admin/lists/webmastersList";
import { UPDATE_MODAL_ACTIVE, SET_CURRENCY } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import store from "../../../../../../../../store";
import {
  CorrectionCreateItem,
  CorrectionCreateState
} from "@core/store/types/admin/finances/financesCorrection/FinancesCorrectionCreateState";
import { CorrectionType } from "@core/store/types/admin/finances/financesCorrection/lists/correctionsList";
import { ActionTree, Module, MutationTree } from "vuex";
import { currencyList } from "@core/store/modules/common/lists/currencyList";

const initialState = (): CorrectionCreateState => {
  return {
    options: {
      from: null,
      to: null,
      amount: 0,
      comment: null,
      currency: null
    },
    correctionType: null,
    isCorrectionCreateModalActive: false
  };
};

const state: () => CorrectionCreateState = initialState;

const mutations: MutationTree<CorrectionCreateState> = {
  SET_EMPTY: state => Object.assign(state, initialState()),

  [UPDATE_MODAL_OPTIONS] (state, option: CorrectionCreateItem) {
    state.options = { ...state.options, ...option };
  },

  [UPDATE_MODAL_ACTIVE] (state, payload: boolean) {
    state.isCorrectionCreateModalActive = payload;
  },
  
  [SET_CURRENCY] (state) {
    state.options.currency = store.state.auth.preferredCurrency;
  },

  [UPDATE_CORRECTION_TYPE] (state, payload: CorrectionType) {
    state.correctionType = payload;
  }
};

const actions: ActionTree<CorrectionCreateState, RootState> = {

  async [CREATE_CORRECTION] ({ state }) {
    try {
      await FinancesCorrectionCreateService.createFinanceTransaction(state.options);
    } catch (e: any) {
      throw new Error(e);
    }
  },

  [UPDATE_MODAL_OPTIONS] ({ commit }, payload: CorrectionCreateItem) {
    commit(UPDATE_MODAL_OPTIONS, payload);
  },

  [UPDATE_CORRECTION_TYPE] ({ commit }, payload: CorrectionType) {
    commit(UPDATE_CORRECTION_TYPE, payload);
    if (!payload) {
      commit(UPDATE_MODAL_OPTIONS, { from: null, to: null });
    }
  },

  [UPDATE_MODAL_ACTIVE] ({ commit }, payload: boolean) {
    commit("SET_EMPTY");
    commit("webmastersList/SET_EMPTY");
    commit("advertisersList/SET_EMPTY");
    commit(UPDATE_MODAL_ACTIVE, payload);
  }
};

export const create: Module<CorrectionCreateState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    currencyList,
    advertisersList,
    webmastersList,
    correctionTypesList
  }
};
