<!--

Пропы:

isEdit: флаг редактирования профиля. По умолчанию false (создание профиля). Отключает инпуты, если true

-->

<template>
    <section>
        <div class="columns is-multiline">
            <div class="column is-6">
                <LTooltip
                    :active="profileLoaded"
                    :label="$t(`advertiser.profile.labels.loginMessage`)"
                    class="is-block"
                    position="is-bottom"
                    type="is-danger">
                    <FField :label="$t(`advertiser.profile.labels.login`)">
                        <CustomInput
                            v-if="profileLoaded"
                            v-model="_user.login"
                            :label="$t(`advertiser.profile.labels.login`)"
                            disabled>
                        </CustomInput>
                        <el-skeleton
                            v-else
                            animated>
                            <template #template>
                                <el-skeleton-item style="width: 100%; height: 46px;"></el-skeleton-item>
                            </template>
                        </el-skeleton>
                    </FField>
                </LTooltip>
            </div>
            <div class="column is-6">
                <LTooltip
                    :active="!isSuperEdit && profileLoaded"
                    :label="$t(`advertiser.profile.labels.emailMessage`)"
                    class="is-block"
                    position="is-bottom"
                    type="is-danger">
                    <FField :label="$t(`advertiser.profile.labels.email`)">
                        <RequiredField
                            v-if="profileLoaded"
                            v-model="_user.email"
                            prop="email"
                            :placeholder="$t(`advertiser.profile.labels.email`)"
                            pattern-message="common.validation.email"
                            :disabled="!isSuperEdit"
                            :pattern="emailValidator"
                            type="email">
                        </RequiredField>
                        <el-skeleton
                            v-else
                            animated>
                            <template #template>
                                <el-skeleton-item style="width: 100%; height: 46px;"></el-skeleton-item>
                            </template>
                        </el-skeleton>
                    </FField>
                </LTooltip>
            </div>
            <div class="column is-6">
                <FField :label="$t(`advertiser.profile.labels.tg`)">
                    <RequiredField
                        v-if="profileLoaded"
                        v-model="_telegram"
                        prop="telegram"
                        :disabled="isEdit"
                        pattern="^@[\d\w]{5,}$"
                        pattern-message="common.auth.signUp.telegramHelp"
                        name="telegram"
                        :placeholder="$t(`advertiser.profile.labels.tg`)">
                        <template #prepend>
                            @
                        </template>
                    </RequiredField>
                    <el-skeleton
                        v-else
                        animated>
                        <template #template>
                            <el-skeleton-item style="width: 100%; height: 46px;"></el-skeleton-item>
                        </template>
                    </el-skeleton>
                </FField>
            </div>
            <!-- FIXME: Бек не присылает таймзону -->
            <!-- <div class="column is-6">
                <CustomInput
                    v-if="profileLoaded"
                    :label="$t(`advertiser.profile.labels.timezone`)">
                    <CustomSelect
                        v-model="user.timeZone"
                        :disabled="isEdit">
                        <optgroup
                            :key="continent"
                            v-for="(zones, continent) in timeZones"
                            :label="continent">
                            <option
                                :key="i"
                                v-for="({ alias, offset }, i) in zones"
                                :value="alias">
                                {{ alias }} {{ offset }}
                            </option>
                        </optgroup>
                    </CustomSelect>
                </CustomInput>
                <b-skeleton
                    v-else
                    width="100%"
                    height="46px"
                    animated>
                </b-skeleton>
            </div> -->
            <div class="column is-6">
                <FField :label="$t(`advertiser.profile.labels.geo`)">
                    <FInput
                        v-if="profileLoaded"
                        v-model="_user.geo"
                        :disabled="isEdit"
                        :label="$t(`advertiser.profile.labels.geo`)"
                        :placeholder="$t(`advertiser.profile.labels.geo`)">
                    </FInput>
                    <el-skeleton
                        v-else
                        animated>
                        <template #template>
                            <el-skeleton-item style="width: 100%; height: 46px;"></el-skeleton-item>
                        </template>
                    </el-skeleton>
                </FField>
            </div>
            <div class="column is-6">
                <FField :label="$t(`advertiser.profile.labels.leads`)">
                    <FInput
                        v-if="profileLoaded"
                        v-model.number="_user.sizeLeads"
                        :disabled="isEdit"
                        :label="$t(`advertiser.profile.labels.leads`)"
                        :placeholder="$t(`advertiser.profile.labels.leads`)"
                        type="number">
                    </FInput>
                    <el-skeleton
                        v-else
                        animated>
                        <template #template>
                            <el-skeleton-item style="width: 100%; height: 46px;"></el-skeleton-item>
                        </template>
                    </el-skeleton>
                </FField>
            </div>
        </div>
        <div v-if="role !== 'advertiser'">
            <h2 class="subtitle card-header-title mb-0 pb-0 column is-12">
                {{ $t(`advertiser.profile.labels.penalties`) }}
            </h2>
            <div class="column px-1 pb-1">
                <CustomCheckbox
                    v-if="profileLoaded"
                    v-model="_user.isFineDebt"
                    :label="$t('advertiser.profile.labels.isFineDebt')">
                </CustomCheckbox>
            </div>
            <div class="columns is-multiline">
                <div class="column is-6">
                    <FField>
                        <template #label>
                            <span class="default-white-space is-flex">
                                <LabelWithAnnotation
                                    :tooltip="$t('advertiser.profile.labels.fineDebtDaysTooltip')"
                                    tooltip-multilined
                                    tooltip-position="is-top">
                                    {{ $t('advertiser.profile.labels.fineDebtDays') }}
                                </LabelWithAnnotation>
                            </span>
                        </template>
                        <FInput
                            v-if="profileLoaded"
                            v-model.number="_user.fineDebtDays"
                            :disabled="isEdit || !_user.isFineDebt"
                            :placeholder="$t('advertiser.profile.labels.fineDebtDays')"
                            :required="_user.isFineDebt"
                            min="1"
                            type="number">
                        </FInput>
                        <el-skeleton
                            v-else
                            animated>
                            <template #template>
                                <el-skeleton-item style="width: 100%; height: 46px;"></el-skeleton-item>
                            </template>
                        </el-skeleton>
                    </FField>
                </div>
                <div class="column">
                    <FField :label="$t('advertiser.profile.labels.fineDebtPercent')">
                        <FInput
                            v-if="profileLoaded"
                            v-model.number="_user.fineDebtPercent"
                            :disabled="isEdit || !_user.isFineDebt"
                            :placeholder="$t('advertiser.profile.labels.fineDebtPercent')"
                            :required="_user.isFineDebt"
                            step="0.01"
                            min="0.01"
                            max="100"
                            type="number">
                        </FInput>
                        <el-skeleton
                            v-else
                            animated>
                            <template #template>
                                <el-skeleton-item style="width: 100%; height: 46px;"></el-skeleton-item>
                            </template>
                        </el-skeleton>
                    </FField>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
  import LabelWithAnnotation from "@/components/Common/LabelWithAnnotation";
  import CustomCheckbox from "@/components/Common/CustomCheckbox";
  import CustomInput from "@/components/Common/CustomInput";
  import { mapState } from "vuex";
  import FField from "@/components/Common/FField";
  import FInput from "@/components/Common/FInput";
  import RequiredField from "@/components/Common/RequiredField";
  import { EMAIL_VALIDATOR } from "@/validators";

  export default {
    name: "AdvertiserProfile",

    components: {
      LabelWithAnnotation,
      CustomCheckbox,
      RequiredField,
      FField,
      FInput,
      CustomInput
    },

    props: {
      isEdit: {
        type: Boolean,
        default: false
      },

      isSuperEdit: {
        type: Boolean,
        default: false
      },

      user: {
        type: Object,
        default: null
      }
    },

    computed: {
      ...mapState({
        timeZones: ({ dictionaries }) => dictionaries.timeZones,
        role: state => state.auth.role
      }),
  
      profileLoaded () {
        return !!this.user;
      },
  
      _user: {
        get () {
          return this.user;
        },
    
        set (value) {
          this.$emit("update:user", value);
        }
      },

      _telegram: {
        get () {
          return this.user.telegram?.replace(/@/g, "");
        },

        set (value) {
          this.$emit("update:user", { ...this.user, telegram: "@" + value });
        }
      },

      emailValidator () {
        return EMAIL_VALIDATOR;
      }
    }
  };
</script>

<style lang="scss" scoped>
    ::v-deep {
        .default-white-space span {
            white-space: normal;
        }
    }
</style>
