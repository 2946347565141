import { CountriesListService } from "@/services/admin/lists/CountriesListService";
import { GET_COUNTRIES, GET_COUNTRIES_LIST } from "@/store/action-constants";
import { EMPTY_COUNTRIES_LIST, SET_COUNTRIES_LIST } from "@/store/mutation-constants";
import { RootState } from "@/store/root-state";
import { PartialCountriesListState } from "@/store/types/admin/lists/CountriesListState.d";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

const initialState = (): PartialCountriesListState => {
  return {
    countries: {
      items: [],
      count: null
    },
    pagination: {
      page: 1,
      perPage: 50
    }
  };
};

const state: () => PartialCountriesListState = initialState;

const getters: GetterTree<PartialCountriesListState, RootState> = {
  [GET_COUNTRIES]: state => {
    return {
      count: state.countries?.count,
      items: state.countries?.items
    };
  }
};

const mutations: MutationTree<PartialCountriesListState> = {
  [SET_COUNTRIES_LIST] (state, payload) {
    state.countries = { ...state.countries, ...payload };
  },

  [EMPTY_COUNTRIES_LIST] (state) {
    Object.assign(state, initialState());
  }
};

const actions: ActionTree<PartialCountriesListState, RootState> = {
  async [GET_COUNTRIES_LIST] ({ state, commit }) {
    const limit = state.pagination.perPage;
    const offset = (state.pagination.page - 1) * limit;

    const { data } = await CountriesListService.getCountriesList(
      limit,
      offset
    );


    const countriesList = {
      items: data,
      // @ts-ignore
      count: data.length
    };

    /*const { data: { countries } } =
                await CountriesListService.getCountriesList(
                    limit,
                    offset
                );

        const countriesList: PaginationOutput<Array<CountriesListItem>> = countries;

        if (countries.count && countries.count > limit) {
            let page = 1;
            let responseCount = 0;
            do {
                const offset = page * limit;
                const { data: { countries: { items } } } =
                  await CountriesListService.getCountriesList(
                    limit,
                    offset
                  );

                if (countriesList?.items && items) {
                    countriesList.items = [...countriesList.items, ...items];
                    responseCount = items.length;
                }
                page++;
            } while (responseCount === limit);
        }*/

    commit(SET_COUNTRIES_LIST, countriesList);
  }
};

export const countriesList: Module<PartialCountriesListState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
