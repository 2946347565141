import { RootState } from "@core/store/root-state";
import { ActionTree, Module } from "vuex";
import { UPDATE_FILTERS } from "@core/store/mutation-constants";
import { SYNC_STATISTIC_FILTERS } from "@core/store/action-constants";
import { subAccountsFilters } from "@core/store/modules/common/statistic/common/subAccountsFilters";
import { prepareBaseFilters } from "@core/store/modules/common/helpers/prepareFilters";
import { currencyList } from "@core/store/modules/common/lists/currencyList";

const actions: ActionTree<RootState, RootState> = {
  [SYNC_STATISTIC_FILTERS] ({ dispatch }, filters) {
    const baseFilters = prepareBaseFilters(filters);
  
    dispatch(`subAccountsFilters/${ UPDATE_FILTERS }`, baseFilters);
  }
};

export const detailingFilters: Module<RootState, RootState> = {
  namespaced: true,
  modules: {
    subAccountsFilters,
    currencyList
  },
  actions
};
