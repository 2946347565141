import { OfferEditWebmastersServices } from "@core/services/webmaster/offer/OfferRatesWebmastersService";
import {
  ADD_OFFER_WEBMASTER_INDIVIDUAL_RATE,
  BEFORE_GET_OFFER_WEBMASTERS, DELETE_OFFER_WEBMASTERS,
  GET_OFFER_ID,
  GET_OFFER_WEBMASTERS,
  PROXY_OFFER_WEBMASTER_INDIVIDUAL_RATE,
  SET_EMPTY,
  UPDATE_OFFER_WEBMASTER_INDIVIDUAL_RATE
} from "@core/store/action-constants";
import { offerEditWebmastersModal } from "@core/store/modules/webmaster/offer/offerRates/offerRatesWebmastersModal";
import {
  SET_OFFER_WEBMASTERS,
  UPDATE_LOADING,
  UPDATE_OFFER_FILTERS,
  UPDATE_OFFER_WEBMASTER_RATE,
  UPDATE_OFFER_WEBMASTERS,
  UPDATE_OFFER_WEBMASTERS_PAGINATION
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import {
  IndividualRate,
  OfferWebmaster
} from "@core/store/types/webmaster/offer/OfferRatesWebmastersState.d";
import { PaginationOutput } from "@core/store/types/PaginationOutput";
import Vue from "vue";
import { ActionTree, Module, MutationTree } from "vuex";
import { OfferRatesWebmastersState } from "@core/store/types/webmaster/offer/OfferRatesWebmastersState.d";

const initialState = (): OfferRatesWebmastersState => {
  return {
    webmasters: {},
    filters: {
      webmasterId: null
    },
    pagination: {
      page: 1,
      perPage: 25
    }
  };
};

const state: () => OfferRatesWebmastersState = initialState;

const mutations: MutationTree<OfferRatesWebmastersState> = {
  SET_EMPTY: state => Object.assign(state, initialState()),
  
  [SET_OFFER_WEBMASTERS] (state, webmasters: PaginationOutput<OfferWebmaster[]>) {
    state.webmasters = { ...webmasters };
  },
  
  [UPDATE_OFFER_WEBMASTERS] (state, webmasters: PaginationOutput<OfferWebmaster[]>) {
    if (state.webmasters) {
      
      state.webmasters = {
        count: webmasters.count,
        items: state.webmasters?.items?.concat(webmasters.items as OfferWebmaster[])
      };
    }
  },
  
  [UPDATE_OFFER_WEBMASTER_RATE] (state, {
    newRate,
    index
  }) {
    if (state.webmasters?.items) {
      Vue.set(state.webmasters.items[index], "individualRate", newRate);
    }
  },
  
  [UPDATE_LOADING] (state, {
    index,
    params,
    value
  }) {
    if (state.webmasters?.items) {
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          const prop = `is${ key.charAt(0).toUpperCase() + key.slice(1) }Loading`;
          Vue.set(state.webmasters.items[index], prop, value);
        }
      }
      
    }
  },
  
  [UPDATE_OFFER_WEBMASTERS_PAGINATION] (state, pagination) {
    state.pagination = { ...state.pagination, ...pagination };
  },
  
  [UPDATE_OFFER_FILTERS] (state, payload) {
    state.filters = { ...state.filters, ...payload };
  }
};

const actions: ActionTree<OfferRatesWebmastersState, RootState> = {
  SET_EMPTY ({ commit }) {
    commit(SET_EMPTY);
  },
  
  async [GET_OFFER_WEBMASTERS] ({
    commit,
    rootGetters,
    state
  }) {
    const offerId = rootGetters[`webmaster/offer/${ GET_OFFER_ID }`];
    const isAgent = rootGetters.isAgent;

    try {
      const { pagination } = state;
      if (offerId && isAgent) {
        const limit = pagination.perPage;
        const offset = (pagination.page - 1) * limit;
        const { data: { offer: { webmasters } } } = await OfferEditWebmastersServices.getOfferWebmasters(
          {},
          offerId,
          limit,
          offset
        );
        
        webmasters.items = webmasters.items.map((item: OfferWebmaster) => {
          if (item.individualRate) {
            const isLoading: { [key: string]: unknown } = {};
            for (let key in item.individualRate) {
              if (item.individualRate.hasOwnProperty(key) && key !== "id") {
                if (key === "moneyReward") {
                  key = "rate";
                }
                const prop = key.charAt(0).toUpperCase() + key.slice(1);
                isLoading[`is${ prop }Loading`] = false;
              }
            }
            return {
              ...item,
              ...isLoading
            };
          } else {
            return item;
          }
        });
        
        if (offset === 0) {
          commit(SET_OFFER_WEBMASTERS, webmasters);
        } else {
          commit(UPDATE_OFFER_WEBMASTERS, webmasters);
        }
        
        return webmasters;
      }
    } catch (e) {
      throw e;
    }
  },
  
  [BEFORE_GET_OFFER_WEBMASTERS] ({
    commit,
    dispatch,
    state
  }) {
    const {
      page,
      perPage
    } = state.pagination;
    
    commit(UPDATE_OFFER_WEBMASTERS_PAGINATION, {
      page: 1,
      perPage: perPage * page
    });
    
    dispatch(GET_OFFER_WEBMASTERS);
    
    commit(UPDATE_OFFER_WEBMASTERS_PAGINATION, {
      page,
      perPage
    });
  },
  
  async [ADD_OFFER_WEBMASTER_INDIVIDUAL_RATE] ({
    state,
    rootGetters,
    commit
  }, {
    index,
    params
  }) {
    const offerId = rootGetters[`webmaster/offer/${ GET_OFFER_ID }`];
    
    commit(UPDATE_LOADING, {
      index,
      params,
      value: true
    });
    
    try {
      if (state.webmasters?.items && offerId) {
        const {
          webmaster: { id }
        } = state.webmasters.items[index];
        const prop = Object.keys(params)[0];
        const newRate: IndividualRate =
          Object.assign(
            {
              rate: null,
              id
            },
            { [prop]: isNaN(params[prop]) ? params[prop] : +params[prop] }
          );
        const { data: { addSubWebmasterParameters } } =
          await OfferEditWebmastersServices.addSubWebmasterParameters(offerId, [ newRate ]);
        
        const { individualRate } = addSubWebmasterParameters[0];
        
        commit(UPDATE_OFFER_WEBMASTER_RATE, {
          newRate: individualRate,
          index
        });
      }
    } catch (e) {
      throw e;
    } finally {
      commit(UPDATE_LOADING, {
        index,
        params,
        value: false
      });
    }
  },
  
  async [UPDATE_OFFER_WEBMASTER_INDIVIDUAL_RATE] ({
    state,
    rootGetters,
    commit
  }, {
    index,
    params
  }) {
    const offerId = rootGetters[`webmaster/offer/${ GET_OFFER_ID }`];
    
    commit(UPDATE_LOADING, {
      index,
      params,
      value: true
    });
    
    try {
      if (state.webmasters?.items) {
        const {
          webmaster,
          individualRate
        } = state.webmasters.items[index];
        const {
          rate,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          id
        } = individualRate || {};
        const prop = Object.keys(params)[0];
        const propValue = !params[prop] || isNaN(params[prop]) ? params[prop] : +params[prop];
        
        const newRate: IndividualRate =
          Object.assign(
            {
              rate,
              id
            },
            {
              [prop]: propValue === 0 ? null : propValue
            }
          );
        const {
          data: {
            editSubWebmasterParameter: {
              individualRate: rates
            }
          }
        } = await OfferEditWebmastersServices.editSubWebmasterParameter(offerId, newRate, webmaster);
        
        commit(UPDATE_OFFER_WEBMASTER_RATE, {
          newRate: rates && Object.keys(rates)?.length > 0 ? rates : null,
          index
        });
      }
    } catch (e) {
      throw e;
    } finally {
      commit(UPDATE_LOADING, {
        index,
        params,
        value: false
      });
    }
  },
  
  async [PROXY_OFFER_WEBMASTER_INDIVIDUAL_RATE] ({
    state,
    dispatch
  }, {
    index,
    params
  }) {
    const prop = Object.keys(params)[0];
    
    params[prop] = params[prop] === "" ? null : params[prop];
    
    if (state.webmasters?.items) {
      const {
        individualRate
      } = state.webmasters.items[index];
      if (!individualRate) {
        await dispatch(ADD_OFFER_WEBMASTER_INDIVIDUAL_RATE, {
          index,
          params
        });
      } else {
        await dispatch(UPDATE_OFFER_WEBMASTER_INDIVIDUAL_RATE, {
          index,
          params
        });
      }
    }
  },
  
  async [DELETE_OFFER_WEBMASTERS] ({
    rootGetters
  }, payload) {
    const offerId = rootGetters[`webmaster/offer/${ GET_OFFER_ID }`];
    
    try {
      if (offerId) {
        const { data: { offerExcludeMultipleWebmasters } } =
          await OfferEditWebmastersServices.excludeSubWebmastersToOffer(offerId, payload);
        
        return offerExcludeMultipleWebmasters;
      }
    } catch (e) {
      throw e;
    }
  },
  
  [UPDATE_OFFER_WEBMASTERS_PAGINATION] ({ commit }, pagination) {
    commit(UPDATE_OFFER_WEBMASTERS_PAGINATION, pagination);
  },
  
  [UPDATE_OFFER_FILTERS] ({ commit }, filter) {
    commit(UPDATE_OFFER_FILTERS, filter);
  }
};

export const offerRatesWebmasters: Module<OfferRatesWebmastersState, RootState> = {
  namespaced: true,
  modules: {
    add: offerEditWebmastersModal
  },
  state,
  mutations,
  actions
};
