import CRM from "@/router/admin/CRM";
import domains from "@/router/admin/domains";
import efficiency from "@/router/admin/efficiency";
import finances from "@/router/admin/finance";
import integrations from "@/router/admin/integrations";
import offers from "@/router/admin/offers";
// import payments from "@/router/admin/payments";
import roles from "@/router/admin/roles";
import statistic from "@/router/admin/statistic";
import users from "@/router/admin/users";
import { RouteConfig } from "vue-router/types/router";

import Full from "@/views/Full.vue";

export const admin: RouteConfig = {
  path: "/admin",
  name: "admin",
  component: Full,
  children: [
    ...integrations,
    ...finances,
    ...users,
    ...offers,
    // ...payments,
    ...statistic,
    ...roles,
    ...domains,
    ...efficiency,
    ...CRM
  ]
};
