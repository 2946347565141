import { DetailingService } from "@core/services/webmaster/statistic/detailing/DetailingService";
import { detailingStatusesList } from "@core/store/modules/webmaster/statistic/modules/lists/detailingStatusesList";
import {
  SET_DETAIL,
  SET_STATS_STATUS,
  SET_UTM_TAGS,
  UPDATE_FILTERS,
  UPDATE_PAGINATION,
  CLEAR_FILTERS
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { filterPreparation } from "@core/helpers/filterPreparation";
import detailingFieldsList from "@core/store/modules/webmaster/statistic/modules/datasets/detailingFieldsList.json";
import { detailingFilters } from "@core/store/modules/webmaster/statistic/modules/detailing/detailingFilters";
import {
  DetailingState,
  LeadsFilters,
  LeadsResponse,
  LeadStatus
} from "@core/store/types/webmaster/statistic/modules/detailng";
import moment from "moment";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { GET_UTM_TAGS } from "@core/store/action-constants";
import { StatisticService } from "@core/logic/webmaster/statistic/statistic";

const modifyLeadsResponse = (leads: LeadsResponse["leads"]): DetailingState["details"] => {
  const items = leads.items.map((item) => {
    const {
      callsCount,
      cancelReason,
      spamReason,
      id,
      offer,
      flow,
      status,
      createdAt,
      fields,
      comment,
      reward,
      webmaster
    } = item;
    const {
      clickId,
      phone,
      domain,
      ...labels
    } = fields;
    const data = {
      callsCount,
      cancelReason,
      spamReason,
      id,
      offer,
      clickId,
      phone,
      flow,
      createdAt,
      domain,
      comment,
      reward,
      webmaster,
      status: status.map(
        (item: string) => item
          .replace(/[\s]+/gm, "_")
          .replace(/[\W\d]*/gm, "")
          .toUpperCase()
      ) as LeadStatus[]
    };

    return {
      data,
      labels
    };
  });
  const { count } = leads;

  return {
    items,
    count
  };
};

const initialState = (): DetailingState => {
  return {
    details: { items: [], count: 0 },
    status: [],
    filters: {
      dateStart: moment().startOf("day").subtract(7, "day").toDate(),
      dateEnd: moment().endOf("day").toDate(),
      currency: null
    },
    utm: null,
    pagination: {
      page: 1,
      perPage: 50
    },
    selectedFields: detailingFieldsList
  };
};

const state: () => DetailingState = initialState;

const getters: GetterTree<DetailingState, RootState> = {
  offset: ({ pagination: { page, perPage } }) => (page - 1) * perPage
};

const mutations: MutationTree<DetailingState> = {
  SET_DETAIL: (state, details): void => {
    state.details = details;
  },
  
  [SET_STATS_STATUS]: (state, status): void => {
    state.status = status;
  },
  
  [UPDATE_FILTERS]: (state, filters): void => {
    if (filters.hasOwnProperty("countries") && Array.isArray(filters.countries) && !filters.countries.length) {
      filters.countries = null;
    }
    state.filters = { ...state.filters, ...filterPreparation(filters) };
  },
  
  [UPDATE_PAGINATION]: (state, pagination): void => {
    state.pagination = { ...state.pagination, ...pagination };
  },
  
  [SET_UTM_TAGS] (state, utm) {
    state.utm = utm;
  },
  
  [CLEAR_FILTERS]: (state): void => {
    const { dateStart, dateEnd, currency } = state.filters;
    state.filters = {
      ...initialState().filters,
      dateStart,
      dateEnd,
      currency
    };
  }
};

const actions: ActionTree<DetailingState, RootState> = {
  async GET_DETAIL ({ commit, state: { filters, pagination: { perPage } }, getters: { offset }, rootGetters }) {
    const modifedFilters: LeadsFilters = { ...filters } as LeadsFilters;
    const isAgent = rootGetters.isAgent;
    
    const { data: { leads } } = await DetailingService.getLeads(modifedFilters, perPage, offset, isAgent);
    const details = modifyLeadsResponse(leads);
    // @ts-ignore
    details.items = details?.items.map(item => {
      return {
        ...item,
        data: {
          ...item.data,
          commissions: item.data.reward.webmasterReward?.value - item.data.reward.subWebmasterReward?.value
        }
      };
    });
    
    commit(SET_DETAIL, details);
  },

  [UPDATE_FILTERS] ({ commit }, filter) {
    commit(UPDATE_FILTERS, filter);
    commit(UPDATE_PAGINATION, { page: 1 });
  },

  [UPDATE_PAGINATION] ({ commit }, pagination) {
    commit(UPDATE_PAGINATION, pagination);
  },
  
  async [GET_UTM_TAGS] ({ commit }) {
    const { data } = await StatisticService.getUTMTags();
      
    commit(SET_UTM_TAGS, data);
  },
  
  [CLEAR_FILTERS] ({ commit }) {
    commit(CLEAR_FILTERS);
    commit(UPDATE_PAGINATION, { page: 1 });
  }
};

export const detailing: Module<DetailingState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    detailingStatusesList,
    detailingFilters
  }
};

