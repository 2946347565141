//TODO: получить токен для капчи
<template>
    <Background>
        <div
            class="form-wrapper"
            @submit.prevent.stop="captchaExecute">
            <form class="form login">
                <div>
                    <img
                        alt="FAIRPAY"
                        class="form-logo"
                        src="@/assets/fairpay-splash.png">
                    
                    <!--                    <h3 class="title is-4 pl-2">-->
                    <!--                        Вход-->
                    <!--                    </h3>-->
                    <FAlert
                        v-show="isLoginByPasswordError || isLoginByTelegramError"
                        class="alert">
                        <p class="alert__title">
                            <i18n
                                v-if="isLoginByPasswordError"
                                path="common.auth.signIn.notification.loginPasswordError.content">
                                <template #login>
                                    <span
                                        v-t="'common.auth.signIn.notification.loginPasswordError.particles.login'"
                                        class="font-semibold"></span>
                                </template>
                                <template #password>
                                    <span
                                        v-t="'common.auth.signIn.notification.loginPasswordError.particles.password'"
                                        class="font-semibold"></span>
                                </template>
                            </i18n>
                            <i18n
                                v-if="isLoginByTelegramError"
                                path="common.auth.signIn.notification.loginTelegramError.content">
                                <template #login>
                                    <span
                                        v-t="'common.auth.signIn.notification.loginTelegramError.particles.login'"
                                        class="font-semibold"></span>
                                </template>
                            </i18n>
                        </p>
                    </FAlert>
                    
                    <FAlert
                        v-show="isTelegramError"
                        class="alert">
                        <i18n path="common.auth.signIn.notification.telegramError.content">
                            <template #username>
                                <span
                                    v-t="'common.auth.signIn.notification.telegramError.particles.username'"
                                    class="font-semibold"></span>
                            </template>
                        </i18n>
                    </FAlert>

                    <FAlert
                        v-show="isUserBlocked"
                        class="alert">
                        <i18n
                            path="common.auth.signIn.notification.banMessage"
                            tag="p">
                            <template #email>
                                <a
                                    href="mailto:support@fairpay.pro"
                                    target="_blank">support@fairpay.pro</a>
                            </template>
                        </i18n>
                    </FAlert>
                
                    <div class="flex flex-col">
                        <!--                        <b-field class="column is-marginless is-12">-->
                        <FInput
                            v-model="login_"
                            class="mb-3"
                            :placeholder="$t(`common.auth.signIn.login`)"
                            required>
                        </FInput>
                        <!--                        </b-field>-->
                    
                        <!--                        <b-field class="column is-marginless is-12">-->
                        <FInput
                            v-model="password"
                            :clearable="false"
                            class="mb-3"
                            :placeholder="$t(`common.auth.signIn.password`)"
                            required
                            show-password
                            type="password">
                        </FInput>
                        <!--                        </b-field>-->
                    </div>

                    <Captcha
                        ref="captcha"
                        @onVerify="submit('password', null, $event)">
                    </Captcha>
                  
                    <div class="flex flex-col">
                        <!--                        <div class="column is-marginless is-12">-->
                        <el-button
                            :loading="isLoading"
                            type="success"
                            native-type="submit"
                            rounded>
                            {{ $t(`common.auth.signIn.signIn`) }}
                        </el-button>
                        <!--                        </div>-->
                        <div
                            v-if="telegramLogin">
                            <template v-if="isValidDomain">
                                <VueTelegramLogin
                                    class="flex justify-center mt-3 mb-1"
                                    :telegram-login="telegramLogin"
                                    mode="callback"
                                    size="medium"
                                    @callback="submit('telegram', $event)">
                                </VueTelegramLogin>
                            </template>
                        </div>
                        <div
                            v-else
                            class="mt-3 mb-1">
                            <el-button
                                :loading="isLoading"
                                type="success"
                                class="w-full"
                                rounded
                                @click="submit('telegram', null)">
                                [TEST] Telegram login
                            </el-button>
                        </div>
                        <!--                    </div>-->

                        <router-link
                            class="form-forgot"
                            :to="{ name: 'auth:reset' }"
                            @click.native.prevent="toReset">
                            {{ $t(`common.auth.signIn.reset`) }}
                        </router-link>
                        
                        <router-link
                            :to="{ name: 'auth:signUp' }"
                            class="form-subbutton">
                            {{ $t(`common.auth.signIn.signUp`) }}
                        </router-link>
                    </div>
                </div>
            </form>
        
            <!--            <ModalBox :is-active.sync="isResetModal">-->
            <!--                <ResetPassword @closeResetModal="isResetModal = false"></ResetPassword>-->
            <!--            </ModalBox>-->
        </div>
    </Background>
</template>

<script>
  import Background from "@/components/Common/Auth/Background";
  // import ResetPassword from "@/components/Common/Auth/ResetPassword/ResetPassword";
  // import ModalBox from "@/components/Common/ModalBox";
  import { USER_LOGIN_BY_PASSWORD, USER_LOGIN_BY_TELEGRAM } from "@core/store/action-constants";
  import { vueTelegramLogin as VueTelegramLogin } from "vue-telegram-login";
  import { mapActions, mapGetters } from "vuex";
  import Captcha from "@/components/Common/Auth/Captcha";
  import FInput from "@/components/Common/FInput";
  import FAlert from "@/components/Common/FAlert";

  export default {
    name: "SignIn",
    components: {
      FAlert,
      // ResetPassword,
      // ModalBox,
      FInput,
      Background,
      VueTelegramLogin,
      Captcha
    },
    props: {
      login: {
        type: String,
        default: null
      }
    },
    data () {
      return {
        loginRaw: null,
        password: null,
        isLoginByPasswordError: false,
        isLoginByTelegramError: false,
        isTelegramError: false,
        isLoading: false,
        isResetModal: false,
        banMessageData: {
          tg: "@Natalya_Di",
          email: "info@fairpay.com",
          tgHref: "https://t.me/Natalya_Di",
          emailHref: "mailto:info@fairpay.com"
        }
      };
    },

    computed: {
      ...mapGetters(["isUserBlocked"]),
      
      login_: {
        get () {
          return this.login ? this.login : this.loginRaw;
        },
        
        set (value) {
          this.loginRaw = value;
        }
      },
      
      telegramLogin () {
        return process.env.VUE_APP_TELEGRAM_BOT;
      },
      
      isValidDomain () {
        return window.location.origin.toLowerCase() === process.env.VUE_APP_CPA_URL.toLowerCase();
      }
    },
    methods: {
      ...mapActions(["USER_LOGOUT"]),
      
      toReset (event) {
        // Не удалять
        event.preventDefault();
        this.isResetModal = true;
      },

      async submit (type, user, captcha) {
        let action;

        if (type === "telegram") {
          this.isTelegramError = false;
          
          if (process.env.NODE_ENV === "development") {
            const fakeUserData = {
              "id": 123456,
              "first_name": "FN",
              "last_name": "LN",
              "username": "telegram",
              "photo_url": "http://test.test",
              "auth_date": Math.floor(+new Date / 1000)
            };
            
            const generateTelegramResponse = (await import("@/utils/generateTelegramResponse")).default;
            
            user = user ?? await generateTelegramResponse(fakeUserData);
          }
          
          if(user.username == null) {
            this.isTelegramError = true;
          } else {
            action = this.loginByTelegram({
              captcha: captcha,
              ...user });
          }
        } else {
          action = this.loginByPassword({
            login: this.login_,
            password: this.password,
            captcha: captcha
          });
        }
        
        this.USER_LOGOUT();
        
        this.isLoginByPasswordError = false;
        this.isLoginByTelegramError = false;
        this.isLoading = true;
        if (action) {
          action.then(() => {
            if (!this.isUserBlocked) {
              this.$router.push({ name: "dashboard" }).catch(_ => {});
            }
          }).catch(() => {
            if (type === "telegram") {
              this.isLoginByTelegramError = true;
            } else {
              this.isLoginByPasswordError = true;
            }
          }).finally(() => {
            this.isLoading = false;
          });
        }
      },
      
      loginByPassword (user, captcha) {
        return this.$store.dispatch(USER_LOGIN_BY_PASSWORD, user, captcha);
      },
      
      loginByTelegram (user, captcha) {
        return this.$store.dispatch(USER_LOGIN_BY_TELEGRAM, user, captcha);
      },

      captchaExecute () {
        this.$refs.captcha.$refs.hcaptcha.execute();
      }
    }
  };
</script>

<style lang="scss" scoped>
    .alert {
        @apply mb-3;

        ::v-deep {
            .el-alert__title {
                @apply text-sm;
            }
        }
    }

    .alert__title {
        @apply text-sm;
    }

    .form {
        .notification {
            left: 50%;
            width: 200%;
            padding: 1em 1.10295em;
            font-size: 0.85em;
            max-width: 500px;
            transform: translateX(-50%);
        }

        &-wrapper {
            z-index: 10;
        }
    }
    
    @media screen and (min-width: 1025px) {
        .form {
            top: 50%;
            right: 22vw;
            position: absolute;
            transform: translateY(-50%);
        }
    }
</style>
