import { AxiosPromise } from "axios";
import Service from "@core/services/common/Service";

import landingFlows from "@core/services/webmaster/flows/graphql/landingFlows.graphql";
import { FlowsState } from "@core/store/types/webmaster/flows";

export class FlowsService extends Service {
  public static async getFlows (
    limit: number,
    offset: number,
    filters: FlowsState["filters"]
  ): Promise<AxiosPromise> {
    try {
      return await this.api.get("", {
        params: {
          query: landingFlows,
          variables: {
            limit,
            offset,
            filters
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }

}
