import { GET_UPLOADS_FIELDS } from "@/store/action-constants";
import { UPDATE_UPLOADS_FIELDS_LIST } from "@/store/mutation-constants";
import { RootState } from "@/store/root-state";
import { UploadsFieldsListState } from "@/store/types/admin/uploads/lists/UploadsFieldsListState";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

const initialState = (): UploadsFieldsListState => {
  return {
    data: {}
  };
};

const state: () => UploadsFieldsListState = initialState;

const getters: GetterTree<UploadsFieldsListState, RootState> = {};

const mutations: MutationTree<UploadsFieldsListState> = {
  [UPDATE_UPLOADS_FIELDS_LIST] (state, uploadsFields: UploadsFieldsListState["data"]) {
    state.data = uploadsFields;
  }
};

const actions: ActionTree<UploadsFieldsListState, RootState> = {
  [GET_UPLOADS_FIELDS] ({ commit }) {
    const items = [
      {
        label: "admin.statistics.statistics.table.labels.webmaster",
        value: "webmaster.login"
      },
      {
        label: "admin.statistics.statistics.table.labels.leadId",
        value: "id"
      },
      {
        label: "admin.statistics.statistics.table.labels.status",
        value: "status"
      },
      {
        label: "admin.statistics.statistics.table.labels.domain",
        value: "fields.domain"
      },
      {
        label: "admin.statistics.statistics.table.labels.clickId",
        value: "fields.clickId"
      },
      {
        label: "admin.statistics.statistics.table.labels.phone",
        value: "fields.phone"
      },
      {
        label: "admin.statistics.statistics.table.labels.utmcampaign",
        value: "fields.utm_campaign"
      },
      {
        label: "admin.statistics.statistics.table.labels.utmcontent",
        value: "fields.utm_content"
      },
      {
        label: "admin.statistics.statistics.table.labels.utmmedium",
        value: "fields.utm_medium"
      },
      {
        label: "admin.statistics.statistics.table.labels.utmsource",
        value: "fields.utm_source"
      },
      {
        label: "admin.statistics.statistics.table.labels.utmterm",
        value: "fields.utm_term"
      },
      {
        label: "admin.statistics.statistics.table.labels.remoteId",
        value: "remoteId"
      },
      {
        label: "admin.statistics.statistics.table.labels.createdAt",
        value: "createdAt"
      },
      {
        label: "admin.statistics.statistics.table.labels.offerName",
        value: "offer.detail.name"
      },
      {
        label: "admin.statistics.statistics.table.labels.advertiserWriteOff",
        value: "reward.advertiserWriteOff"
      },
      {
        label: "admin.statistics.statistics.table.labels.payouts",
        value: "reward.webmasterReward"
      },
      {
        label: "admin.statistics.statistics.table.labels.calls",
        value: "calls"
      },
      {
        label: "admin.statistics.statistics.table.labels.callsCount",
        value: "callsCount"
      },
      {
        label: "admin.statistics.statistics.table.labels.comment",
        value: "comment"
      },
      {
        label: "admin.statistics.statistics.table.labels.cancelReason",
        value: "cancelReason"
      }
    ];
    commit(UPDATE_UPLOADS_FIELDS_LIST, {
      items,
      count: items.length
    });
  }
};

export const uploadsFieldsList: Module<UploadsFieldsListState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
