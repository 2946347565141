import i18n from "../../../core/src/plugins/i18n";
import { SET_LOCALE } from "@/store/mutation-constants";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@/store/root-state";
import { localize } from "vee-validate";
import { UPDATE_LOCALE } from "@/store/action-constants";
import { LocaleState } from "@/store/types/locale";
// @ts-ignore
import elementUiEn from "element-ui/lib/locale/lang/en";
// @ts-ignore
import elementUiRu from "element-ui/lib/locale/lang/ru-RU";
// @ts-ignore
import elementUiLocale from "element-ui/lib/locale";

const state: LocaleState = {
  locale: null,
  settingsLeng: null
};

const getters: GetterTree<LocaleState, RootState> = {
  locale: state => state.locale
};

const mutations: MutationTree<LocaleState> = {
  [SET_LOCALE] (state, value): void {
    state.locale = value;
  }
};

const actions: ActionTree<LocaleState, RootState> = {
  [UPDATE_LOCALE] ({ commit }, payload: string): void {
    i18n.locale = payload;
    localize(payload);
    window.localStorage.setItem("locale", payload);

    if (payload === "ru" || payload === "dev") {
      elementUiLocale.use(elementUiRu);
    } else if (payload === "en") {
      elementUiLocale.use(elementUiEn);
    }

    commit(SET_LOCALE, payload);
  }
};

export const locale: Module<LocaleState, RootState> = {
  state,
  getters,
  actions,
  mutations
};
