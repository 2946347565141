import { RolesService } from "@core/logic/admin/roles/roles";
import { ADD_ROLE, DELETE_ROLE, EDIT_ADMIN_ROLE, EDIT_ROLE, GET_PERMISSIONS, GET_ROLES } from "@core/store/action-constants";
import { SET_PERMISSIONS, SET_ROLES } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { ActionTree, Module, MutationTree } from "vuex";
import { PaginationOutput } from "@core/store/types/PaginationOutput";

interface Permission {
  alias: string;
}

interface Role {
  "id": string;
  "name": string;
  "permissions": [] | null;
}

export interface RolesState {
  permissions: PaginationOutput<Permission[]> | null;
  roles: PaginationOutput<Role[]> | null;
}


const state: RolesState = {
  permissions: null,
  roles: null
};

const mutations: MutationTree<RolesState> = {
  [SET_PERMISSIONS] (state, permissions) {
    state.permissions = permissions;
  },

  [SET_ROLES] (state, roles) {
    state.roles = roles;
  }
};

const actions: ActionTree<RolesState, RootState> = {
  async [GET_PERMISSIONS] ({ commit }) {
    const { data } = await RolesService.getPermissions();
    commit(SET_PERMISSIONS, data);

    return data;
  },

  async [GET_ROLES] ({ commit }) {
    const { data } = await RolesService.getRoles();
    commit(SET_ROLES, data);

    return data;
  },

  async [EDIT_ADMIN_ROLE] (_, { admins }) {
    const { data } = await RolesService.addOrEditMultipleAdminsRoles(admins);

    return data;
  },

  async [ADD_ROLE] (_, params) {
    const { data } = await RolesService.addRole(params);
    return data;
  },

  async [EDIT_ROLE] (_, { roleId, params }) {
    const { data } = await RolesService.editRole(roleId, params);
    return data;
  },

  async [DELETE_ROLE] (_, roleId) {
    const { data } = await RolesService.deleteRole(roleId);
    return data;
  }
};

export const roles: Module<RolesState, RootState> = {
  state,
  actions,
  mutations
};
