<template>
    <div>
        <slot name="label">
            <div
                v-if="label"
                class="mb-1 ml-2 flex">
                <span
                    class="text-sm text-blue-text"
                    :class="customClass">
                    {{ label }}
                </span>
            </div>
        </slot>
        <slot>
        </slot>
    </div>
</template>

<script>
  
  export default {
    name: "FField",
    
    props: {
      label: {
        type: String,
        default: null
      },

      customClass: {
        type: String,
        default: null
      }
    },
    
    methods: {
      update (value) {
        this.$emit("input", value);
      }
    }
  };
</script>

<style lang="scss" scoped>
</style>