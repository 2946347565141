import {
  PartialTrafficAnalysisState,
  ResponseTraffic
} from "@core/store/types/admin/statistic/trafficAnalysis/trafficAnalysis";
import Service from "@core/services/common/Service";
import traffic from "@core/services/admin/statistic/trafficAnalysis/graphql/traffic.graphql";

export class TrafficAnalysisService extends Service {
  public static getTraffic (
    filters: PartialTrafficAnalysisState["filters"]
  ): Promise<ResponseTraffic> {
    try {
      return this.api.get("", {
        params: {
          query: traffic,
          variables: {
            filters
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
}
