import { AdministratorsService } from "@core/services/admin/users/administrators/AdministratorsService";
import { hasPermissions } from "@core/mixins/permissions";
import { UsersService } from "@core/services/admin/users/common/UsersService";
import {
  BLOCK_ADMINISTRATOR,
  GET_ADMINISTRATORS,
  RESEND_EMAIL_BY_ADMIN,
  UNBLOCK_ADMINISTRATOR
} from "@core/store/action-constants";
import {
  SET_ADMINISTRATORS,
  UPDATE_ADMINISTRATORS_FILTERS,
  UPDATE_ADMINISTRATORS_PAGINATION,
  UPDATE_ADMINISTRATORS_SORTS
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { PaginationAdministratorsState } from "@core/store/types/admin/users/administrators/AdministratorsState";
import { ActionTree, Module, MutationTree } from "vuex";
import { edit } from "./modules/edit";

const initialState = (): PaginationAdministratorsState => {
  return {
    administrators: null,
    filters: {
      isBlocked: false
    },
    pagination: {
      page: 1,
      perPage: 25
    },
    sort: null,
    order: null
  };
};

const state: () => PaginationAdministratorsState = initialState;

const mutations: MutationTree<PaginationAdministratorsState> = {
  [SET_ADMINISTRATORS]: (state, administrators: PaginationAdministratorsState["administrators"]): void => {
    state.administrators = administrators;
  },

  [UPDATE_ADMINISTRATORS_FILTERS] (state, filter: PaginationAdministratorsState["filters"]): void {
    state.filters = { ...state.filters, ...filter };
  },

  [UPDATE_ADMINISTRATORS_PAGINATION] (state, pagination): void {
    state.pagination = { ...state.pagination, ...pagination };
  },

  [UPDATE_ADMINISTRATORS_SORTS] (state, { sort, order }): void {
    state.sort = sort;
    state.order = order.toUpperCase();
  }
};

const actions: ActionTree<PaginationAdministratorsState, RootState> = {
  async [GET_ADMINISTRATORS] ({ commit, state, rootGetters }) {
    const { pagination: { perPage: limit, page } } = state;
    const offset = (page - 1) * limit;
    const hasPermissionsAdvertiser = hasPermissions(["ADMIN.ADD_OR_EDIT_PERMISSION"], rootGetters.permissions);
    
    let {
      loginOrIntId,
      email,
      skype,
      telegram,
      ...filters
    } = state.filters;
    
    loginOrIntId = loginOrIntId ? loginOrIntId : null;
    email = email ? email : null;
    skype = skype ? skype : null;
    telegram = telegram ? telegram : null;
    
    try {
      const { data: { admins } } = await AdministratorsService.getAdministrators(
        {
          loginOrIntId,
          email,
          skype,
          telegram,
          ...filters
        },
        limit,
        offset,
        state.sort,
        state.order,
        hasPermissionsAdvertiser
      );

      commit(SET_ADMINISTRATORS, admins);
  
    } catch (error) {
      throw error;
    }
  },

  [UPDATE_ADMINISTRATORS_FILTERS] ({ commit }, filter) {
    commit(UPDATE_ADMINISTRATORS_FILTERS, filter);
    commit(UPDATE_ADMINISTRATORS_PAGINATION, { page: 1 });
  },

  [UPDATE_ADMINISTRATORS_PAGINATION] ({ commit }, pagination) {
    commit(UPDATE_ADMINISTRATORS_PAGINATION, pagination);
  },
  
  async [BLOCK_ADMINISTRATOR] (_: unknown, administratorId: string) {
    try {
      await AdministratorsService.blockAdministrator(administratorId);
    } catch (error) {
      throw error;
    }
  },
  
  async [UNBLOCK_ADMINISTRATOR] (_: unknown, administratorId: string) {
    try {
      await AdministratorsService.unBlockAdministrator(administratorId);
    } catch (error) {
      throw error;
    }
  },

  async [RESEND_EMAIL_BY_ADMIN] (_: unknown, userId: string) {
    try {
      await UsersService.resendEmailByAdmin(userId);
    } catch (error) {
      throw error;
    }
  }
};

export const administrators: Module<PaginationAdministratorsState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    edit
  }
};
