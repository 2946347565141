import { EfficiencyPlansHistoryService } from "@core/services/admin/efficiency/EfficiencyPlansHistoryService";
import { GET_PLANS_HISTORY, REMOVE_PLAN } from "@core/store/action-constants";
import {
  SET_EMPTY,
  SET_EMPTY_DATA,
  SET_MODAL_ACTIVE,
  SET_PLANS_HISTORY_MANAGER,
  UPDATE_FILTERS,
  UPDATE_PAGINATION,
  UPDATE_PLANS_HISTORY
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { PlansItem } from "@core/store/types/admin/efficiency/efficiencyPlans";
import { PlansHistoryState } from "@core/store/types/admin/efficiency/efficiencyPlansHistory";
import { ActionTree, Module, MutationTree } from "vuex";

const initialState = (): PlansHistoryState => {
  return {
    manager: null,
    isModalActive: false,
    filters: {},
    pagination: {
      page: 1,
      perPage: 5
    },
    data: null
  };
};

const state: () => PlansHistoryState = initialState;

const mutations: MutationTree<PlansHistoryState> = {
  [SET_EMPTY]: state => Object.assign(state, initialState()),

  [SET_EMPTY_DATA]: state => Object.assign(state, { data: initialState().data }),

  [SET_MODAL_ACTIVE] (state, value) {
    state.isModalActive = value;
  },

  [SET_PLANS_HISTORY_MANAGER] (state, manager) {
    state.manager = manager;
  },

  [UPDATE_FILTERS] (state, filters) {
    state.filters = { ...state.filters, ...filters };
  },

  [UPDATE_PAGINATION] (state, pagination) {
    if (pagination) {
      state.pagination = { ...state.pagination, ...pagination };
    } else if (state.data?.items && state.data.items.length >= state.pagination.perPage) {
      state.pagination.page = Math.ceil(state.data.items.length / state.pagination.perPage) + 1;
    }
  },

  [UPDATE_PLANS_HISTORY] (state, plans) {
    if (state.pagination.page !== 1 && state?.data?.items && plans) {
      state.data.items = Array.from(new Set([...state.data.items, ...plans.items]));
    } else {
      state.data = plans;
    }
  }
};

const actions: ActionTree<PlansHistoryState, RootState> = {
  async [GET_PLANS_HISTORY] ({ commit, state }) {
    const { pagination: { perPage, page }, filters, manager } = state;
    const { id } = manager as PlansItem;

    const limit = perPage;
    const offset = (page - 1) * limit;

    const {
      data: {
        admins: {
          items: [admin]
        }
      }
    } = await EfficiencyPlansHistoryService.getPlans(id, limit, offset, filters);

    if (admin) {
      const { efficiencies } = admin;
      commit(UPDATE_PLANS_HISTORY, efficiencies);
    }
  },

  async [REMOVE_PLAN] (_, id) {
    await EfficiencyPlansHistoryService.removePlan(id);
  }
};

export const efficiencyPlansHistory: Module<PlansHistoryState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions
};
