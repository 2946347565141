import MAIN from "@/locales/MAIN.dev.json";

import FAQ from "@/locales/FAQ.dev.json";
import PHP_CONFIG from "@/locales/PHP_CONFIG.dev.json";

export default {
  ...MAIN,
  ...FAQ,
  ...PHP_CONFIG
};
