export const INITIALIZATION = "INITIALIZATION";

// auth
export const UPDATE_TOKEN = "UPDATE_TOKEN";
export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGIN_BY_PASSWORD = "USER_LOGIN_BY_PASSWORD";
export const USER_LOGIN_BY_TELEGRAM = "USER_LOGIN_BY_TELEGRAM";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_REGISTRATION = "USER_REGISTRATION";
export const ADMIN_LOGIN_AS_WEBMASTER = "ADMIN_LOGIN_AS_WEBMASTER";
export const ADMIN_LOGIN_AS_ADVERTISER = "ADMIN_LOGIN_AS_ADVERTISER";
export const ADMIN_LOGOUT_ROLE = "ADMIN_LOGOUT_ROLE";
export const GET_USER_ROLE = "GET_USER_ROLE";
export const GET_TOKEN = "GET_TOKEN";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const SEND_NEW_PASSWORD = "SEND_NEW_PASSWORD";
// locale
export const UPDATE_LOCALE = "UPDATE_LOCALE";
// statistics
export const FETCH_LEAD_STATISTIC = "FETCH_STATISTIC";
export const GET_STATISTIC = "GET_STATISTIC";
export const GET_SUBSTATISTIC = "GET_SUBSTATISTIC";
export const GET_SUBSTATISTIC_BY_INDEX = "GET_SUBSTATISTIC";
export const SET_STATISTIC = "SET_STATISTIC";
export const UPDATE_OFFERS_DICTIONARY = "UPDATE_OFFERS_DICTIONARY";
export const GET_CURRENCIES = "GET_CURRENCIES";
export const GET_DAYS_STATS = "GET_DAYS_STATS";
export const GET_DAY_STATISTIC = "GET_DAY_STATISTIC";
export const GET_STATISTIC_TOP_CARD_TABLE_DATA = "GET_STATISTIC_TOP_CARD_TABLE_DATA";
export const GET_ACTIVE_COUNTS = "GET_ACTIVE_COUNTS";
export const GET_FILTER_DATA = "GET_FILTER_DATA";
export const GET_FILTER_INITIAL_DATA = "GET_FILTER_INITIAL_DATA";
export const SYNC_STATISTIC_FILTERS = "SYNC_STATISTIC_FILTERS";

// detailing
export const GET_LEAD_STATUSES = "GET_LEAD_STATUSES";
export const UPDATE_SELECTED_LEADS = "UPDATE_SELECTED_LEADS";
export const GET_SENT_TO_CRM_LEADS = "GET_SENT_TO_CRM_LEADS";
export const GET_RE_REQUESTED_LEADS = "GET_RE_REQUESTED_LEADS";
export const GET_PAID_UP_COUNT = "GET_PAID_UP_COUNT";
export const SELECTED_LEADS = "SELECTED_LEADS";

// profile
export const CHANGE_USER_PASSWORD = "CHANGE_USER_PASSWORD";
export const FETCH_USER = "FETCH_USER";
export const EDIT_USER = "EDIT_USER";
export const GET_WEBMASTER_PERSONAL_MANAGER = "GET_WEBMASTER_PERSONAL_MANAGER";
export const GET_ADVERTISER_PERSONAL_MANAGER = "GET_ADVERTISER_PERSONAL_MANAGER";
export const GET_API_TOKENS = "GET_API_TOKENS";
export const GET_REQUISITES = "GET_REQUISITES";
export const ADD_REQUISITE = "ADD_REQUISITE";
export const REMOVE_REQUISITE = "REMOVE_REQUISITE";
export const GET_PAYMENT_SYSTEMS = "GET_PAYMENT_SYSTEMS";
export const DELETE_PAYMENT_SYSTEM = "DELETE_PAYMENT_SYSTEM";
export const EDIT_PAYMENT_SYSTEM = "EDIT_PAYMENT_SYSTEM";
export const ADD_PAYMENT_SYSTEM = "ADD_PAYMENT_SYSTEM";
export const GET_TRAFFIC_TYPES = "GET_TRAFFIC_TYPES";
export const GET_DASH_STATS = "GET_DASH_STATS";
export const GET_DAY_STATS = "GET_DAY_STATS";
export const GET_OFFER_CATEGORIES = "GET_OFFER_CATEGORIES";

export const GET_DETAIL = "GET_DETAIL";
export const REREQUEST_LEAD_STATUSES = "REREQUEST_LEAD_STATUSES";

export const GET_TIMEZONES = "GET_TIMEZONES";
export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_COUNTRIES_LIST = "GET_COUNTRIES_LIST";
export const GET_REGIONS = "GET_REGIONS";

export const GET_TICKETS = "GET_TICKETS";
export const GET_TICKET = "GET_TICKET";
export const GET_TICKET_MESSAGES = "GET_TICKET_MESSAGES";
export const ADD_TICKET = "ADD_TICKET";
export const CREATE_TICKET_MESSAGES = "CREATE_TICKET_MESSAGES";
export const CLOSE_TICKET = "CLOSE_TICKET";
export const RESEND_EMAIL = "RESEND_EMAIL";

// postback detail
export const GET_STATISTIC_POSTBACKS = "GET_STATISTIC_POSTBACKS";

// admin
export const GET_INTEGRATIONS = "GET_INTEGRATIONS";
export const GET_OLD_STATISTIC = "GET_OLD_STATISTIC";
export const GET_UNINTEGRATION_OFFERS = "GET_UNINTEGRATION_OFFERS";
export const VALIDATE_CRM_LEAD_GROUP = "VALIDATE_CRM_LEAD_GROUP";
export const GET_CRM = "GET_CRM";
export const CREATE_CRM = "CREATE_CRM";
export const CREATE_INTEGRATION = "CREATE_INTEGRATION";
export const DELETE_INTEGRATION = "DELETE_INTEGRATION";
export const LAUNCH_INTEGRATION = "LAUNCH_INTEGRATION";
export const STOP_INTEGRATION = "STOP_INTEGRATION";
export const GET_INTEGRATION_CRM = "GET_INTEGRATION_CRM";
export const UPDATE_INTEGRATION_CRM_LIST_PAGINATION = "UPDATE_INTEGRATION_CRM_LIST_PAGINATION";
export const UPDATE_INTEGRATION_CRM_FILTERS = "UPDATE_INTEGRATION_CRM_FILTERS";
export const UPDATE_FLOW_REDIRECT = "UPDATE_FLOW_REDIRECT";

// Admins list
export const GET_ADMINS_LIST = "GET_ADMINS_LIST";
export const UPDATE_ADMINS_LIST = "UPDATE_ADMINS_LIST";
export const GET_INITIAL_ADMINS = "GET_INITIAL_ADMINS";

// users
export const GET_ADMINISTRATORS = "GET_ADMINISTRATORS";
export const GET_WEBMASTERS = "GET_WEBMASTERS";
export const GET_ADVERTISERS = "GET_ADVERTISERS";
export const WEBMASTER_BALANCE = "WEBMASTER_BALANCE";
export const UPDATE_WEBMASTERS_BALANCE = "UPDATE_WEBMASTERS_BALANCE";
export const UPDATE_ADVERTISERS_BALANCE = "UPDATE_ADVERTISERS_BALANCE";
export const GET_WEBMASTERS_WITH_BALANCE = "GET_WEBMASTERS_WITH_BALANCE";
export const GET_ADVERTISERS_WITH_BALANCE = "GET_ADVERTISERS_WITH_BALANCE";
export const ADVERTISER_BALANCE = "ADVERTISER_BALANCE";
export const GET_ADVERTISER = "GET_ADVERTISER";
export const EDIT_ADVERTISER = "EDIT_ADVERTISER";
export const GET_WEBMASTER = "GET_WEBMASTER";
export const EDIT_WEBMASTER = "EDIT_WEBMASTER";
export const GET_ADMINISTRATOR = "GET_ADMINISTRATOR";
export const EDIT_ADMINISTRATOR = "EDIT_ADMINISTRATOR";
export const CREATE_ADMINISTRATOR = "CREATE_ADMINISTRATOR";
export const EDIT_PASSWORD = "EDIT_PASSWORD";
export const RESEND_EMAIL_BY_ADMIN = "RESEND_EMAIL_BY_ADMIN";

// Admin.Users
export const EDIT_WEBMASTER_ADMIN_COMMENT = "EDIT_WEBMASTER_ADMIN_COMMENT";
export const EDIT_ADVERTISER_ADMIN_COMMENT = "EDIT_ADVERTISER_ADMIN_COMMENT";

// Admin. Users. Advertisers
export const GET_ADVERTISER_RATES = "GET_ADVERTISER_RATES";

// Admin. Offers
export const GET_OFFERS = "GET_OFFERS";
export const ADD_ADMINISTRATOR = "ADD_ADMINISTRATOR";
export const ADD_APPROVED_WEBMASTER = "ADD_APPROVED_WEBMASTER";
export const ADD_APPROVED_ADVERTISER = "ADD_APPROVED_ADVERTISER";
export const ADD_WEBMASTER_PERSONAL_MANAGER = "ADD_WEBMASTER_PERSONAL_MANAGER";
export const DELETE_WEBMASTER_PERSONAL_MANAGER = "DELETE_WEBMASTER_PERSONAL_MANAGER";
export const ADD_ADVERTISER_PERSONAL_MANAGER = "ADD_ADVERTISER_PERSONAL_MANAGER";
export const DELETE_ADVERTISER_PERSONAL_MANAGER = "DELETE_ADVERTISER_PERSONAL_MANAGER";
export const BLOCK_ADVERTISER = "BLOCK_ADVERTISER";
export const UNBLOCK_ADVERTISER = "UNBLOCK_ADVERTISER";
export const BLOCK_WEBMASTER = "BLOCK_WEBMASTER";
export const UNBLOCK_WEBMASTER = "UNBLOCK_WEBMASTER";
export const BLOCK_ADMINISTRATOR = "BLOCK_ADMINISTRATOR";
export const UNBLOCK_ADMINISTRATOR = "UNBLOCK_ADMINISTRATOR";
export const DELETE_OFFER = "DELETE_OFFER";
export const LAUNCH_OFFER = "LAUNCH_OFFER";
export const STOP_OFFER = "STOP_OFFER";
export const GET_HISTORY_WEBMASTER = "GET_HISTORY_WEBMASTER";
export const GET_HISTORY_ADVERTISER = "GET_HISTORY_ADVERTISER";
export const GET_WEBMASTER_BALANCE = "GET_WEBMASTER_BALANCE";
export const GET_WEBMASTERS_LIST = "GET_WEBMASTERS_LIST";
export const UPDATE_WEBMASTERS_LIST = "UPDATE_WEBMASTERS_LIST";
export const UPDATE_SELECTED_ADVERTISERS = "UPDATE_SELECTED_ADVERTISERS";
export const ENABLE_WEBMASTER_PARTNER_NETWORK = "ENABLE_WEBMASTER_PARTNER_NETWORK";
export const DISABLE_WEBMASTER_PARTNER_NETWORK = "DISABLE_WEBMASTER_PARTNER_NETWORK";
export const DISABLE_WEBMASTER_PUBLIC_API = "DISABLE_WEBMASTER_PUBLIC_API";
export const ENABLE_WEBMASTER_PUBLIC_API = "ENABLE_WEBMASTER_PUBLIC_API";

// Admin. Offer
export const GET_OFFER = "GET_OFFER";
export const GET_OFFER_ID = "GET_OFFER_ID";
export const UPDATE_OFFER_RATES_REQUIREMENTS = "UPDATE_OFFER_RATES_REQUIREMENTS";
export const BEFORE_GET_OFFER_EXTERNAL_WEBMASTERS = "BEFORE_GET_OFFER_EXTERNAL_WEBMASTERS";
export const GET_OFFER_TRAFFIC_TYPES = "GET_OFFER_TRAFFIC_TYPES";
export const UPDATE_OFFER_TRAFFIC_TYPES = "UPDATE_OFFER_TRAFFIC_TYPES";
export const GET_OFFER_WEBMASTERS = "GET_OFFER_WEBMASTERS";
export const GET_OFFER_EXTERNAL_WEBMASTERS = "GET_OFFER_EXTERNAL_WEBMASTERS";
export const WEBMASTER_REWARD = "WEBMASTER_REWARD";
export const SET_WEBMASTER_REWARD = "SET_WEBMASTER_REWARD";
export const UPDATE_MINIMAL_ALLOWED_REWARD = "UPDATE_MINIMAL_ALLOWED_REWARD";
export const GET_OFFER_ADVERTISER_WEBMASTERS = "GET_OFFER_ADVERTISER_WEBMASTERS";
export const GET_OFFER_WEBMASTERS_AVAILABLE = "GET_OFFER_WEBMASTERS_AVAILABLE";
export const ADD_OFFER_WEBMASTERS_AVAILABLE = "ADD_OFFER_WEBMASTERS_AVAILABLE";
export const GET_AVAILABLE_WEBMASTERS_BY_ID = "GET_AVAILABLE_WEBMASTERS_BY_ID";
export const GET_OFFER_ADVERTISERS_AVAILABLE = "GET_OFFER_ADVERTISERS_AVAILABLE";
export const UPDATE_MAXIMAL_ALLOWED_WEBMASTER_REWARD = "UPDATE_MAXIMAL_ALLOWED_WEBMASTER_REWARD";
export const ADD_OFFER_ADVERTISERS = "ADD_OFFER_ADVERTISERS";
export const GET_AVAILABLE_ADVERTISERS_BY_ID = "GET_AVAILABLE_ADVERTISERS_BY_ID";
export const UPDATE_MINIMAL_ALLOWED_ADVERTISER_RATE = "UPDATE_MINIMAL_ALLOWED_ADVERTISER_RATE";
export const ADD_OFFER_EXTERNAL_WEBMASTERS = "ADD_OFFER_EXTERNAL_WEBMASTERS";
export const GET_WEBMASTERS_BY_ID = "GET_WEBMASTERS_BY_ID";
export const GET_ADVERTISER_BY_ID = "GET_ADVERTISER_BY_ID";
export const GET_OFFER_RATES_REQUIREMENTS = "GET_OFFER_RATES_REQUIREMENTS";
export const ADD_OFFER_CALLCENTERS = "ADD_OFFER_CALLCENTERS";
export const ADD_OFFER_WEBMASTER_INDIVIDUAL_RATE = "ADD_OFFER_WEBMASTER_INDIVIDUAL_RATE";
export const UPDATE_OFFER_WEBMASTER_INDIVIDUAL_RATE = "UPDATE_OFFER_WEBMASTER_INDIVIDUAL_RATE";
export const DELETE_OFFER_ADVERTISERS = "DELETE_OFFER_ADVERTISERS";
export const DELETE_OFFER_WEBMASTERS = "DELETE_OFFER_WEBMASTERS";
export const GET_OFFER_ADVERTISERS = "GET_OFFER_ADVERTISERS";
export const GET_OFFER_ADVERTISERS_LIST = "GET_OFFER_ADVERTISERS_LIST";
export const ADD_OFFER_ADVERTISER_INDIVIDUAL_RATE = "ADD_OFFER_ADVERTISER_INDIVIDUAL_RATE";
export const GET_OFFER_ADVERTISER_INDIVIDUAL_RATES = "GET_OFFER_ADVERTISER_INDIVIDUAL_RATES";
export const DELETE_OFFER_ADVERTISER_INDIVIDUAL_RATE = "DELETE_OFFER_ADVERTISER_INDIVIDUAL_RATE";
export const UPDATE_OFFER_ADVERTISER_INDIVIDUAL_RATE = "UPDATE_OFFER_ADVERTISER_INDIVIDUAL_RATE";
export const CREATE_OFFER = "CREATE_OFFER";
export const UPDATE_OFFER = "UPDATE_OFFER";
export const GET_LANDINGS = "GET_LANDINGS";
export const PROXY_OFFER_LANDING = "PROXY_OFFER_LANDING";
export const GET_TRANSITS = "GET_TRANSITS";
export const UPDATE_OFFER_ADVERTISER = "UPDATE_OFFER_ADVERTISER";
export const GET_POSTBACKS = "GET_POSTBACKS";
export const UPDATE_POSTBACK = "UPDATE_POSTBACK";
export const DELETE_POSTBACK = "DELETE_POSTBACK";
export const GET_SELECTED_ADVERTISERS = "GET_SELECTED_ADVERTISERS";
export const GET_INITIAL_WEBMASTERS = "GET_INITIAL_WEBMASTERS";
export const UPDATE_SELECTED_WEBMASTERS = "UPDATE_SELECTED_WEBMASTERS";
export const UPDATE_SELECTED_OFFERS = "UPDATE_SELECTED_OFFERS";
export const UPDATE_RATES = "UPDATE_RATES";
export const GET_SELECTED_WEBMASTERS = "GET_SELECTED_WEBMASTERS";
export const GET_OFFER_WEBMASTERS_REWARD = "GET_OFFER_WEBMASTERS_REWARD";
export const GET_WEBMASTER_BY_ID = "GET_WEBMASTER_BY_ID";
export const UPDATE_MINIMAL_ALLOWED_ADVERTISER_WRITE_OFF = "UPDATE_MINIMAL_ALLOWED_ADVERTISER_WRITE_OFF";
export const PROXY_OFFER_WEBMASTER_INDIVIDUAL_RATE = "PROXY_OFFER_WEBMASTER_INDIVIDUAL_RATE";
export const DELETE_OFFER_WEBMASTER_INDIVIDUAL_RATE = "DELETE_OFFER_WEBMASTER_INDIVIDUAL_RATE";
export const BEFORE_GET_OFFER_WEBMASTERS = "BEFORE_GET_OFFER_WEBMASTERS";
export const BEFORE_GET_OFFER_ADVERTISERS = "BEFORE_GET_OFFER_ADVERTISERS";
export const UPDATE_OFFER_ADVERTISER_INDIVIDUAL_RATES = "UPDATE_OFFER_ADVERTISER_INDIVIDUAL_RATES";
export const BEFORE_GET_OFFER_ADVERTISERS_INDIVIDUAL_RATES = "BEFORE_GET_OFFER_ADVERTISERS_INDIVIDUAL_RATES";
export const UPDATE_OFFER_WEBMASTER_SOFT_CAPACITY = "UPDATE_OFFER_WEBMASTER_SOFT_CAPACITY";
export const PROXY_OFFER = "PROXY_OFFER";

// Admin. Offer. Landings
export const GET_OFFER_LANDINGS = "GET_OFFER_LANDINGS";
export const GET_OFFER_TRANSITS = "GET_OFFER_TRANSITS";
export const CREATE_OFFER_LANDING = "CREATE_OFFER_LANDING";
export const EDIT_OFFER_LANDING = "EDIT_OFFER_LANDING";
export const UPLOAD_LANDING_FILE = "UPLOAD_LANDING_FILE";

//Admin. CallCenters
export const CREATE_CALLCENTER = "CREATE_CALLCENTER";
export const CREATE_TIMETABLE = "CREATE_TIMETABLE";
export const GET_CALLCENTERS_UTC = "GET_CALLCENTERS_UTC";
export const GET_AVAILABLE_CALLCENTERS_UTC = "GET_AVAILABLE_CALLCENTERS_UTC";
export const GET_CALLCENTERS = "GET_CALLCENTERS";
export const GET_AVAILABLE_CALLCENTERS_FOR_OFFER = "GET_AVAILABLE_CALLCENTERS_FOR_OFFER";
export const GET_TIMETABLE = "GET_TIMETABLE";
export const EDIT_TIMETABLE = "EDIT_TIMETABLE";
export const DELETE_CALLCENTERS = "DELETE_CALLCENTERS";


// Admin. Roles
export const GET_PERMISSIONS = "GET_PERMISSIONS";
export const GET_ROLES = "GET_ROLES";
export const EDIT_ADMIN_ROLE = "EDIT_ADMIN_ROLE";
export const ADD_ROLE = "ADD_ROLE";
export const EDIT_ROLE = "EDIT_ROLE";
export const DELETE_ROLE = "DELETE_ROLE";

// Admin. Domains
export const GET_DOMAINS = "GET_DOMAINS";
export const GET_DOMAIN = "GET_DOMAIN";
export const ADD_DOMAIN = "ADD_DOMAIN";
export const DELETE_DOMAIN = "DELETE_DOMAIN";
export const UPDATE_DOMAINS_PAGINATION = "UPDATE_DOMAINS_PAGINATION";
export const GET_PARENT_DOMAINS = "GET_PARENT_DOMAINS";

// Admin. Finances
export const CREATE_INCOME = "CREATE_INCOME";
export const GET_USER_BALANCE = "GET_USER_BALANCE";
export const CREATE_COST = "CREATE_COST";
export const CREATE_CORRECTION = "CREATE_CORRECTION";
export const GET_CORRECTIONS = "GET_CORRECTIONS";

// Admin. Efficiency
export const GET_EFFICIENCY = "GET_EFFICIENCY";

// Admin. Plans
export const GET_PLANS = "GET_PLANS";
export const EDIT_PLANS_ADMIN_COMMENT = "EDIT_PLANS_ADMIN_COMMENT";
export const GET_PLANS_HISTORY = "GET_PLANS_HISTORY";
export const REMOVE_PLAN = "REMOVE_PLAN";
export const ADD_PLAN = "ADD_PLAN";

// Lists. Costs
export const GET_COSTS = "GET_COSTS";
export const PROCESS_COST = "PROCESS_COST";
export const ACCEPT_COST = "ACCEPT_COST";
export const REJECT_COST = "REJECT_COST";
export const AUTO_COST = "AUTO_COST";
export const FILTER_SELECTED_COSTS = "FILTER_SELECTED_COSTS";
export const UPDATE_SELECTED_COSTS = "UPDATE_SELECTED_COSTS";
export const GET_COSTS_BY_TYPE = "GET_COSTS_BY_TYPE";
export const UPDATE_COSTS_TYPE = "UPDATE_COSTS_TYPE";
export const PROXY_COST = "PROXY_COST";

// Lists. Refill
export const GET_INCOMES = "GET_INCOMES";

// Lists. Statuses
export const GET_STATUSES = "GET_STATUSES";

// Lists. PaymentSystems
export const GET_PAYMENT_SYSTEMS_LIST = "GET_PAYMENT_SYSTEMS_LIST";
export const UPDATE_PAYMENT_SYSTEMS_LIST = "UPDATE_PAYMENT_SYSTEMS_LIST";
export const GET_INITIAL_PAYMENT_SYSTEMS = "GET_INITIAL_PAYMENT_SYSTEMS";

// Lists. Requisites
export const GET_REQUISITES_LIST = "GET_REQUISITES_LIST";
export const UPDATE_REQUISITES_LIST = "UPDATE_REQUISITES_LIST";
export const GET_INITIAL_REQUISITES = "GET_INITIAL_REQUISITES";

// Lists. Advertisers
export const GET_ADVERTISERS_LIST = "GET_ADVERTISERS_LIST";
export const UPDATE_ADVERTISERS_LIST = "UPDATE_ADVERTISERS_LIST";
export const GET_INITIAL_ADVERTISERS = "GET_INITIAL_ADVERTISERS";

// Lists. Offers
export const GET_OFFERS_LIST = "GET_OFFERS_LIST";
export const GET_INITIAL_OFFERS = "GET_INITIAL_OFFERS";
export const UPDATE_OFFERS_LIST = "UPDATE_OFFERS_LIST";

//Lists. PaymentIntegrations
export const GET_PAYMENT_INTEGRATIONS_LIST = "GET_PAYMENT_INTEGRATIONS_LIST";
export const UPDATE_PAYMENT_INTEGRATIONS_LIST = "UPDATE_PAYMENT_INTEGRATIONS_LIST";
export const GET_INITIAL_PAYMENT_INTEGRATIONS = "GET_INITIAL_PAYMENT_INTEGRATIONS";
export const GET_PAYMENT_INTEGRATIONS_BY_ID = "GET_PAYMENT_INTEGRATIONS_BY_ID";
export const GET_PAYMENT_BY_ID = "GET_PAYMENT_BY_ID";
export const UPDATE_PAYMENT_FORM = "UPDATE_PAYMENT_FORM";
export const UPDATE_EDITABLE_PAYMENTS_ID = "UPDATE_EDITABLE_PAYMENTS_ID";
export const UPDATE_PAYMENT_SYSTEM = "UPDATE_PAYMENT_SYSTEM";
export const UPDATE_PAYMENT_FORM_MODAL = "UPDATE_PAYMENT_FORM_MODAL";

//Lists. CRM
export const GET_CRM_LIST = "GET_CRM_LIST";
export const UPDATE_CRM_LIST = "UPDATE_CRM_LIST";
export const GET_INITIAL_CRM = "GET_INITIAL_CRM";

//Lists. Approved
export const GET_APPROVED = "GET_APPROVED";

//Lists. EmailConfirmed
export const GET_EMAIL_CONFIRMED = "GET_EMAIL_CONFIRMED";

//Lists. Blocked
export const GET_BLOCKED = "GET_BLOCKED";

//Lists. PartnerNetwork
export const GET_PARTNER_NETWORK = "GET_PARTNER_NETWORK";

//Lists. WithdrawTypes
export const GET_WITHDRAW_TYPES = "GET_WITHDRAW_TYPES";

//Lists. OfferTypes
export const GET_OFFER_TYPES = "GET_OFFER_TYPES";

// Lists. Languages
export const GET_LANGUAGES_LIST = "GET_LANGUAGES_LIST";
export const GET_LANGUAGES_INTERNATIONALIZED = "GET_LANGUAGES_INTERNATIONALIZED";

// Webmaster. Flow
export const CREATE_FLOW = "CREATE_FLOW";
export const UPDATE_FLOW = "UPDATE_FLOW";
export const SET_EMPTY = "SET_EMPTY";
export const GET_FLOW = "GET_FLOW";
export const GET_FLOW_PARAMETERS = "GET_FLOW_PARAMETERS";
export const GENERATE_FLOW_URL = "GENERATE_FLOW_URL";
export const CREATE_BASE_LINK = "CREATE_BASE_LINK";
export const CREATE_LINK = "CREATE_LINK";
export const UPDATE_FLOW_FIELDS = "UPDATE_FLOW_FIELDS";

// Webmaster. Flows
export const GET_FLOWS = "GET_FLOWS";
export const EDIT_FLOW_LINK = "EDIT_FLOW_LINK";

// Webmaster. Offer
export const REMOVE_FLOW = "REMOVE_FLOW";
export const JOIN_OFFER = "JOIN_OFFER";
export const JOIN_PRIVATE_OFFER = "JOIN_PRIVATE_OFFER";
export const JOIN_TO_OFFER = "JOIN_TO_OFFER";
export const EXCLUDE_OFFER = "EXCLUDE_OFFER";

// Webmaste. Finances
export const GET_FINANCES = "GET_FINANCES";
export const ORDER_PAYMENT = "ORDER_PAYMENT";

// CRM
export const GET_METHODS = "GET_METHODS";
export const GET_REQUEST_TYPES = "GET_REQUEST_TYPES";
export const GET_RESPONSE_TYPES = "GET_RESPONSE_TYPES";
export const GET_STRATEGY = "GET_STRATEGY";
export const GET_MACROS = "GET_MACROS";
export const GET_FILTERED_CUSTOM_MACROS = "GET_FILTERED_CUSTOM_MACROS";
export const GET_RECOVERY_STRATEGY = "GET_RECOVERY_STRATEGY";
export const GET_CRM_BY_ALIAS = "GET_CRM_BY_ALIAS";
export const PROXY_CRM = "PROXY_CRM";
export const EDIT_CRM = "EDIT_CRM";
export const DELETE_CRM = "DELETE_CRM";
export const DEFAULT_OPTIONS = "DEFAULT_OPTIONS";
export const CHECK_VALID_OPTIONS = "CHECK_VALID_OPTIONS";
export const GET_CRM_OPTIONS = "GET_CRM_OPTIONS";
export const UPDATE_IS_WITHOUT_REQUEST = "UPDATE_IS_WITHOUT_REQUEST";
export const GET_FLOW_REDIRECT_MACROS = "GET_FLOW_REDIRECT_MACROS";

// Admin. Finances. WebAccrual
export const GET_WEB_ACCRUAL = "GET_WEB_ACCRUAL";

// Correction
export const GET_CORRECTION_TYPES = "GET_CORRECTION_TYPES";
export const UPDATE_CORRECTION_TYPE = "UPDATE_CORRECTION_TYPE";
export const GET_CORRECTION_TARGET = "GET_CORRECTION_TARGET";
export const GET_FILTERED_CORRECTION = "GET_FILTERED_CORRECTION";
export const GET_CORRECTION_BY_VALUE = "GET_CORRECTION_BY_VALUE";

// Uploads
export const GET_UPLOADS = "GET_UPLOADS";
export const UPLOAD_FILE = "UPLOAD_FILE";
export const GET_FORMAT_FILE = "GET_FORMAT_FILE";
export const GET_UPLOADS_FIELDS = "GET_UPLOADS_FIELDS";
export const FILES_UPLOADED_IDS = "FILES_UPLOADED_IDS";
export const GET_UPLOADS_FILE_READY_PERCENT = "GET_UPLOADS_FILE_READY_PERCENT";
export const GET_LINK_FOR_DOWNLOAD = "GET_LINK_FOR_DOWNLOAD";

// Webmaster. Offer. Postbacks
export const GET_OFFER_POSTBACKS = "GET_OFFER_POSTBACKS";
export const REMOVE_OFFER_POSTBACK = "REMOVE_OFFER_POSTBACK";
export const SAVE_OFFER_POSTBACKS = "SAVE_OFFER_POSTBACKS";

// Webmaster. Profile. GlobalPostback
export const GET_GLOBAL_POSTBACKS = "GET_GLOBAL_POSTBACKS";
export const SAVE_GLOBAL_POSTBACKS = "SAVE_GLOBAL_POSTBACKS";
export const REMOVE_GLOBAL_POSTBACK = "REMOVE_GLOBAL_POSTBACK";

// Advertiser. Finances
export const GET_WRITE_OFFS_PROXY = "GET_WRITE_OFFS_PROXY";
export const GET_WRITE_OFFS_BY_LEADS = "GET_WRITE_OFFS_BY_LEADS";
export const GET_WRITE_OFFS = "GET_WRITE_OFFS";
export const GET_WRITE_OFFS_BY_LEADS_GROUPED = "GET_WRITE_OFFS_BY_LEADS_GROUPED";
export const UPDATE_GROUP = "UPDATE_GROUP";

// Modal
export const UPDATE_MODAL_ACTIVE = "UPDATE_MODAL_ACTIVE";
export const UPDATE_MODAL_TYPE = "UPDATE_MODAL_TYPE";
export const UPDATE_MODAL_OPTIONS = "UPDATE_MODAL_OPTIONS";
