<template>
    <div>
        <slot
            v-if="role === 'webmaster'"
            name="webmaster"></slot>
        <slot
            v-else-if="role === 'advertiser'"
            name="advertiser"></slot>
        <slot
            v-else-if="role === 'admin'"
            name="admin"></slot>
        <div
            v-else
            class="loader-wrapper">
            <b-loading
                :active="true"
                :is-full-page="false"></b-loading>
        </div>
    </div>
</template>

<script>
  import { mapState } from "vuex";

  export default {
    name: "Role",
    computed: {
      ...mapState({
        role: state => state.auth.role
      })
    }
  };
</script>

<style lang="scss" scoped>
    .loader-wrapper {
        width: 100%;
        height: 100%;
        margin: auto;
        min-width: 60px;
        min-height: 60px;
        position: relative;
    }
</style>
