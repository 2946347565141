// @ts-ignore
import dev from "../../../locales/dev.js";
// @ts-ignore
import en from "../../../locales/en.js";
// @ts-ignore
import ru from "../../../locales/ru.js";
import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const fallbackLocale = "en";

const locale: string = navigator.language.slice(0, 2) || fallbackLocale;

const messages: Record<string, any> = {
  ru,
  en
};

if (process.env.NODE_ENV !== "production") {
  messages.dev = dev;
}

export default new VueI18n({
  locale,
  fallbackLocale,
  messages
});
