<!--

Пропы:

isEdit: флаг для редактирования (по умолчанию false -> компонент используется для создания админа)

-->

<template>
    <div class="columns is-multiline">
        <div
            v-if="!isEdit"
            class="column is-6">
            <FField :label="$t('admin.users.administrators.modal.addAdmin.body.login')">
                <RequiredField
                    v-if="profileLoaded"
                    v-model.trim="_user.login"
                    prop="login"
                    pattern="[\d\w+]{2,}"
                    name="login"
                    :pattern-message="$t(`common.auth.signUp.loginHelp`)"
                    :placeholder="$t('admin.users.administrators.modal.addAdmin.body.login')">
                </RequiredField>
                <el-skeleton
                    v-else
                    animated>
                    <template #template>
                        <el-skeleton-item style="width: 100%; height: 35px;"></el-skeleton-item>
                    </template>
                </el-skeleton>
            </FField>
        </div>

        <div class="column is-6">
            <FField :label="$t('admin.users.administrators.modal.addAdmin.body.name')">
                <RequiredField
                    v-if="profileLoaded"
                    v-model="_user.name"
                    autocomplete="on"
                    :placeholder="$t('admin.users.administrators.modal.addAdmin.body.name')"
                    :pattern="fullNameValidator"
                    :pattern-message="$t(`common.auth.signUp.nameHelp`)"
                    prop="name">
                </RequiredField>
                <el-skeleton
                    v-else
                    animated>
                    <template #template>
                        <el-skeleton-item style="width: 100%; height: 35px;"></el-skeleton-item>
                    </template>
                </el-skeleton>
            </FField>
        </div>

        <div class="column is-6">
            <FField :label="$t('admin.users.administrators.modal.addAdmin.body.telegram')">
                <RequiredField
                    v-if="profileLoaded"
                    v-model.trim="_telegram"
                    autocomplete="on"
                    prop="telegram"
                    pattern="^@[\d\w]{5,}$"
                    pattern-message="common.auth.signUp.telegramHelp"
                    :placeholder="$t('admin.users.administrators.modal.addAdmin.body.telegram')">
                    <template slot="prepend">
                        @
                    </template>
                </RequiredField>
                <el-skeleton
                    v-else
                    animated>
                    <template #template>
                        <el-skeleton-item style="width: 100%; height: 35px;"></el-skeleton-item>
                    </template>
                </el-skeleton>
            </FField>
        </div>

        <div class="column is-6">
            <FField :label="$t('admin.users.administrators.modal.addAdmin.body.email')">
                <RequiredField
                    v-if="profileLoaded"
                    v-model.trim="_user.email"
                    prop="email"
                    type="email"
                    name="email"
                    :pattern="emailValidator"
                    pattern-message="common.validation.email"
                    :placeholder="$t('admin.users.administrators.modal.addAdmin.body.email')">
                </RequiredField>
                <el-skeleton
                    v-else
                    animated>
                    <template #template>
                        <el-skeleton-item style="width: 100%; height: 35px;"></el-skeleton-item>
                    </template>
                </el-skeleton>
            </FField>
        </div>

        <div
            v-if="isCountries"
            class="column is-6">
            <FField :label="$t('admin.users.administrators.modal.addAdmin.body.country')">
                <FCountrySelect
                    v-if="profileLoaded"
                    v-model="_user.countries"
                    multiple
                    :placeholder="$t(`webmaster.offers.filters.labels.country`)">
                </FCountrySelect>
                <el-skeleton
                    v-else
                    animated>
                    <template #template>
                        <el-skeleton-item style="width: 100%; height: 35px;"></el-skeleton-item>
                    </template>
                </el-skeleton>
            </FField>
        </div>

        <div
            v-if="isWorkedYear"
            class="column is-6">
            <FField :label="$t('admin.users.administrators.modal.addAdmin.body.workedYear')">
                <FInput
                    v-if="profileLoaded"
                    v-model="_user.workedYear"
                    type="number"
                    min="1904"
                    max="2022"
                    :placeholder="$t('admin.users.administrators.modal.addAdmin.body.workedYear')">
                </FInput>
                <el-skeleton
                    v-else
                    animated>
                    <template #template>
                        <el-skeleton-item style="width: 100%; height: 35px;"></el-skeleton-item>
                    </template>
                </el-skeleton>
            </FField>
        </div>

        <div
            v-if="isDescription"
            class="column is-6">
            <FField :label="$t('admin.users.administrators.modal.addAdmin.body.description')">
                <FInput
                    v-if="profileLoaded"
                    v-model="_user.description"
                    :placeholder="$t('admin.users.administrators.modal.addAdmin.body.description')">
                </FInput>
                <el-skeleton
                    v-else
                    animated>
                    <template #template>
                        <el-skeleton-item style="width: 100%; height: 35px;"></el-skeleton-item>
                    </template>
                </el-skeleton>
            </FField>
        </div>

        <div
            v-if="isCurrency"
            class="column is-6">
            <FField :label="$t('admin.users.administrators.modal.addAdmin.body.preferredCurrency')">
                <FSelect
                    v-if="profileLoaded"
                    v-model="_user.preferredCurrency"
                    :get-data="getCurrency"
                    :clearable="false"
                    required>
                </FSelect>
                <el-skeleton
                    v-else
                    animated>
                    <template #template>
                        <el-skeleton-item style="width: 100%; height: 35px;"></el-skeleton-item>
                    </template>
                </el-skeleton>
            </FField>
        </div>
    
        <div
            v-if="!isEdit && withPasswordEdit"
            class="column is-6">
            <EditUserPasswordField
                v-if="profileLoaded"
                v-model.trim="_user.password"
                prop="password"
                :label="$t('admin.users.administrators.modal.addAdmin.body.password')"
                :placeholder="$t('admin.users.administrators.modal.addAdmin.body.password')">
            </EditUserPasswordField>
            <el-skeleton
                v-else
                animated>
                <template #template>
                    <el-skeleton-item style="width: 100%; height: 35px;"></el-skeleton-item>
                </template>
            </el-skeleton>
        </div>
    </div>
</template>

<script>
  import { EMAIL_VALIDATOR, FULL_NAME_VALIDATOR } from "@core/validators";
  import { mapActions, mapState } from "vuex";
  import { GET_CURRENCY } from "@core/store/action-constants";
  import FSelect from "@/components/Common/FSelect";
  import EditUserPasswordField from "@/components/Common/Fields/EditUserPasswordField";
  import FCountrySelect from "@/components/Common/FCountrySelect";
  import FField from "@/components/Common/FField";
  import FInput from "@/components/Common/FInput";
  import RequiredField from "@/components/Common/RequiredField";

  export default {
    name: "AdminProfile",
    components: {
      RequiredField,
      FField,
      EditUserPasswordField,
      FCountrySelect,
      FSelect,
      FInput
    },
    props: {
      user: {
        type: Object,
        default: null
      },
      
      isEdit: {
        type: Boolean,
        default: false
      },

      withPasswordEdit: {
        type: Boolean,
        default: true
      },

      isCurrency: {
        type: Boolean,
        default: false
      },

      isCountries: {
        type: Boolean,
        default: false
      },

      isWorkedYear: {
        type: Boolean,
        default: false
      },

      isDescription: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      ...mapState("admin/profile/currencyList", {
        currencylist: ({ data }) => data
      }),

      emailValidator () {
        return EMAIL_VALIDATOR;
      },

      fullNameValidator () {
        return FULL_NAME_VALIDATOR;
      },
  
      profileLoaded () {
        return !!this.user;
      },
  
      _user: {
        get () {
          return this.user;
        },
    
        set (value) {
          this.$emit("update:user", value);
        }
      },

      _telegram: {
        get () {
          return this.user.telegram?.replace(/@/g, "");
        },

        set (value) {
          this.$emit("update:user", { ...this.user, telegram: "@" + value });
        }
      }
    },
    methods: {
      ...mapActions("admin/profile/currencyList", {
        GET_CURRENCY
      }),

      async getCurrency () {
        await this.GET_CURRENCY();
        const items = this.currencylist;
        return {
          items,
          count: items.length
        };
      }
    }
  };
</script>

<style lang="scss" scoped>
</style>
