import { RootState } from "@/store/root-state";
import { CoreAdmin } from "@core/store/modules/admin";

export class Admin extends CoreAdmin<RootState, RootState> {
  constructor () {
    super();
    
    this.modules({});
  }
}
