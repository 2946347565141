import { AxiosResponse } from "axios";
import { PaginationParams } from "@core/logic/common/pagination-params";
import Service from "@core/services/common/Service";

interface Advertiser {
  id: string;
  name: string;
  login: string;
}

export interface CallCenter {
  id: string;
  name: string;
  isDeleted: null;
  createdAt: string;
  advertiser: Advertiser;
  TimeTables: TimeTables;
}

export interface GetCallCentersParams extends PaginationParams {
  filters: {
    id: string;
  };
}

interface Time {
  timeStart: string;
  timeFinish: string;
}

export interface TimeTables {
  monday: Time[];
  tuesday: Time[];
  wednesday: Time[];
  thursday: Time[];
  friday: Time[];
  saturday: Time[];
  sunday: Time[];
}

class CallCentersService extends Service{
  public static getCallCenters (params: GetCallCentersParams): Promise<AxiosResponse<CallCenter>> {
    return this.rest.get("/webmaster/callcenters", { params });
  }
}

export default CallCentersService;