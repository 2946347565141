<template>
    <el-container class="bg-gray-light-bg h-screen flex items-center justify-center">
        <div class="flex items-center flex-col">
            <!--suppress HtmlUnknownTarget -->
            <img
                alt="logo"
                src="@/assets/logo.svg">
            <!--suppress HtmlUnknownTarget -->
            <img
                alt="mail-image"
                src="@/assets/MailConfirmation/expired.png">
            <h1 class="has-text-weight-bold has-text-centered">
                {{ $t(`common.mailConfirmation.expired.title`) }}
            </h1>
            <p class="has-text-grey has-text-centered">
                {{ $t(`common.mailConfirmation.expired.message`) }}
            </p>
            <a
                :href="link"
                class="mt-2">
                <FButton
                    type="success"
                    plain
                    round
                    no-more>
                    {{ $t(`common.mailConfirmation.expired.link`) }}
                </FButton>
            </a>
        </div>
    </el-container>
</template>

<script>
  import FButton from "@/components/Common/FButton";


  export default {
    name: "MailConfirmationExpired",
    components: { FButton },
    computed: {
      link () {
        return process.env.VUE_APP_LANDING_PAGE_URL;
      }
    }
  };
</script>
<style lang="scss" scoped>
  .confirm {
      &-mail {
          h1 {
              margin-bottom: 0;
          }
          p {
              margin-bottom: 20px;
          }
      }
  }
  </style>
