import Service from "@core/services/common/Service";
import { OfferFilters, Response } from "@core/store/types/admin/offer/webmasters";
import { AxiosPromise } from "axios";
import webmastersByOffer from "./graphql/webmastersByOffer.graphql";
import { UserFiltersInput } from "@core/store/types/common/statistic/generic/statisticFilters";

export class OfferWebmastersService extends Service {
  public static getOfferWebmasters (offerId: UserFiltersInput["offerId"], webmasterFilters: OfferFilters, limit = 25, offset = 0): AxiosPromise<Response> {
    const { webmasterId, ...fields } = webmasterFilters;
    const filters = {
      id: webmasterId.length > 0 ? webmasterId : null,
      ...fields
    };
    
    return this.api.get("", {
      params: {
        query: webmastersByOffer,
        variables: {
          limit,
          offset,
          offerId,
          filters
        }
      }
    });
  }
}
