import { Categories, Country, Logo, Type } from "@core/logic/common/common";

export class AdvertiserOffer {
  id: string | undefined;
  intId: number | undefined;
  isLaunched: boolean | undefined;
  type: Type | undefined;
  advertiserWriteOff: number | undefined;
  categories: Categories[] | undefined;
  countries: Country[] | undefined;
  createdAt: Date | undefined;
  landing: {
    cost: null;
    preCost: null;
  } | undefined;
  logo: Logo | undefined;
  
  constructor (offer: any) {
    this.id = offer.id;
    this.intId = offer.intId;
    this.isLaunched = offer.isLaunched;
    this.type = offer.type;
    this.advertiserWriteOff = offer.advertiserWriteOff;
    this.categories = offer.categories;
    this.countries = offer.countries;
    this.createdAt = offer.createdAt;
    this.landing = offer.landing;
    this.logo = offer.logo;
  }
}
