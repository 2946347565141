import { OfferEditCommonService } from "@core/services/admin/offer/edit/OfferEditCommonServices";
import {
  CREATE_OFFER, GET_OFFER_ID,
  PROXY_OFFER,
  UPDATE_MAXIMAL_ALLOWED_WEBMASTER_REWARD,
  UPDATE_MINIMAL_ALLOWED_REWARD,
  UPDATE_OFFER,
  UPDATE_OFFER_RATES_REQUIREMENTS
} from "@core/store/action-constants";
import { offerTypesList } from "@core/store/modules/admin/lists/offerTypesList";
import { withdrawTypesList } from "@core/store/modules/admin/lists/withdrawTypesList";
import { AdminOfferModule } from "@core/store/modules/admin/offer/AdminOfferModule";
import { UPDATE_OFFER_OPTIONS } from "@core/store/mutation-constants";
import { landingParametersList } from "@core/store/modules/admin/offer/lists/offerEditLandingParametersList";
import { currencyList } from "@core/store/modules/common/lists/currencyList";

export class EditAdminOfferModule extends AdminOfferModule {
  constructor () {
    super();
    this.namespaced = true;

    this.actions({
      async [PROXY_OFFER] ({ getters, dispatch }) {
        try {
          const offerId = getters[GET_OFFER_ID];
          if (offerId) {
            await dispatch(UPDATE_OFFER);
          } else {
            await dispatch(CREATE_OFFER);
          }
        } catch (e) {
          throw e;
        }
      },

      async [CREATE_OFFER] ({ state, dispatch, commit }) {
        try {
          const { data: { createOffer: { id, intId } } } =
            await OfferEditCommonService.createOffer(state.offer);
          dispatch(`${ UPDATE_OFFER_OPTIONS }`, { id });
          commit(UPDATE_OFFER_OPTIONS, { intId });
        } catch (e) {
          throw e;
        }
      },

      async [UPDATE_OFFER] ({ state, getters, dispatch }) {
        try {
          const offerId = getters[GET_OFFER_ID];
          if (offerId && state.offer) {

            // TODO: возможно стоит переписать момент ниже на класс "RequestOffer"
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { id, intId, offerMinimalCommission, advertiserMinimalCommission, createdAt, ...offer } = state.offer;
            if (offer.trackingPageId === "") {
              offer.trackingPageId = null;
            }

            await OfferEditCommonService.updateOffer(
              offerId,
              offer
            );
            dispatch(`admin/offer/edit/${ UPDATE_MINIMAL_ALLOWED_REWARD }`,
              {}, { root: true });
            dispatch(`admin/offer/edit/${ UPDATE_OFFER_RATES_REQUIREMENTS }`,
              undefined, { root: true });
            dispatch(`admin/offer/edit/webmastersInternalRates/${ UPDATE_MAXIMAL_ALLOWED_WEBMASTER_REWARD }`,
              undefined, { root: true });
            dispatch(`admin/offer/edit/webmastersExternalRates/${ UPDATE_MAXIMAL_ALLOWED_WEBMASTER_REWARD }`,
              undefined, { root: true });
          }
        } catch (e) {
          throw e;
        }
      }
    });

    this.modules({
      currencyList,
      withdrawTypesList,
      offerTypesList,
      landingParametersList
    });

    return this;
  }
}
