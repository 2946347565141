import { NullableIntegrationsFilters } from "@core/logic/admin/integrations/integrations";
import createIntegration from "@core/services/admin/integrations/graphql/createIntegration.graphql";
import Service from "@core/services/common/Service";
import { CreateIntegrationInput, Integration } from "@core/store/types/admin/integrations";
import { AxiosPromise, AxiosResponse } from "axios";
import _cloneDeep from "lodash/cloneDeep";
import integrations from "../../admin/integrations/graphql/integrations.graphql";
import launchIntegration from "../../admin/integrations/graphql/launchIntegration.graphql";
import removeIntegration from "../../admin/integrations/graphql/removeIntegration.graphql";
import stopIntegration from "../../admin/integrations/graphql/stopIntegration.graphql";
import validateIntegration from "../../admin/integrations/graphql/validateIntegration.graphql";

function prepareRequestData (data: NullableRecord<CreateIntegrationInput>): Record<string, unknown> {
  const newData: NullableRecord<CreateIntegrationInput> = _cloneDeep(data);
  const newOptions = {};
  
  // FixMe: newOptions нужно описать тип
  newData.options?.forEach(option => {
    // @ts-ignore
    newOptions[option.name] = option.value;
  });
  
  // @ts-ignore
  newData.options = newOptions;
  
  return newData;
}

export class IntegrationService extends Service {
  /**
   * Метод создания интеграции
   * @param {NullableRecord<CreateIntegrationInput>} payload
   * @return {Promise<AxiosResponse<Partial<Integration>>>}
   */
  public static async createIntegration (
    payload: NullableRecord<CreateIntegrationInput>
  ): Promise<AxiosResponse<Partial<Integration>>> {
    const preparedData = prepareRequestData(payload);
    
    try {
      return await this.api.post("", {
        query: createIntegration,
        variables: {
          input: {
            ...preparedData
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  /**
   * Метод получения списка интеграций
   * @param {number} limit
   * @param {number} offset
   * @param {NullableIntegrationsFilters} filters
   * @return {Promise<AxiosPromise>}
   */
  public static async getIntegrations (
    limit: number,
    offset: number,
    filters: NullableIntegrationsFilters
  ): Promise<AxiosPromise> {
    try {
      const response = await this.api.get("", {
        params: {
          query: integrations,
          variables: {
            limit,
            offset,
            filters
          }
        }
      });
  
      response.data.integrations.items = response.data.integrations.items.map(
        (item: any) => {
          return {
            ...item,
            offer: {
              id: item.offer.id,
              intId: item.offer.intId,
              name: item.offer.detail.name
            }
          };
        }
      );
  
      return response;
    } catch (e) {
      throw e;
    }
  }
  
  /**
   * Метод запуска интеграции
   * @param {string} integrationId
   * @return {Promise<AxiosPromise>}
   */
  public static async launchIntegration (
    integrationId: string
  ): Promise<AxiosPromise> {
    try {
      return await this.api.post("", {
        query: launchIntegration,
        variables: {
          integrationId
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  /**
   * Метод остановки интеграции
   * @param {string} integrationId
   * @return {Promise<AxiosPromise>}
   */
  public static async stopIntegration (
    integrationId: string
  ): Promise<AxiosPromise> {
    try {
      return await this.api.post("", {
        query: stopIntegration,
        variables: {
          integrationId
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  /**
   * Метод валидации интеграции
   * @param {string} integrationId
   * @return {Promise<AxiosPromise>}
   */
  public static async validateIntegration (
    integrationId: string
  ): Promise<AxiosPromise> {
    try {
      return await this.api.post("", {
        query: validateIntegration,
        variables: {
          integrationId
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  /**
   * Метод удаления интеграции
   * @param {string} integrationId
   * @return {Promise<AxiosPromise>}
   */
  public static async removeIntegration (
    integrationId: string
  ): Promise<AxiosPromise> {
    try {
      return await this.api.post("", {
        query: removeIntegration,
        variables: {
          integrationId
        }
      });
    } catch (e) {
      throw e;
    }
  }
}
