import Service from "@/services/common/Service";
import { LanguagesListResponse } from "@/store/types/admin/lists/LanguagesListState";
import axios, { AxiosPromise } from "axios";

export class CountriesListService extends Service {
  public static async getCountriesList (
    limit: number,
    offset: number
  ): Promise<AxiosPromise<LanguagesListResponse>> {
    try {
      return await axios.get("/dictionaries/countries", {
        params: {
          limit,
          offset
        }
      });
    } catch (e) {
      throw e;
    }
  }
}
