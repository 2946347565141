import { GraphQLServer } from "@core/plugins/axiosGraphQL";
import offers from "@core/services/admin/offer/lists/graphql/webmastersList.graphql";
import Service from "@core/services/common/Service";
import { PartialWebmastersListState } from "@core/store/types/admin/offer/lists/OfferEditWebmastersList";
import { AxiosPromise } from "axios";

export class OfferEditWebmastersListServices extends Service {
  public static getOfferWebmasters (filters: PartialWebmastersListState["filters"],
    limit: number, offset: number, id: string[], initialWebmasters: string[]): AxiosPromise {
    const initialLimit = initialWebmasters?.length || 0;
    const hasInitial = !!initialWebmasters?.length;
    limit = Math.floor(initialLimit / limit + 1) * limit - initialLimit;
    try {
      return GraphQLServer.get("", {
        params: {
          query: offers,
          variables: {
            limit,
            offset,
            id,
            filters,
            initialWebmasters: { id: initialWebmasters },
            initialLimit,
            hasInitial
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
}
