<template>
    <el-dropdown
        ref="dropdown"
        v-bind="$attrs"
        :trigger="hover ? 'hover' : 'click'"
        :placement="placement"
        :hide-on-click="hideClick"
        v-on="$listeners">
        <slot></slot>
        <el-dropdown-menu
            v-if="data"
            slot="dropdown"
            class="overflow-y-auto py-2"
            :class="dropdownClassMenu"
            :style="`max-height: ${ maxHeight }px`">
            <el-dropdown-item
                :key="item.id ? item.id : index"
                v-for="(item, index) of data"
                :class="dropdownClassItem">
                <slot
                    name="dropdown"
                    :item="item">
                </slot>
            </el-dropdown-item>
            <slot name="append"></slot>
        </el-dropdown-menu>
        <el-dropdown-menu
            v-else
            :class="dropdownClassMenu"
            slot="dropdown">
            <slot name="dropdown"></slot>
        </el-dropdown-menu>
    </el-dropdown>
</template>

<script>
/*
* В минимальном виде компонент принимает:
* props - [ data, hover, placement, hideClick, maxHeight, dropdownClassItem ]
*
* data - Массив итерируемых значений, если нет, то используется пустой слот для вставки компонентов
* hover - раскрывается ли список при наведении, default false
* placement - положение раскрытого меню, может быть [ top, top-start, top-end, bottom, bottom-start, bottom-end ]
* hideClick - может ли скрываться меню при клике на элемент меню, default false
* maxHeight - ограничение высоты выпадающего меню
* dropdownClassItem - кастомные стили для итерируемого элемента
* dropdownClassMenu - кастомные стили для меню
* */

  export default {
    name: "FDropdown",
    props: {
      data: {
        type: Array,
        default: () => {}
      },

      hover: {
        type: Boolean,
        default: false
      },

      placement: {
        type: String,
        default: "bottom-end"
      },

      hideClick: {
        type: Boolean,
        default: false
      },

      maxHeight: {
        type: [String, Number],
        default: 300
      },

      dropdownClassItem: {
        type: String,
        default: null
      },

      dropdownClassMenu: {
        type: String,
        default: null
      },

      loading : {
        type: Boolean,
        default: false
      }
    },

    methods: {
      hide () {
        this.$refs.dropdown.visible = false;
      }
    }
  };
</script>

<style lang="scss" scoped>
    ::v-deep {
        .el-dropdown {
            &-menu__item {
                @apply hover:bg-gray-200 hover:text-current;
            }
        }
    }
</style>
