import { Audit } from "@core/store/logic/admin/audit/Audit";
// import { /*Advertisers,*/ OfferResponse } from "@core/store/types/admin/offer/OfferCommon";
import { AuditResponse } from "@core/store/types/admin/audit";

export class AdminOffer extends Audit {
  /*public advertisers?: Nullable<Advertisers>;*/
  
  constructor (payload?: AuditResponse) {
    super(payload);
    /*    if (payload) {
      console.log(payload);
      this.advertisers = payload.advertisers;
    }*/
  }
}
