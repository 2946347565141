<template>
    <form
        v-if="localUser"
        class="form"
        @submit.prevent.stop="updateUser">
        <b-notification
            :closable="false"
            has-icon
            type="is-info">
            <i18n
                path="common.auth.signUp.message"
                tag="span">
                <template #telegram>
                    <a
                        href="https://t.me/Natalya_Di"
                        rel="noopener">@Natalya_Di</a>
                </template>
            </i18n>
        </b-notification>
        <AdvertiserProfile :user.sync="localUser">
        </AdvertiserProfile>
        <b-button
            :loading="isLoading"
            class="is-success mt-6"
            expanded
            native-type="submit"
            rounded>
            {{ $t(`advertiser.profile.values.save`) }}
        </b-button>
    </form>
    <b-loading
        v-else
        :active="true"
        :is-full-page="false"></b-loading>
</template>

<script>
  import AdvertiserProfile from "@/components/Common/Advertiser/AdvertiserProfile";
  import { moment } from "@core/filters";
  import { Advertiser } from "@core/logic/user/model";
  import { EDIT_USER } from "@core/store/action-constants";
  import { SET_EMPTY, UPDATE_ADVERTISER_FORM } from "@core/store/mutation-constants";
  import _cloneDeep from "lodash/cloneDeep";
  import { mapActions, mapMutations, mapState } from "vuex";

  export default {
    name: "SignUpDetailAdvertiser",
    components: { AdvertiserProfile },

    data () {
      return {
        localUser: new Advertiser()
      };
    },

    computed: {
      ...mapState("advertiser/profile", ["user", "form"]),

      registeredDate () {
        return moment(this.localUser.activity.registeredAt);
      },

      isLoading () {
        return this.$wait(`advertiser/profile/${ EDIT_USER }`);
      }
    },

    methods: {
      ...mapActions("advertiser/profile", [
        EDIT_USER
      ]),

      ...mapMutations("advertiser/profile", [
        UPDATE_ADVERTISER_FORM,
        SET_EMPTY
      ]),

      async updateUser () {
        try {
          await this.EDIT_USER();

          this.$buefy.toast.open({
            message: this.$t("advertiser.profile.saveProfileSuccessConfirmation"),
            type: "is-success"
          });

          this.SET_EMPTY();
        } catch (e) {
          this.$buefy.toast.open({
            message: this.$t("advertiser.profile.saveProfileErrorConfirmation"),
            type: "is-danger"
          });
        }
      },

      convertUserToForm () {
        const { login, geo, sizeLeads, contact, comment, timeZone: { alias: timeZone } } = this.user;

        const form = {
          ...contact,
          login,
          geo,
          sizeLeads,
          timeZone,
          comment
        };

        this.UPDATE_ADVERTISER_FORM(form);
        this.localUser = _cloneDeep(this.form);
      }
    },

    watch: {
      user () {
        this.convertUserToForm();
      },

      localUser: {
        deep: true,
        handler (value) {
          this.UPDATE_ADVERTISER_FORM(value);
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
    form {
        max-width: 800px;
    }
</style>
