import LInput from "@/components/Common/LInput.vue";
// @ts-ignore
import VueEasyTinyMCE from "../node_modules/vue-easy-tinymce";

import { i18n, Loader, Sentry } from "@core/plugins";
import { ElementUI } from "@/plugins";
import "@core/plugins/vueTooltip";
import router from "@/router";
import store from "@/store";

import {
  FETCH_USER,
  GET_CURRENCIES,
  GET_TIMEZONES,
  GET_TOKEN,
  GET_USER_ROLE,
  UPDATE_LOCALE
} from "@core/store/action-constants";

import "tailwindcss/tailwind.css";
import Vue, { CreateElement, VNode } from "vue";
import { mapActions } from "vuex";
// @ts-ignore
import VueKinesis from "vue-kinesis";

import "vue-easytable/libs/theme-default/index.css";
// @ts-ignore
import VueEasytable from "vue-easytable";
// import Buefy from "buefy";
// import "buefy/dist/buefy.css";

// @ts-ignore
import { VeTable, VePagination, VeIcon, VeLoading, VeLocale } from "vue-easytable";

import App from "./App.vue";

// @ts-ignore
import "@core/filters";
// @ts-ignore
import "@core/mixins/global";
// @ts-ignore
import locale from "element-ui/lib/locale/lang/en.js";

// Vue.use(Buefy);

Vue.use(VueEasytable, VePagination);
Vue.use(ElementUI, { locale });
Vue.use(Loader, { store });
Vue.use(VeTable);
Vue.use(VePagination);
Vue.use(VeIcon);
Vue.use(VeLoading);
Vue.use(VueKinesis);

Vue.prototype.$veLoading = VeLoading;
Vue.prototype.$veLocale = VeLocale;

// global components
// @ts-ignore
Vue.component("LInput", LInput);
Vue.component("Tinymce", VueEasyTinyMCE);

if (process.env.VUE_APP_SENTRY_ON === "true") {
  Sentry();
}

Vue.config.productionTip = false;

const app = new Vue({
  store,
  router,
  i18n,
  render: (h: CreateElement): VNode => h(App),
  created (): void {
    const routerName = this.$route.name || "";
    const lang = localStorage.getItem("locale") || "en".toLocaleLowerCase().slice(0, 2);
    
    this.UPDATE_LOCALE(lang);

    this.GET_TIMEZONES();
    this.GET_CURRENCIES();


    this.GET_TOKEN().then(() => {
      if (store.getters.isTokenSet) {
        this.GET_USER_ROLE().then(({ isBlocked }) => {
          if (!isBlocked) {
            this.FETCH_USER();

            if (store.getters.isActive) {
              if (routerName === "auth:signUpProfile") {
                return this.$router.push({ name: "dashboard" });
              }
            }

            if (this.$store.state.auth.isConfirmed && !this.$store.state.auth.isApproved) {
              if (routerName !== "auth:signUpProfile") {
                return this.$router.push({ name: "auth:signUpProfile" });
              }
            } else if (!this.$store.state.auth.isConfirmed && !this.$store.state.auth.isApproved) {
              if (routerName !== "mail:send") {
                return this.$router.push({ name: "mail:send" });
              }
            }

            if (!this.$store.state.auth.isConfirmed) {
              // Snackbar.open({
              //   type: "is-warning",
              //   message: this.$t("common.mailConfirmation.unconfirmed.title") as string,
              //   indefinite: true,
              //   position: "is-bottom-left"
              // });
            }
          }
        });
      } else {
        if (!this.$route.name?.startsWith("auth:")) {
          return this.$router.push({ name: "auth:signIn" });
        }
      }
    });
  },

  methods: {
    ...mapActions([
      GET_TOKEN,
      GET_USER_ROLE,
      UPDATE_LOCALE,
      GET_CURRENCIES,
      GET_TIMEZONES,
      FETCH_USER
    ])
  }
});

app.$mount("#app");

export default app;
