import { GqlPostback, StatusesNames } from "@core/store/types/webmaster/postbacks/postback";

const statusesArr: Array<StatusesNames> = [
  "processingStatus",
  "acceptStatus",
  "spamStatus",
  "cancelStatus",
  "paidStatus"
];

class ModifedStatus {
  name: StatusesNames;
  isActive = false;
  value?: string;

  constructor (name: StatusesNames, value?: string, isActive = false) {
    this.isActive = isActive;
    this.name = name;
    this.value = value;
  }
}

export class ModifedPostback {
  statuses: Array<ModifedStatus> = []
  id?: string;
  url?: string;
  offerId?: string;
  isSendDuplicates?: boolean;

  constructor (postback?: GqlPostback) {
    this.statuses = statusesArr.map(statusName => {
      const isActive = !!postback?.statuses[statusName];
      const value = postback?.statuses[statusName];

      return new ModifedStatus(statusName, value, isActive);
    });

    this.id = postback?.id;
    this.offerId = postback?.offer?.id;
    this.url = postback?.url;
    this.isSendDuplicates = false;
  }
}

class InputPb {
  url = "";
  processingStatus?: string;
  acceptStatus?: string;
  spamStatus?: string;
  cancelStatus?: string;
  paidStatus?: string;
  isSendDuplicates?: boolean;

  constructor (postback: ModifedPostback) {
    this.url = postback.url as string;
    this.isSendDuplicates = false as boolean;
    postback.statuses
      .filter(status => status.isActive)
      .forEach(status => {
        this[status.name] = status.value;
      });
  }
}

export class InputPbCreate extends InputPb {
  offerId?: string;

  constructor (postback: ModifedPostback, offerId?: string) {
    super(postback);
    this.offerId = offerId;
  }
}

export class InputPbEdit extends InputPb {
  id = "";

  constructor (postback: ModifedPostback) {
    super(postback);
    this.id = postback.id as string;
  }
}
