import { offersList } from "@core/store/modules/admin/lists/offersList";
import { Module } from "vuex";
import { WebmastersRatesState } from "@core/store/types/admin/users/webmasters/Detail";
import { RootState } from "@core/store/root-state";

export const rates: Module<WebmastersRatesState, RootState> = {
  namespaced: true,
  modules: {
    offersList
  }
};