import { ASTGeneratedOutput, Generator } from "@core/services/common/Generator";
import { OffersFilters, OffersState } from "@core/store/types/admin/offers";

export default function offers (
  filters: OffersFilters,
  limit: number,
  offset: number,
  sort: OffersState["sort"],
  order: OffersState["order"],
  hasPermissionAdvertisers: boolean
): ASTGeneratedOutput {
  const money = ["value","currency"];
  const params = {
    exclude: !hasPermissionAdvertisers ? [
      "items.advertisers.items.writeOff",
      "items.advertisers.items.advertiser.id",
      "items.advertisers.items.advertiser.intId",
      "items.advertisers.items.advertiser.login",
      "items.advertisers.items.individualRates.items.writeOff"
    ] : []
  };

  const gqlObject = {
    operation: "offers",
    
    fields: [
      {
        items: [
          "id",
          "intId",
          "isLaunched",
          "createdAt",
          "type",
          {
            averageCommission: money
          },
          {
            minimalIndividualRate: money
          },
          {
            maximalIndividualRate: money
          },
          {
            minimalCommission: money
          },
          {
            minimalCommission: money
          },
          {
            minimalCommission: money
          },
          "minimalIndividualRate",
          "maximalIndividualRate",
          "minimalCommission",
          "isNewCreated",
          {
            landing: [
              "cost"
            ]
          },
          {
            logo: [
              "url"
            ]
          },
          {
            detail: [
              "name"
            ]
          },
          {
            countries: [
              "id",
              "name",
              "alpha2"
            ]
          },
          {
            categories: [
              "id",
              "name"
            ]
          },
          {
            finance: [
              "withdrawType",
              {
                webmasterReward: money
              }
            ]
          },
          {
            advertisers: [
              {
                items: [
                  {
                    writeOff: money
                  },
                  {
                    advertiser: ["id", "intId", "login"]
                  },
                  {
                    individualRates: [
                      {
                        items: [
                          {
                            writeOff: money
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      "count"
    ],
    
    variables: {
      filters: {
        type: "OfferListQueryFiltersRegistry",
        value: filters
      },
      limit: {
        type: "Int",
        value: limit
      },
      offset: {
        type: "Int",
        value: offset
      },
      sort: {
        type: "OfferSort",
        value: sort
      },
      order: {
        type: "SortOrder",
        value: order
      }
    }

  };

  return Generator.query(gqlObject, params);
}

