import { OfferLandingsService } from "@core/services/admin/offer/OfferLandingsService";
import { GET_OFFER_LANDINGS, GET_OFFER_LANDINGS_READY } from "@core/store/action-constants";
import {
  SET_OFFER_LANDINGS,
  SET_OFFER_LANDINGS_ID,
  SET_OFFER_LANDINGS_READY,
  UPDATE_FILTERS,
  REMOVE_PAGE,
  UPDATE_OFFER_LANDINGS,
  UPDATE_PAGINATION
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { OfferLandingsFilters, OfferLandingsState } from "@core/store/types/admin/offer/OfferLandings";
import { LandingPage } from "@core/store/types/common";
import { ActionTree, Module, MutationTree } from "vuex";

export class OfferLandingsList implements Module<OfferLandingsState, RootState> {
  public namespaced: boolean;
  public state: OfferLandingsState | undefined;
  public mutations: MutationTree<OfferLandingsState> = {
    [SET_OFFER_LANDINGS_ID] (state, id) {
      state.offerId = id;
    },
    
    [SET_OFFER_LANDINGS] (state, landings) {
      state.landings = landings;
    },
    
    [UPDATE_OFFER_LANDINGS] (state, payload) {
      state.landings = {
        count: payload.count,
        items: state.landings?.items?.concat(payload.items as LandingPage[])
      };
    },
    
    [UPDATE_PAGINATION] (state: OfferLandingsState, payload: any) {
      state.pagination = { ...state.pagination, ...payload };
    },
    
    [UPDATE_FILTERS] (state, filters) {
      state.filters = { ...state.filters, ...filters };
    },
  
    [SET_OFFER_LANDINGS_READY] (state, landingsReady) {
      state.landingsReady = landingsReady;
    }
  };
  public actions: ActionTree<OfferLandingsState, RootState> = {
    async [GET_OFFER_LANDINGS] ({
      state,
      commit
    }, offerId) {
      const limit = state.pagination.perPage;
      const offset = (state.pagination.page - 1) * state.pagination.perPage;
      const { pageType, ...fields } = state.filters;
      const filters = {
        ...fields,
        pageType: pageType?.toUpperCase()
      };
      
      if (offerId) {
        const { data: { landingPages } } = await OfferLandingsService.getLandings({
          offerId,
          filters,
          limit,
          offset
        });
        
        if (offset === 0) {
          commit(SET_OFFER_LANDINGS, landingPages);
        } else {
          commit(UPDATE_OFFER_LANDINGS, landingPages);
        }
      }
     
    },
    
    [UPDATE_FILTERS] ({ commit }, filter) {
      commit(UPDATE_FILTERS, filter);
    },
  
    [UPDATE_PAGINATION] ({ commit }, pagination) {
      commit(UPDATE_PAGINATION, pagination);
    },
  
    async [GET_OFFER_LANDINGS_READY] ({ commit }, offerId) {
      if (offerId) {
        const filters = {
          pageType: "LANDING" as OfferLandingsFilters["pageType"],
          isReady: true as OfferLandingsFilters["isReady"]
        };
        const { data: { landingPages } } = await OfferLandingsService.getLandings({
          offerId,
          filters
        });
        
        commit(SET_OFFER_LANDINGS_READY, landingPages);
      }
    },
  
    async [REMOVE_PAGE] (_, id) {
      await OfferLandingsService.removePage(id);
    }
  };
  
  private readonly initState: () => OfferLandingsState;
  
  constructor (pageType: OfferLandingsFilters["pageType"], isReady?: OfferLandingsFilters["isReady"]) {
    this.namespaced = true;
    this.initState = (): OfferLandingsState => {
      return {
        offerId: null,
        landings: {
          items: [],
          count: 0
        },
        landingsReady: {
          items: [],
          count: 0
        },
        pagination: {
          perPage: 25,
          page: 1
        },
        filters: {
          pageType,
          isReady
        }
      };
    };
    this.state = this.initState();
    
    this.mutations.SET_EMPTY = (state): OfferLandingsState => Object.assign(state, this.initState());
    
    return this;
  }
}
