import finances from "@/router/webmaster/finance";
import flows from "@/router/webmaster/flows";
import offers from "@/router/webmaster/offers";
import profile from "@/router/webmaster/profile";
import statistic from "@/router/webmaster/statistic";
import domains from "@/router/webmaster/domains";
import support from "@/router/webmaster/support";
import { RouteConfig } from "vue-router/types/router";

import Full from "@/views/Full.vue";

export const webmaster: RouteConfig = {
  path: "/webmaster",
  name: "webmaster",
  component: Full,
  children: [
    ...statistic,
    ...offers,
    ...flows,
    ...finances,
    // ...support,
    ...profile,
    ...domains
  ]
};
