import { OfferEditWebmastersServices } from "@core/services/admin/offer/edit/OfferEditWebmastersServices";
import {
  ADD_OFFER_WEBMASTERS_AVAILABLE,
  GET_AVAILABLE_WEBMASTERS_BY_ID, GET_OFFER_ID, GET_OFFER_WEBMASTERS_REWARD,
  GET_SELECTED_WEBMASTERS,
  UPDATE_MINIMAL_ALLOWED_ADVERTISER_WRITE_OFF,
  UPDATE_SELECTED_WEBMASTERS
} from "@core/store/action-constants";
import { offerEditWebmastersAvailableList } from "@core/store/modules/admin/offer/lists/offerEditWebmastersAvailableList";
import { UPDATE_MODAL_ACTIVE, UPDATE_SELECTED_WEBMASTERS_IDS } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { SelectedWebmaster } from "@core/store/types/admin/offer/OfferWebmasters.d";
import { OfferWebmastersModalState } from "@core/store/types/admin/offer/OfferWebmastersModal.d";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

const initialState = (): OfferWebmastersModalState => {
  return {
    selectWebmastersIds: [],
    selectedWebmasters: {},
    isWebmastersModalActive: false
  };
};

const state: () => OfferWebmastersModalState = initialState;

const getters: GetterTree<OfferWebmastersModalState, RootState> = {
  [GET_SELECTED_WEBMASTERS]: state => Object.values(state.selectedWebmasters)
};

const mutations: MutationTree<OfferWebmastersModalState> = {
  SET_EMPTY: state => Object.assign(state, initialState()),

  [UPDATE_MODAL_ACTIVE] (state, payload: boolean) {
    state.isWebmastersModalActive = payload;
  },

  [UPDATE_SELECTED_WEBMASTERS] (state, webmasters: OfferWebmastersModalState["selectedWebmasters"]) {
    state.selectedWebmasters = webmasters;
  },

  [UPDATE_SELECTED_WEBMASTERS_IDS] (state, ids: string[]) {
    state.selectWebmastersIds = ids;
  }
};

const actions: ActionTree<OfferWebmastersModalState, RootState> = {
  async [ADD_OFFER_WEBMASTERS_AVAILABLE] ({ state, rootGetters, dispatch }) {
    const offerId = rootGetters[`admin/offer/edit/common/${ GET_OFFER_ID }`];
    try {
      if (offerId) {
        const webmasters = Object.values(state.selectedWebmasters).map((item: SelectedWebmaster) => {
          const { id, rate: webmasterRate, withdrawType, holdTime, softCapacity } = item;
          const values = { rate: webmasterRate, withdrawType, holdTime, softCapacity };
          const rate = Object.values(values).every(el => el == null) ? null : {
            rate: Number(item.rate) || null,
            withdrawType: item.withdrawType || null,
            holdTime: isNaN(item.holdTime) ? null : item.holdTime,
            softCapacity: Number(item.softCapacity) || null
          };
          return {
            id,
            rate
          };
        });
        if (offerId) {
          const {
            data: {
              offerIncludeMultipleWebmasters
            }
          } = await OfferEditWebmastersServices.offerIncludeMultipleWebmasters(offerId, webmasters);
          
          dispatch(
            `admin/offer/edit/offerEditAdvertisers/${ UPDATE_MINIMAL_ALLOWED_ADVERTISER_WRITE_OFF }`,
            { offerId },
            { root: true }
          );
          dispatch(`admin/offer/edit/advertisersExternalIndividualRates/${ GET_OFFER_WEBMASTERS_REWARD }`,
            { offerId: offerId }, { root: true });
          dispatch(`admin/offer/edit/advertisersInternalIndividualRates/${ GET_OFFER_WEBMASTERS_REWARD }`,
            { offerId: offerId }, { root: true });
          return offerIncludeMultipleWebmasters;
        }
      }
    } catch (e) {
      throw e;
    }
  },

  [UPDATE_SELECTED_WEBMASTERS] ({ getters, state, commit }, ids) {
    commit(UPDATE_SELECTED_WEBMASTERS_IDS, ids);
    const newWebmasters: { [key: string]: unknown } = {};
    for (const id of state.selectWebmastersIds) {
      if (state.selectedWebmasters[id]) {
        newWebmasters[id] = state.selectedWebmasters[id];
      } else {
        newWebmasters[id] =
                    JSON.parse(
                      JSON.stringify(
                        getters[`offerEditWebmastersAvailableList/${ GET_AVAILABLE_WEBMASTERS_BY_ID }`]([id])[0]
                      )
                    );
      }
    }
    commit(UPDATE_SELECTED_WEBMASTERS, newWebmasters);
  },

  [UPDATE_MODAL_ACTIVE] ({ commit }, payload: boolean) {
    if (payload) {
      commit("SET_EMPTY");
      commit("offerEditWebmastersAvailableList/SET_EMPTY");
    }
    commit(UPDATE_MODAL_ACTIVE, payload);
  }
};

export const offerEditWebmastersModal: Module<OfferWebmastersModalState, RootState> = {
  namespaced: true,
  modules: {
    offerEditWebmastersAvailableList
  },
  getters,
  state,
  mutations,
  actions
};
