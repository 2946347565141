import { AbstractOffer } from "@core/store/logic/common/offer/AbstractOffer";
import { OfferResponseInterface } from "@core/store/types/common/offer/offer";
import { Money } from "@core/store/types/common";

export class EditableOffer extends AbstractOffer {
  public minimalCommission?: Money["value"];
  public currency?: Money["currency"];
  public webmasterReward?: Money["value"];
  public totalSoftCapacity?: number;
  public advertiserMinimalCommission?: Money;
  
  constructor (payload?: OfferResponseInterface) {
    super(payload);
    if (payload) {
      this.landingParameters = payload.landingParameters ?? ["name", "phone"];
      this.categories = payload.categories?.map(type => type.id) as OfferResponseInterface["categories"];
      this.trafficTypes = payload.trafficTypes?.map(type => type.id) as OfferResponseInterface["trafficTypes"];
      this.countries = payload.countries?.map(type => type.id) as OfferResponseInterface["countries"];
      this.minimalCommission = payload.minimalCommission?.value;
      this.currency = payload.finance?.currency;
      this.webmasterReward = payload.finance?.webmasterReward?.value;
      this.totalSoftCapacity = payload.totalSoftCapacity;
      this.advertiserMinimalCommission = payload.advertiserMinimalCommission;
    }
  }
}

