<template>
    <el-alert
        v-bind="$attrs"
        :type="type"
        :effect="effect">
        <template #title>
            <slot></slot>
        </template>
        <template>
            <slot name="description"></slot>
        </template>
    </el-alert>
</template>

<script>
  export default {
    name: "FAlert",
    props: {
      type: {
        type: String,
        default: "error"
      },

      effect: {
        type: String,
        default: "light"
      }
    }
  };
</script>

<style scoped>

</style>