import i18n from "@core/plugins/i18n";
import { GET_BLOCKED } from "@core/store/action-constants";
import { UPDATE_BLOCKED_LIST } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { BlockedListState } from "@core/store/types/admin/lists/BlockedListState";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

const initialState = (): BlockedListState => {
  return {
    data: {}
  };
};

const state: () => BlockedListState = initialState;

const getters: GetterTree<BlockedListState, RootState> = {};

const mutations: MutationTree<BlockedListState> = {
  [UPDATE_BLOCKED_LIST] (state, blocked: BlockedListState["data"]) {
    state.data = blocked;
  }
};

const actions: ActionTree<BlockedListState, RootState> = {
  [GET_BLOCKED] ({ commit }) {
    const items = [
      {
        label: i18n.t("admin.users.webmasters.filters.values.blocked"),
        value: true
      },
      {
        label: i18n.t("admin.users.webmasters.filters.values.notBlocked"),
        value: false
      }
    ];
    commit(UPDATE_BLOCKED_LIST, {
      items,
      count: items.length
    });
  }
};

export const blockedList: Module<BlockedListState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
