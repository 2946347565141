import { statistic } from "@core/store/modules/webmaster/dashboard/statistic";
import { competition } from "@core/store/modules/webmaster/dashboard/competition";
import { offersList } from "@core/store/modules/admin/lists/offersList";
import { webmastersList } from "@core/store/modules/admin/lists/webmastersList";
import { adminsList } from "@core/store/modules/admin/lists/adminsList";
import { advertisersList } from "@core/store/modules/admin/lists/advertisersList";
import { DashboardTopCard } from "@core/store/modules/admin/dashboard/modules/dashboardTopCard";

export const dashboard = {
  namespaced: true,
  modules: {
    statistic,
    offersList,
    webmastersList,
    adminsList,
    advertisersList,
    competition,
    advertisersTopCard: new DashboardTopCard("advertiser", {
      adminsList
    }),
    webmastersTopCard: new DashboardTopCard("webmaster",{
      adminsList
    }),
    offersTopCard: new DashboardTopCard("offer",{
      adminsList
    })
  }
};
