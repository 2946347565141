import _Vue from "vue";

((m, e, t, r, i, k, a): void => {
  // @ts-ignore
  m[i] = m[i] || ((...params): void => {
    // @ts-ignore
    (m[i].a = m[i].a || []).push(params);
  });
  
  // @ts-ignore
  m[i].l = 1 * new Date();
  
  // @ts-ignore
  k = e.createElement(t);
  // @ts-ignore
  a = e.getElementsByTagName(t)[0];
  // @ts-ignore
  k.async = 1;
  // @ts-ignore
  k.src = r;
  // @ts-ignore
  a.parentNode.insertBefore(k, a);
})(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

interface MetrikaOptions {
  id: string;
  disable: boolean;
  debug: boolean;
  options: {
    webvisor: boolean;
    clickmap: boolean;
    trackLinks: boolean;
    accurateTrackBounce: boolean;
  };
}

export default function install (Vue: typeof _Vue, options: MetrikaOptions): void {
  const {
    id,
    disable,
    debug
  } = options;
  
  if (!id || disable) {
    return;
  }
  
  // @ts-ignore
  ym(id, "init", options.options);
  
  // @ts-ignore
  Vue.prototype.$ym = {
    reachGoal: (eventName: string): void => {
      const eventType = "reachGoal";
      
      // @ts-ignore
      window.ym(id, eventType, eventName);
      
      if (debug) {
        // eslint-disable-next-line no-console
        console.log(`[DEBUG] ${ eventType }: ${ eventName }`);
      }
    },
    setUserId: (eventId: string): void => {
      const eventType = "setUserId";
      
      // @ts-ignore
      window.ym(id, eventType, eventId);
      
      if (debug) {
        // eslint-disable-next-line no-console
        console.log(`[DEBUG] ${ eventType }: ${ eventId }`);
      }
    }
  };
}
