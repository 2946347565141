import { GET_CORRECTION_TARGET, GET_FILTERED_CORRECTION } from "@core/store/action-constants";
import { UPDATE_CORRECTION_TARGET_LIST } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import {
  CorrectionTargetListItem,
  CorrectionTargetListState
} from "@core/store/types/admin/finances/financesCorrection/lists/CorrectionTargetListState";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

const initialState = (): CorrectionTargetListState => {
  return {
    data: {}
  };
};

const state: () => CorrectionTargetListState = initialState;

const getters: GetterTree<CorrectionTargetListState, RootState> = {
  [GET_FILTERED_CORRECTION]: (state) => function (correct: string): CorrectionTargetListState["data"] {
    const filteredItems = state.data.items?.filter((item: CorrectionTargetListItem) => item.value !== correct);
    return {
      items: filteredItems,
      count: filteredItems?.length
    };
  }
};

const mutations: MutationTree<CorrectionTargetListState> = {
  [UPDATE_CORRECTION_TARGET_LIST] (state, correctionTarget: CorrectionTargetListState["data"]) {
    state.data = correctionTarget;
  }
};

const actions: ActionTree<CorrectionTargetListState, RootState> = {
  [GET_CORRECTION_TARGET] ({ commit }) {
    const items = [
      {
        label: "admin.finances.correction.correctionTarget.partnerProgram",
        value: "CPA"
      },
      {
        label: "admin.finances.correction.correctionTarget.advertiser",
        value: "advertiser"
      },
      {
        label: "admin.finances.correction.correctionTarget.webmaster",
        value: "webmaster"
      }
    ];
    commit(UPDATE_CORRECTION_TARGET_LIST, {
      items,
      count: items.length
    });
  }
};

export const correctionTargetList: Module<CorrectionTargetListState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
