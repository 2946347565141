import { WebmastersListService } from "@core/services/admin/lists/WebmastersListService";
import {
  GET_INITIAL_WEBMASTERS,
  GET_WEBMASTER_BY_ID,
  GET_WEBMASTERS_BY_ID,
  GET_WEBMASTERS_LIST,
  UPDATE_WEBMASTERS_LIST
} from "@core/store/action-constants";
import {
  EMPTY_WEBMASTERS_LIST,
  SET_EMPTY,
  UPDATE_WEBMASTERS_FILTERS,
  UPDATE_WEBMASTERS_LIST_PAGINATION
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import {
  PartialWebmastersListState,
  WebmastersListItem,
  WebmastersListResponse
} from "@core/store/types/admin/lists/WebmastersListState";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

const initialState = (): PartialWebmastersListState => {
  return {
    data: {},
    filters: {
      id: null,
      loginOrIntId: null,
      webmastersByAdvertiser: null
    },
    pagination: {
      page: 1,
      perPage: 25
    }
  };
};

const state: () => PartialWebmastersListState = initialState;

const getters: GetterTree<PartialWebmastersListState, RootState> = {
  [GET_WEBMASTER_BY_ID]: state => function (id: string): WebmastersListItem | undefined {
    return state.data?.items?.find((item: { id: string }) => item.id === id);
  },
  
  [GET_WEBMASTERS_BY_ID]: state => function (ids: string[]): (WebmastersListItem | undefined)[] {
    return ids.map(id => state.data?.items?.find((item: WebmastersListItem) => item.id === id));
  }
};

const mutations: MutationTree<PartialWebmastersListState> = {
  [SET_EMPTY]: state => Object.assign(state, initialState()),
  
  [UPDATE_WEBMASTERS_LIST] (state, webmasters: WebmastersListResponse["webmasters"]) {
    if (state.pagination.page !== 1 && state.data?.items && webmasters) {
      state.data.items = Array.from(new Set([...state.data.items, ...webmasters.items as []]));
    } else {
      state.data = webmasters;
    }
  },
  
  [UPDATE_WEBMASTERS_LIST_PAGINATION] (state) {
    if (state.data?.items && state.data.items.length >= state.pagination.perPage) {
      state.pagination.page = Math.ceil(state.data.items.length / state.pagination.perPage) + 1;
    }
  },
  
  [UPDATE_WEBMASTERS_FILTERS] (state, filters: PartialWebmastersListState["filters"]) {
    if (filters?.loginOrIntId !== state.filters?.loginOrIntId) {
      state.pagination.page = 1;
    }
    state.filters = { ...state.filters, ...filters };
  },
  
  [EMPTY_WEBMASTERS_LIST] (state) {
    Object.assign(state, initialState());
  }
};

const actions: ActionTree<PartialWebmastersListState, RootState> = {
  async [GET_WEBMASTERS_LIST] ({ commit, state }) {
    const limit = state.pagination.perPage;
    const offset = (state.pagination.page - 1) * limit;
    
    const { data: { webmasters } } = await WebmastersListService.getWebmastersList({
      loginOrIntId: state.filters?.loginOrIntId,
      webmastersByAdvertiser: state.filters?.webmastersByAdvertiser
    }, limit, offset);
    
    commit(UPDATE_WEBMASTERS_LIST, webmasters);
    return webmasters;
  },
  
  async [GET_INITIAL_WEBMASTERS] ({ commit, state }, ids) {
    if (ids) {
      ids = Array.isArray(ids) ? ids : [ids];
    }
    const { data: { webmasters, initialWebmasters } } = await WebmastersListService.getWebmastersList(
      { webmastersByAdvertiser: state.filters?.webmastersByAdvertiser },
      state.pagination.perPage,
      0,
      { id: ids });
    webmasters.items = [...initialWebmasters.items, ...webmasters.items as []];
    
    commit(UPDATE_WEBMASTERS_LIST, webmasters);
    return initialWebmasters?.items?.length ? initialWebmasters.items.map(user => user.id) : null;
  }
};

export const webmastersList: Module<PartialWebmastersListState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
