import Service from "@core/services/common/Service";
import { AxiosPromise } from "axios";

type Role = "webmaster" | "advertiser" | "admin";

export interface Auth {
  refreshToken: string;
  login: string;
  self: boolean;
  userType: string;
  isConfirmed: boolean;
  isBlocked: boolean;
  subType: string;
  preferredCurrency: string;
  isApproved: boolean;
  isPartnerNetwork: boolean;
  token: string;
  expiresIn: number;
  intId: string;
}

export interface LoginAsWebmasterResponse {
  loginAsWebmaster: Auth;
}

export interface LoginAsAdvertiserResponse {
  loginAsAdvertiser: Auth;
}

export interface Login {
  login: string;
  password: string;
  captcha?: string;
}

export interface TelegramLogin {
  id: number;
  first_name: string;
  last_name: string;
  username: string;
  photo_url: string;
  auth_date: number;
  hash: string;
  captcha: string;
}

export interface Registration extends Login {
  email: string;
  userType: Role;
  managerId: string | null;
}

export interface RefreshToken {
  refreshToken: string;
}

export interface TokenResponse {
  expiresIn: number;
  token: string;
}

export class AuthService extends Service {
  public static registration (payload: Registration): Promise<any> {
    return this.rest.post("/signup", payload);
  }
  
  public static loginByPassword (data: Login): AxiosPromise<Auth> {
    return this.rest.post("/auth/login", data, {
      baseURL: process.env.VUE_APP_API_BASE_URL
    });
  }
  
  public static loginByTelegram (data: TelegramLogin): AxiosPromise<Auth> {
    return this.rest.post("/auth/login/telegram", data, {
      baseURL: process.env.VUE_APP_API_BASE_URL
    });
  }
  
  public static resetPassword (params: any): AxiosPromise<Auth> {
    return this.rest.post("/identity/forgot-password", params);
  }
  
  public static sendNewPassword (params: any): AxiosPromise<Auth> {
    return this.rest.post("/identity/reset-password", params);
  }
  
  public static loginAsWebmaster (webmasterId: string): AxiosPromise<Auth> {
    return this.rest.post(`/admin/login/webmasters/${ webmasterId }`);
  }
  
  public static loginAsAdvertiser (advertiserId: string): AxiosPromise<Auth> {
    return this.rest.post(`/admin/login/advertisers/${ advertiserId }`);
  }
  
  public static returnToAdmin (): AxiosPromise<Auth> {
    return this.rest.post("/admin/login/return");
  }
  
  public static refreshToken (params: RefreshToken): AxiosPromise<TokenResponse> {
    return this.rest.get("/auth/token/refresh", {
      baseURL: process.env.VUE_APP_API_BASE_URL,
      params
    });
  }
  
  public static whoAmI (): AxiosPromise<Auth> {
    return this.rest.get("/auth/token/refresh", {
      baseURL: process.env.VUE_APP_API_BASE_URL
    });
  }
}
