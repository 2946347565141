import { DictionariesService } from "@core/logic/admin/dictionaries/dictionaries";
import { FlowFilter } from "@core/logic/common/dictionaries/FlowFilter";
import { OfferFilter, OffersFilterList } from "@core/logic/common/dictionaries/OfferFilter";
import { UserFilter, UsersFilterList } from "@core/logic/common/dictionaries/UserFilter";
import { PaginationOutput } from "@core/logic/common/pagination-output";
import {
  SET_ADMINS_DICTIONARY,
  SET_ADVERTISERS_DICTIONARY,
  SET_EXECUTORS_DICTIONARY,
  SET_FLOWS_DICTIONARY,
  SET_OFFERS_DICTIONARY,
  SET_WEBMASTERS_DICTIONARY,
  UPDATE_ADMINS_DICTIONARY,
  UPDATE_ADVERTISERS_DICTIONARY,
  UPDATE_EXECUTORS_DICTIONARY,
  UPDATE_FLOWS_DICTIONARY,
  UPDATE_OFFERS_DICTIONARY,
  UPDATE_WEBMASTERS_DICTIONARY
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { ActionTree, Module, MutationTree } from "vuex";

interface Requisite {
  id: string;
  purse: string;
  type: string;
}

interface Requisites extends PaginationOutput {
  items: Requisite[];
}

export interface DictionariesState {
  requisites: Requisites | null;
  advertisers: UsersFilterList | null;
  webmasters: UsersFilterList | null;
  executors: UsersFilterList | null;
  admins: UsersFilterList | null;
  flows: OffersFilterList | null;
  offers: OffersFilterList | null;
}

const state: DictionariesState = {
  requisites: null,
  advertisers: null,
  webmasters: null,
  executors: null,
  admins: null,
  flows: null,
  offers: null
};

const mutations: MutationTree<DictionariesState> = {
  SET_REQUISITES (state, payload: Requisites) {
    state.requisites = payload;
  },
  
  [SET_FLOWS_DICTIONARY] (state, payload: OffersFilterList) {
    state.flows = payload;
  },
  
  [UPDATE_FLOWS_DICTIONARY] (state, payload: OffersFilterList) {
    const items = state.flows ? state.flows.items : [];
    
    state.flows = {
      count: payload.count ? items.length + payload.items.length : 0,
      items: items.concat(payload.items)
    };
  },
  
  [SET_WEBMASTERS_DICTIONARY] (state, payload: UsersFilterList) {
    state.webmasters = payload;
  },
  
  [UPDATE_WEBMASTERS_DICTIONARY] (state, payload: UsersFilterList) {
    const items = state.webmasters ? state.webmasters.items : [];

    state.webmasters = {
      count: payload.count,
      items: items.concat(payload.items)
    };
  },

  [SET_EXECUTORS_DICTIONARY] (state, payload: UsersFilterList) {
    state.executors = payload;
  },

  [UPDATE_EXECUTORS_DICTIONARY] (state, payload: UsersFilterList) {
    const items = state.executors ? state.executors.items : [];

    state.executors = {
      count: payload.count,
      items: items.concat(payload.items)
    };
  },
  
  [SET_ADVERTISERS_DICTIONARY] (state, payload: UsersFilterList) {
    state.advertisers = payload;
  },
  
  [UPDATE_ADVERTISERS_DICTIONARY] (state, payload: UsersFilterList) {
    const items = state.advertisers ? state.advertisers.items : [];
    
    state.advertisers = {
      count: payload.count,
      items: items.concat(payload.items)
    };
  },
  
  [SET_ADMINS_DICTIONARY] (state, payload: UsersFilterList) {
    state.admins = payload;
  },
  
  [UPDATE_ADMINS_DICTIONARY] (state, payload: UsersFilterList) {
    const items = state.admins ? state.admins.items : [];
    
    state.admins = {
      count: payload.count,
      items: items.concat(payload.items)
    };
  },
  
  SET_BUNCHES_DICTIONARY (state, payload: OffersFilterList) {
    state.offers = payload;
  },
  
  UPDATE_BUNCHES_DICTIONARY (state, payload: OffersFilterList) {
    const items = state.offers ? state.offers.items : [];
    
    state.offers = {
      count: payload.count,
      items: items.concat(payload.items)
    };
  },
  
  [SET_OFFERS_DICTIONARY] (state, payload: OffersFilterList) {
    state.offers = payload;
  },
  
  [UPDATE_OFFERS_DICTIONARY] (state, payload: OffersFilterList) {
    const items = state.offers ? state.offers.items : [];
    
    state.offers = {
      count: payload.count,
      items: items.concat(payload.items)
    };
  }
};

const actions: ActionTree<DictionariesState, RootState> = {
  async GET_REQUISITES ({ commit }, payload) {
    const { data } = await DictionariesService.getRequisites(payload);
    commit("SET_REQUISITES", data);
    
    return data;
  },
  
  async [UPDATE_FLOWS_DICTIONARY] ({ commit }, payload) {
    const { data } = await DictionariesService.getFlows(payload);
    data.items.forEach((item: any) => new FlowFilter(item));
    if (payload.offset === 0) {
      commit(SET_FLOWS_DICTIONARY, data);
    } else {
      commit(UPDATE_FLOWS_DICTIONARY, data);
    }
  
    return data;
  },
  
  async UPDATE_BUNCHES_DICTIONARY ({ commit }, payload): Promise<OffersFilterList> {
    const { data } = await DictionariesService.getBunches(payload);
    data.items.forEach((item: any) => new OfferFilter(item));
    if (payload.offset === 0) {
      commit(SET_OFFERS_DICTIONARY, data);
    } else {
      commit(UPDATE_OFFERS_DICTIONARY, data);
    }
    
    return data;
  },
  
  async [UPDATE_WEBMASTERS_DICTIONARY] ({ commit }, payload) {
    const { data } = await DictionariesService.getWebmasters(payload);
    data.items.forEach((item: any) => new UserFilter(item));
    if (payload.offset === 0) {
      commit(SET_WEBMASTERS_DICTIONARY, data);
    } else {
      commit(UPDATE_WEBMASTERS_DICTIONARY, data);
    }
    
    return data;
  },

  async [UPDATE_EXECUTORS_DICTIONARY] ({ commit }, payload) {
    const { limit, offset, filters } = payload;
    const { data: { inTransactionCreators } } = await DictionariesService.getExecutors(limit, offset, filters);
    
    inTransactionCreators.items.forEach((item: any) => new UserFilter(item));
    if (payload.offset === 0) {
      commit(SET_EXECUTORS_DICTIONARY, inTransactionCreators);
    } else {
      commit(UPDATE_EXECUTORS_DICTIONARY, inTransactionCreators);
    }
    
    return inTransactionCreators;
  },
  
  async [UPDATE_ADMINS_DICTIONARY] ({ commit }, payload) {
    const { data } = await DictionariesService.getAdmins(payload);
    data.items.forEach((item: any) => new OfferFilter(item));
    if (payload.offset === 0) {
      commit(SET_ADMINS_DICTIONARY, data);
    } else {
      commit(UPDATE_ADMINS_DICTIONARY, data);
    }
  
    return data;
  },
  
  async [UPDATE_ADVERTISERS_DICTIONARY] ({ commit }, payload) {
    const { data } = await DictionariesService.getAdvertisers(payload);
    data.items.forEach((item: any) => new UserFilter(item));
    if (payload.offset === 0) {
      commit(SET_ADVERTISERS_DICTIONARY, data);
    } else {
      commit(UPDATE_ADVERTISERS_DICTIONARY, data);
    }
  
    return data;
  },
  
  async [UPDATE_OFFERS_DICTIONARY] ({ commit }, payload) {
    const { data } = await DictionariesService.getOffers(payload);
    data.items.forEach((item: any) => new OfferFilter(item));
    if (payload.offset === 0) {
      commit(SET_OFFERS_DICTIONARY, data);
    } else {
      commit(UPDATE_OFFERS_DICTIONARY, data);
    }
  
    return data;
  }
};

export const dictionaries: Module<DictionariesState, RootState> = {
  state,
  actions,
  mutations
};
