import { RootState } from "@core/store/root-state";
import { State } from "@core/store/types/admin/offers/webmastersManagement";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import {
  GET_SELECTED_WEBMASTERS,
  GET_WEBMASTERS_BY_ID,
  UPDATE_SELECTED_WEBMASTERS
} from "@core/store/action-constants";
import {
  UPDATE_MODAL_ACTIVE,
  UPDATE_SELECTED_WEBMASTERS_IDS,
  INCLUDE_WEBMASTERS_OFFERS,
  EXCLUDE_WEBMASTERS_FORM_OFFERS,
  SET_EMPTY
} from "@core/store/mutation-constants";
import { webmastersList } from "@core/store/modules/admin/lists/webmastersList";
import { OffersService } from "@core/services/admin/offers/offers";
import { Offer } from "@core/store/types/admin/offers";

const initialState = (): State => {
  return {
    selectedWebmastersIds: [],
    selectedWebmasters: {},
    isActiveModal: false
  };
};

const state: () => State = initialState;

const getters: GetterTree<State, RootState> = {
  [GET_SELECTED_WEBMASTERS]: state => Object.values(state.selectedWebmasters)
};

const mutations: MutationTree<State> = {
  [UPDATE_MODAL_ACTIVE] (state, payload: boolean) {
    state.isActiveModal = payload;
  },
  
  [UPDATE_SELECTED_WEBMASTERS] (state, webmasters) {
    state.selectedWebmasters = webmasters;
  },
  
  [UPDATE_SELECTED_WEBMASTERS_IDS] (state, ids: string[]) {
    state.selectedWebmastersIds = ids;
  },
  
  [SET_EMPTY] (state) {
    Object.assign(state, initialState());
  }
};

const actions: ActionTree<State, RootState> = {
  [UPDATE_SELECTED_WEBMASTERS] ({ getters, state, commit }, ids) {
    commit(UPDATE_SELECTED_WEBMASTERS_IDS, ids);
    const newWebmasters: { [key: string]: unknown } = {};
    for (const id of state.selectedWebmastersIds) {
      if (state.selectedWebmasters[id]) {
        newWebmasters[id] = state.selectedWebmasters[id];
      } else {
        newWebmasters[id] =
          JSON.parse(JSON.stringify(
            getters[`webmastersList/${ GET_WEBMASTERS_BY_ID }`]([id])[0]));
      }
    }
    commit(UPDATE_SELECTED_WEBMASTERS, newWebmasters);
  },
  
  [UPDATE_MODAL_ACTIVE] ({ commit }, payload: boolean) {
    commit(UPDATE_MODAL_ACTIVE, payload);
  },
  
  async [INCLUDE_WEBMASTERS_OFFERS] ({ state, rootState }) {
    const webmasterIds = state.selectedWebmastersIds;
    const offerIds = rootState.admin.offers.selectedOffers.map((val: Offer) => val.id);
    await OffersService.includeWebmastersToOffers(offerIds, webmasterIds);
  },
  
  async [EXCLUDE_WEBMASTERS_FORM_OFFERS] ({ state, rootState }) {
    const webmasterIds = state.selectedWebmastersIds;
    const offerIds = rootState.admin.offers.selectedOffers.map((val: Offer) => val.id);
    await OffersService.excludeWebmastersFromOffers(offerIds, webmasterIds);
  }
};

export const webmastersManagementModal: Module<State, RootState> = {
  namespaced: true,
  actions,
  state,
  mutations,
  getters,
  modules: {
    webmastersList
  }
};
