import { PaginationOutput } from "@core/logic/common/pagination-output";
import { PaginationParams } from "@core/logic/common/pagination-params";
import { Advertiser } from "@core/store/types/admin/users/advertisers/Edit";
import { EditInput as WebmasterInput, Webmaster } from "@core/store/types/admin/users/webmasters/Edit";
import { User as Administrator } from "@core/store/types/common/User";
import { AxiosPromise, AxiosResponse } from "axios";
import Service from "@core/services/common/Service";

export { Advertiser, Webmaster, Administrator };

export interface Webmasters extends PaginationOutput {
  items: Webmaster[];
}

export interface Advertisers extends PaginationOutput {
  items: Advertiser[];
}

export interface GetWebmastersParams extends PaginationParams {
  isApproved: boolean;
  isPartnerNetwork: boolean;
}

export interface GetAdvertisersParams extends PaginationParams {
  isApproved: boolean;
}

export interface HistoryAction {
  action: string;
  admin: string | null;
  createdAt: string;
}

export interface HistoryActions extends PaginationOutput {
  items: HistoryAction[];
}

class UsersService extends Service{
  // TODO: почистить неиспользуемые методы
  public static getWebmasters (params: GetWebmastersParams): Promise<AxiosResponse<Webmasters>> {
    return this.rest.get("/admin/webmasters", { params });
  }
  
  public static getAdvertisers (params: GetAdvertisersParams): Promise<AxiosResponse<Advertisers>> {
    return this.rest.get("/admin/advertisers", { params });
  }
  
  public static approvedWebmaster (webmasterId: string): Promise<AxiosResponse<Webmaster>> {
    return this.rest.put(`/admin/webmasters/${ webmasterId }/approve`);
  }

  public static approvedAdvertiser (advertiserId: string): Promise<AxiosResponse<Advertiser>> {
    return this.rest.put(`/admin/advertisers/${ advertiserId }/approve`);
  }

  public static bindWebmasterPersonalManager (webmasterId: string, params: any): Promise<AxiosResponse<Webmaster>> {
    return this.rest.put(`/admin/webmasters/${ webmasterId }/bind`, { ...params });
  }

  public static unBindWebmasterPersonalManager (webmasterId: string, params: any): Promise<AxiosResponse<Webmaster>> {
    return this.rest.put(`/admin/webmasters/${ webmasterId }/bind`, { ...params });
  }
  
  public static bindAdvertiserPersonalManager (advertiserId: string, params: any): Promise<AxiosResponse<Advertiser>> {
    return this.rest.put(`/admin/advertisers/${ advertiserId }/bind`, { ...params });
  }

  public static unBindAdvertiserPersonalManager (advertiserId: string, params: any): Promise<AxiosResponse<Advertiser>> {
    return this.rest.put(`/admin/advertisers/${ advertiserId }/bind`, { ...params });
  }

  public static blockAdvertiser (advertiserId: string): Promise<AxiosResponse<Advertiser>> {
    return this.rest.put(`/admin/advertisers/${ advertiserId }/block`);
  }

  public static unBlockAdvertiser (advertiserId: string): Promise<AxiosResponse<Advertiser>> {
    return this.rest.put(`/admin/advertisers/${ advertiserId }/unblock`);
  }
  
  public static blockWebmaster (webmasterId: string): Promise<AxiosResponse<Webmaster>> {
    return this.rest.put(`/admin/webmasters/${ webmasterId }/block`);
  }
  
  public static unBlockWebmaster (webmasterId: string): Promise<AxiosResponse<Webmaster>> {
    return this.rest.put(`/admin/webmasters/${ webmasterId }/unblock`);
  }
  
  public static enableWebmasterPartnerNetwork (webmasterId: string): Promise<AxiosResponse<Webmaster>> {
    return this.rest.put(`/admin/webmasters/${ webmasterId }/enable-partner-network`);
  }
  
  public static disableWebmasterPartnerNetwork (webmasterId: string): Promise<AxiosResponse<Webmaster>> {
    return this.rest.put(`/admin/webmasters/${ webmasterId }/disable-partner-network`);
  }
  
  public static enableWebmasterPublicApi (webmasterId: string): Promise<AxiosResponse<Webmaster>> {
    return this.rest.put(`/admin/webmasters/${ webmasterId }/enable-access-to-api`);
  }
  
  public static disableWebmasterPublicApi (webmasterId: string): Promise<AxiosResponse<Webmaster>> {
    return this.rest.put(`/admin/webmasters/${ webmasterId }/disable-access-to-api`);
  }
  
  public static getHistoryWebmaster (webmasterId: string): Promise<AxiosResponse<HistoryActions>> {
    return this.rest.get(`/admin/webmasters/${ webmasterId }/histories`);
  }
  
  public static getHistoryAdvertiser (advertiserId: string): Promise<AxiosResponse<HistoryActions>> {
    return this.rest.get(`/admin/advertisers/${ advertiserId }/histories`);
  }
  
  public static getAdvertiser (advertiserId: string): AxiosPromise<Advertiser> {
    return this.rest.get(`/admin/advertisers/${ advertiserId }`);
  }
  
  public static getWebmaster (webmasterId: string): AxiosPromise<Webmaster> {
    return this.rest.get(`/admin/webmasters/${ webmasterId }`);
  }
  
  public static editWebmaster (webmasterId: string, params: WebmasterInput): AxiosPromise<Administrator> {
    return this.rest.put(`/admin/webmaster/${ webmasterId }`, params);
  }
  
  public static getUserBalance (userId: string): Promise<AxiosResponse<any>> {
    return this.rest.get(`/admin/users/${ userId }/balance`);
  }
}

export default UsersService;
