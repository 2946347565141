import { TrafficTypesService } from "@/services/common/trafficTypesService";
import { TrafficTypesState } from "@/store/types/common/TrafficTypesState";
import { ActionTree, Module, MutationTree } from "vuex";
import { GET_TRAFFIC_TYPES } from "../../action-constants";
import { SET_TRAFFIC_TYPES } from "../../mutation-constants";
import { RootState } from "../../root-state";

const state: TrafficTypesState = {
  trafficTypes: []
};

const mutations: MutationTree<TrafficTypesState> = {
  [SET_TRAFFIC_TYPES] (state, payload) {
    state.trafficTypes = payload;
  }
};

const actions: ActionTree<TrafficTypesState, RootState> = {
  async [GET_TRAFFIC_TYPES] ({ commit }) {
    const { data: { trafficTypes } } = await TrafficTypesService.getTrafficTypes();
    commit(SET_TRAFFIC_TYPES, trafficTypes);
  }
};

export const trafficTypes: Module<TrafficTypesState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions
};
