<template>
    <span>
        <span
            v-if="position === 'before' || position === 'both'"
            class="before">
            <slot
                :active="!!tooltip"
                :label="tooltip"
                :position="tooltipPosition"
                :type="tooltipType"
                name="before">
                <LTooltip
                    :active="!!tooltip"
                    :label="tooltip"
                    :position="tooltipPosition"
                    ignore-flip
                    :type="tooltipType">
                    <i
                        :class="type"
                        :style="color && { backgroundColor: `${color} !important` }"></i>
                </LTooltip>
            </slot>
        </span>

        <span class="content">
            <slot></slot>
        </span>

        <span
            v-if="position === 'after' || position === 'both'"
            class="after">
            <slot
                :active="!!tooltip"
                :label="tooltip"
                :position="tooltipPosition"
                :type="tooltipType"
                name="after">
                <LTooltip
                    :active="!!tooltip"
                    :label="tooltip"
                    :position="tooltipPosition"
                    ignore-flip
                    :type="tooltipType">
                    <i
                        :class="type"
                        :style="color && { backgroundColor: `${color} !important` }"></i>
                </LTooltip>
            </slot>
        </span>
    </span>
</template>

<script>
  // Простой компонет отображения значка (или иконки если передана в слот)
  export default {
    name: "ColoredStatus",
    props: {
      position: {
        type: String,
        default: "before",
        validator: value => ["before", "after", "both"].includes(value)
      },

      type: {
        type: [String, Array, Object],
        default: null
      },

      color: {
        type: String,
        default: null
      },

      tooltip: {
        type: String,
        default: null
      },

      tooltipPosition: {
        type: String,
        default: "is-top"
      },

      tooltipType: {
        type: String,
        default: "is-primary"
      }
    }
  };
</script>

<style lang="scss" scoped>
    @import "src/scss/variables";

    span {
        $size: 10px;

        position: relative;

        .tippy {
            vertical-align: middle;

            ::v-deep {
                .tooltip {
                    &-trigger {
                        line-height: 0;
                    }
                }
            }
        }

        .before, .after {
            .tippy {
                vertical-align: initial;
            }
        }

        .before {
            .tippy {
                padding-right: 0.4em;
            }
        }

        .after {
            .tippy {
                padding-left: 0.4em
            }
        }

        i {
            width: $size;
            height: $size;
            border-radius: 50%;
            display: inline-block;
            vertical-align: middle;
            background-color: transparent;

            &.is-success {
                background-color: $success;
            }

            &.is-warning {
                background-color: $warning;
            }

            &.is-danger {
                background-color: $danger;
            }

            &.is-info {
                background-color: $info;
            }
        }
    }
</style>
