import { ADD_DOMAIN, UPDATE_OPTIONS } from "@core/store/action-constants";
import { SET_EMPTY } from "@core/store/mutation-constants";
import { NullableDomainsModalState } from "@core/store/types/common/domains/domains";
import { RootState } from "@core/store/root-state";
import { ActionTree, Module, MutationTree } from "vuex";
import { DomainsService } from "@core/store/logic/admin/domains/domainsService";
import { webmastersList } from "@core/store/modules/admin/lists/webmastersList";

const initialState = (): NullableDomainsModalState => {
  return {
    options: {
      name: null,
      sslAuthMethod: null,
      webmasterId: null
    }
  };
};

const state: () => NullableDomainsModalState = initialState;

const mutations: MutationTree<NullableDomainsModalState> = {
  [SET_EMPTY] (state) {
    Object.assign(state, initialState());
  },
  
  [UPDATE_OPTIONS] (state, option) {
    state.options = { ...state.options, ...option };
  }
};

const actions: ActionTree<NullableDomainsModalState, RootState> = {
  async [ADD_DOMAIN] ({ state: { options: input } }) {
    await DomainsService.addDomain(input);
  },
  
  [UPDATE_OPTIONS] ({ commit }, options) {
    commit(UPDATE_OPTIONS, options);
  },
  
  [SET_EMPTY] ({ commit }) {
    commit(SET_EMPTY);
    commit("webmastersList/SET_EMPTY");
  }
};

export const domainModal: Module<NullableDomainsModalState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    webmastersList
  }
};
