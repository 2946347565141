import Service from "@core/services/common/Service";
import competitionTop from "@core/services/webmaster/competition/graphql/competitionTop.graphql";
import currentCompetition from "@core/services/webmaster/competition/graphql/currentCompetition.graphql";
import { AxiosPromise } from "axios";

export class CompetitionService extends Service {
  public static async getCompetitionTop (competitionId: string): Promise<AxiosPromise> {
    try {
      return await this.api.get("", {
        params: {
          query: competitionTop,
          variables: { competitionId }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static async getCurrentCompetition (): Promise<AxiosPromise> {
    try {
      return await this.api.get("", {
        params: {
          query: currentCompetition
        }
      });
    } catch (e) {
      throw e;
    }
  }
}