<template>
    <div class="bg-gray-background full">
        <AsideMenu
            class="asideMenu"
            :is-mobile-menu-open.sync="menuOpen"
            @isCollapsed="isModalOverlay = !isModalOverlay"
            @menuOpen="menuOpen = !menuOpen"></AsideMenu>

        <div class="w-full main">
            <div :class="{ 'modal-overlay absolute w-full h-full bg-gray-900 opacity-50 xl:hidden laptop:z-10': isModalOverlay }"></div>
            <NavigationBar @menuOpen="menuOpen = !menuOpen"></NavigationBar>
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
  import NavigationBar from "@/components/Common/NavigationBar/NavigationBar";
  import AsideMenu from "@/components/Common/AsideMenu";

  export default {
    name: "Full",
    components: {
      AsideMenu,
      NavigationBar
    },

    data () {
      return {
        isModalOverlay: false,
        menuOpen: false
      };
    }
  };
</script>

<style lang="scss" scoped>
    ::v-deep {
        .el-loading-mask {
            @apply z-20;
        }
    }

    .full {
        display: flex;
    }

    @media screen and (min-width: 1366px) {
        .main {
            padding-left: 20rem !important;
        }
    }

    .main {
        padding: 0 1rem 1rem 4rem;
        min-height: 100vh;
        @media screen and (max-width: 768px) {
            padding: 0;
        }

        //.section {
        //    @media screen and (max-width: 768px) {
        //        margin-top: 1rem;
        //    }
        //}
    }

    .el-main {
        @media screen and (max-width: 768px) {
            padding: 10px;
        }
    }
</style>
