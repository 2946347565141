import axios from "axios";
import { ActionTree, Module, MutationTree } from "vuex";
import {
  GET_CURRENCIES,
  GET_OFFER_CATEGORIES,
  GET_PAYMENT_SYSTEMS,
  GET_REGIONS,
  GET_TIMEZONES,
  GET_TRAFFIC_TYPES
} from "../../action-constants";
import {
  SET_CURRENCIES,
  SET_OFFER_CATEGORIES,
  SET_PAYMENT_SYSTEMS,
  SET_REGIONS,
  SET_STATS_STATUS,
  SET_TIMEZONES,
  SET_TRAFFIC_TYPES
} from "../../mutation-constants";
import { RootState } from "../../root-state";

export interface DictionariesState {
  paymentSystems: [];
  trafficTypes: [];
  currencies: [];
  timeZones: [];
  offerCategories: [];
  regions: [];
}

const state: DictionariesState = {
  paymentSystems: [],
  trafficTypes: [],
  currencies: [],
  timeZones: [],
  offerCategories: [],
  regions: []
};

const mutations: MutationTree<DictionariesState> = {
  [SET_PAYMENT_SYSTEMS] (state, systems) {
    state.paymentSystems = systems;
  },
  
  [SET_TRAFFIC_TYPES] (state, payload) {
    state.trafficTypes = payload;
  },
  
  [SET_OFFER_CATEGORIES] (state, payload) {
    state.offerCategories = payload;
  },
  
  [SET_CURRENCIES] (state, payload) {
    state.currencies = payload;
  },
  
  [SET_TIMEZONES] (state, payload) {
    state.timeZones = payload;
  },
  
  [SET_REGIONS] (state, payload) {
    state.regions = payload;
  }
};

const actions: ActionTree<DictionariesState, RootState> = {
  async [GET_PAYMENT_SYSTEMS] ({ commit }) {
    const { data } = await axios.get("/dictionaries/payment-systems");
    commit(SET_PAYMENT_SYSTEMS, data);
    return data;
  },
  
  async [GET_TRAFFIC_TYPES] ({ commit }) {
    const { data } = await axios.get("/dictionaries/traffictypes");
    commit(SET_TRAFFIC_TYPES, data);
  },
  
  async [GET_OFFER_CATEGORIES] ({ commit }) {
    const { data } = await axios.get("/dictionaries/offers/categories");
    commit(SET_OFFER_CATEGORIES, data.items);
  },
  
  async [GET_CURRENCIES] ({ commit }) {
    const { data } = await axios.get("/dictionaries/currencies");
    commit(SET_CURRENCIES, data);
  },
  
  async [GET_TIMEZONES] ({ commit }) {
    const { data } = await axios.get("/dictionaries/time-zone");
    commit(SET_TIMEZONES, data);
  },
  
  async GET_STATS_STATUS ({ commit }) {
    const { data } = await axios.get("/dictionaries/leads/statuses");
    commit(`webmaster/${ SET_STATS_STATUS }`, data);
    return data;
  },
  
  async [GET_REGIONS] ({ commit }) {
    const { data } = await axios.get("/dictionaries/regions");
    commit(SET_REGIONS, data);
  }
};

export const dictionaries: Module<DictionariesState, RootState> = {
  state,
  mutations,
  actions
};
