<template>
    <el-container class="bg-gray-light-bg h-screen flex items-center justify-center">
        <div class="flex items-center flex-col">
            <!--suppress HtmlUnknownTarget -->
            <img
                alt="logo"
                src="@/assets/logo.svg">
            <!--suppress HtmlUnknownTarget -->
            <img
                alt="mail-image"
                src="@/assets/MailConfirmation/confirmed.png">
            <h1 class="has-text-black is-title has-text-centered">
                {{ $t(`common.mailConfirmation.confirmed.title`) }}
            </h1>
            <router-link
                :to="{ name: 'main' }"
                class="button is-success is-rounded is-fullwidth">
                {{ $t(`common.mailConfirmation.confirmed.link`) }}
            </router-link>
        </div>
    </el-container>
</template>

<script>
  export default {
    name: "MailConfirmationConfirmed"
  };
</script>
