<template>
    <el-alert
        v-if="isShown"
        show-icon
        type="warning"
        effect="dark"
        :title="$t(`common.releaseUpdate.header`)">
        <div class="mb-3">
            <p class="text-left">
                <i18n path="common.releaseUpdate.body.content">
                    <template #strong>
                        <span
                            v-t="'common.releaseUpdate.body.particles.strong'"
                            class="font-bold"></span>
                    </template>
                </i18n>
            </p>
        </div>
        <el-button
            icon="el-icon-refresh-right"
            @click="refreshPage">
            {{ $t(`common.releaseUpdate.button`) }}
        </el-button>
    </el-alert>
</template>

<script>
  import { UpdateService } from "@core/logic/common/Update/update";
  import { mapState } from "vuex";

  export default {
    name: "ReleaseUpdate",
    created () {
      this.checkUpdate();
    },

    mounted () {
      this.interval = setInterval(this.checkUpdate, 15 * 1000);
    },

    data () {
      return {
        isShown: false,
        interval: null
      };
    },

    computed: {
      ...mapState(["version"])
    },

    methods: {
      async checkUpdate () {
        if (process.env.VUE_APP_RELEASE_UPDATE) {
          const { data } = await UpdateService.getVersion();
          this.isShown = this.version !== data.version;
          this.isShown && clearInterval(this.interval);
        }
      },

      refreshPage () {
        parent.window.location.reload();
      }
    },

    beforeDestroy () {
      clearInterval(this.interval);
    }
  };
</script>

<style lang="scss" scoped>
    .el-alert {
        width: 80%;
        left: 10px;
        z-index: 40;
        bottom: 10px;
        position: fixed;
        max-width: 600px;
        align-items: start;
        padding: 1.25rem 2.5rem 1.25rem 1.5rem;
        background-color: #E2C21A;

        ::v-deep {
            .el-alert__title {
                font-size: 1.25rem;
            }
            .el-alert__description {
                text-align: right;
                font-size: 1rem;
            }
        }
    }
</style>
