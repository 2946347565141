import { CompetitionService } from "@core/services/admin/competition/CompetitionService";
import { competitionModal } from "@core/store/modules/admin/competition/competitionModal";
import { competitionDetailModal } from "@core/store/modules/admin/competition/competitionDetailModal";
import { filterPreparation } from "@core/helpers/filterPreparation";
import {
  GET_COMPETITION,
  DRAFT_COMPETITION,
  PLAN_COMPETITION,
  DELETE_COMPETITION
} from "@core/store/action-constants";
import {
  SET_COMPETITION,
  SET_EMPTY,
  UPDATE_FILTERS,
  UPDATE_PAGINATION
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { CompetitionState } from "@core/store/types/admin/competition";
import { ActionTree, Module, MutationTree } from "vuex";

const initialState = (): CompetitionState => {
  return {
    data: null,
    pagination: {
      perPage: 25,
      page: 1
    },
    filters: {
      name: null,
      startDate: null,
      finishDate: null
    }
  };
};

export const state: () => CompetitionState = initialState;

const mutations: MutationTree<CompetitionState> = {
  [SET_COMPETITION] (state, competition) {
    state.data = competition;
  },
  
  [UPDATE_FILTERS] (state, filters) {
    state.filters = { ...state.filters, ...filters };
  },
  
  [UPDATE_PAGINATION] (state, pagination) {
    state.pagination = { ...state.pagination, ...pagination };
  },
  
  [SET_EMPTY] (state) {
    Object.assign(state, initialState());
  }
};

const actions: ActionTree<CompetitionState, RootState> = {
  async [GET_COMPETITION] ({ state, commit }) {
    const { pagination: { perPage: limit, page } } = state;
    const filters = filterPreparation(state.filters);
    const offset = (page - 1) * limit;
    
    const { data: { competitions: data } } = await CompetitionService.getCompetitions(limit, offset, filters);
    
    commit(SET_COMPETITION, data);
  },
  
  async [DRAFT_COMPETITION] (_, competitionId) {
    await CompetitionService.draftCompetitions(competitionId);
  },
  
  async [DELETE_COMPETITION] (_, competitionId) {
    await CompetitionService.deleteCompetitions(competitionId);
  },
  
  async [PLAN_COMPETITION] (_, competitionId) {
    await CompetitionService.planCompetitions(competitionId);
  },
  
  [UPDATE_PAGINATION] ({ commit }, pagination) {
    commit(UPDATE_PAGINATION, pagination);
  },
  
  [UPDATE_FILTERS] ({ commit }, filter) {
    commit(UPDATE_FILTERS, filter);
    commit(UPDATE_PAGINATION, { page: 1 });
  },
  
  [SET_EMPTY] ({ commit }) {
    commit(SET_EMPTY);
  }
};

export const competition: Module<CompetitionState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    competitionModal,
    competitionDetailModal
  }
};
