import Service from "@core/services/common/Service";
import {
  CostsListItem,
  CostsListState,
  PartialFinancesCostsState
} from "@core/store/types/admin/finances/financesCosts/financesCosts";
import { AxiosPromise } from "axios";
import autoPayTransaction from "./graphql/autoPayTransaction.graphql";
import getCosts from "./graphql/outTransactions.graphql";
import transitCreatedToHandProcessing from "./graphql/transitCreatedToHandProcessing.graphql";
import transitHandProcessingToProcessed from "./graphql/transitHandProcessingToProcessed.graphql";
import transitHandProcessingToReject from "./graphql/transitHandProcessingToReject.graphql";

export class FinancesCostsService extends Service {
  public static async getCosts (
    limit: number,
    offset: number,
    filters: PartialFinancesCostsState["filters"],
    sort?: Nullable<string>,
    order?: Nullable<string>
  ): Promise<CostsListState["costs"]> {
    try {
      const { data: { outTransactions } } = await this.api.get("", {
        params: {
          query: getCosts,
          variables: {
            limit,
            offset,
            filters,
            sort,
            order
          }
        }
      });
      outTransactions.items = outTransactions.items.map((item: CostsListItem) => {
        return {
          ...item,
          canBeAutoPay: item.paymentSystem.integrator.alias === "capitalist" && item.status === "created" && item.requisite
        };
      });

      return outTransactions;
    } catch (e) {
      throw e;
    }
  }

  public static async autoCost (transactionId: string): Promise<AxiosPromise> {
    try {
      return await this.api.post("", {
        query: autoPayTransaction,
        variables: {
          input: { ids: transactionId }
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static async processCost (ids: string): Promise<AxiosPromise> {
    try {
      return await this.api.post("", {
        query: transitCreatedToHandProcessing,
        variables: {
          input: { ids }
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static async acceptCost (ids: string): Promise<AxiosPromise> {
    try {
      return await this.api.post("", {
        query: transitHandProcessingToProcessed,
        variables: {
          input: { ids }
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static async rejectCost (ids: string): Promise<AxiosPromise> {
    try {
      return await this.api.post("", {
        query: transitHandProcessingToReject,
        variables: {
          input: { ids }

        }
      });
    } catch (e) {
      throw e;
    }
  }
}
