import { GraphQLServer } from "@core/plugins/axiosGraphQL";
import Service from "@core/services/common/Service";
import { AdvertisersListResponse, PartialAdvertisersListState } from "@core/store/types/admin/lists/AdvertisersListState";
import { AxiosPromise } from "axios";
import advertisers from "../../admin/lists/graphql/advertisers.graphql";

export class AdvertisersListService extends Service {
  public static getAdvertisersList (
    filters: PartialAdvertisersListState["filters"], limit: number, offset: number,
    initialAdvertisers: PartialAdvertisersListState["filters"] = {}): AxiosPromise<AdvertisersListResponse> {

    const initialAdvertisersLimit = initialAdvertisers?.id?.length || 0;
    const isInitialAdvertisers = !!initialAdvertisers?.id;
    limit = Math.floor(initialAdvertisersLimit / limit + 1) * limit - initialAdvertisersLimit;
    return GraphQLServer.get("", {
      params: {
        query: advertisers,
        variables: {
          limit,
          offset,
          filters,
          initialAdvertisers,
          initialAdvertisersLimit,
          isInitialAdvertisers
        }
      }
    });
  }
}
