<template>
    <FSelect
        v-model="selectedCountries"
        :get-data-vuex="getFilteredCountries"
        :placeholder="placeholder"
        :required="required"
        :seed="countries"
        :loading="isLoading"
        :bordered="bordered"
        prop="id"
        :multiple="multiple">
        <template #text="{ option }">
            <CountryOutput :country="option"></CountryOutput>
        </template>
        <template #selected-option="{ option }">
            <CountryOutput :country="option"></CountryOutput>
        </template>
    </FSelect>
</template>

<script>
  import {
    GET_COUNTRIES,
    GET_COUNTRIES_LIST
  } from "@core/store/action-constants";
  import { mapGetters } from "vuex";
  import Fuse from "fuse.js";
  import CountryOutput from "@/components/Common/CountryOutput";
  import FSelect from "@/components/Common/FSelect";
  import BaseSelect from "@/components/Common/Base/BaseSelect";

  export default {
    name: "FCountrySelect",
    extends: BaseSelect,
    components: {
      FSelect,
      CountryOutput
    },
    props: {
      value: {
        type: Array,
        default: null
      }
    },
    data () {
      return {
        namespace: "countries"
      };
    },
    computed: {
      ...mapGetters("countriesList", {
        countries: GET_COUNTRIES
      }),

      selectedCountries: {
        get () {
          return this.value;
        },
        set (value) {
          this.$emit("input", value);
        }
      },

      isLoading () {
        return this.$wait(`countriesList/${ GET_COUNTRIES_LIST }`);
      }
    },
    methods: {
      getFilteredCountries (label) {
        if (label) {
          return this.filter(this.countries.items, label);
        } else {
          return this.countries;
        }
      },

      filter (items, label) {
        const fuse = new Fuse([...items], {
          keys: ["name"],
          threshold: 0.4
        });
        const filteredItems = fuse.search(label).map(resultItem => {
          return {
            alpha2: resultItem.item.alpha2,
            alpha3: resultItem.item.alpha3,
            id: resultItem.item.id,
            name: resultItem.item.name
          };
        });

        return {
          count: filteredItems.length,
          items: filteredItems
        };
      }
    }
  };
</script>

<style scoped lang="scss">
</style>