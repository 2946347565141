import Service from "@core/services/common/Service";
import {
  AdvertisersRatesBackendResponse,
  AdvertisersRatesFilters,
  AdvertisersRatesNormalizedResponse
} from "@core/store/types/admin/users/advertisers/Detail";
import { AxiosResponse } from "axios";
import advertiserRatesSummary from "./graphql/advertiserRatesSummary.graphql";

export class AdvertisersDetailService extends Service {
  public static async getRates (advertiserId: string, filters: AdvertisersRatesFilters): Promise<AxiosResponse<AdvertisersRatesNormalizedResponse>> {
    const resp = await this.api.get("", {
      params: {
        query: advertiserRatesSummary,
        variables: {
          advertiserId,
          filters
        }
      }
    }) as AxiosResponse<AdvertisersRatesBackendResponse>;
    
    return {
      ...resp,
      data: {
        advertiserRatesSummary: {
          ratesSummary: {
            items: resp.data.advertiserRatesSummary.ratesSummary,
            count: resp.data.advertiserRatesSummary.ratesSummary.length
          }
        }
      }
    };
  }
}
