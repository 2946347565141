<template>
    <router-link :to="itemTo">
        <el-menu-item
            :index="itemHref"
            class="h-auto flex text-base items-center text-blue-text rounded
         border-gray-background border hover:bg-gray-light-bg hover:shadow-lg"
            @click="updateMobileMenuOpen">
            <InlineSvg
                class="menu-item-icon"
                :src="item.icon"
                :alt="item.label"
                :aria-label="item.label">
            </InlineSvg>
            <span
                v-if="item.label"
                class="label">
                {{ item.label }}
            </span>
        </el-menu-item>
    </router-link>
</template>

<script>
  import InlineSvg from "vue-inline-svg";
  
  export default {
    name: "AsideMenuItem",
    components: {
      InlineSvg
    },
    
    props: {
      item: {
        type: Object,
        default: null
      },
      index: {
        type: Number,
        default: null
      },
      isMobileMenuOpen: {
        type: Boolean,
        default: false
      }
    },
    
    computed: {
      itemTo () {
        return this.item?.to;
      },
      
      itemHref () {
        const path = this.item.to?.name?.split(":");
        return path[1] ?? path[0];
      }
    },
    methods: {
      updateMobileMenuOpen () {
        if (this.isMobileMenuOpen) {
          this.$emit("update:isMobileMenuOpen", false);
        }
    
      }
    }
  };
</script>

<style lang="scss" scoped>
    //.label {
    //    @media screen and (max-width: 1024px) {
    //        display: none;
    //    }
    //}
    .el-menu-item {
        padding-left: 0!important;
        padding-right: 0!important;
    }

    .el-menu-item.is-active {
        background-color: #F3F5F9;
        border-color: #FE2615;
        color: #162239;
    }
</style>
