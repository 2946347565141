<template>
    <FDropdown
        :data="uploadsListItems"
        :loading="isLoading"
        dropdown-class-item="px-4">
        <LTooltip
            :always="isOpen"
            :label="$t('common.files.tooltips.uploads')"
            type="is-info"
            position="is-left">
            <FButton
                :disabled="!hasFiles"
                no-more
                plain
                type="primary">
                <FIcon
                    class="mr-4"
                    icon="file-download"
                    type="primary">
                </FIcon>
                <FTag
                    custom-class="rounded-full"
                    type="primary">
                    {{ uploadFilesCount }}
                </FTag>
            </FButton>
        </LTooltip>

        <template #dropdown="{ item }">
            <div
                v-if="item"
                class="flex items-center py-2 mr-6"
                @click.stop>
                <FIcon
                    :icon="icon(item).icon"
                    :type="icon(item).type"
                    class="text-xl">
                </FIcon>
                <div class="flex flex-col h-full leading-5 ml-2 w-32">
                    <span>
                        {{ item.query }}
                    </span>
                    <FProgress
                        v-if="item.status === uploadStatuses.PROCESSING"
                        :percentage="calculateMaxPercent(item.readyPercent)">
                    </FProgress>
                    <small v-else-if="item.status === uploadStatuses.UNPROCESSED">
                        <span>
                            {{ $t("common.files.pending") }}
                        </span>
                    </small>
                    <small v-else-if="item.status === uploadStatuses.DONE && item.link">
                        <a
                            class="text-purple-600"
                            :download="getFileName(item)"
                            :href="item.link"
                            target="_blank">
                            {{ $t("common.files.download") }}
                        </a>
                    </small>
                </div>
            </div>
        </template>

        <InfiniteLoading
            v-if="isInfiniteLoading_"
            slot="append"
            @infinite="infiniteHandler">
        </InfiniteLoading>
    </FDropdown>
</template>

<script>
  import { GET_UPLOADS } from "@core/store/action-constants";
  import { mapActions, mapState } from "vuex";
  import FProgress from "@/components/Common/FProgress";
  import FDropdown from "@/components/Common/FDropdown";
  import FTag from "@/components/Common/FTag";
  import FIcon from "@/components/Common/FIcon";
  import FButton from "@/components/Common/FButton";
  import InfiniteLoading from "vue-infinite-loading";

  export default {
    name: "UploadsList",

    components: {
      FButton,
      FIcon,
      FTag,
      FDropdown,
      FProgress,
      InfiniteLoading
    },

    props: {
      data: {
        type: Object,
        default: () => {}
      },

      pagination: {
        type: Object,
        default: null
      }
    },

    created () {
      this.GET_UPLOADS();
    },
    
    data () {
      return {
        isOpen: false
      };
    },
    
    computed: {
      ...mapState("uploads", {
        uploadsList: state => state.uploads,
        isFiledUploaded: state => state.isFiledUploaded
      }),
      
      uploadStatuses () {
        return {
          PROCESSING: "PROCESSING",
          UNPROCESSED: "UNPROCESSED",
          DONE: "DONE"
        };
      },
      
      hasFiles () {
        return this.uploadFilesCount !== 0;
      },

      uploadsListItems () {
        return this.uploadsList?.items;
      },
      
      uploadFilesCount () {
        return this.uploadsList?.count;
      },

      isInfiniteLoading_ () {
        return this.pagination?.perPage * this.pagination?.page < this.uploadFilesCount;
      },

      isLoading () {
        return this.$wait(`uploads/${ GET_UPLOADS }`);
      }
    },

    methods: {
      ...mapActions("uploads", [
        GET_UPLOADS
      ]),

      infiniteHandler ($state) {
        this.$emit("update:pagination", {
          page: this.pagination.page + 1,
          perPage: this.pagination.perPage
        });
        $state.loaded();
      },

      getFileName (file) {
        return `${ file.query }.${ file?.format?.toLowerCase() }`;
      },

      icon (file) {
        if (file.format === "CSV") {
          return {
            icon: "file-csv",
            type: "primary"
          };
        } else if (file.format === "XLSX") {
          return {
            icon: "file-excel",
            type: "success"
          };
        } else {
          return {
            icon: "file",
            type: "dark"
          };
        }
      },

      url (file) {
        return file?.file?.url;
      },

      getUploads (active) {
        this.isOpen = active;
      },

      calculateMaxPercent (percent) {
        const MIN_PERCENT = 0;
        const MAX_PERCENT = 100;
        return Math.min(Math.max(percent, MIN_PERCENT), MAX_PERCENT);
      }
    }
  };

</script>

<style lang="scss" scoped>
    .media {
        min-width: 160px;
    }
</style>
