import { formatEmptyString } from "@core/filters";

// @vue/components
export const Filters = {
  props: {
    filters: {
      type: Object,
      default: () => {
      }
    },
    
    perPage: {
      type: Number,
      default: null
    },
    
    isLoading: {
      type: Boolean,
      default: false
    },

    excludedFilters: {
      type: Object,
      default: () => {
      }
    }
  },
  
  computed: {
    limit_: {
      get () {
        return this.perPage;
      },
      set (val) {
        this.$emit("update:perPage", val);
      }
    },
    
    filters_: {
        get () {
            return this.filters;
        },
        set (value) {
            this.$emit("update:filters", value);
        }
    },

    excludedFilters_: {
      get () {
        return this.excludedFilters;
      },
      set (value) {
        this.$emit("update:excludedFilters", value);
      }
    }
  },
  
  methods: {
    formatEmptyString,
    
    emitUpdate () {
      this.$emit("update");
    },
    
    emitClear () {
      this.$emit("clear");
    },

    clearFilters () {
      for (const filter in this.filters) {
        if(this.filters.hasOwnProperty(filter)) {
          if(filter !== "datepicker" && filter !== "registeredDatepicker" && filter !== "groups" && filter !== "currency") {
            this.filters_[filter] = null;
          }
        }
      }
      for (const filter in this.excludedFilters) {
        if(this.excludedFilters.hasOwnProperty(filter)) {
          this.excludedFilters_[filter] = null;
        }
      }
    }
  }
};
