import { AdvertisersEditService } from "@core/services/admin/users/advertisers/AdvertisersEditService";
import { EDIT_ADVERTISER, GET_ADVERTISER } from "@core/store/action-constants";
import {
  SET_ADVERTISERS_EDIT_FORM,
  UPDATE_ADVERTISERS_MINIMAL_COMMISSIONS,
  SET_ADVERTISERS_EDIT_ID,
  SET_EMPTY,
  SET_IS_ADVERTISERS_EDIT_MODAL_ACTIVE,
  UPDATE_ADVERTISERS_EDIT_FORM
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { Advertiser, AdvertisersEditState } from "@core/store/types/admin/users/advertisers/Edit";
import { ActionTree, Module, MutationTree } from "vuex";
import { password } from "./modules/password";
import { currencyList } from "@core/store/modules/common/lists/currencyList";

const initialState = (): AdvertisersEditState => {
  return {
    isModalActive: false,
    advertiserId: null,
    form: {}
  };
};

const mutations: MutationTree<AdvertisersEditState> = {
  [SET_IS_ADVERTISERS_EDIT_MODAL_ACTIVE] (state, value: boolean) {
    state.isModalActive = value;
  },
  
  [SET_ADVERTISERS_EDIT_ID] (state, id: string) {
    state.advertiserId = id;
  },
  
  [UPDATE_ADVERTISERS_EDIT_FORM] (state, form) {
    state.form = { ...state.form, ...form };
  },
  
  [UPDATE_ADVERTISERS_MINIMAL_COMMISSIONS] (state, commission) {
    state.form.minimalCommission = state.form.minimalCommission?.map(item => {
      if(item.currency === commission.currency) {
        return commission;
      } return item;
    });
  },
  
  [SET_ADVERTISERS_EDIT_FORM] (state, advertiser: Advertiser) {
    const { timeZone: { alias: timeZone }, contact: { telegram, email }, ...fields } = advertiser;
    state.form = {
      timeZone,
      telegram,
      email,
      ...fields
    };
  },
  
  [SET_EMPTY] (state) {
    Object.assign(state, initialState());
  }
};

const actions: ActionTree<AdvertisersEditState, RootState> = {
  async [GET_ADVERTISER] ({ state: { advertiserId }, commit }) {
    const { data: { advertisers: { items: [advertiser] } } } = await AdvertisersEditService.getAdvertiser(advertiserId as string);
    
    if (advertiser) {
      commit(SET_ADVERTISERS_EDIT_FORM, advertiser);
    }
  },
  
  async [EDIT_ADVERTISER] ({ state: { advertiserId, form } }) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { login, ...input } = form;
    if (!input.isFineDebt) {
      input.fineDebtDays = null;
      input.fineDebtPercent = null;
    }
    await AdvertisersEditService.editAdvertiser(advertiserId as string, input);
  }
};

export const edit: Module<AdvertisersEditState, RootState> = {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  modules: {
    currencyList,
    password
  }
};
