import { EfficiencyService } from "@core/services/admin/efficiency/EfficiencyService";
import { RootState } from "@core/store/root-state";
import { EfficiencyListState } from "@core/store/types/admin/efficiency/efficiency";
import { ActionTree, Module, MutationTree } from "vuex";
import {
  SET_EFFICIENCY_CHART_FILTERS,
  SET_EFFICIENCY_GRAPH,
  SET_EFFICIENCY_TOTAL,
  SET_GROUP,
  UPDATE_EFFICIENCY_FILTERS,
  UPDATE_EFFICIENCY_SORTINGS
} from "@core/store/mutation-constants";
import {
  GET_EFFICIENCY,
  SET_EMPTY,
  GET_EFFICIENCY_GRAPH,
  GET_EFFICIENCY_TOTAL,
  UPDATE_EFFICIENCY_CHART_FILTERS
} from "@core/store/action-constants";

const initialState = (): EfficiencyListState => {
  return {
    total: null,
    graph: null,
    chartFilters: {
      line: null,
      bar: null
    },
    filters: {
      datepicker: {},
      currency: "USD"
    },
    group: "DAY",
    isGridHidden: false,
    sort: null,
    order: null
  };
};

const state: () => EfficiencyListState = initialState;

const mutations: MutationTree<EfficiencyListState> = {
  [SET_EMPTY] (state) {
    Object.assign(state, initialState());
  },

  [SET_EFFICIENCY_TOTAL] (state, total) {
    state.total = {
      count: total.length,
      items: total
    };
  },

  [SET_EFFICIENCY_GRAPH] (state, graph) {
    state.graph = graph;
  },

  [SET_EFFICIENCY_CHART_FILTERS] (state, chartFilters) {
    state.chartFilters = chartFilters;
  },

  [SET_GROUP] (state, group) {
    state.group = group;
  },

  [UPDATE_EFFICIENCY_FILTERS] (state, filter: any) {
    state.filters = { ...state.filters, ...filter };
  },

  [UPDATE_EFFICIENCY_SORTINGS] (state, {
    sort,
    order
  }): void {
    state.sort = sort;
    state.order = order.toUpperCase();
  }
};

const actions: ActionTree<EfficiencyListState, RootState> = {
  async [GET_EFFICIENCY] ({ dispatch }) {
    await dispatch(GET_EFFICIENCY_GRAPH);
    await dispatch(GET_EFFICIENCY_TOTAL);
  },
  async [GET_EFFICIENCY_TOTAL] ({ commit, state }) {
    const { filters: { datepicker, currency, ...filters } } = state;

    const requestFilters = {
      ...datepicker,
      ...filters
    };

    const { data: { efficiencyDashboard: { total } } } = await EfficiencyService.getEfficiency({
      currency,
      filters: requestFilters,
      group: state.group,
      sort: state.sort,
      order: state.order
    });

    commit(SET_EFFICIENCY_TOTAL, total);
  },

  async [GET_EFFICIENCY_GRAPH] ({ commit, state }) {
    const { filters: { datepicker, currency, ...filters }, chartFilters } = state;

    const requestFilters = {
      ...datepicker,
      ...filters
    };

    const { data: { efficiencyDashboard: { graph } } } = await EfficiencyService.getEfficiency({
      currency,
      filters: requestFilters,
      group: state.group,
      chartFilters
    });

    commit(SET_EFFICIENCY_GRAPH, graph);
  },

  [UPDATE_EFFICIENCY_FILTERS] ({ commit }, filter) {
    commit(UPDATE_EFFICIENCY_FILTERS, filter);
  },

  [UPDATE_EFFICIENCY_CHART_FILTERS] ({ commit }, filters) {
    commit(SET_EFFICIENCY_CHART_FILTERS, filters);
  }
};

export const efficiency: Module<EfficiencyListState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations
};
