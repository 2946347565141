<template>
    <el-switch
        v-if="switchable"
        v-model="model">
        <span v-if="label">{{ label }}</span>
    </el-switch>
    <el-checkbox
        v-else
        v-model="model"
        :native-value="nativeValue">
        <span v-if="label">{{ label }}</span>
    </el-checkbox>
</template>

<script>
  export default {
    props: {
      value: {
        type: null,
        default: null
      },
      nativeValue: {
        type: null,
        default: null
      },
      switchable: {
        type: Boolean,
        default: false
      },
      label: {
        type: String,
        default: ""
      }
    },
    computed: {
      model: {
        get () {
          return this.value;
        },
        set (value) {
          this.$emit("input", value);
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
    span {
        font-size: 0.875em;
    }
</style>
