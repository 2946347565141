import { WebmastersEditService } from "@core/services/admin/users/webmasters/WebmastersEditService";
import { EDIT_PASSWORD } from "@core/store/action-constants";
import { SET_EMPTY, UPDATE_MODAL, UPDATE_PASSWORD_FORM } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { WebmastersPasswordEditInput, WebmastersPasswordState } from "@core/store/types/admin/users/webmasters/Edit";
import { ActionTree, Module, MutationTree } from "vuex";

const initialState = (): WebmastersPasswordState => {
  return {
    modal: {
      isPasswordModalActive: false
    },
    form: {
      password: null
    }
  };
};

const mutations: MutationTree<WebmastersPasswordState> = {
  [SET_EMPTY] (state) {
    Object.assign(state, initialState());
  },

  [UPDATE_MODAL] (state, modal) {
    state.modal = { ...state.modal, ...modal };
  },

  [UPDATE_PASSWORD_FORM] (state, form) {
    state.form = { ...state.form, ...form };
  }
};

const actions: ActionTree<WebmastersPasswordState, RootState> = {
  async [EDIT_PASSWORD] ({ state: { form }, rootState }) {
    const { webmasterId } = rootState.admin.users.webmasters.edit;
    await WebmastersEditService.editPassword(webmasterId, form as WebmastersPasswordEditInput);
  },

  [UPDATE_MODAL] ({ commit }, modal) {
    commit(UPDATE_MODAL, modal);
  },

  [UPDATE_PASSWORD_FORM] ({ commit }, password) {
    commit(UPDATE_PASSWORD_FORM, password);
  }
};

export const password: Module<WebmastersPasswordState, RootState> = {
  namespaced: true,
  state: initialState,
  mutations,
  actions
};
