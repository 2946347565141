import { AxiosPromise } from "axios";
import Service from "@core/services/common/Service";
import profile from "./graphql/profile.graphql";
import resendEmail from "@core/services/common/graphql/resendEmail.graphql";
import editAdvertiserProfile from "./graphql/editAdvertiserProfile.graphql";
import {
  AdvertiserProfileResponse,
  AdvertiserProfilePersonalManagerResponse,
  AdvertiserProfileEditResponse,
  AdvertiserProfileEditInput
} from "@core/store/types/advertiser/profile";
 

export class ProfileService extends Service {
  public static getProfile (): AxiosPromise<AdvertiserProfileResponse> {
    return this.api.get("", {
      params: {
        query: profile
      }
    });
  }

  public static editProfile (input: AdvertiserProfileEditInput): AxiosPromise<AdvertiserProfileEditResponse> {
    return this.api.post("", {
      query: editAdvertiserProfile,
      variables: {
        input
      }
    });
  }
    
  public static getPersonalManager (): AxiosPromise<AdvertiserProfilePersonalManagerResponse> {
    // TODO: Поменять на gql api, когда у бека там появится менеджер
    return this.rest("/advertiser/personal-manager");
  }

  public static resendEmail (): AxiosPromise {
    return this.api.post("", {
      query: resendEmail
    });
  }
}
