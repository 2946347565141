//TODO: отрефакторить капчу
<template>
    <Background>
        <div class="form-wrapper">
            <el-form
                ref="form"
                :model="form"
                class="form"
                @submit.prevent.stop.native="captchaExecute">
                <!--suppress HtmlUnknownTarget -->
                <img
                    alt="FAIRPAY"
                    class="form-logo"
                    src="@/assets/fairpay-splash.png">
            
                <el-alert
                    v-if="isError"
                    show-icon
                    :closable="false"
                    type="error">
                    <i18n path="common.auth.signUp.error.content">
                        <template #login>
                            <span
                                v-t="'common.auth.signUp.error.particles.login'"
                                class="has-text-weight-semibold"></span>
                        </template>
                        <template #email>
                            <span
                                v-t="'common.auth.signUp.error.particles.email'"
                                class="has-text-weight-semibold"></span>
                        </template>
                    </i18n>
                </el-alert>
            
                <RequiredField
                    :label="$t('common.entity.role.entityName')"
                    prop="userType"
                    :rules="{ required: true }">
                    <FSelect
                        v-model="form.userType"
                        :get-data="getRoles"
                        :placeholder="$t(`common.auth.signUp.role`)"
                        expanded
                        prop="value"
                        field="label"
                        class="w-full"
                        required>
                    </FSelect>
                </RequiredField>
                <RequiredField
                    :label="$t('common.auth.signUp.email')"
                    prop="email"
                    :rules="[
                        { required: true, message: $t(`common.auth.signUp.emptyField`), trigger: 'change' },
                        { type: 'email', message: $t(`common.auth.signUp.emailHelp`), trigger: ['blur', 'change'] }
                    ]">
                    <FInput
                        v-model="form.email"
                        :pattern="emailValidator"
                        :placeholder="$t(`common.auth.signUp.email`)"
                        required
                        type="email">
                    </FInput>
                </RequiredField>
                <RequiredField
                    :message="$t(`common.auth.signUp.loginHelp`)"
                    :label="$t('common.auth.signIn.login')"
                    prop="login"
                    :rules="[
                        { required: true, message: $t(`common.auth.signUp.loginHelp`), trigger: 'change' },
                        { pattern: '[\\w+]{2,}', message: $t(`common.auth.signUp.loginHelp`), trigger: ['blur', 'change'] }
                    ]">
                    <FInput
                        v-model="form.login"
                        name="login"
                        required
                        :placeholder="$t(`common.auth.signUp.login`)">
                    </FInput>
                </RequiredField>
                <RequiredField
                    :label="$t('common.auth.signUp.telegram')"
                    :class="{ 'is-default-style': isRequiredTelegram }"
                    :rules="[
                        { required: true, message: $t(`common.auth.signUp.emptyField`), trigger: 'change' },
                        { pattern: '^[\w]{5,}$', message: $t(`common.auth.signUp.telegramHelp`), trigger: ['blur', 'change'] }
                    ]"
                    prop="telegram">
                    <FInput
                        v-model="_telegram"
                        required
                        minlength="5"
                        :placeholder="$t(`common.auth.signUp.telegram`)">
                        <template #prepend>
                            @
                        </template>
                    </FInput>
                </RequiredField>
                <!-- <b-field
                        v-if="form.userType === 'webmaster'"
                        class="column is-marginless is-12">
                        <b-select
                            v-model="form.managerIndex"
                            class="manager-select"
                            :placeholder="$t(`common.auth.signUp.manager`)"
                            expanded>
                            <option
                                :key="i"
                                v-for="(manager, i) in managers"
                                :value="i">
                                {{ manager.name }}
                            </option>
                        </b-select>
                    </b-field> -->
                <EditUserPasswordField
                    v-model="form.password"
                    :label="$t('common.auth.signUp.password')"
                    prop="password"
                    show-asterisk
                    :rules="[
                        { required: true, message: $t(`common.auth.signUp.emptyField`), trigger: 'change' },
                        { pattern: this.passwordValidator, message: $t(`common.auth.signUp.passwordHelp`), trigger: ['blur', 'change'] }
                    ]"
                    :placeholder="$t(`common.auth.signUp.password`)">
                </EditUserPasswordField>

                <Captcha
                    ref="captcha"
                    @onVerify="submit($event)">
                </Captcha>

                <FButton
                    :loading="isLoading"
                    native-type="submit"
                    :disabled="!isRequiredTelegram || !isRequiredPassword"
                    type="success"
                    no-more
                    plain>
                    {{ $t(`common.auth.signUp.submit`) }}
                </FButton>
                <router-link
                    :to="{ name: 'auth:signIn' }"
                    class="form-subbutton">
                    {{ $t(`common.auth.signIn.signIn`) }}
                </router-link>
            </el-form>
        </div>
    </Background>
</template>

<script>
  import Background from "@/components/Common/Auth/Background";
  import EditUserPasswordField from "@/components/Common/Fields/EditUserPasswordField";
  import { EMAIL_VALIDATOR, PASSWORD_VALIDATOR } from "@core/validators";
  import Captcha from "@/components/Common/Auth/Captcha";
  import RequiredField from "@/components/Common/RequiredField";
  import FInput from "@/components/Common/FInput";
  import FButton from "@/components/Common/FButton";
  import FSelect from "@/components/Common/FSelect";

  export default {
    name: "SignUp",
    components: {
      FSelect,
      Background,
      Captcha,
      RequiredField,
      EditUserPasswordField,
      FInput,
      FButton
    },
    data () {
      return {
        isLoading: false,
        errors: [],
        isError: false,
        form: {
          manager: this.$route.query.manager || null,
          login: null,
          password: null,
          email: null,
          userType: null,
          managerIndex: null,
          telegram: null
        }
        // managers: [
        //   { id: null, name: "-" },
        //   { id: "39cb9287-a5a3-47c0-813b-5c6da819e651", name: "Светлана" },
        //   { id: "6cdfc4c8-5f58-4a3a-9321-d5729f52cef7", name: "Роман" },
        //   { id: "5fd2588f-c434-4490-90f0-4561c9504c8c", name: "Виталий" },
        //   { id: "21357a81-4abc-4a05-bb75-1d482abd88f6", name: "Анна" },
        //   { id: "4190e556-9d52-4ecb-b73d-f847a3566c79", name: "Росс" },
        //   { id: "2abf225c-738d-4461-9ca4-29a62e9ea099", name: "Сергей" },
        //   { id: "f16a874f-51ad-49c3-b1ea-948f734abae7", name: "Инна" },
        //   { id: null, name: "Людмила" },
        //   { id: null, name: "Екатерина" },
        //   { id: null, name: "Алина" },
        //   { id: null, name: "Евгений" }
        // ]
      };
    },
    computed: {
      telegramLogin () {
        return `@${ this.form.telegram }`;
      },
        
      _telegram: {
        get () {
          return this.form.telegram?.replace(/@/g, "");
        },

        set (value) {
          this.form.telegram = "@" + value;
        }
      },
        
      emailValidator () {
        return EMAIL_VALIDATOR;
      },
        
      passwordValidator () {
        return PASSWORD_VALIDATOR;
      },

      isRequiredTelegram () {
        return this.isValidatorPattern(this._telegram, /^[\w+]{5,}$/);
      },

      isRequiredPassword () {
        return this.isValidatorPattern(this.form.password, this.passwordValidator);
      }
    },
    methods: {
      // getManagerId (index) {
      //   return this.managers[index]?.id;
      // },

      isValidatorPattern (value, pattern) {
        return new RegExp(pattern).test(value);
      },

      submit (captcha) {
        this.isError = false;
        this.isLoading = true;
        const { managerIndex, ...fields } = this.form;
        // const managerId = this.getManagerId(managerIndex);

        this.$store.dispatch("USER_REGISTRATION", {
          ...fields,
          // managerId,
          captcha: captcha
        }).then(() => {
          this.$ym?.reachGoal("FAIRPAY_REGISTRATION");
          this.$router.push({ name: "mail:send" }).catch(_ => {});
        }).catch(() => {
          this.isError = true;
        }).finally(() => {
          this.isLoading = false;
        });
      },

      captchaExecute () {
        this.$refs.captcha.$refs.hcaptcha.execute();
      },

      getRoles () {
        const items = [
          {
            value: "advertiser",
            label: this.$t("common.auth.signUp.advertiser")
          },
          {
            value: "webmaster",
            label: this.$t("common.auth.signUp.webmaster")
          }
        ];
        return {
          items,
          count: items.length
        };
      }
    }
  };
</script>

<style lang="scss" scoped>
    $light-border: rgb(222, 227, 236);
    $light-text: rgb(96, 98, 102);;

    .form {
        .notification {
            left: 50%;
            width: 200%;
            padding: 1em 1.10295em;
            font-size: 0.85em;
            max-width: 500px;
            transform: translateX(-50%);
        }

        ::v-deep {
            .el-form-item__error {
                position: relative;
            }
        }

        &-wrapper {
            z-index: 10;
        }

        .is-default-style {
            ::v-deep {
                .el-input {
                    border-color: $light-border;
                }
                .el-form-item__error {
                    color: $light-text;
                }
            }
        }
    }
    @media screen and (min-width: 1025px) {
        .form {
            top: 50%;
            right: 22vw;
            position: absolute;
            transform: translateY(-50%);
        }
    }

    .manager-select {
        ::v-deep {
            select {
                padding-right: 0.5em !important;
            }
        }
    }
</style>
