import Service from "@core/services/common/Service";
import { AddPayment } from "@core/store/types/admin/payments/Payments";
import { AxiosPromise } from "axios";
import addPaymentSystem from "./graphql/addPaymentSystem.graphql";
import deletePaymentSystem from "./graphql/deletePaymentSystem.graphql";
import paymentSystems from "./graphql/paymentSystems.graphql";
import updatePaymentSystem from "./graphql/updatePaymentSystem.graphql";
import disablePaymentSystem from "./graphql/disablePaymentSystem.graphql";
import enablePaymentSystem from "./graphql/enablePaymentSystem.graphql";

export class PaymentsService extends Service {
  /**
   * Метод получения списка платежных систем
   * @param {number} limit
   * @param {number} offset
   * @return {Promise<AxiosPromise>}
   */
  public static async getPaymentSystems (
    limit: number,
    offset: number
  ): Promise<AxiosPromise> {
    try {
      return await this.api.get("", {
        params: {
          query: paymentSystems,
          variables: {
            limit,
            offset
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  /**
   * Метод добавления платежной системы
   * @param {NullableRecord<AddPayment>} payload
   * @return {Promise<AxiosPromise>}
   */
  public static async addPaymentSystem (
    payload: NullableRecord<AddPayment>
  ): Promise<AxiosPromise> {
    try {
      return await this.api.post("", {
        query: addPaymentSystem,
        variables: {
          input: { ...payload }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  /**
   * Метод изменения платежной системы
   * @param {string} paymentSystemId
   * @param {NullableRecord<AddPayment>} payload
   * @return {Promise<AxiosPromise>}
   */
  public static async updatePaymentSystem (
    paymentSystemId: string,
    payload: NullableRecord<AddPayment>
  ): Promise<AxiosPromise> {
    try {
      return await this.api.post("", {
        query: updatePaymentSystem,
        variables: {
          paymentSystemId,
          input: {
            ...payload
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  /**
   * Метод удаления платежной системы
   * @param {string} id
   * @return {Promise<AxiosPromise>}
   */
  public static async deletePaymentSystem (
    id: string
  ): Promise<AxiosPromise> {
    try {
      return await this.api.post("", {
        query: deletePaymentSystem,
        variables: {
          id
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  /**
   * Метод запуска платежной системы
   * @param {string} paymentSystemId
   * @return {Promise<AxiosPromise>}
   */
  public static async enablePaymentSystem (
    paymentSystemId: string
  ): Promise<AxiosPromise> {
    try {
      return await this.api.post("", {
        query: enablePaymentSystem,
        variables: {
          paymentSystemId
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  /**
   * Метод остановки платежной системы
   * @param {string} paymentSystemId
   * @return {Promise<AxiosPromise>}
   */
  public static async disablePaymentSystem (
    paymentSystemId: string
  ): Promise<AxiosPromise> {
    try {
      return await this.api.post("", {
        query: disablePaymentSystem,
        variables: {
          paymentSystemId
        }
      });
    } catch (e) {
      throw e;
    }
  }
}
