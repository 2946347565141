import { Country, Type, WithdrawType } from "@core/logic/common/common";
import { Advertiser } from "@core/store/types/common/User";
import { AxiosPromise } from "axios";
import Service from "@core/services/common/Service";

type LandingParameters = "name" | "phone" | "surname" | "email" | "address" | "city" | "pcode" | "country" | "note2";


export interface Offer {
  name: string;
  offerType: string;
  withdrawType: string;
  webmasterReward: number;
  isLaunched: boolean;
  logo: Logo;
  photoUrl: string;
  landingUrl: string;
  landingParameters: LandingParameters[];
  description: string;
  categories: string[];
  countries: string[];
  rules: string;
  totalTimeHold: number;
  preCost: number;
  cost: number;
  trafficTypes: string[];
  id: string;
  intId: number;
  createdAt: string;
  trackingPageId: Nullable<string>;
  offerMinimalCommission?: Nullable<number>;
  minimalCommission?: Nullable<number>;
  advertiserMinimalCommission?: Nullable<number>;
}

interface Logo {
  id: string;
  url: string;
}

interface TrafficTypes {
  trafficTypes: {
    context: boolean;
    brand: boolean;
    popup: boolean;
    clickunder: boolean;
    doorway: boolean;
    email: boolean;
    mobile: boolean;
    motive: boolean;
    broker: boolean;
    teaser: boolean;
    banner: boolean;
    cashback: boolean;
    socialGroups: boolean;
    socialAd: boolean;
    socialApp: boolean;
    coupons: boolean;
    promocodes: boolean;
    autoblock: boolean;
    redirect: boolean;
    app: boolean;
    facebook: boolean;
    youtube: boolean;
    instagram: boolean;
  };
}

interface OfferResponse {
  id: string;
  intId: number;
  name: string;
  type: Type;
  totalSoftCapacity: number;
  usedSoftCapacity: number;
  withdrawType: WithdrawType;
  webmasterReward: number;
  advertiserMinimalWriteOff: number | undefined;
  advertiserWriteOff: number;
  softCapacity: number;
  categories: null | Array<never>;
  countries: null | Country[];
  createdAt: Date;
  description: string | undefined;
  holdTime: number;
  isLaunched: boolean;
  landing: null | {
    cost: number;
    preCost: number;
    trackingPageId: string;
  };
  photoUrl: string;
  rules: string | undefined;
  landingUrl: string;
  logo: null | Logo;
  maximalIndividualRate: number | undefined;
  minimalIndividualRate: number | undefined;
  webmasters: Array<never>;
  advertisers: Array<never>;
}

export interface LandingResponse {
  id: string;
  name: string;
  offerId: string;
  discriminator: string;
  domains: Record<string, unknown>[];
  options: Record<string, unknown>;
  createdAt: Date;
}

export class Landing {
  public id: string;
  public name: string;
  public offerId: string;
  public discriminator: string;
  public domains: Record<string, unknown>[];
  public options: Record<string, unknown>;
  public createdAt: Date;

  constructor ({
    id,
    name,
    offerId,
    discriminator,
    domains,
    options,
    createdAt
  }: LandingResponse) {
    this.id = id;
    this.name = name;
    this.offerId = offerId;
    this.discriminator = discriminator;
    this.domains = domains;
    this.options = options;
    this.createdAt = createdAt;
  }
}

// tslint:disable-next-line:class-name
export class CreatedOffer {
  public id: string | undefined;
  public intId: number | undefined;
  public name: string | undefined;
  public categories: Array<string> | null | undefined = [];
  public description: string | undefined;
  public countries: Country[] | null | undefined = [];
  public type: Type | undefined;
  public withdrawType: WithdrawType | undefined;
  public webmasterReward: number | undefined;
  public advertisers: Array<Advertiser> | undefined;
  public advertiserMinimalWriteOff: number | undefined;
  public minimalIndividualRate: number | undefined;
  public maximalIndividualRate: number | undefined;
  public advertiserWriteOff: number | undefined;
  public holdTime: number | undefined;
  public photoUrl: string | undefined;
  public rules: string | undefined;
  public landingUrl: string | undefined;
  public logo: Logo | null | undefined;
  public cost: number | undefined;
  public preCost: number | undefined;
  public isLaunched: boolean | undefined;
  public createdAt: Date | undefined;

  constructor (payload: OfferResponse | undefined) {
    if (payload) {
      this.id = payload.id;
      this.intId = payload.intId;
      this.name = payload.name;
      this.categories = payload.categories;
      this.description = payload.description;
      this.countries = payload.countries;
      this.type = payload.type;
      this.withdrawType = payload.withdrawType;
      this.webmasterReward = payload.webmasterReward;
      this.advertisers = payload.advertisers;
      this.advertiserMinimalWriteOff = payload.advertiserMinimalWriteOff;
      this.minimalIndividualRate = payload.minimalIndividualRate;
      this.maximalIndividualRate = payload.maximalIndividualRate;
      this.advertiserWriteOff = payload.advertiserWriteOff;
      this.holdTime = payload.holdTime;
      this.photoUrl = payload.photoUrl;
      this.rules = payload.rules;
      this.landingUrl = payload.landingUrl;
      this.preCost = payload.landing?.preCost;
      this.cost = payload.landing?.cost;
      this.logo = payload.logo;
      this.isLaunched = payload.isLaunched;
      this.createdAt = payload.createdAt;
    }
  }
}

// tslint:disable-next-line:max-classes-per-file
export class OfferService extends Service{
  public static getOffer (offerId: string): AxiosPromise<OfferResponse> {
    return this.rest.get(`/admin/offers/${ offerId }`);
  }

  public static getOfferTrafficTypes (offerId: string): AxiosPromise<TrafficTypes> {
    return this.rest.get(`/admin/offers/${ offerId }/trafficTypes`);
  }

  public static updateOfferTrafficTypes (offerId: string, params: any): AxiosPromise<OfferResponse> {
    return this.rest.put(`/admin/offers/${ offerId }/trafficTypes`, { ...params });
  }

  public static getOfferWebmasters (offerId: string, params: any): AxiosPromise<OfferResponse> {
    return this.rest.get(`/admin/offers/${ offerId }/webmasters`, { params });
  }

  public static getOfferWebmastersAvailable (offerId: string, params: any): AxiosPromise<OfferResponse> {
    return this.rest.get(`/admin/offers/${ offerId }/webmasters/available`, { params });
  }

  public static getOfferWebmastersWithoutRate (offerId: string, params: any): AxiosPromise<OfferResponse> {
    return this.rest.get(`/admin/rates/offer/${ offerId }/webmasters-without-rate`, { params });
  }

  public static addOfferWebmastersAvailable (offerId: any, webmasters: any): AxiosPromise<OfferResponse> {
    return this.rest.put(`/admin/offers/${ offerId }/webmasters`, { webmasters });
  }

  public static deleteOfferWebmasters (offerId: any, webmasters: any): AxiosPromise<OfferResponse> {
    return this.rest.delete(`/admin/offers/${ offerId }/webmasters`, { data: { webmasters } });
  }

  public static addOfferWebmasterIndividualRate (offerId: string, params: any): AxiosPromise<OfferResponse> {
    return this.rest.post(`/admin/rates/offer/${ offerId }`, params);
  }

  public static updateOfferWebmasterIndividualRate (rateId: string, rate: any): AxiosPromise<OfferResponse> {
    return this.rest.put(`/admin/rates/${ rateId }`, { ...rate });
  }

  public static getOfferAdvertisers (offerId: string, params: any): AxiosPromise<OfferResponse> {
    return this.rest.get(`/admin/offers/${ offerId }/advertisers`, { params });
  }

  public static addOfferAdvertisers (offerId: any, advertisers: any): AxiosPromise<OfferResponse> {
    return this.rest.put(`/admin/offers/${ offerId }/advertisers`, { advertisers });
  }

  public static deleteOfferAdvertisers (offerId: any, advertisers: any): AxiosPromise<OfferResponse> {
    return this.rest.delete(`/admin/offers/${ offerId }/advertisers`, { data: { advertisers } });
  }

  public static getOfferAdvertiserWebmasters (offerId: string, params: any): AxiosPromise<OfferResponse> {
    return this.rest.get(`admin/rates/advertisers/offer/${ offerId }`, { params });
  }

  public static addOfferAdvertiserIndividualRate (offerId: string, rates: any): AxiosPromise<OfferResponse> {
    return this.rest.post(`admin/rates/advertisers/offer/${ offerId }`, { rates });
  }

  public static updateOfferAdvertiser (offerId: string, params: any): AxiosPromise<OfferResponse> {
    return this.rest.put(`/admin/offers/${ offerId }/edit-advertiser`, { ...params });
  }

  public static updateOfferAdvertiserIndividualRate (rateId: string, rate: any): AxiosPromise<OfferResponse> {
    return this.rest.put(`/admin/rates/advertisers/${ rateId }`, { ...rate });
  }

  public static deleteOfferAdvertiserIndividualRate (rateId: string): AxiosPromise<OfferResponse> {
    return this.rest.delete(`/admin/rates/advertisers/${ rateId }`);
  }

  public static createOffer (offer: CreatedOffer): AxiosPromise<OfferResponse> {
    return this.rest.post("/admin/offers", offer);
  }

  public static updateOffer (offer: CreatedOffer): AxiosPromise<OfferResponse> {
    return this.rest.put(`/admin/offers/${ offer.id }`, offer);
  }

  public static addOfferCallCenters (offerId: string, callCenterIds: any): AxiosPromise<OfferResponse> {
    return this.rest.post(`/admin/offers/${ offerId }/callCenters`, { callCenterIds });
  }

  static getLandings (offerId: string, params: any): AxiosPromise<LandingResponse> {
    return this.rest.get(`admin/offers/${ offerId }/pages`, { params });
  }
}
