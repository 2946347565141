import { OfferEditWebmastersServices } from "@core/services/admin/offer/edit/OfferEditWebmastersServices";
import {
  ADD_OFFER_WEBMASTER_INDIVIDUAL_RATE,
  BEFORE_GET_OFFER_WEBMASTERS,
  DELETE_OFFER_WEBMASTER_INDIVIDUAL_RATE,
  GET_OFFER_ID,
  GET_OFFER_WEBMASTERS,
  GET_WEBMASTERS_BY_ID,
  PROXY_OFFER_WEBMASTER_INDIVIDUAL_RATE,
  SET_EMPTY, SET_WEBMASTER_REWARD,
  UPDATE_MAXIMAL_ALLOWED_WEBMASTER_REWARD,
  UPDATE_MINIMAL_ALLOWED_REWARD,
  UPDATE_OFFER_WEBMASTER_INDIVIDUAL_RATE,
  UPDATE_OFFER_WEBMASTER_SOFT_CAPACITY, WEBMASTER_REWARD
} from "@core/store/action-constants";
import {
  SET_OFFER_WEBMASTERS,
  UPDATE_LOADING,
  UPDATE_OFFER_FILTERS,
  UPDATE_OFFER_WEBMASTER_RATE,
  UPDATE_OFFER_WEBMASTERS,
  UPDATE_OFFER_WEBMASTERS_PAGINATION
} from "@core/store/mutation-constants";
import {
  CreateIndividualRate,
  IndividualRate,
  OfferWebmaster,
  PartialOfferWebmastersState,
  RequestIndividualRate
} from "@core/store/types/admin/offer/OfferWebmasters.d";
import { PaginationOutput } from "@core/store/types/PaginationOutput";
import Vue from "vue";
import { Module } from "@core/store/logic/Module";

export class OfferEditWebmasters<S extends PartialOfferWebmastersState, R> extends Module<S, R> {
  constructor () {
    super();

    this.getters({
      [GET_WEBMASTERS_BY_ID]: state => function (ids: string[]): OfferWebmaster[] | undefined {
        if (Array.isArray(state.webmasters?.items)) {
          // @ts-ignore
          return ids?.map(id => state.webmasters.items?.find((item: OfferWebmaster) => item.webmaster.id === id));
        }
      }
    });

    this.mutations({
      [SET_OFFER_WEBMASTERS] (state, webmasters: PaginationOutput<OfferWebmaster[]>) {
        state.webmasters = { ...webmasters };
      },

      [UPDATE_OFFER_WEBMASTERS] (state, webmasters: PaginationOutput<OfferWebmaster[]>) {
        if (state.webmasters) {

          state.webmasters = {
            count: webmasters.count,
            items: state.webmasters?.items?.concat(webmasters.items as OfferWebmaster[])
          };
        }
      },

      [UPDATE_OFFER_WEBMASTER_RATE] (state, {
        newRate,
        index
      }) {
        if (state.webmasters?.items) {
          Vue.set(state.webmasters.items[index], "individualRate", newRate);
        }
      },

      [UPDATE_OFFER_WEBMASTER_SOFT_CAPACITY] (state, {
        softCapacity,
        index
      }) {
        if (state.webmasters?.items) {
          Vue.set(state.webmasters.items[index], "softCapacity", softCapacity);
        }
      },

      [UPDATE_LOADING] (state, {
        index,
        params,
        value
      }) {
        if (state.webmasters?.items) {
          for (const key in params) {
            if (params.hasOwnProperty(key)) {
              const prop = `is${ key.charAt(0).toUpperCase() + key.slice(1) }Loading`;
              Vue.set(state.webmasters.items[index], prop, value);
            }
          }

        }
      },

      [UPDATE_OFFER_WEBMASTERS_PAGINATION] (state, pagination) {
        state.pagination = { ...state.pagination, ...pagination };
      },

      [UPDATE_OFFER_FILTERS] (state, payload) {
        state.filters = { ...state.filters, ...payload };
      },

      [UPDATE_MAXIMAL_ALLOWED_WEBMASTER_REWARD] (state, payload) {
        if (state.webmasters && state.webmasters.items) {
          const webmastersIds = state.webmasters.items.map(item => item.webmaster.id);

          state.webmasters.items = state.webmasters.items.map((item, index) => {
            const newReward = payload[webmastersIds[index]];

            item.maximalAllowedWebmasterReward = newReward ? newReward : item.maximalAllowedWebmasterReward;

            return item;
          });
        }
      }
    });

    this.actions({
      SET_EMPTY ({ commit }) {
        commit(SET_EMPTY);
      },

      async [GET_OFFER_WEBMASTERS] ({
        commit,
        rootGetters,
        state
      }) {
        const offerId = rootGetters[`admin/offer/edit/common/${ GET_OFFER_ID }`];
        try {
          const { pagination, filters } = state;
          if (offerId) {
            const limit = pagination.perPage;
            const offset = (pagination.page - 1) * limit;
            let loginOrIntId = filters?.loginOrIntId;
            loginOrIntId = loginOrIntId && loginOrIntId.length >= 2 ? loginOrIntId : null;
            const { data: { offer: { webmasters } } } = await OfferEditWebmastersServices.getOfferWebmasters(
              {
                loginOrIntId
              },
              offerId,
              limit,
              offset
            );
            webmasters.items = webmasters.items.map((item: OfferWebmaster) => {
              if (item.individualRate) {
                const isLoading: { [key: string]: unknown } = {};
                for (let key in item.individualRate) {
                  if (item.individualRate.hasOwnProperty(key) && key !== "id") {
                    if (key === "moneyReward") {
                      key = "rate";
                    }
                    const prop = key.charAt(0).toUpperCase() + key.slice(1);
                    isLoading[`is${ prop }Loading`] = false;
                  }
                }
                return {
                  ...item,
                  ...isLoading
                };
              } else {
                return item;
              }
            });

            if (offset === 0) {
              commit(SET_OFFER_WEBMASTERS, webmasters);
            } else {
              commit(UPDATE_OFFER_WEBMASTERS, webmasters);
            }

            return webmasters;
          }
        } catch (e) {
          throw e;
        }
      },

      [BEFORE_GET_OFFER_WEBMASTERS] ({
        commit,
        dispatch,
        state
      }) {
        const {
          page,
          perPage
        } = state.pagination;

        commit(UPDATE_OFFER_WEBMASTERS_PAGINATION, {
          page: 1,
          perPage: perPage * page
        });

        dispatch(GET_OFFER_WEBMASTERS);

        commit(UPDATE_OFFER_WEBMASTERS_PAGINATION, {
          page,
          perPage
        });
      },

      async [ADD_OFFER_WEBMASTER_INDIVIDUAL_RATE] ({
        state,
        rootGetters,
        dispatch,
        commit
      }, {
        index,
        params
      }) {
        const offerId = rootGetters[`admin/offer/edit/common/${ GET_OFFER_ID }`];

        commit(UPDATE_LOADING, {
          index,
          params,
          value: true
        });

        try {
          if (state.webmasters?.items && offerId) {
            const {
              webmaster,
              softCapacity: softCap
            } = state.webmasters.items[index];
            const prop = Object.keys(params)[0];
            const newRate: CreateIndividualRate =
                Object.assign(
                  {
                    rate: null,
                    holdTime: null,
                    withdrawType: null,
                    webmasterId: webmaster.id,
                    softCapacity: softCap
                  },
                  { [prop]: isNaN(params[prop]) ? params[prop] : +params[prop] }
                );
            
            const { data: { addOfferWebmasterParameters } } =
              await OfferEditWebmastersServices.addOfferWebmasterParameters(offerId, [ newRate ]);
            
            const { softCapacity, ...individualRates } = addOfferWebmasterParameters[0];
            const { individualRate } = individualRates;
            
            dispatch(`admin/offer/edit/${ UPDATE_MINIMAL_ALLOWED_REWARD }`,
              {}, { root: true });
            
            commit(UPDATE_OFFER_WEBMASTER_RATE, {
              newRate: { ...individualRate },
              index
            });
            commit(UPDATE_OFFER_WEBMASTER_SOFT_CAPACITY, {
              softCapacity,
              index
            });
            commit(UPDATE_LOADING, {
              index,
              params,
              value: false
            });
          }
        } catch (e) {
          commit(UPDATE_LOADING, {
            index,
            params,
            value: false
          });
          throw e;
        }
      },

      async [UPDATE_OFFER_WEBMASTER_INDIVIDUAL_RATE] ({
        state,
        rootGetters,
        dispatch,
        commit
      }, {
        index,
        params
      }) {
        const offerId = rootGetters[`admin/offer/edit/common/${ GET_OFFER_ID }`];

        commit(UPDATE_LOADING, {
          index,
          params,
          value: true
        });

        try {
          if (state.webmasters?.items) {
            const {
              webmaster,
              individualRate,
              softCapacity: softCap,
              externalWebmasterId
            } = state.webmasters.items[index];
            const {
              moneyReward,
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              hold,
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              id,
              ...rates
            } = individualRate || {};
            const prop = Object.keys(params)[0];
            const propValue = !params[prop] || isNaN(params[prop]) ? params[prop] : +params[prop];

            const newRate: RequestIndividualRate =
              Object.assign(
                {
                  ...rates,
                  // @ts-ignore
                  rate: moneyReward?.value,
                  softCapacity: softCap,
                  externalWebmasterId
                },
                {
                  [prop]: propValue === 0 ? null : propValue
                }
              );
            const {
              softCapacity,
              ...individualRates
            } = await OfferEditWebmastersServices.editOfferWebmasterParameters(offerId, newRate, webmaster);

            dispatch(`admin/offer/edit/${ UPDATE_MINIMAL_ALLOWED_REWARD }`,
              {}, { root: true });

            commit(UPDATE_OFFER_WEBMASTER_RATE, {
              newRate: individualRates,
              index
            });
            commit(UPDATE_OFFER_WEBMASTER_SOFT_CAPACITY, {
              softCapacity,
              index
            });
            commit(UPDATE_LOADING, {
              index,
              params,
              value: false
            });
          }
        } catch (e) {
          commit(UPDATE_LOADING, {
            index,
            params,
            value: false
          });
          throw e;
        }
      },

      // async [DELETE_OFFER_WEBMASTER_INDIVIDUAL_RATE] ({
      //   state,
      //   rootGetters,
      //   dispatch,
      //   commit
      // }, {
      //   index,
      //   params
      // }) {
      //   const offerId = rootGetters[`admin/offer/edit/common/${ GET_OFFER_ID }`];
      //
      //   commit(UPDATE_LOADING, {
      //     index,
      //     params,
      //     value: true
      //   });
      //
      //   try {
      //     if (state.webmasters?.items) {
      //       const { id } = state.webmasters.items[index]?.webmaster;
      //       await OfferEditWebmastersServices.removeOfferWebmasterParameters(offerId, { webmasterId: id });
      //
      //       dispatch(`admin/offer/edit/${ UPDATE_MINIMAL_ALLOWED_REWARD }`,
      //         {}, { root: true });
      //
      //       commit(UPDATE_OFFER_WEBMASTER_RATE, {
      //         newRate: null,
      //         index
      //       });
      //       commit(UPDATE_OFFER_WEBMASTER_SOFT_CAPACITY, {
      //         newRate: null,
      //         index
      //       });
      //       commit(UPDATE_LOADING, {
      //         index,
      //         params,
      //         value: false
      //       });
      //     }
      //   } catch (e) {
      //     commit(UPDATE_LOADING, {
      //       index,
      //       params,
      //       value: false
      //     });
      //     throw e;
      //   }
      // },

      async [PROXY_OFFER_WEBMASTER_INDIVIDUAL_RATE] ({
        state,
        dispatch
      }, {
        index,
        params
      }) {
        const prop = Object.keys(params)[0];

        params[prop] = params[prop] === "" ? null : params[prop];

        if (state.webmasters?.items) {
          const {
            individualRate,
            softCapacity
          } = state.webmasters.items[index];
          if (!individualRate && !softCapacity) {
            await dispatch(ADD_OFFER_WEBMASTER_INDIVIDUAL_RATE, {
              index,
              params
            });
          } else {

            const {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              id,
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              hold,
              ...values
            } = individualRate || {};
            let isEmptyRate = true;

            for (const key in values as IndividualRate) {
              if (values.hasOwnProperty(key)) {
                if (values[key] && values[key] !== "") {
                  isEmptyRate = false;
                }
              }
            }
            if (isEmptyRate && !softCapacity && !params[prop]) {
              await dispatch(DELETE_OFFER_WEBMASTER_INDIVIDUAL_RATE, {
                index,
                params
              });
            } else {
              await dispatch(UPDATE_OFFER_WEBMASTER_INDIVIDUAL_RATE, {
                index,
                params
              });
            }
          }
        }
      },

      [UPDATE_MAXIMAL_ALLOWED_WEBMASTER_REWARD] ({
        state,
        dispatch
      }) {

        state.webmasters?.items?.forEach((item: OfferWebmaster) => {
          dispatch(WEBMASTER_REWARD, {
            webmasterId: item.webmaster.id,
            externalWebmasterId: item.externalWebmasterId
          });
        });
      },

      async [WEBMASTER_REWARD] ({ commit, rootGetters }, { webmasterId, externalWebmasterId }) {
        const offerId = rootGetters[`admin/offer/edit/common/${ GET_OFFER_ID }`];
        try {
          const { data: { maximalAllowedWebmasterReward } } = await OfferEditWebmastersServices.getMaximalAllowedWebmastersReward(
            offerId,
            webmasterId,
            externalWebmasterId
          );
          commit(SET_WEBMASTER_REWARD, { maximalAllowedWebmasterReward, externalWebmasterId });
        } catch (error) {
          throw error;
        }
      },

      [UPDATE_OFFER_WEBMASTERS_PAGINATION] ({ commit }, pagination) {
        commit(UPDATE_OFFER_WEBMASTERS_PAGINATION, pagination);
      },

      [UPDATE_OFFER_FILTERS] ({ commit }, filter) {
        commit(UPDATE_OFFER_FILTERS, filter);
      }
    });

    return this;
  }
}
