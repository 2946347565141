/*
* TODO: Удалить когда будет работать на gql
* */

import { Offer, OfferResponse, Offers, OffersService } from "@core/logic/webmaster/offers/offers";
import { OfferService } from "@core/services/webmaster/OfferService";
import { EXCLUDE_OFFER, GET_OFFERS, JOIN_OFFER, JOIN_PRIVATE_OFFER } from "@core/store/action-constants";
import { SET_OFFERS } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

export interface OffersState {
  offers: Offers | null;
  userOffers: Offers | null;
}

const state: OffersState = {
  offers: null,
  userOffers: null
};

const getters: GetterTree<OffersState, RootState> = {
  OFFERS: ({ offers }) => offers
};

const mutations: MutationTree<OffersState> = {
  [SET_OFFERS]: (state, payload: Offers): void => {
    state.offers = payload;
  }
};

const actions: ActionTree<OffersState, RootState> = {
  async [GET_OFFERS] ({ commit }, params) {
    const { data } = await OffersService.getOffers(params);
    const offers: Offers = { ...data, items: data.items.map((item: OfferResponse) => new Offer(item)) };
    commit(SET_OFFERS, offers);
  },
  
  async [JOIN_PRIVATE_OFFER] (_, id) {
    await OfferService.joinPrivateOffer(id);
  },
  
  async [JOIN_OFFER] (_, id) {
    const { data } = await OffersService.joinOffer(id);
    return data;
  },
  
  async [EXCLUDE_OFFER] (_, id) {
    const { data } = await OffersService.excludeOffer(id);
    return data;
  }
};

export const topOffers: Module<OffersState, RootState> = {
  state,
  getters,
  actions,
  mutations
};