import { OffersService, WebmasterIndividualRates } from "@core/services/admin/offers/offers";
import { hasPermissions } from "@core/mixins/permissions";
import { OffersService as GqlOffersService } from "@core/services/admin/offers/OffersService";
import {
  DELETE_OFFER,
  GET_OFFERS,
  LAUNCH_OFFER,
  STOP_OFFER,
  UPDATE_SELECTED_OFFERS
} from "@core/store/action-constants";
import { webmastersList } from "@core/store/modules/admin/lists/webmastersList";
import {
  SET_EMPTY,
  SET_OFFERS,
  UPDATE_FILTERS,
  UPDATE_PAGINATION,
  UPDATE_SORTINGS,
  CLEAR_FILTERS
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { OffersState as OriginalOffersState } from "@core/store/types/admin/offers";
import { ActionTree, Module, MutationTree } from "vuex";
import { webmasters } from "./modules/webmasters";
import { webmastersManagementModal } from "@core/store/modules/admin/offers/modules/webmastersManagementModal";
import { withdrawTypesList } from "@core/store/modules/admin/lists/withdrawTypesList";
import _intersectionBy from "lodash/intersectionBy";
import _uniqBy from "lodash/uniqBy";
import { filterPreparation } from "@core/helpers/filterPreparation";
import { advertisersList } from "@core/store/modules/admin/lists/advertisersList";
import offersFieldsList from "@core/store/modules/admin/offers/modules/datasets/offersFieldsList.json";
import { SelectedFields } from "@core/store/types/admin/statistic/detailing/detailingState";

export interface OffersState extends OriginalOffersState {
  webmasterIndividualRates: WebmasterIndividualRates | null;
  selectedOffers: any;
  selectedFields: SelectedFields[];
}

const initialState = (): OffersState => {
  return {
    webmasterIndividualRates: null,
    selectedOffers: [],
    selectedFields: offersFieldsList,
    data: null,
    pagination: {
      perPage: 25,
      page: 1
    },
    filters: {},
    sort: null,
    order: null
  };
};

export const state: () => OffersState = initialState;

const mutations: MutationTree<OffersState> = {
  [SET_OFFERS] (state, offers) {
    state.data = offers;
  },

  [UPDATE_PAGINATION] (state, pagination) {
    state.pagination = { ...state.pagination, ...pagination };
  },

  [UPDATE_FILTERS] (state, filters) {
    ["countries", "categories", "advertisers", "webmaster"].forEach((key) => {
      if (
        filters.hasOwnProperty(key) &&
        Array.isArray(filters[key]) &&
        !filters[key].length
      ) {
        filters[key] = null;
      }
    });
    state.filters = { ...state.filters, ...filters };
  },

  SET_INDIVIDUAL_RATES (state, payload: WebmasterIndividualRates) {
    state.webmasterIndividualRates = payload;
  },

  [SET_EMPTY] (state) {
    Object.assign(state, initialState());
  },

  [UPDATE_SORTINGS] (state, sort) {
    state.sort = sort.value;
    state.order = sort.order.toUpperCase();
  },

  [UPDATE_SELECTED_OFFERS] (state, offers: any) {
    state.selectedOffers = offers;
  },

  [CLEAR_FILTERS]: (state): void => {
    state.filters = {
      ...initialState().filters,
      currency: state.filters.currency
    };
  }
};

const actions: ActionTree<OffersState, RootState> = {
  async [GET_OFFERS] ({ commit, state, dispatch, rootGetters }) {
    const { filters: { regions, ...filters }, pagination: { perPage: limit, page }, sort, order } = state;
    const offset = (page - 1) * limit;
    const hasPermissionsAdvertiser = hasPermissions(["OFFERS.VIEW_INCLUDED_ADVERTISERS.OWN", "OFFERS.VIEW_INCLUDED_ADVERTISERS.ALL"], rootGetters.permissions);

    const { data: { offers } } = await GqlOffersService.getOffers({
      regions: regions?.length ? regions : null,
      ...filterPreparation(filters)
    }, limit, offset, sort, order, hasPermissionsAdvertiser);

    commit(SET_OFFERS, offers);
    dispatch(UPDATE_SELECTED_OFFERS);

    return offers;
  },

  async GET_INDIVIDUAL_RATES ({ commit }, { webmasterId, ...params }) {
    const { data } = await OffersService.getWebmasterIndividualRates(webmasterId, params);
    commit("SET_INDIVIDUAL_RATES", data);

    return data;
  },

  async [DELETE_OFFER] (_, offerId: string) {
    await OffersService.deleteOffer(offerId);
  },

  async [LAUNCH_OFFER] (_, offerId: string) {
    await OffersService.launchOffer(offerId);
  },

  async [STOP_OFFER] (_, offerId: string) {
    await OffersService.stopOffer(offerId);
  },

  [UPDATE_PAGINATION] ({ commit }, pagination) {
    commit(UPDATE_PAGINATION, pagination);
  },

  [UPDATE_FILTERS] ({ commit }, filter) {
    commit(UPDATE_FILTERS, filter);
    commit(UPDATE_PAGINATION, { page: 1 });
  },

  [UPDATE_SELECTED_OFFERS] ({ state, commit }, offers) {
    if (offers) {
      commit(UPDATE_SELECTED_OFFERS, offers);
    } else {
      const offers = state.data?.items || [];
      const selectedOffers = state.selectedOffers || [];
      const intersectionOffers = _intersectionBy(offers, selectedOffers, "id");
      const newSelectedOffers = _uniqBy([ ...intersectionOffers, ...selectedOffers ], "id");
      commit(UPDATE_SELECTED_OFFERS, newSelectedOffers);
    }
  },

  [CLEAR_FILTERS] ({ commit }) {
    commit(CLEAR_FILTERS);
    commit(UPDATE_PAGINATION, { page: 1 });
  }
};

export const offers: Module<OffersState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    webmasters,
    webmastersList,
    advertisersList,
    withdrawTypesList,
    webmastersManagement: webmastersManagementModal
  }
};
