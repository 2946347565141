import { AdminStatisticByGroups } from "@core/logic/admin/statistic/AdminStatisticByGroups";
import { StatisticsService } from "@core/logic/admin/statistic/statistic";
import { GET_STATISTIC } from "@core/store/action-constants";
import { Compare } from "@core/store/modules/common/compare";
import { SET_STATISTIC } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import moment from "moment";
import { ActionTree, Module, MutationTree } from "vuex";
import { sortFieldGroupsStatistic } from "@core/helpers/sortFieldGroupsStatistic";
import { ApproveCompareItem, StatisticResponse } from "@core/store/types/common/statistic/common/statistic";
import { userContextStorage } from "@core/store/modules/common/userContextStorage";
import { currencyList } from "@core/store/modules/common/lists/currencyList";

export interface StatisticState {
  statistics: AdminStatisticByGroups | null;
  subStatistics: { [key: string]: AdminStatisticByGroups } | null;
  statisticsOld: AdminStatisticByGroups | null;
  advertisers: {
    today: AdminStatisticByGroups | null;
    yesterday: AdminStatisticByGroups | null;
  };
  webmasters: {
    today: AdminStatisticByGroups | null;
    yesterday: AdminStatisticByGroups | null;
  };
  offers: {
    today: AdminStatisticByGroups | null;
    yesterday: AdminStatisticByGroups | null;
  };
  statisticToday: AdminStatisticByGroups | null;
  statisticYesterday: AdminStatisticByGroups | null;
  debitCompare: Compare | null;
  prepaidCompare: Compare | null;
  commissionCompare: Compare | null;
  approveCompare: Compare | null;
}

const state: StatisticState = {
  statistics: null,
  subStatistics: null,
  statisticsOld: null,
  advertisers: {
    today: null,
    yesterday: null
  },
  webmasters: {
    today: null,
    yesterday: null
  },
  offers: {
    today: null,
    yesterday: null
  },
  statisticToday: null,
  statisticYesterday: null,
  debitCompare: null,
  prepaidCompare: null,
  commissionCompare: null,
  approveCompare: null
};

const mutations: MutationTree<StatisticState> = {
  [SET_STATISTIC] (state, payload: AdminStatisticByGroups) {
    state.statistics = payload;
  },
  
  SET_OLD_STATISTIC (state, payload: AdminStatisticByGroups) {
    state.statisticsOld = payload;
  },
  
  SET_ADVERTISERS_STATISTIC (state, payload) {
    state.advertisers = {
      today: payload.today,
      yesterday: payload.yesterday
    };
  },
  
  SET_WEBMASTERS_STATISTIC (state, payload) {
    state.webmasters = {
      today: payload.today,
      yesterday: payload.yesterday
    };
  },
  
  SET_OFFERS_STATISTIC (state, payload) {
    state.offers = {
      today: payload.today,
      yesterday: payload.yesterday
    };
  },
  
  SET_STATISTICS_COMPARE (state, payload) {
    state.statisticToday = payload.today.data.statistics;
    state.statisticYesterday = payload.yesterday.data.statistics;
  },
  
  SET_DEBIT_COMPARE (state, payload) {
    state.debitCompare = {
      today: payload.today.data.debitDashboard,
      yesterday: payload.yesterday.data.debitDashboard
    };
  },
  
  SET_PREPAID_COMPARE (state, payload) {
    state.prepaidCompare = {
      today: payload.today.data.prepaidExpenseDashboard,
      yesterday: payload.yesterday.data.prepaidExpenseDashboard
    };
  },
  
  SET_COMMISSION_COMPARE (state, payload) {
    state.commissionCompare = {
      today: payload.today.data.commissionDashboard,
      yesterday: payload.yesterday.data.commissionDashboard
    };
  },
  
  SET_APPROVE_COMPARE (state, payload) {
    state.approveCompare = {
      today: payload.today.data.approveDashboard,
      yesterday: payload.yesterday.data.approveDashboard
    };
  }
};

const actions: ActionTree<StatisticState, RootState> = {
  async [GET_STATISTIC] ({ commit }, params): Promise<StatisticResponse> {
    const { filters, excludedFilters, groups } = params;
    const { data: { statistics } } = await StatisticsService.getAdminStatistic(filters, excludedFilters, groups);
    
    sortFieldGroupsStatistic(statistics.items, "country", "region", "category");
    
    statistics.items = statistics?.items.map(item => {
      return {
        ...item,
        moneyCommissions: item.moneyAdvertiserWrittenOff - item.moneyWebmasterAccrued
      };
    });
    
    const statisticsTotal = statistics.aggregates.total;
    const statisticsAverage = statistics.aggregates.average;
  
    statistics.aggregates.total = {
      ...statisticsTotal,
      moneyCommissions: statisticsTotal?.moneyAdvertiserWrittenOff - statisticsTotal?.moneyWebmasterAccrued
    };
    
    statistics.aggregates.average = {
      ...statisticsAverage,
      moneyCommissions: statisticsAverage?.moneyAdvertiserWrittenOff - statisticsAverage?.moneyWebmasterAccrued
    };
    
    commit(SET_STATISTIC, statistics);
    
    return statistics;
  },
  
  async GET_DEBIT_COMPARE ({ commit }) {
    const now = moment();
    const [today, yesterday] = await Promise.all([
      StatisticsService.getAdminDebit(now.toDate()),
      StatisticsService.getAdminDebit(now.subtract(1, "day").toDate())
    ]);
    commit("SET_DEBIT_COMPARE", { today, yesterday });
  },
  
  async GET_PREPAID_COMPARE ({ commit }) {
    const now = moment();
    const [today, yesterday] = await Promise.all([
      StatisticsService.getAdminPrepaid(now.toDate()),
      StatisticsService.getAdminPrepaid(now.subtract(1, "day").toDate())
    ]);
    commit("SET_PREPAID_COMPARE", { today, yesterday });
  },
  
  async GET_COMMISSION_COMPARE ({ commit }) {
    const now = moment();
    const [today, yesterday] = await Promise.all([
      StatisticsService.getAdminCommission(
        now.clone().startOf("day").toDate(),
        now.clone().toDate()
      ),
      StatisticsService.getAdminCommission(
        now.clone().startOf("day").subtract(1, "day").toDate(),
        now.clone().subtract(1, "day").toDate()
      )
    ]);
    commit("SET_COMMISSION_COMPARE", { today, yesterday });
  },
  
  async GET_APPROVE_COMPARE ({ commit }) {
    const now = moment();
    const [today, yesterday] = await Promise.all([
      StatisticsService.getAdminApproves(
        now.clone().startOf("day").toDate(),
        now.clone().toDate()
      ),
      StatisticsService.getAdminApproves(
        now.clone().startOf("day").subtract(1, "day").toDate(),
        now.clone().subtract(1, "day").toDate()
      )
    ]);
    commit("SET_APPROVE_COMPARE", { today, yesterday });
  },
  
  async GET_STATISTICS_COMPARE ({ commit }, payload) {
    const now = moment();
    const { excludedFilters, groups, ...fields } = payload;
    const statisticsGroups = [...groups, "CURRENCY"];
    
    const [today, yesterday] = await Promise.all([
      StatisticsService.getAdminStatistic({
        ...fields,
        dateStart: now.clone().startOf("day").toDate(),
        dateEnd: now.clone().toDate()
      },
      excludedFilters,
      statisticsGroups),
      StatisticsService.getAdminStatistic({
        ...fields,
        dateStart: now.clone().startOf("day").subtract(1, "day").toDate(),
        dateEnd: now.clone().subtract(1, "day").toDate()
      },
      excludedFilters,
      statisticsGroups)
    ]);
    commit("SET_STATISTICS_COMPARE", { today, yesterday });
  }
};

export const statistic: Module<StatisticState, RootState> = {
  state,
  modules: {
    currencyList,
    userContextStorage
  },
  actions,
  mutations
};
