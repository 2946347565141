import Service from "@core/services/common/Service";
import { CRMOptions } from "@core/store/types/admin/crm/CreateCRMState";
import { CRMListItem } from "@core/store/types/admin/crm/CRMState";
import { hasEmptyObject } from "@core/helpers/hasEmptyObject";
import { AxiosPromise } from "axios";
import _cloneDeep from "lodash/cloneDeep";
import createCrm from "./graphql/createCrm.graphql";
import crm from "./graphql/crm.graphql";
import crmOptions from "./graphql/crmOptions.graphql";
import crms from "./graphql/crms.graphql";
import deleteCrm from "./graphql/deleteCrm.graphql";
import editCrm from "./graphql/editCrm.graphql";

// FixMe: newOptions не соответствует типу CRMOptions.
function prepareRequestOptions (
  options: CRMOptions,
  isWithoutRequest: boolean
): CRMOptions {
  const newOptions: CRMOptions = _cloneDeep(options);
  
  if (isWithoutRequest) {
    // @ts-ignore
    newOptions.integrationDetail = null;
  } else if (newOptions?.integrationDetail) {
    newOptions.integrationDetail = {
      ...newOptions.integrationDetail,
      // @ts-ignore
      requestBody: toJSON(newOptions.integrationDetail?.requestBody),
      // @ts-ignore
      requestHeaders: toJSON(newOptions.integrationDetail?.requestHeaders)
    };
  }
  if (hasEmptyObject(newOptions?.recoveryDetail)) {
    // @ts-ignore
    newOptions.recoveryDetail = null;
  }
  
  // @ts-ignore
  newOptions.flowRedirect = toJSON(newOptions?.flowRedirect);
  
  return newOptions;
}

function toJSON (
  str: string | undefined
): Record<string, unknown> | null {
  return str?.length ? JSON.parse(str) : null;
}

export class CRMService extends Service {
  /**
   * TODO: Добавить описание
   * @param {number} limit
   * @param {number} offset
   * @return {Promise<AxiosPromise>}
   */
  public static async getCRMs (
    limit: number,
    offset: number
  ): Promise<AxiosPromise> {
    try {
      return await this.api.get("", {
        params: {
          query: crms,
          variables: {
            limit,
            offset
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  /**
   * TODO: Добавить описание
   * @param {string} alias
   * @return {Promise<AxiosPromise>}
   */
  public static async getCRMByAlias (
    alias: string
  ): Promise<AxiosPromise> {
    try {
      return await this.api.get("", {
        params: {
          query: crm,
          variables: {
            alias
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  /**
   * TODO: Добавить описание
   * @param {string} alias
   * @return {Promise<AxiosPromise>}
   */
  public static async getCRMOptions (
    alias: string
  ): Promise<AxiosPromise> {
    try {
      return await this.api.get("", {
        params: {
          query: crmOptions,
          variables: {
            alias
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  /**
   * TODO: Добавить описание
   * @param {CRMOptions} options
   * @param {boolean} isWithoutRequest
   * @return {Promise<AxiosPromise<CRMListItem>>}
   */
  public static async createCRM (
    options: CRMOptions,
    isWithoutRequest: boolean
  ): Promise<AxiosPromise<CRMListItem>> {
    const preparedOptions = prepareRequestOptions(options, isWithoutRequest);
    
    try {
      return await this.api.post("", {
        query: createCrm,
        variables: {
          input: {
            ...preparedOptions
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  /**
   * TODO: Добавить описание
   * @param {string} alias
   * @param {CRMOptions} options
   * @param {boolean} isWithoutRequest
   * @return {Promise<AxiosPromise<CRMListItem>>}
   */
  public static async editCRM (
    alias: string,
    options: CRMOptions,
    isWithoutRequest: boolean
  ): Promise<AxiosPromise<CRMListItem>> {
    const preparedOptions = prepareRequestOptions(options, isWithoutRequest);
    
    try {
      return await this.api.post("", {
        query: editCrm,
        variables: {
          alias,
          input: {
            ...preparedOptions
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  /**
   * TODO: Добавить описание
   * @param {string} alias
   * @return {Promise<AxiosPromise>}
   */
  public static async deleteCRM (
    alias: string
  ): Promise<AxiosPromise> {
    try {
      return await this.api.post("", {
        query: deleteCrm,
        variables: {
          alias
        }
      });
    } catch (e) {
      throw e;
    }
  }
}
