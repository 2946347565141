import { ASTGeneratedOutput, Generator } from "@core/services/common/Generator";
import badgesList from "@core/services/common/badges/datasets/badgesList.json";
import { BadgesCount, BadgesListFilters } from "@core/store/types/common/badges";

export default function badges (
  badgesOperations: Array<keyof BadgesCount>
): ASTGeneratedOutput {
  const params = {
    include: []
  };
  
  const gqlFields = {
    fields: [
      // TODO: Удалить items, когда станут необязательны для отправки
      {
        items: ["intId"]
      },
      "count"
    ]
  };
  
  /*
  *
  * В badgesList описываются все динамические поля
  * key - имя операции
  * name - уникальное имя фильтра
  * type - уникальный тип фильтра
  * value - дополнительные поля, то что передаём из фильтров
  *
  * */
  
  const gqlArray = badgesOperations.map((operation) => {
    const badges: BadgesListFilters = badgesList;
    const { name, type, value } = badges[operation as keyof BadgesListFilters];
    return {
      operation,
      ...gqlFields,
      variables: {
        [name]: {
          name: "filters",
          type,
          value
        }
      }
    };
  });
  
  return Generator.query(gqlArray, params);
}

