import { CRMService } from "@core/services/admin/crm/CRMService";
import {
  CHECK_VALID_OPTIONS,
  CREATE_CRM,
  DEFAULT_OPTIONS,
  EDIT_CRM,
  GET_FILTERED_CUSTOM_MACROS,
  PROXY_CRM,
  UPDATE_FLOW_REDIRECT,
  UPDATE_IS_WITHOUT_REQUEST,
  UPDATE_IS_AUTO_REQUESTS
} from "@core/store/action-constants";
import { flowRedirectMacrosList } from "@core/store/modules/admin/lists/flowRedirectMacrosList";
import { macrosList } from "@core/store/modules/admin/crm/lists/macrosList";
import { methodsList } from "@core/store/modules/admin/crm/lists/methodsList";
import { recoveryStrategyList } from "@core/store/modules/admin/crm/lists/recoveryStrategyList";
import { requestTypesList } from "@core/store/modules/admin/crm/lists/requestTypesList";
import { responseTypesList } from "@core/store/modules/admin/crm/lists/responseTypesList";
import {
  UPDATE_CUSTOM_MACROS,
  UPDATE_CUSTOM_MACROS_RECOVERY_DETAIL,
  UPDATE_INTEGRATION_DETAILS,
  UPDATE_RECOVERY_DETAILS,
  UPDATE_IS_VALID,
  UPDATE_MODAL_ACTIVE,
  UPDATE_MODAL_IS_EDIT,
  UPDATE_MODAL_OPTIONS,
  UPDATE_POSTBACK_STATUS_MAP
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { CustomMacros, PartialCreateCRMState } from "@core/store/types/admin/crm/CreateCRMState";
import _uniq from "lodash/uniq";
import Vue from "vue";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { transferRulesUrlList } from "@core/store/modules/admin/crm/lists/passingRulesIdUrlList";
import { strategyList } from "@core/store/modules/admin/crm/lists/strategyList";
import { responseFormatTypesList } from "@core/store/modules/admin/crm/lists/responseFormatTypesList";

function IsJsonString (str: string): boolean {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

function getMacrosString (string: string): string[] {
  if (string) {
    return Array.from(string.matchAll(/{(\w+)}/g)).map(arr => arr[1]);
  }
  return [];
}

function getMacrosJson (string: string): string[] {
  if (string && IsJsonString(string)) {
    const regExp = /{(.+?)}/g;
    const newCustomMacros: string[] = [];
    
    JSON.parse(string, function (key, value) {
      const keyMacros = Array.from(key?.matchAll(regExp)).map(arr => arr[1]);
      let keyValue;
      if (typeof value === "string") {
        keyValue = Array.from(value?.matchAll(regExp)).map(arr => arr[1]);
      }
      
      if (keyMacros) {
        newCustomMacros.push(...keyMacros);
      }
      if (keyValue) {
        newCustomMacros.push(...keyValue);
      }
    });
    return newCustomMacros;
  }
  return [];
}

function getCustomMacros (macrosList: string[] | null, systemMacros: string[] = []): string[] {
  return macrosList?.map(el => el.replace(/[{}]/g, ""))
    .filter(el => !systemMacros.includes(el)) || [];
}

const initialState = (): PartialCreateCRMState => {
  return {
    options: {
      name: null,
      alias: null,
      integrationDetail: {
        requestUrl: null,
        requestMethod: "GET",
        requestBodyType: "EMPTY",
        requestHeaders: null,
        requestBody: null,
        responseType: null,
        responseXpath: null
      },
      postbackDetail: {
        strategy: "TOKEN_AND_REMOTE_ID",
        statusMap: {
          accept: [],
          spam: [],
          cancel: [],
          redemption: []
        }
      },
      recoveryDetail: {
        isBulkRequests: false,
        transferIdKey: null,
        maxBulkRequests: null,
        requestUrl: null,
        transferIdStrategy: null,
        responseType: null,
        responseXpath: null
      },
      isAutoRequests: false,
      flowRedirect: null
    },
    customMacros: {
      customMacros: [],
      customFields: {}
    },
    
    isCRMCreateModalActive: false,
    isEdit: false,
    isValidOptions: true,
    isWithoutRequest: false
  };
};

const state: () => PartialCreateCRMState = initialState;

const getters: GetterTree<PartialCreateCRMState, RootState> = {
  [DEFAULT_OPTIONS]: state => function (): any {
    const macros = {};
    state.customMacros.customMacros.forEach((customMacros: string) => {
      Vue.set(macros, customMacros, state.customMacros.customFields[customMacros] || "");
    });
    return macros;
  },
  
  [GET_FILTERED_CUSTOM_MACROS]: state => function (): any {
    const macrosRequestUrl = getMacrosString(state.options.integrationDetail.requestUrl) || [],
      macrosRequestBody = getMacrosJson(state.options.integrationDetail.requestBody) || [],
      macrosRequestHeaders = getMacrosJson(state.options.integrationDetail.requestHeaders) || [],
      macrosRecoveryRequestUrl = getMacrosString(state.options.recoveryDetail.requestUrl) || [],
      macrosFlowRedirect = getMacrosJson(state.options.flowRedirect) || [];
    return _uniq(getCustomMacros(
      [
        ...macrosRequestUrl,
        ...macrosRequestBody,
        ...macrosFlowRedirect,
        ...macrosRequestHeaders,
        ...macrosRecoveryRequestUrl
      ],
      state.macrosList.data));
  }
};

const mutations: MutationTree<PartialCreateCRMState> = {
  SET_EMPTY: state => Object.assign(state, initialState()),
  
  [UPDATE_MODAL_OPTIONS] (state, option: any) {
    if (option.integrationDetail === null) {
      option.integrationDetail = initialState().options.integrationDetail;
    }
    state.options = { ...state.options, ...option };
  },
  
  [UPDATE_INTEGRATION_DETAILS] (state, option: any) {
    state.options.integrationDetail = { ...state.options.integrationDetail, ...option };
  },
  
  [UPDATE_RECOVERY_DETAILS] (state, option: any) {
    state.options.recoveryDetail = { ...state.options.recoveryDetail, ...option };
  },
  
  [UPDATE_POSTBACK_STATUS_MAP] (state, option: any) {
    state.options.postbackDetail.statusMap = { ...state.options.postbackDetail.statusMap, ...option };
  },
  
  [UPDATE_CUSTOM_MACROS] (state, macros: CustomMacros) {
    state.customMacros = { ...state.customMacros, ...macros };
  },
  
  [UPDATE_FLOW_REDIRECT] (state, payload) {
    state.options.flowRedirect = payload.flowRedirect;
  },
  
  [UPDATE_MODAL_IS_EDIT] (state, payload: boolean) {
    state.isEdit = payload;
  },
  
  [UPDATE_IS_VALID] (state, payload: boolean) {
    state.isValidOptions = payload;
  },
  
  [UPDATE_MODAL_ACTIVE] (state, payload: boolean) {
    state.isCRMCreateModalActive = payload;
  },
  
  [UPDATE_IS_WITHOUT_REQUEST] (state, payload: boolean) {
    state.isWithoutRequest = payload;
  },
  
  [UPDATE_IS_AUTO_REQUESTS] (state, payload: boolean) {
    state.options.isAutoRequests = payload;
  }
};

const actions: ActionTree<PartialCreateCRMState, RootState> = {
  async [PROXY_CRM] ({
    state,
    dispatch
  }) {
    try {
      if (state.isEdit) {
        await dispatch(EDIT_CRM);
      } else {
        await dispatch(CREATE_CRM);
      }
    } catch (e) {
      throw e;
    }
  },
  
  async [CREATE_CRM] ({
    state,
    getters
  }) {
    const defaultOptions = getters[DEFAULT_OPTIONS]();
    try {
      await CRMService.createCRM(
        {
          ...state.options,
          defaultOptions
        },
        state.isWithoutRequest
      );
    } catch (e) {
      throw e;
    }
  },
  
  async [EDIT_CRM] ({
    state,
    getters
  }) {
    const {
      alias,
      ...options
    } = state.options;
    const defaultOptions = getters[DEFAULT_OPTIONS]();
    try {
      await CRMService.editCRM(
        alias,
        {
          ...options,
          defaultOptions
        },
        state.isWithoutRequest
      );
    } catch (e) {
      throw e;
    }
  },
  
  [CHECK_VALID_OPTIONS] ({
    state,
    commit
  }) {
    if (!state.isValidOptions) {
      commit(UPDATE_IS_VALID, true);
    }
    const statusMap = state.options.postbackDetail.statusMap;
    
    for (const status in statusMap) {
      if (statusMap.hasOwnProperty(status)) {
        
        if (statusMap[status].length === 0) {
          commit(UPDATE_IS_VALID, false);
        }
      }
    }
  },
  
  [UPDATE_MODAL_ACTIVE] ({ commit }, payload: boolean) {
    if (payload) {
      commit("SET_EMPTY");
    }
    commit(UPDATE_MODAL_ACTIVE, payload);
  },
  
  [UPDATE_IS_WITHOUT_REQUEST] ({ commit }, payload: boolean) {
    commit(UPDATE_IS_WITHOUT_REQUEST, payload);
  },
  
  [UPDATE_IS_AUTO_REQUESTS] ({ commit }, payload: boolean) {
    commit(UPDATE_IS_AUTO_REQUESTS, payload);
  },
  
  [UPDATE_MODAL_OPTIONS] ({ commit }, payload: any) {
    commit(UPDATE_MODAL_OPTIONS, payload);
  },
  
  [UPDATE_INTEGRATION_DETAILS] ({ commit }, payload: any) {
    commit(UPDATE_INTEGRATION_DETAILS, payload);
  },
  
  [UPDATE_RECOVERY_DETAILS] ({ commit }, payload: any) {
    Object.keys(payload).forEach(key => {
      if (payload[key] === "") {
        payload[key] = null;
      }
    });
    if (payload?.isBulkRequests === false) {
      payload.maxBulkRequests = null;
    }
    
    commit(UPDATE_RECOVERY_DETAILS, payload);
  },
  
  [UPDATE_CUSTOM_MACROS] ({
    commit,
    getters
  }, payload: any) {
    commit(UPDATE_INTEGRATION_DETAILS, payload);
    commit(UPDATE_CUSTOM_MACROS, { customMacros: getters[GET_FILTERED_CUSTOM_MACROS]() });
  },
  
  [UPDATE_CUSTOM_MACROS_RECOVERY_DETAIL] ({
    commit,
    getters,
    dispatch
  }, payload: any) {
    dispatch(UPDATE_RECOVERY_DETAILS, payload);
    commit(UPDATE_CUSTOM_MACROS, { customMacros: getters[GET_FILTERED_CUSTOM_MACROS]() });
  },
  
  [UPDATE_FLOW_REDIRECT] ({
    commit,
    getters
  }, payload: any) {
    commit(UPDATE_FLOW_REDIRECT, payload);
    commit(UPDATE_CUSTOM_MACROS, { customMacros: getters[GET_FILTERED_CUSTOM_MACROS]() });
  },
  
  [UPDATE_POSTBACK_STATUS_MAP] ({ commit }, payload: any) {
    commit(UPDATE_POSTBACK_STATUS_MAP, payload);
  }
};

export const crmCreateModal: Module<PartialCreateCRMState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
  modules: {
    methodsList,
    requestTypesList,
    responseTypesList,
    strategyList,
    macrosList,
    recoveryStrategyList,
    transferRulesUrlList,
    responseFormatTypesList,
    flowRedirectMacrosList
  }
};
