import { DashboardTopCard } from "@core/store/modules/admin/dashboard/modules/dashboardTopCard";
import { adminsList } from "@core/store/modules/admin/lists/adminsList";
import { advertisersList } from "@core/store/modules/admin/lists/advertisersList";
import { offersList } from "@core/store/modules/admin/lists/offersList";
import { webmastersList } from "@core/store/modules/admin/lists/webmastersList";
import { withdrawTypesList } from "@core/store/modules/admin/lists/withdrawTypesList";
import { currencyList } from "@core/store/modules/common/lists/currencyList";

export const dashboard = {
  namespaced: true,
  modules: {
    offersList,
    webmastersList,
    adminsList,
    advertisersList,
    withdrawTypesList,
    advertisersTopCard: new DashboardTopCard("advertiser", {
      adminsList, currencyList
    }),
    webmastersTopCard: new DashboardTopCard("webmaster",{
      adminsList, currencyList
    }),
    offersTopCard: new DashboardTopCard("offer",{
      adminsList, currencyList
    })
  }
};
