import { profile } from "@core/store/modules/advertiser/profile";
import { AdvertiserOffersModule } from "@core/store/modules/advertiser/offers";
import { statistic } from "@core/store/modules/advertiser/statistic";
import { dictionaries } from "@core/store/modules/advertiser/dictionaries";
import { finances } from "@core/store/modules/advertiser/finances";
import { Module } from "@core/store/logic/Module";

export class CoreAdvertiser<S, R> extends Module<S, R> {
  constructor () {
    super();
  
    this.namespaced = true;
    
    this.modules({
      offers: new AdvertiserOffersModule().toModule(),
      profile,
      finances,
      statistic,
      dictionaries
    });
  }
}