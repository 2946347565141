import { rates } from "./modules/rates";
import { Module } from "vuex";
import { WebmastersDetailState } from "@core/store/types/admin/users/webmasters/Detail";
import { RootState } from "@core/store/root-state";

export const detail: Module<WebmastersDetailState, RootState> = {
  namespaced: true,
  modules: {
    rates
  }
};