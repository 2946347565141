import Service from "@core/services/common/Service";
import { OfferLandingsAddModalInput, Query } from "@core/store/types/admin/offer/OfferLandingsAddModal";
import { FileResponse } from "@core/store/types/common";
import { AxiosPromise } from "axios";
import createPage from "./graphql/createPage.graphql";
import updatePage from "./graphql/updatePage.graphql";
import _cloneDeep from "lodash/cloneDeep";

function prepareOptions (input: NullableRecord<OfferLandingsAddModalInput>): NullableRecord<OfferLandingsAddModalInput> {
  const { typeLanding, ...fields } = input;
  const newOptions: NullableRecord<OfferLandingsAddModalInput> = _cloneDeep(fields);
  const queryOptions: Query = {};
  
  if (typeLanding === "interior") {
    newOptions.externalDomain = null;
    return newOptions;
  }
  
  if (newOptions?.externalDomain?.path?.length === 0) {
    newOptions.externalDomain.path = null;
  }
  
  if (newOptions?.externalDomain?.query?.[0]?.key?.length === 0) {
    newOptions.externalDomain.query = null;
  }
  
  if (newOptions.externalDomain?.query) {
    newOptions.externalDomain.query.forEach(item => {
      queryOptions[item.key] = item.value;
    });
    // @ts-ignore
    newOptions.externalDomain.query = queryOptions;
  }
  
  return newOptions;
}

export class OfferLandingsAddModalService extends Service {
  public static createPage (input: OfferLandingsAddModalInput): AxiosPromise {
    // FIXME: Убрать, когда бек приведёт pageType списка лендингов и создания лендингов к одному виду
    const modifedInput = {
      ...input,
      pageType: input.pageType.toLocaleUpperCase()
    };
    // @ts-ignore
    const preparedOptions = prepareOptions(modifedInput);
    
    return this.api.post("", {
      query: createPage,
      variables: {
        input: preparedOptions
      }
    });
  }

  public static updatePage (pageId: string, fields: OfferLandingsAddModalInput): AxiosPromise {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { offerId, pageType, ...input } = fields;
    // @ts-ignore
    const preparedOptions = prepareOptions(input);

    return this.api.post("", {
      query: updatePage,
      variables: {
        pageId,
        input: preparedOptions
      }
    });
  }

  public static uploadLandingFile (fd: FormData): AxiosPromise<FileResponse> {
    return this.files.post("", fd);
  }
}
