import { FinancesCostsService } from "@core/services/admin/finances/financesCosts/FinancesCostsService";
import {
  ACCEPT_COST,
  AUTO_COST,
  FILTER_SELECTED_COSTS,
  GET_COSTS,
  GET_COSTS_BY_TYPE,
  PROCESS_COST,
  REJECT_COST,
  SET_EMPTY,
  UPDATE_SELECTED_COSTS
} from "@core/store/action-constants";
import { confirmPayoutModal } from "@core/store/modules/admin/finances/modules/financesCosts/FinancesCostsConfirmPayoutModal";
import { createPaymentModal } from "@core/store/modules/admin/finances/modules/financesCosts/financesCostsCreatePaymentModal";
import { adminsList } from "@core/store/modules/admin/lists/adminsList";
import { paymentSystemsList } from "@core/store/modules/common/lists/paymentSystemsList";
import { statusesList } from "@core/store/modules/admin/lists/statusesList";
import { webmastersList } from "@core/store/modules/admin/lists/webmastersList";
import { SET_COSTS, UPDATE_COSTS_FILTERS, UPDATE_COSTS_PAGINATION, UPDATE_COSTS_SORTINGS } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import {
  CostsListItem,
  CostsListState,
  PartialFinancesCostsState
} from "@core/store/types/admin/finances/financesCosts/financesCosts";
import _intersectionBy from "lodash/intersectionBy";
import _uniqBy from "lodash/uniqBy";
import moment from "moment";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

const initialState = (): PartialFinancesCostsState => {
  return {
    costs: null,
    filters: {
      intId: null,
      userId: null,
      status: ["CREATED", "AUTO_PROCESSING"],
      paymentSystemId: null,
      executorId: null,
      datepicker: {
        dateStart: null,
        dateEnd: moment().endOf("day").toDate()
      }
    },
    pagination: {
      page: 1,
      perPage: 25
    },
    sort: null,
    order: null,
    selectedCosts: []
  };
};

const state: () => PartialFinancesCostsState = initialState;

const getters: GetterTree<PartialFinancesCostsState, RootState> = {
  [GET_COSTS_BY_TYPE]: ({ selectedCosts }) => function (type: string): CostsListItem[] | undefined {
    switch (type) {
      case "auto":
        return selectedCosts?.filter(cost => cost.canBeAutoPay);
      case "created":
        return selectedCosts?.filter(cost => cost.status === "created");
      case "accept":
        return selectedCosts?.filter(cost => cost.status === "hand_processing");
      case "reject":
        return selectedCosts?.filter(cost =>
          cost.status === "created"
                    || cost.status === "hand_processing"
                    || cost.canBeAutoPay);
    }
  }
};

const mutations: MutationTree<PartialFinancesCostsState> = {
  [SET_EMPTY]: state => Object.assign(state, initialState()),

  [SET_COSTS] (state, items: CostsListState["costs"]) {
    state.costs = items;
  },

  [UPDATE_COSTS_FILTERS] (state, filter: any) {
    if (Array.isArray(filter.status) && !filter.status.length) {
      filter.status = null;
    }

    state.filters = { ...state.filters, ...filter };
  },

  [UPDATE_COSTS_PAGINATION] (state, pagination) {
    state.pagination = { ...state.pagination, ...pagination };
  },

  [UPDATE_SELECTED_COSTS] (state, costs: CostsListItem[]) {
    state.selectedCosts = costs;
  },

  [UPDATE_COSTS_SORTINGS] (state, {
    sort,
    order
  }): void {
    state.sort = sort;
    state.order = order.toUpperCase();
  }
};

const actions: ActionTree<PartialFinancesCostsState, RootState> = {
  async [GET_COSTS] ({ state, commit, dispatch }) {
    const limit = state.pagination.perPage;
    const offset = (state.pagination.page - 1) * limit;
    const { datepicker: { dateEnd, dateStart }, status, intId, ...filters } = state.filters;
    const { sort, order } = state;

    try {
      const outTransactions = await FinancesCostsService.getCosts(
        limit,
        offset,
        {
          dateEnd,
          dateStart,
          status: status?.length ? status : null,
          intId: intId?.length ? intId : null,
          ...filters
        },
        sort,
        order
      );
      outTransactions.items = outTransactions.items?.map((item: any) => {
        return {
          ...item,
          isCheckable: item.status !== "auto_processing" &&
                        item.status !== "processed" &&
                        item.status !== "rejected"
        };
      });
      commit(SET_COSTS, outTransactions);
      dispatch(UPDATE_SELECTED_COSTS);
    } catch (e: any) {
      throw new Error(e);
    }
  },

  async [AUTO_COST] ({ dispatch }, transactionIds) {
    try {
      await FinancesCostsService.autoCost(transactionIds);
      dispatch(FILTER_SELECTED_COSTS, transactionIds);
    } catch (e: any) {
      throw new Error(e);
    }
  },

  async [PROCESS_COST] ({ dispatch }, transactionIds) {
    try {
      await FinancesCostsService.processCost(transactionIds);
      dispatch(FILTER_SELECTED_COSTS, transactionIds);
    } catch (e: any) {
      throw new Error(e);
    }
  },

  async [ACCEPT_COST] ({ dispatch }, transactionIds) {
    try {
      await FinancesCostsService.acceptCost(transactionIds);
      dispatch(FILTER_SELECTED_COSTS, transactionIds);
    } catch (e: any) {
      throw new Error(e);
    }
  },

  async [REJECT_COST] ({ dispatch }, transactionIds) {
    try {
      await FinancesCostsService.rejectCost(transactionIds);
      dispatch(FILTER_SELECTED_COSTS, transactionIds);
    } catch (e: any) {
      throw new Error(e);
    }
  },

  [UPDATE_SELECTED_COSTS] ({ state, commit }, costs) {
    if (costs) {
      commit(UPDATE_SELECTED_COSTS, costs);
    } else {
      const costs = state.costs?.items || [];
      const selectedCosts = state.selectedCosts || [];
      const intersectionCosts = _intersectionBy(costs, selectedCosts, "id");
      const newSelectedCosts = _uniqBy([ ...intersectionCosts, ...selectedCosts ], "id")
        .filter(cost => cost.isCheckable);
      commit(UPDATE_SELECTED_COSTS, newSelectedCosts);
    }
  },

  [FILTER_SELECTED_COSTS] ({ state, commit }, transactionIds) {
    const filteredSelectedCosts = state.selectedCosts?.filter((item: CostsListItem) => !transactionIds.includes(item.id));
    commit(UPDATE_SELECTED_COSTS, filteredSelectedCosts);
  },

  [UPDATE_COSTS_FILTERS] ({ commit }, option) {
    commit(UPDATE_COSTS_FILTERS, option);
    commit(UPDATE_COSTS_PAGINATION, { page: 1 });
    commit(UPDATE_SELECTED_COSTS, []);
  },

  [UPDATE_COSTS_PAGINATION] ({ commit }, option) {
    commit(UPDATE_COSTS_PAGINATION, option);
  },

  [SET_EMPTY] ({ commit }) {
    commit("SET_EMPTY");
    commit("adminsList/SET_EMPTY");
    commit("webmastersList/SET_EMPTY");
    commit("statusesList/SET_EMPTY");
    commit("paymentSystemsList/SET_EMPTY");
  }
};

export const costs: Module<PartialFinancesCostsState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    adminsList,
    webmastersList,
    statusesList,
    paymentSystemsList,
    createPaymentModal,
    confirmPayoutModal
  }
};
