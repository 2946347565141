import offerRequirements from "@core/services/admin/offer/edit/graphql/getRequirements.graphql";
import offer from "@core/services/admin/offer/edit/graphql/offer.graphql";
import Service from "@core/services/common/Service";
import { EditableOffer } from "@core/store/logic/admin/offer/EditableOffer";
import { AxiosPromise } from "axios";

export class OfferEditService extends Service {
  public static getRequirements (offerId: string): AxiosPromise {
    try {
      return this.api.post("", {
        query: offerRequirements,
        variables: { offerId }
      });
    } catch (e) {
      throw e;
    }
  }

  public static async getOffer (offerId: string): Promise<EditableOffer> {
    try {
      const { data: { offer: responseOffer } } = await this.api.post("", {
        query: offer,
        variables: { offerId }
      });

      return new EditableOffer(responseOffer);
    } catch (e) {
      throw e;
    }
  }
}


