import { WebmastersEditService } from "@core/services/admin/users/webmasters/WebmastersEditService";
import { EDIT_WEBMASTER, GET_WEBMASTER } from "@core/store/action-constants";
import {
  SET_EMPTY,
  SET_IS_WEBMASTERS_EDIT_MODAL_ACTIVE,
  SET_WEBMASTERS_EDIT_FORM,
  SET_WEBMASTERS_EDIT_ID,
  UPDATE_WEBMASTERS_EDIT_FORM
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { Webmaster, WebmastersEditState } from "@core/store/types/admin/users/webmasters/Edit";
import { ActionTree, Module, MutationTree } from "vuex";
import { password } from "./modules/password";

const initialState = (): WebmastersEditState => {
  return {
    isModalActive: false,
    webmasterId: null,
    form: {}
  };
};

const mutations: MutationTree<WebmastersEditState> = {
  [UPDATE_WEBMASTERS_EDIT_FORM] (state, form) {
    state.form = { ...state.form, ...form };
  },

  [SET_WEBMASTERS_EDIT_FORM] (state, webmaster: Webmaster) {
    const form: WebmastersEditState["form"] = {
      timeZone: webmaster.timeZone.alias,
      telegram: webmaster.contact.telegram,
      email: webmaster.contact.email,
      categoriesPreferences: webmaster.categoriesPreferences.map(({ id }) => id),
      trafficTypes: webmaster.trafficTypes.map(({ id }) => id),
      trust: webmaster.trust.alias,
      login: webmaster.login,
      additional: {
        skype: webmaster.skype,
        birthday: webmaster.birthday
      }
    };

    state.form = form;
  },

  [SET_IS_WEBMASTERS_EDIT_MODAL_ACTIVE] (state, value: boolean) {
    state.isModalActive = value;
  },

  [SET_WEBMASTERS_EDIT_ID] (state, id: string) {
    state.webmasterId = id;
  },

  [SET_EMPTY] (state) {
    Object.assign(state, initialState());
  }
};

const actions: ActionTree<WebmastersEditState, RootState> = {
  async [GET_WEBMASTER] ({ commit, state: { webmasterId } }) {
    const { data: { webmasters: { items: [webmaster] } } } = await WebmastersEditService.getWebmaster(webmasterId as string);

    if (webmaster) {
      commit(SET_WEBMASTERS_EDIT_FORM, webmaster);
    }
  },

  async [EDIT_WEBMASTER] ({ state: { webmasterId, form } }) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { login, ...input } = form;
    await WebmastersEditService.editWebmaster(webmasterId as string, input);
  }
};

export const edit: Module<WebmastersEditState, RootState> = {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  modules: {
    password
  }
};