<template>
    <div>
        <div class="columns is-multiline">
            <div class="column is-12">
                <BlockBox :title="$t(`webmaster.profile.labels.title`)">
                    <div class="block-field columns is-variable is-1">
                        <div class="column">
                            <LTooltip
                                :label="$t(`webmaster.profile.labels.emailMessage`)"
                                class="is-block"
                                position="is-bottom"
                                expanded
                                type="is-danger">
                                <FField :label="$t(`webmaster.profile.labels.email`)">
                                    <FInput
                                        v-if="profileLoaded"
                                        v-model="_user.email"
                                        disabled>
                                    </FInput>
                                    <el-skeleton
                                        v-else
                                        animated>
                                        <template #template>
                                            <el-skeleton-item style="width: 100%; height: 46px;"></el-skeleton-item>
                                        </template>
                                    </el-skeleton>
                                </FField>
                            </LTooltip>
                        </div>
                        <div class="column">
                            <LTooltip
                                :label="$t(`webmaster.profile.labels.loginMessage`)"
                                class="is-block"
                                position="is-bottom"
                                expanded
                                type="is-danger">
                                <FField :label="$t(`webmaster.profile.labels.login`)">
                                    <FInput
                                        v-if="profileLoaded"
                                        v-model="_user.login"
                                        disabled>
                                    </FInput>
                                    <el-skeleton
                                        v-else
                                        animated>
                                        <template #template>
                                            <el-skeleton-item style="width: 100%; height: 46px;"></el-skeleton-item>
                                        </template>
                                    </el-skeleton>
                                </FField>
                            </LTooltip>
                        </div>
                    </div>
                    <div class="block-field columns is-variable is-1">
                        <div class="column">
                            <FField :label="$t(`webmaster.profile.labels.skype`)">
                                <RequiredField
                                    v-if="profileLoaded"
                                    v-model="_user.additional.skype"
                                    prop="skype"
                                    pattern="^[^а-яё]+$"
                                    pattern-message="common.auth.signUp.skypeHelp"
                                    :required="false"
                                    :placeholder="$t(`webmaster.profile.labels.placeholder`)">
                                </RequiredField>
                                <el-skeleton
                                    v-else
                                    animated>
                                    <template #template>
                                        <el-skeleton-item style="width: 100%; height: 46px;"></el-skeleton-item>
                                    </template>
                                </el-skeleton>
                            </FField>
                        </div>
                        <div class="column">
                            <FField :label="$t(`webmaster.profile.labels.tg`)">
                                <RequiredField
                                    v-if="profileLoaded"
                                    v-model="_telegram"
                                    class="px"
                                    prop="telegram"
                                    :placeholder="$t(`webmaster.profile.labels.placeholder`)"
                                    pattern="^@[\d\w]{5,}$"
                                    pattern-message="common.auth.signUp.telegramHelp"
                                    name="telegram">
                                    <template #prepend>
                                        @
                                    </template>
                                </RequiredField>
                                <el-skeleton
                                    v-else
                                    animated>
                                    <template #template>
                                        <el-skeleton-item style="width: 100%; height: 46px;"></el-skeleton-item>
                                    </template>
                                </el-skeleton>
                            </FField>
                        </div>
                        <!-- FIXME: Бек не присылает таймзону
                        <div class="column">
                            <b-field :label="$t('webmaster.profile.labels.timezone')">
                                <b-select v-if="profileLoaded" v-model="user.timeZone">
                                    <optgroup
                                        :key="continent"
                                        v-for="(zones, continent) in timeZones"
                                        :label="continent">
                                        <option
                                            :key="i"
                                            v-for="(zone, i) in zones"
                                            :value="zone.alias">
                                            {{ zone.alias }} {{ zone.offset }}
                                        </option>
                                    </optgroup>
                                </b-select>
                                <b-skeleton
                                    v-else
                                    width="100%"
                                    height="46px"
                                    animated>
                                </b-skeleton>
                            </b-field>
                        </div> -->
                    </div>
                    <div class="block-field columns is-variable is-1 is-multiline">
                        <div class="column is-12-mobile is-6-tablet">
                            <b-field :label="$t('webmaster.profile.labels.timezone')">
                                <b-select
                                    v-if="profileLoaded"
                                    v-model="_user.timeZone">
                                    <optgroup
                                        :key="continent"
                                        v-for="(zones, continent) in timeZones"
                                        :label="continent">
                                        <option
                                            :key="i"
                                            v-for="(zone, i) in zones"
                                            :value="zone.alias">
                                            {{ zone.alias }} {{ zone.offset }}
                                        </option>
                                    </optgroup>
                                </b-select>
                                <b-skeleton
                                    v-else
                                    width="100%"
                                    height="46px"
                                    animated>
                                </b-skeleton>
                            </b-field>
                        </div>

                        <div
                            class="column is-12 is-6-tablet"
                            :class="{'is-12': role === 'admin'}">
                            <b-field :label="$t(`webmaster.profile.labels.birthday`)">
                                <BirthDatepicker
                                    v-if="profileLoaded"
                                    v-model="_user.additional.birthday"
                                    required>
                                </BirthDatepicker>
                                <b-skeleton
                                    v-else
                                    width="100%"
                                    height="46px"
                                    animated>
                                </b-skeleton>
                            </b-field>
                        </div>
                    </div>
                    <div
                        v-if="isConfirmed"
                        class="column is-12 px-0">
                        <b-button
                            class="is-success is-light is-fullwidth"
                            expanded
                            @click="resendEmail">
                            {{ $t(`common.mailConfirmation.resendEmail.resend`) }}
                        </b-button>
                    </div>
                </BlockBox>
            </div>
            <div class="column is-12">
                <LEditTrafficTypes
                    v-if="profileLoaded"
                    v-model="_user.trafficTypes"
                    :title="$t(`webmaster.profile.labels.traffic`)">
                </LEditTrafficTypes>
            </div>
        </div>
        <div class="columns is-multiline">
            <div class="column is-12 is-flex is-flex-direction-column is-justify-content-center">
                <BlockBox :title="$t(`webmaster.profile.labels.offers`)">
                    <div
                        v-if="profileLoaded"
                        class="row-grid row-grid--checkbox-cover">
                        <div
                            :key="index"
                            v-for="(item, index) in offerCategories">
                            <b-checkbox
                                v-model="_user.categoriesPreferences"
                                :native-value="item.id">
                                {{ item.name }}
                            </b-checkbox>
                        </div>
                    </div>
                    <b-loading
                        v-else
                        :active="true"
                        :is-full-page="false"></b-loading>
                </BlockBox>
                <slot name="buttons">
                    <b-button
                        v-if="profileLoaded"
                        class="is-success is-light"
                        expanded
                        @click="$emit('save')">
                        {{ $t(`webmaster.profile.values.save`) }}
                    </b-button>
                </slot>
            </div>
        </div>
        <slot name="other"></slot>
    </div>
</template>

<script>
  import BlockBox from "@/components/Common/BlockBox";
  import LEditTrafficTypes from "@/components/Common/LEditTrafficTypes";
  import { GET_OFFER_CATEGORIES, RESEND_EMAIL } from "@core/store/action-constants";
  import { mapActions, mapState } from "vuex";
  import FField from "@/components/Common/FField";
  import FInput from "@/components/Common/FInput";
  import RequiredField from "@/components/Common/RequiredField";
  import BirthDatepicker from "@/components/Common/BirthDatepicker";

  export default {
    name: "WebmasterProfile",
    
    components: {
      BirthDatepicker,
      RequiredField,
      FInput,
      FField,
      BlockBox,
      LEditTrafficTypes
    },
    
    props: {
      user: {
        type: Object,
        default: null
      }
    },

    created () {
      this.GET_OFFER_CATEGORIES();
    },

    computed: {
      ...mapState({
        timeZones: ({ dictionaries }) => dictionaries.timeZones,
        offerCategories: ({ dictionaries }) => dictionaries.offerCategories,
        role: state => state.auth.role
      }),
  
      profileLoaded () {
        return !!this.user;
      },
  
      isConfirmed () {
        return !this.$store.state.auth.isConfirmed;
      },
  
      _user: {
        get () {
          return this.user;
        },
    
        set (value) {
          this.$emit("update:user", value);
        }
      },

      _telegram: {
        get () {
          return this.user?.telegram?.replace(/@/g, "");
        },

        set (value) {
          this.$emit("update:user", { ...this.user, telegram: "@" + value });
        }
      }
    },

    methods: {
      ...mapActions([
        GET_OFFER_CATEGORIES
      ]),

      ...mapActions("webmaster/profile", [
        RESEND_EMAIL
      ]),

      async resendEmail () {
        try {
          await this.RESEND_EMAIL();

          this.$buefy.toast.open({
            message: this.$t("common.mailConfirmation.resendEmail.success"),
            type: "is-success"
          });
        }
        catch (error) {
          this.$buefy.toast.open({
            message: this.$t("common.mailConfirmation.resendEmail.fail"),
            type: "is-danger"
          });
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
::v-deep {
    .control-label {
        font-size: 0.875em;
    }
}
</style>
