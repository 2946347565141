interface Lead {
  clickId: string;
  leadId: string;
  name: string;
  intId: number;
}

interface Postback {
  createdAt: string;
  id: string;
  status: string;
  sentAt: string;
  url: string;
}

export class StatisticPostback {
  public lead: Lead = {
    clickId: "",
    leadId: "",
    name: "",
    intId: 0
  };
  
  public postback: Postback = {
    createdAt: "",
    id: "",
    status: "",
    sentAt: "",
    url: ""
  };
  
  constructor (postback: any) {
    const status = postback.status?.replace(/[\s]+/gm, "_").replace(/[\W\d]*/gm, "").toUpperCase();
    
    this.lead.clickId = postback.clickId;
    this.lead.leadId = postback.leadId;
    this.lead.name = postback.offerName;
    this.lead.intId = postback.offerIntId;
    
    this.postback.createdAt = postback.createdAt;
    this.postback.id = postback.id;
    this.postback.status = status;
    this.postback.sentAt = postback.sentAt;
    this.postback.url = postback.url;
  }
}

