/**
 * Набор экшенов и мутаций для поулчения фильтров по пользователям
 * @typedef {Object} GetUsersOptions
 * @property {string} updatePaginationMutation - Мутация для обновления пагинации
 * @property {string} updateFiltersMutation - Мутация для обновления фильтров
 * @property {string} getDataAction - Экшен для получения фильтров
 * @property {string} getInitialAction - Экшен для получения инициализирующих фильтров
 */

// @vue/components
export const UsersFilters = {
  methods: {
    getUsersByActionName (func, label, offset, loginFilterType = "loginOrIntId", filters = {}) {
      label = label.length > 0 ? label : null;
      return func({
        limit: 25,
        offset,
        filters: {
          ...filters,
          [loginFilterType]: label
        }
      });
    },
  
    setUsersInitialValue (filterParam, res) {
      this.filters[filterParam] = res[0];
    },
  
    /**
     * Миксин получения фильтров по пользователям
     * @param {GetUsersOptions} options
     * @param {Object} filters
     * @param {String} label
     * @param {Object} data
     * @param {string | number | boolean} routeParam
     * @param {string} filterParam
     */
    // eslint-disable-next-line default-param-last
    async getUsersVuex (options, filters = {}, label = "", data, routeParam, filterParam) {
      const { updatePaginationMutation, updateFiltersMutation, getDataAction, getInitialAction } = options;
      const loginOrIntId = label.length > 0 ? label : null;
  
      this.$store.commit(updatePaginationMutation);
      this.$store.commit(updateFiltersMutation, { ...filters, loginOrIntId });
  
      if (data?.items?.length > 0 || !getInitialAction) {
        await this.$store.dispatch(getDataAction);
      } else {
        await this.$store.dispatch(getInitialAction, routeParam);
        // if (res?.length > 0) {
        //   this.setUsersInitialValue(filterParam, res);
        // }
      }
    }
  }
};
