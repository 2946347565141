<template>
    <div class="block-box">
        <h2
            v-if="title"
            class="block-title">
            {{ title }}
            <LTooltip
                v-if="note"
                :type="noteType"
                interactive
                :max-width="250"
                position="is-right">
                <!--suppress HtmlUnknownTarget -->
                <b-icon
                    icon="question-circle"
                    pack="far"
                    size="is-small"></b-icon>
                <template #content>
                    <p class="multilined-tooltip">
                        {{ note }}
                    </p>
                </template>
            </LTooltip>
        </h2>
        <div class="block-card">
            <b-loading
                v-if="loading"
                :is-full-page="false"
                active></b-loading>
            <slot v-else></slot>
        </div>
    </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        // eslint-disable-next-line no-irregular-whitespace
        default: " " //  	&#8195;	широкий пробел (em-шириной в букву m)
      },
      note: {
        type: String,
        default: null
      },
      noteType: {
        type: String,
        default: "is-white"
      },
      loading: {
        type: Boolean,
        default: false
      }
    }
  };
</script>

<style lang="scss" scoped>
    .multilined-tooltip {
        white-space: pre-line !important
    }
    
    .block-box {
        min-height: 83px;
        position: relative;
    }

    ::v-deep {
        .b-tooltip {
            &.is-multiline:after {
                text-align: left;
                white-space: pre-wrap;
            }

            .icon {
                margin-left: 0.25em;
            }
        }
    }
</style>
