import { GET_UTM_TAGS } from "@core/store/action-constants";

// @vue/components
export const UtmFilters = {
  created () {
    this.GET_UTM_TAGS();
  },
  
  computed: {
    utm () {
      // eslint-disable-next-line no-console
      console.warn("Should be override");
      return {};
    }
  },
  
  methods: {
    [GET_UTM_TAGS] () {
      // eslint-disable-next-line no-console
      console.warn("Should be override");
    },
    
    getUtmTag (tag) {
      const values = this.utm[tag];
      
      if (values) {
        return () => ({
          items: values.map(value => ({
            name: value,
            id: value
          })),
          count: values.length
        });
      }
      
      return () => ({
        items: [],
        count: 0
      });
    }
  }
};
