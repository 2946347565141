import { detailing } from "@core/store/modules/admin/statistic/modules/detailing/detailing";
import { trafficAnalysis } from "@core/store/modules/admin/statistic/modules/trafficAnalysis/trafficAnalysis";
import { statistics as statisticsModule } from "@core/store/modules/admin/statistic/modules/statistics";

export const statistics = {
  namespaced: true,
  modules: {
    detailing,
    statistics: statisticsModule,
    trafficAnalysis
  }
};