import SignIn from "@/components/Common/Auth/SignIn.vue";
import SignUp from "@/components/Common/Auth/SignUp.vue";
import SignUpDetail from "@/components/Common/Auth/SignUpDetail.vue";
import ResetPasswordView from "@/views/Auth/ResetPasswordView.vue";

import Empty from "@/views/Empty.vue";

export default {
  path: "",
  name: "auth",
  component: Empty,
  children: [
    {
      props: true,
      path: "signIn",
      name: "signIn",
      component: SignIn
    },
    {
      path: "signUp",
      children: [
        {
          path: "",
          name: "signUp",
          component: SignUp
        },
        {
          path: "profile",
          name: "signUpProfile",
          component: SignUpDetail
        },
        {
          path: "complete",
          name: "signUpComplete",
          component: SignUpDetail
        }
      ]
    },
    {
      path: "reset",
      name: "reset",
      component: ResetPasswordView
    }
  ]
};
