import Service from "@core/services/common/Service";
import { AxiosPromise } from "axios";
import { CorrectionHistoryState } from "@core/store/types/admin/finances/financesCorrection/FinancesCorrectionHistoryState";
import webmasterTransactions from "@core/services/webmaster/finances/graphql/webmasterTransactions.graphql";
import orderOutTransaction from "@core/services/webmaster/finances/graphql/orderOutTransaction.graphql";
import { OrderOutTransaction } from "@core/store/types/webmaster/finances";

export class FinancesService extends Service {
  public static getFinances (
    limit: number,
    offset: number,
    filters: CorrectionHistoryState["filters"]
  ): AxiosPromise {
    try {
      return this.api.get("", {
        params: {
          query: webmasterTransactions,
          variables: {
            limit,
            offset,
            filters
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static addFinances (input: OrderOutTransaction): AxiosPromise {
    try {
      return this.api.get("", {
        params: {
          query: orderOutTransaction,
          variables: {
            input
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
}
