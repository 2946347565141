<template>
    <el-card
        v-loading="isLoading"
        :body-style="{ padding: '0px' }"
        class="bg-gray-light-bg shadow-inner mb-8 mobile:bg-gray-background">
        <template>
            <div
                class="managerLogo laptop:hidden"
                @click="isManagerModalActive = true">
                <img
                    class="p-3.5"
                    src="@/assets/AsideMenu/mobile-manager.svg"
                    alt="manager">
            </div>
        </template>
        <template>
            <div class="manager flex-col">
                <div class="flex items-center p-4">
                    <div class="mr-4">
                        <el-avatar
                            :src="String(sizedAvatar)"
                            :size="64"></el-avatar>
                    </div>
                    <div>
                        <span class="text-xs text-gray-text">{{ $t("webmaster.aside.personalManager.title") }}</span>
                        <div class="flex is-align-items-center">
                            <span
                                v-if="personalManager"
                                class="text-base text-blue-text font-semibold cursor-pointer"
                                slot="reference"
                                @click="visible = !visible">
                                {{ personalManager.name }}
                            </span>
                            <div class="status">
                                {{ $t("webmaster.aside.personalManager.status") }}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-if="visible"
                    class="h-auto">
                    <a :href="'skype:' + contacts.skype + '?call'">
                        <div class="flex text-item text-xs p-3 border-t">
                            <img
                                class="pr-2"
                                src="@/assets/AsideMenu/skype.svg"
                                alt="skype">
                            <span>{{ $t("webmaster.aside.personalManager.skype") }}</span>
                        </div>
                    </a>
                    <a
                        :href="'mailto:' + contacts.email"
                        target="_blank">
                        <div class="flex text-item text-xs p-3 border-t">
                            <img
                                class="pr-2"
                                src="@/assets/AsideMenu/email.svg"
                                alt="email">
                            <span>{{ $t("webmaster.aside.personalManager.email") }}</span>
                        </div>
                    </a>
                    <a
                        :href="'https://telegram.im/' + contacts.telegram"
                        target="_blank">
                        <div class="flex text-item text-xs p-3 border-t">
                            <img
                                class="pr-2"
                                src="@/assets/AsideMenu/telegram.svg"
                                alt="telegram">
                            <span>{{ $t("webmaster.aside.personalManager.telegram") }}</span>
                        </div>
                    </a>
                </div>
            </div>
        </template>
        <ManagerModal
            :is-modal-active.sync="isManagerModalActive"></ManagerModal>
    </el-card>
</template>

<script>
  // @ts-ignore
  import managerDefaultIcon from "@/assets/manager.svg";
  import ManagerModal from "@/components/Common/NavigationBar/ManagerModal";
  import { GET_ADVERTISER_PERSONAL_MANAGER, GET_WEBMASTER_PERSONAL_MANAGER } from "@core/store/action-constants";
  import { mapActions, mapGetters, mapState } from "vuex";
  
  export default {
    name: "Manager",
    
    components: {
      ManagerModal
    },
    
    props: {
      isCollapsed: {
        type: Boolean,
        default: false
      }
    },
    
    async created () {
      await this.getPersonalManager();
    },
    
    data () {
      return {
        isManagerModalActive: false,
        isLoading: false,
        visible: false
      };
    },
    
    computed: {
      ...mapState("webmaster", {
        webmasterPersonalManagers: state => state.profile.personalManagers,
        user: state => state.profile.user
      }),

      ...mapState("advertiser", {
        advertiserPersonalManager: state => state.profile.personalManager
      }),

      ...mapGetters([
        "role"
      ]),
    
      contacts () {
        return {
          email: this.user.contact.email,
          skype: this.user.skype,
          telegram: this.user.contact.telegram
        };
      },
      
      sizedAvatar () {
        try {
          const url = new URL(this.personalManager?.avatar?.url);
          url.searchParams.set("s", "48");
          return url;
        } catch {
          return this.personalManager?.avatar?.url || managerDefaultIcon;
        }
      },

      personalManager () {
        if (this.role === "webmaster") {
          return this.webmasterPersonalManagers?.[0];
        } else if (this.role === "advertiser") {
          return this.advertiserPersonalManager;
        }
        return undefined;
      }
    },
    methods: {
      ...mapActions("webmaster/profile", [
        GET_WEBMASTER_PERSONAL_MANAGER
      ]),

      ...mapActions("advertiser/profile", [
        GET_ADVERTISER_PERSONAL_MANAGER
      ]),

      async getPersonalManager () {
        this.isLoading = true;
        if (this.role === "webmaster") {
          await this.GET_WEBMASTER_PERSONAL_MANAGER();
        } else if (this.role === "advertiser") {
          await this.GET_ADVERTISER_PERSONAL_MANAGER();
        }
        this.isLoading = false;
      }
    }
  };
</script>

<style lang="scss" scoped>
    .status {
        height: 16px;

        padding: 2px 4px;
        margin-top: 3px;
        margin-left: 7px;
        font-size: 9px;
        font-weight: bold;
        color: white;

        border-radius: 5px;
        background: #1BD12D;
        letter-spacing: 0.05rem;
        cursor: default;
    }

    .managerLogo {
        cursor: pointer;
    }
    .el-card.is-always-shadow, .el-card.is-hover-shadow:focus, .el-card.is-hover-shadow:hover {
        box-shadow: 0 10px 20px rgba(38, 50, 72, 0.05), inset 0 1px 0 #FFFFFF, inset 0 -1px 0px #CFD6E3;
    }
</style>
