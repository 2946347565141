import { AnalyticService } from "@core/services/admin/analytic/AnalyticService";
import { RootState } from "@core/store/root-state";
import { Pagination } from "@core/store/types/PaginationState";
import Vue from "vue";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import {
  SET_EMPTY_SUB_ANALYTIC,
  SET_SUB_ANALYTIC
} from "@core/store/mutation-constants";
import { GET_SUB_ANALYTIC, GET_SUB_ANALYTIC_BY_INDEX } from "@core/store/action-constants";
import { AnalyticItem } from "@core/store/types/admin/analytic/analytic";

export type AdminSubAnalyticState = {
  subAnalytic: Record<string, {
    pagination: Pagination,
    // TODO: сохранять фильтры
    // filters: any,
    items: AnalyticItem[]
  }>;
};

export type NullablePaginationAdminSubAnalyticState = NullableRecord<AdminSubAnalyticState>;

const initialState = (): NullablePaginationAdminSubAnalyticState => {
  return {
    subAnalytic: {}
  };
};

const state: () => NullablePaginationAdminSubAnalyticState = initialState;

const mutations: MutationTree<NullablePaginationAdminSubAnalyticState> = {
  [SET_EMPTY_SUB_ANALYTIC] (state, payload: {
    index: number;
  }) {
    const { index } = payload;
    
    if (state.subAnalytic?.[index]) {
      Vue.set(state.subAnalytic, index, undefined);
    }
  },
  
  [SET_SUB_ANALYTIC] (state, payload: {
    index: number;
    data: any;
  }) {
    if (state.subAnalytic == null) {
      state.subAnalytic = {};
    }
    
    let { index, data } = payload;
    
    if (state.subAnalytic[index]) {
      data = {
        count: data.count,
        items: state.subAnalytic[index].items.concat(data.items)
      };
    }
    
    Vue.set(state.subAnalytic, index, data);
  }
};

const actions: ActionTree<NullablePaginationAdminSubAnalyticState, RootState> = {
  async [GET_SUB_ANALYTIC] ({ commit, state, rootState }, params: {
    index: number,
    subSection: string
  }) {
    const subAnalytic = state.subAnalytic?.[params.index];
    
    const limit = 10;
    const offset = subAnalytic?.items.length ?? 0;
    
    const analyticState = rootState.admin.analytic;
    const { groups: analyticGroups, ...analyticFilters } = analyticState.filters;
    
    const group = analyticState.analytic.items[params.index]?.group;
    const filterId = Array.isArray(group) ? group.map(item => item.id) : group?.id;
    
    const filters = {
      ...analyticFilters,
      [`${ analyticGroups[0].toLowerCase() }Id`]: filterId
    };
  
    const { data: { analytic } } = await AnalyticService.getAnalytic(
      limit,
      offset,
      filters,
      params.subSection,
      analyticGroups[1]
    );
  
    commit(SET_SUB_ANALYTIC, {
      index: params.index,
      data: analytic
    });
    
    return analytic;
  }
};

const getters: GetterTree<NullablePaginationAdminSubAnalyticState, RootState> = {
  [GET_SUB_ANALYTIC_BY_INDEX]: state => (index: string | number) => (state.subAnalytic || {})[index]
};

export const subAnalytic: Module<NullablePaginationAdminSubAnalyticState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
