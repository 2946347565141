import { FETCH_USER } from "@/store/action-constants";
import { Admin } from "@/store/modules/admin";
import { Advertiser } from "@/store/modules/advertiser";
import { Webmaster } from "@/store/modules/webmaster";
import { RootState } from "@/store/root-state";
import { CoreStore } from "@core/store";
import * as Sentry from "@sentry/vue";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export class Store extends CoreStore<RootState, RootState> {
  constructor () {
    super();
  
    this.actions({
      async [FETCH_USER] ({ dispatch, getters }) {
        try {
          if (!["webmaster", "advertiser", "admin"].includes(getters.role)) {
            // noinspection ExceptionCaughtLocallyJS
            throw new Error(`Role [${ getters.role }] does not valid`);
          }
        
          const user = await dispatch(`${ getters.role }/profile/${ FETCH_USER }`);
        
          const { id, login: username } = user;
          if (Sentry) {
            Sentry.setUser({ id, username, Role: getters.role });
          }
        
          return user;
        } catch (error) {
          throw error;
        }
      }
    });
  
    this.getters({
      profile: (state, getters) => {
        switch (getters.role) {
          case "webmaster":
          case "advertiser":
          case "admin":
            return state[getters.role].profile;
          default:
            return {};
        }
      },
    
      role: (state): string => {
        return state.auth.role;
      },
    
      user: (state, getters): string => {
        return getters.profile.user;
      },
    
      timezone: (state, getters): string => {
        return getters.profile.user.timeZone;
      }
    });
  
    this.state({
      version: process.env.VUE_APP_VERSION
    });
    
    this.strict = true;
    
    this.modules({
      advertiser: new Advertiser().toModule(),
      webmaster: new Webmaster().toModule(),
      admin: new Admin().toModule()
    });
  }
}

export default new Vuex.Store<RootState>(new Store().toModule());
