export default [
  {
    path: "profile",
    name: "profile",
    redirect: { name: "webmaster:profile:settings" },
    component: (): Promise<any> => import(/* webpackChunkName: "webmaster:profile" */ "@/views/Webmaster/ProfileView.vue"),
    children: [
      {
        path: "",
        name: "profile",
        component: (): Promise<any> =>
          import(/* webpackChunkName: "webmaster:statistics:statistics" */ "@/components/Webmaster/Profile/Profile.vue")
      },
      {
        path: "postback",
        name: "postback",
        component: (): Promise<any> =>
          import(/* webpackChunkName: "webmaster:statistics:statistics" */ "@/components/Webmaster/Profile/PostbackAndAPI.vue")
      },
      {
        path: "requisites",
        name: "requisites",
        component: (): Promise<any> =>
          import(/* webpackChunkName: "webmaster:statistics:statistics" */ "@/components/Webmaster/Profile/Requisites.vue")
      },
      {
        path: "changepassword",
        name: "changepassword",
        component: (): Promise<any> =>
          import(/* webpackChunkName: "webmaster:statistics:statistics" */ "@/components/Webmaster/Profile/ChangePassword.vue")
      }
    ]
  }
];
