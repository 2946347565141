<template>
    <el-col
        :span="colSize"
        class="flex text-base font-semibold -mr-10 items-center h-10 flex-row-reverse laptop:mr-0 tablet:flex-row tablet:z-10 tablet:justify-end pr-2">
        <el-dropdown
            v-if="role === 'admin'"
            trigger="click"
            placement="top"
            class="mr-5">
            <FButton
                type="primary"
                plain
                no-more
                rounded>
                <p>
                    {{ $t("webmaster.navBar.partners.invite") }}
                </p>
            </FButton>
            <el-dropdown-menu
                slot="dropdown"
                class="pt-1 py-0 rounded-t-none border-gray-border border-t-0 shadow-lg partner">
                <div class="partner-body">
                    <h2 class="partner-title">
                        {{ $t("webmaster.navBar.partners.body.title") }}
                    </h2>
                    <p class="partner-info">
                        {{ $t("webmaster.navBar.partners.body.info") }}
                    </p>
                    <b-field>
                        <div class="control">
                            <FInput
                                class="input-url flex flex-wrap w-full"
                                :value="referralLink"
                                :label="$t('webmaster.navBar.partners.body.link')"
                                :title="$t('webmaster.navBar.partners.body.link')"
                                can-copy
                                can-copy-class="text-purple-800 hover:text-purple-800 hover:opacity-80 focus:text-purple-800"
                                disabled>
                            </FInput>
                        </div>
                    </b-field>
                </div>
            </el-dropdown-menu>
        </el-dropdown>

        <div class="mr-3">
            <UploadsList
                v-if="role === 'admin' || role === 'webmaster' || role === 'advertiser'"
                :pagination="_uploadsPagination"
                @update:pagination="UPDATE_UPLOADS_PAGINATION">
            </UploadsList>
        </div>

        <div class="hidden mr-3 sm:flex laptop:mr-6 desktop:mr-2">
            <img
                src="@/assets/NavigationBar/manager-icon.svg"
                alt="icon"
                class="mr-2">
            <span class="text-blue-text">{{ login }}</span>
        </div>

        <el-dropdown
            class="hover:shadow-lg laptop:mx-4"
            :class="{'mobile:ml-3 mobile:-mr-2': $attrs['is-mobile-menu-open'], 'mobile:mx-2': !$attrs['is-mobile-menu-open']}">
            <el-button class="w-12 h-8 p-1 py-0 border-white shadow-md tablet:h-10 sm:w-16 sm:px-3 hover:bg-white hover:shadow-lg hover:border-gray-border">
                <span class="text-blue-text text-base font-normal mobile:text-sm">{{ locale }}</span>
                <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu
                slot="dropdown"
                class="pt-1 py-0 rounded-t-none border-gray-border border-t-0 shadow-lg"
                :class="isRu">
                <el-dropdown-item
                    :key="locale_"
                    v-for="(locale_, index) in $root.$i18n.availableLocales"
                    class="text-base h-10 p-3 flex items-center border-t"
                    :index="index"
                    :value="locale_"
                    @click.native="updateLocale(locale_)">
                    <small>{{ $t(`common.nativeTranslationLanguages.${ locale_ }`) }}</small>
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>

        <el-dropdown
            class="mx-2 laptop:ml-4">
            <el-button
                class="w-28 h-8 p-1 py-0 border-white shadow-md hover:bg-white hover:shadow-lg hover:border-gray-border
                tablet:h-10 laptop:w-32 sm:w-32 sm:px-3">
                <div class="flex justify-between items-center laptop:pr-4">
                    <img
                        class="w-5 mr-2"
                        src="@/assets/NavigationBar/settings.svg"
                        alt="icon">
                    <span class="text-base text-blue-text font-normal mobile:text-sm">{{ $t('common.navBar.accountDropdown.account') }}</span>
                    <i class="el-icon-arrow-down ml-0.5"></i>
                </div>
            </el-button>
            <el-dropdown-menu
                slot="dropdown"
                class="text-blue-text w-44 pt-1 pb-0 border-t-0 shadow-lg border-gray-border">
                <template v-if="role === 'webmaster'">
                    <el-dropdown-item class="dropdown-menu-item">
                        <router-link :to="{ name: 'webmaster:profile:profile' }">
                            {{ $t("common.navBar.accountDropdown.settings") }}
                        </router-link>
                    </el-dropdown-item>
                    <el-dropdown-item class="dropdown-menu-item">
                        <router-link :to="{ name: 'webmaster:profile:postback' }">
                            {{ $t("common.navBar.accountDropdown.postback") }}
                        </router-link>
                    </el-dropdown-item>
                    <el-dropdown-item class="dropdown-menu-item">
                        <router-link :to="{ name: 'webmaster:profile:requisites' }">
                            {{ $t("common.navBar.accountDropdown.requisites") }}
                        </router-link>
                    </el-dropdown-item>
                    <el-dropdown-item class="dropdown-menu-item">
                        <router-link :to="{ name: 'webmaster:profile:changepassword' }">
                            {{ $t("common.navBar.accountDropdown.changePassword") }}
                        </router-link>
                    </el-dropdown-item>
                </template>

                <template v-else>
                    <el-dropdown-item
                        class="dropdown-menu-item"
                        @click.native="openProfileModal">
                        {{ $t("common.navBar.accountDropdown.profile") }}
                    </el-dropdown-item>
                </template>

                <el-dropdown-item
                    class="dropdown-menu-item"
                    @click.native="logout">
                    {{ asAdmin ? $t("common.navBar.accountDropdown.toAdmin") : $t("common.navBar.accountDropdown.logout") }}
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>

        <Balance
            v-if="(role === 'webmaster' || role === 'advertiser')"
            :role="role"
            :class="{'hidden': !$attrs['is-mobile-menu-open']}"
            class="ml-4 mr-6 tablet:flex tablet:ml-3 laptop:ml-8">
        </Balance>

        <span
            v-show="$attrs['is-mobile-menu-open']"
            class="login hidden text-blue-text text-xs mx-2 mobile:block">{{ login }}</span>
    </el-col>
</template>

<script>
  import { UPDATE_LOCALE } from "@core/store/action-constants";
  import { UPDATE_UPLOADS_PAGINATION, UPDATE_MODAL } from "@core/store/mutation-constants";
  import Balance from "@/components/Common/NavigationBar/Balance";
  import FInput from "@/components/Common/FInput";
  import FButton from "@/components/Common/FButton";
  import { USER_LOGOUT, ADMIN_LOGOUT_ROLE } from "@core/store/action-constants";
  import { mapActions, mapMutations, mapState } from "vuex";
  import UploadsList from "@/components/Admin/NavigationBar/Uploads/UploadsList";

  export default {
    name: "InfoBar",
    components: {
      FInput,
      FButton,
      Balance,
      UploadsList
    },

    props: {
      login: {
        type: String,
        required: true
      },
      role: {
        type: String,
        required: true
      },
      colSize: {
        type: Number,
        default: 12
      },
      asAdmin: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      ...mapState({
        _uploadsPagination: (state) => state.uploads.pagination,
        telegram: ({ admin }) => admin.profile.user.contact.telegram
      }),

      locale: {
        get () {
          return this.$store.state.locale.locale;
        },

        set (value) {
          this.UPDATE_LOCALE(value);
        }
      },

      isRu () {
        return this.locale === "ru" ? "w-24" : "w-16";
      },

      referralLink () {
        return `${ process.env.VUE_APP_REFERRAL_LINK }/${ this.telegram }`;
      }
    },
    methods: {
      ...mapActions({
        USER_LOGOUT,
        UPDATE_LOCALE,
        ADMIN_LOGOUT_ROLE,
        UPDATE_UPLOADS_PAGINATION: `uploads/${ UPDATE_UPLOADS_PAGINATION }`
      }),

      ...mapMutations({
        UPDATE_MODAL (commit, isActive) {
          return commit(`${ this.role }/profile/${ UPDATE_MODAL }`, { isActive });
        }  
      }),

      logout () {
        const asAdmin = this.asAdmin;

        if (asAdmin) {
          this.ADMIN_LOGOUT_ROLE();
        } else {
          this.USER_LOGOUT();
        }
      },

      updateLocale (locale) {
        this.UPDATE_LOCALE(locale);
      },

      openProfileModal () {
        this.UPDATE_MODAL(true);
      }
    }
  };
</script>

<style lang="scss" scoped>
    .partner {
        $self: &;

        display: flex;
        width: 26rem;

        &-body {
            padding: .8rem;

            #{ $self }-title {
                margin-bottom: 0.5rem;
                font-weight: 700;
                font-size: 1.03em;
                color: #212121;
            }

            #{ $self }-info {
                font-size: 0.83em;
                line-height: 1.1rem;
                margin-bottom: 0.8rem;
            }
        }
    }

    .dropdown-menu-item {
        @apply text-base p-3 h-auto flex items-center border-t;
    }

    .login {
        @media screen and (max-width: 350px) {
            display: none;
        }
    }
</style>