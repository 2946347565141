import { AxiosResponse } from "axios";
import { PaginationParams } from "@core/logic/common/pagination-params";
import { PaginationOutput } from "@core/logic/common/pagination-output";
import Service from "@core/services/common/Service";

interface IncomesFilters {
  userId: string;
  comment: string;
  amountMin: number;
  amountMax: number;
  dateStart: Date;
  dateEnd: Date;
}

interface CostsFilters {
  userId: string;
  webmaster: string;
  status: string;
  paymentSystem: string;
  dateStart: Date;
  dateEnd: Date;
}

interface CommissionsFilters {
  userId: string;
  webmaster: string;
  advertiser: string;
  country: string;
  offer: string;
  model: string;
  dateStart: Date;
  dateEnd: Date;
}

interface PushIncomeParams {
  userId: string;
  paymentSystemId: string;
  amount: number;
  comment: string;
}

interface PushCostParams {
  userId: string;
  requisiteId: string;
  paymentSystemId: string;
  amount: number;
  comment: string;
}

interface Commission {
  "id": string;
  "offer": CommissionsOffer;
  "countries": Country[];
  "reward": number;
  "advertiser": CommissionsAdvertiser;
  "webmaster": CommissionsWebmaster;
  "createdAt": Date;
}

interface Income {
  "id": number;
  "amount": number;
  "userName": string;
  "comment": string;
  "executedAt": Date;
  "createdAt": Date;
}

interface Cost {
  "id": string;
  "intId": number;
  "webmaster": CostsWebmaster;
  "email": string;
  "status": string;
  "paymentSystem": string;
  "executor": string;
  "createdAt": Date;
  "executedAt": Date;
  "comment": string;
  "amount": number;
}

interface CommissionsOffer {
  "id": string;
  "name": string;
  // "intId": number; Пока не приходит от бека
}

export interface Country {
  "id": string;
  "name": string;
  "alpha2": string;
  "alpha3": string;
}

interface CommissionsUser {
  "id": string;
  "name": string;
}

interface CostsUser {
  "id": string;
  "name": string;
  "email": string;
  "login": string;
}

type CommissionsAdvertiser = CommissionsUser
type CommissionsWebmaster = CommissionsUser
type CostsWebmaster = CostsUser

export interface Commissions extends PaginationOutput {
  items: Commission[];
}

export interface Incomes extends PaginationOutput {
  items: Income[];
}

export interface Costs extends PaginationOutput {
  items: Cost[];
}

interface GetIncomesParams extends PaginationParams {
  filters: IncomesFilters;
}

interface GetCommissionsParams extends PaginationParams {
  filters: CommissionsFilters;
}

interface GetCostsParams extends PaginationParams {
  filters: CostsFilters;
}

export class FinancesService extends Service{
  public static getIncomes (params: GetIncomesParams): Promise<AxiosResponse<Incomes>> {
    return this.rest.get("/admin/transactions/in", { params });
  }
  
  public static getUserBalance (userId: string): Promise<AxiosResponse<any>> {
    return this.rest.get(`/admin/users/${ userId }/balance`);
  }
  
  public static getCommissions (params: GetCommissionsParams): Promise<AxiosResponse<Commissions>> {
    return this.rest.get("/admin/finances/commissions", { params });
  }
  
  public static getCosts (params: GetCostsParams): Promise<AxiosResponse<Costs>> {
    return this.rest.get("/admin/transactions/out", { params });
  }
  
  public static createCost (params: PushCostParams): Promise<AxiosResponse> {
    return this.rest.post("/admin/transactions/out", params);
  }
  
  public static processCost (id: string): Promise<AxiosResponse> {
    return this.rest.put(`/admin/transactions/out/${ id }/transit-to-hand-processing`);
  }
  
  public static acceptCost (id: string): Promise<AxiosResponse> {
    return this.rest.put(`/admin/transactions/out/${ id }/transit-to-processed`);
  }
  
  public static rejectCost (id: string): Promise<AxiosResponse> {
    return this.rest.put(`/admin/transactions/out/${ id }/transit-to-reject`);
  }
  
  public static createIncome (params: PushIncomeParams): Promise<AxiosResponse> {
    return this.rest.post("/admin/transactions/in", params);
  }
}
