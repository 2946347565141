export default [
  {
    path: "offers",
    name: "offers",
    redirect: { name: "admin:offers:offers" },
    children: [
      {
        path: "",
        name: "offers",
        component: (): Promise<any> => import(/* webpackChunkName: "admin:offers" */ "@/views/Admin/OffersView.vue")
      },
      {
        path: "",
        component: (): Promise<any> => import(/* webpackChunkName: "admin:offerEdit" */ "@/views/Admin/OfferFormView.vue"),
        children: [
          {
            path: "create",
            name: "create",
            component: (): Promise<any> =>
              import(/* webpackChunkName: "admin:offerEdit:create" */ "@/components/Admin/Offer/OfferForm.vue")
          },
          {
            path: "edit/:id/landings",
            name: "landingsForm",
            props: true,
            component: (): Promise<any> =>
              import(/* webpackChunkName: "admin:offerEdit:landingsForm" */ "@/components/Admin/Offers/LandingsForm.vue")
          },
          {
            path: "edit/:id",
            name: "edit",
            props: { offer: true },
            component: (): Promise<any> =>
              import(/* webpackChunkName: "admin:offerEdit:edit" */ "@/components/Admin/Offer/OfferForm.vue")
          },
          {
            path: "edit/:id/rates",
            name: "rates",
            props: { offer: true },
            component: (): Promise<any> =>
              import(/* webpackChunkName: "admin:offerEdit:rates" */ "@/components/Admin/Offer/OfferRates.vue")
          }
        ]
      },
      {
        path: ":id",
        name: "offer",
        props: true,
        component: (): Promise<any> => import(/* webpackChunkName: "offer" */ "@/views/Admin/OfferView.vue")
      }
    ]
  }
];
