

interface Timetable {
  [key: string]: any;
}

function getLocalTimeZone (): string {
  const offset = new Date().getTimezoneOffset();
  const operator = offset > 0 ? "-" : "+";
  const timeZoneHours = ("0" + String(Math.floor(Math.abs(offset / 60)))).slice(-2);
  const timeZoneMinutes = ("0" + String(Math.abs(offset % 60))).slice(-2);

  return `${ operator }${ timeZoneHours }:${ timeZoneMinutes }`;
}

function formatTimeTables (callCenters: any): Record<string, unknown> {
  const localTimeZone = getLocalTimeZone();
  
  return callCenters.items.map((callCenter: any) => {
    return {
      ...callCenter,
      timeTables: ((): Record<string, unknown> => {
        
        const newTimeTables: Timetable = {};
        const timeZone = /[+|-][\d:]*$/;
        const currentYear = new Date().getFullYear();
        const currentMonth = ("0" + (new Date().getMonth() + 1)).slice(-2);
        
        for (const day in callCenter.timeTables) {
          if (callCenter.timeTables.hasOwnProperty(day)) {
            if (callCenter.timeTables[day]) {
              
              newTimeTables[day] = callCenter.timeTables[day].map((time: any) => {
                return {
                  timeStart: time.timeStart.replace(/(\d{4})-(\d{2})/, `${ currentYear }-${ currentMonth }`)
                    .replace(timeZone, localTimeZone),
                  timeFinish: time.timeFinish.replace(/(\d{4})-(\d{2})/, `${ currentYear }-${ currentMonth }`)
                    .replace(timeZone, localTimeZone)
                };
              });
            } else {
              newTimeTables[day] = null;
            }
          }
        }
        return newTimeTables;
      })()
    };
  });
}

export function getCallCentersUTC (callCenters: any): Record<string, unknown> {
  if (callCenters) {
    return {
      ...callCenters,
      items: formatTimeTables(callCenters)
    };
  }
  return {};
}
