import {
  UPDATE_MODAL_ACTIVE,
  SET_EMPTY,
  FILTERED_ACTIONS_LIST,
  FILTERED_TARGETED_LEADS,
  FILTERED_TARGETED_APPROVES,
  FILTERED_TARGETED_SPAMS,
  TARGETED_ACTIONS_LIST, UPDATE_ACTIONS_LIST,
  FILTERED_TARGETED_REDEMPTION
} from "@core/store/mutation-constants";
import { GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import targetedLeads from "../modules/datasets/targetedLeads.json";
import targetedApproves from "../modules/datasets/targetedApproves.json";
import targetedSpams from "../modules/datasets/targetedSpams.json";
import targetedRedemption from "../modules/datasets/targetedRedemption.json";

import {
  ActionsList,
  AnalyticModal,
  TargetedList,
  TargetedActionsList
} from "@core/store/types/admin/analytic/analyticModal";
import { mixPermissions } from "@core/helpers/mixPermissions";

const initialState = (): AnalyticModal => {
  const actionsList = localStorage.getItem("targetedActions");
  
  return {
    isModalActive: false,
    targetedLeads,
    targetedApproves,
    targetedSpams,
    targetedRedemption,
    actionsList: actionsList ? JSON.parse(actionsList) : {
      leads: ["leadsValidAccepted", "leads"],
      approves: ["commonApprove", "cleanApprove"],
      spams: ["leadsTrash"],
      redemption: ["redemption"]
    }
  };
};

const state: () => AnalyticModal = initialState;

function filteringOnInvalidActions (target: TargetedList[]) {
  return (item: string) => target.some((t) => t.value === item);
}


const getters: GetterTree<AnalyticModal, RootState> = {
  [FILTERED_ACTIONS_LIST]: (state) => {
    const leads = state.actionsList.leads
      .filter(filteringOnInvalidActions(state.targetedLeads)); // Удаляет старые неиспользуемые значения в селектах
    const approves = state.actionsList.approves
      .filter(filteringOnInvalidActions(state.targetedApproves));
    const spams = state.actionsList.spams
      .filter(filteringOnInvalidActions(state.targetedSpams));
    const redemption = mixPermissions(state.actionsList.redemption
      .filter(filteringOnInvalidActions(state.targetedRedemption)), [
      {
        name: "redemption",
        permissions: ["STATISTICS.SHOW.REDEMPTION"]
      }
    ]);
    
    return {
      leads,
      approves,
      spams,
      redemption
    };
  },
  
  [FILTERED_TARGETED_LEADS]: (state) => state.targetedLeads,
  
  [FILTERED_TARGETED_APPROVES]: (state) => state.targetedApproves,
  
  [FILTERED_TARGETED_SPAMS]: (state) => state.targetedSpams,
  
  [FILTERED_TARGETED_REDEMPTION]: (state) =>
    mixPermissions(state.targetedRedemption, [
      {
        name: "redemption",
        permissions: ["STATISTICS.SHOW.REDEMPTION"]
      }
    ]),
  
  [TARGETED_ACTIONS_LIST]: (state) => function (localActionsList: ActionsList): TargetedActionsList {
    // Конвертируем в объект со всеми ключами и значениями false
    const allPossibleActions = Object.fromEntries([
      ...state.targetedLeads.map((item: TargetedList) => [ item.value, false ]),
      ...state.targetedApproves.map((item: TargetedList) => [ item.value, false ]),
      ...state.targetedSpams.map((item: TargetedList) => [ item.value, false ]),
      ...state.targetedRedemption.map((item: TargetedList) => [ item.value, false ])
    ]);
  
    // Меняем значения ключей содержащихся в массивах localStorage или селектов модального окна
    Object.entries(localActionsList ?? state.actionsList).forEach(([, value]: [string, string[]]) => {
      // Фильтруем массив по правам (если нет права, то убираем из массива)
      const filteredPermissionsValue = mixPermissions(value, [
        {
          name: "redemption",
          permissions: ["STATISTICS.SHOW.REDEMPTION"]
        }
      ]);
  
      filteredPermissionsValue?.forEach((item: string) => {
        allPossibleActions[item] = true;
      });
    });
  
    return allPossibleActions;
  }
};

const mutations: MutationTree<AnalyticModal> = {
  [SET_EMPTY]: state => Object.assign(state, initialState()),
  
  [UPDATE_MODAL_ACTIVE] (state, payload) {
    state.isModalActive = payload;
  },
  
  [UPDATE_ACTIONS_LIST] (state, payload) {
    localStorage.setItem("targetedActions", JSON.stringify(payload));
  }
};

export const analyticModal: Module<AnalyticModal, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations
};
