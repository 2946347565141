var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Background',[_c('div',{staticClass:"form-wrapper"},[_c('el-form',{ref:"form",staticClass:"form",attrs:{"model":_vm.form},nativeOn:{"submit":function($event){$event.preventDefault();$event.stopPropagation();return _vm.captchaExecute.apply(null, arguments)}}},[_c('img',{staticClass:"form-logo",attrs:{"alt":"FAIRPAY","src":require("@/assets/fairpay-splash.png")}}),(_vm.isError)?_c('el-alert',{attrs:{"show-icon":"","closable":false,"type":"error"}},[_c('i18n',{attrs:{"path":"common.auth.signUp.error.content"},scopedSlots:_vm._u([{key:"login",fn:function(){return [_c('span',{directives:[{name:"t",rawName:"v-t",value:('common.auth.signUp.error.particles.login'),expression:"'common.auth.signUp.error.particles.login'"}],staticClass:"has-text-weight-semibold"})]},proxy:true},{key:"email",fn:function(){return [_c('span',{directives:[{name:"t",rawName:"v-t",value:('common.auth.signUp.error.particles.email'),expression:"'common.auth.signUp.error.particles.email'"}],staticClass:"has-text-weight-semibold"})]},proxy:true}],null,false,1368196486)})],1):_vm._e(),_c('RequiredField',{attrs:{"label":_vm.$t('common.entity.role.entityName'),"prop":"userType","rules":{ required: true }}},[_c('FSelect',{staticClass:"w-full",attrs:{"get-data":_vm.getRoles,"placeholder":_vm.$t(`common.auth.signUp.role`),"expanded":"","prop":"value","field":"label","required":""},model:{value:(_vm.form.userType),callback:function ($$v) {_vm.$set(_vm.form, "userType", $$v)},expression:"form.userType"}})],1),_c('RequiredField',{attrs:{"label":_vm.$t('common.auth.signUp.email'),"prop":"email","rules":[
                    { required: true, message: _vm.$t(`common.auth.signUp.emptyField`), trigger: 'change' },
                    { type: 'email', message: _vm.$t(`common.auth.signUp.emailHelp`), trigger: ['blur', 'change'] }
                ]}},[_c('FInput',{attrs:{"pattern":_vm.emailValidator,"placeholder":_vm.$t(`common.auth.signUp.email`),"required":"","type":"email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1),_c('RequiredField',{attrs:{"message":_vm.$t(`common.auth.signUp.loginHelp`),"label":_vm.$t('common.auth.signIn.login'),"prop":"login","rules":[
                    { required: true, message: _vm.$t(`common.auth.signUp.loginHelp`), trigger: 'change' },
                    { pattern: '[\\w+]{2,}', message: _vm.$t(`common.auth.signUp.loginHelp`), trigger: ['blur', 'change'] }
                ]}},[_c('FInput',{attrs:{"name":"login","required":"","placeholder":_vm.$t(`common.auth.signUp.login`)},model:{value:(_vm.form.login),callback:function ($$v) {_vm.$set(_vm.form, "login", $$v)},expression:"form.login"}})],1),_c('RequiredField',{class:{ 'is-default-style': _vm.isRequiredTelegram },attrs:{"label":_vm.$t('common.auth.signUp.telegram'),"rules":[
                    { required: true, message: _vm.$t(`common.auth.signUp.emptyField`), trigger: 'change' },
                    { pattern: '^[\w]{5,}$', message: _vm.$t(`common.auth.signUp.telegramHelp`), trigger: ['blur', 'change'] }
                ],"prop":"telegram"}},[_c('FInput',{attrs:{"required":"","minlength":"5","placeholder":_vm.$t(`common.auth.signUp.telegram`)},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_vm._v(" @ ")]},proxy:true}]),model:{value:(_vm._telegram),callback:function ($$v) {_vm._telegram=$$v},expression:"_telegram"}})],1),_c('EditUserPasswordField',{attrs:{"label":_vm.$t('common.auth.signUp.password'),"prop":"password","show-asterisk":"","rules":[
                    { required: true, message: _vm.$t(`common.auth.signUp.emptyField`), trigger: 'change' },
                    { pattern: this.passwordValidator, message: _vm.$t(`common.auth.signUp.passwordHelp`), trigger: ['blur', 'change'] }
                ],"placeholder":_vm.$t(`common.auth.signUp.password`)},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('Captcha',{ref:"captcha",on:{"onVerify":function($event){return _vm.submit($event)}}}),_c('FButton',{attrs:{"loading":_vm.isLoading,"native-type":"submit","disabled":!_vm.isRequiredTelegram || !_vm.isRequiredPassword,"type":"success","no-more":"","plain":""}},[_vm._v(" "+_vm._s(_vm.$t(`common.auth.signUp.submit`))+" ")]),_c('router-link',{staticClass:"form-subbutton",attrs:{"to":{ name: 'auth:signIn' }}},[_vm._v(" "+_vm._s(_vm.$t(`common.auth.signIn.signIn`))+" ")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }