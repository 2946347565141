import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import { BadgesService } from "@core/services/common/badges/BadgesService";
import badgesList from "@core/services/common/badges/datasets/badgesList.json";
import { BadgesCount } from "@core/store/types/common/badges";
import { FINANCE_TOTAL, SET_BADGES, USERS_TOTAL } from "@core/store/mutation-constants";
import { GET_BADGES } from "@core/store/action-constants";

/*
* Каждая группа бейджей и имеет свои ключи для отображения в табах
* users - [ webmasters, advertisers, admins ]
* finances - [ outTransactions, inTransactions, webAccrual, financeTransactions ]
* */
const state: BadgesCount = {
  outTransactions: 0,
  webmasters: 0
};

// Возвращаем сумму значений из группы, например: outTransactions + inTransactions + webAccrual + financeTransactions
const getters: GetterTree<BadgesCount, RootState> = {
  [FINANCE_TOTAL]: ({ outTransactions }) =>
    outTransactions,
  [USERS_TOTAL]: ({ webmasters }) => webmasters
};

const mutations: MutationTree<BadgesCount> = {
  [SET_BADGES] (state, payload) {
    state = Object.assign(state, payload);
  }
};

// Возвращаем ключ такой же как и в state
const actions: ActionTree<BadgesCount, RootState> = {
  async [GET_BADGES] ({ commit }, operations: Array<keyof BadgesCount> = []) {
    const badgesOperations = operations.length ? operations : Object.keys(badgesList);
    
    try {
      const { data } = await BadgesService.getBadges(
        badgesOperations as Array<keyof BadgesCount>
      );
      const dataBadges: BadgesCount = {};
      
      Object.keys(data).forEach((item) => {
        dataBadges[item as keyof BadgesCount] = data[item].count;
      });
      
      commit(SET_BADGES, dataBadges);
      
    } catch (e: any) {
      throw new Error(e);
    }
  }
};

export const badges: Module<BadgesCount, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};