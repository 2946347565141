import { admin } from "@/router/admin";
import { advertiser } from "@/router/advertiser";
import auth from "@/router/common/auth";
import dashboard from "@/router/common/dashboard";
import mail from "@/router/common/mail";
import transaction from "@/router/common/transaction";
import { webmaster } from "@/router/webmaster";
import store from "../store";
import { ERROR, SUCCESS } from "@/store/mutation-constants";
import Empty from "@/views/Empty.vue";

import URLNotFound from "@/views/URLNotFound.vue";
import Vue, { CreateElement, VNode } from "vue";
import Router, { Route, RouteConfig } from "vue-router";
import { NavigationGuard, Position, PositionResult } from "vue-router/types/router";

// eslint-disable-next-line default-param-last
function recursivePaths (routes: RouteConfig[] = [], name?: string): RouteConfig[] {
  for (let i = 0, l = routes.length; i < l; i++) {
    // @ts-ignore
    if (routes[i].component == null) {
      // @ts-ignore
      routes[i].component = { render: (c: CreateElement): VNode => c("router-view") };
    }
    
    if (name && routes[i].name) {
      routes[i].name = `${ name }:${ routes[i].name }`;
    }

    if (routes[i].children) {
      if (routes[i].name) {
        recursivePaths(routes[i].children, routes[i].name);
      } else {
        recursivePaths(routes[i].children, name);
      }
    }
  }
  
  return routes;
}

const routes: RouteConfig[] = [
  {
    path: "/",
    name: "main",
    redirect: { name: "dashboard" }
  },
  dashboard,
  auth,
  mail,
  // transaction,
  webmaster,
  // advertiser,
  admin,
  {
    path: "",
    component: Empty,
    children: [
      {
        path: "*",
        name: "page-not-found",
        component: URLNotFound
      }
    ]
  }
];

Vue.use(Router);

// const QsOptions = {
//   encode: false,
//   format: "RFC1738",
//   arrayFormat: "repeat",
//   strictNullHandling: true
//
// };

// const QsOptionStringify = Object.assign({}, QsOptions, {
//   addQueryPrefix: true,
//   serializeDate: (date: Date) => moment(date).format('x')
// });

// var decode = function (str, decoder, charset) {
//   var strWithoutPlus = str.replace(/\+/g, ' ');
//   if (charset === 'iso-8859-1') {
//     // unescape never throws, no try...catch needed:
//     return strWithoutPlus.replace(/%[0-9a-f]{2}/gi, unescape);
//   }
//   // utf-8
//   try {
//     return decodeURIComponent(strWithoutPlus);
//   } catch (e) {
//     return strWithoutPlus;
//   }
// };

// const QsOptionParse = Object.assign({}, QsOptions, {
//   // decoder (str: string, defaultEncoder: Function, charset: string, type: string) {
//   // if (type === 'key') {
//   //   return // Decoded key
//   // } else if (type === 'value') {
//   //   return // Decoded value
//   // }
//
//   //   return str;
//   // }
// });

const scrollBehavior = (to: Route, from: Route, savedPosition: Position | void):
PositionResult | Promise<PositionResult> => {
  if (to.hash) {
    return {
      selector: to.hash,
      offset: {
        x: 0,
        y: 100
      }
    };
  } else {
    return savedPosition || {
      x: 0,
      y: 0
    };
  }
};

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior,
  routes: recursivePaths(routes)
  // stringifyQuery: query => qs.stringify(query, QsOptionStringify),
  // parseQuery: query => qs.parse(query, QsOptionParse)
});

const guard: NavigationGuard = (to, _, next) => {
  const isUserLogin = store.getters.isUserLogin;
  const isTokenSet = store.getters.isTokenSet;
  const isUserBlocked = store.getters.isUserBlocked;
  
  const roles = ["webmaster", "advertiser", "admin"];
  const currentRole = store.state.auth.role?.toLowerCase();
  const currentRoute = to;

  // Тут берём path, потому что для роутера этой страницы нет и currentRoute
  // вернёт name === 'page-not-found' в то время как path будет равен адресу,
  // на который мы изначально хотим попасть
  const currentUrlRole = currentRoute.path.slice(1).split("/")[0];
  const currentUrlIncludeRole = roles.includes(currentUrlRole);
  
  if (currentRole && currentRole !== currentUrlRole && currentUrlIncludeRole) {
    // аналогично с коментом выше, но тут нужно превратить новый адрес в шаблон имени роута,
    // т.к. path в роутере не содержит имя родителя, если он вложенный, в отличие от name
    const newRoutePath = currentRoute.path?.replace(currentUrlRole, currentRole);
    const resolvedRoute = router.resolve({ path: newRoutePath });

    if (resolvedRoute.resolved.matched.length) {
      return next({
        path: newRoutePath,
        params: to.params
      });
    } else {
      return next({
        name: "dashboard"
      });
    }
  }
  
  if (to.name) {
    if (!to.name.startsWith("auth")) {
      if (!isUserLogin || isUserBlocked) {
        return next({ name: "auth:signIn" });
      } else {
        if (isTokenSet) {
          // @ts-ignore
          if (
            store.state.auth.isConfirmed === true &&
            store.state.auth.isApproved === false
          ) {
            if (to.name !== "auth:signUpProfile") {
              return next({ name: "auth:signUpProfile" });
            }
          } else if (
            store.state.auth.isConfirmed === false &&
            store.state.auth.isApproved === false
          ) {
            if (to.name !== "mail:send") {
              return next({ name: "mail:send" });
            }
          }
        }
      }
    } else {
      // if (isUserLogin && isActive) { router.back(); }
    }
  }

  if (!isUserLogin && !(to.name ? to.name.startsWith("auth") : false)) {
    next({ name: "auth:signIn" });
  } else {
    store.commit(SUCCESS, null);
    store.commit(ERROR, null);
    return next();
  }
};

router.beforeEach((...args) => {
  if (store.state.auth.role || args[0].name?.startsWith("auth")) {
    guard(...args);
  } else {
    // Ждём, пока загрузится роль
    // Попадём сюда при первой загрузке сайта, т.к. в этот момент "store.state.auth.role" пустой
    store.watch(
      (state) => state.auth.role,
      () => { guard(...args); }
    );
  }
});

router.onError(error => {
  if (/loading chunk \S* failed/i.test(error.message)) {
    store.commit("SET_IS_REQUEST_BLOCKED", true);
  }
});

export default router;
