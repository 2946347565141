import Service from "@core/services/common/Service";
import { OffersFilters, OffersResponseInterface, OffersState } from "@core/store/types/webmaster/offers";
import offers from "./graphql/offers.graphql";

export class OffersService extends Service {
  public static async getOffers (
    filters: OffersFilters,
    limit: number,
    offset: number,
    sort: OffersState["sort"],
    order: OffersState["order"]
  ): Promise<OffersResponseInterface> {
    try {
      return await this.api.get("", {
        params: {
          query: offers,
          variables: {
            filters,
            limit,
            offset,
            sort,
            order
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
}
