<template>
    <el-progress
        v-bind="$attrs"
        :text-inside="textInside"
        :stroke-width="strokeWidth"
        :percentage="percentage"
        :color="customColors">
    </el-progress>
</template>

<script>
  export default {
    name: "FProgress",

    props: {
      textInside: {
        type: Boolean,
        default: true
      },

      strokeWidth: {
        type: Number,
        default: 12
      },

      percentage: {
        type: Number,
        required: true
      },

      customColors: {
        type: String,
        default: "#7957d5"
      }
    }
  };
</script>

<style lang="scss" scoped>
::v-deep {
    .el-progress-bar {
        &__inner {
            display: flex;
            justify-content: flex-end;
        }

        &__innerText {
            font-size: calc(0.875rem / 1.5);
            line-height: 0.875rem;
            font-weight: 700;
            white-space: nowrap;
        }
    }
}
</style>
