<template>
    <el-menu
        :default-active="asideActive"
        class="bg-gray-background border-0"
        :collapse="false">
        <template v-for="(item, index) in menu">
            <AsideMenuItem
                :key="index"
                :is-mobile-menu-open.sync="_isMobileMenuOpen"
                :item="item"
                :index="index"></AsideMenuItem>
        </template>
    </el-menu>
</template>

<script>
  import crm from "@/assets/AsideMenu/Admin/crm.svg";
  import dashboard from "@/assets/AsideMenu/Admin/dashboard.svg";
  import domains from "@/assets/AsideMenu/Admin/domains.svg";
  import efficiency from "@/assets/AsideMenu/Admin/efficiency.svg";
  import finance from "@/assets/AsideMenu/Admin/finance.svg";
  import integrations from "@/assets/AsideMenu/Admin/integrations.svg";
  import offers from "@/assets/AsideMenu/Admin/offers.svg";
  import paymentSystems from "@/assets/AsideMenu/Admin/payment-systems.svg";
  import statistics from "@/assets/AsideMenu/Admin/statistics.svg";
  import users from "@/assets/AsideMenu/Admin/users.svg";
  import AsideMenuItem from "@/components/Common/AsideMenuItem";
  
  export default {
    name: "AsideMenu",
    components: {
      AsideMenuItem
    },
    
    props: {
      isMobileMenuOpen: {
        type: Boolean,
        default: false
      }
    },
    
    computed: {
      _isMobileMenuOpen: {
        get () {
          return this.isMobileMenuOpen;
        },
        set (value) {
          this.$emit("update:isMobileMenuOpen", value);
        }
      },
      
      menu () {
        return [
          {
            to: { name: "dashboard" },
            label: this.$t("admin.menu.dashboard"),
            icon: dashboard,
            permissions: [
              "SUPER.PERMISSION",
              "DASHBOARD.DEBIT.GET",
              "DASHBOARD.PREPAID_EXPENSE.GET",
              "DASHBOARD.COMMISSION.GET",
              "DASHBOARD.CASH_FLOW.GET"
            ]
          },
          {
            to: { name: "admin:statistics" },
            label: this.$t("admin.menu.statistics"),
            icon: statistics,
            permissions: [
              "STATISTICS.VIEW"
            ]
          },
          {
            to: { name: "admin:users" },
            label: this.$t("admin.menu.users"),
            icon: users,
            permissions: [
              "WEBMASTERS.LIST.ALL",
              "WEBMASTERS.LIST.OWN",
              "WEBMASTERS.LIST.UNBIND",
              "ADVERTISERS.LIST.ALL",
              "ADVERTISERS.LIST.UNBIND",
              "ADVERTISERS.LIST.OWN"
            ]
          },
          {
            to: { name: "admin:offers" },
            label: this.$t("admin.menu.offers"),
            icon: offers,
            permissions: "ANY"
          },
          // {
          //   to: { name: "admin:efficiency" },
          //   label: this.$t("admin.menu.efficiency"),
          //   icon: efficiency,
          //   permissions: [
          //     "MANAGER_EFFICIENCY.VIEW"
          //   ]
          // },
          {
            to: { name: "admin:integrations" },
            label: this.$t("admin.menu.integrations"),
            icon: integrations,
            permissions: [
              "INTEGRATIONS.LIST"
            ]
          },
          {
            to: { name: "admin:finances" },
            label: this.$t("admin.menu.finances"),
            icon: finance,
            permissions: [
              "SUPER.PERMISSION",
              "FINANCES.BALANCE.VIEW"
            ]
          },
          // {
          //   to: { name: "admin:payments" },
          //   label: this.$t("admin.menu.payments"),
          //   icon: paymentSystems,
          //   permissions: [
          //     "SUPER.PERMISSION"
          //   ]
          // },
          {
            to: { name: "admin:roles" },
            label: this.$t("admin.menu.roles"),
            icon: users,
            permissions: [
              "ROLE.CREATE",
              "ROLE.UPDATE",
              "ROLE.DELETE"
            ]
          },
          {
            to: { name: "admin:domains" },
            label: this.$t("admin.menu.domains"),
            icon: domains,
            permissions: [
              "SUPER.PERMISSION"
            ]
          }
          // {
          //   to: { name: "admin:CRM" },
          //   label: this.$t("admin.menu.CRM"),
          //   icon: crm,
          //   permissions: [
          //     "CRM.MANAGE"
          //   ]
          // }
        ];
      },
    
      asideActive () {
        const path = this.$route.path?.split("/");
        return path[2] ?? path[1];
      }
    }
  };
</script>

<style scoped lang="scss">
    ::v-deep {
        .el-menu-item {
            padding: 0.75rem!important;

            .label {
                margin-left: 1rem;
            }
        }
    }
</style>
