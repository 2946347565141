import { CompetitionService } from "@core/services/webmaster/competition/CompetitionService";
import {
  GET_COMPETITION_TOP,
  GET_COMPETITION_PLACE,
  GET_CURRENT_COMPETITION
} from "@core/store/action-constants";
import {
  SET_CURRENT_COMPETITION,
  SET_COMPETITION_TOP,
  SET_EMPTY
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { CompetitionState, CompetitionTop } from "@core/store/types/webmaster/competition";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

const initialState = (): CompetitionState => {
  return {
    competitionTop: null,
    currentCompetition: null
  };
};

export const state: () => CompetitionState = initialState;

const getters: GetterTree<CompetitionState, RootState> = {
  [GET_COMPETITION_PLACE]: (state) => {
    return state.competitionTop?.find((item: CompetitionTop) => item.isMe)?.place;
  }
};

const mutations: MutationTree<CompetitionState> = {
  [SET_CURRENT_COMPETITION] (state, currentCompetition) {
    state.currentCompetition = currentCompetition;
  },
  
  [SET_COMPETITION_TOP] (state, competitionTop) {
    state.competitionTop = competitionTop;
  },
  
  [SET_EMPTY] (state) {
    Object.assign(state, initialState());
  }
};

const actions: ActionTree<CompetitionState, RootState> = {
  async [GET_CURRENT_COMPETITION] ({ state, commit, dispatch }) {
    const { data: { currentCompetition: data } } = await CompetitionService.getCurrentCompetition();
    
    commit(SET_CURRENT_COMPETITION, data);
    if (state.currentCompetition?.competition?.id) {
      dispatch(GET_COMPETITION_TOP);
    }
  },
  
  async [GET_COMPETITION_TOP] ({ state, commit }) {
    const competitionId = state.currentCompetition?.competition?.id;
    // @ts-ignore
    const { data: { competitionTop: data } } = await CompetitionService.getCompetitionTop(competitionId);
    
    commit(SET_COMPETITION_TOP, data);
  },
  
  [SET_EMPTY] ({ commit }) {
    commit(SET_EMPTY);
  }
};

export const competition: Module<CompetitionState, RootState> = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
