// @ts-ignore
import { AgentResponse } from "@core/store/types/webmaster/user/agent";
import { Avatar } from "@core/store/types/webmaster/user/manager";

export class Manager {
  public name?: string;
  public email?: string;
  public phone?: string;
  public telegram?: string;
  public vk?: string;
  public description?: string;
  public workedYear?: string;
  public avatar?: Avatar;
  
  constructor (manager?: Manager) {
    if (manager) {
      this.name = manager.name;
      this.email = manager.email;
      this.phone = manager.phone;
      this.avatar = {
        id: manager.avatar?.id,
        url: manager.avatar?.url
      };
      this.telegram = manager.telegram;
      this.vk = manager.vk;
      this.workedYear = manager.workedYear;
      this.description = manager.description;
    }
  }
}

export class Agent {
  public name?: string;
  public email?: string;
  public telegram?: string;
  public avatar?: Avatar;
  
  constructor (agent?: AgentResponse) {
    if (agent) {
      this.name = agent.personalAgent.login;
      this.email = agent.personalAgent.contact.email;
      this.telegram = agent.personalAgent.contact.telegram;
    }
  }
}
