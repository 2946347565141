<template>
    <div class="icon">
        <svg
            v-if="icon === 'target'"
            width="32"
            height="32"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18 0C8.07471 0 0 8.07471 0 18C0 27.9253 8.07471 36 18 36C27.9253 36 36 27.9253 36 18C36 8.07471 27.9253 0 18 0ZM18 35C8.62598 35 1 27.374 1 18C1 8.62598 8.62598 1 18 1C27.374 1 35 8.62598 35 18C35 27.374 27.374 35 18 35ZM18 8C12.4858 8 8 12.4858 8 18C8 23.5142 12.4858 28 18 28C23.5142 28 28 23.5142 28 18C28 12.4858 23.5142 8 18 8ZM18 27C13.0376 27 9 22.9624 9 18C9 13.0376 13.0376 9 18 9C22.9624 9 27 13.0376 27 18C27 22.9624 22.9624 27 18 27ZM20 17.9999C20 19.1045 19.1046 19.9999 18 19.9999C16.8954 19.9999 16 19.1045 16 17.9999C16 16.8953 16.8954 15.9999 18 15.9999C19.1046 15.9999 20 16.8953 20 17.9999Z"
                :fill="color">
            </path>
        </svg>

        <svg
            v-if="icon === 'user-plus'"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M32 31.5C32 31.7764 31.7764 32 31.5 32C31.2236 32 31 31.7764 31 31.5C31 23.5049 24.271 17 16 17C7.729 17 1 23.5049 1 31.5C1 31.7764 0.776367 32 0.5 32C0.223633 32 0 31.7764 0 31.5C0 22.9531 7.17773 16 16 16C24.8223 16 32 22.9531 32 31.5ZM9 7C9 3.14014 12.1401 0 16 0C19.8599 0 23 3.14014 23 7C23 10.8599 19.8599 14 16 14C12.1401 14 9 10.8599 9 7ZM10 7C10 10.3086 12.6914 13 16 13C19.3086 13 22 10.3086 22 7C22 3.69141 19.3086 1 16 1C12.6914 1 10 3.69141 10 7ZM19 27H17V25C17 24.4478 16.5522 24 16 24C15.4478 24 15 24.4478 15 25V27H13C12.4478 27 12 27.4478 12 28C12 28.5522 12.4478 29 13 29H15V31C15 31.5522 15.4478 32 16 32C16.5522 32 17 31.5522 17 31V29H19C19.5522 29 20 28.5522 20 28C20 27.4478 19.5522 27 19 27Z"
                :fill="color">
            </path>
        </svg>

        <svg
            v-if="icon === 'deposit'"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M32 26.5V31.5C32 31.7764 31.7764 32 31.5 32H0.5C0.223633 32 0 31.7764 0 31.5V26.5C0 26.2236 0.223633 26 0.5 26C0.776367 26 1 26.2236 1 26.5V31H31V26.5C31 26.2236 31.2236 26 31.5 26C31.7764 26 32 26.2236 32 26.5ZM9 3C9 1.3457 10.3457 0 12 0C13.6543 0 15 1.3457 15 3C15 4.6543 13.6543 6 12 6C10.3457 6 9 4.6543 9 3ZM10 3C10 4.10303 10.8975 5 12 5C13.1025 5 14 4.10303 14 3C14 1.89697 13.1025 1 12 1C10.8975 1 10 1.89697 10 3ZM9 11C9 9.3457 10.3457 8 12 8C13.6543 8 15 9.3457 15 11C15 12.6543 13.6543 14 12 14C10.3457 14 9 12.6543 9 11ZM10 11C10 12.103 10.8975 13 12 13C13.1025 13 14 12.103 14 11C14 9.89697 13.1025 9 12 9C10.8975 9 10 9.89697 10 11ZM9 19C9 17.3457 10.3457 16 12 16C13.6543 16 15 17.3457 15 19C15 20.6543 13.6543 22 12 22C10.3457 22 9 20.6543 9 19ZM10 19C10 20.103 10.8975 21 12 21C13.1025 21 14 20.103 14 19C14 17.897 13.1025 17 12 17C10.8975 17 10 17.897 10 19ZM17 3C17 1.3457 18.3457 0 20 0C21.6543 0 23 1.3457 23 3C23 4.6543 21.6543 6 20 6C18.3457 6 17 4.6543 17 3ZM18 3C18 4.10303 18.8975 5 20 5C21.1025 5 22 4.10303 22 3C22 1.89697 21.1025 1 20 1C18.8975 1 18 1.89697 18 3ZM17 11C17 9.3457 18.3457 8 20 8C21.6543 8 23 9.3457 23 11C23 12.6543 21.6543 14 20 14C18.3457 14 17 12.6543 17 11ZM18 11C18 12.103 18.8975 13 20 13C21.1025 13 22 12.103 22 11C22 9.89697 21.1025 9 20 9C18.8975 9 18 9.89697 18 11ZM17 19C17 17.3457 18.3457 16 20 16C21.6543 16 23 17.3457 23 19C23 20.6543 21.6543 22 20 22C18.3457 22 17 20.6543 17 19ZM18 19C18 20.103 18.8975 21 20 21C21.1025 21 22 20.103 22 19C22 17.897 21.1025 17 20 17C18.8975 17 18 17.897 18 19Z"
                :fill="color">
            </path>
        </svg>

        <svg
            v-if="icon === 'income'"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M32 26.5V31.5C32 31.7764 31.7764 32 31.5 32H0.5C0.223633 32 0 31.7764 0 31.5V26.5C0 26.2236 0.223633 26 0.5 26C0.776367 26 1 26.2236 1 26.5V31H31V26.5C31 26.2236 31.2236 26 31.5 26C31.7764 26 32 26.2236 32 26.5ZM7.5 1H24.5C24.7764 1 25 0.776367 25 0.5C25 0.223633 24.7764 0 24.5 0H7.5C7.22363 0 7 0.223633 7 0.5C7 0.776367 7.22363 1 7.5 1ZM7.5 6H24.5C24.7764 6 25 5.77637 25 5.5C25 5.22363 24.7764 5 24.5 5H7.5C7.22363 5 7 5.22363 7 5.5C7 5.77637 7.22363 6 7.5 6ZM7.5 11H24.5C24.7764 11 25 10.7764 25 10.5C25 10.2236 24.7764 10 24.5 10H7.5C7.22363 10 7 10.2236 7 10.5C7 10.7764 7.22363 11 7.5 11ZM7.5 16H24.5C24.7764 16 25 15.7764 25 15.5C25 15.2236 24.7764 15 24.5 15H7.5C7.22363 15 7 15.2236 7 15.5C7 15.7764 7.22363 16 7.5 16ZM7.5 21H24.5C24.7764 21 25 20.7764 25 20.5C25 20.2236 24.7764 20 24.5 20H7.5C7.22363 20 7 20.2236 7 20.5C7 20.7764 7.22363 21 7.5 21Z"
                :fill="color">
            </path>
        </svg>

        <svg
            v-if="icon === 'delete'"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5 0.5C5 0.223633 5.22363 0 5.5 0H8.5C8.77637 0 9 0.223633 9 0.5C9 0.776367 8.77637 1 8.5 1H5.5C5.22363 1 5 0.776367 5 0.5ZM14 2.5V5.5C14 5.77637 13.7764 6 13.5 6H12V13.5C12 13.7764 11.7764 14 11.5 14H2.5C2.22363 14 2 13.7764 2 13.5V6H0.5C0.223633 6 0 5.77637 0 5.5V2.5C0 2.22363 0.223633 2 0.5 2H13.5C13.7764 2 14 2.22363 14 2.5ZM11 6H3V13H11V6ZM13 3H1V5H2.5H11.5H13V3ZM5.5 12C5.77637 12 6 11.7764 6 11.5V7.5C6 7.22363 5.77637 7 5.5 7C5.22363 7 5 7.22363 5 7.5V11.5C5 11.7764 5.22363 12 5.5 12ZM8.5 12C8.77637 12 9 11.7764 9 11.5V7.5C9 7.22363 8.77637 7 8.5 7C8.22363 7 8 7.22363 8 7.5V11.5C8 11.7764 8.22363 12 8.5 12Z"
                :fill="color">
            </path>
        </svg>


        <svg
            v-if="icon === 'edit'"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.8557 3.39418L10.6389 0.14288C10.4514 -0.0476265 10.1154 -0.0476265 9.92791 0.14288L1.06059 9.10497C1.05906 9.10656 1.05863 9.1087 1.05711 9.11028C1.05033 9.1173 1.04771 9.12683 1.04136 9.13428C0.993509 9.19021 0.961892 9.25286 0.942666 9.32008C0.939858 9.32985 0.931008 9.33626 0.92875 9.34628L0.0127195 13.3894C-0.025367 13.554 0.0234619 13.726 0.140651 13.8476C0.235379 13.9458 0.366241 14 0.500032 14C0.531282 14 0.562533 13.9971 0.593783 13.9912L4.72764 13.1999C4.74046 13.1974 4.74894 13.1866 4.76139 13.1831C4.82407 13.1663 4.88065 13.1383 4.93247 13.0986C4.94621 13.0881 4.96171 13.0823 4.97428 13.0704C4.9788 13.0662 4.98503 13.0646 4.98936 13.0602L13.8557 4.09759C14.0481 3.90269 14.0481 3.58909 13.8557 3.39418ZM1.15629 12.8648L1.70214 10.4567L2.32526 11.0864L3.61824 12.3934L1.15629 12.8648ZM4.63389 11.9972L3.527 10.8789L2.11938 9.45649L10.2834 1.20581L12.7971 3.74589L4.63389 11.9972Z"
                :fill="color">
            </path>
        </svg>

        <svg
            v-if="icon === 'plugOn'"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.6649 13.6587C12.1039 15.2183 10.0533 15.998 8.00266 15.998C5.953 15.998 3.90237 15.2183 2.34142 13.6587C-0.780474 10.5391 -0.780474 5.46387 2.34142 2.34521C3.8535 0.833984 5.86406 0.00195312 8.00364 0.00195312C8.71246 0.00195312 9.4046 0.101685 10.0718 0.278931L8.33145 2.01794C8.22228 2.01208 8.11385 2.00195 8.00364 2.00195C6.39871 2.00195 4.89054 2.62598 3.75673 3.75928C1.4158 6.09863 1.4158 9.90479 3.75673 12.2446C6.09962 14.584 9.90864 14.5835 12.2496 12.2446C13.4993 10.9955 14.0602 9.32996 13.9756 7.69128L15.7296 5.93854C16.4383 8.60626 15.755 11.57 13.6649 13.6587ZM8.00364 8.00195C8.19913 8.19727 8.45521 8.29492 8.7113 8.29492C8.96738 8.29492 9.22347 8.19727 9.41895 8.00195L13.6649 3.75928C14.0559 3.36865 14.0559 2.73584 13.6649 2.34521C13.2739 1.95459 12.6405 1.95459 12.2496 2.34521L8.00364 6.58789C7.61267 6.97852 7.61267 7.61133 8.00364 8.00195Z"
                :fill="color"></path>
        </svg>
      

        <svg
            v-if="icon === 'createFlow'"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.5 0C1.57031 0 0 1.57031 0 3.5C0 5.42969 1.57031 7 3.5 7C5.42969 7 7 5.42969 7 3.5C7 1.57031 5.42969 0 3.5 0ZM3.5 5C2.67285 5 2 4.32715 2 3.5C2 2.67285 2.67285 2 3.5 2C4.32715 2 5 2.67285 5 3.5C5 4.32715 4.32715 5 3.5 5ZM12.5 7C14.4297 7 16 5.42969 16 3.5C16 1.57031 14.4297 0 12.5 0C10.5703 0 9 1.57031 9 3.5C9 5.42969 10.5703 7 12.5 7ZM12.5 2C13.3271 2 14 2.67285 14 3.5C14 4.32715 13.3271 5 12.5 5C11.6729 5 11 4.32715 11 3.5C11 2.67285 11.6729 2 12.5 2ZM12.5 9C10.5703 9 9 10.5703 9 12.5C9 14.4297 10.5703 16 12.5 16C14.4297 16 16 14.4297 16 12.5C16 10.5703 14.4297 9 12.5 9ZM12.5 14C11.6729 14 11 13.3271 11 12.5C11 11.6729 11.6729 11 12.5 11C13.3271 11 14 11.6729 14 12.5C14 13.3271 13.3271 14 12.5 14ZM7 12.5C7 13.0522 6.55273 13.5 6 13.5H4.5V15C4.5 15.5522 4.05273 16 3.5 16C2.94727 16 2.5 15.5522 2.5 15V13.5H1C0.447266 13.5 0 13.0522 0 12.5C0 11.9478 0.447266 11.5 1 11.5H2.5V10C2.5 9.44775 2.94727 9 3.5 9C4.05273 9 4.5 9.44775 4.5 10V11.5H6C6.55273 11.5 7 11.9478 7 12.5Z"
                :fill="color"></path>
        </svg>

        <svg
            v-if="icon === 'copy'"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                class="fill-current"
                d="M13.5 4H10V0.5C10 0.223633 9.77637 0 9.5 0H0.5C0.223633 0 0 0.223633 0 0.5V9.5C0 9.77637 0.223633 10 0.5 10H4V13.5C4 13.7764 4.22363 14 4.5 14H13.5C13.7764 14 14 13.7764 14 13.5V4.5C14 4.22363 13.7764 4 13.5 4ZM1 9V1H9V4H4.5C4.22363 4 4 4.22363 4 4.5V9H1ZM9 5V9H5V5H9ZM13 13H5V10H9.5C9.77637 10 10 9.77637 10 9.5V5H13V13Z"
                :fill="color"></path>
        </svg>

        <svg
            v-if="icon === 'more'"
            width="15"
            height="15"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.0004 6.66672C16.8413 6.66672 18.3337 5.17432 18.3337 3.33336C18.3337 1.4924 16.8413 0 15.0004 0C13.1594 0 11.667 1.4924 11.667 3.33336C11.667 5.17432 13.1594 6.66672 15.0004 6.66672Z"
                :fill="color"></path>
            <path
                d="M15.0004 18.3333C16.8413 18.3333 18.3337 16.841 18.3337 15C18.3337 13.159 16.8413 11.6666 15.0004 11.6666C13.1594 11.6666 11.667 13.159 11.667 15C11.667 16.841 13.1594 18.3333 15.0004 18.3333Z"
                :fill="color"></path>
            <path
                d="M15.0004 29.9999C16.8413 29.9999 18.3337 28.5076 18.3337 26.6666C18.3337 24.8256 16.8413 23.3333 15.0004 23.3333C13.1594 23.3333 11.667 24.8256 11.667 26.6666C11.667 28.5076 13.1594 29.9999 15.0004 29.9999Z"
                :fill="color">
            </path>
        </svg>

        <svg
            v-if="icon === 'check'"
            width="16"
            height="10"
            viewBox="0 0 16 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.8535 0.853516L6.85352 9.85352C6.75587 9.95117 6.62794 10 6.50001 10C6.37208 10 6.24415 9.95117 6.14649 9.85352L0.146493 3.85352C-0.0488311 3.6582 -0.0488311 3.3418 0.146493 3.14648C0.341806 2.95117 0.658212 2.95117 0.853525 3.14648L6.50001 8.79297L15.1465 0.146484C15.3418 -0.0488281 15.6582 -0.0488281 15.8535 0.146484C16.0488 0.341797 16.0488 0.658203 15.8535 0.853516Z"
                :fill="color"></path>
        </svg>

        <svg
            v-if="icon === 'times'"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.8535 11.1465C12.0488 11.3418 12.0488 11.6582 11.8535 11.8535C11.7559 11.9512 11.6279 12 11.5 12C11.3721 12 11.2441 11.9512 11.1465 11.8535L6 6.70703L0.853516 11.8535C0.755859 11.9512 0.62793 12 0.5 12C0.37207 12 0.244141 11.9512 0.146484 11.8535C-0.0488281 11.6582 -0.0488281 11.3418 0.146484 11.1465L5.29297 6L0.146484 0.853516C-0.0488281 0.658203 -0.0488281 0.341797 0.146484 0.146484C0.341797 -0.0488281 0.658203 -0.0488281 0.853516 0.146484L6 5.29297L11.1465 0.146484C11.3418 -0.048828 11.6582 -0.048828 11.8535 0.146484C12.0488 0.341797 12.0488 0.658203 11.8535 0.853516L6.70703 6L11.8535 11.1465Z"
                :fill="color"></path>
        </svg>

        <svg
            v-if="icon === 'percent'"
            width="512px"
            height="512px"
            viewBox="-32 0 512 512"
            xmlns="http://www.w3.org/2000/svg">
            <path 
                d="M112 224c61.9 0 112-50.1 112-112S173.9 0 112 0 0 50.1 0 112s50.1 112 112 112zm0-160c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zm224 224c-61.9 0-112 50.1-112 112s50.1 112 112 112 112-50.1 112-112-50.1-112-112-112zm0 160c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zM392.3.2l31.6-.1c19.4-.1 30.9 21.8 19.7 37.8L77.4 501.6a23.95 23.95 0 0 1-19.6 10.2l-33.4.1c-19.5 0-30.9-21.9-19.7-37.8l368-463.7C377.2 4 384.5.2 392.3.2z"
                :fill="color">
            </path>
        </svg>

        <svg
            v-if="icon === 'arrow'"
            width="10"
            height="6"
            viewBox="0 0 10 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.87174 0.834816L5.37159 5.83448C5.36567 5.84107 5.35719 5.84315 5.35096 5.84938C5.34425 5.85609 5.34162 5.86518 5.33448 5.87159C5.30726 5.896 5.27485 5.909 5.24433 5.92646C5.2248 5.93763 5.20801 5.95276 5.18745 5.96106C5.12702 5.98566 5.06384 6 5 6C4.93616 6 4.87298 5.98566 4.81255 5.96106C4.79199 5.95276 4.7752 5.93763 4.75567 5.92646C4.72515 5.909 4.69274 5.896 4.66552 5.87159C4.65838 5.86518 4.65575 5.85609 4.64904 5.84938C4.64281 5.84315 4.63433 5.84107 4.62841 5.83448L0.128261 0.834816C-0.056315 0.629751 -0.039713 0.313366 0.165372 0.128808C0.370457 -0.0567268 0.685896 -0.039638 0.871449 0.165915L5 4.75277L9.12855 0.165914C9.31313 -0.03915 9.63003 -0.0557505 9.83463 0.128808C10.0397 0.313366 10.0563 0.629751 9.87174 0.834816Z"
                :fill="color"></path>
        </svg>

        <svg
            v-if="icon === 'square-edit'"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24">
            <path
                d="M5,3C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V12H19V19H5V5H12V3H5M17.78,4C17.61,4 17.43,4.07 17.3,4.2L16.08,5.41L18.58,7.91L19.8,6.7C20.06,6.44 20.06,6 19.8,5.75L18.25,4.2C18.12,4.07 17.95,4 17.78,4M15.37,6.12L8,13.5V16H10.5L17.87,8.62L15.37,6.12Z"
                :fill="color">
            </path>
        </svg>
        <svg
            v-if="icon === 'code'"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24">
            <path
                d="M12.89,3L14.85,3.4L11.11,21L9.15,20.6L12.89,3M19.59,12L16,8.41V5.58L22.42,12L16,18.41V15.58L19.59,12M1.58,12L8,5.58V8.41L4.41,12L8,15.58V18.41L1.58,12Z"
                :fill="color">
            </path>
        </svg>
        <svg
            v-if="icon === 'hubspot'"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24">
            <path
                d="M17.1,8.6V6.2C17.7,5.9 18.2,5.3 18.2,4.6V4.5C18.2,3.5 17.4,2.7 16.4,2.7H16.3C15.3,2.7 14.5,3.5 14.5,4.5V4.6C14.5,5.3 14.9,5.9 15.6,6.2V8.6C14.7,8.7 13.8,9.1 13.1,9.7L6.5,4.6C6.8,3.5 6.1,2.3 5,2.1C3.9,1.9 2.8,2.4 2.5,3.5C2.2,4.6 2.9,5.8 4,6.1C4.5,6.2 5.1,6.2 5.6,5.9L12,10.9C10.8,12.7 10.8,15 12.1,16.8L10.1,18.8C9.9,18.8 9.8,18.7 9.6,18.7C8.7,18.7 7.9,19.5 7.9,20.4C7.9,21.3 8.7,22 9.6,22C10.5,22 11.3,21.2 11.3,20.3V20.3C11.3,20.1 11.3,20 11.2,19.8L13.1,17.9C15.4,19.6 18.7,19.2 20.4,16.9C22.1,14.6 21.7,11.3 19.4,9.6C18.8,9.1 18,8.7 17.1,8.6M16.3,16.4C14.8,16.4 13.6,15.2 13.6,13.7C13.6,12.2 14.8,11 16.3,11C17.8,11 19,12.2 19,13.7C19,15.2 17.8,16.4 16.3,16.4"
                :fill="color">
            </path>
        </svg>
        <svg
            v-if="icon === 'account-plus'"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24">
            <path
                d="M15,14C12.33,14 7,15.33 7,18V20H23V18C23,15.33 17.67,14 15,14M6,10V7H4V10H1V12H4V15H6V12H9V10M15,12A4,4 0 0,0 19,8A4,4 0 0,0 15,4A4,4 0 0,0 11,8A4,4 0 0,0 15,12Z"
                :fill="color">
            </path>
        </svg>
        <svg
            v-if="icon === 'hand-holding-usd'"
            width="24px"
            height="24px"
            viewBox="0 -32 576 576"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M271.06,144.3l54.27,14.3a8.59,8.59,0,0,1,6.63,8.1c0,4.6-4.09,8.4-9.12,8.4h-35.6a30,30,0,0,1-11.19-2.2c-5.24-2.2-11.28-1.7-15.3,2l-19,17.5a11.68,11.68,0,0,0-2.25,2.66,11.42,11.42,0,0,0,3.88,15.74,83.77,83.77,0,0,0,34.51,11.5V240c0,8.8,7.83,16,17.37,16h17.37c9.55,0,17.38-7.2,17.38-16V222.4c32.93-3.6,57.84-31,53.5-63-3.15-23-22.46-41.3-46.56-47.7L282.68,97.4a8.59,8.59,0,0,1-6.63-8.1c0-4.6,4.09-8.4,9.12-8.4h35.6A30,30,0,0,1,332,83.1c5.23,2.2,11.28,1.7,15.3-2l19-17.5A11.31,11.31,0,0,0,368.47,61a11.43,11.43,0,0,0-3.84-15.78,83.82,83.82,0,0,0-34.52-11.5V16c0-8.8-7.82-16-17.37-16H295.37C285.82,0,278,7.2,278,16V33.6c-32.89,3.6-57.85,31-53.51,63C227.63,119.6,247,137.9,271.06,144.3ZM565.27,328.1c-11.8-10.7-30.2-10-42.6,0L430.27,402a63.64,63.64,0,0,1-40,14H272a16,16,0,0,1,0-32h78.29c15.9,0,30.71-10.9,33.25-26.6a31.2,31.2,0,0,0,.46-5.46A32,32,0,0,0,352,320H192a117.66,117.66,0,0,0-74.1,26.29L71.4,384H16A16,16,0,0,0,0,400v96a16,16,0,0,0,16,16H372.77a64,64,0,0,0,40-14L564,377a32,32,0,0,0,1.28-48.9Z"
                :fill="color">
            </path>
        </svg>
        <svg
            v-if="icon === 'envelope'"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24">
            <path
                d="M20,8L12,13L4,8V6L12,11L20,6M20,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6C22,4.89 21.1,4 20,4Z"
                :fill="color">
            </path>
        </svg>
        <svg
            v-if="icon === 'lock'"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24">
            <path
                d="M12,17A2,2 0 0,0 14,15C14,13.89 13.1,13 12,13A2,2 0 0,0 10,15A2,2 0 0,0 12,17M18,8A2,2 0 0,1 20,10V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V10C4,8.89 4.9,8 6,8H7V6A5,5 0 0,1 12,1A5,5 0 0,1 17,6V8H18M12,3A3,3 0 0,0 9,6V8H15V6A3,3 0 0,0 12,3Z"
                :fill="color">
            </path>
        </svg>
        <svg
            v-if="icon === 'unlock'"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24">
            <path
                d="M18 1C15.24 1 13 3.24 13 6V8H4C2.9 8 2 8.89 2 10V20C2 21.11 2.9 22 4 22H16C17.11 22 18 21.11 18 20V10C18 8.9 17.11 8 16 8H15V6C15 4.34 16.34 3 18 3C19.66 3 21 4.34 21 6V8H23V6C23 3.24 20.76 1 18 1M10 13C11.1 13 12 13.89 12 15C12 16.11 11.11 17 10 17C8.9 17 8 16.11 8 15C8 13.9 8.9 13 10 13Z"
                :fill="color">
            </path>
        </svg>
        <svg
            v-if="icon === 'clock'"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24">
            <path
                d="M12 20C16.4 20 20 16.4 20 12S16.4 4 12 4 4 7.6 4 12 7.6 20 12 20M12 2C17.5 2 22 6.5 22 12S17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2M17 13.9L16.3 15.2L11 12.3V7H12.5V11.4L17 13.9Z"
                :fill="color">
            </path>
        </svg>
        <svg
            v-if="icon === 'check-circle'"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24">
            <path
                d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
                :fill="color">
            </path>
        </svg>
        <svg
            v-if="icon === 'times-circle'"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24">
            <path
                d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2C6.47,2 2,6.47 2,12C2,17.53 6.47,22 12,22C17.53,22 22,17.53 22,12C22,6.47 17.53,2 12,2M14.59,8L12,10.59L9.41,8L8,9.41L10.59,12L8,14.59L9.41,16L12,13.41L14.59,16L16,14.59L13.41,12L16,9.41L14.59,8Z"
                :fill="color">
            </path>
        </svg>
        <svg
            v-if="icon === 'plus'"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24">
            <path
                d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"
                :fill="color">
            </path>
        </svg>
        <svg
            v-if="icon === 'history'"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24">
            <path
                d="M13.5,8H12V13L16.28,15.54L17,14.33L13.5,12.25V8M13,3A9,9 0 0,0 4,12H1L4.96,16.03L9,12H6A7,7 0 0,1 13,5A7,7 0 0,1 20,12A7,7 0 0,1 13,19C11.07,19 9.32,18.21 8.06,16.94L6.64,18.36C8.27,20 10.5,21 13,21A9,9 0 0,0 22,12A9,9 0 0,0 13,3"
                :fill="color">
            </path>
        </svg>
        <svg
            v-if="icon === 'trash-alt'"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24">
            <path
                d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M9,8H11V17H9V8M13,8H15V17H13V8Z"
                :fill="color">
            </path>
        </svg>
        <svg
            v-if="icon === 'play'"
            width="24px"
            height="25px"
            viewBox="0 0 512 512"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="m109.472317,458.764368a44.434829,39.555983 0 0 1 -21.985001,-5.222687c-15.07543,-7.604769 -24.447322,-22.366968 -24.447322,-38.3929l0,-324.321034c0,-16.070666 9.371892,-30.788131 24.447322,-38.3929a44.13332,39.287579 0 0 1 44.937343,0.503257l311.370434,165.918168a45.226289,40.260542 0 0 1 0,68.219252l-311.420686,165.962902a44.598146,39.701368 0 0 1 -22.90209,5.725944z"
                :fill="color">
            </path>
        </svg>
        <svg
            v-if="icon === 'times-think'"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24">
            <path
                d="M20 6.91L17.09 4L12 9.09L6.91 4L4 6.91L9.09 12L4 17.09L6.91 20L12 14.91L17.09 20L20 17.09L14.91 12L20 6.91Z"
                :fill="color">
            </path>
        </svg>
        <svg
            v-if="icon === 'stop'"
            xmlns="http://www.w3.org/2000/svg"
            width="24px"
            height="24px"
            viewBox="0 0 46.734 46.734">
            <path
                d="m37.83022,4.31033l-28.776885,0c-2.377666,0 -4.311971,2.00937 -4.311971,4.479308l0,29.893646c0,2.469938 1.934305,4.479308 4.311971,4.479308l28.776885,0c2.377666,0 4.311971,-2.00937 4.311971,-4.479308l0,-29.893646c-0.0008,-2.469938 -1.935105,-4.479308 -4.311971,-4.479308z"
                :fill="color">
            </path>
        </svg>
        <svg
            v-if="icon === 'headset'"
            width="24px"
            height="24px"
            viewBox="0 0 512 512"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M192 208c0-17.67-14.33-32-32-32h-16c-35.35 0-64 28.65-64 64v48c0 35.35 28.65 64 64 64h16c17.67 0 32-14.33 32-32V208zm176 144c35.35 0 64-28.65 64-64v-48c0-35.35-28.65-64-64-64h-16c-17.67 0-32 14.33-32 32v112c0 17.67 14.33 32 32 32h16zM256 0C113.18 0 4.58 118.83 0 256v16c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 16-16v-16c0-114.69 93.31-208 208-208s208 93.31 208 208h-.12c.08 2.43.12 165.72.12 165.72 0 23.35-18.93 42.28-42.28 42.28H320c0-26.51-21.49-48-48-48h-32c-26.51 0-48 21.49-48 48s21.49 48 48 48h181.72c49.86 0 90.28-40.42 90.28-90.28V256C507.42 118.83 398.82 0 256 0z"
                :fill="color">
            </path>
        </svg>
        <svg
            v-if="icon === 'user-shield'"
            width="640px"
            height="640px"
            viewBox="0 -64 640 640"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M622.3 271.1l-115.2-45c-4.1-1.6-12.6-3.7-22.2 0l-115.2 45c-10.7 4.2-17.7 14-17.7 24.9 0 111.6 68.7 188.8 132.9 213.9 9.6 3.7 18 1.6 22.2 0C558.4 489.9 640 420.5 640 296c0-10.9-7-20.7-17.7-24.9zM496 462.4V273.3l95.5 37.3c-5.6 87.1-60.9 135.4-95.5 151.8zM224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm96 40c0-2.5.8-4.8 1.1-7.2-2.5-.1-4.9-.8-7.5-.8h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c6.8 0 13.3-1.5 19.2-4-54-42.9-99.2-116.7-99.2-212z"
                :fill="color">
            </path>
        </svg>
    </div>
</template>

<script>
  export default {
    name: "FSvgIcon",
    props: {
      icon: {
        type: String,
        default: null
      },
      color: {
        type: String,
        default: "#FE2615"
      }
    }
  };
</script>

<style lang="scss" scoped>
</style>
