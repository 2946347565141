import Service from "@core/services/common/Service";
import { UserCommentInput, UserCommentInputResponse } from "@core/store/types/admin/users/common/index";
import { AxiosPromise } from "axios";
import balance from "./graphql/balance.graphql";
import myBalance from "@core//services/webmaster/graphql/myBalance.graphql";
import resendEmailByAdmin from "./graphql/resendEmailByAdmin.graphql";
import updateComment from "./graphql/updateComment.graphql";

export class UsersService extends Service {
  /**
   * TODO: Добавить описание
   * @param {string} userId
   * @return {Promise<AxiosPromise>}
   */
  public static async resendEmailByAdmin (
    userId: string
  ): Promise<AxiosPromise> {
    try {
      return await this.api.post("", {
        query: resendEmailByAdmin,
        variables: {
          userId
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  /**
   * TODO: Добавить описание
   * @param {string} userId
   * @param {UserCommentInput} input
   * @return {Promise<AxiosPromise<UserCommentInputResponse>>}
   */
  public static async updateComment (
    userId: string,
    input: UserCommentInput
  ): Promise<AxiosPromise<UserCommentInputResponse>> {
    try {
      return await this.api.post("", {
        query: updateComment,
        variables: {
          userId,
          input
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  /**
   * TODO: Добавить описание
   * @param {string} userId
   * @return {Promise<AxiosPromise>}
   */
  public static async balance (
    userId: string
  ): Promise<AxiosPromise> {
    try {
      return await this.api.post("", {
        query: balance,
        variables: {
          userId
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static async myBalance (): Promise<AxiosPromise> {
    try {
      return await this.api.post("", {
        query: myBalance
      });
    } catch (e) {
      throw e;
    }
  }
}
