import { callCenters } from "@core/store/modules/admin/callCenters";
import { crm } from "@core/store/modules/admin/crm/crm";
import { dashboard } from "@core/store/modules/admin/dashboard";
import { detail } from "@core/store/modules/admin/detailing";
import { dictionaries } from "@core/store/modules/admin/dictionaries";
import { domains } from "@core/store/modules/admin/domains";
import { efficiency } from "@core/store/modules/admin/efficiency/efficiency";
import { efficiencyPlans } from "@core/store/modules/admin/efficiency/efficiencyPlans";
import { efficiencyPlansEdit } from "@core/store/modules/admin/efficiency/efficiencyPlansEdit";
import { efficiencyPlansHistory } from "@core/store/modules/admin/efficiency/efficiencyPlansHistory";
import { finance } from "@core/store/modules/admin/finance";
import { finances } from "@core/store/modules/admin/finances/finances";
import { countries } from "@core/store/modules/common/countries/countries";
import { regions } from "@core/store/modules/admin/common/regions";
import { integration } from "@core/store/modules/admin/integrations/integration";
import { integrations } from "@core/store/modules/admin/integrations/integrations";
import { offerOld } from "@core/store/modules/admin/offer/offerOld";
import { offers } from "@core/store/modules/admin/offers";
import { payments } from "@core/store/modules/admin/payments/payments";
import { profile } from "@core/store/modules/admin/profile";
import { roles } from "@core/store/modules/admin/roles";
import { statistic } from "@core/store/modules/admin/statistic";
import { analytic } from "@core/store/modules/admin/analytic";
import { statistics } from "@core/store/modules/admin/statistic/statistics";
import { users } from "@core/store/modules/admin/users";
import { AdminOffer } from "@core/store/modules/admin/offer";
import { audit } from "@core/store/modules/admin/audit";
import { competition } from "@core/store/modules/admin/competition";
import { webmasterSubType } from "@core/store/modules/admin/lists/webmasterSubType";
import { Module } from "@core/store/logic/Module";

export class CoreAdmin<S, R> extends Module<S, R> {
  constructor () {
    super();
  
    this.namespaced = true;
    
    this.modules({
      dashboard,
      profile,
      dictionaries,
      users,
      finances,
      finance,
      integrations,
      integration,
      offerOld,
      offers,
      offer: AdminOffer,
      payments,
      statistic,
      detail,
      callCenters,
      roles,
      domains,
      efficiency,
      efficiencyPlans,
      efficiencyPlansHistory,
      efficiencyPlansEdit,
      crm,
      statistics,
      analytic,
      countries,
      regions,
      audit,
      competition,
      webmasterSubType
    });
  }
}
