<template>
    <el-button
        v-bind="$attrs"
        class="button w-full"
        :class="{'flex justify-center items-center h-10': noMore}"
        :type="type"
        :loading="loading"
        :plain="plain"
        :native-type="nativeType"
        :round="round"
        v-on="$listeners">
        <slot>
            <span
                v-if="title"
                class="flex items-center"
                :class="{ 'justify-center': !$slots['icon'] }">{{ title }}</span>
        </slot>
        <slot
            v-if="!noMore"
            name="icon">
            <i class="icon el-icon-more"></i>
        </slot>
    </el-button>
</template>

<script>
  export default {
    name: "FButton",
    props: {
      title: {
        type: String,
        default: ""
      },
      plain: {
        type: Boolean,
        default: false
      },
      type: {
        type: String,
        default: "danger"
      },
      nativeType: {
        type: String,
        default: "button"
      },
      noMore: {
        type: Boolean,
        default: false
      },
      loading: {
        type: Boolean,
        default: false
      },
      round: {
        type: Boolean,
        default: false
      }
    }
  };
</script>

<style lang="scss" scoped>
    .el-button.is-disabled.is-plain {
        background-color: #FFF !important;
        border-color: #EBEEF5 !important;
        color: #C0C4CC !important;
    }

    .el-button {
        transition: .25s;
        box-shadow: 0 10px 20px rgba(22, 34, 57, 0.05);
        @apply py-1 px-4;

        &.is-plain {
            @apply text-black;
        }

        /*@media screen and (max-width: 768px) {*/
        /*    width: 100%;*/
        /*}*/

        ::v-deep {
            span {
                @apply flex items-center justify-between;
            }
        }

        .icon {
            transition: .25s;
            margin-right: 0 !important;
            margin-left: 0 !important;
            //margin-left: 1em;

            @media screen and (max-width: 768px) {
                margin-left: auto;
            }
        }

        $types: (
                primary: #5921A0,
                success: #1BD12D,
                info: #9AA3B4,
                warning: #E2C21A,
                danger: #FE2615
        );

        &.is-plain {
            background: #FFFFFF;

            @each $type, $color in $types {
                &.el-button--#{$type} {
                    border-color: $color;

                    .icon {
                        color: $color;
                    }
                }
            }

            // отменяет дефолтное наведение библиотеки
            &:hover {
                color: #000000;
            }

            @media screen and (min-width: 1080px) {
                &:hover {
                    border-color: #DEE3EC;
                    box-shadow: 0 5px 5px rgba(22, 34, 57, 0.08);

                    .icon {
                        color: #DEE3EC;
                    }
                }
            }


            &:focus {
                color: #000000;
                border-color: #DEE3EC;
                outline: none;

                .icon {
                    color: #000000;
                }
            }
        }
    }
</style>

<style lang="scss">
    .el-button+.el-button {
        margin-left: 0;
    }
</style>
