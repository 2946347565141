import { ProfileService } from "@core/services/webmaster/ProfileService";
import { ADD_REQUISITE } from "@core/store/action-constants";
import { SET_EMPTY, UPDATE_MODAL, UPDATE_WEBMASTER_REQUISITES_ADD_FORM } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import {
  WebmasterProfileRequisitesAddInput,
  WebmasterProfileRequisitesAddState
} from "@core/store/types/webmaster/profile/modules/requisites/modules/add";
import { ActionTree, Module, MutationTree } from "vuex";

import { paymentSystemsList } from "@core/store/modules/common/lists/paymentSystemsList";

const initialState = (): WebmasterProfileRequisitesAddState => {
  return {
    modal: {
      isActive: false
    },
    form: {}
  };
};

const state: () => WebmasterProfileRequisitesAddState = initialState;

const mutations: MutationTree<WebmasterProfileRequisitesAddState> = {
  [SET_EMPTY]: state => Object.assign(state, initialState()),
  
  [UPDATE_WEBMASTER_REQUISITES_ADD_FORM] (state, form) {
    state.form = { ...state.form, ...form };
  },
  
  [UPDATE_MODAL] (state, modal) {
    state.modal = { ...state.modal, ...modal };
  }
};

const actions: ActionTree<WebmasterProfileRequisitesAddState, RootState> = {
  [ADD_REQUISITE] ({ state: { form } }) {
    return ProfileService.addRequisite(form as WebmasterProfileRequisitesAddInput);
  },

  [UPDATE_MODAL] ({ commit }, modal) {
    commit(UPDATE_MODAL, modal);
  },

  [UPDATE_WEBMASTER_REQUISITES_ADD_FORM] ({ commit }, form) {
    commit(UPDATE_WEBMASTER_REQUISITES_ADD_FORM, form);
  }
};

export const add: Module<WebmasterProfileRequisitesAddState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  modules: {
    paymentSystemsList
  }
};
