import { FinancesService } from "@core/services/webmaster/finances/FinancesService";
import { GET_FINANCES } from "@core/store/action-constants";
import { SET_EMPTY, SET_FINANCES, SET_STATUS, UPDATE_FILTERS, UPDATE_PAGINATION } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { FinancesState } from "@core/store/types/webmaster/finances";
import Axios from "axios";
import { ActionTree, Module, MutationTree } from "vuex";
import { paymentSystemsList } from "@core/store/modules/common/lists/paymentSystemsList";
import { currencyList } from "@core/store/modules/common/lists/currencyList";

const initialState = (): FinancesState => {
  return {
    data: null,
    filters: {},
    pagination: {
      perPage: 25,
      page: 1
    }
  };
};

const state: () => FinancesState = initialState;

const mutations: MutationTree<FinancesState> = {
  [SET_FINANCES]: (state, data: FinancesState["data"]): void => {
    state.data = data;
  },
  
  [UPDATE_FILTERS]: (state, filters): void => {
    state.filters = { ...state.filters, ...filters };
  },
  
  [UPDATE_PAGINATION]: (state, pagination): void => {
    state.pagination = { ...state.pagination, ...pagination };
  },
  
  [SET_EMPTY]: (state): void => {
    Object.assign(state, initialState());
  }
};

const actions: ActionTree<FinancesState, RootState> = {
  async [GET_FINANCES] ({ state, commit }) {
    try {
      const limit = state.pagination.perPage;
      const offset = (state.pagination.page - 1) * limit;
      const { datepicker, ...filters } = state.filters;
      
      const { data: { webmasterTransactions } } = await FinancesService.getFinances(
        limit,
        offset,
        {
          ...datepicker,
          ...filters
        }
      );
      
      commit(SET_FINANCES, webmasterTransactions);
    } catch (error) {
      throw error;
    }
  },
  
  async GET_TRANSACTIONS_STATUS ({ commit }) {
    const response = await Axios.get("/admin/transactions/statuses");
    commit(SET_STATUS, response.data);
  },
  
  async ORDER_PAYMENT (_, payload) {
    try {
      await FinancesService.addFinances(payload);
    } catch (error) {
      throw error;
    }
  },
  
  [UPDATE_PAGINATION] ({ commit }, pagination) {
    commit(UPDATE_PAGINATION, pagination);
  },
  
  [UPDATE_FILTERS] ({ commit, dispatch }, filters) {
    commit(UPDATE_FILTERS, filters);
    commit(UPDATE_PAGINATION, { page: 1 });
    
    dispatch(GET_FINANCES);
  }
};

export const finances: Module<FinancesState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    paymentSystemsList,
    currencyList
  }
};
