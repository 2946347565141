import { OfferModule } from "@core/store/modules/common/offer/OfferModule";
import { WebmasterOffer } from "@core/store/logic/webmaster/offer/WebmasterOffer";
import { InputPbCreate, InputPbEdit, ModifedPostback } from "@core/store/logic/webmaster/postbacks/postback";
import {
  offerRatesWebmasters
} from "@core/store/modules/webmaster/offer/offerRates/offerRatesWebmasters";
import {
  ADD_OFFER_POSTBACK,
  SET_CALLCENTERS,
  SET_OFFER_POSTBACKS,
  UPDATE_OFFER_POSTBACK,
  UPDATE_OFFER_POSTBACK_STATUSES,
  UPDATE_OFFER_POSTBACK_URL,
  UPDATE_OFFER_POSTBACK_DUPLICATES
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import {
  GET_CALLCENTERS, GET_OFFER_ID,
  GET_OFFER_POSTBACKS,
  JOIN_TO_OFFER,
  REMOVE_FLOW, REMOVE_OFFER_POSTBACK, SAVE_OFFER_POSTBACKS,
  UPDATE_OFFER,
  EXCLUDE_OFFER, SET_EMPTY
} from "@core/store/action-constants";
import { OfferService } from "@core/services/webmaster/offer/OfferService";
import Axios from "axios";
import { PostbackService } from "@core/logic/webmaster/postbacks/postback";
import CallCentersService, { GetCallCentersParams } from "@core/logic/webmaster/callCenters/callCenters";
import { PostbacksService } from "@core/services/webmaster/postbacks/PostbacksService";
import { CreateInput, EditInput } from "@core/store/types/webmaster/postbacks/postback";
import { OfferLandings } from "@core/store/modules/webmaster/offers/modules/offerLandings";
import { flowsEditLinkModal } from "@core/store/modules/webmaster/flows/modules/flowsEditLinkModal";
import { offerEditLinkModal } from "@core/store/modules/webmaster/offer/modules/offerEditLinkModal";

export interface OfferState {
  offer: WebmasterOffer | null;
  trafficTypes: any | null;
  callCenters: any | null;
  postbacks: Array<ModifedPostback>;
}

export class WebmasterOfferModule extends OfferModule<OfferState, RootState> {
  private readonly initState: () => OfferState;

  constructor () {
    super();

    this.namespaced = true;

    this.modules({
      landings: new OfferLandings("landing"),
      transits: new OfferLandings("transit"),
      flowsEditLinkModal,
      rates: offerRatesWebmasters,
      offerEditLinkModal
    });

    this.initState = (): OfferState => {
      return {
        offer: null,
        trafficTypes: null,
        callCenters: null,
        // Один постбек, чтобы сразу редактировать, если у оффера их нет
        postbacks: []
      };
    };
    this.state(this.initState());

    this.actions({
      SET_EMPTY ({ commit }) {
        commit(`rates/${ SET_EMPTY }`);
        commit(SET_EMPTY);
      },
      async [JOIN_TO_OFFER] ({ commit }, id) {
        try {
          const { data: { webmasterJoinToOffer: data } } = await OfferService.webmasterJoinToOffer(id);

          commit(UPDATE_OFFER, data);
        } catch (e) {
          throw e;
        }
      },

      async [EXCLUDE_OFFER] ({ commit }, id) {
        try {
          const { data: { excludeWebmasterFromOffer: data } } = await OfferService.excludeWebmasterFromOffer(id);

          commit(UPDATE_OFFER, data);
        } catch (e) {
          throw e;
        }
      },

      async [REMOVE_FLOW] (_, id) {
        await OfferService.deleteFlow(id);
      },

      async ENABLE_OFFER_POSTBACK ({ commit, state }, id: string) {
        await PostbackService.enableOfferPostback(id);
        const data = { ...state.offer, isPostbackEnable: true };
        commit("SET_OFFER", data);

        return data;
      },

      async DISABLE_OFFER_POSTBACK ({ commit, state }, id: string) {
        await PostbackService.disableOfferPostback(id);
        const data = { ...state.offer, isPostbackEnable: false };
        commit("SET_OFFER", data);

        return data;
      },

      async [GET_CALLCENTERS] ({ commit }, params: GetCallCentersParams) {
        try {
          const { data } = await CallCentersService.getCallCenters(params);

          commit(SET_CALLCENTERS, data);
        } catch (error) {
          throw error;
        }
      },

      async [GET_OFFER_POSTBACKS] ({ commit, getters }) {
        const id = getters[GET_OFFER_ID];
        const { data: { postbackUrlList } } = await PostbacksService.getPostbacks(id);

        const postbacks = postbackUrlList.map((pb) => new ModifedPostback(pb));

        commit(SET_OFFER_POSTBACKS, postbacks);
      },

      async [REMOVE_OFFER_POSTBACK] ({ dispatch, commit, state }, index) {
        const pb = state.postbacks[index];

        if (pb.id) {
          await PostbacksService.removePostback(pb.id as string);
          dispatch(GET_OFFER_POSTBACKS);
        } else {
          const filteredPostbacks = state.postbacks.filter((_, i) => i !== index);
          commit(SET_OFFER_POSTBACKS, filteredPostbacks);
        }
      },

      async [SAVE_OFFER_POSTBACKS] ({ dispatch, state }) {
        // Делим постбеки на новые и редактируемые
        const { create, edit } = state.postbacks.reduce<{ create: CreateInput; edit: EditInput }>(
          (acc, pb) => {
            if (pb.offerId) {
              acc.edit.push(new InputPbEdit(pb));
            } else {
              acc.create.push(new InputPbCreate(pb, state.offer?.id as string));
            }
            return acc;
          },
          { create: [], edit: [] }
        );

        if (edit.length) {
          await PostbacksService.editPostbacks(edit);
        }

        if (create.length) {
          await PostbacksService.createPostbacks(create);
        }

        dispatch(GET_OFFER_POSTBACKS);
      },

      [UPDATE_OFFER_POSTBACK_URL] ({ commit }, payload) {
        commit(UPDATE_OFFER_POSTBACK_URL, payload);
      },

      [UPDATE_OFFER_POSTBACK_STATUSES] ({ commit }, payload) {
        commit(UPDATE_OFFER_POSTBACK_STATUSES, payload);
      },

      [UPDATE_OFFER_POSTBACK_DUPLICATES] ({ commit }, payload) {
        commit(UPDATE_OFFER_POSTBACK_DUPLICATES, payload);
      }
    });

    this.mutations({
      SET_OFFER_TRAFFIC_TYPES: (state, trafficTypes): void => {
        state.trafficTypes = trafficTypes;
      },

      SET_EMPTY: (state): void => {
        Object.assign(state, this.initState());
      },

      [SET_CALLCENTERS]: (state, callCenters): void => {
        state.callCenters = callCenters;
      },

      [SET_OFFER_POSTBACKS]: (state, postbacks) => {
        state.postbacks = postbacks;
      },

      [ADD_OFFER_POSTBACK]: (state) => {
        state.postbacks = [...state.postbacks, new ModifedPostback()];
      },

      [UPDATE_OFFER]: (state, data) => {
        if (state.offer){
          state.offer = { ...state.offer, ...data };
        }
      },

      [UPDATE_OFFER_POSTBACK]: (state, { index, postback }) => {
        state.postbacks[index] = { ...state.postbacks[index], ...postback };
      },

      [UPDATE_OFFER_POSTBACK_URL] (state, { value, index }): void {
        state.postbacks[index].url = value;
      },

      [UPDATE_OFFER_POSTBACK_STATUSES] (state, { statusIndex, postbackIndex }): void {
        state.postbacks[postbackIndex].statuses[statusIndex].isActive = !state.postbacks[postbackIndex].statuses[statusIndex].isActive;
      },

      [UPDATE_OFFER_POSTBACK_DUPLICATES] (state, index): void {
        state.postbacks[index].isSendDuplicates = !state.postbacks[index].isSendDuplicates;
      }
    });

    this.getters({
      [GET_OFFER_ID]: (state) => {
        return state.offer?.id;
      }
    });

    return this;
  }
}

