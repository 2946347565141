import UsersServiceREST from "@core/logic/admin/users/users";
import { UsersService } from "@core/services/admin/users/common/UsersService";
import { WebmastersService } from "@core/services/admin/users/webmasters/WebmastersService";
import {
  ADD_APPROVED_WEBMASTER,
  ADD_WEBMASTER_PERSONAL_MANAGER,
  BLOCK_WEBMASTER,
  DELETE_WEBMASTER_PERSONAL_MANAGER,
  DISABLE_WEBMASTER_PARTNER_NETWORK,
  DISABLE_WEBMASTER_PUBLIC_API,
  EDIT_WEBMASTER_ADMIN_COMMENT,
  ENABLE_WEBMASTER_PARTNER_NETWORK,
  ENABLE_WEBMASTER_PUBLIC_API,
  GET_HISTORY_WEBMASTER,
  GET_WEBMASTERS,
  GET_WEBMASTERS_WITH_BALANCE,
  RESEND_EMAIL_BY_ADMIN,
  UNBLOCK_WEBMASTER,
  UPDATE_WEBMASTERS_BALANCE,
  WEBMASTER_BALANCE
} from "@core/store/action-constants";
import { adminsList } from "@core/store/modules/admin/lists/adminsList";
import { approvedList } from "@core/store/modules/admin/lists/ApprovedList";
import { blockedList } from "@core/store/modules/admin/lists/BlockedList";
import { emailConfirmedList } from "@core/store/modules/admin/lists/EmailConfirmedList";
import { partnerNetworkList } from "@core/store/modules/admin/lists/PartnerNetworkList";
import { sortByPreferredCurrency } from "@core/helpers/sortByPreferredCurrency";
import {
  CLEAR_FILTERS,
  EMPTY_WEBMASTER_EDITABLE_COMMENT,
  SET_EMPTY,
  SET_WEBMASTER_BALANCE,
  SET_WEBMASTER_HISTORY,
  SET_WEBMASTERS,
  UPDATE_WEBMASTER_EDITABLE_COMMENT,
  UPDATE_WEBMASTERS_FILTERS,
  UPDATE_WEBMASTERS_PAGINATION,
  UPDATE_WEBMASTERS_SORTINGS
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { UserCommentInput } from "@core/store/types/admin/users/common";
import {
  PaginationWebmastersState,
  WebmastersItem,
  WebmastersState
} from "@core/store/types/admin/users/webmasters/WebmastersState";
import Vue from "vue";
import { ActionTree, Module, MutationTree } from "vuex";
import { detail } from "./modules/detail";
import moment from "moment";
import { edit } from "./modules/edit";
import { agentModal } from "./modules/webmasterAgentAccessModal";
import { subAccountsModal } from "./modules/webmasterSubAccountsModal";
import { checkModal } from "./modules/webmasterCheckModal";
import { Money } from "@core/store/types/common";

const initialEditableComment = (): PaginationWebmastersState["editableComment"] => ({
  userId: null,
  comment: null
});

const initialState = (): PaginationWebmastersState => {
  return {
    webmasters: null,
    filters: {
      isBlocked: false,
      datepicker: {
        dateStart: null,
        dateEnd: moment().endOf("day").toDate()
      }
    },
    pagination: {
      page: 1,
      perPage: 25
    },
    sort: null,
    order: null,
    historyWebmasters: null,
    isWebmastersFindModalActive: false,
    editableComment: initialEditableComment()
  };
};

const state: () => PaginationWebmastersState = initialState;

const mutations: MutationTree<PaginationWebmastersState> = {
  [SET_WEBMASTERS]: (state, webmasters: WebmastersState["webmasters"]): void => {
    state.webmasters = webmasters;
  },

  [SET_WEBMASTER_HISTORY]: (state, history: PaginationWebmastersState["historyWebmasters"]): void => {
    state.historyWebmasters = history;
  },

  [SET_WEBMASTER_BALANCE]: (state, { payload, webmasterId }): void => {
    if (state.webmasters && state.webmasters.items) {
      const webmasterIndex = state.webmasters.items.findIndex(webmaster => webmaster.id === webmasterId);
      const balance = payload.map((item: any) => ({ ...item.balance }));
      const sortingBalance: any = sortByPreferredCurrency(balance);
      const endingBalance = sortingBalance.map((item: Money, idx: number) => ({ balance: { ...item }, updatedAt: payload[idx].updatedAt }));
      Vue.set(state.webmasters.items, webmasterIndex, { ...state.webmasters?.items[webmasterIndex], balance: endingBalance });
    }
  },

  [UPDATE_WEBMASTERS_FILTERS] (state, filter: any): void {
    state.filters = { ...state.filters, ...filter };
  },

  [UPDATE_WEBMASTERS_PAGINATION] (state, pagination): void {
    for (const [key, value] of Object.entries(pagination)) {
      Vue.set(state.pagination, key, value);
    }
  },
  
  [UPDATE_WEBMASTERS_SORTINGS] (state, {
    sort,
    order
  }): void {
    state.sort = sort;
    state.order = order.toUpperCase();
  },
  
  [UPDATE_WEBMASTER_EDITABLE_COMMENT] (state, editableComment: PaginationWebmastersState["editableComment"]): void {
    state.editableComment = { ...state.editableComment, ...editableComment };
  },
  
  [EMPTY_WEBMASTER_EDITABLE_COMMENT] (state): void {
    state.editableComment = initialEditableComment();
  },
  
  [SET_EMPTY] (state) {
    Object.assign(state, initialState());
  },

  [CLEAR_FILTERS]: (state): void => {
    state.filters = initialState().filters;
  }
};

const actions: ActionTree<PaginationWebmastersState, RootState> = {
  async [GET_WEBMASTERS] ({ state, commit }) {
    const limit = state.pagination.perPage;
    const offset = (state.pagination.page - 1) * limit;

    let {
      loginOrIntId,
      email,
      skype,
      telegram,
      datepicker,
      ...filters
    } = state.filters;

    const dateFilter = {
      registeredAtStart: datepicker.dateStart,
      registeredAtEnd: datepicker.dateEnd
    };

    loginOrIntId = loginOrIntId ? loginOrIntId : null;
    email = email ? email : null;
    skype = skype ? skype : null;
    telegram = telegram ? telegram : null;

    try {
      const { data: { webmasters } } = await WebmastersService.getWebmasters(
        limit,
        offset,
        {
          loginOrIntId,
          email,
          skype,
          telegram,
          ...dateFilter,
          ...filters
        },
        state.sort,
        state.order
      );

      commit(SET_WEBMASTERS, webmasters);
    } catch (error) {
      throw error;
    }
  },

  async [GET_WEBMASTERS_WITH_BALANCE] ({ dispatch }) {
    await dispatch(GET_WEBMASTERS);
    dispatch(UPDATE_WEBMASTERS_BALANCE);
  },

  [UPDATE_WEBMASTERS_BALANCE] ({ state, dispatch }) {
    state.webmasters?.items?.forEach((item: WebmastersItem) => {
      dispatch(WEBMASTER_BALANCE, item.id);
    });
  },

  async [ADD_APPROVED_WEBMASTER] (_, webmasterId: string) {
    try {
      await WebmastersService.approveWebmaster(webmasterId);
    } catch (error) {
      throw error;
    }
  },

  async [ADD_WEBMASTER_PERSONAL_MANAGER] (_, { userId, adminIds }) {
    try {
      await WebmastersService.bindWebmaster(userId, { adminIds });
    } catch (error) {
      throw error;
    }
  },

  async [DELETE_WEBMASTER_PERSONAL_MANAGER] (_, userId: string) {
    try {
      await WebmastersService.bindWebmaster(userId, { adminIds: [] });
    } catch (error) {
      throw error;
    }
  },
  
  async [BLOCK_WEBMASTER] (_, webmasterId: string) {
    try {
      await WebmastersService.blockWebmaster(webmasterId);
    } catch (error) {
      throw error;
    }
  },

  async [UNBLOCK_WEBMASTER] (_, webmasterId: string) {
    try {
      await WebmastersService.unblockWebmaster(webmasterId);
    } catch (error) {
      throw error;
    }
  },
  
  async [ENABLE_WEBMASTER_PARTNER_NETWORK] (_, webmasterId: string) {
    try {
      await WebmastersService.enablePartnerNetworkForWebmaster(webmasterId);
    } catch (error) {
      throw error;
    }
  },
  
  async [DISABLE_WEBMASTER_PARTNER_NETWORK] (_, webmasterId: string) {
    try {
      await WebmastersService.disablePartnerNetworkForWebmaster(webmasterId);
    } catch (error) {
      throw error;
    }
  },
  
  async [ENABLE_WEBMASTER_PUBLIC_API] (_, webmasterId: string) {
    try {
      await WebmastersService.enableAccessToApiForWebmaster(webmasterId);
    } catch (error) {
      throw error;
    }
  },
  
  async [DISABLE_WEBMASTER_PUBLIC_API] (_, webmasterId: string) {
    try {
      await WebmastersService.disableAccessToApiForWebmaster(webmasterId);
    } catch (error) {
      throw error;
    }
  },

  async [GET_HISTORY_WEBMASTER] ({ commit }, webmasterId: string) {
    try {
      const { data } = await UsersServiceREST.getHistoryWebmaster(webmasterId);
      
      commit(SET_WEBMASTER_HISTORY, data);
    } catch (error) {
      throw error;
    }
  },
  
  async [WEBMASTER_BALANCE] ({ commit }, webmasterId: string) {
    try {
      const { data: { balance } } = await UsersService.balance(webmasterId);
      commit("SET_WEBMASTER_BALANCE", { payload: balance, webmasterId });
    } catch (error) {
      throw error;
    }
  },

  async [RESEND_EMAIL_BY_ADMIN] (_, userId: string) {
    await UsersService.resendEmailByAdmin(userId);
  },

  [UPDATE_WEBMASTERS_FILTERS] ({ commit }, filter) {
    commit(UPDATE_WEBMASTERS_FILTERS, filter);
    commit(UPDATE_WEBMASTERS_PAGINATION, { page: 1 });
  },

  [UPDATE_WEBMASTERS_PAGINATION] ({ commit }, pagination) {
    commit(UPDATE_WEBMASTERS_PAGINATION, pagination);
  },

  async [EDIT_WEBMASTER_ADMIN_COMMENT] ({ state }) {
    if (state.editableComment) {
      const { editableComment: { userId, comment } } = state;
      try {
        const input = { comment } as UserCommentInput;
        await UsersService.updateComment(userId as string, input);
      } catch (err) {
        throw err;
      }
    }
  },
  
  [UPDATE_WEBMASTER_EDITABLE_COMMENT] ({ commit }, editableComment) {
    commit(UPDATE_WEBMASTER_EDITABLE_COMMENT, editableComment);
  },
  
  [SET_EMPTY] ({ commit }) {
    commit(SET_EMPTY);
  },

  [CLEAR_FILTERS] ({ commit }) {
    commit(CLEAR_FILTERS);
    commit(UPDATE_WEBMASTERS_PAGINATION, { page: 1 });
  }
};

export const webmasters: Module<PaginationWebmastersState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    edit,
    detail,
    // importOrdersByApiModal,
    approvedList,
    emailConfirmedList,
    partnerNetworkList,
    blockedList,
    adminsList,
    agentModal,
    subAccountsModal,
    checkModal
  }
};
