import { Filters } from "../generators/filters";
import { BaseFiltersInput, FilterInput } from "../../../types/common/statistic/generic/statisticFilters";
import { CategoriesListService } from "../../../../services/common/lists/CategoriesListService";
import { CategoryItem } from "@core/store/types/common/CategoriesFilters";

class CategoriesFilters extends Filters<CategoryItem, BaseFiltersInput> {
  getData = async () => {
    const { data } = await CategoriesListService.getCategoriesList();
    
    return {
      data: {
        items: data.items,
        count: data.count
      }
    };
  }
  
  getInitialFilters = (ids?: Array<string> | string): FilterInput | undefined => {
    if (ids) {
      const categoryId = Array.isArray(ids) ? ids : [ids];
      return { categoryId };
    }
  };
}

export const categoriesFilters = new CategoriesFilters();
