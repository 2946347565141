import { formatEmptyString, moment } from "@core/filters";
import VuetableFieldMixin from "vuetable-2/src/components/VuetableFieldMixin.vue";

// @vue/components
export const StatisticTableView = {
  mixins: [VuetableFieldMixin],
  
  methods: {
    moment,
    
    formatEmptyString,
    
    getMethodByPath (obj, path) {
      path = path.split(".");
      
      for (const level of path) {
        obj = obj && obj[level];
      }
      
      return obj;
    },
    
    formatter (value) {
      if (this.rowField.formatter) {
        return this.rowField.formatter(value);
      } else {
        return value;
      }
    },
    
    onColumnHeaderClicked (field, event) {
      this.vuetable.orderBy(field, event);
    }
  }
};
