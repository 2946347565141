import { AdministratorsEditService } from "@core/services/admin/users/administrators/AdministratorsEditService";
import { CREATE_ADMINISTRATOR, EDIT_ADMINISTRATOR, GET_ADMINISTRATOR } from "@core/store/action-constants";
import {
  SET_ADMINISTRATORS_EDIT_FORM,
  SET_ADMINISTRATORS_EDIT_ID,
  SET_EMPTY,
  SET_IS_ADMINISTRATORS_EDIT_MODAL_ACTIVE,
  UPDATE_ADMINISTRATORS_EDIT_FORM
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { AdministratorsEditState } from "@core/store/types/admin/users/administrators/Edit";
import { User } from "@core/store/types/common/User";
import { ActionTree, Module, MutationTree } from "vuex";
import { password } from "./modules/password";

const initialState = (): AdministratorsEditState => {
  return {
    isModalActive: false,
    administratorId: null,
    form: {
      countries: []
    }
  };
};

const mutations: MutationTree<AdministratorsEditState> = {
  [UPDATE_ADMINISTRATORS_EDIT_FORM] (state, form) {
    state.form = { ...state.form, ...form };
  },

  [SET_ADMINISTRATORS_EDIT_FORM] (state, administrator: User) {
    const {
      timeZone: { alias: timeZone },
      contact: { email, telegram },
      countries,
      ...fields
    } = administrator;
    const newCountries = countries.map(item => item.id);
    
    state.form = {
      countries: newCountries,
      timeZone,
      email,
      telegram,
      ...fields
    };
  },

  [SET_IS_ADMINISTRATORS_EDIT_MODAL_ACTIVE] (state, value: boolean) {
    state.isModalActive = value;
  },

  [SET_ADMINISTRATORS_EDIT_ID] (state, id: string) {
    state.administratorId = id;
  },

  [SET_EMPTY] (state) {
    Object.assign(state, initialState());
  }
};

const actions: ActionTree<AdministratorsEditState, RootState> = {
  async [GET_ADMINISTRATOR] ({ state: { administratorId }, commit }) {
    const { data: { admins: { items: [admin] } } } = await AdministratorsEditService.getAdmin(administratorId as string);
    
    if (admin) {
      commit(SET_ADMINISTRATORS_EDIT_FORM, admin);
    }
  },

  async [EDIT_ADMINISTRATOR] ({ state: { administratorId, form } }) {
    await AdministratorsEditService.editAdmin(administratorId as string, form);
  },

  async [CREATE_ADMINISTRATOR] ({ state: { form } }) {
    await AdministratorsEditService.createAdmin(form);
  }
};

export const edit: Module<AdministratorsEditState, RootState> = {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  modules: {
    password
  }
};
